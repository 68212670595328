import React, { memo } from "react";
import "./MarketSummary.scss";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";

const MarketSummary = memo(({ totalAvailableData }) => {
  const summary = {
    image: <FormatListBulletedIcon style={{ fontSize: "17px" }} />,
    text: "Total Records",
    data: totalAvailableData,
  };
  return (
    <>
      <div className="main-summary-container">
        <div className="box-top">
          <span
            title={summary.text}
            className="ellipsisNameWithWidthMarketSummary"
          >
            {summary.text}
          </span>
        </div>
        <span className="line"></span>
        <div className="box-down">
          <span className="notranslate">{summary.data}</span>
        </div>
      </div>
    </>
  );
});

export default MarketSummary;
