import React from "react";
import "./selectDropdown.scss";
import millify from "millify";

export default function SelectDropdown(props) {
  const title = props?.optionValue;
  return (
    <div className="select">
      <select
        onChange={(e) => props?.setValue(e)}
        value={props?.value}
        style={{ height: props?.height, width: props?.width, cursor: "pointer", margin: props?.margin && props?.margin }}
        className={props?.className ? props?.className : ""}
      >
        {props?.intialOption && <option hidden>{props?.intialOptionLable}</option>}
        {props?.option.length > 0 &&
          props?.option.map((e, index) => {
            return (
              <option value={props?.defaultValue ? e[props?.defaultValue] : e[title]} key={index} defaultValue={props?.option[0][title]}>
                {e[title] && e[title].split("_").join(" ")}
                {e["totalSum"] && ` ($${millify(e["totalSum"])})`}
              </option>
            );
          })}
      </select>
    </div>
  );
}
