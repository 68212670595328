import React from "react";
import { Grid, Tooltip } from "@mui/material";
import "./fetchWorkspace.scss";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {
  ButtonComponent,
  ButtonComponentWithToolTip,
  DeleteButton,
  DeleteButtonComponentWithToolTip,
} from "../../../../Component/ButtonComponent/Button";
import ReplyAllOutlinedIcon from "@mui/icons-material/ReplyAllOutlined";
import { DeleteForeverOutlined } from "@mui/icons-material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import moment from "moment";
export default function FetchWorkspace({
  setCurrentSelectedWorkspaceId,
  setIsDeleteRecord,
  allWorkspacesData,
  handleShareBtnClick,
  handleDownlodWorkspaces,
  handleAnalyzeButtonClick,
}) {
  const handleDeleteClick = (workspace = undefined) => {
    if (workspace) {
      setCurrentSelectedWorkspaceId(workspace?._id);
    }
    setIsDeleteRecord(true);
  };

  return (
    <div id="fetchWorkspace-comp">
      <Grid
        container
        spacing={3}
        columnSpacing={{ xs: 1, sm: 3, md: 1, lg: 4 }}
        style={{ marginTop: "0px" }}
      >
        {allWorkspacesData &&
          allWorkspacesData.length > 0 &&
          allWorkspacesData.map((workspace, index) => {
            const flagUrl = require(`../../../../Asset/flags/${workspace?.flag_uri}`);
            return (
              <Grid item xl={3} lg={4} md={6} sm={6} xs={12} key={index}>
                <div className="card-box-workspace">
                  <div className="workSpace-title-date-container">
                    <div className="workSpace-title-container">
                      <Tooltip
                        title={
                          <span className="tooltipTitle">
                            {workspace?.country}
                          </span>
                        }
                        arrow
                      >
                        <img
                          style={{ height: "28px" }}
                          src={flagUrl}
                          className="set-image"
                          alt="flag"
                        />
                      </Tooltip>

                      <Tooltip
                        title={
                          <span className="tooltipTitle">
                            {workspace?.name}
                          </span>
                        }
                        arrow
                      >
                        <span id="workspace-name">{workspace?.name}</span>
                      </Tooltip>
                    </div>
                    <div id="create-date" className="notranslate">
                      <b id="set-date-text">
                        <span id="set-cal-icon">
                          <CalendarMonthIcon fontSize="smaller" />
                        </span>
                        <b style={{ fontSize: "14px" }}>
                          {moment(
                            new Date(workspace?.created_ts).toDateString()
                          ).format("ddd , Do MMM , YYYY")}
                        </b>
                      </b>
                    </div>
                  </div>
                  <div className="workspace-highlight-content">
                    <div style={{ width: "30%" }}>
                      <h4 className=" m-0 workspace-text-align">Trade</h4>
                      <h5 className="text-muted workspace-text-align workspace-text-weight m-5">
                        {workspace?.trade}
                      </h5>
                    </div>
                    <span className="workSpace-pipe"></span>
                    <div style={{ width: "40%" }}>
                      <h4 className=" m-0 workspace-text-align">Country</h4>
                      <h5 className="text-muted workspace-text-align workspace-text-weight m-5">
                        {workspace?.country}
                      </h5>
                    </div>
                    <span className="workSpace-pipe"></span>
                    <div style={{ width: "30%" }}>
                      <h4 className=" m-0 workspace-text-align">Shipments</h4>
                      <h5 className="text-muted workspace-text-align workspace-text-weight m-5 notranslate">
                        {workspace?.records}
                      </h5>
                    </div>
                  </div>

                  <div className="workspaces-btn-container">
                    <Grid
                      container
                      spacing={1}
                      columnSpacing={{ xs: 2, sm: 0, md: 0, lg: 1 }}
                      className="workspaces-btn-grp"
                    >
                      <ButtonComponentWithToolTip
                        name="Analyze"
                        btnClick={() => handleAnalyzeButtonClick(workspace)}
                        style={{
                          width: "55%",
                          height: "35px",
                          marginRight: "1%",
                          maxwidth: "55%",
                        }}
                        tooltipTitle={"Analyze Your WorkSpace"}
                      />

                      <ButtonComponentWithToolTip
                        btnIcon={ReplyAllOutlinedIcon}
                        name=""
                        btnClick={() => handleShareBtnClick(workspace)}
                        style={{
                          margin: "0px 1%",
                          width: "11%",
                          minWidth: "0",
                          height: "35px",
                        }}
                        iconStyle={{
                          transform: "scaleX(-1)",
                          width: "22px",
                          height: "22px",
                        }}
                        tooltipTitle={"Share Your WorkSpace"}
                      />
                      <ButtonComponentWithToolTip
                        name=""
                        btnIcon={FileDownloadOutlinedIcon}
                        btnClick={() => handleDownlodWorkspaces(workspace)}
                        style={{
                          margin: "0px 1% 0px 1% ",
                          width: "11%",
                          minWidth: "0",
                          height: "35px",
                        }}
                        iconStyle={{ width: "22px", height: "22px" }}
                        tooltipTitle={"Download Your WorkSpace"}
                      />

                      <DeleteButtonComponentWithToolTip
                        name=""
                        btnIcon={DeleteForeverOutlined}
                        btnClick={() => handleDeleteClick(workspace)}
                        style={{
                          margin: "0px 0pc 0px 1% ",
                          width: "11%",
                          minWidth: "0",
                          height: "35px",
                        }}
                        iconStyle={{ width: "22px", height: "22px" }}
                        tooltipTitle={"Delete Your WorkSpace"}
                      />
                    </Grid>
                  </div>
                </div>
              </Grid>
            );
          })}
      </Grid>
    </div>
  );
}
// linear-gradient(to top, #ffffff00, #7a7a7a ,#ffffff00) 1;
