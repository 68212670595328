import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import SelectDropdown from "../SelectDropdown";
import { useState } from "react";
import InputBox from "../InputBox";
import PieChartData from "../PieChartData";
import LineChart from "../LineChart";
import BarChart from "../BarChart";
// import { STATIC_COLUMNS_IMPORT, STATIC_COLUMNS_EXPORT } from "../../../../IndiaTradeRecords/records.js";

const options = [
  {
    key: "PIE_CHART",
    title: "Pie Chart",
  },
  {
    key: "LINE_CHART",
    title: "Line Chart",
  },

  {
    key: "BAR_CHART",
    title: "Bar Chart",
  },
];
const recordLength = [
  { key: "3", type: "top", title: "TOP 3" },
  { key: "5", type: "top", title: "TOP 5" },
  { key: "10", type: "top", title: "TOP 10" },
  { key: "15", type: "top", title: "TOP 15" },
  { key: "-15", type: "bottom", title: "BOTTOM 15" },
  { key: "-10", type: "bottom", title: "BOTTOM 10" },
  { key: "-5", type: "bottom", title: "BOTTOM 5" },
  { key: "-3", type: "bottom", title: "BOTTOM 3" },
];
const ChartModalContent = ({
  initialChartType,
  data,
  headers,
  initialRecordLengthValue,
  setChartValues,
  currentChartData,
  isUpdateModal,
  setUpdateModal,
  columnsValues,
  STATIC_COLUMNS
}) => {
  const [title, setTitle] = useState("");
  const [chartType, setChartType] = useState(initialChartType);
  const [groupByArr, setGroupByArr] = useState([]);
  const [lineChartArr, setLineChart] = useState([]);
  const [measuresArr, setMeasuresArr] = useState([]);
  const [measuresValue, setMeasuresValue] = useState("");
  const [yAxisValue, setYAxisValue] = useState("");
  const [yAxis2Value, setYAxis2Value] = useState("");
  const [xAxisValue, setXAxisValue] = useState("");
  const [groupByValue, setGroupByValue] = useState("");
  const [currentChartGroup, setCurrentChartGroup] = useState({});
  const [dummyState, setDummyState] = useState(false);
  const [recordLengthValue, setRecordLengthValue] = useState(initialRecordLengthValue);

  const arr = [
    {
      key: "PIE_CHART",
      title: "Pie Chart",
      groupBy: true,
      measures: true,
    },
    {
      key: "LINE_CHART",
      title: "Line Chart",
      yAxis: true,
      xAxis: true,
    },
    {
      key: "COLUMN_CHART",
      title: "Column Chart",
      yAxis: true,
      yAxis2: true,
      xAxis: true,
    },
    {
      key: "BAR_CHART",
      title: "Bar Chart",
      yAxis: true,
      yAxis2: true,
      xAxis: true,
    },
  ];

  const select_something_obj = {
    key: "CHOOSE OPTION",
    title: "CHOOSE OPTION",
  };

  useEffect(() => {
    setChartValues({
      title,
      chartType,
      groupByValue,
      xAxisValue,
      yAxisValue,
      yAxis2Value,
      measuresValue,
      recordLengthValue: recordLength.find((e) => {
        return e?.key === recordLengthValue;
      }),
    });
  }, [title, chartType, groupByValue, xAxisValue, yAxisValue, measuresValue, recordLengthValue, yAxis2Value]);

  useEffect(() => {
    const newHeaders = headers.filter((elem) => {
      return !STATIC_COLUMNS.includes(elem?.title);

    });
    newHeaders.unshift(select_something_obj);
    setGroupByArr(newHeaders);

    const newMeasures = [...columnsValues];
    newMeasures.unshift(select_something_obj);
    setMeasuresArr(newMeasures);
    const newLineChart = [...columnsValues];
    newLineChart.unshift(select_something_obj);
    setLineChart(newLineChart);

    return () => {
      setUpdateModal(false);
    };
  }, []);

  useEffect(() => {
    if (chartType) {
      clearAllValue();
      const currentObj = arr.find((e) => {
        return e.key === chartType;
      });
      setCurrentChartGroup(currentObj);
    }
  }, [chartType]);

  function clearAllValue() {
    if (!isUpdateModal || dummyState) {
      setMeasuresValue(() => "CHOOSE OPTION");
      setYAxisValue(() => "CHOOSE OPTION");
      setYAxis2Value(() => "CHOOSE OPTION");
      setGroupByValue(() => "CHOOSE OPTION");
      setXAxisValue(() => "CHOOSE OPTION");
      setRecordLengthValue(() => "3");
      setTitle(() => "");
    }
    setDummyState(true);
  }

  const splitWord = (word) => {
    return word?.split("_").join(" ");
  };

  useEffect(() => {
    if (
      (chartType === "BAR_CHART" || chartType === "LINE_CHART") &&
      yAxisValue !== "" &&
      xAxisValue !== "" &&
      yAxisValue !== "CHOOSE OPTION" &&
      xAxisValue !== "CHOOSE OPTION"
    ) {
      setTitle(`${splitWord(yAxisValue)} ON ${splitWord(xAxisValue)}`);
    } else if (
      chartType === "PIE_CHART" &&
      measuresValue !== "" &&
      measuresValue !== "CHOOSE OPTION" &&
      groupByValue !== "" &&
      groupByValue !== "CHOOSE OPTION"
    ) {
      setTitle(`${splitWord(groupByValue)} SHIPMENTS ${splitWord(measuresValue)}`);
    } else {
      setTitle("");
    }
  }, [xAxisValue, yAxisValue, measuresValue, groupByValue]);

  useEffect(() => {
    if (isUpdateModal) {
      setTitle(currentChartData?.type);
      setGroupByValue(currentChartData?.data?.groupValue);
      setXAxisValue(currentChartData?.data?.xkey);
      setYAxisValue(currentChartData?.data?.ykey);
      setMeasuresValue(currentChartData?.data?.messasureValue);
      setYAxis2Value(currentChartData?.data?.ykey2);
    }
  }, [isUpdateModal]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6} className="zoom_in_75">
          <label className="trade-label">Chart Type</label>
          <SelectDropdown
            value={chartType}
            setValue={(e) => {
              setChartType(e.target.value);
            }}
            option={options}
          />
        </Grid>
        <Grid item xs={6} className="zoom_in_75">
          <label className="trade-label">Title</label>
          <div>
            <InputBox
              value={title}
              handleOnChange={(e) => {
                setTitle(e.target.value);
              }}
            />
          </div>
        </Grid>
        {currentChartGroup && Object.keys(currentChartGroup)?.length > 0 && currentChartGroup.hasOwnProperty("groupBy") && (
          <Grid item xs={6} className="zoom_in_75">
            <label className="trade-label">Grpup By</label>
            <SelectDropdown
              value={groupByValue}
              setValue={(e) => {
                setGroupByValue(e.target.value);
              }}
              option={groupByArr}
            />
          </Grid>
        )}
        {currentChartGroup && Object.keys(currentChartGroup)?.length > 0 && currentChartGroup.hasOwnProperty("measures") && (
          <Grid item xs={6} className="zoom_in_75">
            <label className="trade-label">Measures</label>
            <SelectDropdown
              value={measuresValue}
              setValue={(e) => {
                setMeasuresValue(e.target.value);
              }}
              option={measuresArr}
            />
          </Grid>
        )}
        {currentChartGroup && Object.keys(currentChartGroup)?.length > 0 && currentChartGroup.hasOwnProperty("yAxis") && (
          <Grid item xs={6} className="zoom_in_75">
            <label className="trade-label">Y-Axis</label>
            <SelectDropdown
              value={yAxisValue}
              setValue={(e) => {
                setYAxisValue(e.target.value);
              }}
              option={lineChartArr}
            />
          </Grid>
        )}
        {currentChartGroup && Object.keys(currentChartGroup)?.length > 0 && currentChartGroup.hasOwnProperty("yAxis2") && (
          <Grid item xs={6} className="zoom_in_75">
            <label className="trade-label">Y-Axis-2</label>
            <SelectDropdown
              value={yAxis2Value}
              setValue={(e) => {
                setYAxis2Value(e.target.value);
              }}
              option={measuresArr}
            />
          </Grid>
        )}
        {currentChartGroup && Object.keys(currentChartGroup)?.length > 0 && currentChartGroup.hasOwnProperty("xAxis") && (
          <Grid item xs={6} className="zoom_in_75">
            <label className="trade-label">X-Axis</label>
            <SelectDropdown
              value={xAxisValue}
              setValue={(e) => {
                setXAxisValue(e.target.value);
              }}
              option={groupByArr}
            />
          </Grid>
        )}
        <Grid item xs={6} className="zoom_in_75">
          <label className="trade-label">Select</label>
          <SelectDropdown
            value={recordLengthValue}
            setValue={(e) => {
              setRecordLengthValue(e.target.value);
            }}
            option={recordLength}
          />
        </Grid>
        <Grid item xs={12} style={{ height: "400px", overflow: "scroll" }}>
          {chartType === "PIE_CHART" && (
            <>
              <PieChartData
                isLoaderShowing={true}
                data={data}
                label={groupByValue}
                value={measuresValue}
                recordLength={recordLength.find((e) => {
                  return e?.key === recordLengthValue;
                })}
              />
            </>
          )}
          {chartType === "LINE_CHART" && (
            <>
              <LineChart
                isLoaderShowing={true}
                data={data}
                xkey={xAxisValue}
                ykey={yAxisValue}
                recordLength={recordLength.find((e) => {
                  return e?.key === recordLengthValue;
                })}
              />
            </>
          )}
          {chartType === "BAR_CHART" && (
            <>
              <BarChart
                isLoaderShowing={true}
                data={data}
                xkey={xAxisValue}
                ykey={yAxisValue}
                ykey2={yAxis2Value}
                recordLength={recordLength.find((e) => {
                  return e?.key === recordLengthValue;
                })}
              />
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default ChartModalContent;
