import * as React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  TableHead,
  IconButton,
  Tooltip,
  Button,
  Checkbox,
  tooltipClasses,
  Typography,
} from "@mui/material";
import { Pagination as MuiPagination } from "@mui/material";
import Pagination from "../PaginationTest/Pagination";
import SearchIcon from "@mui/icons-material/Search";

import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CreditCardIcon from "@mui/icons-material/CreditCard";

import "./Table.scss";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";

import { useEffect } from "react";
import TablePagionationDropdown from "../TablePaginationDropdown/tablePagionationDropdown";
import { STICKYCOLUMN, minWidth_column } from "../StickyTable/enum/Table.enum";
import Sorting from "./Component/Sorting/Sorting";
import { tableEnum } from "./Table.enum";
import { experimentalStyled as styled } from "@mui/material/styles";
import { translateButtonClick } from "../../utils/CommanFunction";
import moment from "moment";
import { DATEFORMAT } from "../../Containers/ExploreCountryShipmentNewUI/Explore.enum";
import {
  ButtonComponent,
  ButtonComponentWithToolTip,
} from "../ButtonComponent/Button";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import StatusButtonComponent from "./Component/StatusButtonComponent/StatusButtonComponent ";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import EastIcon from "@mui/icons-material/East";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Excel from "../../Asset/images/excel.png";

import { color } from "highcharts";
import { ReactToaster, notify } from "../ReactToaster/ReactToaster";
import millify from "millify";
import { memo } from "react";

const SuccessBtn = styled(Button)({
  backgroundColor: "rgba(26, 188, 156, 0.25);",
  borderColor: "rgba(26, 188, 156, 0.25);",
  color: "#1abc9c !important",
  fontWeight: 900,
  fontSize: "12px",
  height: "19px",
  padding: "5px",
  cursor: "default",
  // textTransform:"none",
  "&:hover": {
    backgroundColor: "rgba(26, 188, 156, 0.25);",
    borderColor: "rgba(26, 188, 156, 0.25);",
  },
});

const ErrorBtn = styled(Button)({
  backgroundColor: "rgba(241, 85, 108, 0.25);",
  borderColor: "rgba(241, 85, 108, 0.25);",
  color: "#f1556c !important",
  fontWeight: 900,
  fontSize: "12px",
  height: "19px",
  padding: "5px",
  cursor: "default",
  // textTransform:"none",
  "&:hover": {
    backgroundColor: "rgba(241, 85, 108, 0.25);",
    borderColor: "rgba(241, 85, 108, 0.25);",
  },
});

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

const DataTable = memo(
  ({
    columns,
    rows,
    handleSelectedShipments,
    getSelectedRow,
    tableScroll,
    height,
    isExplore,
    isWorkSpace,
    handleDescendingAndAscendingOrder,
    descendingAndAscending,
    handleCollapseOpenModal,
    showPagination,
    handleChangeRowsPerPage,
    rowsPerPage,
    totalAvailableData,
    page,
    handleChangePage,
    isGlobalSearch,
    hashMarkColumns,
    availableCountry,
    handleOpenModel,
    currentCountry,
    countryDetails,
    handleEditUser,
    handleAddCredit,
    handleDeleteUser,
    handleRunCompany,
    handleOpenDeleteModal,
    is_active,
    isFromManageUser,
    isRequestedBuyer,
    mirrorData,
    isFromTicket,
    handleResolutionInfo,
    onFilterStatusClick,
    isFromActivity,
    isFromRecentActivity,
    isFromExploreContactCompanyModal,
    isFromLinkedInPage,
    handleRequestForData,
    isAppliedForRequestedData,
    companySocialMediaUrls,
    isRequestedBuyerData,
    isClickedEmail,
    isUnique,
    companyDetailTable,
    handleDownloadExcelFromFrontEnd,
    isFromDashboardTable,
    exploreCompanySearch,
    setLoading,
    loading,
  }) => {
    function getOnlyYear(dateString) {
      const fullDateRegex = /(\d{4})-(\d{2})-(\d{2})/;
      const reversedDateRegex = /(\d{2})-(\d{2})-(\d{4})/;
      const monthNameDateRegex = /(\d{2})\s([A-Za-z]+)\s(\d{4})/;
      let match = fullDateRegex.exec(dateString);
      if (match) return match[1];
      match = reversedDateRegex.exec(dateString);
      if (match) return match[3];
      match = monthNameDateRegex.exec(dateString);
      if (match) return match[3];
      if (/^\d{4}$/.test(dateString)) return dateString;
      return null;
    }
    useEffect(() => {
      if (!isFromRecentActivity) {
        const elem = document.getElementsByClassName("MuiTableContainer-root");
        let a = elem[1];
        let b = elem[0];
        if (a) {
          a.addEventListener("scroll", (e) => {
            b.scrollLeft = a.scrollLeft;
          });
        }
      }
    }, []);

    if (!isExplore) {
      window.onload = translateButtonClick();
    }
    const handleCopy = (row) => {
      navigator.clipboard
        .writeText(row)
        .then(() => {
          notify();
        })
        .catch((err) => {
          console.error("Failed to copy text: ", err);
        });
    };

    const [stickyColumnsArray, setStickyColumnsArray] =
      React.useState(STICKYCOLUMN);
    useEffect(() => {
      if (mirrorData) {
        const array = STICKYCOLUMN.filter(
          (item) => item !== "HS_CODE_DESCRIPTION"
        );
        setStickyColumnsArray(array);
      }
    }, [mirrorData]);

    const getActivityTrackerQueryData = (val) => {
      return (
        val &&
        Array.isArray(val) &&
        val?.length > 0 &&
        val?.map((e) => {
          if (e?.identifier.split("_")[0] === "FILTER") {
            return ` | FILTER :- ${e?.alias?.split("_").join(" ")} : ${
              e?.fieldValue
            }`;
          } else {
            return `SEARCH :- ${e?.alias?.split("_").join(" ")} :  ${
              e?.fieldValue
            }`;
          }
        })
      );
    };

    const getLeftPosition = (key) => {
      let left = "";
      if (isWorkSpace) {
        left =
          key === "add" || key === "entity"
            ? "0px"
            : key === "HS_CODE" || key === "CMDT_CODE"
            ? "66px"
            : tableEnum?.scrollableColumns.includes(key)
            ? columns.filter(
                (e) => e?.key === "HS_CODE" || e?.key === "CMDT_CODE"
              ).length === 0
              ? "66px"
              : "185.56px"
            : "";
      } else if (isGlobalSearch) {
        left =
          key === "HS_CODE" || key === "CMDT_CODE"
            ? "0px"
            : tableEnum?.scrollableColumns.includes(key)
            ? columns.filter(
                (e) => e?.key === "HS_CODE" || e?.key === "CMDT_CODE"
              ).length === 0
              ? "0px"
              : "119.56px"
            : "";
      } else {
        left =
          key === "add" || key === "entity"
            ? "0px"
            : key === "checkbox"
            ? "66px"
            : key === "HS_CODE" || key === "CMDT_CODE"
            ? "140px"
            : tableEnum?.scrollableColumns.includes(key)
            ? columns.filter(
                (e) => e?.key === "HS_CODE" || e?.key === "CMDT_CODE"
              ).length === 0
              ? "140px"
              : "259.56px"
            : "";
      }
      return left;
    };

    return (
      <Paper id="dataTable-table" sx={{ width: "100%" }}>
        <ReactToaster />
        <TableContainer
          sx={{
            height: height ? height : "100%",
            overflowX: tableScroll
              ? "scroll"
              : isExplore || isWorkSpace
              ? "hidden"
              : "scroll",
          }}
        >
          <Table
            stickyHeader
            style={{ height: height, userSelect: "none" }}
            aria-label="sticky table"
            size="small"
          >
            <TableHead>
              <TableRow style={{ height: 61 }}>
                {columns &&
                  !!columns?.length &&
                  columns?.map((column, index) => {
                    if (
                      column?.title !== "isFavourite" &&
                      column?.title !== "isFavouriteShipment"
                    ) {
                      return (
                        <>
                          <TableCell
                            // id={ }
                            className="tableHeader"
                            key={column + index}
                            style={{
                              position: stickyColumnsArray.includes(column?.key)
                                ? "sticky"
                                : "",
                              left: getLeftPosition(column?.key),
                              zIndex: stickyColumnsArray.includes(column?.key)
                                ? 3
                                : "",

                              minWidth: ["HS_CODE", "CMDT_CODE"].includes(
                                column?.key
                              )
                                ? "119.56px"
                                : minWidth_column.includes(column?.key)
                                ? "420px"
                                : column?.key === "add"
                                ? "66px"
                                : column?.key === "checkbox"
                                ? "74px"
                                : "",
                              borderRadius:
                                index === 0 ? "8px 0px 0px 0px" : "",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                position: "relative",
                              }}
                            >
                              {column?.title}
                              {isExplore || isWorkSpace ? (
                                <>
                                  {descendingAndAscending &&
                                  descendingAndAscending.hasOwnProperty(
                                    column?.key
                                  ) ? (
                                    <Sorting
                                      column={column?.key}
                                      handleDescendingAndAscendingOrder={
                                        handleDescendingAndAscendingOrder
                                      }
                                      descendingAndAscending={
                                        descendingAndAscending
                                      }
                                    />
                                  ) : (
                                    ""
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                              {column?.key === "status" ? (
                                <FilterAltOutlinedIcon
                                  onClick={onFilterStatusClick}
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  fontSize="large"
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </TableCell>
                        </>
                      );
                    }
                  })}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows &&
                !!rows?.length &&
                rows.map((row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      {columns.map((column, i) => {
                        if (column !== undefined) {
                          switch (column.key) {
                            case "entityForComposition":
                              return (
                                <TableCell
                                  style={{
                                    position: "sticky",
                                    left: "0px",
                                    zIndex: 2,
                                    padding: "18px",
                                    color: "#6c757d",
                                    cursor: "pointer",
                                  }}
                                  className="tableRow"
                                  key={column + index + row[column]}
                                >
                                  {row?.entity}
                                </TableCell>
                              );
                            case "add":
                              return (
                                <>
                                  <TableCell
                                    style={{
                                      position: "sticky",
                                      left: "0px",
                                      zIndex: 2,
                                    }}
                                    key={column + index + row[column]}
                                    className="tableRow"
                                  >
                                    <IconButton
                                      aria-label="expand row"
                                      size="small"
                                      onClick={() =>
                                        handleCollapseOpenModal(
                                          columns,
                                          row,
                                          index
                                        )
                                      }
                                    >
                                      <RemoveRedEyeIcon fontSize="small" />
                                    </IconButton>
                                  </TableCell>
                                </>
                              );

                            case "checkbox":
                              return (
                                <>
                                  <TableCell
                                    style={{
                                      position: "sticky",
                                      left: "66px",
                                      zIndex: 2,
                                    }}
                                    key={column + index + row[column]}
                                    className="tableRow"
                                  >
                                    <Checkbox
                                      onClick={(e) =>
                                        isRequestedBuyer
                                          ? ""
                                          : handleSelectedShipments(e, row?._id)
                                      }
                                      size="small"
                                      checked={
                                        getSelectedRow?.includes(row?._id)
                                          ? true
                                          : false
                                      }
                                    />
                                  </TableCell>
                                </>
                              );
                            case "EditBtn":
                              return (
                                <TableCell
                                  key={column + index + row[column]}
                                  className="tableRow"
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Tooltip title={"Edit"} arrow>
                                      <EditNoteOutlinedIcon
                                        onClick={() => handleEditUser(row)}
                                        fontSize="medium"
                                        style={{
                                          color: "#005d91",
                                          width: "1.1em",
                                          cursor: "pointer",
                                        }}
                                        className="table-row-icon"
                                      />
                                    </Tooltip>
                                    {row?.role !== "ADMINISTRATOR" && (
                                      <>
                                        <Tooltip title={"Delete"} arrow>
                                          <DeleteForeverIcon
                                            onClick={() =>
                                              handleDeleteUser(row)
                                            }
                                            style={{
                                              color: "red",
                                              margin: "0px 5px 0px 4px",
                                              height: "1em",
                                              cursor: "pointer",
                                            }}
                                            fontSize="medium"
                                            className="table-row-icon"
                                          />
                                        </Tooltip>

                                        {!isFromTicket ? (
                                          <Tooltip title={"Add Credit"} arrow>
                                            <CreditCardIcon
                                              onClick={() =>
                                                handleAddCredit(row)
                                              }
                                              style={{
                                                color: "#005d91",
                                                height: "1em",
                                                cursor: "pointer",
                                              }}
                                              fontSize="medium"
                                              className="table-row-icon"
                                            />
                                          </Tooltip>
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    )}
                                  </div>
                                </TableCell>
                              );
                            case "query":
                              return (
                                <TableCell
                                  className="scroll tableRow"
                                  style={{
                                    color: "black",
                                    overflowX: "auto",
                                  }}
                                  key={column + index + row[column]}
                                >
                                  {getActivityTrackerQueryData(
                                    row[column?.key]
                                  )}
                                </TableCell>
                              );
                            case "queryCreatedAt":
                              return (
                                <TableCell
                                  className="scroll tableRow"
                                  style={{
                                    color: "black",
                                    overflowX: "auto",
                                  }}
                                  key={column + index + row[column]}
                                >
                                  {moment(row[column?.key]).format(
                                    "DD/MM/YYYY HH:mm:ss"
                                  )}
                                </TableCell>
                              );
                            case "resolution":
                              return (
                                <TableCell
                                  className="scroll tableRow"
                                  style={{
                                    color: "black",
                                    overflowX: "auto",
                                  }}
                                  key={column + index + row[column]}
                                >
                                  <ButtonComponent
                                    name="info"
                                    style={{ height: "35px" }}
                                    disabled={row?.status !== 9}
                                    btnClick={() =>
                                      handleResolutionInfo(row, index)
                                    }
                                  />
                                  <ButtonComponentWithToolTip
                                    name={
                                      <img src={Excel} alt="ExcelDownload" />
                                    }
                                    style={{
                                      height: "35px",
                                      marginLeft: "15px",
                                      paddingTop: "12px",
                                    }}
                                    disabled={row?.status !== 9}
                                    btnClick={() =>
                                      handleDownloadExcelFromFrontEnd(
                                        row?.response,
                                        "single"
                                      )
                                    }
                                    toUpperCase={true}
                                    tooltipTitle="download excel"
                                  />
                                </TableCell>
                              );
                            case "status": {
                              return isFromTicket || isRequestedBuyerData ? (
                                <TableCell
                                  className="tableRow"
                                  key={column + index + row[column]}
                                >
                                  <StatusButtonComponent
                                    status={row[column.key]}
                                  />
                                </TableCell>
                              ) : (
                                <TableCell
                                  className="tableRow"
                                  key={column + index + row[column]}
                                >
                                  {row[column.key] === 1 || is_active ? (
                                    <SuccessBtn>ACTIVATE</SuccessBtn>
                                  ) : (
                                    <ErrorBtn
                                      style={{ height: "19px", padding: "5px" }}
                                    >
                                      {"Deactivated"}
                                    </ErrorBtn>
                                  )}
                                </TableCell>
                              );
                            }
                            case "id_subscription":
                              return (
                                <TableCell
                                  className="tableRow"
                                  key={column + index + row[column]}
                                >
                                  {index + 1}
                                </TableCell>
                              );
                            default:
                              let value = row[column?.key];
                              if (
                                column?.key === "companyBtn" &&
                                column?.isFavCompany
                              ) {
                                return (
                                  <TableCell
                                    className="tableRow"
                                    key={column + index + row[column]}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <Tooltip
                                        title={"Search In Explore"}
                                        arrow
                                      >
                                        <SearchIcon
                                          onClick={() => handleRunCompany(row)}
                                          fontSize="medium"
                                          style={{
                                            color: "#005d91",
                                            width: "1.1em",
                                          }}
                                          className="table-row-icon with-border"
                                        />
                                      </Tooltip>
                                      <Tooltip
                                        title={
                                          "Remove Company in  Your Fav list"
                                        }
                                        arrow
                                      >
                                        <DeleteForeverIcon
                                          onClick={() =>
                                            handleOpenDeleteModal(row)
                                          }
                                          fontSize="medium"
                                          style={{
                                            color: "red",
                                            width: "1.1em",
                                            cursor: "pointer",
                                          }}
                                          className="table-row-icon with-border-delete"
                                        />
                                      </Tooltip>
                                    </div>
                                  </TableCell>
                                );
                              } else if (
                                column?.key === "is_active" &&
                                isFromManageUser
                              ) {
                                return (
                                  <TableCell
                                    className="tableRow"
                                    key={column + index + row[column]}
                                  >
                                    {row[column?.key] === 1 ? (
                                      <SuccessBtn>Activated</SuccessBtn>
                                    ) : (
                                      <ErrorBtn
                                        style={{
                                          height: "19px",
                                          padding: "5px",
                                        }}
                                      >
                                        Deactivated
                                      </ErrorBtn>
                                    )}
                                  </TableCell>
                                );
                              } else if (
                                column?.key === "entity" &&
                                isWorkSpace
                              ) {
                                return (
                                  <TableCell
                                    className="tableRow"
                                    key={column + index + row[column]}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        cursor: "pointer",
                                        width: "100%",
                                        color: "black",
                                      }}
                                      onClick={() =>
                                        handleCollapseOpenModal(row)
                                      }
                                    >
                                      {row[column?.key]}
                                    </div>
                                  </TableCell>
                                );
                              } else if (
                                column?.key === "organziation_name" &&
                                isFromExploreContactCompanyModal
                              ) {
                                return (
                                  <TableCell
                                    className="tableRow"
                                    key={column + index + row[column]}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        width: "100%",
                                        color: "black",
                                      }}
                                    >
                                      <div
                                        className="company-logo"
                                        style={{
                                          width: "50px",
                                          marginLeft: "5px",
                                        }}
                                      >
                                        <img
                                          src={row?.organization_url}
                                          style={{
                                            width: "100%",
                                            borderRadius: "16%",
                                          }}
                                        />
                                      </div>
                                      <div
                                        className="name-of-company"
                                        style={{ marginLeft: "30px" }}
                                      >
                                        {row[column?.key]}
                                      </div>
                                    </div>
                                  </TableCell>
                                );
                              } else if (
                                column?.key === "organization_founded" &&
                                isFromExploreContactCompanyModal
                              ) {
                                return (
                                  <TableCell
                                    className="tableRow"
                                    key={column + index + row[column]}
                                  >
                                    <div>
                                      {getOnlyYear(row[column?.key]) ?? "-"}
                                    </div>
                                  </TableCell>
                                );
                              } else if (
                                column?.key === "contact" &&
                                isFromLinkedInPage
                              ) {
                                return (
                                  <TableCell
                                    className="tableRow"
                                    key={column + index + row[column]}
                                  >
                                    {!isUnique ? (
                                      <div className="company-contact-cell">
                                        <Tooltip
                                          title={
                                            !isAppliedForRequestedData?.email[
                                              row?.peopleId
                                            ]?.requested
                                              ? "Request Email Address"
                                              : null
                                          }
                                          arrow
                                        >
                                          <div
                                            className="linkedInPage-contact"
                                            name="email"
                                            onClick={() =>
                                              !isAppliedForRequestedData?.email[
                                                row?.peopleId
                                              ]?.requested &&
                                              handleRequestForData(
                                                "Email Address",
                                                index,
                                                row
                                              )
                                            }
                                          >
                                            <EmailOutlinedIcon className="contact-icon" />
                                            {isAppliedForRequestedData?.email[
                                              row?.peopleId
                                            ]?.requested && (
                                              <>
                                                <span className="tick-icon-email">
                                                  <CheckCircleIcon className="verified-green-icon" />
                                                </span>
                                                <span className="expand-requested-details">
                                                  <HtmlTooltip
                                                    title={
                                                      <div className="html-tooltip-container">
                                                        <div
                                                          className="d-flex"
                                                          style={{
                                                            justifyContent:
                                                              "start",
                                                            alignItems:
                                                              "center",
                                                            height: "26px",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: "14px",
                                                              marginBottom:
                                                                "6px",
                                                            }}
                                                          >
                                                            {
                                                              isAppliedForRequestedData
                                                                ?.email[
                                                                row?.peopleId
                                                              ]?.emailId
                                                            }
                                                          </span>
                                                          <div
                                                            className="copy-icon"
                                                            onClick={() =>
                                                              handleCopy(
                                                                isAppliedForRequestedData
                                                                  ?.email[
                                                                  row?.peopleId
                                                                ]?.emailId
                                                              )
                                                            }
                                                          >
                                                            <ContentCopyIcon fontSize="15px" />
                                                          </div>
                                                        </div>

                                                        {/* <div className="request-latest-data-button">
                                                        Request latest data
                                                      </div> */}
                                                      </div>
                                                    }
                                                  >
                                                    <ExpandMoreIcon />
                                                  </HtmlTooltip>
                                                </span>
                                              </>
                                            )}
                                          </div>
                                        </Tooltip>
                                        <div style={{ color: "#005d91" }}>
                                          <EastIcon className="contact-icon" />
                                        </div>
                                        <Tooltip
                                          // title={
                                          //   isClickedEmail[row?.peopleId] &&
                                          //   !isAppliedForRequestedData?.phoneNumber[
                                          //     row?.peopleId
                                          //   ]?.requested
                                          //     ? "Request phone number"
                                          //     : null
                                          // }
                                          arrow
                                        >
                                          <div
                                            className="linkedInPage-contact"
                                            name="phoneNumber"
                                            onClick={() =>
                                              isClickedEmail[row?.peopleId] &&
                                              !isAppliedForRequestedData
                                                ?.phoneNumber[row?.peopleId]
                                                ?.requested &&
                                              handleRequestForData(
                                                "Phone Number",
                                                index,
                                                row
                                              )
                                            }
                                          >
                                            <LocalPhoneOutlinedIcon
                                              className="contact-icon"
                                              style={{
                                                color: isClickedEmail?.[
                                                  row?.peopleId
                                                ]
                                                  ? "#6c757d"
                                                  : "#bcbcbc",
                                              }}
                                            />

                                            {isAppliedForRequestedData
                                              ?.phoneNumber[row?.peopleId]
                                              ?.requested && (
                                              <>
                                                <span className="tick-icon">
                                                  <CheckCircleIcon className="verified-green-icon" />
                                                </span>
                                                <HtmlTooltip
                                                  title={
                                                    <div className="html-tooltip-container">
                                                      <strong
                                                        style={{
                                                          display: "block",
                                                          marginBottom: "3px",
                                                          fontSize: "14px",
                                                        }}
                                                      >
                                                        Phone Number :
                                                      </strong>
                                                      <div
                                                        className="d-flex"
                                                        style={{
                                                          justifyContent:
                                                            "start",
                                                          alignItems: "center",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: "13px",
                                                          }}
                                                        >
                                                          {
                                                            isAppliedForRequestedData
                                                              ?.phoneNumber[
                                                              row?.peopleId
                                                            ]?.number
                                                          }
                                                        </span>
                                                        <div
                                                          className="copy-icon"
                                                          onClick={() =>
                                                            handleCopy(
                                                              isAppliedForRequestedData
                                                                ?.phoneNumber[
                                                                row?.peopleId
                                                              ]?.number
                                                            )
                                                          }
                                                        >
                                                          <ContentCopyIcon fontSize="15px" />
                                                        </div>
                                                      </div>

                                                      {/* <div className="request-latest-data-button">
                                                      Request latest data
                                                    </div> */}
                                                    </div>
                                                  }
                                                  placement="bottom-end"
                                                  arrow
                                                >
                                                  <span className="expand-requested-details">
                                                    <ExpandMoreIcon />
                                                  </span>
                                                </HtmlTooltip>
                                              </>
                                            )}
                                          </div>
                                        </Tooltip>
                                      </div>
                                    ) : (
                                      <div style={{ textAlign: "center" }}>
                                        -
                                      </div>
                                    )}
                                  </TableCell>
                                );
                              } else if (
                                column?.key === "social_urls" &&
                                isFromLinkedInPage
                              ) {
                                return (
                                  <TableCell
                                    key={column + index + row}
                                    className={"tableRow"}
                                    style={{
                                      color: "black",
                                    }}
                                  >
                                    <div
                                      className="social-media-company d-flex"
                                      style={{ justifyContent: "space-evenly" }}
                                    >
                                      {companySocialMediaUrls(
                                        row?.social_urls,
                                        "x-icon"
                                      )}
                                    </div>
                                  </TableCell>
                                );
                              } else if (column?.key === "TOTAL_VALUE") {
                                return (
                                  <TableCell
                                    className="tableRow"
                                    key={column + index + row[column]}
                                  >
                                    <div>{millify(row[column?.key])}</div>
                                  </TableCell>
                                );
                              } else if (
                                column?.key === "COMPANY" &&
                                isFromDashboardTable
                              ) {
                                return (
                                  <TableCell
                                    className="tableRow"
                                    key={column + index + row[column]}
                                  >
                                    <div
                                      className="hover-companyName"
                                      onClick={() => exploreCompanySearch(row)}
                                    >
                                      {row[column?.key]}
                                      <SuccessBtn
                                        style={{ marginLeft: "10px" }}
                                      >
                                        {row?.COMPANY_TYPE?.split("_")?.join(
                                          " "
                                        )}
                                      </SuccessBtn>
                                    </div>
                                  </TableCell>
                                );
                              } else {
                                if (
                                  tableEnum.formateDateColumns.includes(
                                    column?.key
                                  )
                                ) {
                                  return (
                                    <TableCell
                                      key={column + index + row}
                                      className={"tableRow"}
                                      style={{
                                        color: "black",
                                      }}
                                    >
                                      {moment(value).format(DATEFORMAT?.format)}
                                    </TableCell>
                                  );
                                } else if (
                                  column?.title !== "isFavourite" &&
                                  column?.title !== "isFavouriteShipment"
                                ) {
                                  return (
                                    <>
                                      <TableCell
                                        className={
                                          column?.title ===
                                            "GOODS DESCRIPTION" ||
                                          tableEnum?.scrollableColumns.includes(
                                            column?.key
                                          )
                                            ? "scroll tableRow"
                                            : "tableRow"
                                        }
                                        key={column + index + row}
                                        style={{
                                          color: "black",
                                          position: stickyColumnsArray.includes(
                                            column?.key
                                          )
                                            ? "sticky"
                                            : "",

                                          left: getLeftPosition(column?.key),
                                          zIndex: stickyColumnsArray.includes(
                                            column?.key
                                          )
                                            ? 2
                                            : "",
                                          overflowX: "auto",
                                          cursor:
                                            isGlobalSearch &&
                                            hashMarkColumns?.length &&
                                            hashMarkColumns?.includes(
                                              column?.key
                                            )
                                              ? "pointer"
                                              : "",
                                        }}
                                        onClick={() => {
                                          if (
                                            isGlobalSearch &&
                                            hashMarkColumns?.length &&
                                            hashMarkColumns?.includes(
                                              column?.key
                                            )
                                          ) {
                                            if (
                                              availableCountry &&
                                              availableCountry?.length > 0 &&
                                              availableCountry.filter(
                                                (e) =>
                                                  e.country.toUpperCase() ===
                                                  currentCountry.toUpperCase()
                                              )[0]
                                            ) {
                                              handleOpenModel(
                                                false,
                                                row[column?.key],
                                                currentCountry?.toUpperCase(),
                                                countryDetails?.tradeType,
                                                countryDetails?.bl_flag,
                                                countryDetails?.dateRange
                                              );
                                            } else {
                                              handleOpenModel(
                                                true,
                                                row[column?.key],
                                                currentCountry?.toUpperCase(),
                                                countryDetails?.tradeType,
                                                countryDetails?.bl_flag,
                                                countryDetails?.dateRange
                                              );
                                            }
                                          }
                                        }}
                                      >
                                        {isGlobalSearch ? (
                                          hashMarkColumns?.length &&
                                          hashMarkColumns?.includes(
                                            column?.key
                                          ) ? (
                                            "#########"
                                          ) : (
                                            value
                                          )
                                        ) : isExplore &&
                                          tableEnum?.COPY_ENABLE_FIELDS.includes(
                                            column?.key
                                          ) ? (
                                          <>
                                            {value}{" "}
                                            <ContentCopyIcon
                                              onClick={() => handleCopy(value)}
                                              style={{
                                                cursor: "pointer",
                                                fontSize: "1.1rem",
                                                color: "#005d91",
                                              }}
                                            />
                                          </>
                                        ) : companyDetailTable ? (
                                          value ? (
                                            value
                                          ) : (
                                            "-"
                                          )
                                        ) : (
                                          value
                                        )}
                                      </TableCell>
                                    </>
                                  );
                                }
                              }
                          }
                        }
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>

        <div
          style={{
            display: "flex",
            boxShadow: "0 1px 2px rgba(0, 0, 0, 0.2)",
            alignItems: "center",
          }}
        >
          {showPagination === true && (
            <>
              {!isFromLinkedInPage && (
                <TablePagionationDropdown
                  handleChange={handleChangeRowsPerPage}
                  value={rowsPerPage}
                />
              )}
              {isFromActivity && totalAvailableData > 0 && (
                <MuiPagination
                  count={Math?.ceil(totalAvailableData / rowsPerPage)}
                  page={Number(page) + 1}
                  onChange={handleChangePage}
                />
              )}
              {!isFromActivity && totalAvailableData > 0 && (
                <Pagination
                  className="pagination-bar"
                  currentPage={Number(page) + 1}
                  totalCount={Number(totalAvailableData)}
                  pageSize={rowsPerPage}
                  onPageChange={(page) => {
                    setLoading(true);
                    setTimeout(() => {
                      handleChangePage(page);
                      setLoading(false);
                    }, 1000);
                  }}
                  isUnique={isUnique}
                  loadMore={handleChangePage}
                />
              )}
            </>
          )}
        </div>
      </Paper>
    );
  }
);

export default DataTable;
