import "./login.scss";
import React, { useState } from "react";
import {
  fetchPopUps,
  getLoginDetails,
  getUserSession,
} from "../../Services/service";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import Loading from "../../Component/SuccessLoader/success";
import Error from "../../Component/ErrorLoader/error";
import LoginPassword from "../../Component/LoginPasswordComponent/loginPassword";
import ErrorData from "../../utils/ErrorHandling";
import { AES } from "crypto-js";
import { logger } from "../../Loggers/Loggers";
import { useAppContext } from "../../Contexts/AppContext";

// .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/, 'Password must contain alphabets, numbers and one or more special character/s and must have 8 characters')
const DisplayingErrorMessagesSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email")
    .required("This value is required."),
  password: Yup.string().required("This value is required."),
});
let loginDetails = {};

const Login = ({ setDefaultValue }) => {
  const secretKey = process.env.REACT_APP_CRYPTO_JS_KEY;
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const [error, seterror] = useState(false);
  const [errorMsg, seterrorMsg] = useState({});
  const { setIsPlanExpire, setPopOpen, setPopUpApiContent } = useAppContext();
  function login(values) {
    setloading(true);
    loginDetails.email_id = values.email;
    loginDetails.password = values.password;
    getUserSession(loginDetails)
      .then((res) => {
        if (!res?.data?.isSessionFlag) {
          userLogin();
        } else {
          setloading(false);
          seterror(true);
          seterrorMsg({
            title: "Warning",
            content: ErrorData?.SESSION_ACTIVATE,
          });
        }
      })
      .catch((err) => {
        const errorForLogger = `Login :- ${
          err?.message ? err?.message : err?.msg
        }`;
        logger(errorForLogger);
        setloading(false);
        seterror(true);
        seterrorMsg({
          title: "Error",
          content: ErrorData.SOMETHING_WENT_WRONG,
        });
      });
  }

  const userLogin = () => {
    getLoginDetails(loginDetails)
      .then((accountLoginBundlePack) => {
        if (!accountLoginBundlePack?.data) {
          setTimeout(() => {
            setloading(false);
            seterror(true);
            seterrorMsg({
              title: "Server Time OUT",
              content:
                "Something went wrong ! Please check your internet connection or contact Support",
              open: true,
            });
          }, 1000);
        } else if (accountLoginBundlePack?.data?.type === "MATCHED") {
          if (
            accountLoginBundlePack?.data?.isactive ||
            accountLoginBundlePack?.data?.isextended
          ) {
            seterror(false);
            setloading(false);
            document.cookie = `accountId=${accountLoginBundlePack.data.account_id};path=/;`;
            document.cookie = `user_id=${accountLoginBundlePack.data.user_id};path=/;`;
            document.cookie = `firstName=${accountLoginBundlePack.data.firstName};path=/;`;
            document.cookie = `auth=${true};path=/;`;
            // document.cookie = `token=${accountLoginBundlePack.data.token};path=/;`;
            document.cookie = `lastName=${accountLoginBundlePack.data.lastName};path=/;`;
            document.cookie = `email_id=${accountLoginBundlePack.data.email_id};path=/;`;
            document.cookie = `is_first_login=${accountLoginBundlePack.data.is_first_login};path=/;`;
            sessionStorage.setItem(
              "adxToken",
              JSON.stringify(accountLoginBundlePack.data.adxToken)
            );
            sessionStorage.setItem(
              "time",
              JSON.stringify(accountLoginBundlePack.data.time)
            );
            // const _isActive = accountLoginBundlePack?.data?.isactive;
            // const encryptedIsActive = AES.encrypt(
            //   _isActive.toString(),
            //   secretKey
            // ).toString();
            // localStorage.setItem(
            //   "isActive",
            //   JSON.stringify(encryptedIsActive) ?? false
            // );

            // const _isExtended = accountLoginBundlePack?.data?.isextended;
            // const encryptedIsExtended = AES.encrypt(
            //   _isExtended.toString(),
            //   secretKey
            // ).toString();
            // localStorage.setItem(
            //   "isExtended",
            //   JSON.stringify(encryptedIsExtended) ?? false
            // );

            const { isactive, isextended } = accountLoginBundlePack?.data;
            setIsPlanExpire({ isActive: isactive, isExtended: isextended });
            setDefaultValue(true);
            setTimeout(() => {
              navigate("/consumers/dashboard");
              setloading(false);
            }, 1000);
            handlePopApi();
          } else {
            setTimeout(() => {
              setloading(false);
              seterror(true);
              seterrorMsg({
                title: "Access Denied",
                content: "Your Plan has Expired,please contact administrator",
                open: true,
              });
            }, 1000);
          }
        } else {
          setTimeout(() => {
            setloading(false);
            seterror(true);
            seterrorMsg({
              title: accountLoginBundlePack?.data?.msg,
              content: accountLoginBundlePack?.data?.desc,
              open: true,
            });
          }, 1000);
        }
      })
      .catch((err) => {
        const errorForLogger = `Login :- ${
          err?.message ? err?.message : err?.msg
        }`;
        logger(errorForLogger);
        setloading(false);
        seterror(true);
        seterrorMsg({
          title: "Error",
          content: ErrorData.SOMETHING_WENT_WRONG,
        });
      });
  };

  const setErrorForPop = (err) => {
    const errorForLogger = `fetchPopNotification :- ${
      err?.status + " " + err?.statusText
    }`;
    logger(errorForLogger);
  };

  const handlePopApi = () => {
    fetchPopUps()
      .then((popRes) => {
        if (popRes?.status === 200) {
          const { accountPopups, generalPopups, userPopups } = popRes?.data;

          const mergedPopups = [
            ...(accountPopups || []).map((popup) => ({
              ...popup,
              popCategory: "accountPopup",
            })),
            ...(generalPopups || []).map((popup) => ({
              ...popup,
              popCategory: "generalPopup",
            })),
            ...(userPopups || []).map((popup) => ({
              ...popup,
              popCategory: "userPopup",
            })),
          ];

          setPopUpApiContent(mergedPopups);
          if (mergedPopups?.length) {
            setTimeout(() => {
              setPopOpen(true);
            }, 1500);
          }
        } else if (popRes?.status === 500 || popRes?.status === 401) {
          setErrorForPop(popRes);
        }
      })
      .catch((err) => {
        setErrorForPop(err);
      });
  };

  return (
    <div id="login-page">
      <div className="background-content">
        {error && (
          <Error
            errorMsg={errorMsg}
            open={error}
            setOpen={seterror}
            userLogin={userLogin}
            isAutoLogOutFlag={true}
          />
        )}
        {loading && (
          <Loading
            title={"Access Guard..."}
            content={"Verifying Account Login"}
          />
        )}
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={DisplayingErrorMessagesSchema}
          onSubmit={(values) => {
            login(values);
          }}
        >
          {({ errors, touched, values }) => (
            <LoginPassword
              values={values}
              login="true"
              text=" Enter your email address and password to access
          admin panel"
              subject1="Email Id"
              type1="email"
              name1="email"
              subject1Placeholder="Enter a valid e-mail"
              touched1={touched.email}
              errors1={errors.email}
              subject2="Password"
              type2="password"
              name2="password"
              subject2Placeholder="Password"
              touched2={touched.password}
              errors2={errors.password}
              buttonText="Log In"
            />
          )}
        </Formik>
      </div>
    </div>
  );
};
export default Login;
