import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import "./PasswordStrength.scss";

const List = () => {};

function PasswordStrength(props) {
  const isNumberRegx = /\d/;
  const specialCharacterRegx = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
  const capitalLetterRegx = /[A-Z]/;
  const [validateStrength, setvalidateStrength] = useState({
    passwordLength: false,
    capitalLetter: false,
    specialCharacter: false,
    containsNumber: false,
  });
  useEffect(() => {
    let value = props.values.password;
    setvalidateStrength({
      passwordLength: value.length > 7 && value.length <= 16 ? true : false,
      capitalLetter: capitalLetterRegx.test(value) ? true : false,
      specialCharacter: specialCharacterRegx.test(value) ? true : false,
      containsNumber: isNumberRegx.test(value) ? true : false,
    });
  }, [props.values]);
  return (
    <>
      <div className="password-main">
        {validateStrength?.passwordLength ? (
          <CheckCircleIcon className="error-icons text-success" />
        ) : (
          <ErrorOutlineIcon className="error-icons text-danger" />
        )}
        Password must contain 8 to 16 characters only
      </div>
      <div className="password-main">
        {validateStrength?.capitalLetter ? (
          <CheckCircleIcon className="error-icons text-success" />
        ) : (
          <ErrorOutlineIcon className="error-icons text-danger" />
        )}
        Password must contain at least 1 capital letter
      </div>
      <div className="password-main">
        {validateStrength?.specialCharacter ? (
          <CheckCircleIcon className="error-icons text-success" />
        ) : (
          <ErrorOutlineIcon className="error-icons text-danger" />
        )}
        Password must contain at least 1 special character
      </div>
      <div className="password-main">
        {validateStrength?.containsNumber ? (
          <CheckCircleIcon className="error-icons text-success" />
        ) : (
          <ErrorOutlineIcon className="error-icons text-danger" />
        )}
        Password must contain at least 1 number
      </div>
    </>
  );
}

export default PasswordStrength;
