import React from "react";
import { Box, Button, Typography } from "@mui/material";

export const NotificationHeader = ({ icon, title }) => (
  <Box className="custom-pop-header">
    {icon ?? ""}
    <Typography variant="h6" className="custom-pop-title">
      {title ?? ""}
    </Typography>
  </Box>
);

export const NotificationActions = ({
  primaryButton,
  handlePopUpUpdate,
  popValue,
  buttonSize,
  fontSize,
  isSingleNotification,
  handleRenewBtnAction,
}) => (
  <Box className="custom-pop-actions">
    <Button
      variant="text"
      className="custom-pop-dont-show-btn"
      onClick={() =>
        handlePopUpUpdate(popValue, "noShow", isSingleNotification)
      }
      size={buttonSize}
    >
      Don't show again
    </Button>
    <Box sx={{ display: "flex", alignItems: "end" }}>
      <Button
        variant="text"
        className="custom-pop-secondary-btn"
        sx={{ fontSize: fontSize }}
        onClick={() =>
          handlePopUpUpdate(popValue, "later", isSingleNotification)
        }
        size={buttonSize}
      >
        Remind me later
      </Button>
      {primaryButton && (
        <Button
          variant="contained"
          className="custom-pop-primary-btn"
          size="small"
          sx={{ fontSize: fontSize }}
          onClick={handleRenewBtnAction}
        >
          {primaryButton}
        </Button>
      )}
    </Box>
  </Box>
);

export const SupportMail = ({ className }) => (
  <Typography variant="body1" className={className}>
    eximpedia support :{" "}
    <span>
      <a
        href="mailto:support@eximpedia.app"
        style={{ color: "#005D91 !important" }}
      >
        support@eximpedia.app
      </a>
    </span>
  </Typography>
);
