import React, { useEffect, useState } from "react";
import {
  getAccountDetails,
  getAccountSubscriptions,
} from "../../Services/service";
import SubscriptionTable from "./Componant/SubscriptionTable";
import { Container } from "@mui/material";
import { Button } from "@mui/material";
import { experimentalStyled as styled } from "@mui/material/styles";
import Loading from "../../Component/SuccessLoader/success";
import Error from "../../Component/ErrorLoader/error";
import throwError from "../../utils/throwError";
import ErrorData from "../../utils/ErrorHandling";
import { LOADING } from "../../utils/LoadingMsg";
import StickyTable from "../../Component/Table/Table";
import { SubscriptionsTableHeader } from "./Subscriptions.enum";

export default function Subscription(props) {
  const [loading, setLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState({});
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState({});
  const [subscriptionData, setSubscriptionData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalAvailableData, setTotalAvailableData] = useState(0);
  const [accountDetails, setAccountDetails] = useState({});
  const [Page, setPage] = useState(0);
  const [offset, setOffset] = useState(0);
  useEffect(() => {
    getUserDetails();
    getSubscriptionData(0, 10);
  }, []);
  const setErrorForDashboard = (err) => {
    setLoading(false);
    setError(true);
    setErrorMsg({
      title: err.status === 401 ? "Warning" : "Error",
      content:
        err.status === 500
          ? ErrorData.INTERNAL_SERVER
          : err.status === 401
          ? err.msg
          : ErrorData.SOMETHING_WENT_WRONG,
    });
  };
  const getUserDetails = () => {
    getAccountDetails(props?.CLIENT_ACCOUNT_ID)
      .then((successPayload) => {
        if (successPayload?.status === 500 || successPayload?.status === 401) {
          throw throwError(successPayload);
        } else {
          // setLoading(false);
          let _accountDetails = [];
          if (successPayload?.data) {
            _accountDetails.push(successPayload?.data);
            setAccountDetails(_accountDetails);
          } else {
            throw new Error();
          }
        }
      })
      .catch((err) => {
        setErrorForDashboard(err);
      });
  };
  function sanitizeDate(value) {
    let date = new Date(value);
    return `${date.toLocaleString("default", {
      month: "short",
    })} ${date.getDate()} ${date.getFullYear()}`;
  }

  const createSubscriptionTableData = (data, id) => {
    return {
      id: id,
      type: data?.subscription?.detail?.type,
      price: data?.subscription?.detail?.price?.amount,
      validity:
        sanitizeDate(
          data?.subscription?.meta?.access_validity_interval?.start_date
        ) +
        "-" +
        sanitizeDate(
          data?.subscription?.meta?.access_validity_interval?.end_date
        ),
      data_range:
        sanitizeDate(
          data?.subscription?.meta?.data_availability_interval?.start_date
        ) +
        "-" +
        sanitizeDate(
          data?.subscription?.meta?.data_availability_interval?.end_date
        ),
      points: data?.subscription?.detail?.purchase_points,
      max_users: data?.subscription?.detail?.max_users,
    };
  };
  const getSubscriptionData = (offset, limit) => {
    setLoading(true);
    setLoadingMsg(LOADING);
    getAccountSubscriptions(props?.CLIENT_ACCOUNT_ID)
      .then((e) => {
        if (e?.status === 500 || e?.status === 401) {
          throw throwError(e);
        } else {
          if (e?.data) {
            setLoading(false);
            const _subscriptionData = [];
            e?.data?.forEach((ele, index) => {
              let obj = createSubscriptionTableData(ele, index + 1);
              _subscriptionData.push(obj);
            });
            setSubscriptionData(_subscriptionData);
            setTotalAvailableData(e?.recordsTotal);
          } else {
            throw new Error();
          }
        }
      })
      .catch((err) => {
        setErrorForDashboard(err);
      });
  };

  const handleChangePage = (newPage) => {
    let start = newPage - 1 * rowsPerPage;
    setOffset(start);
    setPage(newPage - 1);
    getSubscriptionData(start, rowsPerPage);
  };

  const handleChangeRowsPerPage = (value) => {
    setRowsPerPage(value);
    getSubscriptionData(offset, value);
  };

  return (
    <Container maxWidth="xxl">
      {loading && (
        <Loading title={loadingMsg.title} content={loadingMsg.content} />
      )}
      {error && <Error open={error} setOpen={setError} errorMsg={errorMsg} />}
      <div style={{ display: "flex", padding: "20px 8px" }}>
        <div className="row-dashbored">
          <h4 className="text">
            Manage
            <span className="badge bg-soft-blue text-blue label-worksapce-name">
              Subscriptions
            </span>
          </h4>
        </div>
      </div>
      <StickyTable
        columns={SubscriptionsTableHeader}
        rows={subscriptionData}
        is_active={accountDetails[0]?.is_active}
        rowsPerPage={rowsPerPage}
        page={Page}
        totalAvailableData={totalAvailableData}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        pagination={true}
      />
    </Container>
  );
}
