import React, { useEffect, useRef, useState } from "react";

const SelectDropDown = ({
  options = [],
  value,
  onChange,
  name,
  styleIndex,
  isDisabled,
  widthStyle,
  labelName,
  color,
  height,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(value);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    if (isDisabled) return;
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option, name) => {
    setSelectedValue(option.value);
    onChange({ target: { ...option, name } });
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  useEffect(() => {
    setSelectedValue(options.find((opt) => opt.value === value)?.title);
  }, [selectedValue, value]);

  useEffect(() => {
    // Add event listener to close dropdown when clicking outside
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div
      className="custom-select"
      ref={dropdownRef}
      style={{
        width: widthStyle ? widthStyle : "",
      }}
    >
      <div
        className="select-header"
        onClick={toggleDropdown}
        style={{ height: height ? height : "" }}
      >
        <span
          className="selected-header-value"
          style={{
            color: isDisabled ? "#999999" : color,
            width: widthStyle ? widthStyle : "",
          }}
        >
          {value ? selectedValue : labelName ? labelName : "Select an option"}
        </span>
        <span id={`arrow`} className={`${isOpen ? "open" : ""}`}></span>
      </div>
      {isOpen && (
        <ul
          className="select-options-ul"
          style={{
            zIndex: styleIndex.isEnabled && styleIndex.value,
            width: widthStyle ? widthStyle : "",
          }}
        >
          {options.map((option, index) => (
            <li
              key={index}
              className={`select-option-li ${
                selectedValue === option.value ? "selected" : ""
              }`}
              onClick={() => {
                handleOptionClick(option, name);
              }}
            >
              {option.title}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SelectDropDown;
