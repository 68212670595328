import React, { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Navbar from "../Navbar/Navbar";

export default function Protected(props) {
  function getCookie(cName) {
    const name = cName + "=";
    const cDecoded = decodeURIComponent(document.cookie); //to be careful
    const cArr = cDecoded.split("; ");
    let res;
    cArr.forEach((val) => {
      if (val.indexOf(name) === 0) res = val.substring(name.length);
    });
    return res;
  }

  const CLIENT_USER_ID = Cookies.get("user_id");
  const CLIENT_ACCOUNT_ID = Cookies.get("accountId");
  const CLIENT_FIRSTNAME = Cookies.get("firstName");
  const CLIENT_LASTNAME = Cookies.get("lastName");
  const CLIENT_EMAIL_ID = Cookies.get("email_id");

  const Cmp = props.cmp;
  const token = getCookie("auth");

  // useEffect(() => {
  //   const { isPlanExpire } = useAppContext();
  //   if (!isPlanExpire.isActive || !isPlanExpire.isExpire) {
  //     navigate("/consumers/logout");
  //   }
  //   // getLocalStorageData("isActive", "isExtended");
  // }, []);

  // const navigate = useNavigate();

  // const getLocalStorageData = (key, key2) => {
  //   const encryptedIsActive = JSON.parse(localStorage.getItem(key));
  //   const encryptedIsActive2 = JSON.parse(localStorage.getItem(key2));
  //   if (!encryptedIsActive || !encryptedIsActive2) {
  //     navigate("/consumers/logout");
  //   }
  // };
  return (
    <div>
      {/* if use localStorage then token !== null */}
      {token !== undefined || props?.isFromAutoLogOut ? (
        <>
          {props.route === "*" ? (
            <Cmp
              token={token}
              CLIENT_USER_ID={CLIENT_USER_ID}
              CLIENT_ACCOUNT_ID={CLIENT_ACCOUNT_ID}
              CLIENT_FIRSTNAME={CLIENT_FIRSTNAME}
              CLIENT_LASTNAME={CLIENT_LASTNAME}
              CLIENT_EMAIL_ID={CLIENT_EMAIL_ID}
            />
          ) : props.route === "/consumers/logout" ? (
            <Cmp
              token={token}
              CLIENT_USER_ID={CLIENT_USER_ID}
              CLIENT_ACCOUNT_ID={CLIENT_ACCOUNT_ID}
              CLIENT_FIRSTNAME={CLIENT_FIRSTNAME}
              CLIENT_LASTNAME={CLIENT_LASTNAME}
              CLIENT_EMAIL_ID={CLIENT_EMAIL_ID}
            />
          ) : (
            <Navbar>
              <Cmp
                isPlanExpire={props?.isPlanExpire}
                token={token}
                CLIENT_USER_ID={CLIENT_USER_ID}
                CLIENT_ACCOUNT_ID={CLIENT_ACCOUNT_ID}
                CLIENT_FIRSTNAME={CLIENT_FIRSTNAME}
                CLIENT_LASTNAME={CLIENT_LASTNAME}
                CLIENT_EMAIL_ID={CLIENT_EMAIL_ID}
                isExplore={
                  props?.route === "/countries/shipments/explorer" ||
                  props?.route === "/workspace/shipments/analysis" ||
                  props?.route === "/consumers/mirrorData"
                    ? true
                    : false
                }
              />
            </Navbar>
          )}
        </>
      ) : (
        <Navigate to="/consumers/login"></Navigate>
      )}
    </div>
  );
}
