import axios from "axios";

const urlHost = process.env.REACT_APP_BASE_LIVE;

export const axiosPostWithoutAuth = async (url, data) => {
  const mainUrl = urlHost + url;
  const response = await executeAPI("post", mainUrl, data);
  return response;
};

export const axiosGetWithoutAuth = async (url) => {
  const mainUrl = urlHost + url;
  const response = await executeAPI("get", mainUrl);
  return response;
};

export const axiosPutWithoutAuth = async (url, data) => {
  const mainUrl = urlHost + url;
  const response = await executeAPI("put", mainUrl, data);
  return response;
};

export const axiosDeleteWithoutAuth = async (url) => {
  const mainUrl = urlHost + url;
  const response = await executeAPI("delete", mainUrl);
  return response;
};

export const axiosGetWithoutAuthWithoutHost = async (url) => {
  const response = await executeAPI("get", url);
  return response;
};

const executeAPI = async (method, url, data = {}) => {
  let response = null;

  const axiosPayload = {
    method,
    url,
    data,
  };
  try {
    response = await axios(axiosPayload);
  } catch (error) {
    response = error.response;
  }
  return response;
};
