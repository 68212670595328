import Add_Users from "../../Asset/content/Add_Users.png";
import Advance_Filters from "../../Asset/content/Advance_Filters.png";
import Favorite_Panel from "../../Asset/content/Favorite_Panel.png";
import Filters from "../../Asset/content/Filters.png";
import Save_Query from "../../Asset/content/Save_Query.png";
import Switch_Btw_Countries from "../../Asset/content/Switch_Btw_Countries.png";
import View_Columns from "../../Asset/content/View_Columns.png";
import View_Details from "../../Asset/content/View_Details.png";
import View_Summary_and_Print from "../../Asset/content/View_Summary_and_Print.png";
import Analyze from "../../Asset/content/Analyze.png";

export const videoContent = {
  content_01_Activation: ` <div class="training-activation-tutorial styled-div">
  <h1 class="training-title">Activation</h1>
  <h2>How to Activate Your Account?</h2>
  <b><p class="font-size text-indent">
      This Eximpedia Activation Tutorial is your go-to guide for unlocking the full potential of your account. Activate now and elevate your trade experience with Eximpedia!
  </p></b>

  <h2>Activate Your Eximpedia Account in 6 Easy Steps!</h2>

 
  <div class="training-activation-step">
      <p class="font-size">Step 1: Check the email received from "Do-Not-Reply@eximpedia.app."</p>
  </div>

  <div class="training-activation-step">
      <p class="font-size">Step 2: Find the activation link inside.</p>
  </div>

  <div class="training-activation-step">
      <p class="font-size">Step 3: Click the link to access your personalized activation process.</p>
  </div>

  <div class="training-activation-step">
      <p class="font-size">Step 4: Follow the on-screen instructions to activate your account.</p>
  </div>

  <div class="training-activation-step">
      <p class="font-size">Step 5: Enhance security by changing your password as prompted.</p>
  </div>

  <div class="training-activation-step">
      <p class="font-size">Step 6: Activate your Account.</p>
  </div>

  <p class="font-size">Don't miss out on the opportunities waiting for you – Activate your Eximpedia account hassle-free!</p>

  

  <p class="font-size">
      Disclaimer: Please check your spam folder if the email is not received in your inbox.
  </p>
</div>
`,
  content_02_User_Dashboard: `     <div class="training-user-dashboard-container styled-div">
  <h1 class="training-title">User Dashboard</h1>

  <h2>
      What is a User Dashboard?
  </h2>
  <h2>
  Description:
  </h2>

 <b><p class="font-size text-indent">
      Welcome to Eximpedia's user-friendly dashboard – Once you log in to your account, the
      dashboard provides a snapshot of crucial information, including plan details, subscription details, expiry details,
      and more.
  </p></b>

  <h2>
    Key Features of Eximpedia's User Dashboard in 10 Easy Steps
  </h2>

  <ol>
     <div>
      <p class="font-size"><strong class="letter-spacing">Total Users:</strong> It shows the total active user count for your account, including admins.</p>
      </div>
      <div>
      <p class="font-size"><strong class="letter-spacing">Available Countries:</strong> Select custom or predefined countries for your data analysis.</p>
      </div>
      <div>
      <p class="font-size"><strong class="letter-spacing">Available Data Range:</strong> Analyze historical data within the specified timeframe.</p>
      </div>
      <div>
      <p class="font-size"><strong class="letter-spacing">Records Purchased:</strong> Track and export your data usage for the purchased period.</p>
      </div>
      <div>
      <p class="font-size"><strong class="letter-spacing">Available Credit Points:</strong> Monitor your remaining credit balance for data access.</p>
      </div>
      <div>
      <p class="font-size"><strong class="letter-spacing">SP Custom:</strong> Review and manage your current subscription plan details.</p>
      </div>
      <div>
      <p class="font-size"><strong class="letter-spacing">Total Workspaces: </strong>Organize and manage your data effectively through workspaces.</p>
      </div>
      <div>
      <p class="font-size"><strong class="letter-spacing">Subscription Expiration Date: </strong>Keep track of your subscription expiry date.</p>
      </div>
      <div>
      <p class="font-size"><strong class="letter-spacing">Saved Query:</strong> Optimize your workflow by saving frequently used queries.</p>
      </div>
  </ol>

  <p class="font-size">
      In essence, the Eximpedia User Dashboard is more than just a tool. If you need further clarification, you may watch the full tutorial 
  </p>

 
</div>`,
  content_03_Admin_Owner: `     <div class="training-admin-owner-container styled-div">
<h1 class="training-title">Admin Owner</h1>
<h2>Description:</h2>
<b><p class="font-size text-indent">
   Welcome to Eximpedia's easy-to-use dashboard – your go-to spot for managing clients
  and accessing data effortlessly. This guide will walk you through the myriad features of your admin owner.
</p></b>

  <h2>Features of Eximpedia's Admin Owner Panel</h2>

<ol>
<div>
  <li class="font-size">
      <strong class="letter-spacing">Account Overview:</strong>
      <ul class="bulletpoints">
          <li class="font-size">Click on the "Account" option to access a detailed account overview.</li>
          <li class="font-size">Monitor countries and check the credit points associated with your account.</li><br/>
          <li class="font-size">Edit user profiles or update company details effortlessly.</li>
      </ul>
  </li>
  </div><br/>
  <div>
  <li class="font-size">
      <strong class="letter-spacing">Subscription:</strong>
      <ul>
          <li class="font-size">Navigate to the "Subscription" panel for a clear plan breakdown.</li>
          <li class="font-size">Understand the plan features and any additional information related to your subscription.</li>
      </ul>
  </li>
  </div><br/>
<div>

  <li class="font-size">
      <strong class="letter-spacing">Activity Tracking:</strong>
      <ul>
          <li class="font-size">Explore the "Activity Tracker" to review all past searches within your account.</li>
          <li class="font-size">Customize the view based on your preferred date range to gain insights into your search history.</li>
      </ul>
  </li>

</div><br/>
<div>

  <li class="font-size">
      <strong class="letter-spacing">Ticket Management:</strong>
      <ul>
          <li class="font-size">Raise a ticket for technical or sales queries, input remarks, and submit.</li>
          <li class="font-size">Expect a swift response from our dedicated team to address your concerns promptly.</li>
      </ul>
  </li>
  </div><br/>
</ol>

<p class="font-size">
  If you need more information, you may watch the complete tutorial and become a dashboard pro in minutes by following tutorial.
</p>

</div>`,
  content_04_Add_User: `<div class="training-add-users-container styled-div">
  <h1 class="training-title">Add Users</h1>
  <h2>How to Create and Modify Users?</h2>
  <h2>Description:</h2>
  <b><p class="font-size text-indent">
       Welcome to Eximpedia's user-friendly dashboard. This tutorial will demonstrate how
      to create and manage users, including adding, deleting, and modifying their roles and credit points.
  </p></b>

      <h2>Easy Steps to Create and Modify Users</h2>

  <ol>
  
      <li class="font-size">Navigate to "User Management" section within the Eximpedia dashboard.</li><br/>
      <li class="font-size">
          Click "Add User" button to open the user creation form.
          <div class="training-step-image">
              <img id="training-content-image" src=${Add_Users}>
          </div>  
      </li><br/>
      <li class="font-size">Provide the user's name, last name, email address, mobile number, and role.</li><br/>
      <li class="font-size">Assign a specific number of credit points to the user.</li><br/>
      <li class="font-size">Choose the countries for which the user will have access to data.</li><br/>
      <li class="font-size">Select the role as “Moderator” for user and “Support” for admin.</li><br/>
      <li class="font-size">Click the "Save" button to create the user and send an activation email.</li><br/>
      <li class="font-size">To modify an existing user, locate the user in the list and click the "Edit" button.</li><br/>
      <li class="font-size">Make the necessary changes to the user's information and click on the "Save" button.</li><br/>
      <li class="font-size">To remove a user, select the "Delete" button next to the user's entry.</li><br/>
  </ol>

  <p class="font-size">
      Eximpedia's user management feature allows you to add, modify, and delete users, ensuring that your team has the
      appropriate access to the data they need. If you need more clarification, you may watch the full video.
  </p>
  
</div>
`,
  content_05_Advance_Filter: `   <div class="advanced-filters-tutorial styled-div">
<h1 class='training-title'>Advance Filters</h1>
<h2>How to Apply Advanced Filters in the Data?</h2>
<h2>Description:</h2>
<b><p class="font-size text-indent">
  Welcome to Eximpedia,This guide will demonstrate the Advanced Filters feature, enabling you to refine your search results using specific criteria.
</p></b>

<h2>Easy Steps to Apply Advanced Filters in the Data</h2>

<div class="advanced-filters-step">
  <p class="font-size">Step 1: Click the "+" symbol next to the search bar to open the Advanced Filters panel.</p>
   
  <img id="training-content-image"
      src=${Advance_Filters}
      alt="Advance Filters"
      class="advanced-filters-image"
  />
</div>

<div class="advanced-filters-step">
  <p class="font-size">Step 2: Select the desired parameter, such as company name, from the dropdown menu.</p>
  
</div>

<div class="advanced-filters-step">
  <p class="font-size">Step 3: Type the relevant search terms in the corresponding field.</p>
  
</div>

<div class="advanced-filters-step">
  <p class="font-size">Step 4: Click "Apply" button to refine your search results based on the specified criteria.</p>
  
</div>

<div class="advanced-filters-step">
  <p class="font-size">Step 5: To clear the applied filters and return to the unfiltered data, click the "Clear Filters" button.</p>
  
</div>

<p class="font-size">
  Eximpedia's Advanced Filters feature empowers you to pinpoint specific data points, saving you time and effort in your search process.
</p>


</div>`,
  content_06_Filter: `       <div class="filters-tutorial styled-div">
  <h1 class='training-title'>Filter</h1>
  <h2>How to Use Filters?</h2>
  <h2>Description:</h2>
  <b><p class="font-size text-indent">
      Welcome to Eximpedia ,  Where your trade journey is transformed with powerful filters. From HSN codes to country specifics and revenue details, our intuitive platform lets you effortlessly customize and explore. This tutorial will help you understand how to use filters in the dashboard.
  </p></b>

  <h2>Steps to Use Filters</h2>

 
  <div class="filters-step">
      <p class="font-size">Step 1: After your initial search, head to the left panel for a range of basic filters.</p>
  </div>

  <div class="filters-step">
      <p class="font-size">Step 2: Click the left panel for HSN codes, countries, and revenue details.</p>
      <div class="training-step-image">
              <img id="training-content-image" src=${Filters}>
          </div> 
  </div>

  <div class="filters-step">
      <p class="font-size">Step 3: Customize filters effortlessly to suit your preferences.</p>
  </div>

  <div class="filters-step">
      <p class="font-size">Step 4: Easily navigate foreign ports, uncovering quantities and prices.</p>
  </div>

  <div class="filters-step">
      <p class="font-size">Step 5: Dive into monthly filters for quantities or prices.</p>
  </div>

  <div class="filters-step">
      <p class="font-size">Step 6: Streamline data with a few clicks for quick insights.</p>
  </div>

  <div class="filters-step">
      <p class="font-size">Step 7: Experience the future of trade data exploration with Eximpedia.</p>
  </div>

  <p class="font-size">
      Transform the way you explore trade data and unlock insights tailored to your business needs.
  </p>

</div>`,
  content_07_Switch_Btw_Countries: ` <div class="switch-between-countries-tutorial styled-div">
<h1 class='training-title'>Switch Btw Countries</h1>
<h2>How do you switch the trade from the dashboard?</h2>
<h2>Description:</h2>
<b><p class="font-size text-indent">
 Welcome to Eximpedia,Your one-stop shop for all things import and export. This instruction manual will demonstrate how to switch between trade types (Export or Import) and filter data by country directly from the dashboard.
</p></b>

<h2>Key Features</h2>

<ul>
  <li class="font-size"><strong class="letter-spacing">Easily switch between Export and Import data:</strong> Toggle between trade types without navigating multiple menus.</li><br/><br/>
  <li class="font-size"><strong class="letter-spacing">Filter data by country:</strong> Narrow your search to specific countries within the dashboard.</li>
</ul>

<h2>Step-by-Step Guide</h2>

<div class="switch-step">
  <p class="font-size">Step 1: Locate the "Trade" option on the dashboard.</p>
  <img id="training-content-image"
      src=${Switch_Btw_Countries}
      alt="Trade Option"
      class="switch-image"
  />
</div>

<div class="switch-step">
  <p class="font-size">Step 2: Click on "Import" or "Export" to view the corresponding data.</p>
</div>

<div class="switch-step">
  <p class="font-size">Step 3: Choose the desired country from the available options.</p>
</div>

<p class="font-size">
  Eximpedia's user-friendly interface lets you quickly switch between trade types and filter data by country, ensuring a streamlined and efficient search experience.
</p>


</div>`,
  content_08_View_Details: `     <div class="view-details-tutorial styled-div">
<h1 class='training-title'>View Details (Eye Icon)</h1>
<h2>Eye icon Specification</h2>
<h2>Description:</h2>
<b><p class="font-size text-indent">
  Welcome to Eximpedia, This will show you how to use our Eye icon specification to view detailed information about specific shipments, mark shipments and organizations as favorites, and access online company information.
</p></b>

<h2>How to Access Icon Specification?</h2>

<div class="view-details-step">
  <p class="font-size">Step 1: Click on the Eye icon.</p>
  <img id="training-content-image" src=${View_Details}  alt="View Details"/>
</div>

<div class="view-details-step">
  <p class="font-size">Step 2: View specific shipment details by clicking Eye icon next to the shipment entry.</p>
</div>

<div class="view-details-step">
  <p class="font-size">Step 3: Click "star sign" and mark shipments and organizations as favorites.</p>
</div>

<div class="view-details-step">
  <p class="font-size">Step 4: Similarly add the shipment to your favorites list, where you can easily access it later.</p>
</div>

<div class="view-details-step">
  <p class="font-size">Step 5: Receive updates about favorite organizations.</p>
</div>

<div class="view-details-step">
  <p class="font-size">Step 6: Click Google toggle button next to organization's name, to find company detail on the web.</p>
</div>

<p class="font-size">
  If you need more clarification, you may watch the full video.
</p>


</div>`,
  content_09_Favorite_Panel: `     <div class="training-favorite-panel-tutorial styled-div">
<h1 class='training-title'>Favorite Panel</h1>
<h2>How to Use Favorite Panel Option?</h2>
<h2>Description:</h2>
<b><p class="font-size text-indent">
  Welcome to Eximpedia, This tutorial guides you through exploring all the companies or shipments you've marked as favorites.
</p></b>

<h2>Step-by-Step Guide for Favorite Panel</h2>

<div class="training-favorite-panel-step">
  <p class="font-size">Step 1: Navigate to the "Favorites" section on the Eximpedia dashboard.</p>
  <img id="training-content-image"
      src=${Favorite_Panel}
      alt="Favorites Section"
      class="training-favorite-panel-image"
  />
</div>

<div class="training-favorite-panel-step">
  <p class="font-size">Step 2: Choose between "Favorite Company" or "Favorite Shipment" from the dropdown menu.</p>
</div>

<div class="training-favorite-panel-step">
  <p class="font-size">Step 3: Access your preferred companies or shipments, neatly organized by country, in the Favorites panel.</p>
</div>

<div class="training-favorite-panel-step">
  <p class="font-size">Step 4: View the latest data for analysis by selecting the "Run" button next to the chosen entry.</p>
</div>

<p class="font-size">
  Eximpedia's Favorites feature simplifies your experience, allowing you to swiftly access and analyze preferred companies or shipments.
</p>


</div>`,
  content_10_Request_Buyer: `   <div class="request-buyer-tutorial styled-div">
<h1 class='training-title'>Request Buyers</h1>
<h2>How to Use the Request Buyer Option? </h2>
<h2>Description:</h2>
<b><p class="font-size text-indent">
  Welcome to Eximpedia, In this section, we will show you how to use our Request Buyer option to fetch information about an unknown buyer (To the order).
</p></b>

<h2>Easy Steps to Use the Request Buyer Option</h2>

<div class="request-buyer-step">
  <p class="font-size">Step 1: Check your plan for Request Buyer allocations count.</p>
</div>

<div class="request-buyer-step">
  <p class="font-size">Step 2: Use Request Buyer for missing consignee names.</p>
</div>

<div class="request-buyer-step">
  <p class="font-size">Step 3: You can utilize LC transactions with buyer names as to the order or bank names.</p>
  
</div>

<div class="request-buyer-step">
  <p class="font-size">Step 4: Click on the Request Buyer button.</p>
  
</div>

<div class="request-buyer-step">
  <p class="font-size">Step 5: Gathering Request Buyer detail will take around 7-8 working days.</p>
  
</div>

<div class="request-buyer-step">
  <p class="font-size">Step 6: View requested buyer detail in Requested Buyer panel.</p>
  
</div>

<p class="font-size">
  The Request Buyer option is a valuable tool for importers and exporters who need to track down unknown buyers.
</p>

 

</div>`,
  content_11_View_Summary: ` <div class="view-summary-tutorial styled-div">
<h1 class='training-title'>View Summary and Print</h1>
<h2>Description:</h2>
<b><p class="font-size text-indent">
  Welcome to Eximpedia, This section will show you how to use the View Summary feature to view essential data, navigate between entries, print specific entries, and access company briefings and analysis.
</p></b>

<h2>Eximpedia's View Summary: A Step-by-Step Guide</h2>

<div class="view-summary-step">
  <p class="font-size">Step 1: Navigate through essential details, including HS code, country, Indian port, and price.</p>
  
</div>

<div class="view-summary-step">
  <p class="font-size">Step 2: Utilize "next" or "previous" buttons to move between entries.</p>
   
  <img id="training-content-image"
      src=${View_Summary_and_Print}
      alt="Next Previous Buttons"
      class="view-summary-image"
  />
</div>

<div class="view-summary-step">
  <p class="font-size">Step 3: Print specific entries as PDF files using the "print" button.</p>
  
</div>

<div class="view-summary-step">
  <p class="font-size">Step 4: Access detailed company information by clicking the "View Summary" button.</p>
  
</div>

<div class="view-summary-step">
  <p class="font-size">Step 5: Download charts and data in various formats (PNG, JPEG, PDF, SVG) for further analysis.</p>
  
</div>

<div class="view-summary-step">
  <p class="font-size">Step 6: Explore exporters' buyers or buyers' suppliers by clicking on respective links.</p>
  
</div>

<p class="font-size">
  The View Summary feature proves invaluable for importers and exporters seeking swift access and analysis of essential data related to companies and their transactions.
</p>

 

</div>`,
  content_12_View_Columns: ` <div class="view-columns-tutorial styled-div">
<h1 class='training-title'>View Columns</h1>
<h2>How to Access View Column Panel?</h2>
<h2>Description:</h2>
<b><p class="font-size text-indent">
  Welcome to Eximpedia, In this tutorial, we will demonstrate the View Columns panel, which allows you to customize the data displayed in your dashboard.
</p></b>

<h2>5 Easy Steps to Access View Column Panel</h2>

<div class="view-columns-step">
  <p class="font-size">Step 1: Access View Columns:</p>
   
  <img id="training-content-image"
      src=${View_Columns}
      alt="View Columns Button"
      class="view-columns-image"
  />
</div>

<div class="view-columns-step">
  <p class="font-size">Step 2: Select columns:</p>
  
</div>

<div class="view-columns-step">
  <p class="font-size">Step 3: Apply filter:</p>
  
</div>

<div class="view-columns-step">
  <p class="font-size">Step 4: View customized data:</p>
  
</div>

<div class="view-columns-step">
  <p class="font-size">Step 5: Reset column selection:</p>
  
</div>

<p class="font-size">
  The View Columns panel provides a convenient way to personalize your Eximpedia experience, ensuring you see only the data most relevant to your needs.
</p>

 

</div>`,
  content_13_Analyze: `<div class="advanced-filters-tutorial styled-div">
<h1 class="training-title"> Analyze </h1>
<h2>How does the Analyze Option work?</h2>
<h2>Description:</h2>

<b><p class="font-size text-indent">Welcome to Eximpedia's easy-to-use dashboard.This tutorial is designed to guide you through the functionality of the Analyze feature, enabling you to obtain a comprehensive overview of a company's import and export activities.</p></b>

<h2 >Eximpedia's Analyze Feature: A Step-by-Step Guide</h2>

<ol>
    <li class="font-size">Click on the “Analyze Panel”</li><br/>
    <li class="font-size">Customize your analysis with all the 7 options that are available there.</li><br/>
    <li class="font-size">The analysis will get created for the data that you have chosen.</li><br/>
    <li class="font-size">Tailor the analysis to your needs by selecting the columns containing the desired information.</li><br/>
    <li class="font-size">Review the analyzed data in a table format.</li><br/>
    <li class="font-size">Click on a specific exporter to explore the list of companies they supply to (buyers).</li><br/>
    <li class="font-size">Download the analyzed data conveniently in an Excel file, free of cost without fear of any point deduction.</li><br/>
    <li class="font-size">Delve into the list of companies and examine their import and export details.</li><br/>
</ol>

<img id="training-content-image" src=${Analyze} alt="Your Image Description" class="">

<p class="font-size">Eximpedia's analyze feature provides a powerful tool for importers and exporters to gain insights into a company's trade activities. For a more in-depth understanding, you may watch the full video. </p>
</div>
`,
  content_14_Dashboard: `      <div class="dashboard-tutorial styled-div">
<h1 class='training-title'>Dashboard </h1>
<h2>How to Access Dashboard?</h2>
<h2>Description:</h2>
<b><p class="font-size text-indent">
  Welcome to Eximpedia, This section will help you explore the Dashboard feature, a powerful tool for analyzing import and export data based on count, quantity, or price. The analysis is based on your primary search result.
</p></b>

<h2>Key Features:</h2>
<ul>
  <li class="font-size"><strong class="letter-spacing">Analyze data by count, quantity, or price: </strong>Choose the parameter that best suits your analysis.</li><br/><br/>
  <li class="font-size"><strong class="letter-spacing">View buyer and exporter counts:</strong> Identify the top 10 buyers and exporters relevant to the product or service under analysis.</li><br/><br/>
  <li class="font-size"><strong class="letter-spacing">Explore revenue by country and port:</strong> Gain insights into revenue generated from different countries and ports.</li><br/><br/>
  <li class="font-size"><strong class="letter-spacing">Download data for further analysis: </strong>Export report to CSV format for more in-depth analysis, absolutely free of cost.</li>
</ul>

<h2>Eximpedia's Dashboard: A Step-by-Step Guide</h2>

<div class="dashboard-step">
  <p class="font-size">Step 1: Click on "Dashboard" button to open analysis panel.</p>
  
</div>

<div class="dashboard-step">
  <p class="font-size">Step 2: Choose between "Count", "Quantity" or "Price" as per your requirement.</p>
  
</div>

<div class="dashboard-step">
  <p class="font-size">Step 3: Review detailed charts and graphs representing various analysis.</p>
  
</div>

<div class="dashboard-step">
  <p class="font-size">Step 4: Click on a specific port to filter the analysis data accordingly.</p>
  
</div>

<div class="dashboard-step">
  <p class="font-size">Step 5: Export data for further analysis by clicking the "Download as CSV" button.</p>
  
</div>

<div class="dashboard-step">
  <p class="font-size">Step 6: Seamlessly switch between different views, including "Top Buyers," "Top Exporters," "HSN Codes," "Indian Ports," "Foreign Ports," "Units," and "Cumulative Value."</p>
  
</div>

<p class="font-size">
  For additional clarification, you can watch the comprehensive tutorial video by following the link provided below:
</p>

 

</div>`,
  content_15_Manage_Workspace: `      <div class="workspace-tutorial styled-div">
<h1 class='training-title'>Manage Workspace and Download Data: </h1>
<h2>How to Create a Workspace?</h2>
<h2>Description:</h2>
<b><p class="font-size text-indent">
  Welcome to Eximpedia,This tutorial will delve into the Workspace feature, offering you the capability to save, organize, and efficiently access your search results for easy retrieval and data download.
</p></b>

<h2>Key Features of Workspace</h2>

<ul>
  <li class="font-size"><strong class="letter-spacing">Create and manage workspaces:</strong> Store your searched data for future reference.</li><br/><br/>
  <li class="font-size"><strong class="letter-spacing">Download data in Excel format: </strong>Export your saved data into Excel files for further analysis.</li><br/><br/>
  <li class="font-size"><strong class="letter-spacing">Share workspaces with other users:</strong> Foster collaboration by sharing your workspaces with team members.</li>
</ul>

<h2>How to Download Data from the Dashboard?</h2>

<div class="workspace-step">
  <p class="font-size">Step 1: Search the desired EXIM records.</p>
  
</div>

<div class="workspace-step">
  <p class="font-size">Step 2: You can create a workspace and download it partially or the entire search result.</p>
  
</div>

<div class="workspace-step">
  <p class="font-size">Step 3: Click the <strong class="letter-spacing">"Add All Records to Workspace"</strong> button to download all visible records.</p>
  
</div>

<div class="workspace-step">
  <p class="font-size">Step 4: To save only selected data, check the boxes next to the desired records and click <strong class="letter-spacing">"Add Selected Records to Workspace."</strong></p>
  
</div>

<div class="workspace-step">
  <p class="font-size">Step 5: Select an existing workspace or create a new one by providing a name.</p>
  
</div>

<div class="workspace-step">
  <p class="font-size">Step 6: Review the credit point deduction and click <strong class="letter-spacing">"OK"</strong> to initiate the download.</p>
  
</div>

<div class="workspace-step">
  <p class="font-size">Step 7: View and download data from your workspaces.</p>
  
</div>

<div class="workspace-step">
  <p class="font-size">Step 8: Remove workspaces that are no longer needed (Optional).</p>
  
</div>

<div class="workspace-step">
  <p class="font-size">Step 9: Grant access to your workspaces to other users for collaborative efforts.</p>
  
</div>

<p class="font-size">
  The Workspace feature provides a convenient way to organize and access frequently used import and export data, enhancing the efficiency of your analysis and decision-making process.
</p>
</div>`,
  content_16_Save_Query: `<div class="save-query-tutorial styled-div">
<h1 class='training-title'>Save Query</h1>
<h2>How to use the Save Query Option?</h2>
<h2>Description:</h2>
<b><p class="font-size text-indent">
  Welcome to Eximpedia, This Eximpedia Tutorial will demonstrate the Saved Query feature, providing you with the capability to save frequently used search parameters for swift and convenient access.
</p></b>

<h2>Step-by-Step Guide for Save Query Option</h2>

<div class="save-query-step">
  <p class="font-size">Step 1: Click on the "Saved Query" button to open the panel.</p>
   
  <img id="training-content-image"
      src=${Save_Query}
      alt="Saved Query Button"
      class="save-query-image"
  />
</div>

<div class="save-query-step">
  <p class="font-size">Step 2: Give a name to your search like HS code or any other name.</p>
  
</div>

<div class="save-query-step">
  <p class="font-size">Step 3: Click the "Save" button to save the search parameters.</p>
  
</div>

<div class="save-query-step">
  <p class="font-size">Step 4: To modify an existing query, select it from the list and make the necessary changes.</p>
  
</div>

<div class="save-query-step">
  <p class="font-size">Step 5: Click on the saved query to instantly apply the saved search parameters.</p>
  
</div>

<p class="font-size">
  For additional clarification, you may watch the full video.
</p>

 

</div>
`,
  content_17_Market_Search_Panel: `       <div class="market-search-tutorial styled-div">
<h1 class='training-title'>Market Search Panel</h1>
<h2>How to Use Market Search Panel?</h2>
<h2>Description:</h2>
<b><p class="font-size text-indent">
  Welcome to Eximpedia, In this Instruction manual, we will help you explore the Market panel, a powerful tool for conducting market research and identifying potential business opportunities.
</p></b>

<h2>East Step-by-Step Guide for Market Search Panel</h2>

<div class="market-search-step">
  <p class="font-size">Step 1: Navigate to the "Market" section on the Eximpedia dashboard.</p>
  
</div>

<div class="market-search-step">
  <p class="font-size">Step 2: Choose your focus by selecting either "Country Search," "Company Search," or "Product Search" from the dropdown menu.</p>
  
</div>

<div class="market-search-step">
  <p class="font-size">Step 3: Opt for "Indian Import" or "Indian Export" and specify your target country.</p>
  
</div>


<div class="market-search-step">
  <p class="font-size">Step 4: Review a comprehensive list of companies, revenue, quantity, and shipments. Apply additional filters as necessary.</p>
  
</div>

<div class="market-search-step">
  <p class="font-size">Step 5: If required, export the data to Excel for further in-depth analysis.</p>
  
</div>

<p class="font-size">
  Eximpedia's Market panel empowers you to gain valuable insights into trade patterns, identify potential partners, and make informed business decisions.
</p>

 

</div>`,
  content_18_Global_Search: `    <div class="global-search-tutorial styled-div">
<h1 class='training-title'>Global Search</h1>
<h2>How to Use the Global Search Option??</h2>
<h2>Description:</h2>
<b><p class="font-size text-indent">
  Welcome to Eximpedia, This video tutorial will demonstrate the Global Search feature, enabling you to identify countries that import or export specific products based on HS codes.
</p></b>

<h2>Easy Steps to Use Global Search Option</h2>

<div class="global-search-step">
  <p class="font-size">Step 1: Navigate to the "Global Search" panel on the Eximpedia dashboard.</p>
  
</div>

<div class="global-search-step">
  <p class="font-size">Step 2: Enter the HS code or the product you are interested in.</p>
  
</div>

<div class="global-search-step">
  <p class="font-size">Step 3: Eximpedia will search its extensive database and display a list of countries that import or export the specified product.</p>
  
</div>

<div class="global-search-step">
  <p class="font-size">Step 4: Review the list of countries and their respective import or export volumes.</p>
  
</div>

<div class="global-search-step">
  <p class="font-size">Step 5: You can navigate to that country by going into all trade data and can lookout for detailed information of the same.</p>
  
</div>

<div class="global-search-step">
  <p class="font-size">Step 6: Apply filters based on HS code or data type to narrow down the results.</p>
  
</div>

<p class="font-size">
  Explore global trade effortlessly with Eximpedia's Global Search feature.
</p>

 

</div>`,
};
