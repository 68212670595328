import React, { memo, useState } from "react";
import { FormControl, MenuItem, Select } from "@mui/material";
import "./searchComponent.scss";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import SelectSearch from "react-select";
import { ButtonComponent } from "../ButtonComponent/Button";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddIcon from "@mui/icons-material/Add";
import { IsMultiSelectedField } from "../../utils/commanConditions";
import { QUERYTYPE } from "../../Containers/ExploreCountryShipmentNewUI/Explore.enum";
import DateRangePicker from "../DateRangePicker/DateRangePicker";
import { useEffect } from "react";
import SelectedCountryHoverCard from "../../Containers/MirrorData/Component/SelectedCountryHoverCard/SelectedCountryHoverCard";
import SelectDropDown from "../SelectDropDownNewUI/SelectDropDown";
let singleValueSelectInDropDown = [
  "IEC",
  "SHIPPER_CODE",
  "CONSIGNEE_CODE",
  "IMPORTER_ID",
  "EXPORTER_ID",
];
export const ADD_USER_SEARCH_IN_SEARCH_OPTIONS = [
  "PRODUCT_DESCRIPTION",
  "PRODUCT_DESC_EN",
  "PRODUCT_DESCRIPTION_EN",
  "PRODUCT_DESCRIPTION_NATIVE",
  "HS_CODE",
  "CMDT_CODE",
  "CUSTOMS_DESCRIPTION",
  "CONSIGNEE_NAME_NATIVE",
  "CONSIGNEE_NAME_EN",
  "SHIPPER_NAME_NATIVE",
  "SHIPPER_NAME_EN",
  "SHIPPER_NAME",
  "CONSIGNEE_NAME",
  "EXPORTER_NAME_EN",
  "EXPORTER_NAME",
  "BUYER_NAME_EN",
  "STD_EXPORTER_NAME",
  "BUYER_NAME",
  "STD_BUYER_NAME",
  "EXPORTER_NAME_VN",
  "IMPORTER_NAME",
  "EXPORTER_NAME_AND_ADDRESS",
  "SUPPLIER_NAME",
  "SUPPLIER_NAME_EN",
  "IMPORTER_NAME_EN",
  "STD_IMPORTER_NAME",
  "STD_SUPPLIER_NAME",
  "IMPORTER_NAME_VN",
  "BUYER_NAME_EN",
];
const SearchComponent = ({
  handleSelectChange,
  handleChangeForSelectedDropdownValue,
  advanceSearchFieldOptions,
  handleInputChangeForSearchTerm,
  searchField,
  setSearchField,
  reactSelectLoading,
  getSearchOption,
  getSelectedOptionForSearchTerm,
  addButton,
  advanceSearchComponent,
  currentCountry,
  isBlFlag,
  currentTradeType,
  selectTrade,
  onSearchClick,
  isSearchValueEmpty,
  isExplore,
  workSpaceBtnClass,
  setDateRange,
  dateRange,
  isWorkSpace,
  mirrorData,
  handleBlurSelectedOptions,
  handleCountryModalOpen,
  tooltipData,
  handleMouseEnterOnSelectedCountry,
  handleMouseLeaveOnSelectedCountry,
  showClass,
  showSelectedCountryModal,
}) => {
  const [getUniqueOption, setUniqueOption] = useState([]);

  const handleAddUniqueId = (options) => {
    return options.map((item, index) => ({
      _id: item?._id,
      key: `${item?._id}-${index}`,
    }));
  };

  const [groupedOptions, setGroupedOptions] = useState([]);

  useEffect(() => {
    // if (getSearchOption?.length > 0) {
    //   setGroupedOptions([
    //     {
    //       label: "Your Search",
    //       options: [
    //         { value: getSearchOption[0]._id, label: getSearchOption[0]._id },
    //       ],
    //     },
    //     {
    //       label: "Suggestions",
    //       options: getSearchOption
    //         .slice(1)
    //         .map((option) => ({ value: option?._id, label: option?._id })),
    //     },
    //   ]);
    // } else {
    //   setGroupedOptions([]);
    // }

    const data = handleAddUniqueId(getSearchOption);
    setUniqueOption(data);
  }, [getSearchOption]);

  const selectStyles = {
    singleValue: (base, state) => ({
      ...base,
      color: state.selectProps.menuIsOpen ? "transparent" : base.color,
    }),
    control: (base) => ({
      ...base,
      height: 42,
      minHeight: 42,
      whiteSpace: "nowrap",
    }),
    valueContainer: (base) => ({
      ...base,
      flexWrap: "nowrap",
    }),
  };

  const defaultSearchOptions = [{ value: "EXACT", title: "EXACT" }];

  const checkChooseSearchTermDisable = () => {
    let disabled = false;
    if (mirrorData || currentCountry === "SILKROUTE") {
      disabled = false;
    } else {
      disabled = isExplore
        ? currentTradeType !== selectTrade?.tradeType ||
          currentCountry !== selectTrade?.country ||
          selectTrade?.dataType !== isBlFlag
          ? true
          : false
        : false;
    }
    return disabled;
  };

  const checkBtnISDisable = () => {
    let disabled = false;
    if (mirrorData || currentCountry === "SILKROUTE") {
      disabled = false;
    } else {
      disabled = isExplore
        ? currentTradeType !== selectTrade?.tradeType ||
          currentCountry !== selectTrade?.country ||
          selectTrade?.dataType !== isBlFlag
          ? true
          : false
        : false;
    }
    return disabled;
  };

  const handleBlur = () => {
    if (isExplore) {
      handleBlurSelectedOptions();
    }
  };

  return (
    <div id="search-container-explore">
      <div className="search-container">
        {isWorkSpace && (
          <div className="search-item">
            <label className="search-item-label" style={{ marginLeft: "0px" }}>
              DateRange
            </label>
            <DateRangePicker
              dateRange={dateRange}
              setDateRange={setDateRange}
            />
          </div>
        )}
        <div className="search-item">
          <label
            className="search-item-label"
            style={{ marginLeft: "0px", marginBottom: "5px" }}
          >
            Search On
          </label>
          <SelectDropDown
            options={
              searchField?.searchOption?.length &&
              searchField?.searchOption?.map((item) => {
                item.title = item.field?.replace(/_/g, " ");
                item.value = item?.field;
                return item;
              })
            }
            value={
              Array.isArray(searchField?.getSelectExploreField)
                ? searchField?.getSelectExploreField[0]
                : searchField?.getSelectExploreField
            }
            onChange={handleSelectChange}
            name={"searchOn"}
            styleIndex={{ isEnabled: true, value: "11111" }}
          />
          {/* <FormControl
            sx={{
              m: 1,
              Width: "14vw",
              maxWidth: "14vw",
              minWidth: "14vw",
              marginLeft: "0px",
              marginTop: "5px",
            }}
            size="small"
          >
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={searchField?.getSelectExploreField}
              onChange={handleSelectChange}
            >
              {searchField?.searchOption?.length > 0 &&
                searchField?.searchOption?.map((e, index) => (
                  <MenuItem
                    alias={e?.alias}
                    query={e?.query}
                    identifier={e?.identifier}
                    template={e?.template}
                    value={e?.field}
                    key={index}
                  >
                    {e?.field?.split("_").join(" ")}
                  </MenuItem>
                ))}
            </Select>
          </FormControl> */}
        </div>
        <div className="search-item">
          <label className="search-item-label">Choose Search Term</label>
          {/* <SelectSearch
            styles={selectStyles}
            isClearable={true}
            isLoading={reactSelectLoading}
            cacheOptions
            closeMenuOnSelect={
              searchField?.getValuesForSelectDropdown?.query ===
                QUERYTYPE?.CHOOSE ||
              IsMultiSelectedField?.includes(
                searchField?.getValuesForSelectDropdown?.field
              ) ||
              (searchField?.getValuesForSelectDropdown?.field?.includes(
                "PROD"
              ) &&
                searchField?.getValuesForSelectDropdown?.field?.includes(
                  "DESC"
                ))
                ? false
                : true
            }
            defaultOptions={getSearchOption}
            value={getSelectedOptionForSearchTerm}
            getOptionLabel={(e) => e?._id}
            getOptionValue={(e) => e?._id}
            options={getSearchOption}
            onInputChange={(e, { action }) =>
              handleInputChangeForSearchTerm(e, action)
            }
            onChange={(e) =>
              handleChangeForSelectedDropdownValue(e, "search", "")
            }
            onBlur={handleBlur}
            isMulti={
              searchField?.getValuesForSelectDropdown?.query ===
                QUERYTYPE?.CHOOSE ||
              IsMultiSelectedField?.includes(
                searchField?.getValuesForSelectDropdown?.field
              ) ||
              (searchField?.getValuesForSelectDropdown?.field?.includes(
                "PROD"
              ) &&
                searchField?.getValuesForSelectDropdown?.field?.includes(
                  "DESC"
                ))
                ? true
                : false
            }
            className="search-form-control search-async"
            hideSelectedOptions={false}
            isDisabled={checkChooseSearchTermDisable()}
          /> */}

          {/* ---> this is grouped option dropdown  */}
          {/* <SelectSearch
            styles={selectStyles}
            isClearable={true}
            isLoading={reactSelectLoading}
            cacheOptions
            closeMenuOnSelect={
              searchField?.getValuesForSelectDropdown?.query ===
                QUERYTYPE?.CHOOSE ||
              IsMultiSelectedField?.includes(
                searchField?.getValuesForSelectDropdown?.field
              ) ||
              (searchField?.getValuesForSelectDropdown?.field?.includes(
                "PROD"
              ) &&
                searchField?.getValuesForSelectDropdown?.field?.includes(
                  "DESC"
                ))
                ? false
                : true
            }
            defaultOptions={
              ADD_USER_SEARCH_IN_SEARCH_OPTIONS.includes(
                searchField?.getSelectExploreField
              )
                ? groupedOptions
                : getSearchOption
            }
            value={
              Array.isArray(getSelectedOptionForSearchTerm)
                ? getSelectedOptionForSearchTerm.map((option) => ({
                    value: option?._id,
                    label: option?._id,
                  }))
                : getSelectedOptionForSearchTerm
            }
            getOptionLabel={(e) =>
              ADD_USER_SEARCH_IN_SEARCH_OPTIONS.includes(
                searchField?.getSelectExploreField
              )
                ? e?.label
                : e?._id
            }
            getOptionValue={(e) =>
              ADD_USER_SEARCH_IN_SEARCH_OPTIONS.includes(
                searchField?.getSelectExploreField
              )
                ? e?.value
                : e?._id
            }
            options={
              ADD_USER_SEARCH_IN_SEARCH_OPTIONS.includes(
                searchField?.getSelectExploreField
              )
                ? groupedOptions
                : getSearchOption
            }
            onInputChange={(e, { action }) =>
              handleInputChangeForSearchTerm(e, action)
            }
            onChange={(selectedOptions) =>
              ADD_USER_SEARCH_IN_SEARCH_OPTIONS.includes(
                searchField?.getSelectExploreField
              )
                ? handleChangeForSelectedDropdownValue(
                    selectedOptions?.map((option) => ({
                      _id: option?.value,
                    })),
                    "search",
                    ""
                  )
                : handleChangeForSelectedDropdownValue(
                    selectedOptions,
                    "search",
                    ""
                  )
            }
            onBlur={handleBlur}
            isMulti={
              searchField?.getValuesForSelectDropdown?.query ===
                QUERYTYPE?.CHOOSE ||
              IsMultiSelectedField?.includes(
                searchField?.getValuesForSelectDropdown?.field
              ) ||
              (searchField?.getValuesForSelectDropdown?.field?.includes(
                "PROD"
              ) &&
                searchField?.getValuesForSelectDropdown?.field?.includes(
                  "DESC"
                ))
                ? true
                : false
            }
            className="search-form-control search-async"
            hideSelectedOptions={false}
            isDisabled={checkChooseSearchTermDisable()}
          /> */}

          <SelectSearch
            styles={selectStyles}
            isClearable={true}
            isLoading={reactSelectLoading}
            cacheOptions
            closeMenuOnSelect={
              searchField?.getValuesForSelectDropdown?.query ===
                QUERYTYPE?.CHOOSE ||
              IsMultiSelectedField?.includes(
                searchField?.getValuesForSelectDropdown?.field
              ) ||
              (searchField?.getValuesForSelectDropdown?.field?.includes(
                "PROD"
              ) &&
                searchField?.getValuesForSelectDropdown?.field?.includes(
                  "DESC"
                ))
                ? false
                : true
            }
            defaultOptions={getUniqueOption}
            value={getSelectedOptionForSearchTerm}
            getOptionLabel={(option) => option?._id}
            getOptionValue={(option) => option?.key}
            options={getUniqueOption}
            onInputChange={(e, { action }) =>
              handleInputChangeForSearchTerm(e, action)
            }
            onChange={(selectedOptions) => {
              handleChangeForSelectedDropdownValue(
                selectedOptions?.map((option) => ({
                  _id: option?._id, // Keep `_id` as is for display
                  key: option?.key, // Keep `key` for uniqueness
                })),
                "search",
                ""
              );
            }}
            onBlur={handleBlur}
            isMulti={
              searchField?.getValuesForSelectDropdown?.query ===
                QUERYTYPE?.CHOOSE ||
              IsMultiSelectedField?.includes(
                searchField?.getValuesForSelectDropdown?.field
              ) ||
              (searchField?.getValuesForSelectDropdown?.field?.includes(
                "PROD"
              ) &&
                searchField?.getValuesForSelectDropdown?.field?.includes(
                  "DESC"
                ))
                ? true
                : false
            }
            className="search-form-control search-async"
            hideSelectedOptions={false}
            isDisabled={checkChooseSearchTermDisable()}
          />

          {isSearchValueEmpty.searchTerm &&
          (getSelectedOptionForSearchTerm === null ||
            (getSelectedOptionForSearchTerm &&
              getSelectedOptionForSearchTerm?.length === 0)) ? (
            <span
              style={{
                color: "#d32f2f",
                fontSize: "15px",
                letterSpacing: "1px",
                position: "relative",
                top: "0px",
                marginLeft: "10px",
              }}
            >
              This field is required
            </span>
          ) : (
            ""
          )}
        </div>

        <div className="search-item">
          <label className="search-item-label" style={{ marginBottom: "5px" }}>
            Operator
          </label>
          <SelectDropDown
            options={
              searchField?.operatorOptions?.length > 0
                ? searchField?.operatorOptions?.length &&
                  searchField?.operatorOptions?.map((item) => {
                    item.title = item?.value;
                    item.value = item?.value;
                    return item;
                  })
                : defaultSearchOptions?.map((item) => {
                    item.title = item.value;
                    item.value = item.value;
                    return item;
                  })
            }
            value={
              searchField?.operatorOptions?.length > 0
                ? searchField?.selectedOperator
                : "EXACT"
            }
            onChange={(e) =>
              setSearchField({
                ...searchField,
                selectedOperator: e?.target?.value,
              })
            }
            name={"selectedOperator"}
            isDisabled={searchField?.operatorOptions?.length > 1 ? false : true}
            styleIndex={{ isEnabled: true, value: "11111" }}
          />
          {/* <FormControl
            sx={{ m: 1, minWidth: "8vw", marginTop: "5px" }}
            size="small"
          >
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              size="small"
              value={
                searchField?.operatorOptions?.length > 0
                  ? searchField?.selectedOperator
                  : "EXACT"
              }
              disabled={
                searchField?.operatorOptions &&
                searchField?.operatorOptions?.length > 0
                  ? false
                  : true
              }
              onChange={(e) =>
                setSearchField({
                  ...searchField,
                  selectedOperator: e?.target?.value,
                })
              }
            >
              {searchField?.operatorOptions?.length > 0 &&
                searchField?.operatorOptions?.map((e, index) => (
                  <MenuItem value={e.value} key={index}>
                    {e.value}
                  </MenuItem>
                ))}
              {searchField?.operatorOptions?.length <= 0 &&
                defaultSearchOptions?.map((e, index) => (
                  <MenuItem value={e.value} key={index}>
                    {e.value}
                  </MenuItem>
                ))}
            </Select>
          </FormControl> */}
        </div>

        {/* MirrorData country selecter button and modal on hover  */}
        <div className="search-item tooltip-position-relative">
          {mirrorData && (
            <>
              <label className="search-item-label">Select Countries</label>
              <ButtonComponent
                style={{
                  marginTop: "5px",
                }}
                btnIcon={FilterAltOutlinedIcon}
                name="SELECT COUNTRY"
                btnClick={() => handleCountryModalOpen()}
                onMouseEnter={() => handleMouseEnterOnSelectedCountry()}
                onMouseLeave={() => handleMouseLeaveOnSelectedCountry()}
              />
            </>
          )}
          {showSelectedCountryModal && (
            <SelectedCountryHoverCard
              tooltipData={tooltipData}
              handleMouseEnterOnSelectedCountry={
                handleMouseEnterOnSelectedCountry
              }
              handleMouseLeaveOnSelectedCountry={
                handleMouseLeaveOnSelectedCountry
              }
              showClass={showClass}
            />
          )}
        </div>
        <div className={`search-item ${workSpaceBtnClass}`}>
          <label className="search-item-label">Actions</label>
          <div className="action-btn">
            {advanceSearchComponent?.length === 0 && (
              <>
                <ButtonComponent
                  name={"search"}
                  btnIcon={SearchIcon}
                  style={{ margin: "5px 8px 8px 8px " }}
                  btnClick={() => onSearchClick({}, false, false)}
                  disabled={checkBtnISDisable()}
                />
                {advanceSearchFieldOptions?.length > 0 && (
                  <ButtonComponent
                    name={""}
                    btnIcon={AddIcon}
                    style={{ margin: "5px 8px 8px 8px " }}
                    btnClick={addButton}
                    disabled={checkBtnISDisable()}
                  />
                )}
              </>
            )}
            <ButtonComponent
              name={""}
              btnIcon={RefreshIcon}
              style={{ margin: "5px 8px 8px 8px " }}
              btnClick={() => window.location.reload()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchComponent;
