import { memo, useCallback, useEffect, useMemo, useState } from "react";
import "./CustomDataTable.scss";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";

const CustomDataTable = ({
  data,
  header,
  pagination,
  // getContextMenuItems,
  // getMainMenuItems,
  handleSelectedShipments,
  handleDescendingAndAscendingOrder,
}) => {
  const [currentRowLength, setCurrentRowLength] = useState(25);
  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      sortable: true,
      // filter: "agTextColumnFilter",
      filter: true,
      //   floatingFilter: true,
      cellStyle: {
        display: "flex",
        alignItems: "center",
      },
    }),
    []
  );

  useEffect(() => {
    const handleScroll = () => {
      const headerElement = document.querySelector(".ag-header");
      const scrollPosition = window.scrollY;
      const mediaQuery = window.matchMedia("(min-width: 1600px)");
      if (headerElement) {
        if (
          (!mediaQuery.matches && scrollPosition >= 238) ||
          (mediaQuery.matches && scrollPosition >= 320)
        ) {
          headerElement.style.position = "fixed";
          headerElement.style.top = "80px";
          headerElement.style.zIndex = "10000";
        } else {
          headerElement.style.position = "";
          headerElement.style.top = "";
          headerElement.style.zIndex = "";
          headerElement.style.backgroundColor = "";
          headerElement.style.boxShadow = "";
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const gridOptions = useMemo(
    () => ({
      suppressCellFocus: true,
      suppressHeaderFocus: true,
      suppressCopyRowsToClipboard: true,
      suppressCopySingleCellRanges: true,
      suppressClipboardPaste: true,
      suppressAggFuncInHeader: true,
      suppressRowClickSelection: true,
      enableCellTextSelection: false,
      suppressClipboardApi: true,
      suppressContextMenu: true,
      // rowSelection: "multiple",
      // postSortRows: (params) => {
      //   console.log(params);
      // },
      getRowHeight: () => 50,
    }),
    []
  );

  const rowSelection = useMemo(() => {
    return {
      mode: "multiRow",
      checkboxes: false,
      headerCheckbox: false,
      enableClickSelection: false,
    };
  }, []);

  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          toolPanelParams: {
            suppressRowGroups: true, // to make disable this
            suppressValues: true, // to make disable this
            suppressPivots: true, // to make disable this
            suppressPivotMode: true, // to make disable this
            suppressColumnFilter: false,
            suppressColumnSelectAll: false,
            suppressColumnExpandAll: false,
          },
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
        },
        // {
        //   id: "customStats",
        //   labelDefault: "Custom Stats",
        //   labelKey: "customStats",
        //   iconKey: "custom-stats",
        //   toolPanel: CustomToolPanel,
        //   toolPanelParams: {
        //     title: "Custom Stats",
        //   },
        // },
      ],
      defaultToolPanel: "columns",
      // hiddenByDefault: true,
      position: "left",
    };
  }, []);

  const DataTable = useCallback(() => {
    return (
      <AgGridReact
        key={JSON.stringify(data[0])}
        rowData={data}
        defaultColDef={defaultColDef}
        columnDefs={header}
        onRowSelected={({ node }) => {
          const isSelected = node.isSelected();
          // console.log("🚀 ~ DataTable ~ isSelected:", isSelected, node);

          handleSelectedShipments(isSelected, node.data._id);

          // Optionally log the information for debugging
          // console.log(
          //   node.data._id,
          //   node.childIndex,
          //   isSelected,
          //   "Row Selection Changed"
          // );
        }}
        onSelectionChanged={({ api }) => {
          // const selectedRows = api.getSelectedRows();
          // selectedRows.map((row, i) => {
          //   return row._id;
          // });
          // console.log(selectedRows, "Selected Rows");
          // setSelectedData(api.getSelectedRows() || []);
        }}
        gridOptions={gridOptions}
        // sendToClipboard={(params) => {
        //   params.api.copySelectedRangeToClipboard();
        //   console.log(params);
        // }}
        // sideBar={sideBar}
        columnMenu={"legacy"}
        // allowContextMenuWithControlKey={true}
        // getContextMenuItems={getContextMenuItems}
        // getMainMenuItems={getMainMenuItems}
        pagination={pagination}
        paginationPageSize={25}
        paginationPageSizeSelector={[25, 50, 100]}
        animateRows={true}
        columnHoverHighlight={false}
        statusBar={true}
        onPaginationChanged={(params) => {
          const currentPageSize = params.api.paginationGetPageSize();
          setCurrentRowLength(currentPageSize);
        }}
        onSortChanged={(params) => {
          const { colId, sort } = params.columns[0];
          handleDescendingAndAscendingOrder(colId, sort ?? "default");
        }}
        rowSelection={rowSelection}
      />
    );
  }, [
    header,
    data,
    defaultColDef,
    gridOptions,
    pagination,
    rowSelection,
    currentRowLength,
    // getContextMenuItems,
    // getMainMenuItems,
    // sideBar,
  ]);

  return (
    <>
      <div
        className="ag-theme-quartz"
        style={{
          width: "100%",
          height:
            currentRowLength === 25
              ? "1370px"
              : currentRowLength === 50
              ? "2622px"
              : "5120px",
          flex: 1,

          transition: "all 0.5s ease",
        }}
      >
        {DataTable()}
      </div>
    </>
  );
};

export default memo(CustomDataTable);
