export const TABLE_HEADER_RECENT_ACTIVITY = [
  { key: "country", title: "Country" },
  { key: "tradeType", title: "TradeType" },
  { key: "query", title: "Query" },
  { key: "add", title: "Action" },
];

export const TABLE_HEADER_SAVE_QUERY = [
  {
    key: "title",
    title: "Title",
  },
  {
    key: "date",
    title: "Date",
  },
  {
    key: "country",
    title: "Country",
  },
  {
    key: "tradeType",
    title: "Trade Type",
  },
  {
    key: "action",
    title: "Action",
    from: "dashboard",
  },
];

export const COMPETITORS_TABLE_HEADER = [
  { key: "COMPANY", title: "Buyer Name" },
  { key: "TOTAL_VALUE", title: "Total Value" },
  { key: "Trade", title: "Trade Type" },
  { key: "Country", title: "Country" },
];

export const RECOMMENDED_TABLE_HEADER = [
  { key: "COMPANY", title: "Company" },
  { key: "TOTAL_VALUE", title: "Total value" },
  { key: "Trade", title: "TradeType" },
  { key: "Country", title: "Country" },
];

export const POTENTIAL_TABLE_HEADER = [
  { key: "COUNTRY", title: "Country" },
  { key: "HS_CODE", title: "Product" },
  { key: "TOTAL_VALUE", title: "Total Value" },
];

export const FAV_COMPANY_ANALYSIS_HEADER = [
  { key: "email_id", title: "Email Id" },
  { key: "role", title: "Role" },
  { key: "activity", title: "Activity" },
  { key: "country", title: "Country" },
  { key: "tradeType", title: "TradeType" },
  { key: "query", title: "Query" },
  { key: "tradeRecords", title: "TradeRecords" },
  { key: "points", title: "Deducted Points" },
  { key: "creditLimitDescription", title: "Credit Left" },
  { key: "queryResponseTime", title: "Query Time" },
  { key: "queryCreatedAt", title: "CreatedAt" },
];
