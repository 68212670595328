import React, { memo } from "react";
import "./CompareTableWithCollapse.scss";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  TableHead,
} from "@mui/material";
import Pagination from "../PaginationTest/Pagination";
import TablePagionationDropdown from "../TablePaginationDropdown/tablePagionationDropdown";
import moment from "moment/moment";
import millify from "millify";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {
  MarketCountryCollapseHeader2,
  MarketCountryHeader3,
} from "../../Containers/MarketPlace/MarketCountry/MarketCountry.enum";

const CompareTableWithCollapse = memo(
  ({
    tableHeight,
    height,
    showPagination,
    scroll,
    columns,
    isHeaderWrap,
    handleChangePage,
    page,
    totalAvailableData,
    rowsPerPage,
    handleChangeRowsPerPage,
    product,
    dateRange,
    rows,
    setCompanyData,
  }) => {
    const getDifference = (val, val1, className) => {
      let difference = ((val - val1) * 100) / (val + val1);
      return (
        <TableCell
          className={`border-bottom tableRowForDiff notranslate ${className}`}
          style={{
            fontWeight: "600",
            color:
              difference > 0
                ? "#1D9200"
                : difference === 0
                ? "#303a43"
                : "#FF0000",
          }}
        >
          {difference.toFixed(2)}
          {"%"}
        </TableCell>
      );
    };
    const onClickToggleBtn = (val) => {
      let data = [];
      rows &&
        rows?.length > 0 &&
        rows?.forEach((value) => {
          if (val?.country) {
            if (value?.country === val?.country) {
              let obj = value;
              obj.show = !val?.show;
              data.push(obj);
            } else {
              data.push(value);
            }
          } else if (val?.port) {
            if (value?.port === val?.port) {
              let obj = value;
              obj.show = !val?.show;
              data.push(obj);
            } else {
              data.push(value);
            }
          }
        });
      setCompanyData(data);
    };
    const getDate = (startDate, endDate) => {
      const startYear = moment(startDate).year();
      const startMonth = moment(startDate).format("MMM");
      const endYear = moment(endDate).year();
      const endMonth = moment(endDate).format("MMM");

      if (startYear === endYear) {
        return `${startMonth}-${endMonth} ${startYear}`;
      } else {
        return `${startMonth} ${startYear} - ${endMonth}  ${endYear}`;
      }
    };
    const getTableHeader = () => {
      return MarketCountryCollapseHeader2.map((e) => {
        if (e?.key === "date") {
          return (
            <TableCell
              className={`CompareTableWithCollapse-header ${e?.className}`}
              style={{
                textAlign: e?.textAlign ? e?.textAlign : "left",
                top: e?.top ? e?.top : "0",
              }}
            >
              {getDate(dateRange?.startMonthDate, dateRange?.endMonthDate)}
            </TableCell>
          );
        } else if (e?.key === "compareDate") {
          return (
            <TableCell
              className={`CompareTableWithCollapse-header ${e?.className}`}
              style={{
                textAlign: e?.textAlign ? e?.textAlign : "left",
                top: e?.top ? e?.top : "0",
              }}
            >
              {getDate(dateRange?.compareStartDate, dateRange?.compareEndDate)}
            </TableCell>
          );
        } else {
          return (
            <TableCell
              className={`CompareTableWithCollapse-header ${e?.className}`}
              style={{
                textAlign: e?.textAlign ? e?.textAlign : "left",
                top: e?.top ? e?.top : "0",
              }}
            >
              {e?.title}
            </TableCell>
          );
        }
      });
    };
    return (
      <>
        <Paper
          id="CompareTableWithCollapse"
          sx={{ width: "100%", backgroundColor: "none", boxShadow: "none" }}
        >
          <TableContainer sx={{ maxHeight: tableHeight ? tableHeight : 600 }}>
            <Table
              // scroll == true ? "tableHeight" : ""
              className={scroll === true ? "tableHeight" : ""}
              stickyHeader
              style={{ height: height, userSelect: "none" }}
              aria-label="sticky table"
              size="small"
            >
              <TableHead>
                <TableRow style={{ height: 50 }}>
                  {columns &&
                    columns?.length &&
                    columns?.length > 0 &&
                    columns?.map((val, index) => {
                      return (
                        <>
                          <TableCell
                            className={`CompareTableWithCollapse-header ${val?.className}`}
                            id={isHeaderWrap ? "no-wrap" : ""}
                            style={{
                              textAlign: val?.textAlign
                                ? val?.textAlign
                                : "left",
                            }}
                            rowSpan={val?.rowSpan ? val?.rowSpan : ""}
                            colSpan={val?.colSpan ? val?.colSpan : ""}
                          >
                            {val?.title}
                          </TableCell>
                        </>
                      );
                    })}
                </TableRow>
                <TableRow style={{ height: 50 }}>
                  {getTableHeader()}
                  {getTableHeader()}
                  {getTableHeader()}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows && rows?.length && rows?.length > 0 ? (
                  <>
                    {rows &&
                      rows?.map((obj, index) => {
                        return (
                          <>
                            <TableRow
                              tabIndex={-1}
                              onClick={() => onClickToggleBtn(obj)}
                            >
                              <TableCell
                                className="tableRowForDiff CompareTableWithCollapse-border-top-bottom"
                                colSpan="10"
                                style={{
                                  fontSize: "18px",
                                  textAlign: "left",
                                  cursor: "pointer",
                                  borderRight: "none",
                                  color: "#005D91 ",
                                  borderRadius: "10px 0px 0px 10px",
                                }}
                              >
                                {obj?.country}
                              </TableCell>
                              <TableCell
                                className="tableRowForDiff CompareTableWithCollapse-border-top-bottom"
                                colSpan="10"
                                style={{
                                  fontSize: "18px",
                                  textAlign: "center",
                                  cursor: "pointer",
                                  borderLeft: "none",
                                  color: "#005D91 ",
                                  borderRadius: "0px 10px 10px 0px",
                                }}
                              >
                                {obj?.show ? (
                                  <ExpandLessIcon />
                                ) : (
                                  <ExpandMoreIcon />
                                )}
                              </TableCell>
                            </TableRow>

                            {obj?.show &&
                              obj?.data &&
                              obj?.data?.length > 0 &&
                              obj?.data?.map((e) => {
                                return (
                                  <>
                                    <TableRow>
                                      {MarketCountryHeader3?.map((ele) => {
                                        if (ele?.key !== "difference") {
                                          return (
                                            <TableCell
                                              className={`tableRowForDiff notranslate ${ele?.className}`}
                                              style={{
                                                fontSize: "16px",
                                                textAlign: ele?.textAlign
                                                  ? ele?.textAlign
                                                  : "left",
                                              }}
                                            >
                                              {ele?.key !==
                                                "hsCode_description" &&
                                              ele?.key !== "hsCode"
                                                ? e[ele?.key] === undefined ||
                                                  e[ele?.key] === "NULL"
                                                  ? 0
                                                  : millify(e[ele?.key])
                                                : e[ele?.key]}
                                            </TableCell>
                                          );
                                        } else {
                                          return getDifference(
                                            e[ele?.title1] ? e[ele?.title1] : 0,
                                            e[ele?.title2] ? e[ele?.title2] : 0
                                          );
                                        }
                                      })}
                                    </TableRow>
                                  </>
                                );
                              })}
                          </>
                        );
                      })}
                  </>
                ) : (
                  <>
                    <TableRow>
                      <TableCell
                        className="scroll  tableRowForDiff"
                        colSpan="12"
                        style={{
                          fontWeight: "600",
                          textAlign: "center",
                          fontSize: "20px",
                        }}
                      >
                        No Record Found
                      </TableCell>
                    </TableRow>
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <div
            style={{
              display: "flex",
              boxShadow: "0 1px 2px rgba(0, 0, 0, 0.2)",
            }}
          >
            <div>
              {showPagination === true && (
                <TablePagionationDropdown
                  handleChange={handleChangeRowsPerPage}
                  value={rowsPerPage}
                />
              )}
            </div>
            {showPagination === true && totalAvailableData > 0 ? (
              <Pagination
                className="pagination-bar"
                currentPage={Number(page) + 1}
                totalCount={Number(totalAvailableData)}
                pageSize={rowsPerPage}
                onPageChange={(page) => handleChangePage(page)}
              />
            ) : (
              ""
            )}
          </div>
        </Paper>
      </>
    );
  }
);

export default CompareTableWithCollapse;
