import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import ErrorPopup from "../../../Component/ErrorLoader/ErrorPopup";

import MarketCountry from "./MarketCountry";
import { logger } from "../../../Loggers/Loggers";

const DiffCountryWithError = () => {
  function ErrorHandler({ error }) {
    logger(`MarketPlace CountrySearch ${error?.message}`);
    return (
      <div role="alert">
        {error && (
          <ErrorPopup
            errorMsg={"Something Went Wrong , Please Login Again"}
            open={error}
          />
        )}
      </div>
    );
  }
  return (
    <>
      <ErrorBoundary FallbackComponent={ErrorHandler}>
        <MarketCountry />
      </ErrorBoundary>
    </>
  );
};

export default DiffCountryWithError;
