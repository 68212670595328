import React, { memo, useEffect } from "react";
import StyledTreeItem from "./Component/StyledTreeItem/StyledTreeItem";
import {
  Box,
  Button,
  FormControlLabel,
  InputBase,
  Slide,
  TextField,
  Typography,
  Zoom,
} from "@mui/material";
import { alpha, experimentalStyled as styled } from "@mui/material/styles";
import RefreshIcon from "@mui/icons-material/Refresh";
import { FILTER_OPTIONS } from "../../Containers/ExploreCountryShipmentNewUI/Explore.enum";
import { TreeItem, TreeView } from "@mui/lab";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import BpCheckbox from "../CheckBox/BpCheckBox";
import SearchIcon from "@mui/icons-material/Search";
import "../../Component/SearchComponent/search.scss";
import SelectDropdown from "../SelectDropDown/selectDropdown";
import "./CollapseFilter.scss";
import Slider from "../RangeSlider/slider";
import millify from "millify";
import { useState } from "react";
import { BtnGreen, ButtonComponent } from "../ButtonComponent/Button";
import {
  getMonthName,
  getMonthNumber,
  translateButtonClick,
} from "../../utils/CommanFunction";
import ViewMoreModal from "./Component/ViewMore/ViewMore";
import valueFilter from "../../utils/ValueFiltersOptions";
import ShipmentFilter from "../../utils/ShipmentFilterOptions";
import { Remove_Order_Or_Null_Option } from "../../utils/DropDownOptions";
import ErrorLoading from "../../Component/ErrorLoader/error";
import { SearchRequireWarning } from "../../utils/LoadingMsg";
import { FixedSizeList } from "react-window";
const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: "9px",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#CED4DA",
}));
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
  },
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: "40px",
    transition: theme.transitions.create("width"),
    width: "100%",
    color: "#6C757D",
    border: "1px solid #CED4DA",
    textOverflow: "ellipsis",
    [theme.breakpoints.up("sm")]: {
      width: "21ch",
      height: "20px",
    },
  },
}));
export const _headerTitle_countryAndPort = [
  "FILTER_COUNTRY",
  "FILTER_PORT",
  "FILTER_FOREIGN_PORT",
  "FILTER_INDIAN_PORT",
  "FILTER_DESTINATION_COUNTRY",
  "FILTER_DESTINATION_PORT",
  "FILTER_ORIGIN_COUNTRY",
  "FILTER_ORIGIN_PORT",
  "FILTER_REPORTING_COUNTRY",
  "FILTER_STATE",
  "FILTER_PIN",
];

export const _headerTitle_importAndExport = [
  "FILTER_IMPORTER",
  "FILTER_EXPORTER",
];

const CollapseFilter = memo(
  ({
    isFiltersShown,
    collapseAllFilters,
    keyValuePairForTreeview,
    allFilterValuesWithCountsArr,
    headerTitle,
    isFromExplore,
    appliedFilterComp,
    filterOptionClick,
    getFilterValues,
    getAllFilterValues,
    setFilterValue,
    CheckedData,
    currentCountry,
    rangeValue,
    getMaxMinRange,
    setRangeValue,
    selectedQuantityOrPriceValue,
    setSelectedQuantityOrPrice,
    setMaxMinRange,
    lastSelected,
    setLastSelected,
    storeFilteredValues,
    setStoreFilterValues,
    handleApplyFilter,
    appliedFilterData,
    setPairForTreeView,
    isFilterLoader,
    isFromMarket,
    isMarketCompany,
    isMarketProduct,
    isGlobalSearch,
    currentTradeType,
    isFromWorkspace,
    mirrorData,
    getSelectedOptionForSearchTerm,
    importerExporterName,
  }) => {
    const keyArr = keyValuePairForTreeview[headerTitle];
    const [currentSelectedQuantity, setCurrentSelectedQuantity] = useState("");
    const [storeFilteredValuesChild, setStoreFilteredValuesChild] = useState(
      {}
    );
    const [searchCountryOrPort, setCountryOrPort] = useState("");
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [showItems, setshowItems] = useState(10);
    const [errorMsg, setErrorMsg] = useState({});
    const [error, setError] = useState(false);
    useEffect(() => {
      handleApplyFilter();
    }, [storeFilteredValues]);

    useEffect(() => {
      setStoreFilteredValuesChild(appliedFilterData);
    }, [appliedFilterData]);
    const handleApply = () => {
      let last = lastSelected;
      if (last && !last?.includes(currentSelectedQuantity)) {
        last.push(currentSelectedQuantity);
        setLastSelected(last);
      }

      setStoreFilterValues(storeFilteredValuesChild);
      if (storeFilteredValuesChild === storeFilteredValues) {
        handleApplyFilter();
      }
      setIsOpenModal(false);
    };

    const RenderApplyBtn = (props) => {
      return (
        <Box
          className={
            props.class_name ? `${props.class_name} btn-parent` : "btn-parent"
          }
          sx={{ mt: 1 }}
        >
          <BtnGreen
            name={"Apply"}
            style={{ width: "70%", fontSize: "18px" }}
            btnClick={handleApply}
            className="btn-background-green"
          />

          <ButtonComponent
            name={""}
            btnIcon={RefreshIcon}
            style={{ margin: "0px 0px 0px 8px " }}
          />
        </Box>
      );
    };
    const renderApplyBtnForViewMore = () => {
      return (
        <Box className="btn-parent" sx={{ mt: 1 }}>
          <BtnGreen
            name={"Apply"}
            btnClick={handleApply}
            style={{ width: "30%", fontSize: "18px" }}
            className="btn-background-green"
          />

          <ButtonComponent
            name={""}
            btnIcon={RefreshIcon}
            style={{ margin: "0px 0px 0px 8px " }}
          />
        </Box>
      );
    };
    const getChildValue = (data) => {
      let a = data?.find((e) => e.isChecked === false);
      return a ? false : true;
    };
    const getHacodeValue = (data) => {
      if (headerTitle === FILTER_OPTIONS?.MONTH) {
        let a = {};
        data?.length > 0 &&
          data?.forEach((parent) => {
            parent?.children?.length &&
              parent?.children?.forEach((e) => {
                if (e?.isChecked) {
                  let month = getMonthNumber(e?._id);
                  let str = `${parent?._id}-${month}`;
                  a[str] = e?.isChecked;
                }
              });
          });
        return a;
      } else if (headerTitle === FILTER_OPTIONS?.HS_CODE) {
        let a = {};
        data?.length > 0 &&
          data?.forEach((parent) => {
            parent?.children &&
              parent?.children?.length > 0 &&
              parent?.children?.forEach((child) => {
                child?.children &&
                  child?.children?.length > 0 &&
                  child?.children?.forEach((subChild) => {
                    if (subChild?.isChecked) {
                      a[subChild._id] = subChild?.isChecked;
                    }
                  });
              });
          });
        return a;
      } else {
        let a = {};
        data?.length > 0 &&
          data?.forEach((parent) => {
            parent?.children &&
              parent?.children?.length > 0 &&
              parent?.children?.forEach((child) => {
                if (child?.isChecked) {
                  a[child?._id] = child?.isChecked;
                }
              });
          });
        return a;
      }
    };
    const handleCheckedInput = (
      e,
      isChildCheckAll,
      isParentCheckAll,
      parentId,
      childId
    ) => {
      if (
        isFromWorkspace ||
        isFromMarket ||
        isMarketCompany ||
        isMarketProduct ||
        isGlobalSearch ||
        currentCountry === "SILKROUTE"
      ) {
        const keyArr = keyValuePairForTreeview[headerTitle];
        let arr =
          keyArr &&
          keyArr?.length > 0 &&
          keyArr?.map((ele) => {
            if (isParentCheckAll) {
              if (ele?._id === e?.target?.value) {
                ele?.children?.length > 0 &&
                  ele?.children?.map((child) => {
                    child.isChecked = !ele?.isChecked;
                    child?.children &&
                      child?.children?.length > 0 &&
                      child?.children?.map((subChild) => {
                        subChild.isChecked = !ele?.isChecked;
                        return subChild;
                      });
                    return child;
                  });
                ele.isChecked = !ele?.isChecked;

                return ele;
              } else {
                return ele;
              }
            } else if (isChildCheckAll) {
              if (ele?._id === parentId) {
                ele?.children &&
                  ele?.children?.length > 0 &&
                  ele?.children?.map((child) => {
                    if (child?._id === e?.target?.value) {
                      child?.children &&
                        child?.children?.length > 0 &&
                        child?.children?.map((subChild) => {
                          subChild.isChecked = !child?.isChecked;
                          return subChild;
                        });
                      child.isChecked = !child?.isChecked;
                      return child;
                    } else {
                      return child;
                    }
                  });
                ele.isChecked = getChildValue(ele?.children);
                return ele;
              } else {
                return ele;
              }
            } else {
              if (ele?._id === parentId) {
                ele?.children &&
                  ele?.children?.length > 0 &&
                  ele?.children?.map((child) => {
                    if (child?._id === childId) {
                      child?.children &&
                        child?.children?.length > 0 &&
                        child?.children?.map((subChild) => {
                          if (subChild?._id === e?.target?.value) {
                            subChild.isChecked = !subChild?.isChecked;
                            return subChild;
                          } else {
                            return subChild;
                          }
                        });
                      child.isChecked = getChildValue(child?.children);
                      return child;
                    } else {
                      return child;
                    }
                  });
                ele.isChecked = getChildValue(ele?.children) ? true : false;
                return ele;
              } else {
                return ele;
              }
            }
          });
        setPairForTreeView({
          ...keyValuePairForTreeview,
          [headerTitle]: arr,
        });
        let _storeFilteredValues = { ...storeFilteredValuesChild };
        _storeFilteredValues[headerTitle] = getHacodeValue(arr);
        setStoreFilteredValuesChild(_storeFilteredValues);
      } else {
        if (
          getSelectedOptionForSearchTerm &&
          getSelectedOptionForSearchTerm.length > 0
        ) {
          const keyArr = keyValuePairForTreeview[headerTitle];
          let arr =
            keyArr &&
            keyArr?.length > 0 &&
            keyArr?.map((ele) => {
              if (isParentCheckAll) {
                if (ele?._id === e?.target?.value) {
                  ele?.children?.length > 0 &&
                    ele?.children?.map((child) => {
                      child.isChecked = !ele?.isChecked;
                      child?.children &&
                        child?.children?.length > 0 &&
                        child?.children?.map((subChild) => {
                          subChild.isChecked = !ele?.isChecked;
                          return subChild;
                        });
                      return child;
                    });
                  ele.isChecked = !ele?.isChecked;

                  return ele;
                } else {
                  return ele;
                }
              } else if (isChildCheckAll) {
                if (ele?._id === parentId) {
                  ele?.children &&
                    ele?.children?.length > 0 &&
                    ele?.children?.map((child) => {
                      if (child?._id === e?.target?.value) {
                        child?.children &&
                          child?.children?.length > 0 &&
                          child?.children?.map((subChild) => {
                            subChild.isChecked = !child?.isChecked;
                            return subChild;
                          });
                        child.isChecked = !child?.isChecked;
                        return child;
                      } else {
                        return child;
                      }
                    });
                  ele.isChecked = getChildValue(ele?.children);
                  return ele;
                } else {
                  return ele;
                }
              } else {
                if (ele?._id === parentId) {
                  ele?.children &&
                    ele?.children?.length > 0 &&
                    ele?.children?.map((child) => {
                      if (child?._id === childId) {
                        child?.children &&
                          child?.children?.length > 0 &&
                          child?.children?.map((subChild) => {
                            if (subChild?._id === e?.target?.value) {
                              subChild.isChecked = !subChild?.isChecked;
                              return subChild;
                            } else {
                              return subChild;
                            }
                          });
                        child.isChecked = getChildValue(child?.children);
                        return child;
                      } else {
                        return child;
                      }
                    });
                  ele.isChecked = getChildValue(ele?.children) ? true : false;
                  return ele;
                } else {
                  return ele;
                }
              }
            });
          setPairForTreeView({
            ...keyValuePairForTreeview,
            [headerTitle]: arr,
          });
          let _storeFilteredValues = { ...storeFilteredValuesChild };
          _storeFilteredValues[headerTitle] = getHacodeValue(arr);
          setStoreFilteredValuesChild(_storeFilteredValues);
        } else {
          setError(true);
          setErrorMsg(SearchRequireWarning);
        }
      }
    };
    const sortingHsCodeData = (data) => {
      data.sort((a, b) => {
        const idA = parseInt(a?.count);
        const idB = parseInt(b?.count);

        if (idA < idB) {
          return 1;
        }
        if (idA > idB) {
          return -1;
        }
        return 0;
      });
      return data;
    };
    const hsCodeOrMonthUI = (type) => {
      if (
        headerTitle === FILTER_OPTIONS?.HS_CODE ||
        headerTitle === FILTER_OPTIONS?.MONTH ||
        headerTitle === FILTER_OPTIONS?.DATATYPE
      ) {
        let sortingData = keyArr;
        if (headerTitle === FILTER_OPTIONS?.HS_CODE) {
          sortingData = sortingHsCodeData(keyArr);
        }
        let data = sortingData;
        if (type !== "VIEW_MORE" && sortingData?.length > 0) {
          data = sortingData.slice(0, 10);
        }
        return (
          <Box>
            <Box
              style={{
                maxHeight: type === "VIEW_MORE" ? "400px" : "",
                overflow: type === "VIEW_MORE" ? "overlay" : "",
              }}
            >
              {type !== "VIEW_MORE" && (
                <Box>
                  <RenderApplyBtn class_name="hs_code_and_month" />
                </Box>
              )}
              <TreeView
                aria-label="multi-select"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                multiSelect
                style={{ marginLeft: "0px" }}
              >
                <Box
                  className={
                    type === "VIEW_MORE"
                      ? "list_container notranslate"
                      : "notranslate"
                  }
                >
                  {data !== undefined &&
                    data?.length > 0 &&
                    data?.map((e, index) => {
                      let parentData = e?.children;
                      if (headerTitle === FILTER_OPTIONS?.HS_CODE) {
                        parentData = sortingHsCodeData(parentData);
                      }
                      if (type !== "VIEW_MORE") {
                        parentData = e?.children
                          ? e?.children.slice(0, 10)
                          : [];
                      }
                      return (
                        <>
                          <Box style={{ position: "relative" }} key={e?._id}>
                            {/* //************** First */}

                            <TreeItem
                              nodeId={e?._id}
                              label={
                                <FormControlLabel
                                  label={
                                    isFromMarket
                                      ? e?._id
                                      : `${e?._id} (${
                                          Object.keys(CheckedData).length > 0
                                            ? `${e?.subCount}/${e?.count}`
                                            : `${e?.count}`
                                        })`
                                  }
                                  control={
                                    <BpCheckbox
                                      checked={e?.isChecked}
                                      value={e?._id}
                                      onChange={(e) =>
                                        handleCheckedInput(e, false, true)
                                      }
                                      className="parent-hscode-month"
                                    />
                                  }
                                />
                              }
                              style={{ display: "block" }}
                            >
                              {/* {e?.children && e?.children?.length > 0 && treeItemData(e?.children)} */}
                              {/* //************** second */}

                              {parentData &&
                                parentData?.length > 0 &&
                                parentData?.map((ele) => {
                                  let childData = ele?.children;
                                  if (headerTitle === FILTER_OPTIONS?.HS_CODE) {
                                    childData = sortingHsCodeData(childData);
                                  }
                                  if (type !== "VIEW_MORE") {
                                    childData = ele?.children
                                      ? ele?.children.slice(0, 10)
                                      : [];
                                  }
                                  return (
                                    <Box
                                      style={{ position: "relative" }}
                                      key={ele?._id}
                                    >
                                      <TreeItem
                                        nodeId={ele?._id}
                                        label={
                                          <FormControlLabel
                                            checked={ele?.isChecked}
                                            label={
                                              isFromMarket
                                                ? ele?._id
                                                : `${ele?._id} (${
                                                    Object.keys(CheckedData)
                                                      .length > 0
                                                      ? `${ele?.subCount}/${ele?.count}`
                                                      : `${ele?.count}`
                                                  })`
                                            }
                                            value={ele?._id}
                                            onChange={(val) =>
                                              handleCheckedInput(
                                                val,
                                                true,
                                                false,
                                                e?._id
                                              )
                                            }
                                            control={
                                              <BpCheckbox className="children-hscode-month" />
                                            }
                                          />
                                        }
                                        style={{ display: "block" }}
                                      >
                                        {childData &&
                                          childData?.length > 0 &&
                                          childData?.map(
                                            (child, childIndex) => {
                                              return (
                                                <Box
                                                  style={{
                                                    position: "relative",
                                                  }}
                                                  key={child?._id}
                                                >
                                                  {/* //************** third */}

                                                  <TreeItem
                                                    style={{ display: "block" }}
                                                    nodeId={child?._id}
                                                    label={
                                                      <FormControlLabel
                                                        className={child?._id}
                                                        label={
                                                          isFromMarket
                                                            ? child?._id
                                                            : `${child?._id} (${
                                                                Object.keys(
                                                                  CheckedData
                                                                ).length > 0
                                                                  ? `${child?.subCount}/${child?.count}`
                                                                  : `${child?.count}`
                                                              })`
                                                        }
                                                        checked={
                                                          child?.isChecked
                                                        }
                                                        value={child?._id}
                                                        onChange={(val) =>
                                                          handleCheckedInput(
                                                            val,
                                                            false,
                                                            false,
                                                            e?._id,
                                                            ele?._id
                                                          )
                                                        }
                                                        control={<BpCheckbox />}
                                                      />
                                                    }
                                                  />
                                                  {!isFromMarket && (
                                                    <p
                                                      className="total-sum-parent"
                                                      style={{
                                                        position: "absolute",
                                                        top: "-8px",
                                                        right: "20px",
                                                      }}
                                                      s
                                                    >{`$${millify(
                                                      child?.totalSum
                                                    )}`}</p>
                                                  )}
                                                </Box>
                                              );
                                            }
                                          )}
                                        {type !== "VIEW_MORE" &&
                                          ele?.children?.length &&
                                          ele?.children?.length > 10 && (
                                            <p
                                              onClick={handleViewMore}
                                              className="btn-viewmore"
                                            >
                                              View More
                                            </p>
                                          )}
                                      </TreeItem>
                                      {!isFromMarket && (
                                        <p
                                          className="total-sum-parent"
                                          style={{
                                            position: "absolute",
                                            top: "-8px",
                                            right: "20px",
                                          }}
                                          s
                                        >{`$${millify(ele?.totalSum)}`}</p>
                                      )}
                                    </Box>
                                  );
                                })}
                              {type !== "VIEW_MORE" &&
                                e?.children?.length &&
                                e?.children?.length > 10 && (
                                  <p
                                    onClick={handleViewMore}
                                    className="btn-viewmore"
                                  >
                                    View More
                                  </p>
                                )}
                            </TreeItem>
                            {!isFromMarket && (
                              <p
                                className="total-sum-parent"
                                style={{
                                  position: "absolute",
                                  top: "-8px",
                                  right: "20px",
                                }}
                                s
                              >{`$${millify(e?.totalSum)}`}</p>
                            )}
                          </Box>
                        </>
                      );
                    })}
                </Box>
              </TreeView>
              {type !== "VIEW_MORE" &&
                keyArr?.length &&
                keyArr?.length > 10 && (
                  <p onClick={handleViewMore} className="btn-viewmore">
                    View More
                  </p>
                )}
            </Box>
            {type === "VIEW_MORE" && (
              <Box sx={{ mt: 2 }}>{renderApplyBtnForViewMore()}</Box>
            )}
          </Box>
        );
      }
    };
    const clearSearchField = () => {
      setCountryOrPort("");
      setFilterValue(getAllFilterValues);
    };
    const handleViewMore = () => {
      translateButtonClick();
      setIsOpenModal(true);
      setshowItems(-1);
    };

    useEffect(() => {
      if (
        _headerTitle_countryAndPort?.includes(headerTitle) ||
        _headerTitle_importAndExport?.includes(headerTitle)
      ) {
        let filterData =
          getAllFilterValues &&
          getAllFilterValues.length > 0 &&
          getAllFilterValues.filter((e) =>
            e?._id?.toLowerCase().includes(searchCountryOrPort?.toLowerCase())
          );
        setFilterValue(filterData);
      }
    }, [searchCountryOrPort]);

    const changeAndStoreFilterOption = (event, range) => {
      if (
        isFromWorkspace ||
        isFromMarket ||
        isMarketCompany ||
        isMarketProduct ||
        isGlobalSearch ||
        currentCountry === "SILKROUTE"
      ) {
        setCurrentSelectedQuantity(event?.toString());
        if (
          _headerTitle_countryAndPort.includes(headerTitle) ||
          _headerTitle_importAndExport.includes(headerTitle)
        ) {
          setStoreFilteredValuesChild({
            ...storeFilteredValuesChild,
            [headerTitle]: {
              ...storeFilteredValuesChild[headerTitle],
              [event.target.value.split(" ").join("_")]: event?.target?.checked,
            },
          });
        } else if (
          headerTitle === FILTER_OPTIONS?.QUANTITY ||
          headerTitle === FILTER_OPTIONS?.PRICE
        ) {
          let range = [];
          getFilterValues &&
            getFilterValues.length > 0 &&
            getFilterValues.map((e) => {
              if (headerTitle === FILTER_OPTIONS?.QUANTITY) {
                if (e?._id === event) {
                  setRangeValue({
                    ...rangeValue,
                    [headerTitle]: [e?.minRange, e?.maxRange],
                  });
                  range = [e.minRange, e.maxRange];
                  setMaxMinRange({
                    ...getMaxMinRange,
                    [headerTitle]: [e?.minRange, e?.maxRange],
                  });
                }
              } else {
                if (e?.metaTag[0]?.currency === event) {
                  range = [e?.minRange, e?.maxRange];
                  setRangeValue({
                    ...rangeValue,
                    [headerTitle]: [e?.minRange, e?.maxRange],
                  });
                  setMaxMinRange({
                    ...getMaxMinRange,
                    [headerTitle]: [e?.minRange, e?.maxRange],
                  });
                }
              }
            });
          setSelectedQuantityOrPrice({
            selectedQuantityOrPriceValue,
            [headerTitle]: event,
          });

          if (headerTitle === "QUANTITY") {
            setStoreFilteredValuesChild({
              ...storeFilteredValuesChild,
              [headerTitle]: {
                // ...storeFilteredValuesChild[props.headerTitle],
                ...storeFilteredValuesChild[headerTitle],
                [event]: {
                  [event]: event,
                  value: event,
                  maxRange: range[1],
                  minRange: range[0],
                },
              },
            });
          } else {
            setStoreFilteredValuesChild({
              ...storeFilteredValuesChild,
              [headerTitle]: {
                // ...storeFilteredValuesChild[props.headerTitle],
                [event]: event,
                value: event,
                maxRange: range[1],
                minRange: range[0],
              },
            });
          }

          setRangeValue({ ...rangeValue, [headerTitle]: [range[0], range[1]] });
        } else if (headerTitle === FILTER_OPTIONS?.REMOVE_ORDER_OR_NULL) {
          setStoreFilteredValuesChild({
            ...storeFilteredValuesChild,
            [headerTitle]: {
              ...storeFilteredValuesChild[headerTitle],
              [event?.target?.value]: event?.target?.checked,
            },
          });
        } else {
          setStoreFilteredValuesChild({
            [headerTitle]: {
              [event?.target?.value]: true,
            },
          });
        }
      } else {
        if (
          getSelectedOptionForSearchTerm &&
          getSelectedOptionForSearchTerm.length > 0
        ) {
          setCurrentSelectedQuantity(event?.toString());
          if (
            _headerTitle_countryAndPort.includes(headerTitle) ||
            _headerTitle_importAndExport.includes(headerTitle)
          ) {
            setStoreFilteredValuesChild({
              ...storeFilteredValuesChild,
              [headerTitle]: {
                ...storeFilteredValuesChild[headerTitle],
                [event.target.value.split(" ").join("_")]:
                  event?.target?.checked,
              },
            });
          } else if (
            headerTitle === FILTER_OPTIONS?.QUANTITY ||
            headerTitle === FILTER_OPTIONS?.PRICE
          ) {
            let range = [];
            getFilterValues &&
              getFilterValues.length > 0 &&
              getFilterValues.map((e) => {
                if (headerTitle === FILTER_OPTIONS?.QUANTITY) {
                  if (e?._id === event) {
                    setRangeValue({
                      ...rangeValue,
                      [headerTitle]: [e?.minRange, e?.maxRange],
                    });
                    range = [e.minRange, e.maxRange];
                    setMaxMinRange({
                      ...getMaxMinRange,
                      [headerTitle]: [e?.minRange, e?.maxRange],
                    });
                  }
                } else {
                  if (e?.metaTag[0]?.currency === event) {
                    range = [e?.minRange, e?.maxRange];
                    setRangeValue({
                      ...rangeValue,
                      [headerTitle]: [e?.minRange, e?.maxRange],
                    });
                    setMaxMinRange({
                      ...getMaxMinRange,
                      [headerTitle]: [e?.minRange, e?.maxRange],
                    });
                  }
                }
              });
            setSelectedQuantityOrPrice({
              selectedQuantityOrPriceValue,
              [headerTitle]: event,
            });

            if (headerTitle === "QUANTITY") {
              setStoreFilteredValuesChild({
                ...storeFilteredValuesChild,
                [headerTitle]: {
                  // ...storeFilteredValuesChild[props.headerTitle],
                  ...storeFilteredValuesChild[headerTitle],
                  [event]: {
                    [event]: event,
                    value: event,
                    maxRange: range[1],
                    minRange: range[0],
                  },
                },
              });
            } else {
              setStoreFilteredValuesChild({
                ...storeFilteredValuesChild,
                [headerTitle]: {
                  // ...storeFilteredValuesChild[props.headerTitle],
                  [event]: event,
                  value: event,
                  maxRange: range[1],
                  minRange: range[0],
                },
              });
            }

            setRangeValue({
              ...rangeValue,
              [headerTitle]: [range[0], range[1]],
            });
          } else if (headerTitle === FILTER_OPTIONS?.REMOVE_ORDER_OR_NULL) {
            setStoreFilteredValuesChild({
              ...storeFilteredValuesChild,
              [headerTitle]: {
                ...storeFilteredValuesChild[headerTitle],
                [event?.target?.value]: event?.target?.checked,
              },
            });
          } else {
            setStoreFilteredValuesChild({
              [headerTitle]: {
                [event?.target?.value]: true,
              },
            });
          }
        } else {
          setError(true);
          setErrorMsg(SearchRequireWarning);
        }
      }
    };

    const ImporterAndExporterUI = (type) => {
      const searchTitle = {
        FILTER_IMPORTER: "Search Importer...",
        FILTER_EXPORTER: "Search Exporter...",
      };

      let data = [];
      if (getFilterValues && getFilterValues?.length > 0) {
        data = getFilterValues.sort(
          (item1, item2) => item2?.count - item1?.count
        );
      } else {
        data = [];
      }
      if (
        type !== "VIEW_MORE" &&
        allFilterValuesWithCountsArr.hasOwnProperty(headerTitle) &&
        allFilterValuesWithCountsArr[headerTitle].length > 0
      ) {
        const sortedData = getFilterValues.sort(
          (item1, item2) => item2?.count - item1?.count
        );
        data = sortedData.slice(0, 10);
      }
      if (
        allFilterValuesWithCountsArr[headerTitle] &&
        allFilterValuesWithCountsArr[headerTitle].length > 0
      ) {
        let _allFilterValuesWithCountsArr_headerTitle =
          [...allFilterValuesWithCountsArr[headerTitle]] ?? [];
        const id = [];
        _allFilterValuesWithCountsArr_headerTitle?.length > 0 &&
          _allFilterValuesWithCountsArr_headerTitle?.forEach((e) => {
            id.push(e?._id);
          });
        let checkedCountryOrPort = data.filter((e) => id.includes(e?._id));
        data = data.filter((e) => !id.includes(e?._id));
        data.unshift(
          checkedCountryOrPort.sort(
            (item1, item2) => item2?.count - item1?.count
          )
        );
        data = data.flat();
        if (type !== "VIEW_MORE") {
          data = data.slice(0, 10);
        }
      }

      const renderRow = ({ index, style }) => {
        const e = data[index];
        if (!e || e._id === "NULL" || e._id === "") {
          return null;
        }

        const filterCountData =
          allFilterValuesWithCountsArr &&
          allFilterValuesWithCountsArr[headerTitle] &&
          allFilterValuesWithCountsArr[headerTitle]?.length > 0 &&
          allFilterValuesWithCountsArr[headerTitle]?.find(
            ({ _id }) => _id === e?._id
          );

        const country = e._id.toUpperCase().split(" ").join("_");
        const isFilterCountData =
          Object.keys(CheckedData).length === 0
            ? e?.totalSum
            : filterCountData?.totalSum;

        return (
          <Box key={e._id} style={style}>
            <span>
              <FormControlLabel
                style={{
                  marginTop: "2px",
                  marginRight: "0px",
                  marginLeft: "0px",
                }}
                value={e._id !== undefined && e._id.toUpperCase()}
                onChange={(e) => changeAndStoreFilterOption(e, [])}
                checked={
                  storeFilteredValuesChild[headerTitle]
                    ? storeFilteredValuesChild[headerTitle][country]
                    : false
                }
                control={<BpCheckbox />}
                label={
                  <Typography
                    title={e._id.toUpperCase()}
                    style={{
                      maxWidth: `calc(100vw - 60px)`,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {e._id.toUpperCase()}
                  </Typography>
                }
              />
            </span>
            <span
              className="notranslate"
              style={{
                minWidth: "70px",
                fontSize: "11px !important",
                textAlign: "right",
                marginTop: "8px",
                float: "right",
              }}
            >
              {isFromMarket
                ? ""
                : isFilterCountData === undefined
                ? e?.totalSum !== undefined
                  ? "$0"
                  : ""
                : `$${millify(e?.totalSum)}`}
            </span>
            {!isFromMarket && (
              <>
                <span
                  className="notranslate"
                  style={{
                    float: "right",
                    marginTop: "9px",
                    color: "#6c7585",
                    fontSize: "14px",
                  }}
                >
                  <span>{"("}</span>
                  {!filterCountData && Object.keys(CheckedData).length > 0 && (
                    <span>0/</span>
                  )}
                  {filterCountData && Object.keys(CheckedData).length > 0 && (
                    <span>{filterCountData.count}/</span>
                  )}
                  <span>{e.count}</span>
                  <span>{")"}</span>
                </span>
              </>
            )}
          </Box>
        );
      };

      if (_headerTitle_importAndExport?.includes(headerTitle)) {
        return (
          <Box>
            {getFilterValues !== undefined && (
              <>
                <Box id="searchComponentExplore">
                  <Search className="inline-container">
                    <SearchIconWrapper>
                      <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                      className="input-flex"
                      value={searchCountryOrPort}
                      onChange={(e) => setCountryOrPort(e.target.value)}
                      placeholder={
                        _headerTitle_importAndExport?.includes(headerTitle)
                          ? searchTitle[headerTitle]
                          : "Search..."
                      }
                      inputProps={{ "aria-label": "search" }}
                      sx={{ width: "100%" }}
                    />
                    <i
                      onClick={() => clearSearchField()}
                      className="clear-search-input"
                    >
                      &times;
                    </i>
                  </Search>
                </Box>
                {type !== "VIEW_MORE" && (
                  <Box>
                    <RenderApplyBtn class_name="country_and_port" />
                  </Box>
                )}
              </>
            )}
            <Box style={{ marginTop: "10px" }}>
              {type === "VIEW_MORE" && data && data.length > 0 && (
                <FixedSizeList
                  height={400}
                  itemCount={data.length}
                  itemSize={40}
                  width="100%"
                >
                  {renderRow}
                </FixedSizeList>
              )}
              {type !== "VIEW_MORE" &&
                data &&
                data?.length > 0 &&
                data.map((e, index) => {
                  if (e?._id === "NULL" || e?._id === "") {
                    return;
                  }
                  const filterCountData =
                    allFilterValuesWithCountsArr &&
                    allFilterValuesWithCountsArr[headerTitle] &&
                    allFilterValuesWithCountsArr[headerTitle]?.length > 0 &&
                    allFilterValuesWithCountsArr[headerTitle]?.find(
                      ({ _id }) => _id === e?._id
                    );
                  const country = e._id.toUpperCase().split(" ").join("_");
                  const isFilterCountData =
                    Object.keys(CheckedData).length === 0
                      ? e?.totalSum
                      : filterCountData?.totalSum;
                  return (
                    <Box key={e?._id}>
                      <span>
                        <FormControlLabel
                          style={{
                            marginTop: "2px",
                            marginRight: "0px",
                            marginLeft: "0px",
                          }}
                          value={e?._id !== undefined && e?._id.toUpperCase()}
                          onChange={(e) => changeAndStoreFilterOption(e, [])}
                          //   checked={false}
                          checked={
                            storeFilteredValuesChild[headerTitle]
                              ? storeFilteredValuesChild[headerTitle][country]
                              : false
                          }
                          control={<BpCheckbox />}
                          label={
                            <Typography
                              title={e?._id.toUpperCase()}
                              style={{
                                maxWidth:
                                  type == "VIEW_MORE" ? "360px" : "180px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {e?._id.toUpperCase()}
                            </Typography>
                          }
                        />
                        {/* <FormControlLabel control={<Checkbox name="gilad" />} label="Gilad Gray" /> */}
                      </span>

                      <span
                        className="notranslate"
                        style={{
                          minWidth: "70px",
                          fontSize: "11px !important",
                          textAlign: "right",
                          marginTop: "8px",
                          float: "right",
                        }}
                      >
                        {isFromMarket
                          ? ""
                          : isFilterCountData === undefined
                          ? e?.totalSum !== undefined
                            ? "$0"
                            : ""
                          : `$${millify(e?.totalSum)}`}
                        {/* : `$${millify(Object.keys(CheckedData).length === 0 ? e?.totalSum : filterCountData?.totalSum)}`}{" "} */}
                      </span>

                      {!isFromMarket && (
                        <>
                          <span
                            className="notranslate"
                            style={{
                              float: "right",
                              marginTop: "9px",
                              color: "#6c7585",
                              fontSize: "14px",
                            }}
                          >
                            <span>{"("}</span>
                            {!filterCountData &&
                              Object.keys(CheckedData).length > 0 && (
                                <span>0/</span>
                              )}
                            {filterCountData &&
                              Object.keys(CheckedData).length > 0 && (
                                <span>{filterCountData.count}/</span>
                              )}
                            <span>{e.count}</span>
                            <span>{")"}</span>
                          </span>
                        </>
                      )}
                    </Box>
                  );
                })}
            </Box>
            {type !== "VIEW_MORE" &&
              getFilterValues.length &&
              getFilterValues.length > 10 && (
                <p onClick={handleViewMore} className="btn-viewmore">
                  View More
                </p>
              )}
            {type === "VIEW_MORE" && renderApplyBtnForViewMore()}
          </Box>
        );
      }
    };

    const countryOrPortUI = (type) => {
      const searchTitle = {
        FILTER_COUNTRY: "Search Country...",
        FILTER_PORT: "Search Ports...",
        FILTER_INDIAN_PORT: "Search Indian Ports...",
        FILTER_FOREIGN_PORT: "Search Foreign Ports...",
        FILTER_REPORTING_COUNTRY: "Search Reporting Country...",
        FILTER_ORIGIN_PORT: "Search Origin Ports...",
        FILTER_DESTINATION_PORT: "Search Destiantion Ports...",
        FILTER_DESTINATION_COUNTRY: "Search Destiantion Country...",
        FILTER_ORIGIN_COUNTRY: "Search Origin Country...",
        FILTER_STATE: "Search State...",
        FILTER_PIN: "Search PIN...",
        FILTER_IMPORTER: "Search Importer...",
        FILTER_EXPORTER: "Search Exporter...",
      };

      let data = [];
      if (getFilterValues && getFilterValues?.length > 0) {
        data = getFilterValues.sort(
          (item1, item2) => item2?.count - item1?.count
        );
      } else {
        data = [];
      }
      if (
        type !== "VIEW_MORE" &&
        allFilterValuesWithCountsArr.hasOwnProperty(headerTitle) &&
        allFilterValuesWithCountsArr[headerTitle].length > 0
      ) {
        const sortedData = getFilterValues.sort(
          (item1, item2) => item2?.count - item1?.count
        );
        data = sortedData.slice(0, 10);
      }
      if (
        allFilterValuesWithCountsArr[headerTitle] &&
        allFilterValuesWithCountsArr[headerTitle].length > 0
      ) {
        let _allFilterValuesWithCountsArr_headerTitle =
          [...allFilterValuesWithCountsArr[headerTitle]] ?? [];
        const id = [];
        _allFilterValuesWithCountsArr_headerTitle?.length > 0 &&
          _allFilterValuesWithCountsArr_headerTitle?.forEach((e) => {
            id.push(e?._id);
          });
        let checkedCountryOrPort = data.filter((e) => id.includes(e?._id));
        data = data.filter((e) => !id.includes(e?._id));
        data.unshift(
          checkedCountryOrPort.sort(
            (item1, item2) => item2?.count - item1?.count
          )
        );
        data = data.flat();
        if (type !== "VIEW_MORE") {
          data = data.slice(0, 10);
        }
      }

      if (_headerTitle_countryAndPort?.includes(headerTitle)) {
        return (
          <Box>
            {getFilterValues !== undefined && (
              <>
                <Box id="searchComponentExplore">
                  <Search className="inline-container">
                    <SearchIconWrapper>
                      <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                      className="input-flex"
                      value={searchCountryOrPort}
                      onChange={(e) => setCountryOrPort(e.target.value)}
                      placeholder={
                        _headerTitle_countryAndPort?.includes(headerTitle)
                          ? searchTitle[headerTitle]
                          : "Search..."
                      }
                      inputProps={{ "aria-label": "search" }}
                      sx={{ width: "100%" }}
                    />
                    <i
                      onClick={() => clearSearchField()}
                      className="clear-search-input"
                    >
                      &times;
                    </i>
                  </Search>
                </Box>
                {type !== "VIEW_MORE" && (
                  <Box>
                    <RenderApplyBtn class_name="country_and_port" />
                  </Box>
                )}
              </>
            )}
            <Box
              style={{ marginTop: "10px" }}
              className={type === "VIEW_MORE" ? "list_container_country" : ""}
            >
              {data &&
                data?.length > 0 &&
                data.map((e, index) => {
                  if (e?._id === "NULL" || e?._id === "") {
                    return;
                  }
                  const filterCountData =
                    allFilterValuesWithCountsArr &&
                    allFilterValuesWithCountsArr[headerTitle] &&
                    allFilterValuesWithCountsArr[headerTitle]?.length > 0 &&
                    allFilterValuesWithCountsArr[headerTitle]?.find(
                      ({ _id }) => _id === e?._id
                    );
                  const country = e._id.toUpperCase().split(" ").join("_");
                  const isFilterCountData =
                    Object.keys(CheckedData).length === 0
                      ? e?.totalSum
                      : filterCountData?.totalSum;
                  return (
                    <Box key={e?._id}>
                      <span>
                        <FormControlLabel
                          style={{
                            marginTop: "2px",
                            marginRight: "0px",
                            marginLeft: "0px",
                          }}
                          value={e?._id !== undefined && e?._id.toUpperCase()}
                          onChange={(e) => changeAndStoreFilterOption(e, [])}
                          //   checked={false}
                          checked={
                            storeFilteredValuesChild[headerTitle]
                              ? storeFilteredValuesChild[headerTitle][country]
                              : false
                          }
                          control={<BpCheckbox />}
                          label={
                            <Typography
                              title={e?._id.toUpperCase()}
                              style={{
                                maxWidth: "180px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {e?._id.toUpperCase()}
                            </Typography>
                          }
                        />
                        {/* <FormControlLabel control={<Checkbox name="gilad" />} label="Gilad Gray" /> */}
                      </span>

                      <span
                        className="notranslate"
                        style={{
                          minWidth: "70px",
                          fontSize: "11px !important",
                          textAlign: "right",
                          marginTop: "8px",
                          float: "right",
                        }}
                      >
                        {isFromMarket
                          ? ""
                          : isFilterCountData === undefined
                          ? e?.totalSum !== undefined
                            ? "$0"
                            : ""
                          : `$${millify(e?.totalSum)}`}
                        {/* : `$${millify(Object.keys(CheckedData).length === 0 ? e?.totalSum : filterCountData?.totalSum)}`}{" "} */}
                      </span>

                      {!isFromMarket && (
                        <>
                          <span
                            className="notranslate"
                            style={{
                              float: "right",
                              marginTop: "9px",
                              color: "#6c7585",
                              fontSize: "14px",
                            }}
                          >
                            <span>{"("}</span>
                            {!filterCountData &&
                              Object.keys(CheckedData).length > 0 && (
                                <span>0/</span>
                              )}
                            {filterCountData &&
                              Object.keys(CheckedData).length > 0 && (
                                <span>{filterCountData.count}/</span>
                              )}
                            <span>{e.count}</span>
                            <span>{")"}</span>
                          </span>
                        </>
                      )}
                    </Box>
                  );
                })}
            </Box>
            {type !== "VIEW_MORE" &&
              getFilterValues.length &&
              getFilterValues.length > 10 && (
                <p onClick={handleViewMore} className="btn-viewmore">
                  View More
                </p>
              )}
            {type === "VIEW_MORE" && renderApplyBtnForViewMore()}
          </Box>
        );
      }
    };

    const handleChangeRange = (event) => {
      const _rangeValue = [];
      if (event?.minValue) {
        _rangeValue.push(event?.min, rangeValue[headerTitle][1]);
      } else if (event?.maxValue) {
        _rangeValue.push(rangeValue[headerTitle][0], event?.max);
      }

      // in function range params is not in use so
      // changeAndStoreFilterOption(arr[props.headerTitle].value, event.target.value);
      if (headerTitle === FILTER_OPTIONS?.QUANTITY) {
        setStoreFilteredValuesChild({
          ...storeFilteredValuesChild,
          [headerTitle]: {
            ...storeFilteredValuesChild[headerTitle],
            [currentSelectedQuantity]: {
              [currentSelectedQuantity]:
                storeFilteredValuesChild[headerTitle][currentSelectedQuantity]
                  ?.value,
              value:
                storeFilteredValuesChild[headerTitle][currentSelectedQuantity]
                  ?.value,
              maxRange: event?.minValue
                ? rangeValue[headerTitle][1]
                : event?.max,
              minRange: event?.maxValue
                ? rangeValue[headerTitle][0]
                : event?.min,
            },
          },
        });
      } else {
        setStoreFilteredValuesChild({
          ...storeFilteredValuesChild,
          [headerTitle]: {
            ...storeFilteredValuesChild[headerTitle],
            [storeFilteredValuesChild[headerTitle].value]:
              storeFilteredValuesChild[headerTitle]?.value,
            value: storeFilteredValuesChild[headerTitle]?.value,
            maxRange: event?.minValue ? rangeValue[headerTitle][1] : event?.max,
            minRange: event?.maxValue ? rangeValue[headerTitle][0] : event?.min,
          },
        });
      }

      setRangeValue({
        ...rangeValue,
        [headerTitle]: _rangeValue,
      });
    };
    const qauntityOrPriceUI = () => {
      if (
        headerTitle === FILTER_OPTIONS?.QUANTITY ||
        headerTitle === FILTER_OPTIONS?.PRICE
      ) {
        return (
          <Box>
            <Box sx={{ pb: 5 }}>
              {getFilterValues && getFilterValues.length ? (
                <SelectDropdown
                  width="100%"
                  value={selectedQuantityOrPriceValue[headerTitle]}
                  setValue={(val) =>
                    changeAndStoreFilterOption(val?.target?.value, rangeValue)
                  }
                  option={
                    headerTitle === FILTER_OPTIONS?.QUANTITY
                      ? getFilterValues
                      : headerTitle === FILTER_OPTIONS?.PRICE
                      ? getFilterValues &&
                        getFilterValues.map((e, index) => {
                          return {
                            currency: e?.metaTag[0]?.currency,
                            totalSum: e?.totalSum,
                          };
                        })
                      : ""
                  }
                  optionValue={
                    headerTitle === FILTER_OPTIONS?.QUANTITY
                      ? "_id"
                      : "currency"
                  }
                  text={
                    headerTitle === FILTER_OPTIONS?.QUANTITY
                      ? "Select Unit"
                      : "Select Currency"
                  }
                  label={
                    headerTitle === FILTER_OPTIONS?.QUANTITY
                      ? "Select Unit"
                      : "Select Currency"
                  }
                  intialOption={true}
                  intialOptionLable={
                    headerTitle === FILTER_OPTIONS?.QUANTITY
                      ? "Select Unit"
                      : "Select Currency"
                  }
                />
              ) : (
                ""
                // <SelectDropdown width="64%" value="None Selected" option={[{ _id: "None Selected" }]} optionValue="_id" text="QUANTITY" label="QUANTITY" />
              )}
              {headerTitle === FILTER_OPTIONS?.QUANTITY &&
              storeFilteredValuesChild[headerTitle] &&
              storeFilteredValuesChild[headerTitle][currentSelectedQuantity]
                ?.value ? (
                // sx={{ width: 300 }}
                <Box className="mt-25">
                  <hr className="hrStyles"></hr>
                  <Box style={{ color: "#6e777f", marginBottom: "10px" }}>
                    Select {headerTitle} Range{" "}
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "10px",
                    }}
                  >
                    <TextField
                      id="outlined-number"
                      label="Min"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      size="small"
                      value={rangeValue[headerTitle][0]}
                      style={{ width: "48%" }}
                      onChange={(event) => {
                        const value = Math.min(
                          Number(event?.target?.value),
                          getMaxMinRange[headerTitle][1] - 1
                        );
                        handleChangeRange({
                          min: value,
                          max: rangeValue[headerTitle][1],
                          minValue: true,
                        });
                      }}
                    />
                    <TextField
                      id="outlined-number"
                      label="Max"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      size="small"
                      value={rangeValue[headerTitle][1]}
                      style={{ width: "48%" }}
                      onChange={(event) => {
                        if (
                          event?.target?.value <= getMaxMinRange[headerTitle][1]
                        ) {
                          let value = "";
                          if (
                            event?.target?.value === "0" ||
                            event?.target?.value === 0 ||
                            event?.target?.value === ""
                          ) {
                            value = 0;
                          } else {
                            value = Math.max(Number(event?.target?.value));
                          }
                          handleChangeRange({
                            min: rangeValue[headerTitle][0],
                            max: value,
                            maxValue: true,
                          });
                        } else {
                          handleChangeRange({
                            min: rangeValue[headerTitle][0],
                            max: getMaxMinRange[headerTitle][1],
                            maxValue: true,
                          });
                        }
                      }}
                      // max={5000}
                    />
                  </Box>
                  <Slider
                    min={getMaxMinRange[headerTitle][0]}
                    max={getMaxMinRange[headerTitle][1]}
                    handleChangeRange={handleChangeRange}
                    rangeValue={rangeValue[headerTitle]}
                  />
                </Box>
              ) : (
                ""
              )}
              {headerTitle === FILTER_OPTIONS?.PRICE &&
              storeFilteredValuesChild[headerTitle]?.value ? (
                // sx={{ width: 300 }}
                <Box className="mt-25">
                  <hr className="hrStyles"></hr>
                  <Box style={{ color: "#6e777f", marginBottom: "10px" }}>
                    Select {headerTitle} Range{" "}
                  </Box>
                  <Box
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <TextField
                      id="outlined-number"
                      label="Min"
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      size="small"
                      value={rangeValue[headerTitle][0]}
                      style={{ width: "48%" }}
                      onChange={(event) => {
                        const value = Math.min(
                          Number(event?.target?.value),
                          getMaxMinRange[headerTitle][1] - 1
                        );
                        handleChangeRange({
                          min: value,
                          max: rangeValue[headerTitle][1],
                          minValue: true,
                        });
                      }}
                    />
                    <TextField
                      id="outlined-number"
                      label="Max"
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      size="small"
                      value={rangeValue[headerTitle][1]}
                      style={{ width: "48%" }}
                      onChange={(event) => {
                        if (
                          event?.target?.value <= getMaxMinRange[headerTitle][1]
                        ) {
                          let value = "";
                          if (
                            event?.target?.value === "0" ||
                            event?.target?.value === 0 ||
                            event?.target?.value === ""
                          ) {
                            value = 0;
                          } else {
                            value = Math.max(Number(event?.target?.value));
                          }
                          handleChangeRange({
                            min: rangeValue[headerTitle][0],
                            max: value,
                            maxValue: true,
                          });
                        } else {
                          handleChangeRange({
                            min: rangeValue[headerTitle][0],
                            max: getMaxMinRange[headerTitle][1],
                            maxValue: true,
                          });
                        }
                      }}
                    />
                  </Box>
                  <Slider
                    min={getMaxMinRange[headerTitle][0]}
                    max={getMaxMinRange[headerTitle][1]}
                    handleChangeRange={handleChangeRange}
                    rangeValue={rangeValue[headerTitle]}
                  />
                </Box>
              ) : (
                ""
              )}
            </Box>
            <Box sx={{ mt: 2 }}>
              <RenderApplyBtn />
            </Box>
          </Box>
        );
      }
    };

    const handelSelectValueOrShipment = (event) => {
      setStoreFilteredValuesChild({
        ...storeFilteredValuesChild,
        [headerTitle]: {
          ...storeFilteredValuesChild[headerTitle],
          [event.target.value]: event?.target?.checked,
        },
      });
    };

    const valueOrShipmentUI = () => {
      let arr = [];
      if (headerTitle === "VALUE") {
        arr = valueFilter?.options;
      } else if (headerTitle === "SHIPMENT") {
        arr = ShipmentFilter?.options;
      }
      return (
        <Box style={{ marginTop: "10px" }}>
          <hr className="hrStyles"></hr>
          <Box>Select {headerTitle} Range </Box>
          <hr className="hrStylesBottom"></hr>
          {arr &&
            arr?.length > 0 &&
            arr?.slice(0, 10).map((e, index) => {
              return (
                <Box key={index}>
                  <span>
                    <FormControlLabel
                      style={{
                        marginTop: "2px",
                        marginRight: "0px",
                        marginLeft: "0px",
                      }}
                      value={e?.title !== undefined && e?.title.toUpperCase()}
                      onChange={(event) => handelSelectValueOrShipment(event)}
                      checked={
                        storeFilteredValuesChild[headerTitle]
                          ? storeFilteredValuesChild[headerTitle][e?.value]
                          : false
                      }
                      control={<BpCheckbox />}
                      label={
                        <Typography
                          title={e.value.toUpperCase()}
                          style={{ whiteSpace: "nowrap", overflow: "hidden" }}
                        >
                          {e.title.toUpperCase()}
                        </Typography>
                      }
                    />
                  </span>
                </Box>
              );
            })}
          <Box sx={{ mt: 2, marginTop: "50px" }}>
            <RenderApplyBtn />
          </Box>
        </Box>
      );
    };

    const removeOrderAndNull = () => {
      return (
        <Box style={{ padding: "10px" }}>
          <Box>
            {Remove_Order_Or_Null_Option?.map((e, index) => {
              return (
                <Box key={index}>
                  <span>
                    <FormControlLabel
                      style={{
                        marginTop: "2px",
                        marginRight: "0px",
                        marginLeft: "0px",
                      }}
                      value={e?.value}
                      onChange={(e) => changeAndStoreFilterOption(e)}
                      checked={
                        storeFilteredValuesChild[headerTitle]
                          ? storeFilteredValuesChild[headerTitle][e?.value]
                          : false
                      }
                      control={<BpCheckbox />}
                      label={
                        <Typography
                          title={e.value.toUpperCase()}
                          style={{ whiteSpace: "nowrap", overflow: "hidden" }}
                        >
                          {e.title.toUpperCase()}
                        </Typography>
                      }
                    />
                  </span>
                </Box>
              );
            })}
          </Box>
          <Box sx={{ mt: 2, marginTop: "20px" }}>
            <RenderApplyBtn />
          </Box>
        </Box>
      );
    };
    const selectMode = () => {
      return (
        <Box style={{ padding: "10px" }}>
          <Box style={{ cursor: "pointer" }}>
            <select
              id="action-dropdown"
              style={{ padding: "10px", width: "100%" }}
              onChange={(e) => changeAndStoreFilterOption(e)}
            >
              <option value="" style={{ padding: "10px", fontSize: "1rem" }}>
                SELECT MODE
              </option>
              <option
                value="import"
                style={{ padding: "10px", fontSize: "1rem" }}
              >
                IMPORT
              </option>
              <option
                value="export"
                style={{ padding: "10px", fontSize: "1rem" }}
              >
                EXPORT
              </option>
            </select>
          </Box>
          <Box sx={{ mt: 2, marginTop: "20px" }}>
            <RenderApplyBtn />
          </Box>
        </Box>
      );
    };
    return (
      <Slide direction="right" in={isFiltersShown} mountOnEnter unmountOnExit>
        <div id="collapse-filter">
          {error && (
            <ErrorLoading errorMsg={errorMsg} open={error} setOpen={setError} />
          )}
          {isFiltersShown && <Box>{appliedFilterComp}</Box>}
          <StyledTreeItem
            mirrorData={mirrorData}
            isFromExplore={isFromExplore}
            allFilterValuesWithCountsArr={allFilterValuesWithCountsArr}
            filterOptionClick={filterOptionClick}
            collapseAllFilters={collapseAllFilters}
            hsCodeOrMonthUI={hsCodeOrMonthUI}
            countryOrPortUI={countryOrPortUI}
            setCountryOrPort={setCountryOrPort}
            valueOrShipmentUI={valueOrShipmentUI}
            currentCountry={currentCountry}
            qauntityOrPriceUI={qauntityOrPriceUI}
            height={isFromExplore ? "" : "517px"}
            isFilterLoader={isFilterLoader}
            isMarketCompany={isMarketCompany}
            isMarketProduct={isMarketProduct}
            isGlobalSearch={isGlobalSearch}
            removeOrderAndNull={removeOrderAndNull}
            currentTradeType={currentTradeType}
            isFromWorkspace={isFromWorkspace}
            selectMode={selectMode}
            ImporterAndExporterUI={ImporterAndExporterUI}
            importerExporterName={importerExporterName}
          />

          {isOpenModal && (
            <ViewMoreModal
              headerTitle={headerTitle}
              open={isOpenModal}
              setOpen={setIsOpenModal}
              hsCodeOrMonthUI={hsCodeOrMonthUI}
              countryOrPortUI={countryOrPortUI}
              setshowItems={setshowItems}
              ImporterAndExporterUI={ImporterAndExporterUI}
            />
          )}
        </div>
      </Slide>
    );
  }
);

export default CollapseFilter;
