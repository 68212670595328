import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  NotificationActions,
  NotificationHeader,
  SupportMail,
} from "./NotificationComponents";
import { popConfigs } from "../popConfigs";
import { capitalizeSentences } from "../../../utils/CommanFunction";

const SinglePopUp = ({
  logo,
  modalContent,
  handlePopUpUpdate,
  highlightEffect,
  pulseEffect,
  handleRenewBtnAction,
}) => {
  const popContent = modalContent?.[0];
  return (
    <Box
      className="custom-pop-modal"
      sx={{
        animation: highlightEffect ? `${pulseEffect} 0.3s ease` : "none",
        transition: "transform 0.3s, box-shadow 0.3s",
      }}
    >
      <div className="logo-container">
        <img src={logo} width="100%" style={{ marginLeft: "1px" }} alt="Logo" />
      </div>
      <Box className="modal-body-wrapper">
        <IconButton
          className="custom-pop-close-button"
          onClick={() => handlePopUpUpdate(popContent, "later", true)}
        >
          <CloseIcon />
        </IconButton>
        <NotificationHeader
          icon={popConfigs?.[popContent?.popupNotificationType]?.icon}
          title={capitalizeSentences(
            popContent?.heading ??
              popConfigs?.[popContent?.popupNotificationType]?.title
          )}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "254px",
          }}
        >
          <Box className="custom-pop-content">
            <Typography variant="body1" className="custom-pop-description">
              {capitalizeSentences(popContent?.description)}
            </Typography>
          </Box>
          <SupportMail />
        </Box>
      </Box>
      <NotificationActions
        primaryButton={
          popConfigs?.[popContent?.popupNotificationType]?.primaryButton
        }
        handlePopUpUpdate={handlePopUpUpdate}
        popValue={popContent}
        isSingleNotification={true}
        handleRenewBtnAction={() => handleRenewBtnAction(popContent)}
      />
    </Box>
  );
};

export default SinglePopUp;
