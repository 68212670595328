export const DATEFORMAT = {
  format: "YYYY-MM-DD",
};

export const StatusFilter = {
  ALL: "ALL",
  DATA_NOT_FOUND: "DATA NOT FOUND",
  WORK_IN_PROGRESS: "WORK IN PROGRESS",
  DATA_FOUND: "DATA FOUND",
};

export const FILTER_STATUS_CODE = {
  ALL: 0,
  "WORK IN PROGRESS": 8,
  "DATA FOUND": 9,
  "DATA NOT FOUND": 10,
};

export const linkedInPageHeader = [
  { key: "name", title: "Name" },
  { key: "designation", title: "Position" },
  { key: "social_urls", title: "Social Media" },
  { key: "phone", title: "Phone Number" },
  { key: "email", title: "Email" },
];

export const EXCEL_FILE_DOWNLOAD = {
  title: "Excel file downloaded!",
  footerTitle: "Download Successfully",
};
