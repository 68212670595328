import * as React from "react";
import { Box, Button, Modal } from "@mui/material";
import "./ConfirmationModal.scss";

import { useNavigate } from "react-router-dom";
import { DeleteButton } from "../ButtonComponent/Button";

export default function ModalComponent(props) {
  const navigate = useNavigate();
  const handleClose = () => {
    props?.setOpen(false);
  };

  const handleSaveBtn = () => {
    if (props?.isConfirmBoxOpen) {
      navigate(props?.navigateUrl);
    } else {
      props?.savedClicked(true);
    }
  };
  return (
    <div>
      <Modal
        id="ConfirmationModal"
        open={props?.open}
        onClose={() => {
          props?.setOpen(false);
        }}
      >
        <Box style={{ width: props?.width, height: props?.height }} className={`confirmationModal-styles ${props?.className}`}>
          <div>
            <div className="confirmationModal-modalBody">{props?.modalBody}</div>
            <div className="hr"></div>
            <div className="footer-confirmationModal">
              <div className="footer-confirmationModal-content">
                <DeleteButton
                  name={props?.saveText}
                  btnClick={() => {
                    handleSaveBtn();
                  }}
                  style={{ margin: "0px 5px 0px 5px " }}
                />

                <Button
                  style={{ margin: "0px 5px 0px 5px " }}
                  onClick={() => {
                    handleClose();
                  }}
                  className="delete-close"
                >
                  {"Close"}
                </Button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
