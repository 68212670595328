import * as React from "react";
import { Typography, Box, Modal, Backdrop, Fade, CircularProgress } from "@mui/material";
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    px: 8,
    py: 3,
    outline: "none",
    borderRadius: "6px",
};
const PercentageLoader = (props) => {
    const open = true;
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={style} id="successLoader-box" className={props.loader === "hide" ? "bg-hide" : "bg-block"} style={{ backgroundColor: props.loader === "hide" ? "white" : "#00000052" }}>
                        <div>
                            <Box sx={{ textAlign: "center", mt: 3 }}>
                                <CircularProgress value={props?.value} variant="determinate" style={{ color: "white" }} />
                            </Box>
                            <Typography
                                id="transition-modal-description"
                                sx={{
                                    mt: 1,
                                    fontSize: "20px",
                                    fontweight: 300,
                                    wordwrap: "break-word",
                                    textAlign: "center",
                                    opacity: 1,
                                    fontFamily: "Rubik",
                                    color: "white"
                                }}
                            >
                                {`${props?.value}%`}
                            </Typography>
                            <Typography
                                id="transition-modal-description"
                                sx={{
                                    mt: 1,
                                    fontSize: "20px",
                                    fontweight: 300,
                                    wordwrap: "break-word",
                                    textAlign: "center",
                                    opacity: 1,
                                    fontFamily: "Rubik",
                                    color: "white"
                                }}
                            >
                                {`Generating Report`}
                            </Typography>
                        </div>
                    </Box>

                </Fade>
            </Modal>
        </div>
    );
};

export default PercentageLoader; 