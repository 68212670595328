import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "./analyzeTable.scss";
import { Button } from "react-bootstrap";
import { experimentalStyled as styled } from "@mui/material/styles";

const FilterButton = styled(Button)({
  marginRight: "6px",
  color: "#005d91",
  borderLeft: "1px solid #005D91",
  borderTop: "1px solid #005D91",
  borderRight: "3px solid #005D91",
  borderBottom: "3px solid #005D91",
  height: "33px",
  background: "none",
  zIndex: "1",
  position: "absolute",
  right: "12px",
  top: "8px",
  borderRadius: "5px",
  "&:hover": {
    backgroundColor: "#005d91 !important",
    borderColor: "transparent !important",
    color: "white",
  },
});

function AnalyzeTable(props) {
  return (
    <div style={{ position: "relative" }}>
      <FilterButton onClick={props.handleDownload}>Download Excel</FilterButton>
      <div className="ag-theme-alpine">
        <AgGridReact
          ref={props?.gridRef}
          gridOptions={props?.gridOptions}
          defaultColDef={props?.defaultColDef}
          pagination={true}
          rowData={props?.rowData}
          columnDefs={props.header}
          autoGroupColumnDef={props?.autoGroupColumnDef}
          rowMultiSelectWithClick={true}
          groupSelectsChildren={true}
          sideBar={props?.sideBar}
          suppressRowClickSelection={true}
          suppressAggFuncInHeader={true}
          onGridReady={props?.onGridReady}
          icons={props?.icons}
          rowDragManaged={false}
          animateRows={true}
          rowGroupPanelShow={"always"}
          overlayNoRowsTemplate={
            '<span style=" font-size:20px;">No data found</span>'
          }
          localeText={props?.localeText}
          skipGroups={true}
          excelStyles={props?.excelStyles}
          defaultExcelExportParams={props?.defaultExcelExportParams}
        />
      </div>
    </div>
  );
}
export default AnalyzeTable;
