import {
  axiosGetWithoutAuth,
  axiosPostWithoutAuth,
  axiosPutWithoutAuth,
  axiosDeleteWithoutAuth,
  axiosGetWithoutAuthWithoutHost,
} from "../../src/Helpers/axiosWithoutAuth";
import {
  axiosPut,
  axiosDelete,
  axiosPostWithCancelRequest,
  axiosPostWithToken,
  axiosGetWithOutAdxToken,
  getSessionValue,
  axiosPostWithOutUrl,
} from "../../src/Helpers/axiosWithAuth";
import {
  axiosGet,
  axiosPost,
  axiosPostWithContentType,
} from "../../src/Helpers/axiosWithAuth";
import { async } from "q";
import { checkAndRemoveExpiredSessions } from "../utils/CommanFunction";
import axios from "axios";

// import axios from "axios";
// import { createBrowserHistory } from "history";

// const history = createBrowserHistory();
// const sleep = (delay) => {
//   return new Promise(resolve => {
//     setTimeout(()=>{window.location.reload();return resolve}, delay)
//   })
// }
// axios.interceptors.response.use(async response => {
//   return response;
// }, (error) => {
//   const { data, status, config } = error.response;
//   switch (status) {
//     case 401:
//       if (!window.location.href.includes("login")){
//         history.push("/consumers/logout");
//         sleep(1000);
//       }
//       break;
//   }
//   return Promise.reject(error)
// })

export const getLoginDetails = async (data) => {
  let response = null;
  try {
    const output = await axiosPutWithoutAuth("/auths/login", data);
    response = output.data;
  } catch (err) {
    response = err;
  }
  return response;
};

export const getSignUpDetails = async (data) => {
  let response = null;
  try {
    const output = await axiosPostWithoutAuth("/accounts/registrations", data);
    response = output.data;
  } catch (err) {
    response = err;
  }
  return response;
};

export const getForgotPasswordDetails = async (data) => {
  let response = null;
  try {
    const output = await axiosPostWithoutAuth(
      "/users/sendResetPassworDetails",
      data
    );
    response = output.data;
  } catch (err) {
    response = err;
  }
  return response;
};

export const getPasswordResetDetails = async (data) => {
  let response = null;
  try {
    const output = await axiosPostWithoutAuth("/users/resetPassword", data);
    response = output;
  } catch (err) {
    response = err;
  }
  return response;
};
export const getPasswordVerifyDetails = async (data) => {
  let response = null;
  try {
    const output = await axiosPostWithoutAuth("/users/verifyPassword", data);
    response = output.data;
  } catch (err) {
    response = err;
  }
  return response;
};

export const logoutDetails = async (data) => {
  let response = null;
  try {
    const output = await axiosPut(`/auths/users/${data.user_id}/logout`, data);
    response = output.data;
  } catch (err) {
    response = err;
  }
  return response;
};

export const emailVerificationDetails = async (data, userId) => {
  let response = null;
  try {
    const output = await axiosPutWithoutAuth(`/users/email/verification`, data);
    response = output.data;
  } catch (err) {
    response = err;
  }
  return response;
};

export const getSaveQuery = async (data, accountId) => {
  let response = null;
  try {
    const output = await axiosGet(`/query/${accountId}`);
    response = output.data;
  } catch (err) {
    response = err;
  }
  return response;
};

export const getDashboredData = async () => {
  let response = null;
  try {
    const output = await axiosGet(`/dashboard/consumers`);
    response = output.data;
  } catch (err) {
    response = err;
  }
  return response;
};

export const deleteSaveQueryData = async (id) => {
  let response = null;
  try {
    const output = await axiosDelete(`/query/${id}`);
    response = output.data;
  } catch (err) {
    response = err;
  }
  return response;
};

export const getNotificationDetails = async () => {
  let response = null;
  try {
    const output = await axiosGet("/notification");
    response = output.data;
  } catch (err) {
    response = err;
  }
  return response;
};

export const updateNotification = async (data) => {
  try {
    let response = await axiosPost("/notification/update_status", data);
    return response.data;
  } catch (err) {
    return err;
  }
};

export const getCustomCountryData = async (tradeType) => {
  if (tradeType === null) {
    tradeType = "";
  }
  let response = null;
  try {
    const output = await axiosGet(
      `/trade/countries/explore?tradeType=${tradeType}`
    );
    response = output.data;
  } catch (err) {
    response = err;
  }
  return response;
};

export const getBlCountryData = async (tradeType) => {
  if (tradeType === null) {
    tradeType = "";
  }
  let response = null;
  try {
    const output = await axiosGet(
      `/trade/countries/bl/explore?tradeType=${tradeType}`
    );
    response = output.data;
  } catch (err) {
    response = err;
  }
  return response;
};

export const getSilkCountryData = async (tradeType) => {
  // if (tradeType === null) {
  //   tradeType = "";
  // }
  let response = null;
  try {
    const output = await axiosGet(
      `/trade/countries/silkroute/explore?tradeType=${tradeType}`
    );
    response = output.data;
  } catch (err) {
    response = err;
  }
  return response;
};

export const getIECSearchData = async (iecNumber) => {
  try {
    let response = await axiosGet(`/iec/search/${iecNumber}`);
    return response.data;
  } catch (err) {
    return err;
  }
};

export const getConsigneeShipmentDetails = async (data) => {
  try {
    let response = await axiosPost("/consignee/shipment/detail", data);
    return response.data;
  } catch (err) {
    return err;
  }
};

export const addConsigneeRequest = async (data) => {
  try {
    let response = await axiosPost("/consignee/request/add", data);
    return response.data;
  } catch (err) {
    return err;
  }
};
export const getTaxonomy = async (data) => {
  let response = null;
  if (
    data.currentCountry &&
    data.currentTradeType &&
    data.currentCountr != "" &&
    data.currentTradeType != ""
  ) {
    try {
      const output = await axiosGet(
        `/taxonomies?countryName=${data.currentCountry}&tradeType=${data.currentTradeType}`
      );
      response = output.data;
    } catch (err) {
      response = err;
    }
  } else {
    try {
      const output = await axiosGet(`/taxonomies`);
      response = output.data;
    } catch (err) {
      response = err;
    }
  }
  return response;
};

export const getTaxonomyGlobal = async () => {
  let response = null;
  try {
    const output = await axiosGet(`/taxonomies?taxonomyScope=GLOBAL`);
    response = output.data;
  } catch (err) {
    response = err;
  }
  return response;
};

export const getFlags = async () => {
  let response = null;
  try {
    const output = await axiosGetWithoutAuthWithoutHost(
      `https://restcountries.com/v3.1/all`
    );
    response = output.data;
  } catch (err) {
    response = err;
  }
  return response;
};

export const getRecordShipmentData = async (data) => {
  if (data.bl_flag === "true") {
    let response = await axiosGet(
      `/trade/shipments/explore/specifications?tradeType=${data.tradeType}&countryCode=${data.countryCode}&country=${data.country}&bl_flag=${data.bl_flag}`
    );
    try {
      return response.data;
    } catch (err) {
      return err;
    }
  } else {
    let response = await axiosGet(
      `/trade/shipments/explore/specifications?tradeType=${data.tradeType}&countryCode=${data.countryCode}&country=${data.country}`
    );
    try {
      return response.data;
    } catch (err) {
      return err;
    }
  }
};

export const getExploreSearchTerm = async (data) => {
  let response = null;
  try {
    const output = await axiosPost(
      "/trade/shipments/explore/traders/search",
      data
    );

    response = output.data;
  } catch (err) {
    response = err;
  }
  return response;
};

// export const onSearchTradeFilters = async (data, callback) => {
//   try {
//     let response = await axiosPost("/trade/shipments/explore/records", data);
//     axiosPost("/trade/shipments/explore/records/filter", data)
//       .then((res) => {
//         callback(res);
//       })
//       .catch(() => { });
//     return response.data;
//   } catch (err) {
//     return err;
//   }
// };
export const onSearchTradeFilters = async (data) => {
  data.AdxToken = getSessionValue("adxToken");
  data.AdxTokenExpireTime = getSessionValue("time");
  try {
    // const record = axiosPost(`/trade/shipments/explore/records`, data);
    const filter = axiosPost(`/trade/shipments/explore/records/filter`, data);
    const summary = await axiosPost(
      "/trade/shipments/explore/records/summary",
      data
    );
    let response = await Promise.all([filter, summary]);
    return response;
  } catch (err) {
    return err;
  }
};
export const getRecordCount = async (payload) => {
  payload.AdxToken = getSessionValue("adxToken");
  payload.AdxTokenExpireTime = getSessionValue("time");
  let response = null;
  try {
    const output = await axiosPost(
      "/trade/shipments/explore/records/count",
      payload
    );
    response = output;
  } catch (err) {
    response = err;
  }
  return response;
};
export const onSearchTradeRecord = async (data) => {
  data.AdxToken = getSessionValue("adxToken");
  data.AdxTokenExpireTime = getSessionValue("time");
  try {
    const response = await axiosPost(`/trade/shipments/explore/records`, data);
    return response;
  } catch (err) {
    return err;
  }
};

export const estimateRecords = async (data) => {
  try {
    let response = await axiosGet(
      `/trade/shipments/explore/estimate?tradeType=${data.tradeType}&countryCode=${data.countryCode}&country=${data.country}`,
      data
    );
    return response.data;
  } catch (err) {
    return err;
  }
};

export const getSummaryDetailsForTrade = async (data) => {
  try {
    let response = await axiosPost("/trade/companies/search", data);
    return response.data;
  } catch (err) {
    return err;
  }
};

export const templateForWorkspace = async (data) => {
  try {
    let response = await axiosGet(
      `/users/${data.userId}/workspaces/templates?tradeType=${data.tradeType}&countryCode=${data.countryCode}`
    );

    return response;
  } catch (err) {
    return err;
  }
};

export const templateApproval = async (data) => {
  try {
    let response = await axiosPost(
      `/workspaces/records/purchase/approval`,
      data
    );

    return response;
  } catch (err) {
    return err;
  }
};

export const addWorkspaceRecords = async (data) => {
  try {
    let response = await axiosPost("/workspaces/records", data);

    return response;
  } catch (err) {
    return err;
  }
};

export const verifyWorkSpaceExistence = async (data) => {
  try {
    let response = await axiosGet(
      `/accounts/${data.accountId}/workspaces/existence/verification?workspaceName=${data.workSpaceName}`
    );

    return response;
  } catch (err) {
    return err;
  }
};

export const savedQuery = async (data) => {
  try {
    let response = await axiosPost("/query/save", data);
    return response.data;
  } catch (err) {
    return err;
  }
};
export const updateSavedQuery = async (data, id) => {
  try {
    let response = await axiosPut(`/query/${id}`, data);
    return response.data;
  } catch (err) {
    return err;
  }
};

export const getSavedQuery = async (id) => {
  try {
    let response = await axiosGet(`/query/${id}`);
    return response.data;
  } catch (err) {
    return err;
  }
};

export const updateFavouriteCompany = async (data) => {
  try {
    let response = await axiosPut("/recommendation/company/update", data);
    return response.data;
  } catch (err) {
    return err;
  }
};

export const addFavouriteCompany = async (data) => {
  try {
    let response = await axiosPost("/recommendation/company", data);
    return response.data;
  } catch (err) {
    return err;
  }
};

export const getFavouriteCompany = async (data) => {
  try {
    let response = await axiosGet("/recommendation/company/list", data);
    return response.data;
  } catch (err) {
    return err;
  }
};

export const getFavouriteShipments = async () => {
  try {
    let response = await axiosGet("/recommendation/shipment/list");
    return response.data;
  } catch (err) {
    return err;
  }
};

export const searchFavouriteCompany = async (data) => {
  try {
    let response = await axiosPost("/favourite/countries/search", data);
    return response.data;
  } catch (err) {
    return err;
  }
};

export const addFavouriteShipment = async (data) => {
  try {
    let response = await axiosPost("/recommendation/shipment", data);
    return response.data;
  } catch (err) {
    return err;
  }
};

export const updateFavouriteShipment = async (data) => {
  try {
    let response = await axiosPut("/recommendation/shipment/update", data);
    return response.data;
  } catch (err) {
    return err;
  }
};

export const getUserDataForWorkSpace = async (id) => {
  try {
    let response = await axiosGet(`/accounts/${id}/users/templates`);
    return response.data;
  } catch (err) {
    return err;
  }
};

export const getAllWorkSpaceList = async (id, payload) => {
  try {
    let response = await axiosPost(`/workspaces/list/${id}`, payload);
    return response.data;
  } catch (err) {
    return err;
  }
};
export const getWorkSpaceById = async (id, workspaceId, payload) => {
  try {
    let response = await axiosPost(
      `/workspaces/list/${id}/${workspaceId}`,
      payload
    );
    return response.data;
  } catch (err) {
    return err;
  }
};

export const onSearchGetWorkspace = async (userId, tradeType, countryCode) => {
  try {
    let response = await axiosGet(
      `/users/${userId}/workspaces?tradeType=${tradeType}&countryCode=${countryCode}`
    );
    return response.data;
  } catch (err) {
    return err;
  }
};

export const deleteWorkspace = async (workspaceId) => {
  let response = null;
  try {
    const output = await axiosDelete(`/workspaces/${workspaceId}`);
    response = output.data;
  } catch (err) {
    response = err;
  }
  return response;
};

export const downloadWorkspaceRecord = async (data) => {
  try {
    let response = await axiosPost(
      "/workspaces/shipments/analytics/records/file",
      data
    );
    return response;
  } catch (err) {
    return err;
  }
};

export const shareWorkspace = async (data) => {
  try {
    let response = await axiosPost("/workspaces/share", data);
    return response.data;
  } catch (err) {
    return err;
  }
};

export const getAnalyzeSpecification = async (
  userId,
  workspaceId,
  tradeType,
  countryCode
) => {
  try {
    let response = await axiosGet(
      `/users/${userId}/workspaces/${workspaceId}/analytics/specifications?tradeType=${tradeType}&countryCode=${countryCode}`
    );
    return response.data;
  } catch (err) {
    return err;
  }
};

// export const onSearchWorkspace = async (data, callback) => {
//   try {
//     let response = await axiosPost("/workspaces/shipments/analytics/records", data);
//     axiosPost("/workspaces/shipments/analytics/records/filter", data)
//       .then((res) => {
//         callback(res);
//       })
//       .catch(() => { });
//     return response.data;
//   } catch (err) {
//     return err;
//   }
// };
export const onSearchWorkspace = async (data) => {
  try {
    const record = axiosPost(`/workspaces/shipments/analytics/records`, data);
    const filter = axiosPost(
      `/workspaces/shipments/analytics/records/filter`,
      data
    );
    const summary = await axiosPost(
      "/workspaces/shipments/analytics/summary",
      data
    );
    let response = await Promise.all([record, filter, summary]);
    return response;
  } catch (err) {
    return err;
  }
};
export const workSpaceRecord = async (data) => {
  try {
    const response = await axiosPost(
      `/workspaces/shipments/analytics/records`,
      data
    );
    return response?.data;
  } catch (err) {
    return err;
  }
};

export const getWorkspaceSearchTerm = async (data) => {
  let response = null;
  try {
    const output = await axiosPost(
      "/workspaces/shipments/analytics/traders/search",
      data
    );

    response = output.data;
  } catch (err) {
    response = err;
  }
  return response;
};

export const getWorkspaceGenralAnalyticsGraphData = async (data) => {
  let response = null;
  try {
    const output = await axiosPost(
      "/analytics/correlation/chronology/trade/factors",
      data
    );

    response = output.data;
  } catch (err) {
    response = err;
  }
  return response;
};

export const getWorkspaceGenralDistributionGraph = async (data) => {
  let response = null;
  try {
    const output = await axiosPost(
      "/analytics/distribution/chronology/trade/entities",
      data
    );

    response = output.data;
  } catch (err) {
    response = err;
  }
  return response;
};

export const getWorkspaceGenralComparisonGraph = async (data) => {
  let response = null;
  try {
    const output = await axiosPost(
      "/analytics/comparison/chronology/trade/entities",
      data
    );

    response = output.data;
  } catch (err) {
    response = err;
  }
  return response;
};

export const getWorkspaceCorrelationGraph = async (data) => {
  let response = null;
  try {
    const output = await axiosPost(
      "/analytics/correlation/trade/entities/factors",
      data
    );

    response = output.data;
  } catch (err) {
    response = err;
  }
  return response;
};

export const getWorkspaceCompositionGraph = async (data) => {
  let response = null;
  try {
    const output = await axiosPost(
      "/analytics/composition/trade/entities/factors",
      data
    );

    response = output.data;
  } catch (err) {
    response = err;
  }
  return response;
};

export const getWorkspaceContributionGraph = async (data) => {
  let response = null;
  try {
    const output = await axiosPost(
      "/analytics/contribution/trade/entities/factors",
      data
    );

    response = output.data;
  } catch (err) {
    response = err;
  }
  return response;
};

export const getWorkspacePeriodisationGraph = async (data) => {
  let response = null;
  try {
    const output = await axiosPost(
      "/analytics/periodisation/trade/entities/factors",
      data
    );

    response = output.data;
  } catch (err) {
    response = err;
  }
  return response;
};

export const getAccountUsersList = async (accountId) => {
  try {
    let response = await axiosGet(`/accounts/${accountId}/users`);
    return response.data;
  } catch (err) {
    return err;
  }
};

export const getAccountDetails = async (accountId) => {
  try {
    let response = await axiosGet(`/accounts/${accountId}`);
    return response.data;
  } catch (err) {
    return err;
  }
};

export const updateAccountDetails = async (accountId, data) => {
  try {
    let response = await axiosPut(`/accounts/${accountId}`, data);
    return response.data;
  } catch (err) {
    return err;
  }
};

export const updateUserDetails = async (userId, data) => {
  try {
    let response = await axiosPut(`/users/${userId}`, data);
    return response.data;
  } catch (err) {
    return err;
  }
};

export const getUsersDetails = async (userId) => {
  try {
    let response = await axiosGet(`/users/${userId}`);
    return response.data;
  } catch (err) {
    return err;
  }
};

export const deleteUser = async (userId) => {
  let response = null;
  try {
    const output = await axiosDelete(`/users/${userId}`);
    response = output.data;
  } catch (err) {
    response = err;
  }
  return response;
};

export const addNewUser = async (data) => {
  let response = null;
  try {
    const output = await axiosPost("/users", data);

    response = output.data;
  } catch (err) {
    response = err;
  }
  return response;
};

export const getAccountSubscriptions = async (accountId, offset, limit) => {
  try {
    // let response = await axiosGet(`/accounts/${accountId}/subscriptions?limit=${parseInt(limit)}&offset=${parseInt(offset)}`);
    let response = await axiosGet(`/accounts/${accountId}/subscriptions`);
    return response.data;
  } catch (err) {
    return err;
  }
};

export const getSubscriptionPlanTemplates = async () => {
  try {
    let response = await axiosGet(`/subscriptions/plans/templates`);
    return response.data;
  } catch (err) {
    return err;
  }
};

// export const getUserActivityList = async (userId) => {
//   let response = null;
//   try {
//     const output = await axiosGet(`/activity/user/list/${userId}`);

//     response = output.data;
//   } catch (err) {
//     response = err;
//   }
//   return response;
// };

export const getUserActivity = async (payload) => {
  let response = null;
  try {
    const output = await axiosPost(`/activity/user`, payload);
    response = output.data;
  } catch (err) {
    response = err;
  }
  return response;
};

export const addPaymentOrder = async (data) => {
  let response = null;
  try {
    const output = await axiosPost("/orders", data);

    response = output.data;
  } catch (err) {
    response = err;
  }
  return response;
};

export const addPaymentTransaction = async (data) => {
  let response = null;
  try {
    const output = await axiosPost("/payments", data);

    response = output;
  } catch (err) {
    response = err;
  }
  return response;
};

export const getExploreSearchTermForSearchWithCancelReq = async (data) => {
  data.AdxToken = getSessionValue("adxToken");
  data.AdxTokenExpireTime = getSessionValue("time");
  let response = null;
  try {
    const output = await axiosPostWithCancelRequest(
      "/trade/shipments/explore/traders/search",
      data
    );

    response = output;
  } catch (err) {
    response = err;
  }
  return response;
};

export const getWorkspaceSearchTermForSearchWithCancelReq = async (data) => {
  data.AdxToken = getSessionValue("adxToken");
  data.AdxTokenExpireTime = getSessionValue("time");
  let response = null;
  try {
    const output = await axiosPostWithCancelRequest(
      "/workspaces/shipments/analytics/traders/search",
      data
    );

    response = output.data;
  } catch (err) {
    response = err;
  }
  return response;
};

export const getConsigneeShipmentRequestList = async () => {
  try {
    let response = await axiosGet(`/consignee/user/shipment/request/list`);
    return response.data;
  } catch (err) {
    return err;
  }
};

// Recommandation
export const getRelatedSearches = async (data) => {
  data.AdxToken = getSessionValue("adxToken");
  data.AdxTokenExpireTime = getSessionValue("time");
  try {
    let response = await axiosPost(`/recommendation/relatedSearch`, data);
    return response;
  } catch (err) {
    return err;
  }
};
export const getRecommendation = async (data) => {
  try {
    let response = await axiosPost(
      "/recommendation/recommendationSearch",
      data
    );
    return response;
  } catch (err) {
    return err;
  }
};

export const getRecommendationByValue = async (data) => {
  try {
    let response = await axiosPost(
      "/recommendation/recommendationByValue",
      data
    );
    return response;
  } catch (err) {
    return err;
  }
};
export const addUserCredits = async (data, clickedUserId) => {
  try {
    let response = await axiosPut(`/users/addCredits/${clickedUserId}`, data);
    return response.data;
  } catch (err) {
    return err;
  }
};

export const getMarketCountryByCountry = async (data) => {
  try {
    let response = await axiosPost(`/marketanalytics/countries/search`, data);
    return response;
  } catch (err) {
    return err;
  }
};

export const getDifferanceRecords = async (data, payload, callback) => {
  try {
    let response = await axiosPost("/marketanalytics/companies/search", data);
    axiosPost("/marketanalytics/companies/filters", payload)
      .then((res) => {
        callback(res);
      })
      .catch(() => {});
    return response;
  } catch (err) {
    return err;
  }
};

export const marketCountryRecordAndFilter = async (
  Recordpayload,
  filterPayload
) => {
  try {
    const record = axiosPost(
      `/marketanalytics/companies/search`,
      Recordpayload
    );
    const filter = axiosPost(
      `/marketanalytics/companies/filters`,
      filterPayload
    );
    let response = await Promise.all([record, filter]);
    return response;
  } catch (err) {
    return err;
  }
};
export const marketCountryRecord = async (payload) => {
  try {
    const response = await axiosPost(
      `/marketanalytics/companies/search`,
      payload
    );
    return response;
  } catch (err) {
    return err;
  }
};

//market product
export const marketProductRecordAndFilter = async (
  RecordPayload,
  filterPayload
) => {
  try {
    const record = axiosPost(`/marketanalytics/product/search`, RecordPayload);
    const filter = axiosPost(`/marketanalytics/product/filter`, filterPayload);
    let response = await Promise.all([record, filter]);
    return response;
  } catch (err) {
    return err;
  }
};
export const marketProductRecord = async (payload) => {
  try {
    const response = await axiosPost(
      `/marketanalytics/product/search`,
      payload
    );
    return response;
  } catch (err) {
    return err;
  }
};
//market Company
export const marketCompanyRecord = async (payload) => {
  try {
    const response = await axiosPost(`/marketanalytics/trade/search`, payload);
    return response;
  } catch (err) {
    return err;
  }
};
export const marketCompanyRecordAndFilter = async (
  RecordPayload,
  filterPayload
) => {
  try {
    const record = axiosPost(`/marketanalytics/trade/search`, RecordPayload);
    const filter = axiosPost(`/marketanalytics/trade/filter`, filterPayload);
    let response = await Promise.all([record, filter]);
    return response;
  } catch (err) {
    return err;
  }
};
//find unique countries
export const getUniqueCountry = async (payload) => {
  try {
    let response = await axiosPost(
      `/marketanalytics/findUniqueCountries`,
      payload
    );
    return response;
  } catch (err) {
    return err;
  }
};

export const addViewColumns = async (payload) => {
  try {
    let response = await axiosPost(`/trade/addViewColumn`, payload);
    return response;
  } catch (err) {
    return err;
  }
};

export const getViewColumns = async (taxonomy_id) => {
  try {
    const response = await axiosGet(`/trade/getViewColumn/${taxonomy_id}`);

    return response;
  } catch (err) {
    return err;
  }
};

//raise Ticket
export const raiseTicket = async (payload) => {
  try {
    let response = await axiosPost(`/support/raise_ticket`, payload);
    return response;
  } catch (error) {
    return error;
  }
};

export const tradeCountry = async (payload) => {
  try {
    let response = await axiosPost(
      `/marketanalytics/trade/countries/search`,
      payload
    );
    return response;
  } catch (error) {
    return error;
  }
};

//global search

// export const globalSearchExistingUser = async (payload) => {
//   try {
//     let response = await axiosPost(`/globalsearch/existingUser`, payload);
//     return response;
//   } catch (error) {
//     return error;
//   }
// };
export const globalSearchExistingUser = async (payload, callback) => {
  try {
    let response = await axiosPost(
      `/globalSearch/existingUser/search`,
      payload
    );
    return response;
  } catch (err) {
    return err;
  }
};
// get global search
export const globalSearchCountry = async (payload) => {
  try {
    let response = await axiosGet(`/globalSearch/get/country/names`, payload);
    return response;
  } catch (error) {
    return error;
  }
};

//get sorting columns
export const getSortColumns = async (payload) => {
  let response = null;
  try {
    response = await axiosPost(`/trade/sort/schema`, payload);
    return response;
  } catch (error) {
    return error;
  }
};

export const onSearchAnalyze = async (data, count) => {
  try {
    delete data.groupExpressions;
    let responseArray = [];

    let index = count;

    data.length = 100000;

    for (let i = 0; i < index; i += 100000) {
      data.start = i;

      let response = axiosPost("/analysis/shipments/explore/records", data);

      responseArray.push(response);
    }

    responseArray = await Promise.all(responseArray);

    let result = responseArray[0];

    for (let i = 1; i < responseArray.length; i++) {
      result.data.push(responseArray[i].data.data);
    }

    return result;
  } catch (err) {
    return err;
  }
};

export const saveChart = async (payload) => {
  let response = null;
  try {
    response = await axiosPost(`/users/addCharts`, payload);
    return response;
  } catch (error) {
    return error;
  }
};
export const updateChart = async (payload) => {
  let response = null;
  try {
    response = await axiosPost(`/users/updateCharts`, payload);
    return response;
  } catch (error) {
    return error;
  }
};
export const deleteChart = async (id) => {
  let response = null;
  try {
    response = await axiosPost(`/users/deleteCharts`, id);
    return response;
  } catch (error) {
    return error;
  }
};
export const getChart = async (payload) => {
  let response = null;
  try {
    response = await axiosPost(`/users/getCharts`, payload);
    return response;
  } catch (error) {
    return error;
  }
};

export const getExploreFilter = async (payload) => {
  let response = null;
  try {
    response = await axiosPost(
      "/trade/shipments/explore/records/filter",
      payload
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getTaxonomyCountryList = async () => {
  let response = null;
  try {
    response = await axiosGet(`/taxonomies/countries/list`);
    return response;
  } catch (error) {
    return error;
  }
};
export const getPowerBiDashBoard = async (payload) => {
  await checkAndRemoveExpiredSessions();
  let response = null;
  try {
    if (
      sessionStorage.getItem(
        `powerBiResponse_${payload.country}_${payload.tradeType}`
      )
    ) {
      const storedData = JSON.parse(
        sessionStorage.getItem(
          `powerBiResponse_${payload.country}_${payload.tradeType}`
        )
      );
      payload[`powerBiResponse_${payload.country}_${payload.tradeType}`] =
        storedData;
    }

    response = await axiosPost(`/powerbi`, payload);

    if (response && response.data) {
      const data = response.data;
      if (
        data.reportId &&
        data.embedUrl &&
        data.datasetsId &&
        data.accessToken
      ) {
        if (
          !sessionStorage.getItem(
            `powerBiResponse_${payload.country}_${payload.tradeType}`
          )
        ) {
          data.storedTimestamp = new Date().getTime();
          sessionStorage.setItem(
            `powerBiResponse_${payload.country}_${payload.tradeType}`,
            JSON.stringify(data)
          );
        }
      }
    }

    return response;
  } catch (error) {
    return error;
  }
};
export const getPowerBiDashBoardForViewSummary = async (payload) => {
  await checkAndRemoveExpiredSessions();
  let response = null;
  try {
    if (
      sessionStorage.getItem(
        `powerBiResponse_view_summary_${payload.country}_${payload.tradeType}`
      )
    ) {
      const storedData = JSON.parse(
        sessionStorage.getItem(
          `powerBiResponse_view_summary_${payload.country}_${payload.tradeType}`
        )
      );
      payload[
        `powerBiResponse_view_summary_${payload.country}_${payload.tradeType}`
      ] = storedData;
    }

    response = await axiosPost(`/powerbi`, payload);

    if (response && response.data) {
      const data = response.data;
      if (
        data.reportId &&
        data.embedUrl &&
        data.datasetsId &&
        data.accessToken
      ) {
        if (
          !sessionStorage.getItem(
            `powerBiResponse_view_summary_${payload.country}_${payload.tradeType}`
          )
        ) {
          data.storedTimestamp = new Date().getTime();
          sessionStorage.setItem(
            `powerBiResponse_view_summary_${payload.country}_${payload.tradeType}`,
            JSON.stringify(data)
          );
        }
      }
    }

    return response;
  } catch (error) {
    return error;
  }
};
export const getPowerBiDashBoardForWorkSpace = async (payload) => {
  await checkAndRemoveExpiredSessions();
  let response = null;
  try {
    if (
      sessionStorage.getItem(
        `powerBiResponse_workspace_${payload.country}_${payload.tradeType}`
      )
    ) {
      const storedData = JSON.parse(
        sessionStorage.getItem(
          `powerBiResponse_workspace_${payload.country}_${payload.tradeType}`
        )
      );
      payload[
        `powerBiResponse_workspace_${payload.country}_${payload.tradeType}`
      ] = storedData;
    }

    response = await axiosPost(`/workspaces/powerbi`, payload);
    if (response) {
      const { data } = response;
      if (
        data?.reportId &&
        data?.embedUrl &&
        data?.datasetsId &&
        data?.accessToken
      ) {
        if (
          !sessionStorage.getItem(
            `powerBiResponse_workspace_${payload.country}_${payload.tradeType}`
          )
        ) {
          data.storedTimestamp = new Date().getTime();
          sessionStorage.setItem(
            `powerBiResponse_workspace_${payload.country}_${payload.tradeType}`,
            JSON.stringify(data)
          );
        }
      }
    }
    return response;
  } catch (error) {
    return error;
  }
};
export const getPowerBi = async (payload, accessToken) => {
  let response = null;
  try {
    response = await axiosPostWithToken(
      `https://api.powerbi.com/v1.0/myorg/GenerateToken`,
      payload,
      accessToken
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getAdxToken = async () => {
  try {
    let response = await axiosGetWithOutAdxToken(`/getadxToken`);
    return response;
  } catch (error) {
    return error;
  }
};
export const getVideoIndex = async () => {
  let response = null;
  try {
    response = await axiosGet(`/video`);
    return response;
  } catch (error) {
    return error;
  }
};
export const getSelectedVideo = async (id) => {
  let response = null;
  try {
    response = await axiosGet(`/video/${id}`);
    return response;
  } catch (error) {
    return error;
  }
};
export const getSasToken = async () => {
  let response = null;
  try {
    response = await axiosGet(`/video/generate/token`);
    return response;
  } catch (error) {
    return error;
  }
};
export const getVideoRelatedFeedback = async (payload) => {
  let response = null;
  try {
    response = await axiosPost(`/video/feedback/add`, payload);
    return response;
  } catch (error) {
    return error;
  }
};
export const getUserSearchCount = async (payload) => {
  let response = null;
  try {
    response = await axiosPost(`/trade/summarylimit`, payload);
    return response;
  } catch (error) {
    return error;
  }
};
export const getUserSession = async (payload) => {
  let response = null;
  try {
    response = await axiosPost(`/auths/loginflag`, payload);
    return response;
  } catch (error) {
    return error;
  }
};
export const fetchUserData = async (payload) => {
  let response = null;
  try {
    response = await axiosPost(`/ticketmanager/user`, payload);
    return response;
  } catch (error) {
    return error;
  }
};
export const fetchTicketTableData = async (payload) => {
  let response = null;
  try {
    response = await axiosPost(`/ticketmanager/list/tickets`, payload);
    return response;
  } catch (error) {
    return error;
  }
};
export const createUserTicket = async (payload) => {
  let response = null;
  try {
    response = await axiosPost(`/ticketmanager/createticket`, payload);
    return response;
  } catch (error) {
    return error;
  }
};
export const getUserEmails = async (user_Id) => {
  let response = null;
  try {
    response = await axiosGet(`/activity/email/suggestion/${user_Id}`);
    return response;
  } catch (error) {
    return error;
  }
};
export const getUserWorkSpaceActivity = async (payload) => {
  let response = null;
  try {
    response = await axiosPost(`/activity/user`, payload);
    return response;
  } catch (error) {
    return error;
  }
};
export const getTicketInfo = async (payload) => {
  let response = null;
  try {
    response = await axiosPost(`/ticketmanager/conversations`, payload);
    return response;
  } catch (error) {
    return error;
  }
};
export const getRecentSearches = async (payload) => {
  let response = null;
  try {
    response = await axiosPost(`/trade/getrecentseaches`, payload);
    return response;
  } catch (error) {
    return error;
  }
};
export const getPotentialMarketData = async (payload) => {
  let response = null;
  try {
    response = await axiosPost(`/trade/potential/market`, payload);
    return response;
  } catch (error) {
    return error;
  }
};
export const getCompetitorsData = async (payload) => {
  let response = null;
  try {
    response = await axiosPost(`/trade/competitors`, payload);
    return response;
  } catch (error) {
    return error;
  }
};
export const getGroupExpressionOfCountry = async (payload) => {
  let response = null;
  try {
    response = await axiosPost(`/dashboard/getTaxnomonies`, payload);
    return response;
  } catch (error) {
    return error;
  }
};
export const getCompanyDetailData = async (payload) => {
  let response = null;
  try {
    response = await axiosPost(`/contact/social/search`, payload);
    return response;
  } catch (error) {
    return error;
  }
};
export const getCountryNameFromCompany = async (payload) => {
  let response = null;
  try {
    response = await axiosPost(`/trade/getCountry`, payload);
    return response;
  } catch (error) {
    return error;
  }
};
export const getCompanyDetails = async (payload) => {
  let response = null;
  try {
    response = await axiosPost(`/contact/social/getcompnaydetails`, payload);
    return response;
  } catch (error) {
    return error;
  }
};
export const getCompanyEmployeeDetails = async (payload) => {
  let response = null;
  try {
    response = await axiosPost(`/contact/social/linkedin`, payload);
    return response;
  } catch (error) {
    return error;
  }
};
export const userRequestForCompanyData = async (payload) => {
  let response = null;
  try {
    response = await axiosPost(`/contact/request/create`, payload);
    return response;
  } catch (error) {
    return error;
  }
};
export const getUserRequestedDataInfo = async (payload) => {
  let response = null;
  try {
    response = await axiosPost(`/contact/request/fetch`, payload);
    return response;
  } catch (error) {
    return error;
  }
};

export const getRequestedDataInfo = async (payload) => {
  let response = null;
  try {
    response = await axiosPost(`/ticketmanager/conversations`, payload);
    return response;
  } catch (error) {
    return error;
  }
};

export const getEmailAndPhoneOfEmployee = async (payload) => {
  let response = null;
  try {
    response = await axiosPost(`/contact/social/linkedin/email`, payload);
    return response;
  } catch (error) {
    return error;
  }
};

export const handlePointDeduct = async (payload) => {
  let response = null;
  try {
    response = await axiosPost(`/contact/social/pointDeduction`, payload);
    return response;
  } catch (error) {
    return error;
  }
};

export const getLLMEmployeeData = async (payload) => {
  let response = null;
  try {
    response = await axiosPost(`/contact/linkedin/v0`, payload);
    return response;
  } catch (error) {
    return error;
  }
};

export const getBuyerSellerData = async (payload) => {
  let response = null;
  try {
    response = await axiosPost(`/trade/recommend/buyerseller`, payload);
    return response;
  } catch (error) {
    return error;
  }
};

export const favCompanyAnalysis = async (payload) => {
  let response = null;
  try {
    response = await axiosPost(`/trade/favourite/company/analysis`, payload);
    return response;
  } catch (error) {
    return error;
  }
};

export const searchAnalysis = async (payload) => {
  let response = null;
  try {
    response = await axiosPost(`/trade/search/analysis`, payload);
    return response;
  } catch (error) {
    return error;
  }
};

const exportReportToPDF = async (
  groupId,
  reportId,
  accessToken,
  pageNameForDownload
) => {
  const url = `https://api.powerbi.com/v1.0/myorg/groups/${groupId}/reports/${reportId}/ExportTo`;

  const body = {
    format: "PDF",
    powerBIReportConfiguration: {
      pages: [{ pageName: pageNameForDownload }],
    },
  };
  const response = await axiosPostWithOutUrl(url, body, accessToken);

  return response?.data?.id;
};

const getExportStatus = async (groupId, reportId, jobId, accessToken) => {
  const url = `https://api.powerbi.com/v1.0/myorg/groups/${groupId}/reports/${reportId}/exports/${jobId}`;

  while (true) {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const data = response.data;

    if (data.status === "Succeeded") {
      const downloadUrl = await downloadExportedFile(
        groupId,
        reportId,
        jobId,
        accessToken
      );
      return downloadUrl;
    } else if (data.status === "Failed") {
      throw new Error("Export job failed");
    }

    // Wait for a few seconds before checking the status again
    await new Promise((resolve) => setTimeout(resolve, 5000));
  }
};

const downloadExportedFile = async (groupId, reportId, jobId, accessToken) => {
  const downloadUrl = `https://api.powerbi.com/v1.0/myorg/groups/${groupId}/reports/${reportId}/exports/${jobId}/file`;

  const response = await axios.get(downloadUrl, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    responseType: "blob",
  });

  return response?.data;
};

const getReportPages = async (groupId, reportId, accessToken) => {
  const url = `https://api.powerbi.com/v1.0/myorg/groups/${groupId}/reports/${reportId}/pages`;

  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data.value;
  } catch (error) {
    console.error(
      "Error fetching report pages:",
      error.response?.data || error.message
    );
    throw new Error("Failed to fetch report pages");
  }
};

export const handleExport = async (
  groupId,
  reportId,
  access,
  pageDisplayName
) => {
  try {
    const accessToken = access;

    const pages = await getReportPages(groupId, reportId, accessToken);
    const targetPage = pages.find(
      (page) => page.displayName === pageDisplayName
    );

    if (!targetPage) {
      throw new Error(`Page with display name "${pageDisplayName}" not found.`);
    }

    const jobId = await exportReportToPDF(
      groupId,
      reportId,
      accessToken,
      targetPage?.name
    );
    const file = await getExportStatus(groupId, reportId, jobId, accessToken);

    // Create a link element to download the file
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(
      new Blob([file], { type: "application/pdf" })
    );
    link.download = `${pageDisplayName}-report.pdf`;
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error) {
    console.error("Error exporting to PDF:", error);
  } finally {
    console.log("finally block");
  }
};

export const manageUserRoleOnActivation = async (payload) => {
  let response = null;
  try {
    response = await axiosPut(`/accounts/activate/admin`, payload);
    return response;
  } catch (error) {
    return error;
  }
};

export const getMirrorAccessCountry = async (payload) => {
  let response = null;
  try {
    response = await axiosPost(`/trade/isocode/country`, payload);
    return response;
  } catch (error) {
    return error;
  }
};

export const getPlanDetail = async () => {
  let response = null;
  try {
    const output = await axiosGet(`/auths/plan/verify`);
    response = output.data;
  } catch (err) {
    response = err;
  }
  return response;
};

export const getPasswordResetForSales = async (data) => {
  let response = null;
  try {
    const output = await axiosPostWithoutAuth("/sales/resetPassword", data);
    response = output;
  } catch (err) {
    response = err;
  }
  return response;
};

export const getPasswordVerifyForSales = async (data) => {
  let response = null;
  try {
    const output = await axiosPostWithoutAuth("/sales/verifyPassword", data);
    response = output.data;
  } catch (err) {
    response = err;
  }
  return response;
};

export const salesActivityTrack = async (payload) => {
  let response = null;
  try {
    response = await axiosPost("/customerTracker/add/activity", payload);
    return response;
  } catch (error) {
    return error;
  }
};

export const fetchPopUps = async () => {
  let response = null;
  try {
    response = await axiosGet("/popups");
    return response;
  } catch (error) {
    return error;
  }
};

export const updatePopStatus = async (payload) => {
  let response = null;
  try {
    response = await axiosPost("/popups/update_status", payload);
    return response;
  } catch (error) {
    return error;
  }
};
