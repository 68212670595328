import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import millify from "millify";
import "./analyzeMain.scss";
import AnalyzeTable from "./Component/AnalyzeTable";
import AnalyzeField from "./Component/AnalyzeField";
import { useMemo } from "react";
import { useRef } from "react";
import {
  INDIA_ANALYZE_EXPORT_HEADER,
  INDIA_ANALYZE_IMPORT_HEADER,
  STATIC_COLUMNS_EXPORT,
  STATIC_COLUMNS_IMPORT,
  STATIC_COLUMNS_CHART_EXPORT,
  STATIC_COLUMNS_CHART_IMPORT,
} from "../../IndiaTradeRecords/records";
import AddIcon from "@mui/icons-material/Add";
import ModalComponent from "../Modal/modal";
import ChartModalContent from "./Component/ChartModalContent";
import { isBarChartValidation, isLineChartValidation, isPieChartValidation } from "../../utils/ChartsValidation";
import ErrorLoading from "../../Component/ErrorLoader/error";
import ChartBox from "./Component/ChartBox";
import { deleteChart, getChart, saveChart, updateChart } from "../../Services/service";
import { useLocation } from "react-router-dom";
import Loading from "../SuccessLoader/success";
import ErrorData from "../../utils/ErrorHandling";
import { ButtonComponent } from "../ButtonComponent/Button";
import DeleteIcon from "../../Asset/images/delete.png";
import ConfirmationModal from "../../Component/ConfirmationModal/ConfirmationModal";
import { USA_ANALYZE_IMPORT_HEADER, USA_STATIC_COLUMNS_CHART_IMPORT, USA_STATIC_COLUMNS_IMPORT } from "./Analyze.enum";
const plus = require(`../../Asset/images/more.png`);
const minus = require(`../../Asset/images/minus.png`);

const columns = {
  india: {
    IMPORT: [{ _id: "QUANTITY" }, { _id: "TOTAL VALUE USD" }, { _id: "DUTY PAID INR" }, { _id: "TOTAL VALUE INR" }],
    EXPORT: [{ _id: "QUANTITY" }, { _id: "FOB USD" }, { _id: "FOB INR" }, { _id: "DRAWBACK" }]
  },
  usa: {
    IMPORT: [{ _id: "QUANTITY" }, { _id: "WEIGHT IN KG" }, { _id: "CIF" }, { _id: "TEU" }],
  }
}

const staticColumnsData = {
  india: {
    IMPORT: STATIC_COLUMNS_IMPORT,
    EXPORT: STATIC_COLUMNS_EXPORT
  },
  usa: {
    IMPORT: USA_STATIC_COLUMNS_IMPORT,
  }
}

const AnalyzeHeader = {
  india: {
    IMPORT: INDIA_ANALYZE_IMPORT_HEADER,
    EXPORT: INDIA_ANALYZE_EXPORT_HEADER
  },
  usa: {
    IMPORT: USA_ANALYZE_IMPORT_HEADER,
  }
}
const staticColumns_chart = {
  india: {
    IMPORT: STATIC_COLUMNS_CHART_IMPORT,
    EXPORT: STATIC_COLUMNS_CHART_EXPORT
  },
  usa: {
    IMPORT: USA_STATIC_COLUMNS_CHART_IMPORT,

  }
}
const AnalyzeMain = (props) => {
  const [error, setError] = useState(false);
  const [currentChartData, setCurrentChartData] = useState({});
  const search = useLocation().search;
  const currentCountry = new URLSearchParams(search).get("country");
  const [errorMsg, seterrorMsg] = useState({});
  const [chartsComponent, setChartsComponent] = useState([]);
  const reactSelectLoading = false;
  const [chartValues, setChartValues] = useState({});
  const [isUpdateModal, setUpdateModal] = useState(false);
  const getSearchOption = columns[currentCountry.toLowerCase()][props?.tradeType];
  const [inputValues, setInputValues] = useState(columns[currentCountry.toLowerCase()][props?.tradeType]);
  const [openModal, setOpenModal] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState({});
  const [loading, setLoading] = useState(false);

  // Delete chart
  const [isDeleteChart, setDeleteChart] = useState(false);

  const [header, setHeader] = useState([]);
  const [rowData, setRowData] = useState();
  const selectedColumns = staticColumnsData[currentCountry.toLowerCase()][props?.tradeType];
  const gridRef = useRef();
  let staticColumns = staticColumnsData[currentCountry.toLowerCase()][props?.tradeType];

  const handleSearchForTable = (value) => {
    if (value === null) {
      setInputValues(null);
    } else {
      setInputValues(value);
      handleChange(value);
    }
  };

  const currencyFormatter = (params) => {
    return millify(params.value);
  };


  const handleChange = (value) => {
    let array = header.filter((e) => staticColumns.includes(e.headerName));
    let _header = header.filter((e) => !staticColumns.includes(e.headerName));

    array.forEach((item) => {
      if (value.filter((ele) => ele._id === item.headerName)[0]) {
        item.hide = false;
      } else {
        item.hide = true;
      }
      _header.push(item);
    });
    setHeader(_header);
    // setSelectedColumns(value);
  };

  useEffect(() => {
    let array = AnalyzeHeader[currentCountry?.toLowerCase()][props?.tradeType]
    let headerColumn = [];

    array.forEach((e) => {
      let obj = {
        headerName: e.title,
        field: e.key,
        aggFunc: "sum",
        hide: true,
        chartDataType: "category",
        cellStyle: { border: " 0.5px solid #dfdfdf" },
        filterParams: {
          excelMode: "windows",
        },
      };

      if (staticColumns.includes(e.title)) {
        obj.hide = false;
        obj.filter = "agNumberColumnFilter";
        obj.valueFormatter = currencyFormatter;
        obj.suppressColumnsToolPanel = true;
        obj.chartDataType = "series";
      }

      headerColumn.push(obj);
    });
    setHeader(headerColumn);
  }, []);

  const handleBackPage = () => {
    props.setOpenAnalyzePage(false);
    props.setIsRecordBtnChecked(true);
  };

  const autoGroupColumnDef = useMemo(() => {
    return {
      // headerName: "treeview",
      field: "",
      minWidth: 300,
      cellStyle: { border: " 1.0px solid #dfdfdf" },
      cellRendererParams: {
        suppressDoubleClickExpand: true,
      },
    };
  }, []);

  // useEffect(() => {
  //   window.addEventListener("resize", updateDimensions);
  //   function updateDimensions() {
  //     let middleContentHeight = document.getElementById("middle-content").offsetHeight;
  //     if (middleContentHeight) {
  //       const agThemeAlpine = document.querySelector(".ag-theme-alpine");
  //       if (agThemeAlpine) {
  //         agThemeAlpine.style.height = `${middleContentHeight - 94}px`;
  //       }
  //     }
  //   }
  // }, []);

  const gridOptions = {
    suppressMakeColumnVisibleAfterUnGroup: true,
    suppressContextMenu: true,
    onColumnRowGroupChanged: function (params) {
      window.colState = gridRef.current.columnApi.getColumnState();

      var x = window.colState.filter((e) => e.rowGroup === true);

      if (x.length === 0) {
        // setRowData([]);
        document.querySelector(".ag-body-clipper").style.filter = "blur(5px)";
      } else {
        // let middleContentHeight = document.getElementById("middle-content").offsetHeight;
        document.querySelector(".ag-body-clipper").style.filter = "none";
        // document.querySelector(".ag-theme-alpine").style.height = `${middleContentHeight - 94}px`;
        setRowData(props?.analyzeData);
      }
    },

    getRowStyle: function (params) {
      window.colState = gridRef.current.columnApi.getColumnState();
      var dataLength = window.colState.filter((e) => e.rowGroup === true);

      if (params.node && params.node.level === dataLength.length - 1) {
        return { pointerEvents: "none" };
      }
      return null;
    },
  };

  const defaultColDef = useMemo(() => {
    return {
      editable: false,
      sortable: true,
      filter: true,
      flex: 1,
      minWidth: 100,
      enableRowGroup: true,
      enablePivot: false,
      resizable: true,
      enableValue: true,
    };
  }, []);

  const icons = useMemo(() => {
    return {
      groupContracted: `<img src=${plus} style="height: 15px; width: 17px;padding-right: 2px"/>`,
      groupExpanded: `<img src=${minus} style="height: 15px; width: 17px;padding-right: 2px"/>`,
    };
  }, []);

  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",

          toolPanelParams: {
            suppressValues: true,
            suppressPivots: true,
            suppressPivotMode: true,
            suppressColumnSelectAll: true,
            suppressColumnsToolPanel: true,
          },
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
          toolPanelParams: {
            excelMode: "windows",
          },
        },
      ],
      position: "left",
      defaultToolPanel: "columns",
    };
  }, []);
  const localeText = useMemo(() => {
    return {
      applyFilter: "Apply",
      cancelFilter: "Clear",
    };
  }, []);

  const isValid = () => {
    if (chartValues && Object.keys(chartValues).length > 0 && chartValues.hasOwnProperty("chartType")) {
      if (chartValues?.chartType === "PIE_CHART") {
        return isPieChartValidation(chartValues?.groupByValue, chartValues?.measuresValue);
      } else if (chartValues?.chartType === "BAR_CHART") {
        return isBarChartValidation(chartValues?.xAxisValue, chartValues?.yAxisValue);
      } else if (chartValues?.chartType === "LINE_CHART") {
        return isLineChartValidation(chartValues?.xAxisValue, chartValues?.yAxisValue);
      }
    } else {
      return false;
    }
  };

  const getPayload = () => {
    let data = {};
    if (chartValues?.chartType === "PIE_CHART") {
      data = {
        groupValue: chartValues?.groupByValue,
        messasureValue: chartValues?.measuresValue,
      };
    } else if (chartValues?.chartType === "BAR_CHART") {
      data = {
        xkey: chartValues?.xAxisValue,
        ykey: chartValues?.yAxisValue,
        ykey2: chartValues?.yAxis2Value,
      };
    } else if (chartValues?.chartType === "LINE_CHART") {
      data = { xkey: chartValues?.xAxisValue, ykey: chartValues?.yAxisValue };
    }
    return {
      type: chartValues?.chartType,
      title: chartValues?.title,
      recordLengthValue: chartValues?.recordLengthValue,
      country: currentCountry,
      trade: props.tradeType,
      data: data,
    };
  };

  const getChartData = async (title, time) => {
    setLoading(true);
    setLoadingMsg({
      title: title,
      content: "Please Wait..",
    });
    let payload = {
      country: currentCountry,
      trade: props?.tradeType,
    };
    await getChart(payload)
      .then((res) => {
        if (res.status === 200) {
          if (res && res?.data && res?.data?.data) {
            const chartData = res?.data?.data;
            const _chartsComponent = chartData.map((el) => {
              return { ...el, isShowChart: true };
            });
            setChartsComponent(_chartsComponent);
          }
        } else {
          throw Error();
        }
      })
      .catch((err) => {
        setError(true);
        seterrorMsg({
          title: err?.status === 401 ? "Warning" : "Error",
          content: err?.status === 500 ? ErrorData?.INTERNAL_SERVER : err?.status === 401 ? ErrorData?.SESSION_TIMEOUT : ErrorData?.SOMETHING_WENT_WRONG,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getChartData("Fetching Charts..", 2500);
  }, []);

  const handleSavedClick = async () => {
    if (isValid()) {
      if (isUpdateModal) {
        const payload = getPayload();
        payload._id = currentChartData._id;
        await updateChart(payload)
          .then(async (res) => {
            if (res?.status === 200) {
              await getChartData("Updating Charts..", 2000);
            } else {
              throw Error();
            }
          })
          .catch((err) => {
            setError(true);
            seterrorMsg({
              title: err?.status === 401 ? "Warning" : "Error",
              content: err?.status === 500 ? ErrorData?.INTERNAL_SERVER : err?.status === 401 ? ErrorData?.SESSION_TIMEOUT : ErrorData?.SOMETHING_WENT_WRONG,
            });
          });
      } else {
        const payload = getPayload();
        await saveChart(payload)
          .then(async (res) => {
            if (res?.status === 200) {
              await getChartData("Creating Charts..", 2000);
            } else {
              throw Error();
            }
          })
          .catch((err) => {
            setError(true);
            seterrorMsg({
              title: err?.status === 401 ? "Warning" : "Error",
              content: err?.status === 500 ? ErrorData?.INTERNAL_SERVER : err?.status === 401 ? ErrorData?.SESSION_TIMEOUT : ErrorData?.SOMETHING_WENT_WRONG,
            });
          });
      }
      setOpenModal(false);
      setError(true);
      seterrorMsg({
        title: "Success",
        content: `Chart ${isUpdateModal ? "Updated" : "Addedd"} Successfully`,
      });
    } else {
      setError(true);
      seterrorMsg({
        title: "Warning",
        content: "Please select the values",
      });
    }
  };

  const handleDeleteChart = async () => {
    setDeleteChart(false);
    try {
      const { _id } = currentChartData;
      setLoading(true);
      setLoadingMsg({
        title: "Deleting Chart",
        content: "Please Wait..",
      });
      await deleteChart({ _id })
        .then(async (res) => {
          if (res?.status === 200) {
            await getChartData("Deleting Chart", 2000);
            setLoading(false);
          } else {
            throw Error();
          }
        })
        .catch((err) => {
          setError(true);
          seterrorMsg({
            title: err?.status === 401 ? "Warning" : "Error",
            content: err?.status === 500 ? ErrorData?.INTERNAL_SERVER : err?.status === 401 ? ErrorData?.SESSION_TIMEOUT : ErrorData?.SOMETHING_WENT_WRONG,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) { }
  };
  const handleMinMaxChart = (id, isMinimized) => {
    const _chartsComponent = [...chartsComponent];
    _chartsComponent.forEach((chart) => {
      if (chart?.id === id) {
        chart.isShow = isMinimized;
      }
    });
    setChartsComponent(_chartsComponent);
  };

  const onGridReady = (params) => {
    setRowData(props?.analyzeData);
  };

  const handleDownload = () => {
    const page = gridRef.current.api.paginationProxy.currentPage * gridRef.current.api.paginationProxy.pageSize;
    const nextPage = (gridRef.current.api.paginationProxy.currentPage + 1) * gridRef.current.api.paginationProxy.pageSize;
    const excelParams = {
      fileName: "AnalyzeData.xlsx",
      sheetName: "Sheet1",
      exportMode: "xlsx",
      shouldRowBeSkipped: (params) => params.node.rowIndex < page || params.node.rowIndex >= nextPage, // Skip rows after the first 10 rows
      suppressRowRanges: true, // Disable the default row range suppression
      shouldRowBeCollapsed: function (params) {
        return true; // Collapse all rows
      },
      columnWidth: 300, // Set the width for the header cell
    };
    gridRef.current.api.exportDataAsExcel(excelParams);
  };

  const excelStyles = useMemo(() => {
    return [
      {
        id: "header",
        alignment: {
          vertical: "Center",
        },
        interior: {
          color: "#005d91",
          pattern: "Solid",
          patternColor: undefined,
        },
        font: {
          fontName: "Calibri Light",
          size: 12,
          color: "#ffffff",
          bold: true,
        },
      },
    ];
  }, []);

  const defaultExcelExportParams = useMemo(() => {
    return {
      headerRowHeight: 50,
    };
  }, []);

  const handlingShowTable = (chart) => {
    const newData = chartsComponent.map((el) => {
      if (el?._id === chart?._id) {
        el = { ...el, isShowChart: !el?.isShowChart };
      }
      return el;
    });
    setChartsComponent(newData);
  };

  return (
    <div className="mainDiv" style={{ padding: "0px 24px" }}>
      {loading && <Loading title={loadingMsg.title} content={loadingMsg.content} />}
      {error && <ErrorLoading errorMsg={errorMsg} open={error} setOpen={setError} />}

      <ModalComponent
        open={openModal}
        setOpen={setOpenModal}
        header={`${isUpdateModal ? "Update" : "Add"} Chart`}
        modalBody={
          <ChartModalContent
            data={props?.analyzeData}
            headers={AnalyzeHeader[currentCountry?.toLowerCase()][props?.tradeType]}
            columnsValues={staticColumns_chart[currentCountry.toLowerCase()][props?.tradeType]}
            setChartValues={setChartValues}
            setUpdateModal={setUpdateModal}
            isUpdateModal={isUpdateModal}
            currentChartData={currentChartData}
            initialChartType={isUpdateModal ? currentChartData?.type : "PIE_CHART"}
            initialRecordLengthValue={isUpdateModal ? currentChartData?.recordLengthValue?.key : "3"}
            STATIC_COLUMNS={staticColumnsData[currentCountry.toLowerCase()][props?.tradeType]}
          />
        }
        savedClicked={() => {
          handleSavedClick();
        }}
        saveText={isUpdateModal ? "Update" : "Save"}
        saveButtonShown={true}
        saveButtonColor="#1abc9c"
        width={"50%"}
        className="zoom_out_1_33"
      />
      <ConfirmationModal
        open={isDeleteChart}
        setOpen={setDeleteChart}
        header="Delete Chart"
        modalBody={
          <div style={{ padding: "15px", display: "flex", flexDirection: "row", alignItems: "center" }}>
            <div style={{ margin: "10px" }}>
              <img src={DeleteIcon} alt="delete" />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span style={{ fontSize: "22px", fontWeight: 600, color: "rgb(50, 58, 70)" }}>Delete</span>
              <div style={{ fontSize: "16px", marginTop: "20px" }}>
                Are you sure you want to delete this chart?
              </div>
            </div>
          </div>
        }
        width="600px"
        saveText="Delete"
        saveButtonShown={true}
        saveButtonColor="#f1556c"
        savedClicked={handleDeleteChart}
      />
      <Box id="excel" style={{ display: "block" }}>
        <AnalyzeField
          handleBackPage={handleBackPage}
          handleChange={handleChange}
          selectedColumns={selectedColumns}
          staticColumns={staticColumns}
          header={header}
          searchFieldName="Select columns"
          handleSearchForTable={handleSearchForTable}
          getSearchOption={getSearchOption}
          reactSelectLoading={reactSelectLoading}
          inputValues={inputValues}
        />
        <AnalyzeTable
          header={header}
          onGridReady={onGridReady}
          rowData={rowData}
          defaultColDef={defaultColDef}
          icons={icons}
          autoGroupColumnDef={autoGroupColumnDef}
          sideBar={sideBar}
          gridRef={gridRef}
          gridOptions={gridOptions}
          localeText={localeText}
          handleDownload={handleDownload}
          excelStyles={excelStyles}
          defaultExcelExportParams={defaultExcelExportParams}
        />
      </Box>
      <Grid container style={{ marginTop: "20px", marginLeft: "0px" }} className="zoom_out_1_33">
        {chartsComponent &&
          chartsComponent?.length > 0 &&
          chartsComponent?.map((chart, index) => {
            return (
              <ChartBox
                chart={chart}
                index={index}
                chartLength={chartsComponent?.length}
                setDeleteChart={setDeleteChart}
                analyzeData={props?.analyzeData}
                handleMinMaxChart={handleMinMaxChart}
                setUpdateModal={setUpdateModal}
                setCurrentChartData={setCurrentChartData}
                setOpen={setOpenModal}
                handlingShowTable={handlingShowTable}
              />
            );
          })}
      </Grid>
      <Box style={{ display: "flex", justifyContent: "center" }}>
        <ButtonComponent
          name={" Add Chart"}
          btnIcon={AddIcon}
          style={{ margin: "50px 0px", fontSize: "23px", height: "50px" }}
          btnClick={() => setOpenModal(true)}
        />
      </Box>
    </div>
  );
};

export default AnalyzeMain;
