import React, { useState, useRef } from "react";
import "./shipment.scss";
import { useEffect } from "react";
import Loading from "../../Component/SuccessLoader/success";
import {
  INDIA_EXPORT_COLUMN_NAME,
  INDIA_IMPORT_COLUMN_NAME,
} from "../../IndiaTradeRecords/records";
import { getFavouriteShipments, getTaxonomy } from "../../Services/service";
import { Box, Grid } from "@mui/material";
import CollapsibleTable from "../../Component/CollapseDataTable/CollapseTable";
import ModalComponent from "../../Component/Modal/modal";
import ExploreDataTableModal from "../../Component/ExploreDataTableModal/exploreModal";
import Error from "../../Component/ErrorLoader/error";
import { useReactToPrint } from "react-to-print";
import throwError from "../../utils/throwError";
import { getIECSearchData } from "../../Services/service";
import ErrorData from "../../utils/ErrorHandling";
import {
  Fav_shipment_absent,
  Retrieving_Fav_Shipments,
} from "../../utils/LoadingMsg";
import { shipmentTableHeader } from "./shipment.enum";
import {
  KAZAKHSTAN_EXPORT_RECORDS,
  KAZAKHSTAN_IMPORT_RECORDS,
} from "../../kazakhstanTradeRecords/KazakRecords";
import { logger } from "../../Loggers/Loggers";
export default function Shipment() {
  const PrintRef = useRef();
  const SHIPMENT_FIELD_RECORDS_TAG = "RECORDS_TAG";
  const [loading, setLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState({});
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState({});
  const [favouriteShipments, setFavouriteShipments] = useState([]);
  const [noDataFound, setNoDataFound] = useState(false);
  const [getTaxonomyData, setTaxonomyData] = useState([]);
  const [getRowData, setRowData] = useState([]);
  const [isCollapseModelDetails, setIsCollapseModelDetails] = useState({
    companyDetails: true,
    productDetails: true,
    otherDetails: true,
    IECDetails: true,
  });
  const [currentCountryOrTrade, setCurrentCountryOrTrade] = useState({});
  const [storeEyeModalRowData, setStoreEyeModalRowData] = useState({});
  const [modalheaderArr, setModalArr] = useState([]);
  const [collapseTable, setCollapseTable] = useState(true);
  const [openCollapseModal, setOpenCollapseModal] = useState(false);
  const [prevOrNext, setPrevOrNext] = useState(0);
  const [childData, setChildData] = useState([]);
  const [childdataTableHead, setChilddataTableHead] = useState([]);
  const [IECDetails, setIECDetails] = useState([]);

  const setErrorForDashboard = (err) => {
    const errorForLogger = `Favorite Shipment:- ${
      err?.message ? err?.message : err?.msg
    }`;
    logger(errorForLogger);
    setLoading(false);
    setError(true);
    setErrorMsg({
      title: err.status === 401 ? "Warning" : "Error",
      content:
        err.status === 500
          ? ErrorData.INTERNAL_SERVER
          : err.status === 401
          ? err.msg
          : ErrorData.SOMETHING_WENT_WRONG,
    });
  };
  useEffect(() => {
    setLoading(true);
    setLoadingMsg(Retrieving_Fav_Shipments);
    getFavouriteShipments()
      .then((successPayload) => {
        if (successPayload?.status === 500 || successPayload?.status === 401) {
          throw throwError(successPayload);
        } else {
          if (successPayload?.favoriteShipment) {
            if (successPayload.favoriteShipment.length === 0) {
              setNoDataFound(true);
              setLoadingMsg(Fav_shipment_absent);
            }
            setFavouriteShipments(successPayload.favoriteShipment);
            let getCountryData = [];
            let Data = [];
            successPayload.favoriteShipment &&
              successPayload.favoriteShipment.length &&
              successPayload.favoriteShipment.forEach((fav) => {
                let concatTradeWithCountry =
                  fav.country + fav.tradeType + fav.bl_flag;
                if (
                  !getCountryData.includes(concatTradeWithCountry) &&
                  fav.isFavorite
                ) {
                  Data.push({
                    country:
                      fav.bl_flag === 1
                        ? capitalizeFirstLetterOfWords(fav.country) + "(bl)"
                        : capitalizeFirstLetterOfWords(fav.country),
                    tradeType: fav.tradeType,
                    bl_flag: fav.bl_flag,
                    key: fav?._id,
                  });
                  getCountryData.push(concatTradeWithCountry);
                }
              });

            setRowData(Data);
          } else {
            throw new Error();
          }
        }
      })
      .catch((err) => {
        setErrorForDashboard(err);
      });
    let payload = {
      currentTradeType: "",
      currentCountry: "",
    };
    getTaxonomy(payload)
      .then((e) => {
        if (e?.data) {
          setLoading(false);
          setTaxonomyData(e.data);
        } else {
          setError(true);
          setErrorMsg({
            title: "Error",
            content: ErrorData.SOMETHING_WENT_WRONG,
          });
        }
      })
      .catch((err) => {
        setErrorForDashboard(err);
      });
  }, []);

  const handleClickCompositionTableRow = (data) => {
    setCurrentCountryOrTrade(data);
  };

  function capitalizeFirstLetterOfWords(str) {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }

  const handleCollapseDetails = (e) => {
    const name = e.target.id;

    setIsCollapseModelDetails({
      ...isCollapseModelDetails,
      [name]: !isCollapseModelDetails[name],
    });
  };

  const getIECSearchDetails = async (iecNumber) => {
    const Arr = await getIECSearchData(iecNumber);
    return Arr.data;
  };

  const handleCollapseOpenModal = async (column, row, index) => {
    setStoreEyeModalRowData(row);
    let arr = [];
    column.map((col) => {
      let obj = {
        [col.title]: row[col.key],
      };
      arr.push(obj);
      setModalArr(arr);
    });

    if (currentCountryOrTrade.country === "India") {
      const IECArr = await getIECSearchDetails(row.IEC);
      setIECDetails(IECArr);
    }

    setOpenCollapseModal(true);
    setPrevOrNext(index);
  };

  const nextDataTableRecords = async () => {
    setPrevOrNext(prevOrNext + 1);
    const row = childData && childData[prevOrNext + 1]?.recordRow;
    setStoreEyeModalRowData(row);
    let arr = [];
    childdataTableHead &&
      childdataTableHead.length &&
      childdataTableHead.forEach((col) => {
        let obj = {
          [col.title]: row[col.key],
        };
        arr.push(obj);
      });
    const IECArr = await getIECSearchDetails(row.IEC);
    setIECDetails(IECArr);
    setModalArr(arr);
  };

  const previousDataTableRecords = async () => {
    setPrevOrNext(prevOrNext - 1);
    const row = childData && childData[prevOrNext - 1]?.recordRow;
    setStoreEyeModalRowData(row);
    let arr = [];
    childdataTableHead &&
      childdataTableHead.length &&
      childdataTableHead.forEach((col) => {
        let obj = {
          [col.title]: row[col.key],
        };
        arr.push(obj);
      });
    const IECArr = await getIECSearchDetails(row.IEC);
    setIECDetails(IECArr);
    setModalArr(arr);
  };

  const handlePrintTableRecords = useReactToPrint({
    content: () => PrintRef.current,
  });

  const [open, setOpen] = useState(false);
  const [childDataTableHeader, setChildDataTableHeader] = React.useState([]);
  const [childTableData, setChildTableData] = React.useState([]);

  const handleOpen = (row) => {
    handleClickCompositionTableRow(row);
    setOpen(!open);
    let bl = row.bl_flag === 1 ? true : false;
    const filterTaxonomy = getTaxonomyData.find(
      (ele) =>
        ele.country.toLowerCase() ===
          row.country.replace("(bl)", "").toLowerCase() &&
        ele.trade === row.tradeType &&
        ele.bl_flag === bl
    );

    dataTableValues(filterTaxonomy);
    const newArr = favouriteShipments.filter(
      (ele) =>
        ele.country === row.country.replace("(bl)", "").toLowerCase() &&
        ele.tradeType === row.tradeType &&
        ele.bl_flag === row.bl_flag
    );

    setChildTableData(newArr);
    setChildData(newArr);
  };

  const createHeaderCountryWise = (Header, sequencedFields, ValuesArr) => {
    let _sequencedFields = [...sequencedFields];
    let _ValuesArr = [...ValuesArr];
    for (const [key, value] of Object.entries(Header)) {
      const fieldName = key.toUpperCase().trim();
      if (!fieldName?.includes(SHIPMENT_FIELD_RECORDS_TAG)) {
        if (!_sequencedFields.includes(key)) {
          _sequencedFields.push(key);
        }
      }
    }

    _sequencedFields.forEach((e) => {
      const obj = {
        key: e,
        title: Header[e].split("_").join(" "),
        dataIndex: e,
      };
      if (e !== "RECORDS_TAG") {
        _ValuesArr.push(obj);
      }
    });

    return { _sequencedFields, _ValuesArr };
  };
  const dataTableValues = (filterTaxonomy) => {
    let sequencedFields = [];
    let ValuesArr = [
      {
        key: "add",
        title: "",
        dataIndex: "add",
      },
    ];
    filterTaxonomy.fields.explore.forEach((e) => {
      sequencedFields.push(e);
    });

    if (
      filterTaxonomy?.country === "India" &&
      filterTaxonomy?.trade === "IMPORT" &&
      filterTaxonomy?.bl_flag === false
    ) {
      const countryWiseHeader = createHeaderCountryWise(
        INDIA_IMPORT_COLUMN_NAME,
        sequencedFields,
        ValuesArr
      );
      sequencedFields = [...countryWiseHeader?._sequencedFields];
      ValuesArr = [...countryWiseHeader?._ValuesArr];
    } else if (
      filterTaxonomy?.country === "India" &&
      filterTaxonomy?.trade === "EXPORT" &&
      filterTaxonomy?.bl_flag === false
    ) {
      const countryWiseHeader = createHeaderCountryWise(
        INDIA_EXPORT_COLUMN_NAME,
        sequencedFields,
        ValuesArr
      );
      sequencedFields = [...countryWiseHeader?._sequencedFields];
      ValuesArr = [...countryWiseHeader?._ValuesArr];
    } else if (
      filterTaxonomy?.country === "IMPORT" &&
      filterTaxonomy?.trade === "Kazakhstan" &&
      filterTaxonomy?.trade === "false"
    ) {
      const countryWiseHeader = createHeaderCountryWise(
        KAZAKHSTAN_IMPORT_RECORDS,
        sequencedFields,
        ValuesArr
      );
      sequencedFields = [...countryWiseHeader?._sequencedFields];
      ValuesArr = [...countryWiseHeader?._ValuesArr];
    } else if (
      filterTaxonomy?.country === "EXPORT" &&
      filterTaxonomy?.trade === "Kazakhstan" &&
      filterTaxonomy?.trade === "false"
    ) {
      const countryWiseHeader = createHeaderCountryWise(
        KAZAKHSTAN_EXPORT_RECORDS,
        sequencedFields,
        ValuesArr
      );
      sequencedFields = [...countryWiseHeader?._sequencedFields];
      ValuesArr = [...countryWiseHeader?._ValuesArr];
    } else {
      filterTaxonomy.fields.all.forEach((e) => {
        const fieldName = e.toUpperCase().trim();
        if (!fieldName?.includes(SHIPMENT_FIELD_RECORDS_TAG)) {
          if (!sequencedFields.includes(e)) {
            sequencedFields.push(e);
          }
        }
      });

      sequencedFields.forEach((ele) => {
        const obj = {
          key: ele,
          title: ele.split("_").join(" "),
          dataIndex: ele,
        };
        if (ele !== "RECORDS_TAG") {
          ValuesArr.push(obj);
        }
      });
    }

    // if (currentCountry === "VIETNAM_2022") {
    let firstTwo = [];
    let others = [];
    let viewAndCheck = [];

    ValuesArr.forEach((header) => {
      if (header.key === "HS_CODE") {
        if (firstTwo[0] !== undefined) {
          firstTwo[1] = firstTwo[0];
          firstTwo[0] = header;
        } else {
          firstTwo[0] = header;
        }
      } else if (
        header.key === "PRODUCT_DESC_EN" ||
        header.key === "PRODUCT_DESCRIPTION" ||
        header.key === "GOODS_DESCRIPTION"
      ) {
        let index;
        if (firstTwo[0] === undefined) {
          index = 0;
        } else {
          index = 1;
        }
        firstTwo[index] = header;
      } else if (header.key === "add" || header.key === "checkbox") {
        viewAndCheck.push(header);
      } else {
        others.push(header);
      }
    });

    ValuesArr = [...viewAndCheck, ...firstTwo, ...others];
    // }
    setChildDataTableHeader(ValuesArr);
    setChilddataTableHead(ValuesArr);
  };

  return (
    <div id="favourite-shipment">
      {loading && (
        <Loading title={loadingMsg.title} content={loadingMsg.content} />
      )}
      {error && <Error open={error} setOpen={setError} errorMsg={errorMsg} />}
      <Grid maxWidth="xxl">
        <div className="row-dashbored">
          <h4 className="text" style={{ margin: "0px" }}>
            Favourite
            <span className="badge bg-soft-blue text-blue label-worksapce-name">
              Shipment
            </span>
          </h4>
        </div>
        {/* <CollapsibleTable
          rows={getRowData}
          columns={shipmentTableHeader}
          handleClickCompositionTableRow={handleClickCompositionTableRow}
          isCollapseOpen={isCollapseOpen}
          favouriteShipments={favouriteShipments}
          getTaxonomyData={getTaxonomyData}
          getRowData={getRowData}
          favoriteShipment={true}
          collapseTableSize="94vw"
          handleCollapseOpenModal={handleCollapseOpenModal}
          setChildData={setChildData}
          setChilddataTableHead={setChilddataTableHead}
          currentCountry={currentCountryOrTrade?.country?.toUpperCase()}
        /> */}
        <CollapsibleTable
          rows={getRowData}
          columns={shipmentTableHeader}
          childTableColumns={childDataTableHeader}
          childTableRows={childTableData}
          handleOpen={handleOpen}
          getRowData={getRowData}
          favoriteShipment={true}
          handleCollapseOpenModal={handleCollapseOpenModal}
        />

        <ModalComponent
          IsNextPrevBtnShown={true}
          open={openCollapseModal}
          setOpen={setOpenCollapseModal}
          header="DataTable"
          prevOrNext={prevOrNext}
          nextDataTableRecords={nextDataTableRecords}
          previousDataTableRecords={previousDataTableRecords}
          handlePrintTableRecords={handlePrintTableRecords}
          rowsPerPage={childData && childData.length ? childData.length : 0}
          modalBody={
            <ExploreDataTableModal
              PrintRef={PrintRef}
              currentTradeType={currentCountryOrTrade.tradeType}
              currentCountry={currentCountryOrTrade?.country?.toUpperCase()}
              handleCollapseDetails={(e) => handleCollapseDetails(e)}
              collapseTable={collapseTable}
              setCollapseTable={setCollapseTable}
              modalheaderArr={modalheaderArr}
              isCollapseModelDetails={isCollapseModelDetails}
              setIsCollapseModelDetails={setIsCollapseModelDetails}
              IECDetails={IECDetails}
              // handleFavouriteCompany={handleFavouriteCompany}
              // handleFavouriteShipment={handleFavouriteShipment}
            />
          }
          width="1000px"
          saveText="Save"
          saveButtonShown={false}
          saveButtonColor="#005d91"
        />

        {noDataFound && (
          <>
            <Box className="noShipmentFound-title">
              No Favourite Shipments Found
            </Box>{" "}
            <Box className="noShipmentFound-subTitle">
              You haven't added any Shipment as Favourite yet!
            </Box>
          </>
        )}
      </Grid>
    </div>
  );
}
