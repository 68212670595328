import React from "react";
import "./DropDown.scss";


export default function SelectDropdown(props) {
    // const title = props?.optionValue;
    return (
        <div className="select-drop">
            <select value={props?.value} onChange={(e) => props?.handleChange(e)} name={props?.name}>
                {props?.option.length > 0 &&
                    props?.option.map((e, index) => {
                        return (
                            <option value={e?.value} key={index} >
                                {e?.title && e?.title.split("_").join(" ")}
                            </option>
                        );
                    })}
            </select>
        </div>
    );
}
