import React, { useState } from "react";
import "./forgotPassword.scss";
import forgotPasswordImg from "../../Asset/images/Forgot password.png";
import userAvatar from "../../Asset/images/user-avatar.svg";
import Logo from "../../Asset/images/logo-dark-og-transparent.png";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Loading from "../../Component/SuccessLoader/success";
import Error from "../../Component/ErrorLoader/error";
import { useNavigate } from "react-router-dom";
import { getForgotPasswordDetails } from "../../Services/service";
import { TextField } from "@mui/material";

const DisplayingErrorMessagesSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email")
    .required("This value is required."),
});
const ForgotPassword = () => {
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState({});
  function forgotPassword(values) {
    setloading(true);
    let forgotPasswordDetails = {
      userEmail: values.email,
    };
    getForgotPasswordDetails(forgotPasswordDetails)
      .then((forgotPasswordResponse) => {
        setTimeout(() => {
          if (
            forgotPasswordResponse?.data === 1 ||
            forgotPasswordResponse?.data?.desc === "User Not Found"
          ) {
            navigate("/password/recovery/submitted");
          } else {
            setError(true);
            setloading(false);
            setErrorMsg({
              title: forgotPasswordResponse?.data?.msg || "Warning",
              content:
                forgotPasswordResponse?.data?.desc || forgotPasswordResponse,
              open: true,
            });
            setloading(false);
          }
        }, 500);
        setError(false);
      })
      .catch((e) => {
        setloading(false);
      });
  }
  return (
    <div className="login-test-one-container">
      {error && <Error errorMsg={errorMsg} open={error} setOpen={setError} />}
      {loading && (
        <Loading title={"Email"} content={"Sending email for password reset"} />
      )}
      <div className="container-one">
        <div className="container-one-image-content">
          <div className="login-content-image-container-forgot">
            <img id="login-content-img-one" src={forgotPasswordImg} />
          </div>
          <h1 className="login-image-content-one">Step 1: Enter Your Email</h1>
          <p className="login-image-content-para-one">
            Provide the email address associated with your account below. We'll
            send you a link to reset your password.
          </p>
        </div>
      </div>
      <div className="container-two">
        <div id="container-two-div">
          <div className="login-form-div">
            <div className="login-test-one-logo-div">
              <img
                src={Logo}
                // width={300}
                // height={100}
                id="login-test-one-logo"
                alt="logo"
              />
            </div>
            <div className="form-container-with-image-one">
              <div className="user-avatar-test-one">
                <img src={userAvatar} id="user-avatar-one" />
              </div>
              <div>
                {loading && (
                  <Loading
                    title={"Email"}
                    content={"Sending email for password reset"}
                  />
                )}
                {error && (
                  <Error errorMsg={errorMsg} open={error} setOpen={setError} />
                )}

                <Formik
                  initialValues={{
                    email: "",
                    password: "",
                  }}
                  validationSchema={DisplayingErrorMessagesSchema}
                  onSubmit={(values) => {
                    forgotPassword(values);
                  }}
                >
                  {({ errors, touched, handleSubmit, handleChange }) => (
                    <Form onSubmit={handleSubmit}>
                      {/* <button type="submit">Submit</button> */}
                      <div id="forgot-password">
                        <div className="container">
                          <div className="rowCards">
                            <div className="card-forgot bg-pattern">
                              <div className="card-body">
                                <div className="text-center w-75 m-auto">
                                  <a
                                    href="/consumers/login"
                                    rel="noopener noreferrer"
                                  ></a>
                                  <p className="">Enter your email address.</p>
                                </div>
                                {/* <form id="form-account-login" className="parsley-components"> */}
                                <div className="form-group">
                                  <TextField
                                    type="email"
                                    id="email-id-input"
                                    name="email"
                                    className="form-control-forgot"
                                    placeholder="Enter a valid e-mail"
                                    size={"small"}
                                    fullWidth
                                    onChange={handleChange}
                                  />

                                  {touched.email && errors.email && (
                                    <div className="error-message">
                                      {errors.email}
                                    </div>
                                  )}
                                </div>
                                <div className="form-group mb-0 text-center">
                                  <button
                                    type="submit"
                                    id="cta-login-account"
                                    style={{
                                      marginTop: "20px",
                                      background: "#005d91",
                                    }}
                                    className="set-submit-btn button-color"
                                  >
                                    Submit
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
            <div className="bottom-paragraph-test-one">
              <p>2023-24 design and developed by Eximpedia.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
