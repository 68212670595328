import React from "react";
import SelectDropdown from "../../../../Component/SelectDropDown/selectDropdown";
import "./details.scss";
import { Grid } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { ButtonComponent } from "../../../../Component/ButtonComponent/Button";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

export default function Details({
  selectedDataType,
  setSelectedDataType,
  setSelectedCountry,
  country,
  onSearchClick,
  userNameOptionValues,
  dataTypesOptionValues,
  handleSelectedUser,
  selectedCountry,
  selectedUser,
  onResetClick,
}) {
  return (
    <div id="detailsComponent">
      <div id="all-pages-title">
        <h4 className="text">
          Manage
          <span className="badge bg-soft-blue text-blue label-worksapce-name">
            Workspaces
          </span>
        </h4>
      </div>
      <div id="details-container">
        <span id="filter-title">FILTER</span>
        <span className="line"></span>
        <Grid
          container
          spacing={1}
          columnSpacing={{ xs: 4, sm: 3, md: 2.5 }}
          className="details"
        >
          <Grid item lg={3} md={2.5} sm={2.6} xs={6}>
            <SelectDropdown
              value={selectedDataType}
              setValue={(val) => setSelectedDataType(val.target.value)}
              option={dataTypesOptionValues}
              text="Choose TradeType"
              label="Choose TradeType"
              optionValue="title"
              width="100%"
              height="40px"
              intialOption={true}
              intialOptionLable="Choose TradeType..."
              className="select-border-redius"
            />
          </Grid>

          <Grid item lg={3} md={2.5} sm={2.6} xs={6}>
            <SelectDropdown
              value={selectedCountry}
              setValue={(val) => setSelectedCountry(val.target.value)}
              option={country}
              text="Choose Country"
              label="Choose Country"
              optionValue="title"
              width="100%"
              height="40px"
              intialOption={true}
              intialOptionLable="Choose Country..."
              className="select-border-redius"
            />
          </Grid>

          <Grid item lg={3} md={2.5} sm={2.6} xs={6}>
            {selectedUser.userName !== undefined && (
              <SelectDropdown
                value={selectedUser.userName}
                setValue={(val) => handleSelectedUser(val)}
                option={userNameOptionValues}
                text="Choose User"
                label="Choose User"
                optionValue="userName"
                width="100%"
                height="40px"
                className="select-border-redius"
              />
            )}
          </Grid>

          <Grid item lg={3} md={4} sm={4} xs={6}>
            <ButtonComponent
              btnClick={onSearchClick}
              btnIcon={SearchIcon}
              name="Search"
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item lg={3} md={4} sm={4} xs={6}>
            <ButtonComponent
              btnClick={onResetClick}
              btnIcon={RestartAltIcon}
              name="Reset filter"
              style={{ width: "100%" }}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
