import React from "react";
import "../../../SelectDropDown/selectDropdown.scss";

export default function SelectDropdown(props) {
  return (
    <div className="select">
      <select onChange={(e) => props?.setValue(e)} value={props?.value} style={{ cursor: "pointer" }}>
        {props?.intialOption && <option hidden>{props?.intialOptionLable}</option>}
        {props?.option.length > 0 &&
          props?.option.map((e, index) => {
            return (
              <option value={e?.key} key={index}>
                {e?.title}
              </option>
            );
          })}
      </select>
    </div>
  );
}
