import React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import CloseIcon from "../../Asset/images/close.png";

import "./error.scss";
import { Button } from "antd";
const borderColorCode = {
  Error: "red",
  Warning: "#fea400",
  Success: "#348f34",
  Not_Access: "#276cab",
  Info: "#42c0fb"
}
export default function ErrorPopup(props) {
  const clearStorage = () => {
    window.location.reload();
  };

  return (
    <div id="error-loader">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <Box id="error-box" style={{ borderColor: borderColorCode["Error"] }}>
            {/* <Box sx={{ textAlign: "center", mt: 2 }}>
              <img src={CloseIcon} alt="CloseIcon" />
            </Box>
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              sx={{
                fontSize: "20px",
                fontWeight: 600,
                textAlign: "center",
                marginTop: "10px",
                opacity: 0.9,
                color: "#595959",
              }}
            >
              {props.errorMsg}
            </Typography>

            <Box sx={{ textAlign: "center", mt: 3 }} id="err-button">
              <Box sx={{ textAlign: "center", mt: 4 }}>
                <span onClick={() => clearStorage()} className="OK-button">
                  OK
                </span>
              </Box>
            </Box> */}
            <div style={{ padding: "15px", display: "flex", flexDirection: "row", alignItems: "center", }}>
              <div style={{ margin: "10px" }}>
                <img src={CloseIcon} alt="CloseIcon" />
              </div>
              <div style={{ display: "flex", flexDirection: "column", flex: "1" }}>
                <span style={{ fontSize: "22px", fontWeight: 600, color: "rgb(50, 58, 70)" }}>{props.errorMsg.title ? props.errorMsg.title : "error"}</span>
                <div style={{ fontSize: "14px", marginTop: "20px" }}>
                  {props.errorMsg && props.errorMsg}
                </div>
              </div>
            </div>
            <hr style={{ margin: "0px" }} />
            <div style={{ textAlign: "end", padding: "10px" }}>
              <Button
                className=".OK-button"
                style={{ backgroundColor: borderColorCode["Error"], color: "white", fontWeight: "600" }}
                onClick={() =>
                  clearStorage()
                }
              >
                OK
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
