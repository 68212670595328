import React from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";

const buttonStyles = {
  success: {
    backgroundColor: "rgba(26, 188, 156, 0.25)",
    borderColor: "rgba(26, 188, 156, 0.25)",
    color: "#1abc9c",
  },
  error: {
    backgroundColor: "rgba(241, 85, 108, 0.25)",
    borderColor: "rgba(241, 85, 108, 0.25)",
    color: "#f1556c",
  },
  3: {
    backgroundColor: "rgba(230, 126, 34, 0.25)",
    borderColor: "rgba(230, 126, 34, 0.25)",
    color: "#e67e22",
  },
  4: {
    backgroundColor: "rgba(52, 152, 219, 0.25)",
    borderColor: "rgba(52, 152, 219, 0.25)",
    color: "#3498db",
  },
  5: {
    backgroundColor: "rgba(231, 76, 60, 0.25)",
    borderColor: "rgba(231, 76, 60, 0.25)",
    color: "#e74c3c",
  },
  2: {
    backgroundColor: "rgba(26, 188, 156, 0.25)",
    borderColor: "rgba(26, 188, 156, 0.25)",
    color: "#1abc9c",
  },
  8: {
    backgroundColor: "rgba(241, 196, 15, 0.25)",
    borderColor: "rgba(241, 196, 15, 0.25)",
    color: "#f1c40f",
  },
  7: {
    backgroundColor: "rgba(155, 89, 182, 0.25)",
    borderColor: "rgba(155, 89, 182, 0.25)",
    color: "#9b59b6",
  },
  6: {
    backgroundColor: "rgba(46, 204, 113, 0.25)",
    borderColor: "rgba(46, 204, 113, 0.25)",
    color: "#2ecc71",
  },
  9: {
    backgroundColor: "rgba(26, 188, 156, 0.25)",
    borderColor: "rgba(26, 188, 156, 0.25)",
    color: "#1abc9c",
  },
  10: {
    backgroundColor: "rgba(231, 76, 60, 0.25)",
    borderColor: "rgba(231, 76, 60, 0.25)",
    color: "#e74c3c",
  },
};

const StatusButton = styled(Button)(({ status }) => ({
  ...buttonStyles[status],
  fontWeight: 900,
  fontSize: "12px",
  height: "19px",
  padding: "5px",
  cursor: "default",
  "&:hover": {
    backgroundColor: buttonStyles[status]?.backgroundColor,
    borderColor: buttonStyles[status]?.borderColor,
  },
}));

const StatusButtonComponent = ({ status }) => {
  const statusText = {
    success: "Success",
    error: "Error",
    2: "Open",
    3: "Pending",
    4: "Resolved",
    5: "Closed",
    6: "Waiting on Customer",
    7: "Waiting on Third Party",
    8: "Work In Progress",
    9: "Data Found",
    10: "Data Not Found",
  };

  return <StatusButton status={status}>{statusText[status]}</StatusButton>;
};

export default StatusButtonComponent;
