import React, { useEffect } from "react";
import "./RecentSearchTable.scss";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

let flagUrl = require(`../../../../Asset/flags/flag_ind.png`);

const RecentSearchTable = ({
  RecentSearchData,
  handleClickForRecentActivity,
}) => {
  const getActivityTrackerQueryData = (val) => {
    return (
      val &&
      Array.isArray(val) &&
      val?.length > 0 &&
      val?.map((e) => {
        if (e?.identifier.split("_")[0] === "FILTER") {
          return ` | FILTER :- ${e?.alias?.split("_").join(" ")} : ${
            e?.fieldValue
          }`;
        } else {
          return `SEARCH :- ${e?.alias?.split("_").join(" ")} :  ${
            e?.fieldValue
          }`;
        }
      })
    );
  };
  return (
    <div className="data-update-container-recent-search">
      <div className="header-recent-search">
        <h2 className="title-for-update-country-recent-search">
          RECENT SEARCH
        </h2>
      </div>
      <hr style={{ opacity: 0.4 }} />
      <div className="data-list-recent-search">
        {RecentSearchData &&
          RecentSearchData.length > 0 &&
          RecentSearchData?.map((item, index) => (
            <div key={index} className="data-row-recent-search">
              <span className="country-flag-for-recent-search">
                <img src={flagUrl} style={{ width: "100%" }} />
              </span>
              <span className="country-recent-search">
                {item.country.toUpperCase()}
              </span>
              <span className="type-recent-search">
                {item.tradeType.toUpperCase()}
              </span>
              <span
                className="recent-search-query"
                onClick={() => handleClickForRecentActivity("", item, index)}
              >
                {" "}
                {getActivityTrackerQueryData(item.query)}
              </span>
              <span
                className="view-recent-search-recent-search"
                onClick={() => handleClickForRecentActivity("", item, index)}
              >
                <RemoveRedEyeIcon fontSize="small" />
              </span>
            </div>
          ))}
      </div>
    </div>
  );
};

export default RecentSearchTable;
