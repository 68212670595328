import * as React from "react";
import { Typography, Box, Modal, Backdrop, Fade } from "@mui/material";

import "./success.scss";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  px: 8,
  py: 3,
  outline: "none",
  borderRadius: "6px",
};
export default function Loading(props) {
  const open = true;
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style} id="successLoader-box" className={props.loader === "hide" ? "bg-hide" : "bg-block"} style={{ backgroundColor: props.loader === "hide" ? "white" : "#00000052" }}>
            {/* <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              sx={{
                fontSize: "24px",
                textAlign: "center",
                fontFamily: "Rubik",
                color: "#595959",
                fontWeight: 600,
              }}
            >
              {props.title}
            </Typography>
            <Typography
              id="transition-modal-description"
              sx={{
                mt: 1,
                fontSize: "16px",
                fontweight: 300,
                wordwrap: "break-word",
                textAlign: "center",
                opacity: 0.6,
                fontFamily: "Rubik",
              }}
            >
              {props.content}
            </Typography> */}
            {props.loader === "hide" ? (
              <>
                <Typography
                  id="transition-modal-title"
                  variant="h6"
                  component="h2"
                  sx={{
                    fontSize: "24px",
                    textAlign: "center",
                    fontFamily: "Rubik",
                    // color: "#595959",
                    fontWeight: 600,

                  }}
                >
                  {props.title}
                </Typography>
                <Typography
                  id="transition-modal-description"
                  sx={{
                    mt: 1,
                    fontSize: "16px",
                    fontweight: 300,
                    wordwrap: "break-word",
                    textAlign: "center",
                    opacity: 0.6,
                    fontFamily: "Rubik",

                  }}
                >
                  {props.content}
                </Typography>
              </>
            ) : (
              <div>
                <Box sx={{ textAlign: "center", mt: 3 }}>
                  <span className="loading-spinner"></span>
                </Box>
                <Typography
                  id="transition-modal-description"
                  sx={{
                    mt: 1,
                    fontSize: "20px",
                    fontweight: 300,
                    wordwrap: "break-word",
                    textAlign: "center",
                    opacity: 1,
                    fontFamily: "Rubik",
                    color: "white"
                  }}
                >
                  {props.title}
                </Typography>
              </div>
            )}
          </Box>

        </Fade>
      </Modal>
    </div>
  );
}
