import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  TableHead,
} from "@mui/material";
import Pagination from "../PaginationTest/Pagination";
import "./CompareTable.scss";
import TablePagionationDropdown from "../TablePaginationDropdown/tablePagionationDropdown";
import moment from "moment/moment";
import millify from "millify";
function CompareTable({
  handleClickCompanyName,
  tableHeigth,
  height,
  showPagination,
  scroll,
  columns,
  isMarketProduct,
  isHeaderWrap,
  handleChangePage,
  page,
  totalAvailableData,
  rowsPerPage,
  handleChangeRowsPerPage,
  dateRange,
  rows,
}) {
  const getDifference = (val, val1, className) => {
    let difference = ((val - val1) * 100) / (val + val1);
    return (
      <TableCell
        className={`border-bottom tableRowForDiff notranslate ${className}`}
        style={{
          fontWeight: "600",
          color:
            difference > 0
              ? "#1D9200"
              : difference === 0
              ? "#303a43"
              : "#FF0000",
        }}
      >
        {difference.toFixed(2)}
        {"%"}
      </TableCell>
    );
  };

  const getDate = (startDate, endDate) => {
    const startYear = moment(startDate).year();
    const startMonth = moment(startDate).format("MMM");
    const endYear = moment(endDate).year();
    const endMonth = moment(endDate).format("MMM");

    if (startYear === endYear) {
      return `${startMonth}-${endMonth} ${startYear}`;
    } else {
      return `${startMonth} ${startYear} - ${endMonth}  ${endYear}`;
    }
  };
  return (
    <>
      <Paper id="dataTable" sx={{ width: "100%" }}>
        <TableContainer sx={{ maxHeight: tableHeigth ? tableHeigth : 560 }}>
          <Table
            // scroll == true ? "tableHeight" : ""
            className={scroll == true ? "tableHeight" : ""}
            stickyHeader
            style={{ height: height, userSelect: "none" }}
            aria-label="sticky table"
            size="small"
          >
            <TableHead>
              <TableRow style={{ height: 65 }}>
                {columns && columns.length && columns.length > 0 ? (
                  <>
                    {columns.map((val, index) => {
                      return (
                        <>
                          {
                            <TableCell
                              className="header-diff"
                              id={isHeaderWrap ? "no-wrap" : ""}
                              style={{
                                textAlign: val?.textAlign
                                  ? val?.textAlign
                                  : val?.key === "company_name" ||
                                    val?.key === "compare_date" ||
                                    val?.key === "hsCode" ||
                                    val?.key === "hsCode_description" ||
                                    val?.key === "name"
                                  ? "center"
                                  : "left",
                              }}
                              // colSpan={val === "Port" || val === "Country" ? 2 : ""}
                            >
                              {val.title}
                            </TableCell>
                          }
                        </>
                      );
                    })}
                  </>
                ) : (
                  ""
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows && rows?.length && rows?.length > 0 ? (
                <>
                  {rows &&
                    rows?.map((obj, index) => {
                      return (
                        <>
                          <TableRow tabIndex={-1}>
                            {!isMarketProduct && (
                              <TableCell
                                className="tableRowForDiff border-top-bottom scroll"
                                onClick={() => handleClickCompanyName(obj)}
                                rowSpan="3"
                                style={{
                                  fontSize: "20px",
                                  textAlign: "center",
                                  cursor: "pointer",
                                }}
                              >
                                {obj?.companyName}
                              </TableCell>
                            )}

                            {isMarketProduct && (
                              <>
                                <TableCell
                                  className="tableRowForDiff border-top-bottom scroll"
                                  style={{
                                    fontSize: "18px",
                                    textAlign: "center",
                                  }}
                                  rowSpan="3"
                                >
                                  {obj?.hsCode}
                                </TableCell>
                                <TableCell
                                  className="tableRowForDiff  border-top scroll"
                                  style={{
                                    fontSize: "18px",
                                    textAlign: "center",
                                  }}
                                  rowSpan="3"
                                >
                                  {obj?.hsCode_description}
                                </TableCell>
                                {obj?.name && (
                                  <TableCell
                                    className="tableRowForDiff  border-top"
                                    style={{
                                      fontSize: "18px",
                                      textAlign: "center",
                                    }}
                                    rowSpan="3"
                                  >
                                    {obj?.name}
                                  </TableCell>
                                )}
                              </>
                            )}
                            <TableCell
                              className="tableRowForDiff  border-top"
                              style={{ fontSize: "18px", textAlign: "center" }}
                            >
                              {getDate(
                                dateRange?.startMonthDate,
                                dateRange?.endMonthDate
                              )}
                            </TableCell>
                            <TableCell className="tableRowForDiff border-top notranslate">
                              {millify(obj?.data[0]?.price) ?? "0"} ​
                            </TableCell>
                            <TableCell className="tableRowForDiff border-top notranslate">
                              {millify(obj?.data[0]?.quantity) ?? "0"}​
                            </TableCell>
                            <TableCell className="tableRowForDiff border-top border-radius-top-right notranslate">
                              {millify(obj?.data[0]?.shipments) ?? "0"}​
                            </TableCell>
                          </TableRow>
                          {obj?.data?.slice(1).map((val, index) => {
                            return (
                              <>
                                <TableRow>
                                  <TableCell
                                    className="tableRowForDiff"
                                    style={{
                                      fontSize: "18px",
                                      textAlign: "center",
                                    }}
                                  >
                                    <>
                                      {getDate(
                                        dateRange?.compareStartDate,
                                        dateRange?.compareEndDate
                                      )}
                                    </>
                                  </TableCell>
                                  <TableCell className="tableRowForDiff notranslate">
                                    {val?.price ? millify(val?.price) : "0"}​
                                  </TableCell>
                                  <TableCell className="tableRowForDiff notranslate">
                                    {val?.quantity
                                      ? millify(val?.quantity)
                                      : "0"}
                                    ​
                                  </TableCell>
                                  <TableCell className="tableRowForDiff notranslate">
                                    {val?.shipments
                                      ? millify(val?.shipments)
                                      : "0"}
                                    ​
                                  </TableCell>
                                </TableRow>

                                <TableRow>
                                  <TableCell
                                    className="tableRowForDiff border-bottom "
                                    style={{
                                      fontSize: "18px",
                                      textAlign: "center",
                                      fontWeight: "600",
                                      color: "#303a43",
                                    }}
                                  >
                                    {isMarketProduct
                                      ? " Growth(%)"
                                      : "Difference"}
                                  </TableCell>
                                  {getDifference(
                                    obj?.data[0]?.price
                                      ? obj?.data[0]?.price
                                      : 0,
                                    val?.price ? val?.price : 0
                                  )}
                                  {getDifference(
                                    obj?.data[0]?.quantity
                                      ? obj?.data[0]?.quantity
                                      : 0,
                                    val?.quantity ? val?.quantity : 0
                                  )}
                                  {getDifference(
                                    obj?.data[0]?.shipments
                                      ? obj?.data[0]?.shipments
                                      : 0,
                                    val?.shipments ? val?.shipments : 0,
                                    "border-radius-bottom-right"
                                  )}
                                </TableRow>
                              </>
                            );
                          })}
                        </>
                      );
                    })}
                </>
              ) : (
                <>
                  <TableRow>
                    <TableCell
                      className="scroll  tableRowForDiff"
                      colSpan="12"
                      style={{
                        fontWeight: "600",
                        textAlign: "center",
                        fontSize: "20px",
                      }}
                    >
                      No Record Found
                    </TableCell>
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            boxShadow: "0 1px 2px rgba(0, 0, 0, 0.2)",
          }}
        >
          <div>
            {showPagination === true && (
              <TablePagionationDropdown
                handleChange={handleChangeRowsPerPage}
                value={rowsPerPage}
              />
            )}
          </div>
          {showPagination === true && totalAvailableData > 0 ? (
            <Pagination
              className="pagination-bar"
              currentPage={Number(page) + 1}
              totalCount={Number(totalAvailableData)}
              pageSize={rowsPerPage}
              onPageChange={(page) => handleChangePage(page)}
            />
          ) : (
            ""
          )}
        </div>
      </Paper>
    </>
  );
}

export default CompareTable;
