import React from "react";
import "./globalSearchCard.scss";
import Divider from "@mui/material/Divider";
import ExportIcon from "../../Asset/images/export.png";
import ImportIcon from "../../Asset/images/import.png";
import KeyboardArrowUpSharpIcon from "@mui/icons-material/KeyboardArrowUpSharp";
import { useState } from "react";
import StickyTable from "../Table/Table";
import { useEffect } from "react";
import { Accordion, Card } from "react-bootstrap";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useCallback } from "react";

const GlobalSearchCard = React.memo((props) => {
  const [dataTableHeader, setDataTableHeader] = useState([]);
  const [dataTableRowValues, setDataTableRowValues] = useState([]);
  const [headerValue, setHeaderValue] = useState([]);
  const [AlldataTableHeader, setAlldataTableHeader] = useState([]);
  const [isDisplay, setIsDisplay] = useState(false);
  const [hashMarkColumns, setHashMarkColumns] = useState([]);
  const toggle = () => setIsDisplay(!isDisplay);
  let flag_Url = require(`../../Asset/flags/${props?.data?.flag_uri}`);
  const SHIPMENT_FIELD_RECORDS_TAG = "RECORDS_TAG";

  const dataTableValues = useCallback((e, isSetAllHeader) => {
    let tableHeader = [];
    const checkedArr = [];
    const data = [...e];
    if (data && data.length > 0) {
      for (const [key, value] of Object.entries(data[0])) {
        if (
          !key.includes(SHIPMENT_FIELD_RECORDS_TAG) &&
          key !== "_id" &&
          key !== "isFavourite" &&
          key !== "isFavouriteShipment"
        ) {
          if (!checkedArr.includes(key)) {
            const obj = {
              key: key,
              title: key.split("_").join(" "),
            };
            tableHeader.push(obj);
          }
        }
      }
    }

    let hsCode = tableHeader.filter((e) => e.key == "HS_CODE");
    let productDescription = tableHeader.filter(
      (e) => e.key === "PRODUCT_DESCRIPTION"
    );
    tableHeader.splice(
      tableHeader.indexOf(
        tableHeader.filter((e) => e.key === "PRODUCT_DESCRIPTION")[0]
      ),
      1
    );
    tableHeader.splice(
      tableHeader.indexOf(tableHeader.filter((e) => e.key === "HS_CODE")[0]),
      1
    );
    tableHeader.splice(
      tableHeader.indexOf(
        tableHeader.filter(
          (e) =>
            e.key ===
            props?.data?.buyerSellerArr.find(
              (e) => e?.identifier === "SEARCH_SELLER"
            )?.fieldTerm
        )[0]
      ),
      1
    );
    tableHeader.splice(
      tableHeader.indexOf(
        tableHeader.filter(
          (e) =>
            e.key ===
            props?.data?.buyerSellerArr.find(
              (e) => e?.identifier === "SEARCH_BUYER"
            )?.fieldTerm
        )[0]
      ),
      1
    );

    tableHeader.unshift({
      key: props?.data?.buyerSellerArr.find(
        (e) => e?.identifier === "SEARCH_SELLER"
      )?.fieldTerm,
      title: props?.data?.buyerSellerArr
        .find((e) => e?.identifier === "SEARCH_SELLER")
        ?.fieldTerm.split("_")
        .join(" "),
    });
    tableHeader.unshift({
      key: props?.data?.buyerSellerArr.find(
        (e) => e?.identifier === "SEARCH_BUYER"
      )?.fieldTerm,
      title: props?.data?.buyerSellerArr
        .find((e) => e?.identifier === "SEARCH_BUYER")
        ?.fieldTerm.split("_")
        .join(" "),
    });
    if (productDescription?.length > 0) {
      tableHeader.unshift({
        key: "PRODUCT_DESCRIPTION",
        title: "PRODUCT DESCRIPTION",
      });
    } else {
      const productDescription_en = tableHeader.find(
        (e) => e.key === "PRODUCT_DESC_EN"
      );
      if (
        productDescription_en &&
        Object.keys(productDescription_en).length > 0
      ) {
        tableHeader.unshift({
          key: "PRODUCT_DESC_EN",
          title: "PRODUCT DESC EN",
        });
      }
    }
    if (hsCode?.length > 0) {
      tableHeader.unshift({ key: "HS_CODE", title: "HS CODE" });
    }
    setDataTableHeader(tableHeader);
    setHeaderValue(tableHeader);
    // setAccountTableHeader(array);
    if (isSetAllHeader) {
      setAlldataTableHeader(tableHeader);
    }
    for (const item of data) {
      Object.keys(item).forEach((property) => {
        if (item[property] === "") {
          item[property] = "NULL";
        }
        if (
          Number(item[property]) === item[property] &&
          item[property] % 1 !== 0
        ) {
          item[property] = Number(item[property]).toFixed(2);
        }
      });
    }
    setDataTableRowValues(data.slice(0, 10));
    const _hashMarkColumns = [];
    const search_seller = props?.data?.buyerSellerArr.find(
      (e) => e?.identifier === "SEARCH_SELLER"
    )?.fieldTerm;
    const search_buyer = props?.data?.buyerSellerArr.find(
      (e) => e?.identifier === "SEARCH_BUYER"
    )?.fieldTerm;
    if (search_buyer !== "") {
      _hashMarkColumns.push(search_buyer);
    }
    if (search_seller !== "") {
      _hashMarkColumns.push(search_seller);
    }
    setHashMarkColumns(_hashMarkColumns);
  });

  useEffect(() => {
    dataTableValues(props.data.RECORD_SET, true);
  }, [props.data.RECORD_SET]);

  return (
    <>
      <Accordion defaultActiveKey="0">
        <Card>
          <div
            aria-controls="panel1d-content"
            id="panel1d-header"
            className="accordionSummary"
            onClick={toggle}
            style={{ backgroundColor: "#ECF0F2" }}
          >
            <div style={{ display: "flex", width: "100%", cursor: "pointer" }}>
              <div
                style={{
                  width: "40%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {<img src={flag_Url} className="flag" alt="flag" />}
                <span className="divider"></span>
                <span
                  className="countryName"
                  style={{ textTransform: "capitalize" }}
                >
                  {`${props?.countryName}_`}
                  {props?.data?.type?.toUpperCase()}(
                  {props?.data?.SUMMARY_RECORDS[0]?.count
                    ? props?.data?.SUMMARY_RECORDS[0]?.count
                    : "0"}
                  )
                </span>
              </div>
              <div
                style={{
                  width: "30%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span className="importerIcon" style={{ marginRight: "10px" }}>
                  <img src={ImportIcon} alt="importIcon" />
                </span>
                <span className="divider"></span>
                <span style={{ marginLeft: "10px" }}>BUYERS</span>
                <span className="sellers-buyers-value notranslate">
                  {props?.data?.SUMMARY_BUYERS
                    ? props?.data?.SUMMARY_BUYERS
                    : "0"}
                </span>
              </div>
              <div
                style={{
                  width: "30%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span className="exporterIcon" style={{ marginRight: "10px" }}>
                  <img src={ExportIcon} alt="exportIcon" />
                </span>
                <span className="divider"></span>

                <span style={{ marginLeft: "10px" }}>SELLERS</span>
                <span className="sellers-buyers-value notranslate">
                  {props?.data?.SUMMARY_SELLERS
                    ? props?.data?.SUMMARY_SELLERS
                    : "0"}
                </span>
              </div>
              {isDisplay === false ? (
                <ExpandMoreIcon
                  style={{
                    fontSize: "30px",
                    paddingRight: "5px",
                    color: "#005d91",
                  }}
                  onClick={toggle}
                />
              ) : (
                <KeyboardArrowUpSharpIcon
                  style={{
                    fontSize: "30px",
                    paddingRight: "5px",
                    color: "#005d91",
                  }}
                />
              )}
            </div>
          </div>
        </Card>
        <Card style={{ border: "1px solid #ecf0f2" }}>
          <Divider></Divider>
          {isDisplay == true && (
            <div style={{ padding: "10px" }}>
              {dataTableRowValues.length > 0 ? (
                <StickyTable
                  columns={dataTableHeader}
                  rows={dataTableRowValues}
                  isGlobalSearch={true}
                  showPagination={false}
                  hashMarkColumns={hashMarkColumns}
                  availableCountry={props?.country}
                  handleOpenModel={props?.handleOpenModel}
                  currentCountry={props?.currentCountry}
                  countryDetails={{
                    tradeType: props?.data?.type,
                    bl_flag: props?.bl_flag,
                    dateRange: props?.data?.dateRange,
                  }}
                />
              ) : (
                <span>No Record Available</span>
              )}
            </div>
          )}
        </Card>
      </Accordion>
    </>
  );
});
export default GlobalSearchCard;
