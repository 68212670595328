import React, { memo, useState } from "react";
import { Drawer, Box, Typography } from "@mui/material";
import NoNotiIcon from "../../../Asset/images/no-notification.webp";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import "./notification.scss";
import NotificationButtons from "./NotificationButtons";

const useStyles = makeStyles({
  notiHeader: {
    display: "block",
    fontSize: "15px !important",
    fontWeight: 600,
    color: "#343A40 !important",
    position: "sticky",
    top: "0px",
    zIndex: 10,
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "20px",
    background: "white",
    margin: "0px",
  },
  dflex: {
    display: "flex",
  },
  notiIcon: {
    verticalAlign: "middle",
    position: "absolute",
    top: "35%",
  },
  heading: {
    fontSize: "0.875rem !important",
    fontWeight: 200,
    color: "#343a40 !important",
  },
  timeZone: {
    float: "right",
    color: "#707070",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "12px",
  },
  timeIcon: {
    position: "relative",
    right: "5px",
    top: "1px",
  },
});

function Notification({
  notificationDetail,
  Open,
  setOpen,
  updateNotificationAPIHandler,
}) {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState("All");

  const handleButtonClick = (tab) => {
    setActiveTab(tab);
  };

  const handleCloseDrawer = () => {
    setOpen(false);
    updateNotificationAPIHandler();
  };

  const renderNotificationsCard = (notiDetails, isRead) => {
    return notiDetails && !!notiDetails?.length > 0 ? (
      notiDetails.map((e, index) => {
        return (
          <Box
            className={isRead ? "read-noti-section" : "unRead-noti-section"}
            key={index}
          >
            <span className={classes.timeZone}>
              <span className={classes.timeIcon}>
                <CalendarMonthIcon fontSize="smaller" />
              </span>
              {moment(e.created_at).format("lll")}
            </span>
            <div style={{ margin: "6px 9px" }}>
              <span className="circle"></span>
              <span className={classes.heading}>{e.heading}</span>
            </div>
            <Typography className={classes.description}>
              {e.description}
            </Typography>
          </Box>
        );
      })
    ) : notificationDetail?.unReadNoti?.length === 0 &&
      notificationDetail?.readNoti?.length === 0 ? (
      <img src={NoNotiIcon} width="100%" alt="no-notification" />
    ) : (
      ""
    );
  };

  return (
    <Drawer
      anchor="right"
      open={Open}
      onClose={handleCloseDrawer}
      id="notification-drawer"
    >
      <Box id="notification-modal">
        <Typography className="sidebar-title" variant="h6" component="h2">
          Notifications
          <span
            className="sidebar-close"
            onClick={() => {
              handleCloseDrawer();
            }}
          >
            ×
          </span>
        </Typography>
        <div>
          <NotificationButtons
            activeTab={activeTab}
            handleButtonClick={handleButtonClick}
            allCount={notificationDetail?.readNoti?.length}
            unReadCount={notificationDetail?.unReadNoti?.length}
          />
        </div>
        <Box id="notification-list">
          {activeTab === "Unread" && (
            <>
              {notificationDetail?.unReadNoti?.length === 0 ? (
                <Typography
                  color="textSecondary"
                  sx={{ marginTop: 2, textAlign: "center" }}
                >
                  No unread notifications available.
                </Typography>
              ) : (
                renderNotificationsCard(notificationDetail?.unReadNoti, false)
              )}
            </>
          )}

          {activeTab === "All" && (
            <>
              {renderNotificationsCard(notificationDetail?.unReadNoti, false)}
              {renderNotificationsCard(notificationDetail?.readNoti, true)}
            </>
          )}
        </Box>
      </Box>
    </Drawer>
  );
}

export default memo(Notification);
