import React, { useState, useCallback, useEffect, useContext } from "react";
import "./LinkedInInfo.scss";
import { ButtonComponent } from "../../../Component/ButtonComponent/Button";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EximLogo from "../../../Asset/images/logo-dark-og-transparent.png";
import StickyTable from "../../../Component/Table/Table";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import { UserContext } from "../../../Component/Navbar/Navbar";
import ModalComponent from "../../../Component/Modal/modal";
import { getCookie, translateButtonClick } from "../../../utils/CommanFunction";
import {
  getCompanyEmployeeDetails,
  getEmailAndPhoneOfEmployee,
  getLLMEmployeeData,
  handlePointDeduct,
  salesActivityTrack,
} from "../../../Services/service";
import GoogleIcon from "@mui/icons-material/Google";
import { logger } from "../../../Loggers/Loggers";
import FacebookIcon from "@mui/icons-material/Facebook";
import XIcon from "@mui/icons-material/X";
import Loading from "../../../Component/SuccessLoader/success";
import ErrorData from "../../../utils/ErrorHandling";
import Error from "../../../Component/ErrorLoader/error";
import millify from "millify";
import { DATEFORMAT } from "../../ExploreCountryShipmentNewUI/Explore.enum";
import moment from "moment";
import throwError from "../../../utils/throwError";
import { AES, enc } from "crypto-js";

export const linkedInPageHeader = [
  { key: "fullName", title: "Name" },
  { key: "title", title: "Position" },
  { key: "contact", title: "Contact" },
  { key: "social_urls", title: "Social Media" },
  { key: "country", title: "Country" },
  { key: "address", title: "Address" },
];

const CREDIT_POINT_IS_NOT_ENOUGH = {
  title: "Warning",
  content: "Credit Point is not enough",
};
let rowsPerPage = 25;
let llmstart = 1;

const LinkedInInfo = ({
  backButton,
  companyDetailPageData,
  companyEmployeeData,
  rowData,
  isUniqueData,
  setLLMEmployeeData,
  // countryForCompanyDetail,
  CLIENT_ACCOUNT_ID,
  currentCountry,
  llmEmployeeData,
}) => {
  const secretKey = process.env.REACT_APP_CRYPTO_JS_KEY;

  const [requestForDataModal, setRequestForDataModal] = useState(false);
  const [requestedDataName, setRequestedDataName] = useState("");
  const [isAppliedForRequestedData, setIsAppliedForRequestedData] = useState({
    email: {},
    phoneNumber: {},
  });
  const [indexOfEmailAndNum, setIndexOfEmailAndNum] = useState(null);
  const [page, setPage] = useState(0);
  const [dataTableRowValues, setDataTableRowValues] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState({});
  const [errorMsg, setErrorMsg] = useState({});
  const [error, setError] = useState(false);
  const [linkedInPeopleData, setLinkedInPeopleData] = useState();
  const [isClickedEmail, setIsClickedEmail] = useState();

  useEffect(() => {
    if (companyEmployeeData && companyEmployeeData?.length) {
      setDataTableRowValues(companyEmployeeData);
    }
  }, [companyEmployeeData]);

  const { setProgressBarValue, setIsFromCompanyDetailPage, progressBarValue } =
    useContext(UserContext);

  function removeBackticks(response) {
    const data = response.replace(/```/g, "");
    const parsedData = JSON.parse(data);
    return parsedData;
  }
  useEffect(() => {
    dataTableRowValues &&
      dataTableRowValues?.length > 0 &&
      dataTableRowValues?.map((employeeData) => {
        if (employeeData?.is_email_unlocked) {
          setIsClickedEmail((prevState) => ({
            ...prevState,
            [employeeData?.peopleId]: true,
          }));
        }
        if (employeeData?.email_id) {
          setIsAppliedForRequestedData((prevState) => ({
            ...prevState,
            email: {
              ...prevState.email,
              [employeeData?.peopleId]: {
                requested: true,
                emailId: employeeData?.email_id,
              },
            },
          }));
        }
        if (employeeData?.phone) {
          setIsAppliedForRequestedData((prevState) => ({
            ...prevState,
            phoneNumber: {
              ...prevState.phoneNumber,
              [employeeData?.peopleId]: {
                requested: true,
                number: employeeData?.phone,
              },
            },
          }));
        }
      });
  }, [companyEmployeeData, dataTableRowValues, page]);

  const catchBlock = (err) => {
    logger(err);
    setLoading(false);
    setError(true);
    setErrorMsg({
      title: err?.status === 401 ? "Warning" : "Error",
      content:
        err?.status === 500
          ? ErrorData?.INTERNAL_SERVER
          : err?.status === 401
          ? ErrorData?.SESSION_TIMEOUT
          : ErrorData?.SOMETHING_WENT_WRONG,
    });
  };
  const handleChangePage = (newPage) => {
    setLoading(true);
    setLoadingMsg({
      title: "Fetching people data",
      content: "Please Wait",
    });
    setPage(newPage - 1);
    let payload = {
      company_domain: rowData?.organization_doamin,
      userId: getCookie("user_id"),
      company_id: [rowData?.organization_id],
      start: isUniqueData?.is_unique ? llmstart + 1 : newPage,
      accountId: getCookie("accountId"),
    };
    let payloadForLLm = {
      start: (llmstart += 1),
      clientAccountId: CLIENT_ACCOUNT_ID,
      company: rowData?.organization_name,
      location: [currentCountry],
    };
    if (isUniqueData?.is_unique) {
      getLLMEmployeeData(payloadForLLm)
        .then((res) => {
          if (res?.status === 200) {
            setLoading(false);
            let _employeeData = removeBackticks(res?.data?.res?.linkedin_data);
            let arrData = [...llmEmployeeData, ..._employeeData];
            setLLMEmployeeData(arrData);
          }
        })
        .catch((err) => {
          catchBlock(err);
        });
    } else {
      getCompanyEmployeeDetails(payload)
        .then((res) => {
          if (res?.status === 200) {
            setLoading(false);
            setDataTableRowValues(res?.data?.res);
          }
        })
        .catch((err) => {
          catchBlock(err);
        });
    }

    translateButtonClick();
  };

  const requestDataCreditPointBody = useCallback(() => {
    return (
      <div className="remaning">
        <p>
          You can request <b>{requestedDataName}</b> with
          <span style={{ color: "red" }}> 5</span> credits
        </p>
        <p>
          Press <b>Confirm</b> to access the information.
        </p>
        <p>
          Credits available :{" "}
          <span style={{ color: "green" }}> {progressBarValue} </span>
        </p>
      </div>
    );
  }, [requestedDataName, progressBarValue]);

  const handleRequestForData = (requestedDataInfo, index, row) => {
    setLinkedInPeopleData(row);
    setIndexOfEmailAndNum(index);
    setRequestedDataName(requestedDataInfo);
    setRequestForDataModal(true);
  };

  const handleConfirmationForRequestData = useCallback(() => {
    setLoading(true);
    setLoadingMsg({
      title: "Fetching people contact",
      content: "Please Wait",
    });
    const encryptedCompanyCredits = JSON.parse(
      localStorage.getItem("company_detail_credit")
    );

    const decryptedCreditPoints = AES.decrypt(
      encryptedCompanyCredits,
      secretKey
    ).toString(enc.Utf8);

    let payloadForEmailAndPhone = {
      accountId: getCookie("accountId"),
      points: 5,
    };

    let payload = {
      peopleId: linkedInPeopleData?.peopleId,
      userId: getCookie("user_id"),
      accountId: getCookie("accountId"),
    };

    if (decryptedCreditPoints >= 5) {
      handlePointDeduct(payloadForEmailAndPhone)
        .then((res) => {
          if (res?.status === 200) {
            const updatedCompanyCredit = res?.data?.companyCredits;
            if (updatedCompanyCredit) {
              setProgressBarValue(updatedCompanyCredit ?? 0);
              const encryptedCompanyCredits = AES.encrypt(
                updatedCompanyCredit.toString(),
                secretKey
              ).toString();
              localStorage.setItem(
                "company_detail_credit",
                JSON.stringify(encryptedCompanyCredits)
              );
            }
          } else {
            throw throwError();
          }
        })
        .catch((err) => {
          catchBlock(err);
        });

      getEmailAndPhoneOfEmployee(payload)
        .then((res) => {
          if (res?.status === 200) {
            setLoading(false);
            if (res?.data) {
              if (requestedDataName === "Email Address") {
                setIsAppliedForRequestedData((prevState) => ({
                  ...prevState,
                  email: {
                    ...prevState.email,
                    [linkedInPeopleData?.peopleId]: {
                      requested: true,
                      emailId: res?.data?.res[0]?.email,
                    },
                  },
                }));
                setIsClickedEmail({
                  ...isClickedEmail,
                  [linkedInPeopleData?.peopleId]: true,
                });

                const salesTrackPayload = {
                  activityType: "EMPLOYEE_EMAIL_REQUEST",
                  activityPayload: payload,
                  user_id: getCookie("user_id"),
                  account_id: getCookie("accountId"),
                };
                salesActivityTrack(salesTrackPayload);
              } else if (requestedDataName === "Phone Number") {
                setIsAppliedForRequestedData((prevState) => ({
                  ...prevState,
                  phoneNumber: {
                    ...prevState.phoneNumber,
                    [linkedInPeopleData?.peopleId]: {
                      requested: true,
                      number: res?.data?.res[0]?.phone,
                    },
                  },
                }));
              }
            }
          } else if (res?.status === 204) {
            setLoading(false);
            setError(true);
            setErrorMsg({
              title: "Info",
              content: res?.data?.message,
            });
          }
        })
        .catch((err) => {
          catchBlock(err);
        });
    } else {
      setError(true);
      setErrorMsg(CREDIT_POINT_IS_NOT_ENOUGH);
      setLoading(false);
    }

    setRequestForDataModal(false);
  }, [linkedInPeopleData, requestedDataName]);

  const companyDetailsSection = useCallback(
    (data) => {
      let companyDetail;
      if (isUniqueData?.is_unique) {
        companyDetail = data;
      } else {
        companyDetail = data?.details[0];
      }
      return [
        {
          Industry: isUniqueData?.is_unique
            ? data?.industries
            : companyDetail?.industries
            ? companyDetail?.industries?.map((items) => items)
            : "-",
          "Year Established": "-",
          "Annual Income": companyDetail?.annual_revenue
            ? millify(companyDetail?.annual_revenue)
            : "-",
          "Employee Count": companyDetail?.estimated_num_employees
            ? millify(companyDetail?.estimated_num_employees)
            : "-",
          "Market Value": "-",
          "Time of Last Round of Funding":
            companyDetail?.latest_funding_round_date
              ? moment(companyDetail?.latest_funding_round_date).format(
                  DATEFORMAT?.format
                )
              : "-",
        },
        {
          "Financing Stage": "-",
          "Total Funding": companyDetail?.total_funding
            ? millify(companyDetail?.total_funding)
            : "-",
          "Public Exchange": "-",
          Address: companyDetail?.raw_address ?? "-",
          State: companyDetail?.state ?? "-",
          Country: companyDetail?.country ?? "-",
        },
      ];
    },
    [companyDetailPageData]
  );

  const companySocialMediaUrls = useCallback(
    (socialMediaData, xClass) => {
      const arr = [socialMediaData];
      return arr?.flat()?.map((socialUrl) => {
        return (
          <>
            {socialUrl?.linkedin_url && (
              <a
                href={socialUrl?.linkedin_url}
                className="social-icons social-media-linkedIn"
                target="_blank"
              >
                <LinkedInIcon />
              </a>
            )}
            {socialUrl?.twitter_url && (
              <a
                href={socialUrl?.twitter_url}
                className={xClass}
                target="_blank"
              >
                <XIcon />
              </a>
            )}
            {socialUrl?.facebook_url && (
              <a
                href={socialUrl?.facebook_url}
                className="social-icons faceBook"
                target="_blank"
              >
                <FacebookIcon />
              </a>
            )}
          </>
        );
      });
    },
    [companyDetailPageData]
  );

  const dataTable = useCallback(() => {
    return (
      <StickyTable
        columns={linkedInPageHeader}
        rows={dataTableRowValues}
        isFromLinkedInPage={true}
        handleRequestForData={handleRequestForData}
        isAppliedForRequestedData={isAppliedForRequestedData}
        indexOfEmailAndNum={indexOfEmailAndNum}
        showPagination={true}
        totalAvailableData={
          companyDetailPageData?.details && companyDetailPageData?.details[0]
            ? companyDetailPageData?.details[0]?.estimated_num_employees
            : 26
        }
        page={page}
        handleChangePage={handleChangePage}
        companySocialMediaUrls={companySocialMediaUrls}
        rowsPerPage={rowsPerPage}
        isClickedEmail={isClickedEmail}
        isUnique={isUniqueData?.is_unique}
        height={"70vh"}
        companyDetailTable={true}
      />
    );
  }, [
    page,
    dataTableRowValues,
    companyEmployeeData,
    isAppliedForRequestedData,
    isClickedEmail,
    requestedDataName,
    isUniqueData,
  ]);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleGoogleSearch = useCallback((searchTerm) => {
    var left = windowDimensions.width / 2 - 800 / 2;
    var top = windowDimensions.height / 2 - 500 / 2;
    window.open(
      `${searchTerm}`,
      searchTerm,
      "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
        800 +
        ", height=" +
        500 +
        ", top=" +
        top +
        ", left=" +
        left
    );
  });

  return (
    <>
      <div className="back-button" style={{ padding: "20px" }}>
        <ButtonComponent name="back" btnClick={() => backButton(false)} />
      </div>
      <div className="LinkedInfo-container">
        {error && <Error open={error} errorMsg={errorMsg} setOpen={setError} />}
        <div className="linkedInfo-header-container">
          <div className="LinkedInfo-header">
            <div className="company-name-header">
              {companyDetailPageData?.organization_name ??
                rowData?.organization_name}
            </div>
            <div className="company-contact">
              <span
                style={{
                  paddingRight: "5px",
                }}
              >
                {companyDetailPageData?.phone}
              </span>
              <span className="company-social-media-pipe"></span>
              <span className="social-media-container">
                {companySocialMediaUrls(
                  companyDetailPageData?.social_urls ?? rowData?.social_urls,
                  "social-icons"
                )}
                {companyDetailPageData?.organization_website && (
                  <span
                    className="social-icons"
                    onClick={() =>
                      handleGoogleSearch(
                        companyDetailPageData?.organization_website
                      )
                    }
                  >
                    <GoogleIcon />
                  </span>
                )}
              </span>
            </div>
          </div>

          <div className="company-detail-summary">
            <p style={{ lineHeight: "30px" }}>
              {companyDetailPageData?.short_description}
            </p>
          </div>
        </div>

        <div className="company-keywords">
          <div className="heading-blue" style={{ marginRight: "2px" }}>
            <span>
              <AccountTreeOutlinedIcon />
            </span>
            Keywords
          </div>
          <div className="keywords">
            {companyDetailPageData?.keywords?.length > 0 ? (
              companyDetailPageData?.keywords?.map((keyword) => (
                <span key={keyword} className="keywords-name">
                  {keyword}
                </span>
              ))
            ) : (
              <span className="keywords-name">No keywords available</span>
            )}
          </div>
        </div>

        <div className="company-details">
          <div className="heading-blue">
            <span>
              <DashboardOutlinedIcon />
            </span>
            Details
          </div>

          <div className="linkedIn-data-display">
            {companyDetailsSection(companyDetailPageData)?.map((column) => (
              <div className="column-for-company-data">
                {Object.keys(column).map((key) => (
                  <p className="company-details-data">
                    <strong>{key}</strong>
                    <div style={{ textAlign: "end" }}>{column[key]}</div>
                  </p>
                ))}
              </div>
            ))}
            <div className="logo-column">
              <img src={EximLogo} alt="Logo" />
            </div>
          </div>
        </div>

        <div className="company-detail-table">
          <div className="heading-blue">
            <span>
              <PersonOutlinedIcon />
            </span>
            Contact
          </div>
          <div style={{ marginTop: "20px" }}>
            {loading && (
              <Loading title={loadingMsg.title} content={loadingMsg.content} />
            )}
            {dataTable()}
          </div>
        </div>
      </div>
      <ModalComponent
        open={requestForDataModal}
        setOpen={setRequestForDataModal}
        header="Credits Reminder"
        modalBody={requestDataCreditPointBody()}
        saveButtonShown={true}
        saveText="confirm"
        width="25%"
        savedClicked={handleConfirmationForRequestData}
        companyData={true}
      />
    </>
  );
};

export default React.memo(LinkedInInfo);
