import React, { memo, useEffect } from "react";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import "./Table.scss";
import { STICKYCOLUMN, tableCellWidth } from "./enum/Table.enum";
import { Button, Checkbox, Tooltip } from "@mui/material";
import Pagination from "../PaginationTest/Pagination";
import { Table } from "antd";
import { SORTINGTYPE } from "../../Containers/ExploreCountryShipmentNewUI/Explore.enum";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { experimentalStyled as styled } from "@mui/material/styles";
import TablePagionationDropdown from "../TablePaginationDropdown/tablePagionationDropdown";
const SuccessBtn = styled(Button)({
  backgroundColor: "rgba(26, 188, 156, 0.25);",
  borderColor: "rgba(26, 188, 156, 0.25);",
  color: "#1abc9c !important",
  fontWeight: 900,
  fontSize: "12px",
  height: "19px",
  padding: "5px",
  cursor: "default",
  // textTransform:"none",
  "&:hover": {
    backgroundColor: "rgba(26, 188, 156, 0.25);",
    borderColor: "rgba(26, 188, 156, 0.25);",
  },
});
const ErrorBtn = styled(Button)({
  backgroundColor: "rgba(241, 85, 108, 0.25);",
  borderColor: "rgba(241, 85, 108, 0.25);",
  color: "#f1556c !important",
  fontWeight: 900,
  fontSize: "12px",
  height: "19px",
  padding: "5px",
  cursor: "default",
  // textTransform:"none",
  "&:hover": {
    backgroundColor: "rgba(241, 85, 108, 0.25);",
    borderColor: "rgba(241, 85, 108, 0.25);",
  },
});
const DataTable = memo(
  ({
    columns,
    rows,
    handleChangePage,
    page,
    rowsPerPage,
    totalAvailableData,
    handleChangeRowsPerPage,
    isExplore,
    descendingAndAscending,
    handleDescendingAndAscendingOrder,
    getSelectedRow,
    handleSelectedShipments,
    handleCollapseOpenModal,
    isWorkSpace,
    pagination,
    isGlobalSearch,
    hashMarkColumns,
    availableCountry,
    currentCountry,
    countryDetails,
    handleOpenModel,
    handleAddCredit,
    handleDeleteUser,
    handleEditUser,
    handleOpenDeleteModal,
    handleRunCompany,
    isRequestedBuyer,
    height,
    is_active,
    isFromManageUser,
    id,
  }) => {
    const getActivityTrackerQueryData = (val) => {
      return (
        val &&
        val.length > 0 &&
        val.map((e) => {
          return e?.alias?.split("_").join(" ") + " : " + e?.fieldValue;
        })
      );
    };

    const header = columns.map((e, i) => {
      e.width = e?.cellWidth
        ? e?.cellWidth
        : tableCellWidth?.width_150.includes(e?.key)
        ? 150
        : tableCellWidth?.maxWidth_column?.includes(e?.key)
        ? 420
        : tableCellWidth?.width_300.includes(e?.key)
        ? 300
        : 270;

      if (
        e.key !== "" &&
        e?.key !== null &&
        STICKYCOLUMN.includes(e?.key) &&
        i <= 3 &&
        (isExplore || isWorkSpace || isGlobalSearch || isRequestedBuyer)
      ) {
        e.fixed = "left";
      }
      if (e?.key === "add") {
        e.render = (text, record, index) => (
          <div style={{ textAlign: "center" }}>
            <RemoveRedEyeIcon
              onClick={() => handleCollapseOpenModal(columns, record, index)}
              style={{ color: "#9ea5ab", cursor: "pointer" }}
            />
          </div>
        );
        e.width = 75;
      } else if (e?.key === "checkbox") {
        e.render = (text, record, index) => (
          <div style={{ textAlign: "center" }}>
            <Checkbox
              onClick={(e) => handleSelectedShipments(e, record?._id)}
              size="small"
              checked={getSelectedRow?.includes(record?._id) ? true : false}
              style={{ padding: "0px" }}
            />
          </div>
        );
        e.width = 75;
      } else if (hashMarkColumns?.length && hashMarkColumns?.includes(e?.key)) {
        e.render = (text, record, index) => (
          <span
            onClick={() => {
              if (
                availableCountry &&
                availableCountry?.length > 0 &&
                availableCountry.filter(
                  (e) =>
                    e.country.toUpperCase() === currentCountry.toUpperCase()
                )[0]
              ) {
                handleOpenModel(
                  false,
                  record[e?.key],
                  currentCountry?.toUpperCase(),
                  countryDetails?.tradeType,
                  countryDetails?.bl_flag,
                  countryDetails?.dateRange
                );
              } else {
                handleOpenModel(
                  true,
                  record[e?.key],
                  currentCountry?.toUpperCase(),
                  countryDetails?.tradeType,
                  countryDetails?.bl_flag,
                  countryDetails?.dateRange
                );
              }
            }}
            style={{ cursor: "pointer" }}
          >
            ########
          </span>
        );
      } else if (e?.key === "EditBtn") {
        e.render = (text, record, index) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Tooltip title={"Edit"} arrow>
              <EditNoteOutlinedIcon
                onClick={() => handleEditUser(record)}
                fontSize="medium"
                style={{ color: "#005d91", width: "1.1em", cursor: "pointer" }}
                className="table-row-icon"
              />
            </Tooltip>
            {record?.role !== "ADMINISTRATOR" && (
              <>
                <Tooltip title={"Delete"} arrow>
                  <DeleteForeverIcon
                    onClick={() => handleDeleteUser(record)}
                    style={{
                      color: "red",
                      margin: "0px 5px 0px 4px",
                      height: "1em",
                      cursor: "pointer",
                    }}
                    fontSize="medium"
                    className="table-row-icon"
                  />
                </Tooltip>

                <Tooltip title={"Add Credit"} arrow>
                  <CreditCardIcon
                    onClick={() => handleAddCredit(record)}
                    style={{
                      color: "#005d91",
                      height: "1em",
                      cursor: "pointer",
                    }}
                    fontSize="medium"
                    className="table-row-icon"
                  />
                </Tooltip>
              </>
            )}
          </div>
        );
        e.width = 150;
      } else if (e?.key === "companyBtn" && e?.isFavCompany) {
        e.render = (text, record, index) => (
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          >
            <Tooltip title={"Search In Explore"} arrow>
              <SearchIcon
                onClick={() => handleRunCompany(record)}
                fontSize="medium"
                style={{ color: "#005d91", width: "1.1em" }}
                className="table-row-icon with-border"
              />
            </Tooltip>
            <Tooltip title={"Remove Company in  Your Fav list"} arrow>
              <DeleteForeverIcon
                onClick={() => handleOpenDeleteModal(record)}
                fontSize="medium"
                style={{ color: "red", width: "1.1em", cursor: "pointer" }}
                className="table-row-icon with-border-delete"
              />
            </Tooltip>
          </div>
        );
      } else if (e?.key === "query") {
        e.render = (text, record, index) =>
          getActivityTrackerQueryData(record[e?.key]);
      } else if (e?.key === "status") {
        e.render = (text, record, index) =>
          record[e.key] === 1 || is_active ? (
            <SuccessBtn>Activated</SuccessBtn>
          ) : (
            <ErrorBtn style={{ height: "19px", padding: "5px" }}>
              {"Deactivated"}
            </ErrorBtn>
          );
      } else if (e?.key === "id_subscription") {
        e.render = (text, record, index) => index + 1;
        e.width = 75;
      } else if (e?.key === "is_active" && isFromManageUser) {
        e.render = (text, record, index) =>
          record[e?.key] === 1 ? (
            <SuccessBtn>Activated</SuccessBtn>
          ) : (
            <ErrorBtn style={{ height: "19px", padding: "5px" }}>
              Deactivated
            </ErrorBtn>
          );
        e.width = 150;
      } else if (e?.key === "entity" && isWorkSpace) {
        e.render = (text, record, index) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              width: "100%",
            }}
            onClick={() => handleCollapseOpenModal(record)}
          >
            {record[e?.key]}
          </div>
        );
      }
      if (
        descendingAndAscending &&
        descendingAndAscending.hasOwnProperty(e?.key) &&
        (isExplore || isWorkSpace)
      ) {
        e.sortDirections = ["ascend", "descend"];
        e.sorter = (a, b) => a?.column1?.localeCompare(b?.column1);
      }
      e.dataIndex = e?.key;
      return e;
    });

    const tableHandleChange = (pagination, filters, sorter, extra) => {
      if (extra?.action === "sort") {
        if (sorter?.order === "ascend") {
          handleDescendingAndAscendingOrder(
            sorter?.columnKey,
            SORTINGTYPE?.asc
          );
        } else if (sorter?.order === "descend") {
          handleDescendingAndAscendingOrder(
            sorter?.columnKey,
            SORTINGTYPE?.desc
          );
        } else {
          handleDescendingAndAscendingOrder(
            sorter?.columnKey,
            SORTINGTYPE?.default
          );
        }
      }
    };

    useEffect(() => {
      if (isExplore || isWorkSpace) {
        const elem = document.getElementsByClassName("ant-table-content");
        let secondTable = elem[1];
        let firstTable = elem[0];
        if (secondTable) {
          secondTable.addEventListener("scroll", (e) => {
            firstTable.scrollLeft = secondTable?.scrollLeft;
          });
        }
      }
    }, []);

    return (
      <>
        {isExplore || isWorkSpace ? (
          <>
            <div
              style={{
                position: isExplore ? "sticky" : "",
                top: isExplore ? "80px" : "",
                zIndex: isExplore ? "1000" : "",
              }}
              className="table"
            >
              <Table
                columns={header}
                dataSource={[]}
                scroll={{
                  x: "100%",
                }}
                onChange={tableHandleChange}
                pagination={false}
              />
            </div>
            <div
              style={{ overflow: "auto" }}
              className="table2"
              id={isExplore && id ? id : ""}
            >
              <Table
                columns={header}
                dataSource={rows}
                showHeader={false}
                scroll={{
                  x: "100%",
                }}
                onChange={tableHandleChange}
                pagination={false}
                sortDirections={["ascend", "descend"]}
              />

              <div
                style={{
                  display: "flex",
                  boxShadow: "0 1px 2px rgba(0, 0, 0, 0.2)",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {pagination === true && (
                  <TablePagionationDropdown
                    handleChange={handleChangeRowsPerPage}
                    value={rowsPerPage}
                  />
                )}
                {pagination === true && totalAvailableData > 0 && (
                  <Pagination
                    className="pagination-bar"
                    currentPage={Number(page) + 1}
                    totalCount={Number(totalAvailableData)}
                    pageSize={rowsPerPage}
                    onPageChange={(page) => handleChangePage(page)}
                  />
                )}
              </div>
            </div>
          </>
        ) : (
          <div className="table2">
            <Table
              columns={header}
              dataSource={rows}
              showHeader={true}
              scroll={
                height
                  ? {
                      x: "100%",
                      y: height,
                    }
                  : {
                      x: "100%",
                    }
              }
              onChange={tableHandleChange}
              pagination={false}
            />
            <div
              style={{
                display: "flex",
                boxShadow: "0 1px 2px rgba(0, 0, 0, 0.2)",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {pagination === true && (
                <TablePagionationDropdown
                  handleChange={handleChangeRowsPerPage}
                  value={rowsPerPage}
                />
              )}
              {pagination === true && totalAvailableData > 0 && (
                <Pagination
                  className="pagination-bar"
                  currentPage={Number(page) + 1}
                  totalCount={Number(totalAvailableData)}
                  pageSize={rowsPerPage}
                  onPageChange={(page) => handleChangePage(page)}
                />
              )}
            </div>
          </div>
        )}
      </>
    );
  }
);

export default DataTable;
