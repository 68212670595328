import React from "react";
import { Paper, Table, TableBody, TableContainer, TableCell, TableRow, TablePagination, TableHead, IconButton } from "@mui/material";
import { experimentalStyled as styled } from "@mui/material/styles";
import { Button } from "@mui/material";

const DeleteBtn = styled(Button)({
  color: "#fff",
  backgroundColor: "#f1556c",
  borderColor: "#f1556c",
  marginLeft: "10px",
  height: "30px",
  "&:hover": {
    backgroundColor: "#be3b4e !important",
    borderColor: "#be3b4e !important",
  },
});
export default function SubscriptionTable(props) {
  function sanitizeDate(value) {
    let date = new Date(value);
    return `${date.toLocaleString("default", { month: "short" })} ${date.getDate()} ${date.getFullYear()}`;
  }

  return (
    <Paper sx={{ width: "100%" }}>
      <TableContainer>
        <Table>
          <TableHead style={{ background: "#c2d8e5" }}>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Validity</TableCell>
              <TableCell>Data Range</TableCell>
              <TableCell>Points</TableCell>
              <TableCell>Max Users </TableCell>
              <TableCell>Status</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props?.subscriptionData?.map((e, index) => {
              return (
                <>
                  <TableRow>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{e.subscription?.detail?.type}</TableCell>
                    <TableCell>{e.subscription?.detail?.price?.amount}</TableCell>
                    <TableCell>
                      {sanitizeDate(e?.subscription?.meta?.access_validity_interval?.start_date) +
                        "-" +
                        sanitizeDate(e?.subscription?.meta?.access_validity_interval?.end_date)}
                    </TableCell>
                    <TableCell>
                      {sanitizeDate(e?.subscription?.meta?.data_availability_interval?.start_date) +
                        "-" +
                        sanitizeDate(e?.subscription?.meta?.data_availability_interval?.end_date)}
                    </TableCell>
                    <TableCell>{e?.subscription?.detail?.purchase_points}</TableCell>
                    <TableCell>{e?.subscription?.detail?.max_users}</TableCell>
                    <TableCell>
                      {props?.is_active ? (
                        <span className="bg-soft-green set-interval">Activated</span>
                      ) : (
                        <span className="bg-soft-red set-interval">Deactivated</span>
                      )}
                    </TableCell>
                    {/* <TableCell>
                      <DeleteBtn>Report</DeleteBtn>
                    </TableCell> */}
                  </TableRow>
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        id="pagination"
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={props.totalAvailableData}
        rowsPerPage={props.rowsPerPage}
        page={props.page}
        onPageChange={props.handleChangePage}
        onRowsPerPageChange={props.handleChangeRowsPerPage}
      />
    </Paper>
  );
}
