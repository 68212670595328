import React, { useCallback, useContext } from "react";
import { useEffect, useState } from "react";
import { getDashboredData } from "../../Services/service";
import moment from "moment";
import { Container } from "@mui/material";
import "./DashboardNewUi.scss";
import Loading from "../../Component/SuccessLoader/success";
import Error from "../../Component/ErrorLoader/error";
import DashboardComponent from "./Component/DashboardCards/DashboardCardsUi";
import throwError from "../../utils/throwError";
import ErrorData from "../../utils/ErrorHandling";
import {
  Delete,
  Retrieving_Dashboard,
  planExpiredWarning,
} from "../../utils/LoadingMsg";
import { AES, enc } from "crypto-js";
import DashboardTable from "./Component/DashboardTables/DashboardTables";
import { logger } from "../../Loggers/Loggers";
import { UserContext } from "../../Component/Navbar/Navbar";
import DashboardSideBar from "./Component/DashboardSideBar/DashboardSideBar";
import DashboardDropdown from "./Component/DashboardDropdown/DashboardDropdown";
import { getCookie } from "../../utils/CommanFunction";
import { useAppContext } from "../../Contexts/AppContext";

const DashboardNewUi = (props) => {
  const { setProgressBarValue, setIsFromCompanyDetailPage } =
    useContext(UserContext);
  const [isMobileOrTablateScreen, setIsMobileOrTablateScreen] = useState(false);
  const [sidebarContent, setSidebarContent] = useState({
    "SAVE QUERY": true,
    "FAVORITE COMPANY": false,
    "RECENT SEARCHES": false,
    "RECOMMEND BUYERS/SELLERS": false,
    COMPETITORS: false,
    "POTENTIAL MARKET": false,
    "RECENT COUNTRY SEARCH": false,
    HELP: false,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState({});
  const [getDashboredValues, setDashboredValue] = useState([]);
  const [loadingMsg, setLoadingMsg] = useState({});
  const [arrForDashboard, setArrForDashboard] = useState([]);
  const [isPlanExpire, setIsPlanExpire] = useState();
  const secretKey = process.env.REACT_APP_CRYPTO_JS_KEY;
  const { isPlanExpire: isPlanExpireData } = useAppContext();

  useEffect(() => {
    const isUserFirstLogin = getCookie("is_first_login");
    // const secretKey = process.env.REACT_APP_CRYPTO_JS_KEY;
    // const encryptedIsActive = JSON.parse(localStorage.getItem("isActive"));
    // const encryptedIsExtended = JSON.parse(localStorage.getItem("isExtended"));
    // if (encryptedIsActive && encryptedIsExtended) {
    //   const decryptedIsActive = AES.decrypt(
    //     encryptedIsActive,
    //     secretKey
    //   ).toString(enc.Utf8);
    //   const decryptedIsExtended = AES.decrypt(
    //     encryptedIsExtended,
    //     secretKey
    //   ).toString(enc.Utf8);
    //   setIsPlanExpire({
    //     isActive: decryptedIsActive ?? false,
    //     isExtended: decryptedIsExtended ?? false,
    //   });
    // }
    setIsPlanExpire({
      isActive: isPlanExpireData.isActive ?? false,
      isExtended: isPlanExpireData.isExtended ?? false,
    });
    if (isUserFirstLogin === "1" || isUserFirstLogin === 1) {
      setSidebarContent({ ...sidebarContent, "SAVE QUERY": false, HELP: true });
    }
  }, []);
  useEffect(() => {
    if (window?.screen?.width < 769) {
      setIsMobileOrTablateScreen(true);
    } else {
      setIsMobileOrTablateScreen(false);
    }
  }, [window?.screen?.width]);

  useEffect(() => {
    setArrForDashboard([
      {
        spanStyle: "textStyles",
        text: "Total Users",
      },
      {
        spanStyle: "textStyles",
        text: " Available Countries",
      },
      {
        spanStyle: "textStyles",
        text: "Available Data Range",
      },
      {
        spanStyle: "textStyles",
        text: "Available Credits",
      },
      {
        spanStyle: "textStyles",
        text: "Total Workspaces",
      },
      {
        spanStyle: "textStyles",
        text: "Validity Expiry",
      },
    ]);
  }, []);

  const setErrorForDashboard = (err) => {
    const errorForLogger = `Dashboard Component:- ${
      err?.message ? err?.message : err?.msg
    }`;
    logger(errorForLogger);
    setLoading(false);
    setError(true);
    setErrorMsg({
      title: err.status === 401 ? "Warning" : "Error",
      content:
        err?.status === 500
          ? ErrorData?.INTERNAL_SERVER
          : err.status === 401
          ? err?.msg
          : ErrorData?.SOMETHING_WENT_WRONG,
    });
  };

  useEffect(() => {
    setLoading(true);
    setLoadingMsg(Retrieving_Dashboard);
    getDashboredData()
      .then((ele) => {
        if (ele?.status === 401 || ele?.status === 500) {
          throw throwError(ele);
        }
        if (ele?.data) {
          localStorage.setItem("userRole", JSON.stringify(ele?.role));
          ele.role === "ADMINISTRATOR"
            ? setArrForDashboard([
                {
                  spanStyle: "textStyles",
                  text: "Total Users",
                },
                {
                  spanStyle: "textStyles",
                  text: " Available Countries",
                },
                {
                  spanStyle: "textStyles",
                  text: "Available Data Range",
                },
                {
                  spanStyle: "textStyles",
                  text: "Available Credits",
                },
                {
                  spanStyle: "textStyles",
                  text: " Total Workspaces",
                },
                {
                  spanStyle: "textStyles",
                  text: "Validity Expiry",
                },
              ])
            : setArrForDashboard([
                {
                  spanStyle: "textStyles",
                  text: " Available Countries",
                },
                {
                  spanStyle: "textStyles",
                  text: "Available Data Range",
                },

                {
                  spanStyle: "textStyles",
                  text: "Available Credits",
                },

                {
                  spanStyle: "textStyles",
                  text: " Total Workspaces",
                },
                {
                  spanStyle: "textStyles",
                  text: "Validity Expiry",
                },
                {
                  spanStyle: "textStyles",
                  text: "User Role",
                },
              ]);
          const arr = [...ele?.data];
          let titlesForDashBoard = [];
          arr?.forEach((e) => {
            ele?.role === "ADMINISTRATOR"
              ? (titlesForDashBoard = [
                  {
                    title: e?.userCount,
                  },
                  {
                    title: e?.countryArray,
                  },
                  {
                    title:
                      moment(
                        e?.availableDataRange?.start_date,
                        "YYYY-MM-DDTHH:mm:ss"
                      ).format("YYYY") +
                      "-" +
                      moment(
                        e?.availableDataRange?.end_date,
                        "YYYY-MM-DDTHH:mm:ss"
                      ).format("YYYY"),
                  },
                  {
                    title: e?.availableCredits ? e?.availableCredits : 0,
                  },
                  {
                    title: e?.workspaceCount ? e?.workspaceCount : 0,
                  },
                  {
                    title: moment(
                      e?.validity?.end_date,
                      "YYYY-MM-DDTHH:mm:ss"
                    ).format("DD-MM-YYYY"),
                  },
                ])
              : (titlesForDashBoard = [
                  {
                    title: e?.countryArray,
                  },
                  {
                    title:
                      moment(
                        e?.availableDataRange?.start_date,
                        "YYYY-MM-DDTHH:mm:ss"
                      ).format("YYYY") +
                      "-" +
                      moment(
                        e?.availableDataRange?.end_date,
                        "YYYY-MM-DDTHH:mm:ss"
                      ).format("YYYY"),
                  },
                  {
                    title: e?.availableCredits ? e?.availableCredits : 0,
                  },
                  {
                    title: e?.workspaceCount,
                  },
                  {
                    title: moment(
                      e?.validity?.end_date,
                      "YYYY-MM-DDTHH:mm:ss"
                    ).format("DD-MM-YYYY"),
                  },
                  {
                    title:
                      ele?.role === "SUPPORT" ? ele?.role : ele?.role + "ER",
                  },
                ]);
            setProgressBarValue(e?.companyCredits ?? 0);
            const encryptedCompanyCredits = AES.encrypt(
              e?.companyCredits?.toString(),
              secretKey
            ).toString();
            localStorage.setItem(
              "company_detail_credit",
              JSON.stringify(encryptedCompanyCredits ?? 0)
            );
            setDashboredValue(titlesForDashBoard);
          });
        } else {
          throw new Error();
        }
        setLoading(false);
      })
      .catch((err) => {
        setErrorForDashboard(err);
      });
  }, []);

  const TableComponent = useCallback(
    () => (
      <DashboardTable
        CLIENT_ACCOUNT_ID={props?.CLIENT_ACCOUNT_ID}
        CLIENT_USER_ID={props?.CLIENT_USER_ID}
        setErrorMsg={setErrorMsg}
        setLoading={setLoading}
        setError={setError}
        isPlanExpire={isPlanExpire}
        setLoadingMsg={setLoadingMsg}
        sidebarContent={sidebarContent}
      />
    ),
    [isPlanExpire, sidebarContent]
  );

  return (
    <div id="dashboard">
      <Container className="dashboard-container">
        {error && <Error errorMsg={errorMsg} open={error} setOpen={setError} />}
        {loading && (
          <Loading title={loadingMsg?.title} content={loadingMsg?.content} />
        )}

        <DashboardComponent
          arrForDashboard={arrForDashboard}
          getDashboredValues={getDashboredValues}
          setErrorMsg={setErrorMsg}
          setLoading={setLoading}
          setError={setError}
        />
        {isMobileOrTablateScreen ? (
          <div className="dashbord-table-container">
            <DashboardDropdown
              setSidebarContent={setSidebarContent}
              sidebarContent={sidebarContent}
              TableComponent={TableComponent}
            />
          </div>
        ) : (
          <div className="dashbord-table-container">
            <DashboardSideBar
              setSidebarContent={setSidebarContent}
              sidebarContent={sidebarContent}
              TableComponent={TableComponent}
            />
          </div>
        )}
      </Container>
    </div>
  );
};
export default DashboardNewUi;
