import React from "react";

const InputBox = ({ value, handleOnChange, placeholder }) => {
  return (
    <input
      type="text"
      style={{
        width: "100%",
        height: "35px",
        fontSize: "16px",
        padding: "0px 10px",
        border: "1px solid #ced4da",
        color: "#495057",
        outline: "none",
        borderRadius: "0.25rem",
      }}
      placeholder={placeholder}
      value={value}
      onChange={handleOnChange}
    />
  );
};

export default InputBox;
