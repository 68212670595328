import React from "react";
// import { styled } from "@mui/material/styles";
// import { InputBase, FormControl, MenuItem, Select } from "@mui/material";
// const BootstrapInput = styled(InputBase)(({ theme }) => ({
//   "label + &": {
//     marginTop: theme.spacing(3),
//   },
//   "& .MuiInputBase-input": {
//     borderRadius: 4,
//     position: "relative",
//     backgroundColor: theme.palette.background.paper,
//     border: "1px solid #ced4da",
//     fontSize: 16,
//     padding: "3px 60px 5px 12px",
//     transition: theme.transitions.create(["border-color", "box-shadow"]),
//     // Use the system font instead of the default Roboto font.
//     fontFamily: [
//       "-apple-system",
//       "BlinkMacSystemFont",
//       '"Segoe UI"',
//       "Roboto",
//       '"Helvetica Neue"',
//       "Arial",
//       "sans-serif",
//       '"Apple Color Emoji"',
//       '"Segoe UI Emoji"',
//       '"Segoe UI Symbol"',
//     ].join(","),
//     "&:focus": {
//       borderRadius: 4,
//       borderColor: "#80bdff",
//       boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
//     },
//   },
// }));

export default function TablePaginationDropdown(props) {
  return (
    <div style={{ padding: "10px", marginLeft: "10px" }}>
      {/* <FormControl variant="standard">
        <Select
          labelId="demo-customized-select-label"
          id="demo-customized-select"
          value={props.value}
          onChange={(e) => props.handleChange(e?.target?.value)}
          input={<BootstrapInput />}
          style={{ color: "#5c6165" }}
        >
          <MenuItem value={10}>10/rows</MenuItem>
          <MenuItem value={25}>25/rows</MenuItem>
          <MenuItem value={50}>50/rows</MenuItem>
          <MenuItem value={100}>100/rows</MenuItem>
        </Select>
      </FormControl> */}

      <select labelId="demo-customized-select-label" value={props?.value} style={{ height: "30px", border: "1px solid grey", borderRadius: "4px" }} onChange={(e) => props?.handleChange(e?.target?.value)}>
        <option value={10}>10 / rows</option>
        <option value={25}>25 / rows</option>
        <option value={50}>50 / rows</option>
        <option value={100}>100 / rows</option>
      </select>
    </div>
  );
}
