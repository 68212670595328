import React, { useState } from "react";
import "./DashboardSideBar.scss";
import { IconButton } from "@mui/material";
import {
  Save as SaveIcon,
  Star as StarIcon,
  History as HistoryIcon,
  Handshake as HandshakeIcon,
  Group as GroupIcon,
  TrendingUp as TrendingUpIcon,
  Public as PublicIcon,
} from "@mui/icons-material";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import OndemandVideoOutlinedIcon from "@mui/icons-material/OndemandVideoOutlined";

const tabs = [
  { name: "SAVE QUERY", icon: <SaveIcon /> },
  { name: "FAVORITE COMPANY", icon: <StarIcon /> },
  { name: "RECENT SEARCHES", icon: <HistoryIcon /> },
  { name: "RECOMMEND BUYERS/SELLERS", icon: <HandshakeIcon /> },
  { name: "COMPETITORS", icon: <GroupIcon /> },
  { name: "POTENTIAL MARKET", icon: <TrendingUpIcon /> },
  { name: "RECENT COUNTRY SEARCH", icon: <PublicIcon /> },
  { name: "HELP", icon: <OndemandVideoOutlinedIcon /> },
];

const DashboardSideBar = ({
  TableComponent,
  sidebarContent,
  setSidebarContent,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const handleTabClick = (name) => {
    setSidebarContent((prevContent) => {
      if (prevContent[name]) return prevContent;
      const newContent = {};
      for (let key in prevContent) {
        newContent[key] = key === name;
      }
      return newContent;
    });
  };

  const handleSidebarToggle = () => {
    setIsCollapsed((prev) => !prev);
  };

  return (
    <div className="dashboard-container">
      <div className={`sidebar ${isCollapsed ? "collapsed" : ""}`}>
        <IconButton className="toggle-btn" onClick={handleSidebarToggle}>
          {isCollapsed ? <ArrowRightIcon /> : <ArrowLeftIcon />}
          {isCollapsed ? "" : "QUICK ACCESS"}
        </IconButton>
        <ul className="tab-list">
          {tabs.map((tab, index) => (
            <li
              key={index}
              className={`tab-item ${sidebarContent[tab.name] ? "active" : ""}`}
              onClick={() => handleTabClick(tab.name)}
            >
              {tab.icon}
              {!isCollapsed && <span className="tab-text">{tab.name}</span>}
            </li>
          ))}
        </ul>
      </div>
      <div className="sidebar-table-container">{TableComponent()}</div>
    </div>
  );
};

export default DashboardSideBar;
