

export const MarketCountryHeader = [
    { key: "company_name", title: "Company Name" },
    { key: "compare_date", title: "Compare Date" },
    { key: "value", title: "Value", textAlign: "right" },
    { key: "quantity", title: "Quantity", textAlign: "right" },
    { key: "shipment", title: "Shipment", textAlign: "right" },
]
export const MarketCountryCollapseHeader = [
    { key: "hsCode", title: "HSCODE 4 DIGIT", rowSpan: "2", textAlign: "center", className: "CompareTableWithCollapse-border-top-bottom" },
    { key: "hsCode_description", title: "HSCODE DESCRIPTION", rowSpan: "2", textAlign: "center", className: "CompareTableWithCollapse-border-top-bottom " },
    { key: "value", title: "Value", colSpan: "3", textAlign: "center", className: "CompareTableWithCollapse-border-top" },
    { key: "quantity", title: "Quantity", colSpan: "3", textAlign: "center", className: "CompareTableWithCollapse-border-top" },
    { key: "shipment", title: "Shipment", colSpan: "3", textAlign: "center", className: "CompareTableWithCollapse-border-top" },
]
export const MarketCountryCollapseHeader2 = [
    { key: "date", title: "date", textAlign: "center", className: "CompareTableWithCollapse-border-bottom", top: "50px" },
    { key: "compareDate", title: "compareDate", textAlign: "center", className: "CompareTableWithCollapse-border-bottom", top: "50px" },
    { key: "difference", title: "Difference", colSpan: "3", textAlign: "center", className: "CompareTableWithCollapse-border-bottom", top: "50px" },
]


export const MarketCountryHeader3 = [
    { key: "hsCode", title: "hsCode", textAlign: "center" },
    { key: "hsCode_description", title: "hsCode description", className: "scroll" },
    { key: "value1", title: "value1" },
    { key: "value2", title: "value2" },
    { key: "difference", title1: "value1", title2: "value2" },
    { key: "quantity1", title: "quantity1" },
    { key: "quantity2", title: "quantity2" },
    { key: "difference", title1: "quantity1", title2: "quantity2" },
    { key: "shipments1", title: "shipments1" },
    { key: "shipments2", title: "shipments2" },
    { key: "difference", title1: "shipments1", title2: "shipments2" },
]

const MarketProductHeader = [
    { key: "hsCode", title: "HSCODE" },
    { key: "hsCode_description", title: "HsCode Description" },
    { key: "compare_date", title: "Compare Date" },
    { key: "value", title: "Value", textAlign: "right" },
    { key: "quantity", title: "Quantity", textAlign: "right" },
    { key: "shipment", title: "Shipment", textAlign: "right" },
]
const MarketProductHeaderWithPort = [
    { key: "hsCode", title: "HSCODE" },
    { key: "hsCode_description", title: "HsCode Description" },
    { key: "name", title: "PORT" },
    { key: "compare_date", title: "Compare Date" },
    { key: "value", title: "Value" },
    { key: "quantity", title: "Quantity", textAlign: "right" },
    { key: "shipment", title: "Shipment", textAlign: "right" },
]
const MarketProductHeaderWithCountry = [
    { key: "hsCode", title: "HSCODE" },
    { key: "hsCode_description", title: "HsCode Description" },
    { key: "name", title: "COUNTRY" },
    { key: "compare_date", title: "Compare Date" },
    { key: "value", title: "Value", textAlign: "right" },
    { key: "quantity", title: "Quantity", textAlign: "right" },
    { key: "shipment", title: "Shipment", textAlign: "right" },
]

export const ProductTableHeader = {
    SELECT: MarketProductHeader,
    PORT: MarketProductHeaderWithPort,
    COUNTRY: MarketProductHeaderWithCountry,
}