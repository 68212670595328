import React, { useState } from "react";
import "./DashboardDropdown.scss";
import { IconButton } from "@mui/material";
import {
  Save as SaveIcon,
  Star as StarIcon,
  History as HistoryIcon,
  Handshake as HandshakeIcon,
  Group as GroupIcon,
  TrendingUp as TrendingUpIcon,
  Public as PublicIcon,
} from "@mui/icons-material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import OndemandVideoOutlinedIcon from "@mui/icons-material/OndemandVideoOutlined";

const tabs = [
  { name: "SAVE QUERY", icon: <SaveIcon /> },
  { name: "FAVORITE COMPANY", icon: <StarIcon /> },
  { name: "RECENT SEARCHES", icon: <HistoryIcon /> },
  { name: "RECOMMEND BUYERS/SELLERS", icon: <HandshakeIcon /> },
  { name: "COMPETITORS", icon: <GroupIcon /> },
  { name: "POTENTIAL MARKET", icon: <TrendingUpIcon /> },
  { name: "RECENT COUNTRY SEARCH", icon: <PublicIcon /> },
  { name: "HELP", icon: <OndemandVideoOutlinedIcon /> },
];

const DashboardDropdown = ({
  TableComponent,
  sidebarContent,
  setSidebarContent,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [selectedTab, setSelectedTab] = useState("SAVE QUERY");

  const handleTabClick = (name) => {
    setSidebarContent((prevContent) => {
      const newContent = {};
      for (let key in prevContent) {
        newContent[key] = key === name;
      }
      return newContent;
    });
    setSelectedTab(name);
    setIsCollapsed(true);
  };

  const handleSidebarToggle = () => {
    setIsCollapsed((prev) => !prev);
  };

  const getSelectedIcon = () => {
    const selectedTabObj = tabs.find((tab) => tab.name === selectedTab);
    return selectedTabObj ? selectedTabObj.icon : null;
  };

  return (
    <div className="dropdown-sidebar-container">
      <div className="dropdown-toggle-btn" onClick={handleSidebarToggle}>
        <IconButton>
          {isCollapsed ? (
            <ArrowDropDownIcon className="toggle-dropdown-btn-dashboard" />
          ) : (
            <ArrowDropUpIcon className="toggle-dropdown-btn-dashboard" />
          )}
        </IconButton>
        <span
          style={{
            display: "flex",
            alignItems: "center",
            maxWidth: "300px",
          }}
        >
          <span style={{ marginRight: "10px", marginTop: "3px" }}>
            {getSelectedIcon()}
          </span>
          {selectedTab}
        </span>
      </div>
      <div
        className={`dropdown-sidebar ${
          isCollapsed ? "collapsed-dropdown" : "expanded-dropdown"
        }`}
      >
        <ul className="dropdown-tab-list">
          {tabs.map((tab, index) => (
            <li
              key={index}
              className={`dropdown-tab-item ${
                sidebarContent[tab.name] ? "active" : ""
              }`}
              onClick={() => handleTabClick(tab.name)}
            >
              {tab.icon}
              <span className="dropdown-tab-text">{tab.name}</span>
            </li>
          ))}
        </ul>
      </div>
      <div className="dropdown-sidebar-table-container">{TableComponent()}</div>
    </div>
  );
};

export default DashboardDropdown;
