import React, { useState, useEffect, useCallback } from "react";
import {
  getUserActivity,
  getUserEmails,
  getUserWorkSpaceActivity,
} from "../../Services/service";
import { Container, Grid } from "@mui/material";
import StickyTable from "../../Component/Table/Table";
import Error from "../../Component/ErrorLoader/error";
import Loading from "../../Component/SuccessLoader/success";
import throwError from "../../utils/throwError";
import ErrorData from "../../utils/ErrorHandling";
import "./ActivityTracker.scss";
import moment from "moment";
import DateRangePicker from "../../Component/DateRangePicker/DateRangePicker";
import { DATEFORMAT } from "../ExploreCountryShipmentNewUI/Explore.enum";
import { ButtonComponent } from "../../Component/ButtonComponent/Button";
import { getCookie, translateButtonClick } from "../../utils/CommanFunction";
import ToggleSwitchIe from "../../Component/ToggleSwitch/ToggleSwitchIe";
import {
  POINTS_ACTIVITY_TABLE_HEADER,
  SEARCH_ACTIVITY_TABLE_HEADER,
  SWITCHVALUE,
} from "./ActivityTracker.enum";
import Select from "react-select";
import { logger } from "../../Loggers/Loggers";

const selectStyles = {
  singleValue: (base, state) => ({
    ...base,
    color: state.selectProps.menuIsOpen ? "transparent" : base.color,
  }),
  control: (base) => ({
    ...base,
    height: 42,
    minHeight: 42,
    whiteSpace: "nowrap",
  }),
  valueContainer: (base) => ({
    ...base,
    flexWrap: "nowrap",
  }),
};

export default function ActivityTracker(props) {
  let currentCheckedValue;
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState({});
  const [activityData, setActivityData] = useState([]);
  const [userActivityData, setUserActivityData] = useState([]);
  const [noDataFound, setNoDataFound] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [dateRange, setDateRange] = useState({
    minDateForDatePicker: moment("2019/01/01").format(DATEFORMAT?.format),
    monthRange: 1,
    maxDateForEndMonth: moment().format(DATEFORMAT?.format),
    endMonthDate: "",
    startMonthDate: "",
  });

  const [currentChecked, setCurrentChecked] = useState(
    SWITCHVALUE.SEARCH_ACTIVITY
  );
  const [ActivityTableHeader, setActivityTableHeader] = useState([
    { key: "email_id", title: "Email Id" },
    { key: "role", title: "Role" },
    { key: "ipAddress", title: "IP Address" },
    { key: "activity", title: "Activity" },
    { key: "country", title: "Country" },
    { key: "tradeType", title: "TradeType" },
    { key: "query", title: "Query" },
    { key: "creditLimitDescription", title: "Description" },
    { key: "queryResponseTime", title: "Query Time" },
    { key: "queryCreatedAt", title: "CreatedAt" },
  ]);

  const [WorkspaceActivity, setWorkspaceActivity] = useState(false);
  const [userEmail, setUserEmail] = useState([]);
  const [ValueForUserEmailDropDown, setValueForUserEmailDropDown] = useState(
    []
  );
  const [userRoleForSwitch, setUserRoleForSwitch] = useState("");
  const [totalRecords, setTotalRecord] = useState(0);

  const setErrorForActivityTracker = (err) => {
    const errorForLogger = `Activity tracker:- ${
      err?.message ? err?.message : err.msg
    }`;
    logger(errorForLogger);
    setLoading(false);
    setError(true);
    setErrorMsg({
      title: err.status === 401 ? "Warning" : "Error",
      content:
        err.status === 500
          ? ErrorData.INTERNAL_SERVER
          : err.status === 401
          ? err.msg
          : ErrorData.SOMETHING_WENT_WRONG,
    });
  };

  useEffect(() => {
    const getUserRole = JSON.parse(localStorage.getItem("userRole"));
    setUserRoleForSwitch(getUserRole);
    if (getUserRole !== "ADMINISTRATOR") {
      setActivityTableHeader((prev) => [
        ...prev.slice(0, 5),
        { key: "points", title: "Deducted Points" },
        { key: "tradeRecords", title: "Trade Records" },
        ...prev.slice(5),
      ]);
    }
  }, []);

  useEffect(() => {
    const endDate = moment().format(DATEFORMAT?.format);
    const startDate = moment().subtract(1, "months").format(DATEFORMAT?.format);
    let payload = {
      userId: props.CLIENT_USER_ID,
      date_from: startDate,
      date_to: endDate,
      emails: ValueForUserEmailDropDown,
      isWorkspaceQuery:
        currentCheckedValue === SWITCHVALUE.WORKSPACE_ACTIVITY ? true : false,
      start: 0,
      length: rowsPerPage,
    };
    setDateRange({
      ...dateRange,
      endMonthDate: endDate,
      startMonthDate: startDate,
    });
    handleUserActivity(payload, SWITCHVALUE.SEARCH_ACTIVITY);
  }, []);

  useEffect(() => {
    const userId = getCookie("user_id");
    getUserEmails(userId)
      .then((res) => {
        if (res.status === 200) {
          if (res && res.data) {
            const emailData = res?.data?.data?.map(({ email_id, role }) => ({
              email_id,
              role,
            }));
            const emailIds = emailData.map((data) => ({
              value: data.email_id,
              label: data.email_id,
            }));
            setUserEmail(emailIds);
            setValueForUserEmailDropDown([]);
          }
        } else {
          throw throwError(res);
        }
      })
      .catch((err) => {
        setErrorForActivityTracker(err);
      });
  }, [WorkspaceActivity]);

  const handleUserActivity = (payload, currentCheckedValue) => {
    setLoading(true);
    setLoadingMsg({
      title: "Fetching Activity",
      content: "Please Wait",
    });
    if (currentCheckedValue === SWITCHVALUE.SEARCH_ACTIVITY) {
      getUserActivity(payload)
        .then((successPayload) => {
          if (successPayload?.status === 500) {
            throw throwError(successPayload);
          } else if (successPayload?.status === 409) {
            setNoDataFound(true);
          } else {
            if (successPayload?.data) {
              if (successPayload?.data && successPayload?.data.length > 0) {
                successPayload.data.forEach((e) => {
                  let analyseArr = [];
                  if (e?.query) {
                    JSON.parse(e.query) &&
                      JSON.parse(e.query).length &&
                      JSON.parse(e.query).forEach((ele) => {
                        if (typeof ele.fieldValue === "string") {
                          analyseArr.push(ele);
                        } else if (typeof ele.fieldValue === "object") {
                          if (Object.keys(ele.fieldValue).length > 0) {
                            analyseArr.push(ele);
                          }
                        }
                      });
                  }
                  e.query = analyseArr;
                });
                setActivityData(successPayload.data);
                setUserActivityData(successPayload.data);
                setTotalRecord(successPayload?.data[0]?.totalDocuments);
                setLoading(false);
              } else {
                setNoDataFound(true);
                setLoading(false);
              }
            } else {
              throw new Error();
            }
          }
        })
        .catch((err) => {
          setErrorForActivityTracker(err);
        });
    } else {
      setLoading(true);
      setLoadingMsg({
        title: "Fetching Activity",
        content: "Please Wait",
      });
      getUserWorkSpaceActivity(payload)
        .then((res) => {
          if (res.status === 200) {
            res?.data?.data.forEach((e) => {
              let analyseArr = [];
              if (e?.query) {
                JSON.parse(e.query) &&
                  JSON.parse(e.query).length &&
                  JSON.parse(e.query).forEach((ele) => {
                    if (typeof ele.fieldValue === "string") {
                      analyseArr.push(ele);
                    } else if (typeof ele.fieldValue === "object") {
                      if (Object.keys(ele.fieldValue).length > 0) {
                        analyseArr.push(ele);
                      }
                    }
                  });
              }
              e.query = analyseArr;
            });
            setActivityData(res?.data?.data);
            setUserActivityData(res?.data?.data);
            setTotalRecord(res?.data?.data[0]?.totalDocuments);
            setLoading(false);
          }
        })
        .catch((err) => {
          setErrorForActivityTracker(err);
        });
    }
    translateButtonClick();
  };
  const handleChangePage = (event) => {
    let newPage = parseInt(event.target.textContent);
    let offset = (newPage - 1) * rowsPerPage;
    setPage(newPage - 1);
    let payload = {
      userId: props.CLIENT_USER_ID,
      date_from: dateRange?.startMonthDate,
      date_to: dateRange?.endMonthDate,
      emails: ValueForUserEmailDropDown,
      isWorkspaceQuery:
        currentChecked === SWITCHVALUE.SEARCH_ACTIVITY ? false : true,
      start: offset,
      length: rowsPerPage,
    };
    handleUserActivity(payload, currentCheckedValue);
  };

  const handleChangeRowsPerPage = (value) => {
    setRowsPerPage(parseInt(value));
    setPage(0);
    let payload = {
      userId: props.CLIENT_USER_ID,
      date_from: dateRange?.startMonthDate,
      date_to: dateRange?.endMonthDate,
      emails: ValueForUserEmailDropDown,
      isWorkspaceQuery:
        currentChecked === SWITCHVALUE.SEARCH_ACTIVITY ? false : true,
      start: 0,
      length: parseInt(value),
    };
    handleUserActivity(payload, currentChecked);
  };
  const onSearchClick = () => {
    let payload = {
      userId: props.CLIENT_USER_ID,
      date_from: dateRange?.startMonthDate,
      date_to: dateRange?.endMonthDate,
      emails: ValueForUserEmailDropDown,
      isWorkspaceQuery:
        currentChecked === SWITCHVALUE.SEARCH_ACTIVITY ? false : true,
      start: 0,
      length: rowsPerPage,
    };
    handleUserActivity(payload, currentChecked);
  };

  const handleChangeSwitch = (e) => {
    setPage(0);
    setLoading(true);
    setWorkspaceActivity(!WorkspaceActivity);
    setCurrentChecked(
      currentChecked === SWITCHVALUE.SEARCH_ACTIVITY
        ? SWITCHVALUE.WORKSPACE_ACTIVITY
        : SWITCHVALUE.SEARCH_ACTIVITY
    );
    currentCheckedValue =
      currentChecked === SWITCHVALUE.SEARCH_ACTIVITY
        ? SWITCHVALUE.WORKSPACE_ACTIVITY
        : SWITCHVALUE.SEARCH_ACTIVITY;
    if (currentCheckedValue === SWITCHVALUE.WORKSPACE_ACTIVITY) {
      setActivityTableHeader(POINTS_ACTIVITY_TABLE_HEADER);
    } else {
      setActivityTableHeader(SEARCH_ACTIVITY_TABLE_HEADER);
    }
    const endDate = moment().format(DATEFORMAT?.format);
    const startDate = moment().subtract(1, "months").format(DATEFORMAT?.format);
    let payload = {
      userId: props.CLIENT_USER_ID,
      date_from: dateRange?.startMonthDate,
      date_to: dateRange?.endMonthDate,
      emails: [],
      isWorkspaceQuery:
        currentCheckedValue === SWITCHVALUE.WORKSPACE_ACTIVITY ? true : false,
      start: 0,
      length: 10,
    };
    setDateRange({
      ...dateRange,
      endMonthDate: endDate,
      startMonthDate: startDate,
    });
    setTimeout(() => {
      handleUserActivity(payload, currentCheckedValue);
    }, 500);
  };

  const handelChangeSelect = (e) => {
    if (e && e?.length > 0) {
      const values = e?.map((item) => item.value);
      setValueForUserEmailDropDown(values);
    }
  };
  const dataTable = useCallback(() => {
    return (
      <>
        <StickyTable
          columns={ActivityTableHeader}
          rows={activityData}
          showPagination={true}
          totalAvailableData={totalRecords}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          isFromActivity={true}
          height={"60vh"}
        />
      </>
    );
  }, [
    ActivityTableHeader,
    activityData,
    userActivityData,
    rowsPerPage,
    page,
    userRoleForSwitch,
    totalRecords,
  ]);

  const toggleSwitchForPointsAndActivity = useCallback(() => {
    return (
      <>
        {userRoleForSwitch === "ADMINISTRATOR" && (
          <ToggleSwitchIe
            onChange={handleChangeSwitch}
            currentChecked={currentChecked}
            ActivityTracker={true}
          />
        )}
      </>
    );
  }, [userRoleForSwitch, currentChecked]);

  return (
    <Container maxWidth="xxl" id="ActivityTracker">
      {error && <Error open={error} errorMsg={errorMsg} setOpen={setError} />}
      {loading && (
        <Loading title={loadingMsg.title} content={loadingMsg.content} />
      )}
      <div className="title-date-div">
        <div className="row-dashbored" style={{ padding: "20px 0px" }}>
          <h4 className="text">
            Activity
            <span className="badge bg-soft-blue text-blue label-worksapce-name">
              Tracker
            </span>
          </h4>
        </div>
        <div className="activity-tracker-date-container">
          <DateRangePicker dateRange={dateRange} setDateRange={setDateRange} />
          {WorkspaceActivity ? (
            <>
              <Grid item lg={3} md={2.5} sm={2.6} xs={6}>
                <Select
                  options={userEmail}
                  styles={selectStyles}
                  isMulti={true}
                  closeMenuOnSelect={false}
                  onChange={handelChangeSelect}
                  hideSelectedOptions={false}
                />
              </Grid>
            </>
          ) : (
            ""
          )}
          <ButtonComponent
            name={"Submit"}
            btnClick={() => {
              onSearchClick();
            }}
            style={{ margin: "0px 0px 0px 8px" }}
          />
          {toggleSwitchForPointsAndActivity()}
        </div>
      </div>
      {dataTable()}
      {noDataFound && (
        <div
          style={{
            fontSize: "30px",
            display: "flex",
            justifyContent: "center",
            color: "rgb(108, 117, 125)",
            marginTop: "20px",
          }}
        >
          No Data Found
        </div>
      )}
    </Container>
  );
}
