// import * as React from "react";
// import Stack from "@mui/material/Stack";
// import Snackbar from "@mui/material/Snackbar";
// import MuiAlert from "@mui/material/Alert";

// const Alert = React.forwardRef(function Alert(props, ref) {
//   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
// });

// export default function Toast(props) {
//   return (
//     <Stack spacing={2} sx={{ width: "100%" }}>
//       <Snackbar open={props.openToast} autoHideDuration={4000} style={{position : "relative" , top : "0px"}}>
//         <Alert severity={props.ToastType}>This is a success message!</Alert>
//       </Snackbar>
//       <Alert severity="error">This is an error message!</Alert>
//       <Alert severity="warning">This is a warning message!</Alert>
//       <Alert severity="info">This is an information message!</Alert>
//     </Stack>

//   );
// }

import React from "react";
import { Modal } from "@mui/material";
import "./toast.scss";

export default function toast(props) {
  return (
    <Modal
      // style={{zIndex : "1299"}}
      open={props.openToast.open}
      // onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div id="toast">
        <div id="toast-header" style={{ background: props.openToast.color }}>
          <span>{props.openToast.type === "company" ? "Favourite Company" : "Favourite Shipment"}</span>
        </div>
        <div className="toast-body">
          <div className="mr-auto">
            {props.openToast.type === "company" ? (
              props.openToast.color === "green" ? (
                <div>
                  <span id="text-small">You have Marked </span>
                  <span id="cmp-name">{props.openToast.name}</span>
                  <span id="text-small"> company as your favourite </span>
                </div>
              ) : (
                <div>
                  <span id="text-small">You have Deselected </span>
                  <span id="cmp-name">{props.openToast.name}</span>
                  <span id="text-small"> company as your favourite </span>
                </div>
              )
            ) : (
              "You have marked this Shipment as your favourite"
            )}
          </div>

          <div>
            <small className="text-muted">
              You have consumed <strong style={{ color: "red" }} className="notranslate">{props.openToast.totalSelectedShipment - props.openToast.leftShipmentCount}</strong> and left
              with <strong style={{ color: "green" }} className="notranslate"> {props.openToast.leftShipmentCount}</strong> limit to mark your favourites
            </small>
          </div>
        </div>
      </div>
    </Modal>
  );
}
