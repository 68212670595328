import React from "react";
import { Button } from "@mui/material";
import { Grid } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import SelectSearch from "react-select";
import "./analyzeField.scss";
import { ButtonComponent } from "../../ButtonComponent/Button";

const AnalyzeField = (props) => {
  const selectStyles = {
    singleValue: (base, state) => ({
      ...base,
      color: state.selectProps.menuIsOpen ? "transparent" : base.color,
    }),
    control: (base) => ({
      ...base,
      height: 42,
      minHeight: 42,
      minWidth: 300,
      whiteSpace: "nowrap",
    }),
    valueContainer: (base) => ({
      ...base,
      flexWrap: "nowrap",
    }),
  };

  return (
    <Grid container className="analyzeField-container">
      <div className="row-dashbored">
        <h3 className="text">
          Explore
          <span className="badge bg-soft-blue text-blue label-worksapce-name">Analyze</span>
        </h3>
      </div>

      <div className="analyzeBtn">
        <SelectSearch
          styles={selectStyles}
          isClearable={true}
          cacheOptions
          defaultOptions={props?.getSearchOption}
          value={props?.inputValues}
          getOptionLabel={(e) => e._id}
          getOptionValue={(e) => e._id}
          options={props?.getSearchOption}
          onChange={(e) => props?.handleSearchForTable(e, "search", "")}
          className="search-form-control search-async"
          isMulti={true}
          hideSelectedOptions={false}
          closeMenuOnSelect={false}
        />

        <ButtonComponent name={"Back"} btnIcon={ArrowBackIcon} style={{ margin: "0px 8px 8px 8px ", height: "42px" }} btnClick={props?.handleBackPage} />
      </div>
    </Grid>
  );
};

export default AnalyzeField;
