import React, { useEffect, useState } from "react";
import "./TicketRaise.scss";
import Logo from "../../Asset/images/logo-dark-og-transparent.png";
import userLogo from "../../Asset/images/userLogoforTicket.png";
import ticketLogo from "../../Asset/images/ticketLogo.png";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { Button, MenuItem, Select } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormHelperText, useTheme, useMediaQuery } from "@mui/material";

import ErrorData from "../../utils/ErrorHandling";
import { getCookie, translateButtonClick } from "../../utils/CommanFunction";
import StickyTable from "../../Component/Table/Table";
import {
  createUserTicket,
  fetchTicketTableData,
  fetchUserData,
  getTicketInfo,
  updatePopStatus,
} from "../../Services/service";
import throwError from "../../utils/throwError";
import { FetchData, VideoFeedback } from "../../utils/LoadingMsg";
import {
  FinanceRelated,
  Priority,
  SalesSupportRelated,
  SELECT_QUERY,
  StatusFilter,
  TechnicalIssues,
} from "./TicketRaiseForm.enum";
import Error from "../../Component/ErrorLoader/error";
import Loading from "../../Component/SuccessLoader/success";
import ApprovedSuccess from "../../Component/ApprovedSuccessModal/ApprovedSuccess";
import ModalComponent from "../../Component/Modal/modal";
import InfoModal from "./Component/InfoModal";
import { useLocation } from "react-router";
import SelectDropDown from "../../Component/SelectDropDownNewUI/SelectDropDown";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: "0.75rem",
    marginTop: theme.spacing(1),
  },
}));

const TicketRaiseFormCustom = () => {
  const location = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [category, setCategory] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [showTicketForm, setShowTicketForm] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [allTableData, setAllTableData] = useState([]);
  const [errorMsg, setErrorMsg] = useState({});
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataTableHeader, setDataTableHeader] = useState([
    { key: "id", title: "Ticket No" },
    { key: "created_at", title: "Date" },
    { key: "title", title: "Title" },
    { key: "type", title: "Type" },
    { key: "subtype", title: "SubType" },
    { key: "subject", title: "Description" },
    { key: "status", title: "Status" },
    { key: "resolution", title: "Resolution" },
  ]);
  const [selectPriority, setSelectPriority] = useState("");
  const [ticketPriority, setTicketPriority] = useState("");
  const [initialValuesOfForm, setInitialValuesOfForm] = useState({
    name: "",
    email: "",
    phone: "",
    supportID: "",
    title: "",
    detailQuery: "",
  });
  const options = {
    Low: 1,
    Medium: 2,
    High: 3,
    Urgent: 4,
  };
  const priorityOptions = {
    OPEN: 2,
    PENDING: 3,
    RESOLVED: 4,
    CLOSED: 5,
    "Waiting on Customer": 6,
    "Waiting on Third Party": 7,
    "Work In Progress": 8,
  };
  const [loadingMsg, setLoadingMsg] = useState({});
  const [approveSuccessModalData, setApproveSuccessModalData] = useState({});
  const [approveSuccessModal, setApproveSuccessModal] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [infoModal, setInfoModal] = useState(false);
  const [infoModalDetail, setInfoModalDetail] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [filterStatusValue, setFilterStatusValue] = useState();

  const userId = getCookie("user_id");
  const emailId = getCookie("email_id");
  const countryName = location?.state?.countryName;
  const tradeType = location?.state?.tradeType;
  const upgradePlan = location?.state?.upgradePlan;
  const renewPopDetail = location?.state?.popDetail;

  const setErrForTicketManager = (err) => {
    setLoading(false);
    setError(true);
    setErrorMsg({
      title: err.status === 401 ? "Warning" : "Error",
      content:
        err.status === 500
          ? ErrorData.INTERNAL_SERVER
          : err.status === 401
          ? err.msg
          : ErrorData.SOMETHING_WENT_WRONG,
    });
  };
  const fetchUserDetailData = async () => {
    try {
      const res = await fetchUserData(userId);
      if (res?.status === 200) {
        if (res?.data) {
          setInitialValuesOfForm({
            name: res?.data?.first_name || "",
            email: res?.data?.email_id || "",
            phone: upgradePlan ? res?.data?.mobile_no : "",
            supportID: res?.data?.account_email_id || "",
            title:
              countryName || tradeType
                ? "Upgrade Plan"
                : renewPopDetail
                ? "Renew Plan"
                : "",
            detailQuery:
              countryName || tradeType
                ? `${countryName} ${tradeType ?? ""}`
                : "",
          });
        }
      } else {
        throw throwError(res);
      }
    } catch (err) {
      setErrForTicketManager(err);
    }
  };

  useEffect(() => {
    setLoading(true);
    setLoadingMsg(FetchData);
    fetchUserDetailData();
  }, [userId]);

  const fetchTableData = async () => {
    setLoading(true);
    setFilterModalOpen(false);
    let payload = {
      userId: userId,
      filter: priorityOptions[filterStatusValue] ?? 0,
    };
    try {
      const res = await fetchTicketTableData(payload);
      if (res?.status === 200) {
        setFilterStatusValue();
        if (res?.data?.length > 0) {
          setLoading(false);
          setTableData(res?.data.slice(0, rowsPerPage));
          setAllTableData(res?.data);
        } else {
          setLoading(false);
          setTableData([]);
          setAllTableData([]);
          return (
            <div
              style={{
                fontSize: "30px",
                display: "flex",
                justifyContent: "center",
                color: "rgb(108, 117, 125)",
                marginTop: "20px",
              }}
            >
              No Data Found
            </div>
          );
        }
      } else {
        throw throwError(res);
      }
    } catch (err) {
      setLoading(false);
      setErrForTicketManager(err);
    }
  };

  useEffect(() => {
    fetchTableData();
  }, [approveSuccessModal]);

  useEffect(() => {
    if (countryName || tradeType) {
      setShowTicketForm(true);
      handleSetOptions("Finance Related (FN)");
      setSelectedOption("Others");
      handleChangePriorityOptions("Urgent");
    }
  }, [countryName, tradeType]);

  const handleChangeOptions = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleSetOptions = (event) => {
    setCategory(event?.target?.value ?? event);
    switch (event?.target?.value ?? event) {
      case "Sales & Support related (SS)":
        setSelectedOptions(SalesSupportRelated ?? []);
        break;
      case "Technical Issues (TH)":
        setSelectedOptions(TechnicalIssues ?? []);
        break;
      case "Finance Related (FN)":
        setSelectedOptions(FinanceRelated ?? []);
        break;
      default:
        setSelectedOptions([]);
        break;
    }
  };

  const handleNextStep = () => {
    if (!showTicketForm) {
      if (validatePersonalInfo()) {
        setShowTicketForm(!showTicketForm);
      }
    } else {
      setShowTicketForm(!showTicketForm);
    }
  };

  const handleChangePriorityOptions = (e) => {
    setSelectPriority(e?.target?.value ?? e);
    setTicketPriority(options[e?.target?.value ?? e]);
  };

  const validateForm = () => {
    const errors = {};
    if (!initialValuesOfForm.name) errors.name = "Name is required";
    if (!initialValuesOfForm.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(initialValuesOfForm.email)) {
      errors.email = "Email address is invalid";
    }
    if (!upgradePlan) {
      if (!initialValuesOfForm.phone) {
        errors.phone = "Phone number is required";
      }
    }
    if (!initialValuesOfForm.supportID)
      errors.supportID = "Support ID is required";
    if (!initialValuesOfForm.title) errors.title = "Title is required";
    if (!category) errors.category = "Category is required";
    if (!selectedOption) errors.selectedOption = "Sub-category is required";
    if (!selectPriority) errors.selectPriority = "Priority is required";
    if (!initialValuesOfForm.detailQuery)
      errors.detailQuery = "Query details are required";

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const validatePersonalInfo = () => {
    const errors = {};
    if (!initialValuesOfForm.name) errors.name = "Name is required";
    if (!initialValuesOfForm.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(initialValuesOfForm.email)) {
      errors.email = "Email address is invalid";
    }
    if (!upgradePlan) {
      if (!initialValuesOfForm.phone) {
        errors.phone = "Phone number is required";
      }
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const handleSubmitForm = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      setLoading(true);
      setLoadingMsg({
        title: "Submitting Data...",
      });
      let payload = {
        ...initialValuesOfForm,
        priority: ticketPriority,
        user_id: userId,
        category: category,
        selectedOption: selectedOption,
      };
      try {
        await createUserTicket(payload).then((res) => {
          if (res?.status === 200) {
            setLoading(false);
            setApproveSuccessModal(true);
            setApproveSuccessModalData(VideoFeedback);
            setTimeout(() => {
              setApproveSuccessModal(false);
            }, 2000);
            setCategory("");
            setSelectedOptions([]);
            setSelectedOption("");
            setTicketPriority("");
            setSelectPriority("");
            setShowTicketForm(!showTicketForm);
            setInitialValuesOfForm({
              name: "",
              email: "",
              phone: "",
              supportID: "",
              title: "",
              detailQuery: "",
            });
            setFilterStatusValue();
            fetchUserDetailData();
            handlePopUpUpdate();
          } else {
            throw throwError(res);
          }
        });
      } catch (error) {
        setErrForTicketManager(error);
      }
    }
  };
  const handleChangePage = (newPage) => {
    let offset = (newPage - 1) * rowsPerPage;
    setPage(newPage - 1);
    if (parseInt(rowsPerPage) < allTableData?.length) {
      const newArr = allTableData.slice(offset, offset + parseInt(rowsPerPage));
      setTableData(newArr);
    }
    translateButtonClick();
  };

  const handleChangeRowsPerPage = (value) => {
    setRowsPerPage(parseInt(value));
    setPage(0);
    if (parseInt(value) < allTableData?.length) {
      const newArr = allTableData.slice(0, parseInt(value));
      setTableData(newArr);
      translateButtonClick();
    }
  };

  const handleResolutionInfo = (row) => {
    setLoading(true);
    let payload = {
      emailId: emailId,
      ticketId: row?.id,
    };
    getTicketInfo(payload)
      .then((res) => {
        if (res.status === 200) {
          if (res) {
            setInfoModalDetail({
              title: "Info",
              content: res?.data ? (
                <div>
                  {res?.data}
                  <br />
                  <span>
                    <strong>
                      NOTE: For Detailed Conversation Please Refer to Your
                      Email!
                    </strong>
                  </span>
                </div>
              ) : (
                "No resolution available for this ticket"
              ),
            });

            setLoading(false);
            setInfoModal(true);
          }
        } else {
          throw throwError(res);
        }
      })
      .catch((err) => {
        setErrForTicketManager(err);
      });
  };

  const clearError = (field) => {
    setFormErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
  };
  const onFilterStatusClick = () => {
    setFilterModalOpen(true);
  };
  const handleChangeFilterPriorityOptions = (e) => {
    setFilterStatusValue(e.target.value);
  };
  const handleOnClose = () => {
    setInfoModal(false);
  };

  const handlePopUpUpdate = () => {
    const payload = {
      popup: {
        popupType: renewPopDetail?.popCategory,
        popupData: {
          _id: renewPopDetail?._id,
          remaining_views: 0,
        },
      },
    };

    updatePopStatus(payload);
  };

  return (
    <>
      {error && <Error errorMsg={errorMsg} open={error} setOpen={setError} />}
      {loading && (
        <Loading title={loadingMsg.title} content={loadingMsg.content} />
      )}
      {approveSuccessModal && (
        <ApprovedSuccess
          Open={approveSuccessModal}
          title={approveSuccessModalData.title}
        />
      )}
      {infoModal && (
        <InfoModal
          modalDetail={infoModalDetail}
          open={infoModal}
          setOpen={setInfoModal}
          onCloseModal={handleOnClose}
        />
      )}
      <ModalComponent
        open={filterModalOpen}
        setOpen={setFilterModalOpen}
        header="Status Filter"
        modalBody={
          // <div>
          //   <FormControl variant="outlined" style={{ width: "100%" }}>
          //     <InputLabel id="query-sub-type-label">Select Filter</InputLabel>
          //     <Select
          //       labelId="query-sub-type-label"
          //       id="query-sub-type-select"
          //       value={filterStatusValue}
          //       onChange={(e) => {
          //         handleChangeFilterPriorityOptions(e);
          //       }}
          //       label="Select Filter"
          //       MenuProps={{
          //         PaperProps: {
          //           style: {
          //             maxHeight: isSmallScreen ? 200 : 48 * 4.5 + 8,
          //             width: isSmallScreen ? "100%" : 250,
          //           },
          //         },
          //       }}
          //     >
          //       {Object.values(StatusFilter).map((item) => (
          //         <MenuItem key={item} value={item}>
          //           {item}
          //         </MenuItem>
          //       ))}
          //     </Select>
          //     {formErrors.selectPriority && (
          //       <span className="error-text">{formErrors.selectPriority}</span>
          //     )}
          //   </FormControl>

          // </div>
          <div style={{ marginBottom: "10px" }}>
            <SelectDropDown
              name="Select Filter"
              value={filterStatusValue}
              label="Select Filter"
              options={StatusFilter}
              onChange={(e) => {
                handleChangeFilterPriorityOptions(e);
              }}
              widthStyle={"100%"}
              styleIndex={{ isEnabled: false, value: "0" }}
              labelName="Select Filter"
              color="#666"
              height="3.4375em"
            />
          </div>
        }
        width="400px"
        saveText="Confirm"
        saveButtonShown={true}
        saveButtonColor="#1abc9c"
        savedClicked={fetchTableData}
      />
      <div className="ticket-raise-container">
        <div className="title-div" style={{ padding: "15px 0px" }}>
          <h4 className="text set-explore-title">
            <span style={{ fontSize: "23px" }}>{"Ticket"}</span>
            <span
              className="badge bg-soft-blue text-blue label-worksapce-name"
              style={{ height: "27px" }}
            >
              {"Raise"}
            </span>
          </h4>
        </div>
        <div className="sidebar-form-container">
          <div className="ticket-form-sidebar">
            <div className="div-sidebar">
              <div className="logo-ticket">
                <img src={Logo} alt="logo" />
              </div>
              <div className="step-1">
                <div className="step-content">
                  <h2>Step-1</h2>
                  <p>Please Fill All Your Detail</p>
                </div>
                <div className="step-1-userLogo">
                  <img src={userLogo} alt="userLogo" />
                </div>
              </div>
              <div className="step-2">
                <div className="step-2-content">
                  <h2>Step-2</h2>
                  <p>Please Fill All Your Ticket Detail</p>
                </div>
                <div className="step-2-ticketLogo">
                  <img src={ticketLogo} alt="ticketLogo" />
                </div>
              </div>
            </div>
          </div>
          <div className="forms-container">
            {!showTicketForm ? (
              <div className="user-detail-form">
                <h1>Personal Info</h1>
                <div className="input-row">
                  <TextField
                    className="input-div"
                    name="name"
                    id="outlined-basic"
                    label="Enter your Name"
                    variant="outlined"
                    placeholder="Enter your Name"
                    fullWidth
                    value={initialValuesOfForm.name}
                    onChange={(e) => {
                      setInitialValuesOfForm({
                        ...initialValuesOfForm,
                        name: e.target.value,
                      });
                      clearError("name");
                    }}
                    error={!!formErrors.name}
                    helperText={formErrors.name}
                  />

                  <TextField
                    className="input-div"
                    name="email"
                    id="outlined-basic"
                    label="Enter your Email"
                    variant="outlined"
                    placeholder="Enter your Email"
                    value={initialValuesOfForm.email}
                    onChange={(e) => {
                      setInitialValuesOfForm({
                        ...initialValuesOfForm,
                        email: e.target.value,
                      });
                      clearError("email");
                    }}
                    error={!!formErrors.email}
                    helperText={formErrors.email}
                    fullWidth
                  />
                  <TextField
                    className="input-div"
                    name="phone"
                    id="outlined-basic"
                    label="Enter your Phone"
                    variant="outlined"
                    placeholder="Enter your Phone"
                    value={initialValuesOfForm.phone}
                    onChange={(e) => {
                      setInitialValuesOfForm({
                        ...initialValuesOfForm,
                        phone: e.target.value,
                      });
                      clearError("phone");
                    }}
                    error={!!formErrors.phone}
                    helperText={formErrors.phone}
                    fullWidth
                  />
                  <TextField
                    className="input-div"
                    name="supportID"
                    id="email-id-input"
                    label="ADMIN"
                    variant="outlined"
                    placeholder="Enter your Support ID"
                    value={initialValuesOfForm.supportID}
                    onChange={(e) => {
                      setInitialValuesOfForm({
                        ...initialValuesOfForm,
                        supportID: e.target.value,
                      });
                      clearError("supportID");
                    }}
                    error={!!formErrors.supportID}
                    helperText={formErrors.supportID}
                    fullWidth
                    disabled={true}
                  />
                </div>
              </div>
            ) : (
              <div className="ticket-detail-form">
                <h1>Ticket Detail</h1>
                <div className="input-row">
                  <TextField
                    name="title"
                    className="input-div"
                    id="email-id-input"
                    label="Title"
                    variant="outlined"
                    placeholder="Title"
                    autoComplete="off"
                    value={initialValuesOfForm.title}
                    onChange={(e) => {
                      setInitialValuesOfForm({
                        ...initialValuesOfForm,
                        title: e.target.value,
                      });
                      clearError("title");
                    }}
                    error={!!formErrors.title}
                    fullWidth
                  />
                  {<span className="error-text">{formErrors.title}</span>}
                  {/* <FormControl
                    variant="outlined"
                    style={{ width: "100%" }}
                    error={!!formErrors.category}
                  >
                    <InputLabel id="query-type-label">
                      Select Query Type
                    </InputLabel>
                    <Select
                      labelId="query-type-label"
                      id="query-type-select"
                      value={category}
                      onChange={(e) => {
                        handleSetOptions(e);
                        clearError("category");
                      }}
                      label="Select Query Type"
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: isSmallScreen ? 200 : 48 * 4.5 + 8,
                            width: isSmallScreen ? "100%" : 250,
                          },
                        },
                      }}
                    >
                      <MenuItem value="Sales & Support related (SS)">
                        Sales & Support related (SS)
                      </MenuItem>
                      <MenuItem value="Technical Issues (TH)">
                        Technical Issues (TH)
                      </MenuItem>
                      <MenuItem value="Finance Related (FN)">
                        Finance Related (FN)
                      </MenuItem>
                    </Select>
                    {formErrors.category && (
                      <span className="error-text">{formErrors.category}</span>
                    )}
                  </FormControl> */}
                  <div style={{ marginBottom: "21px" }}>
                    <SelectDropDown
                      name="category"
                      value={category}
                      label="Select Query Type"
                      options={SELECT_QUERY}
                      onChange={(e) => {
                        handleSetOptions(e);
                        clearError("category");
                      }}
                      widthStyle={"100%"}
                      styleIndex={{ isEnabled: false, value: "0" }}
                      labelName="Select Query Type"
                      color="#666"
                      height="3.4375em"
                    />
                  </div>
                  {formErrors.category && (
                    <span className="error-text">{formErrors.category}</span>
                  )}
                  {/* <FormControl
                    variant="outlined"
                    style={{ width: "100%" }}
                    error={!!formErrors.selectedOption}
                  >
                    <InputLabel id="query-sub-type-label">
                      Select Query Sub-Type
                    </InputLabel>
                    <Select
                      labelId="query-sub-type-label"
                      id="query-sub-type-select"
                      value={selectedOption}
                      onChange={(e) => {
                        handleChangeOptions(e);
                        clearError("selectedOption");
                      }}
                      label="Select Query Sub-Type"
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: isSmallScreen ? 200 : 48 * 4.5 + 8,
                            width: isSmallScreen ? "100%" : 250,
                          },
                        },
                      }}
                    >
                      <MenuItem value="">Select an option</MenuItem>
                      {Object.values(selectedOptions).map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                    {formErrors.selectedOption && (
                      <span className="error-text">
                        {formErrors.selectedOption}
                      </span>
                    )}
                  </FormControl> */}
                  <div style={{ marginBottom: "21px" }}>
                    <SelectDropDown
                      name="Select Query Sub-Type"
                      value={selectedOption}
                      label="Select Query Sub-Type"
                      options={selectedOptions}
                      onChange={(e) => {
                        handleChangeOptions(e);
                        clearError("selectedOption");
                      }}
                      widthStyle={"100%"}
                      styleIndex={{ isEnabled: false, value: "0" }}
                      labelName="Select Query Sub-Type"
                      color="#666"
                      height="3.4375em"
                    />
                  </div>
                  {formErrors.selectedOption && (
                    <span className="error-text">
                      {formErrors.selectedOption}
                    </span>
                  )}

                  {/* <FormControl
                    variant="outlined"
                    style={{ width: "100%" }}
                    error={!!formErrors.selectPriority}
                  >
                    <InputLabel id="query-sub-type-label">Priority</InputLabel>
                    <Select
                      labelId="query-sub-type-label"
                      id="query-sub-type-select"
                      value={selectPriority}
                      onChange={(e) => {
                        handleChangePriorityOptions(e);
                        clearError("selectPriority");
                      }}
                      label="Select Priority"
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: isSmallScreen ? 200 : 48 * 4.5 + 8,
                            width: isSmallScreen ? "100%" : 250,
                          },
                        },
                      }}
                    >
                      <MenuItem value="">Select an option</MenuItem>
                      {Object.values(Priority).map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                    {formErrors.selectPriority && (
                      <span className="error-text">
                        {formErrors.selectPriority}
                      </span>
                    )}
                  </FormControl> */}
                  <div style={{ marginBottom: "21px" }}>
                    <SelectDropDown
                      name="query-sub-type-label"
                      value={selectPriority}
                      label="query-sub-type-label"
                      options={Priority}
                      onChange={(e) => {
                        handleChangePriorityOptions(e);
                        clearError("selectPriority");
                      }}
                      widthStyle={"100%"}
                      styleIndex={{ isEnabled: false, value: "0" }}
                      labelName="Select Priority"
                      color="#666"
                      height="3.4375em"
                    />
                  </div>
                  {formErrors.selectPriority && (
                    <span className="error-text">
                      {formErrors.selectPriority}
                    </span>
                  )}
                  <TextField
                    name="detailQuery"
                    className="input-div"
                    maxRows={10}
                    minRows={4}
                    multiline
                    placeholder="Describe your query"
                    label="Describe your query"
                    variant="outlined"
                    value={initialValuesOfForm.detailQuery}
                    onChange={(e) => {
                      setInitialValuesOfForm({
                        ...initialValuesOfForm,
                        detailQuery: e.target.value,
                      });
                      clearError("detailQuery");
                    }}
                    error={!!formErrors.detailQuery}
                    fullWidth
                  />
                  {<span className="error-text">{formErrors.detailQuery}</span>}
                </div>
                <div className="submit-history-btn">
                  <Button
                    type="submit"
                    onClick={handleSubmitForm}
                    variant="contained"
                    style={{
                      backgroundColor: "#005d91",
                      color: "white",
                    }}
                  >
                    SUBMIT
                  </Button>
                </div>
              </div>
            )}

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginRight: showTicketForm ? "3rem" : "3.7rem",
                paddingBottom: "1%",
              }}
            >
              <Button
                variant="contained"
                style={{ backgroundColor: "#005d91", color: "white" }}
                onClick={() => handleNextStep()}
              >
                {showTicketForm ? "Previous Step" : "Next Step"}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="ticket-raise-container">
        <StickyTable
          columns={dataTableHeader}
          rows={tableData ?? []}
          showPagination={true}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          totalAvailableData={allTableData?.length}
          isFromTicket={true}
          handleResolutionInfo={handleResolutionInfo}
          onFilterStatusClick={onFilterStatusClick}
        />
      </div>
    </>
  );
};

export default TicketRaiseFormCustom;
