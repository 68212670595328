import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import ErrorPopup from "../../Component/ErrorLoader/ErrorPopup";
import ManageUser from "./ManageUser";
import { logger } from "../../Loggers/Loggers";

const ManageUserWithError = (props) => {
  function ErrorHandler({ error }) {
    logger(`Manage user ${error?.message}`);
    return (
      <div role="alert">
        {error && (
          <ErrorPopup
            errorMsg={"Something Went Wrong , Please Login Again"}
            open={error}
          />
        )}
      </div>
    );
  }
  return (
    <>
      <ErrorBoundary FallbackComponent={ErrorHandler}>
        <ManageUser
          CLIENT_ACCOUNT_ID={props.CLIENT_ACCOUNT_ID}
          CLIENT_USER_ID={props.CLIENT_USER_ID}
        />
      </ErrorBoundary>
    </>
  );
};

export default ManageUserWithError;
