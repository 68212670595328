import React, { useEffect, useState } from "react";
import "./styledTreeItem.scss";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { FILTER_OPTIONS } from "../../../../Containers/ExploreCountryShipmentNewUI/Explore.enum";

export default function StyledTreeItem({
  currentCountry,
  hsCodeOrMonthUI,
  countryOrPortUI,
  qauntityOrPriceUI,
  filterOptionClick,
  setCountryOrPort,
  isFromExplore,
  collapseAllFilters,
  allFilterValuesWithCountsArr,
  height,
  isFilterLoader,
  isMarketCompany,
  isMarketProduct,
  valueOrShipmentUI,
  isGlobalSearch,
  removeOrderAndNull,
  currentTradeType,
  isFromWorkspace,
  selectMode,
  mirrorData,
  ImporterAndExporterUI,
  importerExporterName,
}) {
  const [isViewedFilter, setIsViewedFilter] = useState({
    HS_CODE: false,
    FILTER_PIN: false,
    FILTER_COUNTRY: false,
    DATATYPE: false,
    FILTER_PORT: false,
    QUANTITY: false,
    PRICE: false,
    MONTH: false,
    FILTER_FOREIGN_PORT: false,
    FILTER_DESTINATION_COUNTRY: false,
    FILTER_DESTINATION_PORT: false,
    FILTER_ORIGIN_COUNTRY: false,
    FILTER_INDIAN_PORT: false,
    FILTER_ORIGIN_PORT: false,
    FILTER_REPORTING_COUNTRY: false,
    VALUE: false,
    SHIPMENT: false,
    FILTER_STATE: false,
    REMOVE_ORDER_OR_NULL: false,
    SEARCH_MODE: false,
    FILTER_IMPORTER: false,
    FILTER_EXPORTER: false,
  });
  useEffect(() => {
    setIsViewedFilter({
      HS_CODE: false,
      FILTER_PIN: false,
      FILTER_COUNTRY: false,
      DATATYPE: false,
      FILTER_PORT: false,
      QUANTITY: false,
      PRICE: false,
      MONTH: false,
      FILTER_FOREIGN_PORT: false,
      FILTER_DESTINATION_COUNTRY: false,
      FILTER_DESTINATION_PORT: false,
      FILTER_ORIGIN_COUNTRY: false,
      FILTER_INDIAN_PORT: false,
      FILTER_ORIGIN_PORT: false,
      FILTER_REPORTING_COUNTRY: false,
      VALUE: false,
      SHIPMENT: false,
      FILTER_STATE: false,
      REMOVE_ORDER_OR_NULL: false,
      SEARCH_MODE: false,
      FILTER_IMPORTER: false,
      FILTER_EXPORTER: false,
    });
  }, [collapseAllFilters]);

  const clickedFilter = (e, name) => {
    setCountryOrPort("");
    let _isViewedFilter = { ...isViewedFilter };
    Object.entries(_isViewedFilter).forEach((e) => {
      if (e[0] === name) {
        _isViewedFilter[e[0]] = !e[1];
      } else {
        _isViewedFilter[e[0]] = false;
      }
    });
    setIsViewedFilter(_isViewedFilter);
    filterOptionClick(name);
  };

  return (
    <>
      <div
        id="collapse-tree"
        style={{ height: isFromExplore ? height : "560px" }}
      >
        <div
          className="collapse-tree"
          style={!isFromExplore ? { paddingLeft: "0px" } : {}}
        >
          {!isFilterLoader ? (
            <>
              {allFilterValuesWithCountsArr?.FILTER_HS_CODE?.length > 0 && (
                <div className="collapse__item">
                  <label
                    htmlFor="collapse-1"
                    className="collapse-header"
                    onClick={(e) => clickedFilter(e, FILTER_OPTIONS?.HS_CODE)}
                  >
                    <h4 className="collapse__h6  ellipsisName">HS CODE</h4>
                    {isViewedFilter.HS_CODE ? (
                      <ExpandLessIcon style={{ pointerEvents: "none" }} />
                    ) : (
                      <ExpandMoreIcon style={{ pointerEvents: "none" }} />
                    )}
                  </label>
                  <div style={{ padding: "10px 10px 10px 20px" }}>
                    {isViewedFilter.HS_CODE && hsCodeOrMonthUI()}
                  </div>
                </div>
              )}
              {allFilterValuesWithCountsArr?.FILTER_COUNTRY?.length > 0 && (
                <div className="collapse__item">
                  <label
                    htmlFor="collapse-1"
                    className="collapse-header"
                    onClick={(e) =>
                      clickedFilter(e, FILTER_OPTIONS?.FILTER_COUNTRY)
                    }
                  >
                    <h4 className="collapse__h6  ellipsisName">COUNTRY</h4>
                    {isViewedFilter.FILTER_COUNTRY ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </label>
                  <div style={{ padding: "10px 10px 10px 20px" }}>
                    {" "}
                    {isViewedFilter.FILTER_COUNTRY && countryOrPortUI()}
                  </div>
                </div>
              )}
              {allFilterValuesWithCountsArr?.FILTER_IMPORTER?.length > 0 && (
                <div className="collapse__item">
                  <label
                    htmlFor="collapse-1"
                    className="collapse-header"
                    onClick={(e) =>
                      clickedFilter(e, FILTER_OPTIONS?.FILTER_IMPORTER)
                    }
                  >
                    <h4 className="collapse__h6  ellipsisName">
                      {importerExporterName?.importer}
                    </h4>
                    {isViewedFilter.FILTER_IMPORTER ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </label>
                  <div style={{ padding: "10px 10px 10px 20px" }}>
                    {" "}
                    {isViewedFilter.FILTER_IMPORTER && ImporterAndExporterUI()}
                  </div>
                </div>
              )}
              {allFilterValuesWithCountsArr?.FILTER_EXPORTER?.length > 0 && (
                <div className="collapse__item">
                  <label
                    htmlFor="collapse-1"
                    className="collapse-header"
                    onClick={(e) =>
                      clickedFilter(e, FILTER_OPTIONS?.FILTER_EXPORTER)
                    }
                  >
                    <h4 className="collapse__h6  ellipsisName">
                      {importerExporterName?.exporter}
                    </h4>
                    {isViewedFilter.FILTER_EXPORTER ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </label>
                  <div style={{ padding: "10px 10px 10px 20px" }}>
                    {" "}
                    {isViewedFilter.FILTER_EXPORTER && ImporterAndExporterUI()}
                  </div>
                </div>
              )}
              {allFilterValuesWithCountsArr?.FILTER_STATE?.length > 0 && (
                <div className="collapse__item">
                  <label
                    htmlFor="collapse-1"
                    className="collapse-header"
                    onClick={(e) =>
                      clickedFilter(e, FILTER_OPTIONS?.FILTER_STATE)
                    }
                  >
                    <h4 className="collapse__h6  ellipsisName">STATE</h4>
                    {isViewedFilter.FILTER_STATE ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </label>
                  <div style={{ padding: "10px 10px 10px 20px" }}>
                    {isViewedFilter.FILTER_STATE && countryOrPortUI()}
                  </div>
                </div>
              )}
              {allFilterValuesWithCountsArr?.FILTER_DESTINATION_COUNTRY
                ?.length > 0 && (
                <div className="collapse__item">
                  <label
                    htmlFor="collapse-1"
                    className="collapse-header"
                    onClick={(e) =>
                      clickedFilter(
                        e,
                        FILTER_OPTIONS?.FILTER_DESTINATION_COUNTRY
                      )
                    }
                  >
                    <h4 className="collapse__h6  ellipsisName">
                      DESTINATION COUNTRY
                    </h4>
                    {isViewedFilter.FILTER_DESTINATION_COUNTRY ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </label>
                  <div style={{ padding: "10px 10px 10px 20px" }}>
                    {" "}
                    {isViewedFilter.FILTER_DESTINATION_COUNTRY &&
                      countryOrPortUI()}
                  </div>
                </div>
              )}
              {allFilterValuesWithCountsArr?.FILTER_DESTINATION_PORT?.length >
                0 && (
                <div className="collapse__item">
                  <label
                    htmlFor="collapse-1"
                    className="collapse-header"
                    onClick={(e) =>
                      clickedFilter(e, FILTER_OPTIONS?.FILTER_DESTINATION_PORT)
                    }
                  >
                    <h4 className="collapse__h6  ellipsisName">
                      DESTINATION PORT
                    </h4>
                    {isViewedFilter.FILTER_DESTINATION_PORT ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </label>
                  <div style={{ padding: "10px 10px 10px 20px" }}>
                    {" "}
                    {isViewedFilter.FILTER_DESTINATION_PORT &&
                      countryOrPortUI()}
                  </div>
                </div>
              )}
              {allFilterValuesWithCountsArr?.FILTER_ORIGIN_COUNTRY?.length >
                0 && (
                <div className="collapse__item">
                  <label
                    htmlFor="collapse-1"
                    className="collapse-header"
                    onClick={(e) =>
                      clickedFilter(e, FILTER_OPTIONS?.FILTER_ORIGIN_COUNTRY)
                    }
                  >
                    <h4 className="collapse__h6  ellipsisName">
                      ORIGIN COUNTRY
                    </h4>
                    {isViewedFilter.FILTER_ORIGIN_COUNTRY ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </label>
                  <div style={{ padding: "10px 10px 10px 20px" }}>
                    {" "}
                    {isViewedFilter.FILTER_ORIGIN_COUNTRY && countryOrPortUI()}
                  </div>
                </div>
              )}
              {allFilterValuesWithCountsArr?.FILTER_ORIGIN_PORT?.length > 0 && (
                <div className="collapse__item">
                  <label
                    htmlFor="collapse-1"
                    className="collapse-header"
                    onClick={(e) =>
                      clickedFilter(e, FILTER_OPTIONS?.FILTER_ORIGIN_PORT)
                    }
                  >
                    <h4 className="collapse__h6  ellipsisName">ORIGIN PORT</h4>
                    {isViewedFilter.FILTER_ORIGIN_PORT ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </label>
                  <div style={{ padding: "10px 10px 10px 20px" }}>
                    {" "}
                    {isViewedFilter.FILTER_ORIGIN_PORT && countryOrPortUI()}
                  </div>
                </div>
              )}
              {allFilterValuesWithCountsArr?.FILTER_REPORTING_COUNTRY?.length >
                0 && (
                <div className="collapse__item">
                  <label
                    htmlFor="collapse-1"
                    className="collapse-header"
                    onClick={(e) =>
                      clickedFilter(e, FILTER_OPTIONS?.FILTER_REPORTING_COUNTRY)
                    }
                  >
                    <h4 className="collapse__h6  ellipsisName">
                      REPORTING COUNTRY
                    </h4>
                    {isViewedFilter.FILTER_REPORTING_COUNTRY ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </label>
                  <div style={{ padding: "10px 10px 10px 20px" }}>
                    {" "}
                    {isViewedFilter.FILTER_REPORTING_COUNTRY &&
                      countryOrPortUI()}
                  </div>
                </div>
              )}
              {allFilterValuesWithCountsArr?.FILTER_PORT?.length > 0 && (
                <div className="collapse__item">
                  <label
                    htmlFor="collapse-1"
                    className="collapse-header"
                    onClick={(e) =>
                      clickedFilter(e, FILTER_OPTIONS?.FILTER_PORT)
                    }
                  >
                    <h4 className="collapse__h6  ellipsisName">PORTS</h4>
                    {isViewedFilter.FILTER_PORT ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </label>
                  <div style={{ padding: "10px 10px 10px 20px" }}>
                    {" "}
                    {isViewedFilter.FILTER_PORT && countryOrPortUI()}
                  </div>
                </div>
              )}
              {currentCountry === "INDIA" &&
                allFilterValuesWithCountsArr?.FILTER_FOREIGN_PORT?.length >
                  0 && (
                  <div className="collapse__item">
                    <label
                      htmlFor="collapse-1"
                      className="collapse-header"
                      onClick={(e) =>
                        clickedFilter(e, FILTER_OPTIONS?.FILTER_FOREIGN_PORT)
                      }
                    >
                      <h4 className="collapse__h6  ellipsisName">
                        FOREIGN PORT
                      </h4>
                      {isViewedFilter.FILTER_FOREIGN_PORT ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </label>
                    <div style={{ padding: "10px 10px 10px 20px" }}>
                      {" "}
                      {isViewedFilter.FILTER_FOREIGN_PORT && countryOrPortUI()}
                    </div>
                  </div>
                )}
              {currentCountry === "INDIA" &&
                allFilterValuesWithCountsArr?.FILTER_INDIAN_PORT?.length >
                  0 && (
                  <div className="collapse__item">
                    <label
                      htmlFor="collapse-1"
                      className="collapse-header"
                      onClick={(e) =>
                        clickedFilter(e, FILTER_OPTIONS?.FILTER_INDIAN_PORT)
                      }
                    >
                      <h4 className="collapse__h6  ellipsisName">
                        INDIAN PORT
                      </h4>
                      {isViewedFilter.FILTER_INDIAN_PORT ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </label>
                    <div style={{ padding: "10px 10px 10px 20px" }}>
                      {" "}
                      {isViewedFilter.FILTER_INDIAN_PORT && countryOrPortUI()}
                    </div>
                  </div>
                )}
              {allFilterValuesWithCountsArr?.FILTER_MONTH?.length > 0 && (
                <div className="collapse__item">
                  <label
                    htmlFor="collapse-1"
                    className="collapse-header"
                    onClick={(e) => clickedFilter(e, FILTER_OPTIONS?.MONTH)}
                  >
                    <h4 className="collapse__h6  ellipsisName">MONTH</h4>
                    {isViewedFilter.MONTH ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </label>
                  <div style={{ padding: "10px 10px 10px 20px" }}>
                    {" "}
                    {isViewedFilter.MONTH && hsCodeOrMonthUI()}
                  </div>
                </div>
              )}
              {allFilterValuesWithCountsArr?.FILTER_UNIT_QUANTITY?.length >
                0 && (
                <div className="collapse__item">
                  <label
                    htmlFor="collapse-1"
                    className="collapse-header"
                    onClick={(e) => clickedFilter(e, "QUANTITY")}
                  >
                    <h4 className="collapse__h6  ellipsisName">QUANTITY</h4>
                    {isViewedFilter.QUANTITY ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </label>
                  <div style={{ padding: "10px 10px 10px 30px" }}>
                    {" "}
                    {isViewedFilter.QUANTITY && qauntityOrPriceUI()}
                  </div>
                </div>
              )}
              {(allFilterValuesWithCountsArr?.FILTER_DUTY?.length > 0 ||
                allFilterValuesWithCountsArr?.FILTER_CURRENCY_PRICE_USD
                  ?.length > 0 ||
                allFilterValuesWithCountsArr?.FILTER_CURRENCY_PRICE_INR
                  ?.length > 0) && (
                <div className="collapse__item">
                  <label
                    htmlFor="collapse-1"
                    className="collapse-header"
                    onClick={(e) => clickedFilter(e, "PRICE")}
                  >
                    <h4 className="collapse__h6  ellipsisName">PRICE</h4>
                    {isViewedFilter.PRICE ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </label>
                  <div style={{ padding: "10px 10px 10px 30px" }}>
                    {" "}
                    {isViewedFilter.PRICE && qauntityOrPriceUI()}
                  </div>
                </div>
              )}
              {(isMarketCompany || isMarketProduct) && (
                <div className="collapse__item">
                  <label
                    htmlFor="collapse-1"
                    className="collapse-header"
                    onClick={(e) => clickedFilter(e, "VALUE")}
                  >
                    <h4 className="collapse__h6  ellipsisName">VALUE</h4>
                    {isViewedFilter.VALUE ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </label>
                  <div style={{ padding: "10px 10px 10px 20px" }}>
                    {" "}
                    {isViewedFilter.VALUE && valueOrShipmentUI()}
                  </div>
                </div>
              )}
              {(isMarketCompany || isMarketProduct) && (
                <div className="collapse__item">
                  <label
                    htmlFor="collapse-1"
                    className="collapse-header"
                    onClick={(e) => clickedFilter(e, "SHIPMENT")}
                  >
                    <h4 className="collapse__h6  ellipsisName">SHIPMENT</h4>
                    {isViewedFilter.SHIPMENT ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </label>
                  <div style={{ padding: "10px 10px 10px 20px" }}>
                    {" "}
                    {isViewedFilter.SHIPMENT && valueOrShipmentUI()}
                  </div>
                </div>
              )}
              {allFilterValuesWithCountsArr?.FILTER_PIN?.length > 0 && (
                <div className="collapse__item">
                  <label
                    htmlFor="collapse-1"
                    FILTER_PIN
                    className="collapse-header"
                    onClick={(e) =>
                      clickedFilter(e, FILTER_OPTIONS?.FILTER_PIN)
                    }
                  >
                    <h4 className="collapse__h6  ellipsisName">PIN</h4>
                    {isViewedFilter.FILTER_PIN ? (
                      <ExpandLessIcon style={{ pointerEvents: "none" }} />
                    ) : (
                      <ExpandMoreIcon style={{ pointerEvents: "none" }} />
                    )}
                  </label>
                  <div style={{ padding: "10px 10px 10px 20px" }}>
                    {isViewedFilter.FILTER_PIN && countryOrPortUI()}
                  </div>
                </div>
              )}
              {isGlobalSearch &&
                allFilterValuesWithCountsArr?.DATATYPE?.length > 0 && (
                  <div className="collapse__item">
                    <label
                      htmlFor="collapse-1"
                      className="collapse-header"
                      onClick={(e) => clickedFilter(e, "DATATYPE")}
                    >
                      <h4 className="collapse__h6  ellipsisName">DATATYPE</h4>
                      {isViewedFilter.DATATYPE ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </label>
                    <div style={{ padding: "10px 10px 10px 20px" }}>
                      {isViewedFilter.DATATYPE && hsCodeOrMonthUI()}
                    </div>
                  </div>
                )}
              {(isFromExplore || isFromWorkspace) &&
                currentTradeType === "EXPORT" &&
                currentCountry === "INDIA" && (
                  <div className="collapse__item">
                    <label
                      htmlFor="collapse-1"
                      className="collapse-header"
                      onClick={(e) => clickedFilter(e, "REMOVE_ORDER_OR_NULL")}
                    >
                      <h4 className="collapse__h6  ellipsisName">
                        REMOVE ORDER OR NULL
                      </h4>
                      {isViewedFilter?.REMOVE_ORDER_OR_NULL ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </label>
                    <div style={{ padding: "10px 10px 10px 20px" }}>
                      {isViewedFilter?.REMOVE_ORDER_OR_NULL &&
                        removeOrderAndNull()}
                    </div>
                  </div>
                )}
              {mirrorData && (
                <div className="collapse__item">
                  <label
                    htmlFor="collapse-1"
                    className="collapse-header"
                    onClick={(e) => clickedFilter(e, "SEARCH_MODE")}
                  >
                    <h4 className="collapse__h6  ellipsisName">SELECT MODE</h4>
                    {isViewedFilter?.SEARCH_MODE ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </label>
                  <div style={{ padding: "10px 10px 10px 20px" }}>
                    {isViewedFilter?.SEARCH_MODE && selectMode()}
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className="spinner-filter">
              <div className="bounce1"></div>
              <div className="bounce2"></div>
              <div className="bounce3"></div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
