import React from "react";
import { Button } from "@mui/material";
import "./CountryRegion.scss";

const RegionList = ({
  countryRegion,
  setCurrentRegion,
  currentRegion,
  allCountryData,
  filterCountrySearchValueAndRegionWise,
}) => {
  const handleRegion = (e) => {
    const value = e.target.value;
    let _currentRegion = [];
    if (
      currentRegion &&
      currentRegion?.length > 0 &&
      currentRegion?.includes(value)
    ) {
      _currentRegion = currentRegion.filter((region) => region !== value);
    } else {
      _currentRegion = [...currentRegion];
      _currentRegion.push(value);
    }
    setCurrentRegion(_currentRegion);
    filterCountrySearchValueAndRegionWise(allCountryData, _currentRegion);
  };

  return (
    <div>
      {countryRegion &&
        countryRegion.length > 0 &&
        countryRegion
          .filter((name) => name.trim() !== "" && name !== "EXIM")
          .map((name, index) => {
            return (
              <Button
                className={`region_button ${
                  currentRegion && currentRegion?.includes(name)
                    ? "active_region"
                    : "deactivate_region"
                }`}
                key={name + index}
                value={name}
                onClick={handleRegion}
              >
                {name}
              </Button>
            );
          })}
    </div>
  );
};

export default React.memo(RegionList);
