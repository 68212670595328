// import React from "react";
// import Login from "./login";
// import { ErrorBoundary } from "react-error-boundary";
// import ErrorPopup from "../../Component/ErrorLoader/ErrorPopup";

// const LoginWithError = () => {
//   // const [open, setOpen] = useState(true);
//   function ErrorHandler({ error }) {
//     return (
//       <div role="alert">
//         {error && <ErrorPopup errorMsg={error.message} open={error} />}
//         {/* <p>An error occurred:</p>
//         <pre>{error.message}</pre> */}
//       </div>
//     );
//   }
//   return (
//     <>
//       <ErrorBoundary FallbackComponent={ErrorHandler}>
//         <Login />
//       </ErrorBoundary>
//     </>
//   );
// };

// export default LoginWithError;

import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import ErrorPopup from "../../Component/ErrorLoader/ErrorPopup";
import Login from "./login";

const LoginWithError = (props) => {
  function ErrorHandler({ error }) {
    return (
      <div role="alert">
        {error && (
          <ErrorPopup
            errorMsg={"Something Went Wrong , Please Login Again"}
            open={error}
          />
        )}
      </div>
    );
  }
  return (
    <>
      <ErrorBoundary FallbackComponent={ErrorHandler}>
        <Login setDefaultValue={props?.setDefaultValue} />
      </ErrorBoundary>
    </>
  );
};

export default LoginWithError;
