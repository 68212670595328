import React from "react";
import "./SelectedCountryHoverCard.scss";

const SelectedCountryHoverCard = ({
  tooltipData,
  handleMouseLeaveOnSelectedCountry,
  handleMouseEnterOnSelectedCountry,
  showClass,
}) => {
  return (
    <>
      <div
        className={`tooltip-for-mirrorData-selected-country-modal-container tooltip-position-absolute ${
          showClass ? "show" : ""
        }`}
        onMouseLeave={() => handleMouseLeaveOnSelectedCountry()}
        onMouseEnter={() => handleMouseEnterOnSelectedCountry()}
      >
        {tooltipData && tooltipData.length > 0 ? (
          <div className="tooltip-container">
            {tooltipData?.map((item) => {
              let flagUrl = null;
              try {
                flagUrl = require(`../../../../Asset/flags/${item?.flag_uri}`);
              } catch {}
              return (
                <>
                  <span className="selected-country-span">
                    {flagUrl && (
                      <img
                        src={flagUrl}
                        alt="flag"
                        className="selected-country-flag"
                      />
                    )}
                    <p
                      className="country-name"
                      htmlFor={`checkbox-${item.country}`}
                    >
                      {item.country.split("_").join(" ").toUpperCase()}
                    </p>
                  </span>
                </>
              );
            })}
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "7px",
            }}
          >
            ALL COUNTRIES ARE SELECTED
          </div>
        )}
      </div>
    </>
  );
};

export default SelectedCountryHoverCard;
