import React from "react";
import "./DataUpdateTable.scss";

const dataForUpdateCountry = [
  { countryName: "india", trade: "import", date: "24-03-31" },
  { countryName: "Africa", trade: "export", date: "24-03-31" },
  { countryName: "America", trade: "import", date: "24-03-31" },
  { countryName: "USA", trade: "import", date: "24-03-31" },
  { countryName: "china", trade: "export", date: "24-03-31" },
  { countryName: "japan", trade: "import", date: "24-04-20" },
  { countryName: "korea", trade: "export", date: "24-03-31" },
  { countryName: "Africa", trade: "export", date: "24-03-31" },
  { countryName: "china", trade: "import", date: "24-03-31" },
  { countryName: "south africa", trade: "import", date: "24-03-31" },
  { countryName: "india", trade: "export", date: "24-03-31" },
  { countryName: "china", trade: "import", date: "24-03-31" },
  { countryName: "hong kong", trade: "export", date: "24-03-31" },
  { countryName: "china", trade: "import", date: "24-03-31" },
  { countryName: "hong kong", trade: "export", date: "24-03-31" },
  { countryName: "America", trade: "export", date: "24-10-14" },
  { countryName: "korea", trade: "import", date: "24-03-31" },
  { countryName: "vietnam", trade: "export", date: "24-01-31" },
  { countryName: "japan", trade: "import", date: "24-03-31" },
  { countryName: "hong kong", trade: "export", date: "24-03-31" },
  { countryName: "korea", trade: "export", date: "24-03-31" },
  { countryName: "india", trade: "import", date: "24-03-31" },
  { countryName: "argentina", trade: "export", date: "24-03-31" },
];
let flagUrl = require(`../../../../Asset/flags/flag_ind.png`);

const DataUpdate = () => {
  return (
    <div className="data-update-container">
      <div className="header">
        <h2 className="title-for-update-country">DATA UPDATE</h2>
        {/* <div className="buttons">
          <button>Import</button>
          <button>Export</button>
        </div> */}
      </div>
      <hr style={{ opacity: 0.4 }} />
      <div className="update-date">2024-06-12</div>
      <div className="data-list">
        {dataForUpdateCountry?.map((item, index) => (
          <div key={index} className="data-row">
            <span className="country-flag-for-update-date">
              <img src={flagUrl} style={{ width: "100%" }} />
            </span>
            <span className="country">{item.countryName.toUpperCase()}</span>
            <span className="type">{item.trade.toUpperCase()}</span>
            <span className="update">
              <span style={{ color: "gray" }}>Update at :</span> {item.date}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DataUpdate;
