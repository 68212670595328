export const SubscriptionsTableHeader = [
  { key: "id_subscription", title: "" },
  { key: "type", title: "Type" },
  { key: "price", title: "Price" },
  { key: "validity", title: "Validity" },
  { key: "data_range", title: "Data Range" },
  { key: "points", title: "Points" },
  { key: "max_users", title: "Max Users " },
  { key: "status", title: "Status" },
];
