import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import log from "loglevel";
import remote from "loglevel-plugin-remote";
import { AppProvider } from "./Contexts/AppContext";

// const customJSON = (log) => ({
//   msg: log.message,
//   level: log.level.label,
//   stacktrace: log.stacktrace,
// });
// remote.apply(log, { format: customJSON, url: "http://localhost:4300/logger" });

// log.enableAll();

// log.info("Message one");
// log.warn("Message two");
ReactDOM.render(
  <React.StrictMode>
    <AppProvider>
      <App />
    </AppProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
