import React, { useCallback, useEffect, useState, useRef } from "react";
import "./slider.scss";
import millify from "millify";

const Slider = ({ min, max, handleChangeRange, rangeValue }) => {
  const [minVal, setMinVal] = useState(min);
  const [maxVal, setMaxVal] = useState(max);
  const minValRef = useRef(min);
  const maxValRef = useRef(max);
  const range = useRef(null);
  // Convert to percentage
  const getPercent = useCallback(
    (value) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  );
  // Set width of the range to decrease from the left side
  useEffect(() => {
    const minPercent = getPercent(minVal);
    const maxPercent = getPercent(maxValRef.current);
    if (range.current) {
      range.current.style.left = `${minPercent}%`;
      // range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [minVal, getPercent]);
  // Set width of the range to decrease from the right side
  useEffect(() => {
    const minPercent = getPercent(minValRef.current);
    const maxPercent = getPercent(maxVal);
    // if (range.current) {
    //   range.current.style.width = `${maxPercent - minPercent}%`;
    // }
  }, [maxVal, getPercent]);
  // Get min and max values when their state changes
  //   useEffect(() => {
  //     onChange({ min: minVal, max: maxVal });
  //   }, [minVal, maxVal, onChange]);
  return (
    <div id="slider">
      <input
        type="range"
        title={minVal}
        min={min}
        max={max}
        value={rangeValue && rangeValue[0]}
        onChange={(event) => {
          const value = Math.min(Number(event.target.value), maxVal - 1);
          setMinVal(value);
          minValRef.current = value;
          handleChangeRange({ min: value, max: maxVal, minValue: true });
        }}
        className="thumb thumb--left"
        style={{ zIndex: minVal > max - 100 && "5" }}
      />
      <input
        type="range"
        title={maxVal}
        min={min}
        max={max}
        value={rangeValue && rangeValue[1]}
        onChange={(event) => {
          const value = Math.max(Number(event.target.value), minVal + 1);
          setMaxVal(value);
          maxValRef.current = value;
          handleChangeRange({ min: minVal, max: value, maxValue: true });
        }}
        className="thumb thumb--right"
      />
      <div className="slider notranslate">
        <div className="slider__track" />
        <div ref={range} className="slider__range" />
        <div className="slider__left-value">
          {rangeValue && millify(rangeValue[0] ?? 0)}
        </div>
        <div className="slider__right-value">
          {rangeValue && millify(rangeValue[1])}
        </div>
      </div>
    </div>
  );
};

export default Slider;
