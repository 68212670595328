import React from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import BusinessIcon from "@mui/icons-material/Business";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";

import { Grid, Container } from "@mui/material";
import moment from "moment";
import { ButtonComponentWithToolTip } from "../../../Component/ButtonComponent/Button";

export default function AccountDetails(props) {
  const sanitizeDate = (value) => {
    return new Date(value).toDateString();
  };

  return (
    <Container maxWidth="xxl">
      <div id="account-comp">
        <div style={{ display: "flex" }}>
          <div className="row-dashbored" style={{ padding: "20px 8px" }}>
            <h4 className="text">
              Manage
              <span className="badge bg-soft-blue text-blue label-worksapce-name">
                Account
              </span>
            </h4>
          </div>
        </div>
        <Grid container>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <div
              className="account-card"
              style={{ margin: "10px 10px 10px 0px" }}
            >
              <div
                className="account-card-header"
                style={{
                  padding:
                    props?.userDetails[0]?.role === "ADMINISTRATOR"
                      ? "0.5rem"
                      : "1rem",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <AccountCircleIcon className="set-card-icons" />
                  USER PROFILE
                </div>
                {props?.userDetails[0]?.role === "ADMINISTRATOR" && (
                  <div>
                    <ButtonComponentWithToolTip
                      name={""}
                      btnClick={props?.handleEditUserDetails}
                      btnIcon={EditNoteOutlinedIcon}
                      style={{ backgroundColor: "white", minWidth: "40px" }}
                      iconStyle={{ width: "30px", height: "30px" }}
                      tooltipTitle={"EDIT USER PROFILE"}
                    />
                  </div>
                )}
              </div>
              <div className="account-card-body">
                {props.userDetails.map((user, index) => {
                  return (
                    <>
                      <div className="account-card-title" key={index}>
                        {user.first_name} {user.last_name}
                      </div>
                      <span className="text-muted">@{user.role}</span>

                      <div className="account-card-body-content">
                        <div className="mb-2">
                          <span className="account-card-body-content-key">
                            {" "}
                            Email :
                          </span>{" "}
                          <span className="account-card-body-content-value">
                            {user.email_id}
                          </span>
                          <span
                            style={{
                              color: "#005d91",
                              fontSize: "11px",
                              fontWeight: 900,
                              marginRight: "3px",
                            }}
                          >
                            {user.is_email_verified === 1 ? (
                              <span className="verify-email"> Verified</span>
                            ) : (
                              <span className="pending-email"> Pending</span>
                            )}
                          </span>
                        </div>
                        <div className="mb-2">
                          <span className="account-card-body-content-key">
                            Mobile :
                          </span>{" "}
                          <span className="account-card-body-content-value">
                            {user.mobile_no}
                          </span>
                        </div>
                        <div className="mb-2">
                          <span className="account-card-body-content-key">
                            Joined :
                          </span>{" "}
                          <span className="account-card-body-content-value">
                            {moment(user.created_ts).format("DD-MM-YYYY")}
                          </span>
                        </div>
                        <div>
                          <span className="account-card-body-content-key ">
                            Status :{" "}
                          </span>
                          {user.is_active ? (
                            <span className="account-card-body-content-value bg-soft-green set-interval">
                              Activated
                            </span>
                          ) : (
                            <span className="account-card-body-content-value bg-soft-red set-interval">
                              Deactivated
                            </span>
                          )}
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <div className="account-card">
              <div
                className="account-card-header"
                style={{
                  padding:
                    props?.userDetails[0]?.role === "ADMINISTRATOR"
                      ? "0.5rem"
                      : "1rem",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <BusinessIcon className="set-card-icons" />
                  COMPANY PROFILE
                </div>
                {props?.userDetails[0]?.role === "ADMINISTRATOR" && (
                  <div>
                    <ButtonComponentWithToolTip
                      name={""}
                      btnClick={props.handleEditCompanyDetails}
                      btnIcon={EditNoteOutlinedIcon}
                      style={{ backgroundColor: "white", minWidth: "40px" }}
                      iconStyle={{ width: "30px", height: "30px" }}
                      tooltipTitle={"EDIT COMPANY PROFILE"}
                    />
                  </div>
                )}
              </div>

              <div className="account-card-body">
                {props.accountDetails.map((accountDetails, index) => {
                  return (
                    <>
                      <div className="account-card-title" key={index}>
                        {accountDetails.company.name}
                      </div>
                      <span className="text-muted">
                        @{accountDetails.company.country}
                      </span>

                      <div className="account-card-body-content">
                        <div className="mb-2">
                          <span className="account-card-body-content-key">
                            Address :
                          </span>
                          <span className="account-card-body-content-value">
                            {accountDetails.company.address}
                          </span>
                        </div>
                        <div className="mb-2">
                          <span className="account-card-body-content-key">
                            Pincode :
                          </span>
                          <span className="account-card-body-content-value">
                            {accountDetails.company.pin_code}
                          </span>
                        </div>
                        <div className="mb-2">
                          <span className="account-card-body-content-key">
                            Email :
                          </span>
                          <span className="account-card-body-content-value">
                            {accountDetails.company.email_id}
                          </span>
                        </div>
                        <div className="mb-2">
                          <span className="account-card-body-content-key">
                            Website :
                          </span>
                          <span className="account-card-body-content-value">
                            {accountDetails.company.website_url}
                          </span>
                        </div>
                        <div className="mb-2">
                          <span className="account-card-body-content-key">
                            Phone No :
                          </span>
                          <span className="account-card-body-content-value">
                            {accountDetails.company.phone_no}
                          </span>
                        </div>
                        <div>
                          <span className="account-card-body-content-key">
                            Tax Identification No :
                          </span>
                          <span className="account-card-body-content-value">
                            {accountDetails.company.tax_identification_no}
                          </span>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <div
              className="account-card"
              style={{ margin: "10px 0px 10px 10px" }}
            >
              <div
                className="account-card-header"
                style={{
                  padding:
                    props?.userDetails[0]?.role === "ADMINISTRATOR"
                      ? "1rem 0.5rem"
                      : "1rem",
                  justifyContent: "flex-start",
                }}
              >
                <CardGiftcardIcon className="set-card-icons" />
                CURRENT PLAN BENEFITS
              </div>
              <div className="account-card-body">
                {props.accountDetails.map((accountDetails, index) => {
                  return (
                    <>
                      <div className="account-card-title" key={index}>
                        {accountDetails.plan_constraints.subscriptionType}
                      </div>
                      <span className="text-muted">
                        @
                        {
                          accountDetails.plan_constraints
                            .data_availability_interval.start_date
                        }
                      </span>

                      <div className="account-card-body-content">
                        <div className="mb-2">
                          <span className="account-card-body-content-key">
                            Max User :
                          </span>
                          <span className="account-card-body-content-value">
                            {accountDetails.plan_constraints.max_users}
                          </span>
                        </div>
                        <div className="mb-2">
                          <span className="account-card-body-content-key">
                            Purchase Points :
                          </span>
                          <span className="account-card-body-content-value">
                            {accountDetails.plan_constraints.purchase_points}
                          </span>
                        </div>
                        <span className="account-card-body-content-key">
                          Countries Available :
                        </span>
                        <div className="mb-2 p-10">
                          <div style={{ textAlign: "justify" }}>
                            {/* {accountDetails?.plan_constraints?.countries_available?.map((country) => {
                              return <span className="account-card-body-content-value available-countries">{country} |</span>
                            })} */}
                            {props.userAvailableCountry &&
                              !!props.userAvailableCountry.length &&
                              props.userAvailableCountry.map(
                                (country, index) => {
                                  return (
                                    <span
                                      key={index}
                                      className="available-countries"
                                    >{`  ${country}  |`}</span>
                                  );
                                }
                              )}
                          </div>
                        </div>
                        <div className="mb-2">
                          <span className="account-card-body-content-key">
                            Data Range :
                          </span>
                          <span className="account-card-body-content-value bg-soft-green set-interval">
                            {sanitizeDate(
                              accountDetails.plan_constraints
                                .data_availability_interval.start_date
                            ) +
                              "-" +
                              sanitizeDate(
                                accountDetails.plan_constraints
                                  .data_availability_interval.end_date
                              )}
                          </span>
                        </div>
                        <div className="mb-2">
                          <span className="account-card-body-content-key">
                            Access Validity :
                          </span>
                          <span className="account-card-body-content-value bg-soft-red set-interval">
                            {sanitizeDate(
                              accountDetails.plan_constraints
                                .access_validity_interval.start_date
                            ) +
                              "-" +
                              sanitizeDate(
                                accountDetails.plan_constraints
                                  .access_validity_interval.end_date
                              )}
                          </span>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
