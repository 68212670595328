import React from "react";
import "./authFooter.scss";
import { getCurrentYear } from "../../utils/CommanFunction";
const AuthFooter = (props) => {
  return (
    <div id="auth-footer">
      <div
        style={{ position: props.name !== "reg" ? "absolute" : "" }}
        className="footer footer-alt"
      >
        <span>
          2020-{getCurrentYear()} @ Developed by
          <a
            href="https://www.eximpedia.app/"
            rel="noopener noreferrer"
            target="_blank"
          >
            {" "}
            Eximpedia
          </a>
        </span>
      </div>
    </div>
  );
};

export default AuthFooter;
