export const USA_STATIC_COLUMNS_IMPORT = ["QUANTITY", "WEIGHT IN KG", "CIF", "TEU"];
export const USA_ANALYZE_IMPORT_HEADER = [
    { key: "HS_CODE", title: "HS CODE" },
    { key: "COUNTRY", title: "COUNTRY" },
    { key: "LOADING_PORT", title: "LOADING PORT" },
    { key: "UNLOADING_PORT", title: "UNLOADING PORT" },
    { key: "SHIPPER_NAME", title: "SHIPPER NAME" },
    { key: "CONSIGNEE_NAME", title: "CONSIGNEE NAME" },

    { key: "QUANTITY", title: "QUANTITY" },
    { key: "WEIGHT_IN_KG", title: "WEIGHT IN KG" },
    { key: "CIF", title: "CIF" },
    { key: "TEU", title: "TEU" },

];
export const USA_STATIC_COLUMNS_CHART_IMPORT = [
    { key: "QUANTITY", title: "QUANTITY" },
    { key: "WEIGHT_IN_KG", title: "WEIGHT IN KG" },
    { key: "CIF", title: "CIF" },
    { key: "TEU", title: "TEU" },
];