import { useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import React from "react";

export const notify = () => toast.success("Copied!");

export const ReactToaster = React.memo(() => {
  return (
    <div>
      <Toaster
        toastOptions={{
          success: {
            style: {
              background: "#005d91",
              color: "white",
            },
          },
        }}
        containerStyle={{
          top: 500,
          left: 20,
          bottom: 20,
          right: 20,
        }}
      />
    </div>
  );
});

export default ReactToaster;
