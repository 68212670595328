import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import ErrorPopup from "../../Component/ErrorLoader/ErrorPopup";
import TicketRaiseFormCustom from "./TicketRaiseFormCustom";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  spacing: 8,
});
const TicketRaiseWithError = (props) => {
  function ErrorHandler({ error }) {
    return (
      <div role="alert">
        {error && (
          <ErrorPopup
            errorMsg={"Something Went Wrong , Please Login Again"}
            open={error}
          />
        )}
      </div>
    );
  }
  return (
    <>
      <ErrorBoundary FallbackComponent={ErrorHandler}>
        <ThemeProvider theme={theme}>
          <TicketRaiseFormCustom />
        </ThemeProvider>
      </ErrorBoundary>
    </>
  );
};

export default TicketRaiseWithError;
