import React from "react";
import { Grid } from "@mui/material";
import noImageFound from "../../../Asset/images/noImageFound.jpg";
import "./Countries.scss";
import moment from "moment";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useNavigate } from "react-router-dom";
import millify from "millify";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { translateButtonClick } from "../../../utils/CommanFunction";
import LockIcon from "@mui/icons-material/Lock";
const Countries = ({
  currentFilteredCountries,
  countryType,
  userAccessibleCountry,
  handleNoAccess,
}) => {
  const navigate = useNavigate();

  const redirectPage = (e) => {
    navigate(
      `/countries/shipments/explorer?tradeType=${e.trade}&countryCode=${
        e.code_iso_3
      }&country=${e.country.toUpperCase()}&blFlag=${e.bl_flag}`
    );
  };
  window.onload = translateButtonClick();
  return (
    <Grid
      container
      spacing={3}
      columnSpacing={{ xs: 1, sm: 2, md: 2 }}
      style={{ marginLeft: "-16px", marginTop: "0px" }}
    >
      {currentFilteredCountries &&
        currentFilteredCountries.length > 0 &&
        currentFilteredCountries.map((country, index) => {
          let flagUrl = null;
          try {
            flagUrl = require(`../../../Asset/flags/${country.flag_uri}`);
          } catch (error) {
            flagUrl = noImageFound;
          } finally {
            const isAccessible = userAccessibleCountry?.some(
              (accessibleCountry) =>
                accessibleCountry?.country === country?.country
            );
            return (
              <Grid
                className="card-container"
                item
                lg={4}
                md={6}
                sm={6}
                key={index}
              >
                <div className="card-box-country">
                  <div
                    className="card-header"
                    style={{ padding: "0px 0px 0px 1%" }}
                  >
                    {flagUrl && (
                      <img
                        src={flagUrl}
                        style={{ height: "25px" }}
                        className="set-image"
                        alt="flag"
                      />
                    )}
                    <span>
                      {country.country.toUpperCase().split("_").join(" ")}
                    </span>
                    <div
                      className="card-box ribbon-box"
                      style={{
                        marginLeft: "auto",
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "5px",
                        marginBottom: "10px",
                      }}
                    >
                      <div className="ribbon-trade-lable">
                        <div className="ribbon-success-trade-lable"></div>
                        <div className="rebbon-text-trade-lable">
                          {country?.trade}
                        </div>
                        <div className="square"></div>
                        <div className="ribbon-trade-lable-1">
                          <div className="ribbon-success-trade-lable-1"></div>
                          <div className="rebbon-text-trade-lable-none"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Grid container className="card-content">
                    <Grid item xs={6} className="card-content-left">
                      <div className="card-content-left-div">
                        <ul className="field_container">
                          {country.showcase_fields &&
                            country.showcase_fields.map((e, index) => {
                              return (
                                <li key={index}>
                                  <CheckCircleOutlinedIcon className="showcase-fields-bullete-point" />
                                  {e.split("_").join(" ")}
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </Grid>
                    <Grid item xs={6} className="card-content-right">
                      <div className="card-content-right-div">
                        <Grid container className="right-content">
                          <Grid item xs={6} className="right-content-left">
                            <ul className="field_container_left">
                              {/* <li className="field_name">Trade</li> */}
                              <li className="field_name">RECORDS COUNT</li>
                              <li className="field_name">START DATE</li>
                              <li className="field_name">END DATE</li>
                            </ul>
                          </Grid>
                          <Grid item xs={6} className="right-content-right">
                            <ul className="field_container_right">
                              {/* <li className="field_value">{country?.trade}</li> */}
                              <li className="field_value notranslate">
                                {millify(country?.count)}
                              </li>
                              <li className="field_value notranslate">
                                {moment(country?.dataRange?.start).format(
                                  "DD/MM/YYYY"
                                )}
                              </li>
                              <li className="field_value notranslate">
                                {moment(country?.dataRange?.end).format(
                                  "DD/MM/YYYY"
                                )}
                              </li>
                            </ul>
                          </Grid>
                        </Grid>
                        <div className="view-button-container">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginBottom: "15px",
                            }}
                          >
                            <button
                              className="view_button"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                fontSize: "16px",
                              }}
                              onClick={() => {
                                if (countryType?.CUSTOM) {
                                  if (isAccessible) {
                                    redirectPage(country);
                                  } else {
                                    handleNoAccess(country);
                                  }
                                } else {
                                  redirectPage(country);
                                }
                              }}
                            >
                              <span>View</span>
                              {countryType?.CUSTOM ? (
                                isAccessible ? (
                                  <RemoveRedEyeIcon />
                                ) : (
                                  <LockIcon />
                                )
                              ) : (
                                <RemoveRedEyeIcon />
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            );
          }
        })}
    </Grid>
  );
};

export default React.memo(Countries);
