"use strict";

import React, { useState } from "react";
import { useEffect } from "react";
import { CircularProgress } from "@mui/material";
import nopreview from "../../../Asset/images/no_preview.png";
import { isBarChartValidation, isBarChartYAxis2Validation } from "../../../utils/ChartsValidation";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import millify from "millify";
const BarChart = ({ data, xkey, ykey, isLoaderShowing, ykey2, recordLength, isBox, handleGetData }) => {
  const [noPreview, setNoPreview] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [options, setOptions] = useState({});
  const [allData, setAllData] = useState([]);
  const [xAxisDataValue, setXAxisDataValue] = useState([]);
  useEffect(() => {
    if (isBarChartValidation(xkey, ykey)) {
      const serieData = [];
      const xAxisData = [];
      const yAxisData = [];
      const yAxisData2 = [];

      data.forEach((elem) => {
        const name = elem[xkey];
        if (!xAxisData.includes(name)) {
          xAxisData.push(name);
        }
      });

      xAxisData.forEach((ser) => {
        const newData = data
          .filter((el) => {
            if (el[xkey] === ser) {
              return el;
            }
          })
          .map((e) => e[ykey]);
        const sum = newData.reduce((total, item) => total + item);
        yAxisData.push(sum);
        if (isBarChartYAxis2Validation(ykey2)) {
          const newData = data
            .filter((el) => {
              if (el[xkey] === ser) {
                return el;
              }
            })
            .map((e) => e[ykey2]);
          const sum = newData.reduce((total, item) => total + item);
          yAxisData2.push(sum);
        }
      });
      if (ykey && yAxisData?.length > 0) {
        serieData.push({ name: ykey.split("_").join(" "), data: yAxisData });
        if (yAxisData2?.length > 0 && ykey2) {
          serieData.push({
            name: ykey2.split("_").join(" "),
            data: yAxisData2,
          });
        }
      }
      setXAxisDataValue(xAxisData);
      setAllData(serieData);
    } else {
      setNoPreview(true);
      setAllData([]);
    }
  }, [xkey, ykey, ykey2]);
  useEffect(() => {
    if (allData?.length > 0 && xAxisDataValue?.length > 0 && recordLength && isBarChartValidation(xkey, ykey)) {
      let _pp = [];
      let xAxisData = [];
      if (recordLength?.type === "top") {
        _pp = allData.map((ele) => {
          return {
            ...ele,
            data: ele?.data?.slice(0, Number(recordLength?.key)),
          };
        });
        xAxisData = xAxisDataValue.slice(0, Number(recordLength?.key));
      } else if (recordLength?.type === "bottom") {
        _pp = allData.map((ele) => {
          return {
            ...ele,
            data: ele?.data?.slice(Number(recordLength?.key)),
          };
        });
        xAxisData = xAxisDataValue.slice(Number(recordLength?.key));
      }

      let tableHeader = _pp.map((elem) => {
        return {
          title: elem?.name,
          val: elem?.name,
        };
      });
      tableHeader.unshift({
        title: xkey,
        val: xkey,
      });

      const tableData = xAxisData.map((el, index) => {
        let obj = {
          [xkey]: el,
        };
        _pp.forEach((item) => {
          obj[item.name] = item.data[index];
        });
        return obj;
      });

      if (isBox) {
        handleGetData({
          headers: tableHeader,
          data: tableData,
        });
      }

      setLoading(true);
      setTimeout(() => {
        setNoPreview(false);
        setOptions({
          chart: {
            type: "bar",
            zoomType: "xy",
          },
          title: {
            text: "",
          },
          tooltip: {
            shared: true,
            formatter: function () {
              let tooltip = `<span><b>${this.x}</b></span><br>`;
              this.points.forEach((point) => {
                var value = point.y >= 1 ? millify(point.y) : point.y;
                tooltip += `<span>${point.series.name}: ${value}</span><br>`;
              });
              return tooltip;
            },
          },
          xAxis: {
            categories: xAxisData,
          },
          series: _pp,
        });
        setLoading(false);
      }, 2000);
    } else {
      setLoading(false);
      setNoPreview(true);
    }
  }, [recordLength, allData]);
  return (
    <div style={{ position: "relative", width: "100%", height: "100%" }}>
      {isLoading && isLoaderShowing && (
        <CircularProgress
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            zIndex: "1000",
          }}
        />
      )}
      {noPreview && isLoaderShowing ? (
        <img
          src={nopreview}
          alt=""
          style={{
            width: "60%",
            height: "100%",
            margin: "0 auto",
            display: "block",
            objectFit: "contain",
          }}
        />
      ) : (
        <HighchartsReact highcharts={Highcharts} options={options} />
      )}
    </div>
  );
};

export default BarChart;
