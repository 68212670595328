export const STICKYCOLUMN = [
  "add",
  "entity",
  "HS_CODE",
  "PRODUCT_DESCRIPTION",
  "PRODUCT_DESCRIPTION_EN",
  "HS_CODE_DESCRIPTION",
  "checkbox",
  "PRODUCT_DESC_EN",
  "CMDT_CODE",
  "CUSTOMS_DESCRIPTION",
];
export const minWidth_column = [
  "PRODUCT_DESCRIPTION",
  "PRODUCT_DESCRIPTION_EN",
  "CUSTOMS_DESCRIPTION",
  "HS_CODE_DESCRIPTION",
  "PRODUCT_DESC_EN",
];

export const maxWidth_column = [
  "PRODUCT_DESCRIPTION",
  "PRODUCT_DESCRIPTION_EN",
  "HS_CODE_DESCRIPTION",
  "SUPPLIER_ADDRESS",
  "BUYER_ADDRESS",
  "PRODUCT_DESC_VN",
  "PRODUCT_DESC_EN",
  "SUPPLIER_NAME",
  "IMPORTER_NAME",
  "ADDRESS",
  "IMPORTER",
  "EXPORTER",
  "EXPORTER_NAME",
];

export const tableCellWidth = {
  width_150: [
    "TYPE",
    "HS_CODE",
    "STD_UNIT",
    "TEU",
    "HS_CODE_2",
    "HS_CODE_4",
    "UVF",
    "ITEM",
    "BE_TYPE",
    "ITEM_NUMBER",
    "INCOTERM",
    "QUANTITY",
    "EXD_TK",
    "COUNTRY_CODE",
    "IMP_OR_EXP",
    "QUANTITY_UNIT",
    "NET_WEIGHT",
    "ITEM_NO",
    "USD_CIF",
    "WEIGHT",
    "PV",
    "VAT",
    "SERIE_FLOW",
    "CMDT_CODE",
    "REV_WEEK",
    "FEU",
    "PKG",
    "POR",
    "PIN",
    "STATE",
  ],
  width_75: ["add", "checkbox"],

  width_300: [
    "ORIGIN_COUNTRY",
    "INVOICE_UNITPRICE_FC",
    "COMMERCIAL_QUANTITY",
    "ASSESSED_VALUE_USD",
    "AGENT_IDENTIFICATION_NUMBER",
    "OFFICE_CODE_AND_NAME",
    "NAME_OF_CF_AGENT",
    "BUSINESS_IDENTIFICATION_NUMBER",
    "CPC_CODE_AND_DESCRIPTION",
    "ASSESSED_UNIT_PRICE_FC",
    "DECLARED_UNIT_PRICE_FC",
    "ORIGIN_PORT",
    "SHIPPER_COUNTRY",
    "FORWARDER",
    "NORTIFY_PARTY_NAME",
    "PORT_OF_MARITIME_DESTINATION",
    "PRODUCT_DECLARATION",
    "CLEARING_AGENT_NAME",
    "CUSTOMS_CLEARANCE_OFFICE",
    "INSURANCE_IN_BOTSWANAN_PULA",
    "FREIGHT_IN_BOTSWANAN_PULA",
    "DECLARANT",
    "TRANS_TYPE_WITHIN_COUNTRY_EN",
    "TRANS_TYPE_CROSS_BORDER_EN",
    "CUSTOMS_OFFICE_NAME_AT_BORDER",
    "CUSTOMS_OFFICE_CODE_AT_BORDER",
    "CUSTOMS_PROCEDURE_NAME",
    "COST_ADJUST_METHOD_NAME",
    "GUARANTEE_ADDRESS",
    "CUSTOMS_PROCEDURE_NAME_EN",
    "CUSTOMS_OFFICE_NAME_EN",
    "STD_IMPORTER_NAME",
    "AGENT_NAME",
    "TOTAL_ASSESS_VALUE_PAK_RS",
    "DEPARTURE_PORT_OR_PREMISES",
    "UNLOADING_PORT",
    "LOADING_PORT",
    "RAW_SHIPPER_ADDRESS",
    "RAW_SHIPPER_NAME",
    "SHIPPER_ADDRESS",
    "RAW_CONSIGNEE_NAME",
    "NOTIFY_PARTY_NAME",
    "NOTIFY_PARTY_ADDRESS",
    "PIECES_OR_NUMBER_OF_GOODS",
    "ADDITIONAL_MEASUREMENT_UNIT",
    "EXCHANGE_RATE_OF_CONTRACT_VALUE",
    "TYPE_OF_IMPORT",
    "CUSTOMS_MODE_CODE",
    "TRANSPORT_COMPANY_COUNTRY",
    "TRANSPORT_DOCUMENT_NUMBER",
    "UNIT",
    "INSURANCE_IN_FOREIGN_CURRENCY",
    "CODE_MODE_TRANSP_FRONTIERE",
    "VALUE_IN_FOREIGN_CURRENCY",
    "CONTRACTORS_NAME_AND_LOCATION",
    "CALCULATED_TOTAL_VALUE_USD",
    "CALCULATED_ITEM_VALUE_USD",
    "TOTAL_CUSTOMS_VALUE_USD",
    "STATISTICAL_VALUE_IN_1000_USD",
    "INVOICE_ITEM_CURRENCY_CODE",
    "TOTAL_PRICE_IN_FOREIGN_CURRENCY",
    "COUNTRY_OF_LAST_CONSIGNMENT",
    "BOOKING_CONTACT_PHONE_NUMBER",
    "BOOKING_CONTACT_E-MAIL",
  ],
  maxWidth_column: [
    "PRODUCT_DESCRIPTION",
    "PRODUCT_DESCRIPTION_EN",
    "HS_CODE_DESCRIPTION",
    "SUPPLIER_ADDRESS",
    "BUYER_ADDRESS",
    "PRODUCT_DESC_VN",
    "PRODUCT_DESC_EN",
    "SUPPLIER_NAME",
    "IMPORTER_NAME",
    "ADDRESS",
    "IMPORTER",
    "EXPORTER",
    "EXPORTER_NAME",
    "OPERATION_TYPE",
    "IMPORTER_ADDRESS",
    "SHIPPER_NAME",
    "CONSIGNEE_NAME",
    "HS_DESCRIPTION",
    "CONSIGNEE_ADDRESS",
    "HS_CODE_ITEM_DESCRIPTION",
    "DECLARED_FOB_VALUE_IN_BOTSWANAN_PULA",
    "TOTAL_CARGO_INVOICE_VALUE_IN_USD",
    "TOTAL_CARGO_INVOICE_VALUE_IN_BOTSWANAN_PULA",
    "PRODUCT_DESCRIPTION_1",
    "PRODUCT_DESCRIPTION_2",
    "CUSTOMS_AGENCY",
    "CUSTOMS_AGENT",
    "SHIPPING_AGENCY",
    "CUSTOMS_PROCEDURE_CODE",
    "HS_CODE_DESCRIPTION_EN",
    "IMPORTER_ADDRESS_EN",
    "IMPORTER_NAME_EN",
    "SUPPLIER_ADDRESS_EN",
    "SUPPLIER_NAME_EN",
    "PACKING_DESCRIPTION_NOMENCLATURE",
    "CONTRACTORS_NAME_AND_LOCATION",
    "NUMBER_OF_SKU_IN_DECLARATION_FORM",
    "COUNTRY_OF_SHIPMENT_OR_DESTINATION",
    "COUNTRY_OF_SHIPMENT_OR_DESTINATION_CODE",
    "PRODUCT_DESCRIPTION_3",
    "PRODUCT_DESCRIPTION_4",
    "TRANSPORT_COMPANY",
    "IMPORTER_EMAIL",
    "PORT",
    "STD_SUPPLIER_NAME",
    "STD_IMPORTER_NAME",
    "RAW_CONSIGNEE_ADDRESS",
    "RAW_NOTIFY_PARTY_ADDRESS",
    "REGISTERED_CUSTOMS_POST",
    "TRUSTEE_NAME",
    "IMPORTER_NAME_VN",
    "IMPORT_TYPE",
    "PRODUCT_DESCRIPTION_VN",
    "IMPORTER_ADDRESS_VN",
    "UNIT_PRICE_IN_FOREIGN_CURRENCY",
    "TOTAL_PRICE_IN_FOREIGN_CURRENCY",
    "ITEM_REGIME_NAME",
    "EXPORTER_ADDRESS",
    "BUYER_NAME",
    "UNLOADING_PORT",
    "NORTIFY_PARTY_NAME",
    "SHIPPER_ADDRESS",
    "CLEARING_AGENT_NAME",
    "PACKAGE_TYPE",
    "INSURANCE_IN_BOTSWANAN_PULA",
    "DECLARED_CIF_VALUE_IN_BOTSWANAN_PULA",
    "LOCATION",
    "DECLARANT",
    "FOB_VALUE_CURRENCY_FOB_BRGR_ORI",
    "EXPORTER_ADDRESS_EN",
    "BUYER_NAME_EN",
    "BUYER_ADDRESS_EN",
    "STD_EXPORTER_NAME",
    "STD_BUYER_NAME",
    "EXPORTER_NAME_EN",
    "EXCHANGE_RATE_OF_CONTRACT_VALUE",
    "CHAPTER_DESCRIPTION",
    "EXPORTER_ADDRESS_VN",
    "TOTAL_TAXABLE_VALUE_CURRENCY_CODE",
    "EXCHANGE_RATE_CURRENCY_CODE",
    "EXPORTER_NAME_VN",
    "TYPE_OF_EXPORT",
    "CMDT",
    "NOTIFY_ADDRESS",
    "NOTIFY_NAME",
    "CUSTOMS_DESCRIPTION",
    "EXPORT_NAME",
    "FORWARD_NAME",
    "ALSO_NOTIFY_NAME",
    "CUSTOMS_BROKER_ADDRESS",
  ],
};
