export const modalheaderArr_companyDetailsData = [
  "EXPORTER",
  "IMPORTER",
  "ADDRESS",
  "CONSIGNEE NAME",
  "CONSIGNEE ADDRESS",
  "SUPPLIER",
  "SUPPLIER ADDRESS",
  "IMPORTER NAME",
  "IMPORTER NAME EN",
  "IMPORTER NAME VN",
  "IMPORTER ADDRESS",
  "IMPORTER ADDRESS EN",
  "IMPORTER ADDRESS VN",
  "SUPPLIER NAME",
  "EXPORTER NAME",
  "EXPORTER NAME EN",
  "EXPORTER NAME VN",
  "EXPORTER ADDRESS",
  "EXPORTER ADDRESS EN",
  "EXPORTER ADDRESS VN",
  "BUYER NAME",
  "BUYER ADDRESS",
  "SHIPPER NAME",
  "SHIPPER ADDRESS",
  "NOTIFY PARTY NAME",
  "NOTIFY PARTY ADDRESS",
  "CONSIGNEE NAME EN",
  "SHIPPER NAME EN",
  "CONSIGNEE NAME NATIVE",
  "SHIPPER NAME NATIVE",
  "SHIPPER NAME NATIVE",
  "SHIPPER NAME NATIVE",
];

export const modalheaderArrCompanyDetailsData_Loop = [
  "EXPORTER",
  "IMPORTER",
  "CONSIGNEE NAME",
  "SUPPLIER",
  "IMPORTER NAME",
  "IMPORTER NAME EN",
  "IMPORTER NAME VN",
  "SUPPLIER NAME",
  "EXPORTER NAME",
  "EXPORTER NAME EN",
  "EXPORTER NAME VN",
  "BUYER NAME",
  "SHIPPER NAME",
  "NOTIFY PARTY NAME",
  "CONSIGNEE NAME EN",
  "SHIPPER NAME EN",
  "CONSIGNEE NAME NATIVE",
  "SHIPPER NAME NATIVE",
  "SHIPPER NAME NATIVE",
  "SHIPPER NAME NATIVE",
];
export const TradeType = {
  IMPORT: "IMPORT",
  EXPORT: "EXPORT",
};

export const ADDRESS = "ADDRESS";

export const modalheaderArr_productDetailsData = [
  "HS CODE",
  "GOODS DESCRIPTION",
  "PRODUCT DESCRIPTION",
  "PRODUCT DESCRIPTION EN",
  "PRODUCT DESCRIPTION VN",
  "HS CODE DESCRIPTION",
  "PRODUCT DESCRIPTION 1",
  "PRODUCT DESCRIPTION 2",
  "PRODUCT DESCRIPTION 3",
  "PRODUCT DESCRIPTION 4",
];

export const KEY_IMPORTER = {
  IMPORTER: "IMPORTER",
  IMPORTER_ADDRESS: "IMPORTER ADDRESS",
  SUPPLIER_ADDRESS: "SUPPLIER ADDRESS",
  SHIPPER_ADDRESS: "SHIPPER ADDRESS",
  CONSIGNEE_ADDRESS: "CONSIGNEE ADDRESS",
  NOTIFY_PARTY_ADDRESS: "NOTIFY PARTY ADDRESS",
};

export const KEY_EXPORTER = {
  EXPORTER: "EXPORTER",
  BUYER_ADDRESS: "BUYER ADDRESS",
  EXPORTER_ADDRESS: "EXPORTER ADDRESS",
  SHIPPER_ADDRESS: "SHIPPER ADDRESS",
};

export const COMPANY_DETAIL_VALUE_FOR_HIDE_CONTACT_ICON = [
  "NULL",
  "TO ORDER",
  "TO NULL",
  "ORDER",
  "BANK",
  "N A",
];
