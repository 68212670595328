import React, { useState, useCallback } from "react";
import "./RequestedData.scss";
import { logger } from "../../../Loggers/Loggers";
import Loading from "../../../Component/SuccessLoader/success";
import Error from "../../../Component/ErrorLoader/error";
import StickyTable from "../../../Component/Table/Table";
import { Box, Container } from "@mui/material";
import ErrorData from "../../../utils/ErrorHandling";
import { useEffect } from "react";
import { getUserRequestedDataInfo } from "../../../Services/service";
import { getCookie } from "../../../utils/CommanFunction";
import ModalComponent from "../../../Component/Modal/modal";
import millify from "millify";
import moment from "moment";
import EximLogo from "../../../Asset/images/logo-dark-og-transparent.png";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import { FetchData } from "../../../utils/LoadingMsg";
import throwError from "../../../utils/throwError";
import FacebookIcon from "@mui/icons-material/Facebook";
import XIcon from "@mui/icons-material/X";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GoogleIcon from "@mui/icons-material/Google";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import { MenuItem, Select } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import {
  DATEFORMAT,
  EXCEL_FILE_DOWNLOAD,
  FILTER_STATUS_CODE,
  linkedInPageHeader,
  StatusFilter,
} from "./RequestedData.enum";
import { utils, writeFile } from "xlsx";
import ApprovedSuccess from "../../../Component/ApprovedSuccessModal/ApprovedSuccess";
import { ButtonComponentWithToolTip } from "../../../Component/ButtonComponent/Button";
import Excel from "../../../Asset/images/excel.png";

const RequestedData = () => {
  const [loading, setLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState({});
  const [errorMsg, setErrorMsg] = useState();
  const [error, setError] = useState(false);
  const [dataTableHeader, setDataTableHeader] = useState([
    { key: "company_name", title: "Company Name" },
    { key: "company_location", title: "Location" },
    { key: "requested_contacts", title: "Employee Count" },
    { key: "status", title: "Status" },
    { key: "resolution", title: "Resolution" },
  ]);
  const [tableRowData, setTableRowData] = useState();
  const [infoModal, setInfoModal] = useState(false);
  const [requestedData, setRequestedData] = useState();
  const [totalRecords, setTotalRecord] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [filterStatusValue, setFilterStatusValue] = useState();
  const [formErrors, setFormErrors] = useState({});
  const [approvSuccessModalData, setApprovSuccessModalData] = useState({});
  const [approvSuccessModal, setApprovSuccessModal] = useState(false);
  const [allData, setAllData] = useState([]);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setLoading(true);
    setLoadingMsg(FetchData);
    let payload = {
      user_id: getCookie("user_id"),
      start: 0,
      length: rowsPerPage,
      status: 0,
    };
    fetchUserRequestedDataAndInfo(payload);
  }, []);

  const handleGoogleSearch = useCallback((searchTerm) => {
    var left = windowDimensions.width / 2 - 800 / 2;
    var top = windowDimensions.height / 2 - 500 / 2;
    window.open(
      `${searchTerm}`,
      searchTerm,
      "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
        800 +
        ", height=" +
        500 +
        ", top=" +
        top +
        ", left=" +
        left
    );
  });

  const validateForm = () => {
    const errors = {};
    if (!filterStatusValue) errors.selectFilter = "status is required";
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleResolutionInfo = (row, index) => {
    const _requestedData = requestedData[index];
    const _formattedData = {
      ..._requestedData,
      social_urls: [
        {
          twitter_url: _requestedData?.twitterUrl,
          linkedin_url: _requestedData?.linkedInUrl,
        },
      ],
      details: {
        country: _requestedData?.address,
      },
      employees: _requestedData?.employees?.map((emp) => ({
        name: emp?.name,
        designation: emp?.designation,
        phone: emp?.phone,
        email: emp?.email,
        social_urls: [
          {
            twitter_url: emp?.twitterUrl,
            linkedin_url: emp?.linkedInUrl,
          },
        ],
      })),
    };
    setRequestedData(_formattedData);
    setInfoModal(true);
  };

  const companySocialMediaUrls = useCallback(
    (socialMediaData, xClass) => {
      const arr = [socialMediaData];
      return arr?.flat()?.map((socialUrl) => {
        return (
          <>
            {socialUrl?.linkedin_url && (
              <a
                href={socialUrl?.linkedin_url}
                className="social-icons social-media-linkedIn"
                target="_blank"
              >
                <LinkedInIcon />
              </a>
            )}
            {socialUrl?.twitter_url && (
              <a
                href={socialUrl?.twitter_url}
                className={xClass}
                target="_blank"
              >
                <XIcon />
              </a>
            )}
            {socialUrl?.facebook_url && (
              <a
                href={socialUrl?.facebook_url}
                className="social-icons faceBook"
                target="_blank"
              >
                <FacebookIcon />
              </a>
            )}
          </>
        );
      });
    },
    [requestedData]
  );

  const companyDetailsSection = useCallback(() => {
    return [
      {
        Industry: requestedData?.industries
          ? requestedData.industries.map((items) => items)
          : "-",
        "Year Established": "-",
        "Annual Income": requestedData?.annual_revenue
          ? millify(requestedData?.annual_revenue)
          : "-",
        "Employee Count": requestedData?.estimated_num_employees
          ? millify(requestedData?.estimated_num_employees)
          : "-",
        "Market Value": "-",
        "Time of Last Round of Funding":
          requestedData?.latest_funding_round_date
            ? moment(requestedData?.latest_funding_round_date).format(
                DATEFORMAT?.format
              )
            : "-",
      },
      {
        "Financing Stage": "-",
        "Total Funding": requestedData?.total_funding
          ? millify(requestedData?.total_funding)
          : "-",
        "Public Exchange": "-",
        Address: requestedData?.raw_address ?? "-",
        State: requestedData?.state ?? "-",
        Country: requestedData?.details?.country ?? "-",
      },
    ];
  }, [requestedData]);

  const infoButtonCompanyModalBody = () => {
    return (
      <>
        <div className="requested-LinkedInfo-container">
          <div className="linkedInfo-header-container">
            <div className="LinkedInfo-header">
              <div className="company-name-header">
                {requestedData?.companyName}
              </div>
              <div className="company-contact">
                <span
                  style={{
                    paddingRight: "5px",
                  }}
                >
                  {requestedData?.phone}
                </span>
                <span className="company-social-media-pipe"></span>
                <span className="social-media-container">
                  {companySocialMediaUrls(
                    requestedData?.social_urls,
                    "social-icons"
                  )}
                  {requestedData?.websiteUrl && (
                    <span
                      className="social-icons"
                      onClick={() =>
                        handleGoogleSearch(requestedData?.websiteUrl)
                      }
                    >
                      <GoogleIcon />
                    </span>
                  )}
                </span>
              </div>
            </div>

            <div className="company-detail-summary">
              <p style={{ lineHeight: "30px" }}>{requestedData?.summary}</p>
            </div>
          </div>

          <div className="company-keywords">
            <div className="heading-blue" style={{ marginRight: "2px" }}>
              <span>
                <AccountTreeOutlinedIcon />
              </span>
              Keywords
            </div>
            <div className="keywords">
              {requestedData?.keywords?.length > 0 ? (
                requestedData?.keywords?.map((keyword) => (
                  <span key={keyword} className="keywords-name">
                    {keyword}
                  </span>
                ))
              ) : (
                <span className="keywords-name">No keywords available</span>
              )}
            </div>
          </div>

          <div className="company-details">
            <div className="heading-blue">
              <span>
                <DashboardOutlinedIcon />
              </span>
              Details
            </div>

            <div className="linkedIn-data-display">
              {companyDetailsSection()?.map((column) => (
                <div className="column-for-company-data">
                  {Object.keys(column).map((key) => (
                    <p className="company-details-data">
                      <strong>{key}</strong>
                      <div style={{ textAlign: "end" }}>{column[key]}</div>
                    </p>
                  ))}
                </div>
              ))}
              <div className="logo-column" style={{ top: "31%" }}>
                <img src={EximLogo} alt="Logo" />
              </div>
            </div>
            <div className="company-detail-table">
              <div className="heading-blue">
                <span>
                  <PersonOutlinedIcon />
                </span>
                Contact
              </div>
              <div style={{ marginTop: "20px" }}>
                <StickyTable
                  columns={linkedInPageHeader}
                  rows={requestedData?.employees}
                  isFromLinkedInPage={true}
                  companySocialMediaUrls={companySocialMediaUrls}
                  companyDetailTable={true}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const fetchUserRequestedDataAndInfo = (payload) => {
    getUserRequestedDataInfo(payload)
      .then((res) => {
        if (res?.status === 200) {
          if (res?.data) {
            setAllData(res?.data);
            setLoading(false);
            const userData = res?.data?.map((item) => {
              return {
                ...item.request,
                status: item?.status,
                response: item?.response,
              };
            });
            const userResponse = res?.data?.map((item) => item?.response);
            setRequestedData(userResponse);
            setTableRowData(userData);
            setTotalRecord(res?.data[0]?.total_records);
          }
        } else {
          throw throwError();
        }
      })
      .catch((err) => {
        showErrForRequestedBuyer(err);
      });
  };

  const handleStatusFilterApply = () => {
    let payload = {
      user_id: getCookie("user_id"),
      start: 0,
      length: rowsPerPage,
      status: FILTER_STATUS_CODE[filterStatusValue] ?? 0,
    };
    if (validateForm()) {
      fetchUserRequestedDataAndInfo(payload);
      setFilterStatusValue();
      setFilterModalOpen(false);
    }
  };

  const showErrForRequestedBuyer = (err) => {
    const errorForLogger = `Requested Buyer:- ${
      err?.msg ? err?.msg : err?.message
    }`;
    logger(errorForLogger);
    setLoading(false);
    setError(true);
    setErrorMsg({
      title: err?.status === 401 ? "Warning" : "Error",
      content:
        err?.status === 500
          ? ErrorData?.INTERNAL_SERVER
          : err?.status === 401
          ? err?.msg
          : ErrorData?.SOMETHING_WENT_WRONG,
    });
  };

  const handleChangePage = (event) => {
    let newPage = parseInt(event);
    let offset = (newPage - 1) * rowsPerPage;
    setPage(newPage - 1);
    const payload = {
      user_id: getCookie("user_id"),
      start: offset,
      length: rowsPerPage,
      status: 0,
    };
    fetchUserRequestedDataAndInfo(payload);
  };

  const handleChangeRowsPerPage = (value) => {
    setRowsPerPage(parseInt(value));
    setPage(0);
    const payload = {
      user_id: getCookie("user_id"),
      start: 0,
      length: parseInt(value),
      status: 0,
    };
    fetchUserRequestedDataAndInfo(payload);
  };

  const onFilterStatusClick = () => {
    setFilterModalOpen(true);
    setFilterStatusValue();
  };

  const handleChangeStatusFilter = (e) => {
    setFilterStatusValue(e.target.value);
  };

  const handleDownloadExcelFromFrontEnd = (data, downloadedType) => {
    setApprovSuccessModal(true);
    setApprovSuccessModalData(EXCEL_FILE_DOWNLOAD);
    setTimeout(() => {
      setApprovSuccessModal(false);
    }, 2500);

    const workbook = utils.book_new();

    if (downloadedType === "single") {
      const companyData = [
        {
          "Company Name": data?.companyName,
          Address: data?.address,
          Summary: data?.summary,
          "Website URL": data?.websiteUrl,
          "Twitter URL": data?.twitterUrl,
          "LinkedIn URL": data?.linkedInUrl,
          Keywords: data?.keywords,
        },
      ];

      const employeeData = data?.employees?.map((employee) => ({
        "Employee Name": employee?.name,
        "Employee Designation": employee?.designation,
        "Employee Phone": employee?.phone,
        "Employee Email": employee?.email,
        "Employee LinkedIn URL": employee?.linkedInUrl,
        "Employee Twitter URL": employee?.twitterUrl,
      }));

      const companySheet = utils.json_to_sheet(companyData);
      const employeeSheet = utils.json_to_sheet(employeeData);

      utils.book_append_sheet(workbook, companySheet, "Company Data");
      if (employeeData?.length) {
        utils.book_append_sheet(workbook, employeeSheet, "Employee Data");
      }

      writeFile(workbook, `${data?.companyName}.xlsx`);
    } else if (downloadedType === "all") {
      data.forEach((item) => {
        const combinedData = [
          {
            "Company Location": item?.request?.company_location,
            "Company Name": item?.request?.company_name,
            "Requested Contacts": item?.request?.requested_contacts,
            "Email ID": item?.email_id,
            "Created Timestamp": moment(item?.created_ts).format("YYYY-MM-DD"),
          },
        ];

        if (item.response && item.response.employees) {
          item.response.employees.forEach((employee) => {
            combinedData.push({
              "Employee Name": employee?.name,
              "Employee Designation": employee?.designation,
              "Employee Phone": employee?.phone,
              "Employee Email": employee?.email,
              "Employee LinkedIn URL": employee?.linkedInUrl,
              "Employee Twitter URL": employee?.twitterUrl,
            });
          });
        }

        const sheet = utils.json_to_sheet(combinedData);
        let sheetName = item?.request?.company_name
          .replace(/[\[\]\*\?\/\\]/g, " ")
          .substring(0, 31);

        utils.book_append_sheet(workbook, sheet, sheetName);
      });

      writeFile(workbook, "CompanyData.xlsx");
    }
  };

  const dataTable = useCallback(() => {
    return (
      <StickyTable
        columns={dataTableHeader}
        rows={tableRowData}
        isRequestedBuyerData={true}
        showPagination={true}
        totalAvailableData={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleResolutionInfo={handleResolutionInfo}
        onFilterStatusClick={onFilterStatusClick}
        handleDownloadExcelFromFrontEnd={handleDownloadExcelFromFrontEnd}
      />
    );
  }, [tableRowData, totalRecords, page, rowsPerPage]);

  return (
    <>
      <Container maxWidth="xxl">
        {loading && (
          <Loading title={loadingMsg.title} content={loadingMsg.content} />
        )}
        <ApprovedSuccess
          Open={approvSuccessModal}
          title={approvSuccessModalData.title}
          footerTitle={approvSuccessModalData.footerTitle}
        />
        {error && <Error open={error} setOpen={setError} errorMsg={errorMsg} />}
        <ModalComponent
          open={infoModal}
          setOpen={setInfoModal}
          header="Requested Data Info"
          modalBody={infoButtonCompanyModalBody()}
          saveButtonShown={false}
          width="65%"
        />
        <ModalComponent
          open={filterModalOpen}
          setOpen={setFilterModalOpen}
          header="Status Filter"
          modalBody={
            <div>
              <FormControl variant="outlined" style={{ width: "100%" }}>
                <InputLabel id="query-sub-type-label">Select Filter</InputLabel>
                <Select
                  labelId="query-sub-type-label"
                  id="query-sub-type-select"
                  value={filterStatusValue}
                  onChange={(e) => {
                    handleChangeStatusFilter(e);
                  }}
                  label="Select Filter"
                >
                  {Object.values(StatusFilter).map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
                {formErrors?.selectFilter && (
                  <span className="error-text">{formErrors?.selectFilter}</span>
                )}
              </FormControl>
            </div>
          }
          width="400px"
          saveText="Confirm"
          saveButtonShown={true}
          saveButtonColor="#1abc9c"
          savedClicked={handleStatusFilterApply}
        />
        <div className="row-dashbored" style={{ padding: "20px 10px" }}>
          <h4 className="text">
            Requested
            <span className="badge bg-soft-blue text-blue label-worksapce-name">
              Company Data
            </span>
          </h4>
        </div>
        <div>
          <ButtonComponentWithToolTip
            name={<img src={Excel} alt="ExcelDownload" />}
            style={{
              height: "35px",
              paddingTop: "12px",
              float: "right",
              marginRight: "10px",
              marginBottom: "10px",
            }}
            btnClick={() => handleDownloadExcelFromFrontEnd(allData, "all")}
            toUpperCase={true}
            tooltipTitle="Download All"
          />
        </div>
        <Box style={{ marginTop: "10px" }}>{dataTable()}</Box>
      </Container>
    </>
  );
};

export default RequestedData;
