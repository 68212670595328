import * as React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/system";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: "1em",
  },
}));

function PointDeductionBar(props) {
  const { value, max } = props;
  const percentage = (value / max) * 100;

  return (
    <CustomTooltip title="Available Points" placement="bottom" arrow>
      <Box
        sx={{
          position: "relative",
          display: "inline-flex",
        }}
      >
        <CircularProgress
          variant="determinate"
          value={percentage}
          sx={{ backgroundColor: "#bfd6e342", borderRadius: "100%" }}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="caption" component="div" color="gray">
            {`${Math.round(value)}`}
          </Typography>
        </Box>
      </Box>
    </CustomTooltip>
  );
}

PointDeductionBar.propTypes = {
  value: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
};

export default PointDeductionBar;
