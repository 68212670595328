export const DefaultWorkSpaceTableHeader = [
    {
        key: "add",
        title: "",
    },

];

export const leftSelectAnalyticsOptions = [
    { title: "QUANTITY", value: "QUANTITY" },
    { title: "PRICE", value: "PRICE" },
    { title: "UNIT PRICE", value: "UNIT_PRICE" },
    { title: "AVERAGE UNIT PRICE", value: "AVERAGE_UNIT_PRICE" },
    { title: "DUTY", value: "DUTY" },
    { title: "SHIPMENT", value: "SHIPMENT" },
];
export const rightSelectAnalyticsOptions = [
    { title: "PRICE", value: "PRICE" },
    { title: "QUANTITY", value: "QUANTITY" },
    { title: "UNIT PRICE", value: "UNIT_PRICE" },
    { title: "AVERAGE UNIT PRICE", value: "AVERAGE_UNIT_PRICE" },
    { title: "DUTY", value: "DUTY" },
    { title: "SHIPMENT", value: "SHIPMENT" },
];

export const leftSelectMarketShareOptions = [
    { title: "TOP 3", value: "TOP_3" },
    { title: "TOP 5", value: "TOP_5" },
    { title: "TOP 10", value: "TOP_10" },
    { title: "BOTTOM 3", value: "BOTTOM_3" },
    { title: "BOTTOM 5", value: "BOTTOM_5" },
    { title: "BOTTOM 10", value: "BOTTOM_10" },
];
export const leftSideTradeCompositionOption = [{ title: "TOP", value: "TOP" }, { title: "BOTTOM", value: "BOTTOM" }];
export const rightSelectMarketShareOptions = [{ title: "COUNTRY", value: "COUNTRY" }, { title: "PORT", value: "PORT" }, { title: "IMPORTER", value: "IMPORTER" }, { title: "EXPORTER", value: "EXPORTER" }];

export const yearMonths = [
    {
        id: 1,
        month: "Jan",
        alias: "JAN",
    },
    {
        id: 2,
        month: "Feb",
        alias: "FEB",
    },
    {
        id: 3,
        month: "Mar",
        alias: "MAR",
    },
    {
        id: 4,
        month: "Apr",
        alias: "APR",
    },
    {
        id: 5,
        month: "May",
        alias: "MAY",
    },
    {
        id: 6,
        month: "Jun",
        alias: "JUN",
    },
    {
        id: 7,
        month: "Jul",
        alias: "JUL",
    },
    {
        id: 8,
        month: "Aug",
        alias: "AUG",
    },
    {
        id: 9,
        month: "Sep",
        alias: "SEP",
    },
    {
        id: 10,
        month: "Oct",
        alias: "OCT",
    },
    {
        id: 11,
        month: "Nov",
        alias: "NOV",
    },
    {
        id: 12,
        month: "Dec",
        alias: "DEC",
    },
];

export const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const Default_Value = {
    QUANTITY: "QUANTITY", PRICE: "PRICE", TOP_3: "TOP_3", COUNTRY: "COUNTRY", AVERAGE_UNIT_PRICE: "AVERAGE_UNIT_PRICE", TOP: "TOP", TOP_5: "TOP_5",
    NULL: "NULL"
}

export const sectionType = "ANALYTICS_CORRELATION";
export const ANALYTICS_DISTRIBUTION = "ANALYTICS_DISTRIBUTION";

export const field = {
    fieldTerm: "fieldTerm",
    fieldTermTypeSuffix: "fieldTermTypeSuffix",
    fieldTermPrimary: "fieldTermPrimary",
    fieldTermPrimaryTypeSuffix: "fieldTermPrimaryTypeSuffix",
    fieldTermSecondary: "fieldTermSecondary",
    fieldTermSecondaryTypeSuffix: "fieldTermSecondaryTypeSuffix"
}

export const Factor = {
    QUANTITY: "QUANTITY",
    PRICE: "PRICE",
    QUANTITY_COMPARISON: "QUANTITY_COMPARISON",
    QUANTITY_PRICE: "QUANTITY_PRICE",
    AVERAGE_UNIT_PRICE: "AVERAGE_UNIT_PRICE"
}

export const Domain = {
    IMPORTER: "IMPORTER",
    EXPORTER: "EXPORTER",
    HSCODE: "HSCODE",
    PORT: "PORT",
    COUNTRY: "COUNTRY",
}