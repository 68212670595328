const ShipmentFilter = {
  options: [
    // {
    //   value: "DEFAULT ALL",
    //   title: "DEFAULT ALL",
    // },
    {
      value: "LESS THAN 100",
      title: "LESS THAN 100",
    },
    {
      value: "GREATER THAN 100 TO 1K",
      title: "GREATER THAN 100 TO 1K",
    },
    {
      value: "GREATER THAN 1K TO 5K",
      title: "GREATER THAN 1K TO 5K",
    },
    {
      value: "GREATER THAN 5K TO 10K",
      title: "GREATER THAN 5K TO 10K",
    },
    {
      value: "GREATER THAN 10K TO 20K",
      title: "GREATER THAN 10K TO 20K",
    },
    {
      value: "GREATER THAN 20K TO 40K",
      title: "GREATER THAN 20K TO 40K",
    },
    {
      value: "GREATER THAN 40K TO 60K",
      title: "GREATER THAN 40K TO 60K",
    },
    {
      value: "GREATER THAN 60K TO 80K",
      title: "GREATER THAN 60K TO 80K",
    },
    {
      value: "GREATER THAN 80K TO 100K",
      title: "GREATER THAN 80K TO 100K",
    },
    {
      value: "GREATER THAN 100K",
      title: "GREATER THAN 100K",
    },
  ],
  getRangeFromOption: (value) => {
    let min = 0;
    let max = 0;
    switch (value) {
      // case "DEFAULT ALL":
      //   min = 0;
      //   max = 0;
      //   break;
      case "LESS THAN 100":
        min = 0;
        max = 100;
        break;
      case "GREATER THAN 100 TO 1K":
        min = 100;
        max = 1000;
        break;
      case "GREATER THAN 1K TO 5K":
        min = 1000;
        max = 5000;
        break;
      case "GREATER THAN 5K TO 10K":
        min = 5000;
        max = 10000;
        break;
      case "GREATER THAN 10K TO 20K":
        min = 10000;
        max = 20000;
        break;
      case "GREATER THAN 20K TO 40K":
        min = 20000;
        max = 40000;
        break;
      case "GREATER THAN 40K TO 60K":
        min = 40000;
        max = 60000;
        break;
      case "GREATER THAN 60K TO 80K":
        min = 60000;
        max = 80000;
        break;
      case "GREATER THAN 80K TO 1000K":
        min = 80000;
        max = 100000;
        break;
      case "GREATER THAN 100K":
        min = 100000;
        max = 1000000;
        break;
      default:
        min = 0;
        max = 0;
        break;
    }
    return { min, max };
  },
};

export default ShipmentFilter;
