import React, { memo } from "react";
import "./Sorting.scss";
const Sorting = memo(
  ({ handleDescendingAndAscendingOrder, column, descendingAndAscending }) => {
    const Sorting = (column, type) => {
      if (type === "asc") {
        if (descendingAndAscending[column] === "asc") {
          handleDescendingAndAscendingOrder(column, "default");
        } else {
          handleDescendingAndAscendingOrder(column, "asc");
        }
      } else if (type === "desc") {
        if (descendingAndAscending[column] === "desc") {
          handleDescendingAndAscendingOrder(column, "default");
        } else {
          handleDescendingAndAscendingOrder(column, "desc");
        }
      } else {
      }
    };

    return (
      <div style={{ position: "relative", minWidth: "50px" }}>
        <div
          className="triangle-up"
          style={{
            borderBottom:
              descendingAndAscending[column] === "asc"
                ? "7px solid #005d91 "
                : "7px solid#aaaaaa",
            top: "-11px",
          }}
          onClick={() => {
            Sorting(column, "asc");
          }}
        />
        <div
          className="triangle-up"
          style={{
            borderTop:
              descendingAndAscending[column] === "desc"
                ? "7px solid #005d91 "
                : "7px solid#aaaaaa",
            top: "2px",
          }}
          onClick={() => {
            Sorting(column, "desc");
          }}
        />
      </div>
    );
  }
);

export default Sorting;
