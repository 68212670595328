import React, { memo } from "react";
import "./ToggleSwitchIe.scss";
const ToggleSwitchIe = memo(({ onChange, currentChecked, ActivityTracker }) => {
  return (
    <div>
      <label
        className={`${ActivityTracker ? "switch-ie-activity" : "switch-ie"}`}
      >
        <input type="checkbox" id="togBtn" onChange={onChange} />
        <div className="sliderBtn round">
          <span className="on text-center">{currentChecked}</span>
        </div>
      </label>
    </div>
  );
});

export default ToggleSwitchIe;
