import { styled } from "@mui/material/styles";
import { Grid, Paper } from "@mui/material";
import "./DashboardCardsUi.scss";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#e5eef4" : "#e5eef4",
  ...theme.typography.body2,
  textAlign: "center",
  margin: "15px 0px",
  color: theme.palette.text.secondary,
  boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
  padding: "14px",
  border: "1px solid #2977a2",
}));

const DashboardCardsUi = (props) => {
  return (
    <div id="dashBoardComponent">
      <div className="row-dashbored">
        <h4 className="text">
          Dashboard
          <span className="badge bg-soft-blue text-blue label-worksapce-name">
            Current Highlights
          </span>
        </h4>
      </div>
      <Grid
        container
        columnSpacing={4}
        columns={{ xs: 2, sm: 8, md: 12, lg: 12, xl: 12 }}
      >
        {props?.arrForDashboard?.length > 0 &&
          props?.arrForDashboard.map((e, index) => (
            <Grid key={index} item xs={2} sm={4} md={4} lg={2} xl={2}>
              <Item id="demo-1">
                <div className="dashboard-detail-badge">
                  <div
                    style={{
                      textAlign: "left",
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: "14px",
                      width: "80%",
                    }}
                  >
                    {" "}
                    <p
                      className="muted-text-black"
                      id={e.text === "Saved Queries" && "savedQueries"}
                      style={{ margin: "0px" }}
                    >
                      {e.text}
                    </p>
                    <h3 style={{ margin: "0px" }}>
                      <span className={e.spanStyle}>
                        {props?.getDashboredValues[index]?.title}
                      </span>
                    </h3>
                  </div>
                </div>
              </Item>
            </Grid>
          ))}
      </Grid>
    </div>
  );
};

export default DashboardCardsUi;
