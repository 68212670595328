import React from "react";
import {
  getFavouriteCompany,
  updateFavouriteCompany,
  searchFavouriteCompany,
  getRecordShipmentData,
} from "../../Services/service";
import SelectComponent from "../../Component/SelectComponentForExploreNewUI/SelectComponent";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import StickyTable from "../../Component/Table/Table";
import { Box } from "@mui/material";
import Loading from "../../Component/SuccessLoader/success";
import Error from "../../Component/ErrorLoader/error";
import throwError from "../../utils/throwError";
import ErrorData from "../../utils/ErrorHandling";
import ConfirmationModal from "../../Component/ConfirmationModal/ConfirmationModal";
import DeleteIcon from "../../Asset/images/delete.png";
import { RecordShipment_Payload } from "../../utils/DefaultPayload";
import {
  Delete,
  Fav_company_absent,
  FetchData,
  planExpiredWarning,
  Retrieving_Fav_Company,
} from "../../utils/LoadingMsg";
import { companyTableHeader } from "./FavouriteCompanyTable.enum";
import SaveQueryCard from "../../Containers/DashboardNewUI/Component/SaveQueryCard/SaveQueryCard";
import moment from "moment";
import "./FavCompanyCard.scss";
import { useAppContext } from "../../Contexts/AppContext";

const pageTitle = {
  title: "Favourite",
  badgeTitle: "Company",
};
export default function FavouriteCompanyTable(props) {
  const { isPlanExpire } = useAppContext();

  const navigate = useNavigate();
  const [favouriteCompany, setFavouriteCompany] = useState([]);
  const [noDataFound, setNoDataFound] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState({});
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteRow, setDeleteRow] = useState({});
  const [searchCountry, setSearchCountry] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState([]);

  const [selectTrade, setSelectTrade] = useState({
    tradeType: "IMPORT",
    dataType: false,
    country: "",
    CountryOptions: [],
  });
  const setErrorForDashboard = (err) => {
    setLoading(false);
    setError(true);
    setErrorMsg({
      title: err?.status === 401 ? "Warning" : "Error",
      content:
        err?.status === 500
          ? ErrorData?.INTERNAL_SERVER
          : err?.status === 401
          ? err?.msg
          : ErrorData.SOMETHING_WENT_WRONG,
    });
  };

  useEffect(() => {
    // setLoading(true);
    // setLoadingMsg(Retrieving_Fav_Company);
    // getRecordShipmentData(RecordShipment_Payload)
    //   .then((e) => {
    //     if (e?.status === 500 || e?.status === 401) {
    //       throw throwError(e);
    //     } else {
    //       let countries = [];
    //       e?.countryNames &&
    //         e?.countryNames?.length > 0 &&
    //         e?.countryNames.forEach((country) => {
    //           countries.push({
    //             value: country?.country?.toUpperCase(),
    //             title: country?.country?.split("_").join(" ").toUpperCase(),
    //           });
    //         });
    //       setSelectTrade({
    //         ...selectTrade,
    //         country: countries[0]?.value?.toUpperCase() ?? "",
    //         CountryOptions: countries,
    //       });
    //     }
    //   })
    //   .catch((err) => {
    //     setErrorForDashboard(err);
    //   });
    getFavCompany();
  }, []);

  const handelChangeSelect = (e) => {
    const { name, value } = e?.target;
    setSelectTrade({ ...selectTrade, [name]: value });
  };
  // useEffect(() => {
  //   if (selectTrade?.tradeType !== "" && selectTrade?.country !== "") {
  //     searchFavouriteCompanyList(selectTrade?.tradeType, selectTrade?.country);
  //   }
  // }, [selectTrade?.tradeType, selectTrade?.country]);

  const getFavCompany = () => {
    setLoading(true);
    setLoadingMsg(FetchData);
    if (props?.isFromDashBoard) {
      props?.setLoadingForDashboardTable?.(true);
    }
    getFavouriteCompany()
      .then((fav) => {
        if (fav?.status === 500 || fav?.status === 401) {
          throw throwError(fav);
        } else {
          if (fav?.favoriteShipment) {
            const favCompany =
              fav?.favoriteShipment &&
              fav?.favoriteShipment?.length &&
              fav?.favoriteShipment.filter((e) => {
                return e.isFavorite === true;
              });
            setFavouriteCompany(favCompany);
            setLoading(false);
            if (fav?.favoriteShipment?.length === 0) {
              setNoDataFound(true);
              setLoadingMsg(Fav_company_absent);
              if (props?.isFromDashBoard) {
                props?.setNoDataFound?.(true);
                setNoDataFound(false);
                props?.setLoadingForDashboardTable?.(false);
                props?.setNoDataMsg?.(
                  "You haven't added any company as Favourite yet!"
                );
              } else {
                props?.setNoDataFound?.(false);
              }
            } else {
              setNoDataFound(false);
            }
          } else {
            throw new Error();
          }
        }
      })
      .catch((err) => {
        setErrorForDashboard(err);
      });
  };

  const searchFavouriteCompanyList = (tradeType, country) => {
    setLoading(true);
    setLoadingMsg(FetchData);
    const obj = {
      tradeType: tradeType,
      country: country,
      searchField: tradeType === "IMPORT" ? "IMPORTER_NAME" : "EXPORTER_NAME",
    };
    searchFavouriteCompany(obj)
      .then((favCmp) => {
        if (favCmp?.status === 500 || favCmp?.status === 401) {
          throw throwError(favCmp);
        } else {
          if (favCmp?.data) {
            setLoading(false);
            let FavCmp = [];
            favCmp?.data &&
              favCmp?.data?.length &&
              favCmp?.data?.forEach((ele) => {
                FavCmp.push({ title: ele });
              });
            setSearchCountry(FavCmp);
          }
        }
      })
      .catch((err) => {
        setErrorForDashboard(err);
      });
  };
  const handleRunCompany = (fav) => {
    if (
      (isPlanExpire?.isActive === true || isPlanExpire?.isActive === "true") &&
      (isPlanExpire?.isExtended === false ||
        isPlanExpire?.isExtended === "false")
    ) {
      navigate(
        `/countries/shipments/explorer?tradeType=${
          fav?.tradeType
        }&countryCode=${fav?.countryCode}&taxonomy_id=${
          fav?.taxonomy_id
        }&blFlag=${
          fav.bl_flag === 0 ? "false" : "true"
        }&country=${fav?.country.toUpperCase()}&columnName=${fav?.columnValue
          .split(" ")
          .join("_")}&columnField=${fav?.columnName.split(" ").join("_")}`
      );
    } else {
      setError(true);
      setErrorMsg({
        title: planExpiredWarning?.title,
        content: planExpiredWarning?.content,
      });
    }
  };

  const handleOpenDeleteModal = (getRowValue) => {
    if (
      (isPlanExpire?.isActive === true || isPlanExpire?.isActive === "true") &&
      (isPlanExpire?.isExtended === false ||
        isPlanExpire?.isExtended === "false")
    ) {
      setIsDeleteModalOpen(true);
      setDeleteRow(getRowValue);
    } else {
      setError(true);
      setErrorMsg({
        title: planExpiredWarning?.title,
        content: planExpiredWarning?.content,
      });
    }
  };

  const deleteQuery = () => {
    setIsDeleteModalOpen(false);
    setLoading(true);
    setLoadingMsg(Delete);
    let isFavouritePayload = {
      country: deleteRow?.country,
      tradeType: deleteRow?.tradeType,
      bl_flag: deleteRow?.bl_flag,
      columnName: deleteRow?.columnName,
      columnValue: deleteRow?.columnValue,
      recordRow: deleteRow?.recordRow,
      isFavorite: false,
      taxonomyId: deleteRow?.taxonomy_id,
      _id: deleteRow?._id,
    };

    updateFavouriteCompany(isFavouritePayload).then((e) => {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      getFavCompany();
    });
  };

  const handleIsMultiDropdown = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedCompany(typeof value === "string" ? value.split(",") : value);
  };
  const renderCards = () => {
    if (favouriteCompany && favouriteCompany?.length > 0) {
      return favouriteCompany?.map((item) => (
        <SaveQueryCard
          key={item.id}
          title={item?.columnValue}
          date={moment(item?.createdAt).format("MM/DD/YYYY")}
          tradeType={item?.tradeType}
          country={item?.country}
          countryFlag={item?.flag_country}
          handleRunCompany={handleRunCompany}
          handleOpenDeleteModal={handleOpenDeleteModal}
          data={item}
          recordCount={item?.companycount}
          lastUpdateDate={moment(item?.companydate).format("MM/DD/YYYY")}
        />
      ));
    }
    return null;
  };
  return (
    <>
      {!props?.isFromDashBoard && loading && (
        <Loading title={loadingMsg?.title} content={loadingMsg?.content} />
      )}
      {error && <Error open={error} setOpen={setError} errorMsg={errorMsg} />}
      {/* {noDataFound && <Loading title={loadingMsg.title} content={loadingMsg.content} loader={"hide"} />} */}

      {/* <SelectComponent
        pageTitle={pageTitle}
        handelChangeSelect={handelChangeSelect}
        selectTrade={selectTrade}
        buttonIcon={BookmarksIcon}
        buttonText="Set Trade"
        isMultipleDropdown={true}
        isMultipleDropdownOption={searchCountry}
        isMultipleDropdownSelectedValue={selectedCompany}
        handleIsMultiDropdown={handleIsMultiDropdown}
        btnShow={false}
        favCompany={true}
      /> */}

      {/* <StickyTable
        columns={companyTableHeader}
        rows={favouriteCompany}
        handleRunCompany={handleRunCompany}
        handleOpenDeleteModal={handleOpenDeleteModal}
      /> */}

      {props?.isFromDashBoard ? (
        <>{renderCards()}</>
      ) : (
        <div className="fav-company-card-container">{renderCards()}</div>
      )}

      {noDataFound && (
        <div style={{ marginBottom: "25px" }}>
          <Box className="noCmpFound-title">No Favourite Company Found</Box>
          <Box className="noCmpFound-subTitle">
            You haven't added any company as Favourite yet!
          </Box>
        </div>
      )}

      {isDeleteModalOpen && (
        <ConfirmationModal
          open={isDeleteModalOpen}
          setOpen={setIsDeleteModalOpen}
          modalBody={
            <div
              style={{
                padding: "15px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div style={{ margin: "10px" }}>
                <img src={DeleteIcon} alt="delete" />
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span
                  style={{
                    fontSize: "22px",
                    fontWeight: 600,
                    color: "rgb(50, 58, 70)",
                  }}
                >
                  Delete
                </span>
                <div style={{ fontSize: "16px", marginTop: "20px" }}>
                  Are you sure you want to remove this company from Favourite
                  List ?
                </div>
              </div>
            </div>
          }
          width="600px"
          saveText="Delete"
          saveButtonShown={true}
          saveButtonColor="#f1556c"
          savedClicked={deleteQuery}
        />
      )}
    </>
  );
}
