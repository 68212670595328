import React from "react";
import { experimentalStyled as styled } from "@mui/material/styles";

import Grid from "@mui/material/Grid";
import { Button, Box, Typography } from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";
import "./ExploreFilter.scss";
import CancelIcon from "@mui/icons-material/Cancel";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Filter from "../../../../Asset/images/filter.svg";
const FilterButton = styled(Button)({
  color: "#fff",
  backgroundColor: "#005D91",
  borderColor: " #005D91",
  padding: "6px 8px",
  height: "32px",
  "&:hover": {
    backgroundColor: "#07527D !important",
    borderColor: "#07527D !important",
  },
});

const ExploreFilter = (props) => {
  return (
    <div id="explore-filter-box" style={!props?.isFromExplore ? { padding: "0px" } : {}}>
      <Box item className="filter-title">
        <div
          id="filter-title"
          style={{
            fontSize: props?.fontSize ? props?.fontSize : "",
          }}
        >
          <div style={{ display: "flex", flex: "1", alignItems: "center" }} className="ellipsisName">
            <span>
              {/* <TuneIcon fontSize="small" style={{ marginRight: "5px" }} /> */}
              <img src={Filter} alt="filter" width="20px" height="20px" style={{ margin: "3px 5px 0px 0px" }}></img>
            </span>
            <b style={{ fontSize: "18px" }} className="ellipsisName" >Applied Filters</b>
          </div>
          <div>
            <ArrowBackIosNewIcon onClick={props?.handleToggleFilters} style={{ cursor: "pointer", marginTop: "3px" }} />
          </div>
        </div>
      </Box>
      {Object.values(props.CheckedData).length > 0 && (
        <Grid
          container
          style={{
            margin: "0px",
            padding: "0px 18px 8px",
            borderBottom: "1px solid #0000001a",
            width: "100%",
          }}
          spacing={{ xs: 2, md: 2 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {Object.values(props.CheckedData) &&
            Object.values(props.CheckedData).length > 0 &&
            Object.keys(props.CheckedData).map((e) => props.apply[e]) &&
            Object.values(props.CheckedData).map((e, index) => {
              let data = Object.keys(props.CheckedData).map((e) => [e]);
              if (data[index][0] === "QUANTITY") {
                return (
                  <>
                    {Object.keys(e).map((val) => {
                      return (
                        <>
                          <Grid item className="filter-btn">
                            <FilterButton
                              variant="contained"
                              startIcon={<CancelIcon />}
                              onClick={() =>
                                props.RemoveAppliedFilter(
                                  index,
                                  Object.keys(props.CheckedData).map((e) => [e]),
                                  val
                                )
                              }
                              key={index}
                            >
                              <Typography className="apply-filter-label"  >{val}</Typography>
                            </FilterButton>
                          </Grid>
                        </>
                      );
                    })}
                  </>
                );
              } else {
                return (
                  <Grid item className="filter-btn">
                    <FilterButton
                      variant="contained"
                      startIcon={<CancelIcon />}
                      onClick={(e) =>
                        props.RemoveAppliedFilter(
                          index,
                          Object.keys(props.CheckedData).map((e) => [e])
                        )
                      }
                      title={
                        Object.keys(e) &&
                        !!Object.keys(e).length &&
                        Object.keys(e)
                          .map((e) => e.split("_").join(" "))
                          .join(" | ")
                      }
                      key={index}
                    >
                      <Typography className="apply-filter-label">{`${Object.keys(e) && !!Object.keys(e).length && Object.keys(e)[0].split("_").join(" ")} ${Object.keys(e).length > 1 ? "..." : ""
                        }`}</Typography>
                    </FilterButton>
                  </Grid>
                );
              }
            })}
        </Grid>
      )}
    </div>
  );
};

export default ExploreFilter;
