import React, { useState } from "react";
import { Box, Grid } from "@mui/material";
import PieChartData from "../../Component/PieChartData";
import LineChart from "../../Component/LineChart";
import BarChart from "../../Component/BarChart";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import ListRoundedIcon from "@mui/icons-material/ListRounded";
import ChartBoxTable from "../ChartBoxTable";
import TableViewIcon from "@mui/icons-material/TableView";
import "./ChartBox.scss";
const ChartBox = ({ chart, analyzeData, setDeleteChart, setUpdateModal, setCurrentChartData, setOpen, handlingShowTable, chartLength, index }) => {
  const [tableData, setTableData] = useState(null);
  const handleSettings = () => {
    setUpdateModal(true);
    setCurrentChartData(chart);
    setOpen(true);
  };

  const handleBarClick = () => {
    handlingShowTable(chart);
  };

  const handleGetData = (data) => {
    setTableData(data);
  };

  const isOdd = chartLength % 2 !== 0;
  return (
    <Grid
      item
      xs={12}
      lg={isOdd && index === chartLength - 1 ? 12 : 6}
      className="workspace-card-chartBox"
      id="Analytics-chart-card"
      key={chart?._id}
      style={{ background: "none" }}
    >
      <div
        className="workspace-card-header-chartBox"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "5px 20px",
        }}
      >
        <p>{chart?.title}</p>
        <div style={{ position: "relative" }}>
          <SettingsRoundedIcon style={{ fontSize: "35px", margin: "0px 5px", cursor: "pointer" }} className="chart-icon" onClick={() => handleSettings()} />
          <TableViewIcon
            style={{ fontSize: "35px", margin: "0px 5px", cursor: "pointer", padding: "5px 2px" }}
            className="chart-icon"
            onClick={() => {
              handleBarClick();
            }}
          />
          <DeleteForeverIcon
            onClick={() => {
              setDeleteChart(true);
              setCurrentChartData(chart);
            }}
            style={{ fontSize: "35px", margin: "0px 5px", cursor: "pointer", padding: "4px 2px" }}
            className="chart-icon"
          />
        </div>
      </div>
      <div
        className="workspace-card-body-chartBox"
        id="Analytics-chart-body"
        style={{
          display: chart?.isShowChart ? "block" : "none",
          overflow: "scroll",
          background: "#fff",
        }}
      >
        <Box style={{ marginTop: "5px" }}>
          {chart && chart?.type === "PIE_CHART" && (
            <>
              <PieChartData
                recordLength={chart?.recordLengthValue}
                isBox={true}
                data={analyzeData}
                label={chart?.data?.groupValue}
                value={chart?.data?.messasureValue}
                handleGetData={handleGetData}
              />
            </>
          )}
          {chart && chart?.type === "LINE_CHART" && (
            <>
              <LineChart
                recordLength={chart?.recordLengthValue}
                isBox={true}
                data={analyzeData}
                xkey={chart?.data?.xkey}
                ykey={chart?.data?.ykey}
                handleGetData={handleGetData}
              />
            </>
          )}
          {chart && chart?.type === "BAR_CHART" && (
            <>
              <BarChart
                recordLength={chart?.recordLengthValue}
                isBox={true}
                data={analyzeData}
                xkey={chart?.data?.xkey}
                ykey={chart?.data?.ykey}
                ykey2={chart?.data?.ykey2}
                handleGetData={handleGetData}
              />
            </>
          )}
        </Box>
      </div>
      <div style={{ display: chart?.isShowChart ? "none" : "block", border: "2px solid #005D91", padding: "10px" }}>
        {tableData && tableData.hasOwnProperty("headers") && tableData.hasOwnProperty("data") && <ChartBoxTable tableData={tableData} />}
      </div>
    </Grid>
  );
};

export default ChartBox;
