import React from "react";
import { Button, Box } from "@mui/material";
import "./NotificationButtons.scss";

function NotificationButtons({
  activeTab,
  handleButtonClick,
  allCount,
  unReadCount,
}) {
  return (
    <Box className="toggleButtonGroup">
      <Button
        className={`toggleButton ${activeTab === "All" ? "activeButton" : ""}`}
        onClick={() => handleButtonClick("All")}
      >
        All
        <span className="countBadge">{allCount}</span>
      </Button>
      <Button
        className={`toggleButton ${
          activeTab === "Unread" ? "activeButton" : ""
        }`}
        onClick={() => handleButtonClick("Unread")}
      >
        Unread
        <span className="unreadCountBadge">{unReadCount}</span>
      </Button>
    </Box>
  );
}

export default NotificationButtons;
