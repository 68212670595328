import React from "react";
import "./passwordRecovery.scss";
import PasswordRecoveryComponent from "../../Component/PasswordRecoveryComponent/PasswordRecovery";
import loginImage from "../../Asset/images/Mail.png";
import Logo from "../../Asset/images/logo-dark-og-transparent.png";
const PasswordRecovery = (props) => {
  return (
    <div className="login-test-one-container">
      <div className="container-one">
        <div className="container-one-image-content">
          <div className="login-content-image-container">
            <img id="login-content-img-one" src={loginImage} />
          </div>
          <h1 className="login-image-content-one">Step 2: Check Your Inbox</h1>
          <p className="login-image-content-para-one">
            Once you've submitted your email address, keep an eye on your inbox.
            You should receive an email from us shortly with instructions on how
            to reset your password. If you don't see it, make sure to check your
            spam or junk folder.
          </p>
        </div>
      </div>
      <div className="container-two">
        <div id="container-two-div">
          <div className="login-form-div">
            <div className="login-test-one-logo-div">
              <img
                src={Logo}
                // width={300}
                // height={100}
                id="login-test-one-logo"
                alt="logo"
              />
            </div>
            <div className="form-container-with-image-one">
              <div>
                <PasswordRecoveryComponent
                  name="passwordRecovery"
                  data={props}
                  PasswordRecoveryComponent={true}
                />
              </div>
            </div>
            <div className="bottom-paragraph-test-one">
              <p>2023-24 design and developed by Eximpedia.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordRecovery;
