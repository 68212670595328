import React from "react";

const ReportsSvgIcon = ({ color = "#005d91" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="30"
      height="30"
      viewBox="0,0,256,256"
    >
      <g
        fill={color}
        fill-rule="nonzero"
        stroke="none"
        stroke-width="1"
        stroke-linecap="butt"
        stroke-linejoin="miter"
        stroke-miterlimit="10"
        stroke-dasharray=""
        stroke-dashoffset="0"
        font-family="none"
        font-weight="none"
        font-size="none"
        z
        text-anchor="none"
      >
        <g transform="scale(5.12,5.12)">
          <path d="M27.5,1c-1.378,0 -2.5,1.122 -2.5,2.5v6.5h6.5c2.481,0 4.5,2.019 4.5,4.5v34.5h4.5c1.378,0 2.5,-1.121 2.5,-2.5v-43c0,-1.378 -1.122,-2.5 -2.5,-2.5zM18.5,12c-1.378,0 -2.5,1.122 -2.5,2.5v5.5h6.5c2.481,0 4.5,2.019 4.5,4.5v24.5h4.5c1.378,0 2.5,-1.121 2.5,-2.5v-32c0,-1.378 -1.122,-2.5 -2.5,-2.5zM9.5,22c-1.378,0 -2.5,1.122 -2.5,2.5v22c0,1.379 1.122,2.5 2.5,2.5h13c1.378,0 2.5,-1.121 2.5,-2.5v-22c0,-1.378 -1.122,-2.5 -2.5,-2.5z"></path>
        </g>
      </g>
    </svg>
  );
};

export default ReportsSvgIcon;
