import PasswordRecoveryComponent from "../../Component/PasswordRecoveryComponent/PasswordRecovery";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { emailVerificationDetails } from "../../Services/service";

const EmailVerification = (props) => {
  const search = useLocation().search;
  const email = new URLSearchParams(search).get("verification_email");

  useEffect(() => {
    let verificationPayload = {
      email_id: email,
    };

    emailVerificationDetails(verificationPayload)
      .then(() => {})
      .catch(() => {});
  }, []);

  return (
    <PasswordRecoveryComponent
      name="emailVerification"
      data={props}
      emailId={email}
    />
  );
};

export default EmailVerification;
