import React, { useEffect } from "react";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import log from "loglevel";
import { useLocation } from "react-router-dom";

const TelemetryProvider = ({ instrumentationKey, children }) => {
  const location = useLocation();

  useEffect(() => {
    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey,
      },
    });

    appInsights.loadAppInsights();

    appInsights.setAuthenticatedUserContext(location?.pathname);

    // Set the log level to 'info' to ensure that all log messages are sent to Application Insights
    log.setLevel("info");

    // Override the default log methods to send log messages to Application Insights
    const originalLogMethods = {
      trace: log.trace,
      debug: log.debug,
      info: log.info,
      warn: log.warn,
      error: log.error,
    };
    log.trace = (message) =>
      appInsights.trackTrace({ message, severityLevel: 0 });
    log.debug = (message) =>
      appInsights.trackTrace({ message, severityLevel: 1 });
    log.info = (message) =>
      appInsights.trackTrace({ message, severityLevel: 2 });
    log.warn = (message) =>
      appInsights.trackTrace({ message, severityLevel: 3 });
    log.error = (message) =>
      appInsights.trackTrace({ message, severityLevel: 4 });

    return () => {
      // Restore the original log methods when the component is unmounted
      log.trace = originalLogMethods.trace;
      log.debug = originalLogMethods.debug;
      log.info = originalLogMethods.info;
      log.warn = originalLogMethods.warn;
      log.error = originalLogMethods.error;
    };
  }, [instrumentationKey, location?.pathname]);

  return <>{children}</>;
};

export default TelemetryProvider;
