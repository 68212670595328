import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

export default function ShareWorkspace(props) {
  const [value, setValue] = React.useState("female");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <FormControl>
      {/* <FormLabel id="demo-controlled-radio-buttons-group">Gender</FormLabel> */}
      <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group" name="controlled-radio-buttons-group" value={value} onChange={handleChange}>
        {props.otherUser && props.otherUser.length > 0 ? (
          props.otherUser.map((e) => {
            return <FormControlLabel value={e.userId} control={<Radio />} label={e.userName} onChange={(e) => props.handleShareUserChange(e)} />;
          })
        ) : (
          // <FormControlLabel value={e.userId} control={<Radio />} label={e.userName} onChange={(e) => props.handleShareUserChange(e)}/>;
          <span style={{ fontSize: "22px", fontWeight: 600, color: "#323a46" }}>No User Available</span>
        )}
      </RadioGroup>
    </FormControl>
  );
}
