import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import ErrorPopup from "../../Component/ErrorLoader/ErrorPopup";

import UserAccountProfile from "./account";
import { useEffect } from "react";

const UserAccountWithError = (props) => {
  function ErrorHandler({ error }) {
    return <div role="alert">{error && <ErrorPopup errorMsg={"Something Went Wrong , Please Login Again"} open={error} />}</div>;
  }
  return (
    <>
      <ErrorBoundary FallbackComponent={ErrorHandler}>
        <UserAccountProfile CLIENT_USER_ID={props.CLIENT_USER_ID} CLIENT_ACCOUNT_ID={props.CLIENT_ACCOUNT_ID} />
      </ErrorBoundary>
    </>
  );
};

export default UserAccountWithError;
