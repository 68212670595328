import React, { useEffect, useState } from "react";
import { Modal } from "@mui/material";
import "./CustomPopNotification.scss";
import logo from "../../Asset/images/logo-dark-og-transparent.png";
import { keyframes } from "@emotion/react";
import { updatePopStatus } from "../../Services/service";
import SinglePopUp from "./components/SinglePopUp";
import MultiplePopUp from "./components/MultiplePopUp";
import { logger } from "../../Loggers/Loggers";
import ModalComponent from "../Modal/modal";
import RequestCompanyModal from "../../Containers/ExploreCountryNewUI/components/RequestCompanyModal";
import { useNavigate } from "react-router-dom";
import LockOpenIcon from "@mui/icons-material/LockOpen";

export const CustomPopNotificationModal = ({ open, onClose, modalContent }) => {
  const navigate = useNavigate();
  const [highlightEffect, setHighlightEffect] = useState(false);
  const [notifications, setNotifications] = useState(modalContent);
  const [isUpgradeModal, setIsUpgradeModal] = useState(false);
  const [renewPopDetail, setRenewPopDetail] = useState(null);

  const isSinglePop = modalContent?.length === 1;
  const pulseEffect = keyframes`0% { transform: scale(1); box-shadow: 0 0 10px rgba(0, 93, 145, 0.2); }
 50% { transform: scale(1.05); box-shadow: 0 0 20px rgba(0, 93, 145, 0.4); }
  100% { transform: scale(1); box-shadow: 0 0 10px rgba(0, 93, 145, 0.2); }
`;

  const setErrorForPop = (err) => {
    const errorForLogger = `Popup Notification Component:- ${
      err?.status + ":" + err?.statusText
    }`;
    logger(errorForLogger);
  };

  const handleTicketManager = () => {
    navigate("/ticket-manager", {
      state: {
        countryName: "Renew Plan",
        tradeType: "",
        upgradePlan: true,
        popDetail: renewPopDetail,
      },
    });
    setIsUpgradeModal(false);
    onClose();
  };

  const handleOkayClick = (value) => {
    setIsUpgradeModal(false);
  };

  const handleRenewBtnAction = (value) => {
    setIsUpgradeModal(true);
    setRenewPopDetail(value);
  };

  const handlePopUpUpdate = (value, updateType, isSinglePop) => {
    const remainingPopViews =
      updateType === "noShow" ? 0 : value?.remaining_views - 1;
    const payload = {
      popup: {
        popupType: value?.popCategory,
        popupData: {
          _id: value?._id,
          remaining_views: remainingPopViews,
        },
      },
    };

    updatePopStatus(payload)
      .then((updateRes) => {
        if (updateRes?.data?.message === "Popup Updated Successfully") {
          if (!isSinglePop)
            setNotifications(
              notifications?.filter((n) => n?._id !== value?._id)
            );

          if (isSinglePop) onClose();
        } else {
          setErrorForPop(updateRes);
        }
      })
      .catch((err) => setErrorForPop(err));
  };

  const handleBackdropClick = (event, reason) => {
    if (reason === "backdropClick") {
      setHighlightEffect(true);
      setTimeout(() => setHighlightEffect(false), 300);

      return;
    }
    onClose();
  };

  const dismissNotification = (popValue) => {
    setNotifications(notifications?.filter((n) => n._id !== popValue?._id));
    handlePopUpUpdate(popValue, "later", false);
  };

  useEffect(() => {
    setNotifications(modalContent);
  }, [modalContent]);

  useEffect(() => {
    if (notifications?.length === 0) onClose();
  }, [notifications]);

  return (
    <>
      <Modal
        open={open}
        onClose={handleBackdropClick}
        className="custom-pop-modal-container"
        aria-labelledby="notification-modal-title"
        aria-describedby="notification-modal-description"
      >
        {isSinglePop ? (
          <SinglePopUp
            logo={logo}
            modalContent={modalContent}
            handlePopUpUpdate={handlePopUpUpdate}
            highlightEffect={highlightEffect}
            pulseEffect={pulseEffect}
            handleRenewBtnAction={handleRenewBtnAction}
          />
        ) : (
          <MultiplePopUp
            logo={logo}
            notifications={notifications}
            handlePopUpUpdate={handlePopUpUpdate}
            highlightEffect={highlightEffect}
            pulseEffect={pulseEffect}
            dismissNotification={dismissNotification}
            handleRenewBtnAction={handleRenewBtnAction}
          />
        )}
      </Modal>

      {isUpgradeModal && (
        <ModalComponent
          open={isUpgradeModal}
          setOpen={setIsUpgradeModal}
          modalBody={
            <RequestCompanyModal
              onClick={() => handleTicketManager()}
              headerIcon={<LockOpenIcon style={{ fontSize: "30px" }} />}
              headerTitle="Renew Your Plan"
              handleCancel={handleOkayClick}
              isFromRenewal={true}
            />
          }
          width="550px"
          isUpgradePlan={true}
        />
      )}
    </>
  );
};
