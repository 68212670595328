import ErrorData from "./ErrorHandling";

const throwError = (err) => {
  let errObj = {
    status: err.status,
    msg: err?.data?.message,
  };
  if (err.status === 401) {
    errObj = {
      msg: err?.data?.data?.msg
        ? err?.data?.data?.msg
        : ErrorData.SESSION_TIMEOUT,
      status: err.status,
    };
  }

  return errObj;
};

export default throwError;
