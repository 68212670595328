import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import "./InfoModal.scss";

const InfoModal = ({ open, modalDetail, onCloseModal }) => {
  return (
    <Modal
      open={open}
      onClose={onCloseModal}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      className="custom-modal"
    >
      <Box className="MuiPaper-root">
        <div className="modal-header" id="modal-title">
          Last Comment
        </div>
        <div className="modal-body" id="modal-description">
          {modalDetail?.content}
        </div>
        <div className="modal-footer">
          <Button className="button" onClick={onCloseModal}>
            OK
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default InfoModal;
