import React, { useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import millify from "millify";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import HighchartsExporting from "highcharts/modules/exporting";
import "./MyAreaChart.scss";

// Initialize the exporting module for Highcharts
HighchartsExporting(Highcharts);

const MyAreaChart = ({ title, data }) => {
  const chartRef = useRef(null);

  // Map the provided data to Highcharts series format
  const seriesData = data.map((companyData) => {
    const companyName = companyData[0]?.COMPANY;
    const dataPoints = companyData.map((entry) => [
      new Date(entry.TradeMonth).getTime(),
      entry.TOTAL_VALUE,
    ]);

    return {
      name: companyName,
      data: dataPoints,
      showInLegend: true,
      visible: true,
    };
  });

  const options = {
    chart: {
      type: "area",
      height: 550,
      styledMode: false,
    },
    title: {
      text: title,
    },
    xAxis: {
      type: "datetime",
      title: {
        text: "Trade Months and Year",
      },
    },
    yAxis: {
      title: {
        text: "Total Value (In Millions)",
      },
      labels: {
        formatter: function () {
          return millify(this.value, { precision: 2 });
        },
      },
    },
    tooltip: {
      pointFormatter: function () {
        return `<span style="color:${this.color}">\u25CF</span> ${
          this.series.name
        }: <b>${millify(this.y, { precision: 2 })} M</b><br/>`;
      },
    },
    series: seriesData,
    plotOptions: {
      series: {
        events: {
          legendItemClick: function () {
            const selectedSeries = this;
            const chart = this.chart;
            const allSeriesVisible = chart.series.every(
              (series) => series.visible
            );
            const isSelectedSeriesVisible = selectedSeries.visible;

            if (isSelectedSeriesVisible && !allSeriesVisible) {
              chart.series.forEach((series) => {
                series.setVisible(true, false);
              });
              chart.redraw();
            } else {
              chart.series.forEach((series) => {
                series.setVisible(false, false);
              });
              selectedSeries.setVisible(true, false);
              chart.redraw();
            }

            return false;
          },
        },
      },
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            {
              text: "Download PDF",
              onclick: function () {
                exportDataAsPDF();
              },
            },
            {
              text: "Download JPEG",
              onclick: function () {
                exportChartAsJPEG();
              },
            },
          ],
        },
      },
      menuItemStyle: {},
      menuItemHoverStyle: {},
    },
  };

  // Utility function to get month name and year
  const getMonthNameWithYear = (date) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const d = new Date(date);
    return `${monthNames[d.getMonth()]} ${d.getFullYear()}`;
  };

  // Function to export data as PDF
  const exportDataAsPDF = () => {
    const doc = new jsPDF();
    const companyDataMap = {};

    seriesData.forEach((series) => {
      const companyName = series.name;
      series.data.forEach((point) => {
        const month = getMonthNameWithYear(point[0]);
        const totalValue = millify(point[1], { precision: 2 });

        if (!companyDataMap[companyName]) {
          companyDataMap[companyName] = [];
        }

        companyDataMap[companyName].push({
          Month: month,
          TotalValue: totalValue,
        });
      });
    });

    const dataForPDF = [];
    Object.keys(companyDataMap).forEach((companyName) => {
      const monthsData = companyDataMap[companyName];
      monthsData.forEach((data) => {
        dataForPDF.push({
          Company: companyName,
          ...data,
        });
      });
    });

    const columns = [
      { header: "Company", dataKey: "Company" },
      { header: "Month", dataKey: "Month" },
      { header: "Total Value in (Millions)", dataKey: "TotalValue" },
    ];

    doc.autoTable({
      head: [columns.map((col) => col.header)],
      body: dataForPDF.map((row) => columns.map((col) => row[col.dataKey])),
      startY: 20,
      columnStyles: {
        Company: { cellWidth: "auto" },
        Month: { cellWidth: "auto" },
        TotalValue: { cellWidth: "auto" },
      },
    });

    doc.save("favorite-company-data.pdf");
  };

  // Function to export chart as JPEG
  const exportChartAsJPEG = () => {
    const chart = chartRef.current.chart;
    if (chart) {
      chart.exportChart({
        type: "image/jpeg",
        filename: "favorite-company-chart",
      });
    } else {
      console.error("Chart instance not found");
    }
  };

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        ref={chartRef}
      />
    </div>
  );
};

export default MyAreaChart;
