import React, { memo } from "react";
import { FormControl, Grid, MenuItem, Select, TextField } from "@mui/material";
import "./SelectComponent.scss";
import {
  TradeOption,
  DataTypeOptions,
  TradeOption_EXPORT,
  TradeOption_IMPORT,
  DataTypeOptions_custom,
  DataTypeOptions_bl,
  valueDropdownArr,
  hsCodeOption,
  groupByOption,
} from "../../utils/DropDownOptions";
import { ButtonComponent } from "../ButtonComponent/Button";
import DateRangePicker from "../DateRangePicker/DateRangePicker";
import MultiSelectDropDown from "../SelectDropDownNewUI/MultiSelectDropDown";
import RefreshIcon from "@mui/icons-material/Refresh";
import SelectDropdownCustom from "./DropDown/index";
import SelectDropDown from "../SelectDropDownNewUI/SelectDropDown";
const SelectComponent = memo(
  ({
    pageTitle,
    setDateRange,
    handelChangeSelect,
    selectTrade,
    dateRange,
    btnName,
    btnIcon,
    currentCountry,
    isBlFlag,
    currentTradeType,
    btnClick,
    isQueryId,
    isExplore,
    favCompany,
    btnShow,
    isMultipleDropdownOption,
    handleIsMultiDropdown,
    isMultipleDropdownSelectedValue,
    isMarketCountry,
    isMarketCompany,
    isMarketProduct,
    isGlobalSearch,
    errorFromMarket,
    errorFromGlobalSearch,
    mirrorData,
  }) => {
    return (
      <Grid id="selectComponent-forExplore" maxWidth="xxl">
        <div
          className="selectComponent-container"
          style={{
            display:
              favCompany ||
              isMarketCountry ||
              isMarketCompany ||
              isMarketProduct ||
              isGlobalSearch
                ? "flex"
                : "",
          }}
        >
          <div className="title-div" style={{ marginTop: "20px" }}>
            <h4 className="text set-explore-title">
              <span style={{ fontSize: "23px" }}>{pageTitle?.title}</span>
              <span
                className="badge bg-soft-blue text-blue label-worksapce-name"
                style={{ height: "27px" }}
              >
                {pageTitle?.badgeTitle}
              </span>
            </h4>
          </div>
          <div
            className="select-container-div"
            style={{
              flex:
                favCompany ||
                isMarketCountry ||
                isMarketCompany ||
                isMarketProduct ||
                isGlobalSearch
                  ? ""
                  : "1",
            }}
          >
            {isExplore && (
              <div className="explore-date-container">
                <div className="select-container-item">
                  <label className="select-item-label">DateRange</label>
                  <DateRangePicker
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                  />
                </div>
              </div>
            )}
            <div className="explore-select-container">
              <div className="select-container">
                {!isMarketCountry &&
                  !isMarketCompany &&
                  !isMarketProduct &&
                  !isGlobalSearch &&
                  !mirrorData &&
                  currentCountry !== "SILKROUTE" && (
                    <div className="select-container-item">
                      <label className="select-item-label">DataType</label>
                      {isExplore ? (
                        <SelectDropdownCustom
                          value={selectTrade?.dataType}
                          handleChange={handelChangeSelect}
                          name="dataType"
                          option={
                            isQueryId
                              ? selectTrade?.dataType === "true"
                                ? DataTypeOptions_bl
                                : DataTypeOptions_custom
                              : DataTypeOptions
                          }
                        />
                      ) : (
                        <SelectDropDown
                          name="dataType"
                          value={selectTrade?.dataType}
                          label="DataType"
                          options={
                            isQueryId
                              ? selectTrade?.dataType === "true"
                                ? DataTypeOptions_bl
                                : DataTypeOptions_custom
                              : DataTypeOptions
                          }
                          onChange={handelChangeSelect}
                          width={"8vw"}
                          styleIndex={{ isEnabled: false, value: "0" }}
                        />
                      )}
                    </div>
                  )}
                {!mirrorData && currentCountry !== "SILKROUTE" && (
                  <div className="select-container-item">
                    <label className="select-item-label">TradeType</label>
                    {isExplore ? (
                      <SelectDropdownCustom
                        value={selectTrade?.tradeType}
                        handleChange={handelChangeSelect}
                        name="tradeType"
                        option={
                          isQueryId
                            ? selectTrade?.tradeType === "IMPORT"
                              ? TradeOption_IMPORT
                              : TradeOption_EXPORT
                            : TradeOption
                        }
                      />
                    ) : (
                      <SelectDropDown
                        name="tradeType"
                        value={selectTrade?.tradeType}
                        label="TradeType"
                        options={
                          isQueryId
                            ? selectTrade?.tradeType === "IMPORT"
                              ? TradeOption_IMPORT
                              : TradeOption_EXPORT
                            : TradeOption
                        }
                        onChange={handelChangeSelect}
                        width={"6vw"}
                        styleIndex={{ isEnabled: false, value: "0" }}
                      />
                    )}
                  </div>
                )}
                {isGlobalSearch && (
                  <div className="select-container-item">
                    <label className="select-item-label">Search On</label>

                    <FormControl
                      sx={{ m: 1, margin: "0px 8px 8px 8px" }}
                      size="small"
                      className="select-search-On"
                    >
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={selectTrade?.selectedValue}
                        onChange={handelChangeSelect}
                        name="selectedValue"
                      >
                        {selectTrade?.selectOption?.length > 0 &&
                          selectTrade?.selectOption?.map((e, index) => (
                            <MenuItem
                              alias={e?.alias}
                              query={e?.query}
                              identifier={e?.identifier}
                              template={e?.template}
                              value={e?.field}
                              key={index}
                            >
                              {e.field.split("_").join(" ")}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>
                )}
                {isGlobalSearch && (
                  <div className="select-container-item">
                    <label className="select-item-label">
                      Choose Search Term
                    </label>
                    <TextField
                      id="outlined-multiline-flexible"
                      className="search-form-control search-async"
                      style={{
                        height: "41px",
                        display: "block",
                        width: "400px !important",
                      }}
                      placeholder="Search..."
                      value={selectTrade?.searchValue}
                      name="searchValue"
                      onChange={handelChangeSelect}
                      size="small"
                      sx={{ margin: "0px 8px 8px 8px" }}
                    />
                    {errorFromGlobalSearch && (
                      <span
                        style={{
                          color: "red",
                          marginLeft: "10px",
                          position: "absolute",
                          top: "74px",
                        }}
                      >
                        Select Search Term
                      </span>
                    )}
                  </div>
                )}
                {!isGlobalSearch &&
                  !mirrorData &&
                  currentCountry !== "SILKROUTE" && (
                    <div className="select-container-item">
                      <label className="select-item-label">Country</label>
                      {isExplore ? (
                        <SelectDropdownCustom
                          value={selectTrade?.country}
                          handleChange={handelChangeSelect}
                          name="country"
                          option={selectTrade?.CountryOptions}
                        />
                      ) : (
                        <SelectDropDown
                          name="country"
                          value={selectTrade?.country}
                          label="Country"
                          options={selectTrade?.CountryOptions}
                          onChange={handelChangeSelect}
                          width={"10vw"}
                          styleIndex={{ isEnabled: false, value: "0" }}
                        />
                      )}
                    </div>
                  )}

                {isMarketCountry && (
                  <>
                    <div className="select-container-item">
                      <span
                        style={{
                          marginTop: "15px",
                          fontSize: "23px",
                          color: "#005d91",
                          fontWeight: "bold",
                          marginRight: "15px",
                        }}
                      >
                        VS
                      </span>
                    </div>
                    {!mirrorData && (
                      <div className="select-container-item">
                        <label className="select-item-label">Country</label>
                        <SelectDropDown
                          name="compareCountry"
                          value={selectTrade?.compareCountry}
                          label="compareCountry"
                          options={selectTrade?.compareCountryOptions}
                          onChange={handelChangeSelect}
                          width={"10vw"}
                          styleIndex={{ isEnabled: false, value: "0" }}
                        />
                        {errorFromMarket && (
                          <span
                            style={{
                              color: "red",
                              marginLeft: "10px",
                              position: "absolute",
                              top: "74px",
                            }}
                          >
                            Select Country
                          </span>
                        )}
                      </div>
                    )}
                  </>
                )}

                {(isMarketCompany || isMarketProduct) && (
                  <div className="select-container-item">
                    <label className="select-item-label">Value</label>
                    <SelectDropDown
                      name="value"
                      value={selectTrade?.value}
                      label="value"
                      options={valueDropdownArr}
                      onChange={handelChangeSelect}
                      width={"10vw"}
                      styleIndex={{ isEnabled: false, value: "0" }}
                    />
                  </div>
                )}
                {isMarketProduct && (
                  <div className="select-container-item">
                    <label className="select-item-label">HsCode</label>
                    <SelectDropDown
                      name="hsCode"
                      value={selectTrade?.hsCode}
                      label="HsCode"
                      options={hsCodeOption}
                      onChange={handelChangeSelect}
                      width={"6vw"}
                      styleIndex={{ isEnabled: false, value: "0" }}
                    />
                  </div>
                )}
                {isMarketProduct && (
                  <div className="select-container-item">
                    <label className="select-item-label">Group By</label>
                    <SelectDropDown
                      name="groupBy"
                      value={selectTrade?.groupBy}
                      label="Group By"
                      options={groupByOption}
                      onChange={handelChangeSelect}
                      width={"6vw"}
                      styleIndex={{ isEnabled: false, value: "0" }}
                    />
                  </div>
                )}
                {favCompany && (
                  <div className="select-container-item">
                    <label className="select-item-label">
                      Favourite Company
                    </label>
                    <MultiSelectDropDown
                      value={isMultipleDropdownSelectedValue}
                      onChange={handleIsMultiDropdown}
                      options={isMultipleDropdownOption}
                      Placeholder={"Selecte Your Fav Company"}
                      width={300}
                    />
                  </div>
                )}
                {btnShow && !mirrorData && currentCountry !== "SILKROUTE" && (
                  <div
                    className="setTrade-btn-container"
                    style={{ marginTop: "10px" }}
                  >
                    <ButtonComponent
                      btnIcon={btnIcon}
                      name={btnName}
                      btnClick={btnClick}
                      style={{
                        margin: "14px 0px 0px 8px ",
                        maxWidth: "7.9rem",
                      }}
                    />
                    {(isMarketCountry ||
                      isMarketCompany ||
                      isMarketProduct ||
                      isGlobalSearch) && (
                      <ButtonComponent
                        btnIcon={RefreshIcon}
                        name=""
                        btnClick={() => window.location.reload()}
                        style={{ margin: "14px 0px 0px 8px " }}
                      />
                    )}
                  </div>
                )}
              </div>
              {isExplore &&
                !mirrorData &&
                currentCountry !== "SILKROUTE" &&
                (currentTradeType !== selectTrade?.tradeType ||
                  isBlFlag !== selectTrade?.dataType ||
                  currentCountry !== selectTrade?.country) && (
                  <div>
                    <span className="set-red-setTrade-alert">
                      Please set trade to apply your changes
                    </span>
                  </div>
                )}
            </div>
          </div>
        </div>
      </Grid>
    );
  }
);

export default SelectComponent;
