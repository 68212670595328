import React, { useState, useEffect } from "react";
import moment from "moment";
import { Box } from "@mui/material";
import "moment-timezone";
import "./iframeDashboard.scss";
import { ButtonComponent } from "../ButtonComponent/Button";

export default function Iframe(props) {
  const [iframeSrc, setIframeSrc] = useState("");
  const [compareIframeSrc, setCompareIframeSrc] = useState("");

  const [random, setRandom] = useState(0);
  const [randomCompare, setRandomCompare] = useState(1);
  const term = props?.fieldTerm ? props?.fieldTerm : props?.currentTradeType === "IMPORT" ? "IMP_DATE" : "EXP_DATE";

  const dateValues = props?.storeRisonQuery
    .slice(props?.storeRisonQuery.indexOf(term) + term.length + 2, props?.storeRisonQuery.lastIndexOf("Z") + 2)
    .split(",");

  const startDateFromQuery = dateValues[0].slice(dateValues[0].indexOf("'") + 1, dateValues[0].indexOf("Z'") + 1);
  const endDateFromQuery = dateValues[1].slice(dateValues[0].indexOf("'") + 1, dateValues[0].indexOf("Z'") + 1);

  const compareDateValues = props?.compareRisonQuery
    .slice(props?.compareRisonQuery.indexOf(term) + term.length + 2, props?.compareRisonQuery.lastIndexOf("Z") + 2)
    .split(",");

  const compareStartDateFromQuery = compareDateValues[0].slice(compareDateValues[0].indexOf("'") + 1, compareDateValues[0].indexOf("Z'") + 1);
  const compareEndDateFromQuery = compareDateValues[1].slice(compareDateValues[0].indexOf("'") + 1, compareDateValues[0].indexOf("Z'") + 1);


  useEffect(() => {
    let src;
    let compareSrc;
    let RisonQuery = props?.storeRisonQuery;
    src = `https://web.eximpedia.app:9400/app/dashboards?auth_provider_hint=anonymous1#/view/${props?.view}?embed=true&_a=(filters:())&_g=(filters%3A!(('$state':(store:appState),meta:(alias:!n),${props.storeRisonQuery}))%2CrefreshInterval%3A(pause%3A!t%2Cvalue%3A0)%2Ctime%3A(from:'${startDateFromQuery}',to:'${endDateFromQuery}'))&hide-filter-bar=true`;
    compareSrc = `https://web.eximpedia.app:9400/app/dashboards?auth_provider_hint=anonymous1#/view/${props?.view}?embed=true&_a=(filters:())&_g=(filters%3A!(('$state':(store:appState),meta:(alias:!n),${props.compareRisonQuery}))%2CrefreshInterval%3A(pause%3A!t%2Cvalue%3A0)%2Ctime%3A(from:'${compareStartDateFromQuery}',to:'${compareEndDateFromQuery}'))&hide-filter-bar=true`;
    setRandom(random + 1);
    setRandomCompare(randomCompare + 1);
    setIframeSrc(src);
    setCompareIframeSrc(compareSrc);
    setRandom(random + 1);
    setRandomCompare(randomCompare + 1);
  }, []);

  return (
    <div style={{ padding: "10px" }}>
      <ButtonComponent
        name={"Records"}
        style={{ width: "120px", height: "36px", marginBottom: "10px" }}
        btnClick={() => {
          props?.setIsRecordBtnChecked(true);
        }}
      />
      <Box style={{ display: "flex" }}>
        <iframe key={random} src={iframeSrc} style={{ height: "calc(100vh - 4px)", width: "50%", boxSizing: "border-box" }} title="dashboardIframe" />
        <iframe
          key={randomCompare}
          src={compareIframeSrc}
          style={{ height: "calc(100vh - 4px)", width: "50%", boxSizing: "border-box" }}
          title="dashboardIframe"
        />
      </Box>
    </div>
  );
}
