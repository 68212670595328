"use strict";

import React, { useState } from "react";
import { useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { CircularProgress } from "@mui/material";
import nopreview from "../../../Asset/images/no_preview.png";
import { isLineChartValidation } from "../../../utils/ChartsValidation";
import millify from "millify";

const LineChart = ({ data, xkey, ykey, isLoaderShowing, recordLength, handleGetData, isBox }) => {
  const [noPreview, setNoPreview] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [options, setOptions] = useState({});
  const [allData, setAllData] = useState([]);
  useEffect(() => {
    if (isLineChartValidation(xkey, ykey)) {
      setLoading(true);
      const dataShow = [];
      const dataLable = [];
      data.forEach((elem) => {
        const name = elem[xkey];
        if (!dataLable.includes(name)) {
          dataLable.push(name);
        }
      });

      dataLable.map((ser) => {
        const newData = data
          .filter((el) => {
            if (el[xkey] === ser) {
              return el;
            }
          })
          .map((e) => e[ykey]);
        const sum = newData.reduce((total, item) => total + item);
        dataShow.push([ser, sum]);
      });
      setAllData([...dataShow]);
    } else {
      setNoPreview(true);
      setAllData([]);
    }
  }, [xkey, ykey]);

  useEffect(() => {
    if (allData?.length > 0 && isLineChartValidation(xkey, ykey)) {
      let _pp = [];
      if (recordLength?.type === "top") {
        _pp = allData?.slice(0, Number(recordLength?.key));
      } else if (recordLength?.type === "bottom") {
        _pp = allData?.slice(Number(recordLength?.key));
      }
      setTimeout(() => {
        setNoPreview(false);
        setOptions({
          chart: {
            zoomType: "xy",
          },
          tooltip: {
            backgroundColor: {
              linearGradient: [0, 0, 0, 60],
              stops: [
                [0, "#FFFFFF"],
                [1, "#E0E0E0"],
              ],
            },
            borderWidth: 1,
            borderColor: "#AAA",
            formatter: function () {
              var value = this.y >= 1 ? millify(this.y) : this.y;
              return `${this.point.name} <br /> ${this.series.name} : ${value}`;
            },
          },
          xAxis: {
            type: "category",
          },
          yAxis: {
            title: {
              text: ykey?.split("_")?.join(" "),
            },
          },
          autoSize: true,
          title: {
            text: "",
          },
          subtitle: {
            text: "",
          },
          series: {
            name: xkey?.split("_")?.join(" "),
            data: _pp,
          },
        });
        setLoading(false);
      }, 500);
      let tableHeader = [
        {
          title: xkey,
          val: xkey,
        },
        {
          title: ykey,
          val: ykey,
        },
      ];
      let tableData = _pp.map((elem) => {
        return {
          [xkey]: elem[0],
          [ykey]: elem[1],
        };
      });
      if (isBox) {
        handleGetData({
          headers: tableHeader,
          data: tableData,
        });
      }
    } else {
      setLoading(false);
      setNoPreview(true);
    }
  }, [recordLength, allData]);
  return (
    <div style={{ position: "relative", width: "100%", height: "100%" }}>
      {isLoading && isLoaderShowing && (
        <CircularProgress
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            zIndex: "1000",
          }}
        />
      )}
      {noPreview && isLoaderShowing ? (
        <img
          src={nopreview}
          alt=""
          style={{
            width: "60%",
            height: "100%",
            margin: "0 auto",
            display: "block",
            objectFit: "contain",
          }}
        />
      ) : (
        <HighchartsReact highcharts={Highcharts} options={options} />
      )}
    </div>
  );
};

export default LineChart;
