import React, { useMemo } from "react";
import { Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { NotificationActions, SupportMail } from "./NotificationComponents"; // Adjust the path if needed
import { popConfigs } from "../popConfigs";
import { capitalizeSentences } from "../../../utils/CommanFunction";

const IMPORTANT_PLAN_TYPE = ["Plan Expire Soon", "Plan Expired"];

const MultiplePopUp = React.memo(
  ({
    logo,
    notifications,
    handlePopUpUpdate,
    highlightEffect,
    pulseEffect,
    dismissNotification,
    handleRenewBtnAction,
  }) => {
    const renderedNotifications = useMemo(() => {
      return (
        notifications?.length &&
        notifications.map((value) => {
          const popConfig = popConfigs[value?.popupNotificationType];
          const importantBadge = IMPORTANT_PLAN_TYPE.includes(
            value?.popupNotificationType
          );
          return (
            <div key={value?._id} className="notification-item">
              <div className="notification-content">
                <Box className="custom-pop-header">{popConfig?.icon}</Box>
                <div className="notification-details">
                  <div className="notification-header">
                    <span className="notification-title">
                      {capitalizeSentences(value?.heading ?? popConfig?.title)}
                    </span>
                    {importantBadge && (
                      <span
                        className={`important-badge ${
                          value?.popupNotificationType === "Plan Expire Soon"
                            ? "important-badge--orange"
                            : "important-badge--red"
                        }`}
                      >
                        Important
                      </span>
                    )}
                  </div>
                  <p className="notification-description">
                    {capitalizeSentences(value?.description)}
                  </p>
                  <div className="notification-footer">
                    <NotificationActions
                      primaryButton={popConfig?.primaryButton}
                      handlePopUpUpdate={handlePopUpUpdate}
                      popValue={value}
                      buttonSize="small"
                      fontSize="12px"
                      isSingleNotification={false}
                      handleRenewBtnAction={() => handleRenewBtnAction(value)}
                    />
                  </div>
                </div>
                <IconButton
                  size="small"
                  onClick={() => dismissNotification(value)}
                  className="dismiss-button"
                >
                  <CloseIcon sx={{ fontSize: "20px" }} />
                </IconButton>
              </div>
            </div>
          );
        })
      );
    }, [notifications, popConfigs, handlePopUpUpdate, dismissNotification]);

    return (
      <Box
        className="multi-pop-modal"
        sx={{
          animation: highlightEffect ? `${pulseEffect} 0.3s ease` : "none",
          transition: "transform 0.3s, box-shadow 0.3s",
        }}
      >
        <div className="logo-container">
          <img
            src={logo}
            width="100%"
            style={{ marginLeft: "1px" }}
            alt="Logo"
          />
        </div>
        <Box className="multi-modal-body-wrapper">
          {renderedNotifications}
          <SupportMail className="multi-popup-mail" />
        </Box>
      </Box>
    );
  }
);

export default MultiplePopUp;
