import React, { memo } from 'react';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}


const MultiSelectDropDown = memo(({ value, onChange, Placeholder, options, width }) => {
    const theme = useTheme();
    return (
        <div>
            <FormControl sx={{ m: 1, width: width, margin: "0px 8px 8px 8px" }} size="small" >
                <Select
                    multiple
                    displayEmpty
                    value={value}
                    onChange={onChange}
                    input={<OutlinedInput />}
                    renderValue={(selected) => {
                        if (selected?.length === 0) {
                            return <em>{Placeholder}</em>;
                        }

                        return selected.join(', ');
                    }}
                    MenuProps={MenuProps}
                    inputProps={{ 'aria-label': 'Without label' }}
                >

                    {options.map((ele) => (
                        <MenuItem key={ele?.title} value={ele?.title} style={getStyles(ele?.country, value, theme)}>
                            {ele?.title}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
});

export default MultiSelectDropDown;