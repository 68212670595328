import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import millify from "millify";
import "./ChartBoxTable.scss";
export default function ChartBoxTable({ tableData }) {
  return (
    <TableContainer component={Paper} style={{ maxHeight: "550px", overflow: "scroll" }} id="chart-Table">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {tableData &&
              tableData?.headers &&
              tableData?.headers?.length > 0 &&
              tableData?.headers.map((e, index) => {
                return <TableCell className={index % 2 === 0 ? "chart-table-header-1" : "chart-table-header"}>{e?.val?.split("_")?.join(" ")}</TableCell>;
              })}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData &&
            tableData?.data &&
            tableData?.data?.length > 0 &&
            tableData?.data.map((rowVal, i) => {
              return (
                <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }} key={i}>
                  {tableData &&
                    tableData?.headers &&
                    tableData?.headers?.length > 0 &&
                    tableData?.headers.map((col, index) => {
                      const isNumber = !isNaN(rowVal[col?.title]);
                      const value = rowVal[col?.title];
                      return (
                        <TableCell
                          component="th"
                          scope="row"
                          key={index}
                          style={{ backgroundColor: i % 2 === 0 ? "#FFFFFF" : "#ECF0F2" }}
                          className={index % 2 === 0 ? "" : "chart-row"}
                        >
                          {isNumber ? (millify(value)) : value}
                        </TableCell>
                      );
                    })}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
