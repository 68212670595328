import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import ErrorPopup from "../../Component/ErrorLoader/ErrorPopup";

import ExploreCountry from "./country";

const ExploreCountryWithError = () => {
  function ErrorHandler({ error }) {
    return <div role="alert">{error && <ErrorPopup errorMsg={"Something Went Wrong , Please Login Again"} open={error} />}</div>;
  }
  return (
    <>
      <ErrorBoundary FallbackComponent={ErrorHandler}>
        <ExploreCountry />
      </ErrorBoundary>
    </>
  );
};

export default ExploreCountryWithError;
