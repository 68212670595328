import React, { memo } from "react";
import "./summary.scss";
import { useEffect } from "react";
import { useState } from "react";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import Filter1Icon from "@mui/icons-material/Filter1";
import StorageIcon from "@mui/icons-material/Storage";
import PersonIcon from "@mui/icons-material/Person";
const Summary = memo(({ data, isFromExplore }) => {
  const [summaryData, setSummaryData] = useState([]);
  useEffect(() => {
    const summary = [
      {
        image: <FormatListBulletedIcon style={{ fontSize: "17px" }} />,
        text: "Records",
        data: data?.SUMMARY_RECORDS,
      },
      {
        image: <Filter1Icon style={{ fontSize: "17px" }} />,
        text: "HS Codes",
        data: data?.SUMMARY_HS_CODE,
      },
      {
        image: <StorageIcon style={{ fontSize: "17px" }} />,
        text: "Shipments",
        data: data?.SUMMARY_SHIPMENTS,
      },
      {
        image: <PersonIcon style={{ fontSize: "17px" }} />,
        text: "Buyers",
        data: data?.SUMMARY_BUYERS,
      },
      {
        image: <PersonIcon style={{ fontSize: "17px" }} />,
        text: "Sellers",
        data: data?.SUMMARY_SELLERS,
      },
    ];
    setSummaryData(summary);
  }, [data]);
  return (
    <>
      {summaryData?.length &&
        summaryData.map((e, index) => {
          return (
            <>
              <div key={index} className="main-container-summary">
                <div className="box-top">
                  <span style={{ marginRight: "5px" }}>{e.image}</span>
                  <span title={e?.text} className="ellipsisNameWithWidth">
                    {e?.text}
                  </span>
                </div>
                <span className="line"></span>
                <div className="box-down">
                  <span className="notranslate">{e?.data}</span>
                </div>
              </div>
            </>
          );
        })}
      {/* {!isFromExplore && (
        <div className="summary-container">
          {!isFromExplore &&
            summaryData?.length &&
            summaryData.map((e, index) => {
              return (
                <>
                  <div key={index} className="main-container-workSpace">
                    <div className="box-top-workSpace">
                      <span style={{ marginRight: "5px" }}>{e.image}</span>
                      <span>{e?.text}</span>
                    </div>
                    <div className="box-down-workSpace">
                      <div className="line-workSpace"></div>
                      <span className="notranslate">{e?.data}</span>
                    </div>
                  </div>
                </>
              );
            })}
        </div>
      )} */}
    </>
  );
});

export default Summary;
