import React from "react";
import "./Recommendations.scss";
import RecommendIcon from "@mui/icons-material/Recommend";
import { Box, Card, Typography } from "@mui/material";
import { useEffect, useState } from "react";
const fields = [
  "PRODUCT_DESCRIPTION",
  "PRODUCT_DESCRIPTION_EN",
  "CUSTOMS_DESCRIPTION",
  "HS_CODE_DESCRIPTION",
  "HS_DESCRIPTION",
  "HS_CODE",
  "IMPORTER_NAME",
  "SHIPPER_NAME",
  "CONSIGNEE_NAME",
  "EXPORTER_NAME_EN",
  "EXPORTER_NAME",
  "BUYER_NAME_EN",
  "STD_EXPORTER_NAME",
  "BUYER_NAME",
  "STD_BUYER_NAME",
  "EXPORTER_NAME_VN",
  "IMPORTER_NAME",
  "EXPORTER_NAME_AND_ADDRESS",
  "SUPPLIER_NAME",
  "SUPPLIER_NAME_EN",
  "IMPORTER_NAME_EN",
  "STD_IMPORTER_NAME",
  "STD_SUPPLIER_NAME",
  "IMPORTER_NAME_VN",
  "CONSIGNEE_NAME_NATIVE",
  "SHIPPER_NAME_NATIVE",
];
const Recommendations = ({
  recommandationArr,
  setSelectedOptionForSearchTerm,
  setSearchField,
  searchField,
  onSearchClick,
  loadOptions,
  isShowRecommendationLoader,
  setPage,
  relatedSearchFromFilter,
}) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [recommandationArrValue, setRecommandationArrValue] = useState([]);
  const [filterBuyerSeller, setFilterBuyerSeller] = useState(null);

  const handleRecommendationClick = (
    e,
    value,
    recommendValue,
    identifierOfClickedRecomandation
  ) => {
    setPage(0);
    setSelectedItem(null);
    setSelectedOptionForSearchTerm(null);
    let data = [];
    if (value?.includes("##$$##")) {
      data = searchField?.searchOption?.find(
        (item) => item?.field === value?.split("##$$##")[0].trim()
      );
    } else {
      // data = searchField?.searchOption?.find(
      //   (item) => item?.field === searchField?.getSelectExploreField
      // );
      data = searchField?.searchOption?.find(
        (item) => item?.identifier === identifierOfClickedRecomandation
      );
    }
    if (data?.query === "CHOOSE" || fields.includes(data?.field)) {
      let obj = {
        _id: recommendValue,
      };

      setSelectedItem([obj]);
      setSelectedOptionForSearchTerm([obj]);
      loadOptions(recommendValue, "search", "");
    } else {
      let obj = {
        _id: recommendValue,
      };

      setSelectedItem([obj]);
      setSelectedOptionForSearchTerm(obj);
      loadOptions(recommendValue, "search", "");
    }
    let obj = {
      getSelectExploreField: data?.field,
      getValuesForSelectDropdown: data,
      searchOption: searchField?.searchOption,
      operatorOptions: searchField?.operatorOptions,
      selectedOperator: searchField?.selectedOperator,
    };
    setSearchField(obj);
  };

  useEffect(() => {
    if (selectedItem !== null) {
      onSearchClick();
    }
  }, [selectedItem]);
  useEffect(() => {
    setFilterBuyerSeller(relatedSearchFromFilter);
  }, []);

  useEffect(() => {
    const searchFilters = [
      {
        identifier: "SEARCH_SELLER",
        searchTerm: filterBuyerSeller?.seller,
      },
      {
        identifier: "SEARCH_BUYER",
        searchTerm: filterBuyerSeller?.buyer,
      },
    ];
    searchFilters?.forEach(({ identifier, searchTerm }) => {
      if (searchTerm) {
        recommandationArr.unshift({ identifier, searchTerm });
      }
    });
    let options = [];
    searchField?.searchOption?.length > 0 &&
      searchField?.searchOption?.forEach((e) => {
        options.push(e.field);
      });
    let arr = recommandationArr?.flat(1).filter((e, index) => {
      if (e?.searchTerm?.includes("##$$##")) {
        if (options.includes(e.split("##$$##")[0].trim())) {
          return e?.searchTerm;
        }
      } else {
        return e?.searchTerm;
      }
    });
    setRecommandationArrValue(arr);
  }, [recommandationArr, relatedSearchFromFilter]);

  const card = (value) => {
    const recommendValue = value?.searchTerm?.includes("##$$##")
      ? value?.searchTerm?.split("##$$##")[1].trim()
      : value?.searchTerm;
    return (
      <React.Fragment>
        <button
          className="recommendation"
          onClick={(e) => {
            handleRecommendationClick(
              e,
              value?.searchTerm,
              recommendValue,
              value?.identifier
            );
          }}
        >
          <Typography
            className="recommendation-innner"
            color="text.secondary"
            gutterBottom
            title={
              value?.searchTerm?.includes("##$$##")
                ? value?.searchTerm?.split("##$$##")[1].trim()
                : value?.searchTerm
            }
          >
            {value?.searchTerm?.includes("##$$##")
              ? value?.searchTerm?.split("##$$##")[1].trim()
              : value?.searchTerm}
          </Typography>
        </button>
      </React.Fragment>
    );
  };

  return (
    <Box id="recommendations">
      <Box sx={{ ml: 1, display: "flex", alignItems: "center" }}>
        <RecommendIcon />
        <span>Related Searches</span>
      </Box>
      <Box className="card-box-recommendation">
        {recommandationArrValue?.length === 0 ? (
          <Typography style={{ marginLeft: "13px", marginTop: "20px" }}>
            No recommendations
          </Typography>
        ) : (
          !isShowRecommendationLoader &&
          recommandationArrValue
            ?.flat(1)
            .slice(0, 3)
            .map((e, index) => (
              <Card variant="outlined" className="card-style" key={index}>
                {card(e)}
              </Card>
            ))
        )}
      </Box>
      {isShowRecommendationLoader && (
        <div className="spinner">
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
        </div>
      )}
    </Box>
  );
};

export default React.memo(Recommendations);
