import PublicIcon from "@mui/icons-material/Public";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CampaignIcon from "@mui/icons-material/Campaign";

export const popConfigs = {
  "New Launches": {
    icon: <AutoAwesomeIcon className="custom-pop-success-icon" />,
    title: "New Launches",
  },
  "New Country": {
    icon: <PublicIcon className="custom-pop-info-icon" />,
    title: "New Country",
  },
  "Plan Expire Soon": {
    icon: <AccessTimeIcon className="custom-pop-warning-icon" />,
    title: "Renewal Reminder",
    primaryButton: "Renew Plan",
  },
  "Plan Expired": {
    icon: <AccessTimeIcon className="custom-pop-error-icon" />,
    title: "Plan Expired - Renewal Overdue",
    primaryButton: "Renew Now",
  },
  "Custom Update": {
    icon: <CampaignIcon className="custom-pop-info-icon" />,
    title: "Important Notification",
  },
};
