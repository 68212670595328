import { TextField } from "@mui/material";
import React, { memo } from "react";

const TextFieldComponent = memo(({ label, name, onChange, value, error, helperText, width }) => {
  return (
    <TextField
      id="outlined-basic standard-error-helper-text"
      label={label}
      variant="outlined"
      className="user-mt-5"
      name={name}
      onChange={onChange}
      value={value}
      error={error}
      helperText={helperText}
      style={{ width: width ? width : "" }}
      required
      size="small"
    />
  );
});

export default TextFieldComponent;
