import React, { memo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import _ from "lodash";
import {
  addConsigneeRequest,
  addFavouriteCompany,
  addFavouriteShipment,
  addViewColumns,
  addWorkspaceRecords,
  deleteSaveQueryData,
  getCompanyDetailData,
  getConsigneeShipmentDetails,
  getCountryNameBasedOnColumn,
  getCountryNameFromCompany,
  getExploreFilter,
  getExploreSearchTermForSearchWithCancelReq,
  getFavouriteCompany,
  getFavouriteShipments,
  getIECSearchData,
  getMirrorAccessCountry,
  getPowerBi,
  getPowerBiDashBoard,
  getRecentSearches,
  getRecommendation,
  getRecommendationByValue,
  getRecordCount,
  getRecordShipmentData,
  getRelatedSearches,
  getSaveQuery,
  getSavedQuery,
  getSortColumns,
  getTaxonomy,
  getTaxonomyCountryList,
  getTaxonomyGlobal,
  getViewColumns,
  onSearchAnalyze,
  onSearchTradeFilters,
  onSearchTradeRecord,
  salesActivityTrack,
  savedQuery,
  templateApproval,
  templateForWorkspace,
  updateFavouriteCompany,
  updateFavouriteShipment,
  updateSavedQuery,
  verifyWorkSpaceExistence,
} from "../../Services/service";
import "./explore.scss";
import { useReactToPrint } from "react-to-print";
import Toast from "../../Component/Toast/toast";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Radio,
  FormControl,
  FormControlLabel,
  RadioGroup,
  OutlinedInput,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  TextField,
  Container,
} from "@mui/material";
import throwError from "../../utils/throwError";
import Loading from "../../Component/SuccessLoader/success";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import CountryShipmentModel from "../../Component/CountryShipmentModal/countryShipmentModel";
import SelectComponent from "../../Component/SelectComponentForExploreNewUI/SelectComponent";
import moment from "moment";
import SearchComponent from "../../Component/SearchComponentNewUI/SearchComponent";
import AdvanceSearchComponent from "../../Component/AdvanceSearchComponentNewUI/AdvanceSearchComponent";
import {
  IsMultiSelectedField,
  IsMultiSelectedFieldForAdvanceSearch,
} from "../../utils/commanConditions";
import Summary from "../../Component/SummaryBarForExploreShipmentNewUI/Summary";
import ErrorLoading from "../../Component/ErrorLoader/error";
import { useRef } from "react";
import Recommendations from "../../Component/RecommendationsNewUI/Recommendations";
import CombineTable from "../../Component/Table/CombineTable";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Filter from "../../Asset/images/filter-white.png";
import {
  ADVANCESEARCHFIELDNAME,
  DATEFORMAT,
  GETDATAFROMURL,
  QUERYTYPE,
  SEARCHTYPE,
  SHIPMENT_RESULT_TYPE_RECORDS,
  DefaultTableHeader,
  SORTINGTYPE,
  FILTER_OPTIONS,
  RESULT_TYPE,
  BUTTON_NAME,
  ADD_USER_SEARCH_IN_SEARCH_OPTIONS,
  DataType,
  defaultValue,
  CloseSearchOption,
  currentCountries,
  MirrorDataPayload,
  ADD_USER_SEARCH_IN_SEARCH_OPTIONS_VALIDATE_FIELD,
  SELECT_OPTIONS_VALIDATE_FIELD,
  silkRoutePayload,
  TABLE_HEADER_RECENT_ACTIVITY,
  POWER_BI_INDIA_IMP_REPORT_DOWNLOAD_OPTIONS,
  POWER_BI_INDIA_EXP_REPORT_DOWNLOAD_OPTIONS,
  CHILD_USER_ROLES,
  FILTER_IMPORTER_EXPORTER_IDENTIFIER,
} from "./Explore.enum";
import {
  Analyzing_data,
  ApprovSuccess_viewColumns,
  Approving_Purchase,
  FetchData,
  LOADING,
  PageLanding_Explore_shipment,
  Preparing_workspace,
  RecordLimit,
  Request_Submitted_Successfully,
  Retrieving_Workspaces,
  Saved_Query,
  Saved_Query_Successfully,
  Searching,
  Select_Workspace,
  Update_Query,
  Update_Query_Successfully,
  Verifying_Workspace_Availability,
  explore_record_limits,
  RemovingDuplicateRecord,
  hsCodeSelectLimit,
  SearchRequireWarning,
  Delete,
  NO_ACCESS_MIRROR_FOR_CHILD,
} from "../../utils/LoadingMsg";
import {
  convertDataIntoLeftAndRightValue,
  packDTParams,
} from "../../utils/CreateRecordAndFilterPayload";
import ErrorData from "../../utils/ErrorHandling";
import {
  appendZeroAtStart,
  createOperator,
  divideDateRange,
  getChildValue,
  getCookie,
  getFilterWidth,
  getMonthName,
  getOperatorNameUsingExpressionType,
  getRecordWidth,
  sanitizeHsCodeDigitBoundaries,
  sortCountryWiseData,
  translateButtonClick,
} from "../../utils/CommanFunction";
import {
  INDIA_EXPORT_COLUMN_NAME,
  INDIA_IMPORT_COLUMN_NAME,
} from "../../IndiaTradeRecords/records";
import {
  KAZAKHSTAN_EXPORT_RECORDS,
  KAZAKHSTAN_IMPORT_RECORDS,
} from "../../kazakhstanTradeRecords/KazakRecords";
import ViewColumnModal from "../../Component/ViewColumnsModalNewUI/ViewColumns";
import ApprovedSuccess from "../../Component/ApprovedSuccessModal/ApprovedSuccess";
import CollapseFilter from "../../Component/CollapseFilterNewUI/CollapseFilter";
import ExploreFilter from "../../Component/CollapseFilterNewUI/Component/ExploreFilter/ExploreFilter";
import AnalyzeMain from "../../Component/AnalyzeFilter/AnalyzeMain";
import ModalComponent from "../../Component/Modal/modal";
import ExploreDataTableModal from "../../Component/ExploreDataTableModal/exploreModal";
import ExploreButtonComponent from "./Component/ExploreButtonComponent";
import { useCallback } from "react";
import PowerBIReport from "../../Component/PowerBiDashBoard/PowerBiDashBoard";
import PercentageLoader from "../../Component/PercentageLoader/LoaderWithPercentage";
import LanguageSharpIcon from "@mui/icons-material/LanguageSharp";
import CountrySelectModal from "../MirrorData/Component/CountrySelectModal";
import RecentUserActivity from "../../Component/RecentUserActivity/RecentUserActivity";
import { Tooltip } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DeleteIcon from "../../Asset/images/delete.png";
import ConfirmationModal from "../../Component/ConfirmationModal/ConfirmationModal";
import { logger } from "../../Loggers/Loggers";
import axios from "axios";
import RequestCompanyModal from "../ExploreCountryNewUI/components/RequestCompanyModal";
import LockOpenIcon from "@mui/icons-material/LockOpen";

// import { FormControl, MenuItem, Select } from "@mui/base";
let pageTitle;
const useStyles = makeStyles({
  modelBodyStyle: {
    color: "#5b6369",
    fontWeight: 600,
  },
});
let advanceSearchDefaultObj = {};
let selectTradeForPayload = "all";
let filterCountryArr = [];
let timeRangeArr = [];
let firstTimeCalled = true;
let _accessTokenForPowerBiReportDownload;

const explore = memo((props) => {
  const currentPath = window.location.pathname;
  const search = useLocation().search;
  const [isRecordBtnChecked, setIsRecordBtnChecked] = useState(true);
  const mirrorData = currentPath === "/consumers/mirrorData" ? true : false;
  const currentCountry = mirrorData
    ? MirrorDataPayload?.country
    : new URLSearchParams(search).get(GETDATAFROMURL?.country);
  if (mirrorData) {
    pageTitle = {
      title: "Mirror",
      badgeTitle: "Data",
    };
  } else if (currentCountry === "SILKROUTE") {
    pageTitle = {
      title: "Explore",
      badgeTitle: "SilkRoute",
    };
  } else {
    pageTitle = {
      title: "Explore",
      badgeTitle: "Shipments",
    };
  }
  const queryId = new URLSearchParams(search).get(GETDATAFROMURL?.id);
  const isFromRecentSearch = new URLSearchParams(search).get(
    "isFromRecentSearch"
  );
  const isQueryId = queryId !== null ? true : false;
  const isBlFlag = mirrorData
    ? "false"
    : new URLSearchParams(search).get(GETDATAFROMURL?.blFlag);
  const currentTradeType = mirrorData
    ? MirrorDataPayload?.tradeType
    : new URLSearchParams(search).get(GETDATAFROMURL?.tradeType);
  const currentCountryCode = mirrorData
    ? MirrorDataPayload?.countryCode
    : new URLSearchParams(search).get(GETDATAFROMURL?.countryCode);
  const isSaveQueryFilterApply = new URLSearchParams(search).get(
    GETDATAFROMURL?.filter
  );
  //favourite company
  const companyColumnName = new URLSearchParams(search).get(
    GETDATAFROMURL?.columnName
  );
  const companyColumnField = new URLSearchParams(search).get(
    GETDATAFROMURL?.columnField
  );
  //globalSearch
  const globalSearch = new URLSearchParams(search)?.get(
    GETDATAFROMURL?.globalSearch
  );
  //dashboardTableSearch
  const isFromDashboard = new URLSearchParams(search)?.get("isFromDashboard");
  const valueSearch = new URLSearchParams(search)?.get("valueSearch");
  const searchOn = new URLSearchParams(search)?.get("searchOn");

  const isColumnName = companyColumnName !== null ? true : false;
  const navigate = useNavigate();
  const [selectTrade, setSelectTrade] = useState({
    tradeType:
      currentTradeType?.toUpperCase() === DataType?.IMPORT
        ? DataType?.IMPORT
        : DataType?.EXPORT,
    dataType: isBlFlag,
    country: currentCountry,
    CountryOptions: [],
  });

  const [loading, setLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState({});
  const [dateRange, setDateRange] = useState({
    minDateForDatePicker: "",
    monthRange: 1,
    maxDateForEndMonth: "",
    endMonthDate: "",
    startMonthDate: "",
  });
  //
  //specification
  const [specificationData, setSpecificationData] = useState({});
  const [exploreShipmentSpecification, setExploreShipmentSpecification] =
    useState({});
  const [dashboardData, setDashboardData] = useState({
    isDashboardAvailable: false,
    dashboardQuery: {},
  });
  const [searchField, setSearchField] = useState({
    getSelectExploreField: "",
    getValuesForSelectDropdown: {},
    searchOption: {},
    operatorOptions: [],
    selectedOperator: {},
  });

  //taxonomy
  const [filteredTaxonomy, setFilteredTaxonomy] = useState({});
  const [reactSelectLoading, setReactSelectLoading] = useState(false);
  const [getSearchOption, setSearchOptionForMain] = useState([]);
  const [inputValues, setInputValues] = useState(null);
  const [getSelectedOptionForSearchTerm, setSelectedOptionForSearchTerm] =
    useState(null);
  const [advanceSearchFieldOptions, setAdvanceSearchFieldOptions] = useState(
    []
  );

  //advanceSearch
  const [advanceSearchComponent, setAdvanceSearchComponent] = useState([]);
  const [reactSelectAdvanceLoading, setReactSelectAdvanceLoading] =
    useState(false);
  const [defaultOptionForSearchState, setDefaultOptionForAdvancedSearch] =
    useState([]);
  const [currentAdvanceSearchCmpIndex, setCurrentAdvanceSearchCmpIndex] =
    useState(0);
  const [emptyErrorAdvanceSearch, setEmptyErrorAdvanceSearch] = useState([]);
  //ref
  const advanceSearchComponentRef = useRef(null);
  const exploreContainerRef = useRef(null);
  const PrintRef = useRef();

  //search
  var onSearchClickFlag = false;
  const [totalLeftSearchCount, setTotalLeftSearchCount] = useState({});
  const [isVisibleData, setVisibleData] = useState(false);
  const SHIPMENT_FIELD_RECORDS_TAG = "RECORDS_TAG";
  const [isDisplayRec, setDisplayRec] = useState(false);
  const [isSearchValueEmpty, setIsSearchValueEmpty] = useState({
    searchTerm: false,
    advancedSearch: false,
  });

  //table
  const [dataTableHeader, setDataTableHeader] = useState([]);
  const [dataTableRowValues, setDataTableRowValues] = useState([]);

  const [accountTableHeader, setAccountTableHeader] = useState([]);
  const [allHeader, setAllHeader] = useState([]);
  const [viewHeader, setViewHeader] = useState({});
  const [allRecord, setAllRecord] = useState([]);
  //paginations
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);
  //error
  const [errorMsg, setErrorMsg] = useState({});
  const [error, setError] = useState(false);

  //summary
  const [summaryData, setSummaryData] = useState(null);
  //view Columns
  const [handleOpenFilterModal, setHandleOpenFilterModal] = useState(false);

  //filter
  const [isFilterLoader, setIsFilterLoader] = useState(false);
  const [headerTitle, setHeaderTitle] = useState("");
  const [getFilterValues, setFilterValue] = useState([]);
  const [getAllFilterValues, setGetAllFilterValues] = useState([]);
  const [isFiltersShown, setIsFiltersShown] = useState(true);
  const [getAllFilterValueArray, setAllFilterValueArray] = useState([]);
  const [collapseAllFilters, setCollapseAllFilters] = useState(true);
  const [allFilterValuesWithCountsArr, setAllFilterValuesWithCountsArr] =
    useState({});
  const [getAllSplitFilters, setGetAllSplitFilters] = useState();
  const [initialAllFilterValues, setInitialAllFilterValues] = useState({});
  const [keyValuePairForTreeview, setPairForTreeView] = useState({});
  const [storeFilteredValues, setStoreFilterValues] = useState({});
  const [lastSelected, setLastSelected] = useState([]);
  const [appliedFilterData, setAppliedFilterData] = useState({});
  const [apply, setApply] = useState({
    HS_CODE: false,
    FILTER_COUNTRY: false,
    FILTER_PORT: false,
    QUANTITY: false,
    PRICE: false,
    MONTH: false,
    REMOVEDUPLICATE: false,
  });
  var removeButtonState = {};
  const [CheckedData, setCheckedData] = useState({});
  const [rangeValue, setRangeValue] = useState({});
  const [getMaxMinRange, setMaxMinRange] = useState({});
  const [selectedQuantityOrPriceValue, setSelectedQuantityOrPrice] = useState(
    {}
  );

  const [importerExporterName, setImporterExporterName] = useState({
    importer: "IMPORTER",
    exporter: "EXPORTER",
  });

  // payload
  const [getSearchValueData, setSearchValueData] = useState({});
  //ApprovedSuccess modal
  const [approvSuccessModalData, setApprovSuccessModalData] = useState({});
  const [approvSuccessModal, setApprovSuccessModal] = useState(false);

  //related search
  const [isShowRecommendationLoader, setShowRecommendationLoader] =
    useState(false);
  const [recommandationArr, setRecommandationArr] = useState([]);
  let relatedSearchFromFilterRef = useRef(null);

  //dashboard
  const [risonQuery, setRisonQuery] = useState("");
  const [isClearFilter, setIsClearFilter] = useState(false);

  //sorting
  const [sortedColumnList, setSortedColumnList] = useState();
  const [descendingAndAscending, setDescendingAndAscending] = useState({});

  //analyze
  const [openAnalyzePage, setOpenAnalyzePage] = useState(false);
  const [analyzeData, setAnalyzeData] = useState([]);
  const [searchPayload, setSearchPayload] = useState({});

  //work space
  const [getNameForWorkSpace, setNameForWorkSpace] = useState("");
  const [isNothingSelected, setIsNothingSelected] = useState(false);
  const [isOpenWorkspaceModal, setIsOpenWorkspaceModal] = useState(false);
  const [workspaceAddType, setWorkspaceAddType] = useState("");
  const [isWorkSpaceNameAlredyExists, setWorkSpaceNameExistOrNot] =
    useState(false);
  const [getNameFromExistingWorkSpace, setNameFromExistingWorkSpace] =
    useState("");
  const [isNameEmpty, setIsNameEmpty] = useState(false);
  const [isNameNotAllowed, setIsNameNotAllowed] = useState(false);
  const [optionsForExistingTemplate, setOptionsForExistingTemplate] = useState(
    []
  );
  const [openModal, setModalForAddingWorkspace] = useState(false);
  const [getTemplateData, setTemplateData] = useState({});
  const [selectedValueRadioButton, setSelectedValueRadioButton] = useState("");
  const [errorMsgForExistingWorkspace, setErrorMsgForExistingWorkspace] =
    useState(false);
  const [countryShipmentModelData, setCountryShipmentModelData] = useState([]);
  const [countryShipmentModel, setCountryShipmentModel] = useState(false);
  const [selectedWorkSpaceShipments, setSelectedWorkSpaceShipments] = useState(
    []
  );

  const [currentSelectedWorkspaceDetails, setCurrentSelectedWorkspaceDetails] =
    useState({});
  const [getSelectedRow, setSelectedRow] = useState([]);
  const classes = useStyles();

  //saveQuery
  const [saveQueryModalOpen, setSaveQueryOpenModal] = useState(false);
  const [saveQueryLimit, setSaveQueryLimit] = useState({});
  const [saveTitleSavedQuery, setSaveTitleForSaveQuery] = useState("");
  const [savedQueryData, setSavedQueryData] = useState({});
  const [intialQueryCalled, setIntialQueryCalled] = useState(
    queryId !== null ? true : false
  );
  const [storeUpdateSavedId, setStoreUpdatedSavedId] = useState("");

  //view icon
  const [isCollapseModelDetails, setIsCollapseModelDetails] = useState({
    companyDetails: true,
    productDetails: true,
    otherDetails: true,
    IECDetails: true,
    consigneeDetails: true,
  });
  const [storeEyeModalRowData, setStoreEyeModalRowData] = useState({});
  const [IECDetails, setIECDetails] = useState([]);
  const [addConsigneeDetailsPayload, setAddConsigneeDetailsPayload] = useState(
    {}
  );
  const [modalHeaderArr, setModalArr] = useState([]);
  const [openCollpaseModal, setOpenCollapseModal] = useState(false);
  const [prevOrNext, setPrevOrNext] = useState(0);
  const [storeRowShipmentArrId, setStoreRowShipmentArrId] = useState([]);
  const [storeFavCompanyName, setStoreFavCompanyName] = useState([]);
  const [consigneeDetails, setConsigneeDetails] = useState({});
  const [collapseTable, setCollapseTable] = useState(true);
  const [storeFavShipmentId, setStoreFavShipmentId] = useState({});
  const [storeFavCompanyId, setStoreFavCompanyId] = useState({});

  const [openToast, setOpenToast] = useState({
    name: "",
    type: "",
    open: false,
    color: "",
    totalSelectedShipment: 0,
    totalSelectedCompany: 0,
    leftShipmentCount: 0,
    leftCompanyCount: 0,
  });
  const [favCompanyOrShipmentLimit, setFavCompanyOrShipmentLimit] = useState({
    title: "",
    msg: "",
  });
  const [isFavCompanyOrShipment, setIsFavCompanyOrShipment] = useState(false);
  //remove duplicate record
  const [isRemoveDuplicateButtonModal, setIsRemoveDuplicateButtonModal] =
    useState(false);
  const [isRemoveDuplicateButton, setIsRemoveDuplicateButton] = useState(false);
  const [ChangeRemoveDuplicateButton, setIsChangeRemoveDuplicateButton] =
    useState(false);

  const removeDuplicateBtnClick = () => {
    setIsChangeRemoveDuplicateButton(!ChangeRemoveDuplicateButton);
    onSearchClick({}, false, isRemoveDuplicateButton);
  };
  //PowerBi dashboard
  const [powerBiDashBoard, setPowerBiDashBoard] = useState({});
  const [dashboardLoading, setDashboardLoading] = useState(false);
  const [powerBiModelOpen, setPowerBiModelOpen] = useState(false);
  const [progress, setProgress] = useState(0);

  //mirrorData country modal
  const [countryModalOpenClose, setCountryModalOpenClose] = useState(false);
  const [allCountryData, setAllCountryData] = useState([]);
  const [importData, setImportData] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [eximData, setEximData] = useState([]);
  const [tradeTypeForCountryModal, setTradeTypeForCountryModal] =
    useState("all");
  const [selectedCountries, setSelectedCountries] = useState([]);
  const latestSelectedCountries = useRef(selectedCountries);
  const [regionWiseCountryData, setRegionWiseCountryData] = useState({
    Asia: [],
    Africa: [],
    Central_America: [],
    Europe: [],
    North_America: [],
    South_Africa: [],
    South_America: [],
  });
  const [searchQueryForCountryModal, setSearchQueryForCountryModal] =
    useState("");
  const [isSearchCountryTrue, setIsSearchCountryTrue] = useState(false);
  const [showSelectedCountryModal, setShowSelectedCountryModal] =
    useState(false);
  const [tooltipData, setTooltipData] = useState(null);
  const [showClass, setShowClass] = useState(false);
  const [recordIdForClickedRow, setRecordIdForClickedRow] = useState();
  const [mirrorAccessCountryList, setMirrorAccessCountryList] = useState([]);
  const [mirrorCountryData, setMirrorCountryData] = useState({});

  useEffect(() => {
    latestSelectedCountries.current = selectedCountries;
  }, [selectedCountries]);
  useEffect(() => {
    if (latestSelectedCountries.current?.length <= 0) {
      selectTradeForPayload = "all";
      setTradeTypeForCountryModal("all");
    }
  }, [selectedCountries, latestSelectedCountries.current]);

  //recent User Activity
  const [recentUserActivityData, setRecentUserActivityData] = useState([]);
  const [showRecentActivities, setShowRecentActivities] = useState(true);
  const [recentUserActivityQuery, setRecentUserActivityQuery] = useState([]);
  const [callForceFully, setCallForceFully] = useState(false);

  //powerbi report download
  const [powerBiReportPageName, setPowerBiReportPageName] =
    useState("General Analysis");

  // request for country in explore
  const [requestForCountryModal, setRequestForCountryModal] = useState(false);

  const manageDateRange = (dateObj, globalSearchDataObj) => {
    const dataStartDate = dateObj?.data_start_date;
    const userAccessStartDate = dateObj?.userConstraints?.start_date;
    const dataEndDate = dateObj?.data_end_date;
    const userAccessEndDate = dateObj?.userConstraints?.end_date;
    const obj = {
      startMonthDate: moment(
        dataEndDate < userAccessEndDate ? dataEndDate : userAccessEndDate
      )
        .subtract(1, "months")
        .format(DATEFORMAT?.format),
      endMonthDate: moment(
        dataEndDate < userAccessEndDate ? dataEndDate : userAccessEndDate
      ).format(DATEFORMAT?.format),
      maxDateForEndMonth: moment(
        dataEndDate < userAccessEndDate ? dataEndDate : userAccessEndDate
      ).format(DATEFORMAT?.format),
      monthRange: 1,
      minDateForDatePicker: moment(
        dataStartDate > userAccessStartDate
          ? dataStartDate
          : userAccessStartDate
      ).format(DATEFORMAT?.format),
    };
    if (globalSearch) {
      obj.startMonthDate = globalSearchDataObj?.startDate;
      obj.endMonthDate = globalSearchDataObj?.endDate;
      obj.monthRange = globalSearchDataObj?.monthRange;
    }
    setDateRange(obj);
  };

  const getTaxonomyAndSpecificationData = async () => {
    if (isQueryId) {
      setShowRecentActivities(false);
    }
    let taxonomyPayload;
    if (mirrorData) {
      taxonomyPayload = {
        currentTradeType: MirrorDataPayload?.tradeType,
        currentCountry: MirrorDataPayload?.country,
        countryCode: MirrorDataPayload?.countryCode,
      };
    } else if (currentCountry === "SILKROUTE") {
      taxonomyPayload = {
        currentTradeType: silkRoutePayload?.tradeType,
        currentCountry: silkRoutePayload?.country,
      };
    } else {
      taxonomyPayload = {
        currentTradeType: selectTrade?.tradeType,
        currentCountry: currentCountry,
      };
    }
    let recordShipmentPayload;
    if (mirrorData) {
      recordShipmentPayload = {
        tradeType: MirrorDataPayload?.tradeType,
        countryCode: MirrorDataPayload?.countryCode,
        country: MirrorDataPayload?.country,
        bl_flag: isBlFlag,
      };
    } else {
      recordShipmentPayload = {
        tradeType: currentTradeType,
        countryCode: currentCountryCode,
        country: currentCountry,
        bl_flag: isBlFlag,
      };
    }

    let RecentUserSearchPayload = {
      userId: props.CLIENT_USER_ID,
      date_from: moment().subtract(1, "months").format(DATEFORMAT?.format),
      date_to: moment().format(DATEFORMAT?.format),
      emails: [],
      isWorkspaceQuery: false,
      start: 0,
      length: 5,
    };

    const [taxonomy, specification, recentSearches] = await Promise.all([
      getTaxonomy(taxonomyPayload),
      getRecordShipmentData(recordShipmentPayload),
      getRecentSearches(RecentUserSearchPayload),
    ]);
    return {
      taxonomy: taxonomy,
      specification: specification,
      recentSearches: recentSearches,
    };
  };

  const catchBlock = (err) => {
    let errorMsg;
    if (mirrorData === true) {
      errorMsg = "MirrorData :- " + err;
    } else if (isQueryId === true) {
      errorMsg = "SaveQuery :- " + err;
    } else if (isColumnName === true) {
      errorMsg = "Favorite Company :- " + err;
    } else if (globalSearch) {
      errorMsg = "GlobalSearch Explore :- " + err;
    } else {
      errorMsg = "Explore :- " + err;
    }
    logger(errorMsg);
    setLoading(false);
    setError(true);
    setIsFilterLoader(false);
    setErrorMsg({
      title: err?.status === 401 ? "Warning" : "Error",
      content:
        err?.status === 500
          ? ErrorData?.INTERNAL_SERVER
          : err?.status === 401
          ? ErrorData?.SESSION_TIMEOUT
          : ErrorData?.SOMETHING_WENT_WRONG,
    });
  };

  useEffect(() => {
    const width = window.innerWidth;
    if (width < 1070) {
      setIsFiltersShown(false);
    }
    translateButtonClick();
  }, []);

  const categorizeByRegion = (countryData) => {
    const regionData = {
      asia: [],
      africa: [],
      "central america": [],
      europe: [],
      "north america": [],
      "south africa": [],
      "south america": [],
      "eastern europe": [],
      exim: [],
    };

    countryData.forEach((item) => {
      if (regionData[item.region.toLowerCase()]) {
        regionData[item.region.toLowerCase()].push(item);
      }
    });

    return {
      Asia: regionData.asia,
      Africa: regionData.africa,
      Central_America: regionData["central america"],
      Europe: regionData.europe,
      North_America: regionData["north america"],
      South_Africa: regionData["south africa"],
      South_America: regionData["south america"],
      Eastern_europe: regionData["eastern europe"],
      exim: regionData.exim,
    };
  };

  const importerExporterNamesByCountry = (filterImporterExporterNamesArr) => {
    if (filterImporterExporterNamesArr) {
      const { importer, exporter } = filterImporterExporterNamesArr.reduce(
        (acc, e) => {
          if (e?.identifier === "FILTER_IMPORTER") {
            acc.importer = e?.fieldTerm
              ?.replace(/_/g, " ")
              .replace(/ NAME$/, "");
          } else if (e?.identifier === "FILTER_EXPORTER") {
            acc.exporter = e?.fieldTerm
              ?.replace(/_/g, " ")
              .replace(/ NAME$/, "");
          }
          return acc;
        },
        { importer: "", exporter: "" }
      );

      setImporterExporterName({
        ...importerExporterName,
        importer,
        exporter,
      });
    }
  };

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      setLoadingMsg(PageLanding_Explore_shipment);
      if (mirrorData) {
        let _customCountryData;
        try {
          await getTaxonomyGlobal()
            .then((res) => {
              const data = res?.data || [];
              const uniqueCountries = new Set();

              _customCountryData = data?.filter((item) => {
                if (
                  item.bl_flag === false &&
                  !uniqueCountries.has(item.country) &&
                  item.country !== "Vietnam"
                ) {
                  uniqueCountries.add(item.country);
                  return true;
                }
                return false;
              });
              setAllCountryData(_customCountryData);

              const _importData = [];
              const _exportData = [];
              const _eximData = [];
              data?.forEach((item) => {
                if (item.trade === "IMPORT" && !item.bl_flag) {
                  _importData.push(item);
                } else if (item.trade === "EXPORT" && !item.bl_flag) {
                  _exportData.push(item);
                } else if (item.trade === "EXIM" && !item.bl_flag) {
                  _eximData.push(item);
                }
              });
              setImportData(_importData);
              setExportData(_exportData);
              setEximData(_eximData);
              if (_customCountryData?.length > 0) {
                const customCountryByRegion =
                  categorizeByRegion(_customCountryData);
                setRegionWiseCountryData(customCountryByRegion);
              }
            })
            .catch((err) => {
              catchBlock(err);
            });

          await getMirrorAccessCountry({
            user_id: getCookie("user_id"),
          })
            .then((res) => {
              if (res?.status === 200 && res?.data?.res) {
                setMirrorAccessCountryList(res?.data?.res);
                let _availableCountry =
                  res?.data?.res?.map((item) => item?.toLowerCase()) ?? [];
                const removed_bl_country = _customCountryData
                  ?.filter(
                    ({ country }) =>
                      _availableCountry?.length &&
                      country?.toLowerCase() !== "mirror_table" &&
                      _availableCountry?.includes(country?.toLowerCase())
                  )
                  .map((item) => item?.country?.toLowerCase());
                setSelectedCountries(removed_bl_country);
              }
            })
            .catch((err) => {
              catchBlock(err);
            });
        } catch (err) {
          catchBlock(err);
        }
      }

      await getTaxonomyAndSpecificationData()
        .then((e) => {
          if (!isQueryId) {
            setLoading(false);
          }
          if (
            e &&
            e?.taxonomy &&
            e?.specification &&
            e?.taxonomy?.data &&
            e?.recentSearches &&
            e?.recentSearches?.data
          ) {
            if (e?.taxonomy?.data?.length > 0) {
              const currentCountryTaxonomy = e?.taxonomy?.data?.find(
                (e) => e?.bl_flag.toString() === selectTrade?.dataType
              );
              setFilteredTaxonomy(currentCountryTaxonomy);
              const _filterImporterExporterNames =
                currentCountryTaxonomy?.fields?.explore_aggregation?.matchExpressions?.filter(
                  (e) =>
                    FILTER_IMPORTER_EXPORTER_IDENTIFIER?.includes(e?.identifier)
                );
              importerExporterNamesByCountry(_filterImporterExporterNames);
              if (
                currentCountryTaxonomy?.fields &&
                currentCountryTaxonomy?.fields?.advance_search_semantic &&
                currentCountryTaxonomy?.fields?.advance_search_semantic
                  ?.length > 0
              ) {
                let advanceSearchOption =
                  currentCountryTaxonomy?.fields?.advance_search_semantic;
                setAdvanceSearchFieldOptions(advanceSearchOption);

                let operatorOptions = [];
                if (
                  advanceSearchOption[0] &&
                  advanceSearchOption[0]?.searchType
                ) {
                  operatorOptions = createOperator(
                    advanceSearchOption[0]?.searchType
                  );
                }

                advanceSearchDefaultObj = {
                  relation: "AND",
                  field: advanceSearchOption[0]?.field,
                  operatorOptions: operatorOptions,
                  operator:
                    operatorOptions &&
                    operatorOptions?.length > 0 &&
                    operatorOptions[0].type,
                  value: "",
                  query: advanceSearchOption[0]?.query,
                };
              }
            } else {
              if (mirrorData) {
                const role = JSON.parse(localStorage.getItem("userRole"));
                if (role && CHILD_USER_ROLES?.includes(role)) {
                  setError(true);
                  setErrorMsg(NO_ACCESS_MIRROR_FOR_CHILD);
                }
              }
            }
            if (e?.specification && e?.specification?.data?.length > 0) {
              setSpecificationData(e?.specification);
              const specification = e?.specification?.data[0];
              filterCountryData(e?.specification?.countryNames);
              if (
                e?.specification &&
                e?.specification?.data &&
                e?.specification?.data?.length &&
                e?.specification?.data[0]?.dashboard
              ) {
                setDashboardData({
                  isDashboardAvailable: true,
                  dashboardQuery: specification?.dashboard,
                });
              }
              let _searchField = {};
              const dateObj = {
                data_start_date: specification?.data_start_date,
                data_end_date: specification?.data_end_date,
                userConstraints:
                  e?.specification?.userConstraints?.dataAccessRange,
              };
              let globalSearchDataObj = {};
              if (globalSearch) {
                globalSearchDataObj = JSON.parse(
                  localStorage.getItem("globalSearchData")
                );
                manageDateRange(dateObj, globalSearchDataObj);
              } else {
                manageDateRange(dateObj);
              }
              if (!queryId && !globalSearch) {
                if (isColumnName) {
                  _searchField = {
                    getSelectExploreField:
                      specification?.search_field_semantic?.find(
                        (e) => e?.field === companyColumnField
                      ).field,
                    getValuesForSelectDropdown:
                      specification?.search_field_semantic?.find(
                        (e) => e?.field === companyColumnField
                      ),
                    searchOption: specification?.search_field_semantic,
                  };
                  if (companyColumnName) {
                    setSelectedOptionForSearchTerm([
                      {
                        _id: companyColumnName?.split("_")?.join(" "),
                      },
                    ]);
                  }
                } else {
                  _searchField = {
                    getSelectExploreField:
                      specification?.search_field_semantic[0]?.field,
                    getValuesForSelectDropdown:
                      specification?.search_field_semantic[0],
                    searchOption: specification?.search_field_semantic,
                  };
                }
              }

              if (
                specification?.search_field_semantic[0]?.searchType &&
                specification?.search_field_semantic[0]?.searchType?.length > 0
              ) {
                let operator =
                  specification?.search_field_semantic[0]?.searchType;
                const operatorArr =
                  operator?.length > 0 &&
                  operator.map((e) => {
                    return { value: e?.type, title: e?.type };
                  });
                _searchField.operatorOptions = operatorArr;
                _searchField.selectedOperator = operatorArr[0]?.value;
              } else {
                _searchField.operatorOptions = [];
                _searchField.selectedOperator = {};
              }
              if (globalSearch) {
                _searchField = {
                  getSelectExploreField:
                    specification?.search_field_semantic?.find(
                      (e) =>
                        e?.identifier ===
                        globalSearchDataObj?.searchValue?.identifier
                    ).field,
                  getValuesForSelectDropdown:
                    specification?.search_field_semantic?.find(
                      (e) =>
                        e?.identifier ===
                        globalSearchDataObj?.searchValue?.identifier
                    ),
                  searchOption: specification?.search_field_semantic,
                };
                if (
                  globalSearchDataObj?.searchValue &&
                  globalSearchDataObj?.searchValue?.searchType?.length > 0
                ) {
                  let operator = globalSearchDataObj?.searchValue?.searchType;
                  const operatorArr =
                    operator?.length > 0 &&
                    operator.map((e) => {
                      return { value: e?.type, title: e?.type };
                    });
                  _searchField.operatorOptions = operatorArr;
                  _searchField.selectedOperator = operatorArr[0]?.value;
                }
                setSelectedOptionForSearchTerm([
                  {
                    _id: globalSearchDataObj?.ChooseSearchTerm?.split(
                      "_"
                    )?.join(" "),
                  },
                ]);
              }
              setSearchField(_searchField);
              setExploreShipmentSpecification(specification);
            } else {
              if (mirrorData) {
                const role = JSON.parse(localStorage.getItem("userRole"));
                if (role && CHILD_USER_ROLES?.includes(role)) {
                  setError(true);
                  setErrorMsg(NO_ACCESS_MIRROR_FOR_CHILD);
                }
              }
            }
            if (!isFromRecentSearch) {
              // if (e?.recentSearches?.data) {
              //   let _data = e?.recentSearches?.data.slice(0, 10);
              //   setRecentUserActivityData(_data);
              //   _data.forEach((e) => {
              //     let analyseArr = [];
              //     if (e?.query) {
              //       setRecentUserActivityQuery((prevQueries) => [
              //         ...prevQueries,
              //         e?.query,
              //       ]);
              //       JSON.parse(e.query) &&
              //         JSON.parse(e.query).length &&
              //         JSON.parse(e.query).forEach((ele) => {
              //           if (typeof ele.fieldValue === "string") {
              //             analyseArr.push(ele);
              //           } else if (typeof ele.fieldValue === "object") {
              //             if (ele.identifier === "SEARCH_MONTH_RANGE") {
              //               timeRangeArr.push(ele);
              //             }
              //             if (Object.keys(ele.fieldValue).length > 0) {
              //               analyseArr.push(ele);
              //             }
              //           }
              //         });
              //     }
              //     e.query = analyseArr;
              //   });
              //   setDataTableRowValues(_data);
              // }
            }
          }
        })
        .catch((err) => {
          catchBlock(err);
        });
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (
      isQueryId &&
      filteredTaxonomy &&
      Object.keys(filteredTaxonomy).length > 0 &&
      exploreShipmentSpecification &&
      Object.keys(exploreShipmentSpecification).length > 0
    ) {
      setShowRecentActivities(false);
      getSavedQuery(props?.CLIENT_ACCOUNT_ID)
        .then(async (e) => {
          if (e?.status === 500 || e?.status === 401) {
            throw throwError(e);
          } else {
            if (e?.data) {
              const queryData = [...e?.data];
              let filterQuery =
                queryData &&
                queryData.length > 0 &&
                queryData?.find((data) => data?._id === queryId);
              if (filterQuery && Object.keys(filterQuery)?.length > 0) {
                filterQuery &&
                  Object.keys(filterQuery).length > 0 &&
                  setSavedQueryData(filterQuery);
                if (
                  isSaveQueryFilterApply ||
                  isSaveQueryFilterApply === true ||
                  isSaveQueryFilterApply === "true"
                ) {
                  await getSaveQueryFilter(filterQuery);
                }
              }
              setSaveTitleForSaveQuery(
                new URLSearchParams(search).get(GETDATAFROMURL?.title)
              );
              let matchExpression = [...filterQuery?.payload?.matchExpressions];
              let _selectedTrade = { ...selectTrade };
              if (filterQuery?.payload?.country === "bl") {
                const countryDetail = specificationData?.countryNames?.find(
                  (item) =>
                    item?.code_iso_3 === filterQuery?.payload?.countryCode
                );
                _selectedTrade.CountryOptions = [
                  {
                    value: countryDetail?.country.toUpperCase(),
                    title: countryDetail?.country
                      ?.toUpperCase()
                      .split("_")
                      .join(" "),
                  },
                ];
              } else {
                _selectedTrade.CountryOptions = [
                  {
                    value: filterQuery?.payload?.country.toUpperCase(),
                    title: filterQuery?.payload?.country
                      ?.toUpperCase()
                      .split("_")
                      .join(" "),
                  },
                ];
              }
              setSelectTrade(_selectedTrade);
              matchExpression &&
                matchExpression?.length > 0 &&
                matchExpression.forEach((e) => {
                  // in old saveQuery if product is in string then convert into array
                  if (
                    (e?.fieldTerm === defaultValue?.PRODUCT_DESCRIPTION ||
                      e?.fieldTerm === FILTER_OPTIONS?.HS_CODE ||
                      (e?.fieldTerm?.includes("PROD") &&
                        e?.fieldTerm?.includes("DESC"))) &&
                    typeof e?.fieldValue === "string"
                  ) {
                    let newFieldValue = [];
                    newFieldValue.push(e?.fieldValue);
                    e.fieldValue = newFieldValue;
                  }
                });
              let saveCheckedData = { ...CheckedData };
              let _apply = { ...apply };
              const _advance_search_component = [];
              let _matchExpression = [...matchExpression];
              let unit = matchExpression?.find(
                (e) => e?.identifier === FILTER_OPTIONS?.FILTER_UNIT
              );
              let quantity = matchExpression?.find(
                (e) => e?.identifier === FILTER_OPTIONS?.FILTER_QUANTITY
              );
              if (
                unit &&
                quantity &&
                Object.keys(unit).length > 0 &&
                Object.keys(quantity).length > 0
              ) {
                _matchExpression = matchExpression?.map((e) => {
                  if (
                    ![
                      FILTER_OPTIONS?.FILTER_QUANTITY,
                      FILTER_OPTIONS?.FILTER_UNIT,
                    ].includes(e?.identifier)
                  ) {
                    return e;
                  }
                });
                _matchExpression.push(unit);
                _matchExpression.push(quantity);
              }
              let _searchField = { ...searchField };
              let arr = [];

              _matchExpression &&
                _matchExpression?.length > 0 &&
                _matchExpression.map((expression) => {
                  if (
                    expression?.identifier === defaultValue?.SEARCH_MONTH_RANGE
                  ) {
                    let dateObj = { ...dateRange };
                    dateObj.startMonthDate = moment(
                      expression?.fieldValueLeft
                    ).format(DATEFORMAT?.format);
                    dateObj.endMonthDate = moment(
                      expression?.fieldValueRight
                    ).format(DATEFORMAT?.format);
                    dateObj.monthRange = filterQuery?.payload?.monthRange ?? 0;
                    setDateRange(dateObj);
                  } else {
                    if (
                      expression &&
                      !expression?.advanceSearchType &&
                      expression?.advanceSearchType !== undefined
                    ) {
                      _searchField.getSelectExploreField =
                        expression?.fieldTerm;
                      _searchField.searchOption =
                        exploreShipmentSpecification?.search_field_semantic;
                      _searchField.getValuesForSelectDropdown =
                        exploreShipmentSpecification?.search_field_semantic.find(
                          (e) => e?.field === expression?.fieldTerm
                        );
                      if (
                        _searchField?.getValuesForSelectDropdown?.searchType &&
                        _searchField?.getValuesForSelectDropdown?.searchType
                          ?.length > 0
                      ) {
                        let operator =
                          _searchField?.getValuesForSelectDropdown?.searchType;
                        const operatorArr = operator.map((e) => {
                          return { value: e?.type, title: e?.type };
                        });
                        _searchField.operatorOptions = operatorArr;
                        _searchField.selectedOperator =
                          getOperatorNameUsingExpressionType(
                            expression?.expressionType
                          );
                      } else {
                        _searchField.operatorOptions = [];
                        _searchField.selectedOperator = {};
                      }
                      setSearchField(_searchField);
                      if (Array.isArray(expression?.fieldValue)) {
                        expression?.fieldValue &&
                          expression?.fieldValue?.length &&
                          expression?.fieldValue?.map((val) => {
                            const obj = {
                              _id: val,
                            };
                            arr.push(obj);
                          });
                        if (arr && arr?.length) {
                          setInputValues(arr);
                          setSelectedOptionForSearchTerm(arr);
                        }
                      } else {
                        const obj = {
                          _id: expression?.fieldValue,
                        };
                        setInputValues(obj);
                        setSelectedOptionForSearchTerm(obj);
                      }
                    } else if (
                      expression?.advanceSearchType &&
                      expression?.advanceSearchType !== undefined
                    ) {
                      let advanceSearchOption =
                        filteredTaxonomy?.fields?.advance_search_semantic;
                      let _field =
                        advanceSearchOption?.length > 0 &&
                        advanceSearchOption?.find(
                          (e) => e?.identifier === expression?.identifier
                        );
                      let operatorOptions = [];
                      if (
                        _field &&
                        _field?.searchType &&
                        Object.keys(_field).length > 0
                      ) {
                        operatorOptions = createOperator(_field?.searchType);
                      }
                      let arr = [];
                      let valueObject = {};
                      if (Array.isArray(expression?.fieldValue)) {
                        expression?.fieldValue &&
                          expression?.fieldValue?.length &&
                          expression?.fieldValue?.map((val) => {
                            const obj = {
                              _id: val,
                            };
                            arr.push(obj);
                          });
                      } else {
                        valueObject = {
                          _id: expression?.fieldValue,
                        };
                      }
                      let _advanceSearchDefaultObj = {
                        relation: expression?.relation?.toUpperCase(),
                        field: _field?.field,
                        operatorOptions: operatorOptions,
                        operator: expression?.expressionType
                          ? getOperatorNameUsingExpressionType(
                              expression?.expressionType
                            )
                          : operatorOptions &&
                            operatorOptions?.length > 0 &&
                            operatorOptions[0].type,
                        value: arr?.length > 0 ? arr : valueObject,
                        query: _field?.query,
                      };
                      _advance_search_component.push(_advanceSearchDefaultObj);
                    } else if (expression?.filterType) {
                      let identifier = "";
                      let isQuantity = false;
                      let isUnit = false;
                      switch (expression?.identifier) {
                        case FILTER_OPTIONS?.FILTER_HS_CODE:
                          identifier = FILTER_OPTIONS?.HS_CODE;
                          break;
                        case FILTER_OPTIONS?.TO_NULL:
                          identifier = FILTER_OPTIONS?.TO_NULL;
                          break;
                        case FILTER_OPTIONS?.TO_ORDER:
                          identifier = FILTER_OPTIONS?.TO_ORDER;
                          break;
                        case FILTER_OPTIONS?.FILTER_COUNTRY:
                          identifier = FILTER_OPTIONS?.FILTER_COUNTRY;
                          break;
                        case FILTER_OPTIONS?.FILTER_PIN:
                          identifier = FILTER_OPTIONS?.FILTER_PIN;
                          break;
                        case FILTER_OPTIONS?.FILTER_STATE:
                          identifier = FILTER_OPTIONS?.FILTER_STATE;
                          break;
                        case FILTER_OPTIONS?.FILTER_DESTINATION_COUNTRY:
                          identifier =
                            FILTER_OPTIONS?.FILTER_DESTINATION_COUNTRY;
                          break;
                        case FILTER_OPTIONS?.FILTER_DESTINATION_PORT:
                          identifier = FILTER_OPTIONS?.FILTER_DESTINATION_PORT;
                          break;
                        case FILTER_OPTIONS?.FILTER_ORIGIN_COUNTRY:
                          identifier = FILTER_OPTIONS?.FILTER_ORIGIN_COUNTRY;
                          break;
                        case FILTER_OPTIONS?.FILTER_ORIGIN_PORT:
                          identifier = FILTER_OPTIONS?.FILTER_ORIGIN_PORT;
                          break;
                        case FILTER_OPTIONS?.FILTER_REPORTING_COUNTRY:
                          identifier = FILTER_OPTIONS?.FILTER_REPORTING_COUNTRY;
                          break;
                        case FILTER_OPTIONS?.FILTER_PORT:
                          identifier = FILTER_OPTIONS?.FILTER_PORT;
                          break;
                        case FILTER_OPTIONS?.FILTER_FOREIGN_PORT:
                          identifier = FILTER_OPTIONS?.FILTER_FOREIGN_PORT;
                          break;
                        case FILTER_OPTIONS?.FILTER_MONTH:
                          identifier = FILTER_OPTIONS?.MONTH;
                          break;
                        case FILTER_OPTIONS?.FILTER_UNIT:
                          identifier = FILTER_OPTIONS?.QUANTITY;
                          isUnit = true;
                          break;
                        case FILTER_OPTIONS?.FILTER_QUANTITY:
                          identifier = FILTER_OPTIONS?.QUANTITY;
                          isQuantity = true;
                          break;
                        case FILTER_OPTIONS?.FILTER_PRICE:
                          identifier = FILTER_OPTIONS?.PRICE;
                          break;
                        default:
                          break;
                      }
                      if (
                        identifier === FILTER_OPTIONS?.TO_NULL ||
                        identifier === FILTER_OPTIONS?.TO_ORDER
                      ) {
                        _apply = {
                          ..._apply,
                          [FILTER_OPTIONS?.REMOVE_ORDER_OR_NULL]: true,
                        };
                      } else {
                        _apply = {
                          ..._apply,
                          [identifier]: true,
                        };
                      }
                      if (
                        identifier === FILTER_OPTIONS?.HS_CODE ||
                        identifier === FILTER_OPTIONS?.FILTER_COUNTRY ||
                        identifier === FILTER_OPTIONS?.FILTER_STATE ||
                        identifier === FILTER_OPTIONS?.FILTER_PIN ||
                        identifier === FILTER_OPTIONS?.FILTER_PORT ||
                        identifier === FILTER_OPTIONS?.FILTER_FOREIGN_PORT ||
                        identifier ===
                          FILTER_OPTIONS?.FILTER_DESTINATION_COUNTRY ||
                        identifier ===
                          FILTER_OPTIONS?.FILTER_DESTINATION_PORT ||
                        identifier === FILTER_OPTIONS?.FILTER_ORIGIN_COUNTRY ||
                        identifier === FILTER_OPTIONS?.FILTER_ORIGIN_PORT ||
                        identifier === FILTER_OPTIONS?.FILTER_REPORTING_COUNTRY
                      ) {
                        expression?.fieldValue &&
                          expression?.fieldValue?.length > 0 &&
                          expression?.fieldValue.forEach((data) => {
                            saveCheckedData = {
                              ...saveCheckedData,
                              [identifier]: {
                                ...saveCheckedData[identifier],
                                [typeof data === "string"
                                  ? data?.split(" ")?.join("_")
                                  : data]: true,
                              },
                            };
                          });
                      } else if (identifier === FILTER_OPTIONS?.MONTH) {
                        expression?.fieldValues &&
                          expression?.fieldValues?.length > 0 &&
                          expression?.fieldValues.forEach((data) => {
                            const selectedDate = new Date(data?.fieldValueLeft);
                            const year = selectedDate.getFullYear();
                            let month = selectedDate.getMonth() + 1;
                            if (month.toString().length === 1) {
                              month = "0" + month;
                            }
                            const concatDate = year + "-" + month;
                            saveCheckedData = {
                              ...saveCheckedData,
                              [identifier]: {
                                ...saveCheckedData[identifier],
                                [typeof data === "string"
                                  ? data?.split(" ")?.join("_")
                                  : concatDate]: true,
                              },
                            };
                          });
                      } else if (
                        identifier === FILTER_OPTIONS?.QUANTITY &&
                        isQuantity
                      ) {
                        lastSelected.push(expression?.unit);
                        saveCheckedData = {
                          ...saveCheckedData,
                          [identifier]: {
                            ...saveCheckedData[identifier],
                            [expression.unit]: {
                              ...saveCheckedData[identifier][expression?.unit],
                              maxRange: expression?.fieldValueRight,
                              minRange: expression?.fieldValueLeft,
                            },
                          },
                        };
                        setRangeValue({
                          ...rangeValue,
                          [identifier]: [
                            expression?.fieldValueLeft,
                            expression?.fieldValueRight,
                          ],
                        });
                        setMaxMinRange({
                          ...getMaxMinRange,
                          [identifier]: [
                            expression?.fieldValueLeft,
                            expression?.fieldValueRight,
                          ],
                        });
                      } else if (
                        identifier === FILTER_OPTIONS?.QUANTITY &&
                        isUnit
                      ) {
                        let fieldValue = "";
                        if (typeof expression?.fieldValue === "string") {
                          fieldValue = expression?.fieldValue;
                        } else {
                          fieldValue = expression?.fieldValue[0];
                        }
                        saveCheckedData = {
                          ...saveCheckedData,
                          [identifier]: {
                            ...saveCheckedData[identifier],
                            [fieldValue]: {
                              [fieldValue]: fieldValue,
                              value: fieldValue,
                            },
                          },
                        };
                        setSelectedQuantityOrPrice({
                          ...selectedQuantityOrPriceValue,
                          [identifier]: fieldValue,
                        });
                      } else if (identifier === FILTER_OPTIONS?.PRICE) {
                        saveCheckedData = {
                          ...saveCheckedData,
                          [identifier]: {
                            ...saveCheckedData[identifier],
                            [expression.metaTag]: expression?.metaTag,
                            value: expression?.metaTag,
                            maxRange: expression?.fieldValueRight,
                            minRange: expression?.fieldValueLeft,
                          },
                        };
                        setRangeValue({
                          ...rangeValue,
                          [identifier]: [
                            expression?.fieldValueLeft,
                            expression?.fieldValueRight,
                          ],
                        });
                        setMaxMinRange({
                          ...getMaxMinRange,
                          [identifier]: [
                            expression?.fieldValueLeft,
                            expression?.fieldValueRight,
                          ],
                        });
                        setSelectedQuantityOrPrice({
                          ...selectedQuantityOrPriceValue,
                          [identifier]: expression?.metaTag,
                        });
                      } else if (
                        identifier === FILTER_OPTIONS?.TO_NULL ||
                        identifier === FILTER_OPTIONS?.TO_ORDER
                      ) {
                        saveCheckedData = {
                          ...saveCheckedData,
                          [FILTER_OPTIONS?.REMOVE_ORDER_OR_NULL]: {
                            ...saveCheckedData[
                              FILTER_OPTIONS?.REMOVE_ORDER_OR_NULL
                            ],
                            [expression?.identifier]: true,
                          },
                        };
                      }
                    }
                  }
                });
              setStoreFilterValues(() => saveCheckedData);
              setApply(_apply);
              setCheckedData(saveCheckedData);
              setAdvanceSearchComponent(_advance_search_component);
              if (filterQuery?.payload?.removeDuplicateRelatedData) {
                setIsChangeRemoveDuplicateButton(true);
              }
              if (
                !isSaveQueryFilterApply ||
                isSaveQueryFilterApply === false ||
                isSaveQueryFilterApply === "false"
              ) {
                const relatedPayload = {
                  taxonomy_id: filteredTaxonomy?._id,
                  tradeType: filterQuery?.payload?.tradeType,
                  country: filterQuery?.payload?.country,
                  searchField: _searchField?.getSelectExploreField,
                  searchTerm: arr[0]?._id,
                  dateRange: {
                    startDate: dateRange?.startMonthDate,
                    endDate: dateRange?.endMonthDate,
                  },
                };
                const payload = {
                  recordPayload: filterQuery?.payload,
                  recommendationPayload: relatedPayload,
                };
                onSearchClick(payload, false, false);
              }
            } else {
              throw new Error();
            }
          }
        })
        .catch((err) => {
          catchBlock(err);
        });
    }
  }, [isQueryId, filteredTaxonomy, exploreShipmentSpecification]);

  useEffect(() => {
    if (
      filteredTaxonomy &&
      Object.keys(filteredTaxonomy).length > 0 &&
      exploreShipmentSpecification &&
      Object.keys(exploreShipmentSpecification).length > 0 &&
      isFromRecentSearch
    ) {
      let userPayload = JSON.parse(localStorage.getItem("searchPayload"));
      let arr = [];
      let _searchField = {};
      let _dateRange = {};
      let filterMatchExpressions;
      let _filterMatchExpressions;
      let saveCheckedData = { ...CheckedData };
      let _apply = { ...apply };
      let _advance_search_component = [];

      userPayload &&
        userPayload?.length > 0 &&
        userPayload?.map((expression) => {
          if (
            expression &&
            !expression?.advanceSearchType &&
            expression?.advanceSearchType !== undefined
          ) {
            if (
              expression?.fieldTerm === "PRODUCT_DESCRIPTION" ||
              (expression?.fieldTerm === "HS_CODE" &&
                typeof expression?.fieldTerm === "string")
            ) {
              let newFieldValue = [];
              newFieldValue.push(expression?.fieldTerm);
              expression.fieldTerm = newFieldValue;
            }
            expression.query = "CHOOSE";
            _searchField.getSelectExploreField = expression?.fieldTerm;
            _searchField.searchOption =
              exploreShipmentSpecification?.search_field_semantic;
            const _fieldTerm = Array.isArray(expression?.fieldTerm)
              ? expression?.fieldTerm[0]
              : expression?.fieldTerm;
            _searchField.getValuesForSelectDropdown =
              exploreShipmentSpecification?.search_field_semantic.find(
                (e) => e?.field === _fieldTerm
              );

            if (
              _searchField?.getValuesForSelectDropdown?.searchType &&
              _searchField?.getValuesForSelectDropdown?.searchType?.length > 0
            ) {
              let operator =
                _searchField?.getValuesForSelectDropdown?.searchType;
              const operatorArr = operator.map((e) => {
                return { value: e?.type, title: e?.type };
              });
              _searchField.operatorOptions = operatorArr;
              if (typeof expression?.expressionType === "number") {
                _searchField.selectedOperator =
                  getOperatorNameUsingExpressionType(
                    expression?.expressionType
                  );
              } else {
                _searchField.selectedOperator = expression?.expressionType;
              }
            } else {
              _searchField.operatorOptions = [];
              _searchField.selectedOperator = {};
            }
            setSearchField(_searchField);
            if (Array.isArray(expression?.fieldValue)) {
              expression?.fieldValue &&
                expression?.fieldValue?.length &&
                expression?.fieldValue?.map((val) => {
                  const obj = {
                    _id: val,
                  };
                  arr.push(obj);
                });
              if (arr && arr?.length) {
                setInputValues(arr);
                setSelectedOptionForSearchTerm(arr);
              }
            } else {
              const obj = {
                _id: expression?.fieldValue,
              };

              setInputValues(obj);
              setSelectedOptionForSearchTerm(obj);
            }
          } else if (
            expression?.advanceSearchType &&
            expression?.advanceSearchType !== undefined
          ) {
            let advanceSearchOption =
              filteredTaxonomy?.fields?.advance_search_semantic;
            let _field =
              advanceSearchOption?.length > 0 &&
              advanceSearchOption?.find(
                (e) => e?.identifier === expression?.identifier
              );
            let operatorOptions = [];
            if (
              _field &&
              _field?.searchType &&
              Object.keys(_field).length > 0
            ) {
              operatorOptions = createOperator(_field?.searchType);
            }
            let arr = [];
            let valueObject = {};
            if (Array.isArray(expression?.fieldValue)) {
              expression?.fieldValue &&
                expression?.fieldValue?.length &&
                expression?.fieldValue?.map((val) => {
                  const obj = {
                    _id: val,
                  };
                  arr.push(obj);
                });
            } else {
              valueObject = {
                _id: expression?.fieldValue,
              };
            }
            let _advanceSearchDefaultObj = {
              relation: expression?.relation?.toUpperCase(),
              field: _field?.field,
              operatorOptions: operatorOptions,
              operator: expression?.expressionType
                ? getOperatorNameUsingExpressionType(expression?.expressionType)
                : operatorOptions &&
                  operatorOptions?.length > 0 &&
                  operatorOptions[0].type,
              value: arr?.length > 0 ? arr : valueObject,
              query: _field?.query,
            };
            _advance_search_component.push(_advanceSearchDefaultObj);
          }
          if (expression?.identifier === "REMOVE_DUPLICATE") {
            setIsChangeRemoveDuplicateButton(true);
          }
        });

      const searchMonthRangeObject = userPayload?.find(
        (item) => item.identifier === "SEARCH_MONTH_RANGE"
      );

      let dateObj = { ...searchMonthRangeObject };

      function getMonthRange(startDate, endDate) {
        const start = new Date(startDate);
        const end = new Date(endDate);

        const startYear = start.getFullYear();
        const startMonth = start.getMonth();
        const endYear = end.getFullYear();
        const endMonth = end.getMonth();

        const yearDiff = endYear - startYear;
        const monthDiff = endMonth - startMonth;

        return yearDiff * 12 + monthDiff;
      }
      _dateRange = {
        monthRange:
          getMonthRange(dateObj?.fieldValueLeft, dateObj?.fieldValueRight) ?? 0,
        endMonthDate: dateObj?.fieldValueRight,
        startMonthDate: dateObj?.fieldValueLeft,
      };
      setDateRange({ ...dateRange, ..._dateRange });
      if (userPayload && userPayload?.length > 0) {
        filterMatchExpressions = userPayload?.filter((item) =>
          item?.identifier.includes("FILTER")
        );
        filterMatchExpressions.map((expression) => {
          if (filterMatchExpressions) {
            let unit = filterMatchExpressions?.find(
              (e) => e?.identifier === FILTER_OPTIONS?.FILTER_UNIT
            );
            let quantity = filterMatchExpressions?.find(
              (e) => e?.identifier === FILTER_OPTIONS?.FILTER_QUANTITY
            );
            if (
              unit &&
              quantity &&
              Object.keys(unit).length > 0 &&
              Object.keys(quantity).length > 0
            ) {
              _filterMatchExpressions = filterMatchExpressions?.map((e) => {
                if (
                  ![
                    FILTER_OPTIONS?.FILTER_QUANTITY,
                    FILTER_OPTIONS?.FILTER_UNIT,
                  ].includes(e?.identifier)
                ) {
                  return e;
                }
              });
              _filterMatchExpressions.push(unit);
              _filterMatchExpressions.push(quantity);
            } else if (expression?.filterType) {
              let identifier = "";
              let isQuantity = false;
              let isUnit = false;
              switch (expression?.identifier) {
                case FILTER_OPTIONS?.FILTER_HS_CODE:
                  identifier = FILTER_OPTIONS?.HS_CODE;
                  break;
                case FILTER_OPTIONS?.TO_NULL:
                  identifier = FILTER_OPTIONS?.TO_NULL;
                  break;
                case FILTER_OPTIONS?.TO_ORDER:
                  identifier = FILTER_OPTIONS?.TO_ORDER;
                  break;
                case FILTER_OPTIONS?.FILTER_COUNTRY:
                  identifier = FILTER_OPTIONS?.FILTER_COUNTRY;
                  break;
                case FILTER_OPTIONS?.FILTER_PIN:
                  identifier = FILTER_OPTIONS?.FILTER_PIN;
                  break;
                case FILTER_OPTIONS?.FILTER_STATE:
                  identifier = FILTER_OPTIONS?.FILTER_STATE;
                  break;
                case FILTER_OPTIONS?.FILTER_DESTINATION_COUNTRY:
                  identifier = FILTER_OPTIONS?.FILTER_DESTINATION_COUNTRY;
                  break;
                case FILTER_OPTIONS?.FILTER_DESTINATION_PORT:
                  identifier = FILTER_OPTIONS?.FILTER_DESTINATION_PORT;
                  break;
                case FILTER_OPTIONS?.FILTER_ORIGIN_COUNTRY:
                  identifier = FILTER_OPTIONS?.FILTER_ORIGIN_COUNTRY;
                  break;
                case FILTER_OPTIONS?.FILTER_ORIGIN_PORT:
                  identifier = FILTER_OPTIONS?.FILTER_ORIGIN_PORT;
                  break;
                case FILTER_OPTIONS?.FILTER_REPORTING_COUNTRY:
                  identifier = FILTER_OPTIONS?.FILTER_REPORTING_COUNTRY;
                  break;
                case FILTER_OPTIONS?.FILTER_PORT:
                  identifier = FILTER_OPTIONS?.FILTER_PORT;
                  break;
                case FILTER_OPTIONS?.FILTER_FOREIGN_PORT:
                  identifier = FILTER_OPTIONS?.FILTER_FOREIGN_PORT;
                  break;
                case FILTER_OPTIONS?.FILTER_MONTH:
                  identifier = FILTER_OPTIONS?.MONTH;
                  break;
                case FILTER_OPTIONS?.FILTER_UNIT:
                  identifier = FILTER_OPTIONS?.QUANTITY;
                  isUnit = true;
                  break;
                case FILTER_OPTIONS?.FILTER_QUANTITY:
                  identifier = FILTER_OPTIONS?.QUANTITY;
                  isQuantity = true;
                  break;
                case FILTER_OPTIONS?.FILTER_PRICE:
                  identifier = FILTER_OPTIONS?.PRICE;
                  break;
                default:
                  break;
              }
              if (
                identifier === FILTER_OPTIONS?.TO_NULL ||
                identifier === FILTER_OPTIONS?.TO_ORDER
              ) {
                _apply = {
                  ..._apply,
                  [FILTER_OPTIONS?.REMOVE_ORDER_OR_NULL]: true,
                };
              } else {
                _apply = {
                  ..._apply,
                  [identifier]: true,
                };
              }
              if (
                identifier === FILTER_OPTIONS?.HS_CODE ||
                identifier === FILTER_OPTIONS?.FILTER_COUNTRY ||
                identifier === FILTER_OPTIONS?.FILTER_STATE ||
                identifier === FILTER_OPTIONS?.FILTER_PIN ||
                identifier === FILTER_OPTIONS?.FILTER_PORT ||
                identifier === FILTER_OPTIONS?.FILTER_FOREIGN_PORT ||
                identifier === FILTER_OPTIONS?.FILTER_DESTINATION_COUNTRY ||
                identifier === FILTER_OPTIONS?.FILTER_DESTINATION_PORT ||
                identifier === FILTER_OPTIONS?.FILTER_ORIGIN_COUNTRY ||
                identifier === FILTER_OPTIONS?.FILTER_ORIGIN_PORT ||
                identifier === FILTER_OPTIONS?.FILTER_REPORTING_COUNTRY
              ) {
                expression?.fieldValue &&
                  expression?.fieldValue?.length > 0 &&
                  expression?.fieldValue.forEach((data) => {
                    saveCheckedData = {
                      ...saveCheckedData,
                      [identifier]: {
                        ...saveCheckedData[identifier],
                        [typeof data === "string"
                          ? data?.split(" ")?.join("_")
                          : data]: true,
                      },
                    };
                  });
              } else if (identifier === FILTER_OPTIONS?.MONTH) {
                expression?.fieldValues &&
                  expression?.fieldValues?.length > 0 &&
                  expression?.fieldValues.forEach((data) => {
                    const selectedDate = new Date(data?.fieldValueLeft);
                    const year = selectedDate.getFullYear();
                    let month = selectedDate.getMonth() + 1;
                    if (month.toString().length === 1) {
                      month = "0" + month;
                    }
                    const concatDate = year + "-" + month;
                    saveCheckedData = {
                      ...saveCheckedData,
                      [identifier]: {
                        ...saveCheckedData[identifier],
                        [typeof data === "string"
                          ? data?.split(" ")?.join("_")
                          : concatDate]: true,
                      },
                    };
                  });
              } else if (
                identifier === FILTER_OPTIONS?.QUANTITY &&
                isQuantity
              ) {
                lastSelected.push(expression?.unit);
                saveCheckedData = {
                  ...saveCheckedData,
                  [identifier]: {
                    ...saveCheckedData[identifier],
                    [expression.unit]: {
                      ...saveCheckedData[identifier][expression?.unit],
                      maxRange: expression?.fieldValueRight,
                      minRange: expression?.fieldValueLeft ?? 0,
                    },
                  },
                };
                setRangeValue({
                  ...rangeValue,
                  [identifier]: [
                    expression?.fieldValueLeft,
                    expression?.fieldValueRight,
                  ],
                });
                setMaxMinRange({
                  ...getMaxMinRange,
                  [identifier]: [
                    expression?.fieldValueLeft,
                    expression?.fieldValueRight,
                  ],
                });
              } else if (identifier === FILTER_OPTIONS?.QUANTITY && isUnit) {
                let fieldValue = "";
                if (typeof expression?.fieldValue === "string") {
                  fieldValue = expression?.fieldValue;
                } else {
                  fieldValue = expression?.fieldValue[0];
                }
                saveCheckedData = {
                  ...saveCheckedData,
                  [identifier]: {
                    ...saveCheckedData[identifier],
                    [fieldValue]: {
                      [fieldValue]: fieldValue,
                      value: fieldValue,
                    },
                  },
                };
                setSelectedQuantityOrPrice({
                  ...selectedQuantityOrPriceValue,
                  [identifier]: fieldValue,
                });
              } else if (identifier === FILTER_OPTIONS?.PRICE) {
                saveCheckedData = {
                  ...saveCheckedData,
                  [identifier]: {
                    ...saveCheckedData[identifier],
                    [expression.metaTag]: expression?.metaTag,
                    value: expression?.metaTag,
                    maxRange: expression?.fieldValueRight,
                    minRange: expression?.fieldValueLeft,
                  },
                };
                setRangeValue({
                  ...rangeValue,
                  [identifier]: [
                    expression?.fieldValueLeft,
                    expression?.fieldValueRight,
                  ],
                });
                setMaxMinRange({
                  ...getMaxMinRange,
                  [identifier]: [
                    expression?.fieldValueLeft,
                    expression?.fieldValueRight,
                  ],
                });
                setSelectedQuantityOrPrice({
                  ...selectedQuantityOrPriceValue,
                  [identifier]: expression?.metaTag,
                });
              } else if (
                identifier === FILTER_OPTIONS?.TO_NULL ||
                identifier === FILTER_OPTIONS?.TO_ORDER
              ) {
                saveCheckedData = {
                  ...saveCheckedData,
                  [FILTER_OPTIONS?.REMOVE_ORDER_OR_NULL]: {
                    ...saveCheckedData[FILTER_OPTIONS?.REMOVE_ORDER_OR_NULL],
                    [expression?.identifier]: true,
                  },
                };
              }
            }
          }
        });
        setStoreFilterValues(() => saveCheckedData);
        setApply(_apply);
        setCheckedData(saveCheckedData);
        setAdvanceSearchComponent(_advance_search_component);
      }
      setCallForceFully(true);
    }
  }, [filteredTaxonomy, exploreShipmentSpecification]);

  useEffect(() => {
    if (
      isFromDashboard &&
      filteredTaxonomy &&
      Object.keys(filteredTaxonomy).length > 0 &&
      exploreShipmentSpecification &&
      Object.keys(exploreShipmentSpecification).length > 0
    ) {
      let arr = [];
      let _searchField = {};
      let _dateRange = {};

      let filteredTaxonomyData =
        filteredTaxonomy?.fields?.search_semantic?.filter(
          (data) => data?.field === searchOn
        );

      let payloadForDashboardSearch = [
        {
          identifier: filteredTaxonomyData?.identifier,
          alias: filteredTaxonomyData?.alias,
          clause: "MATCH",
          expressionType: 200,
          fieldTerm: searchOn,
          fieldValue: [valueSearch],
          fieldTermTypeSuffix: ".keyword",
          advanceSearchType: false,
        },
      ];

      payloadForDashboardSearch &&
        payloadForDashboardSearch?.length > 0 &&
        payloadForDashboardSearch?.map((expression) => {
          if (
            expression &&
            !expression?.advanceSearchType &&
            expression?.advanceSearchType !== undefined
          ) {
            if (
              expression?.fieldTerm === "PRODUCT_DESCRIPTION" ||
              (expression?.fieldTerm === "HS_CODE" &&
                typeof expression?.fieldTerm === "string")
            ) {
              let newFieldValue = [];
              newFieldValue.push(expression?.fieldTerm);
              expression.fieldTerm = newFieldValue;
            }
            expression.query = "CHOOSE";
            _searchField.getSelectExploreField = expression?.fieldTerm;
            _searchField.searchOption =
              exploreShipmentSpecification?.search_field_semantic;
            const _fieldTerm = Array.isArray(expression?.fieldTerm)
              ? expression?.fieldTerm[0]
              : expression?.fieldTerm;
            _searchField.getValuesForSelectDropdown =
              exploreShipmentSpecification?.search_field_semantic.find(
                (e) => e?.field === _fieldTerm
              );

            if (
              _searchField?.getValuesForSelectDropdown?.searchType &&
              _searchField?.getValuesForSelectDropdown?.searchType?.length > 0
            ) {
              let operator =
                _searchField?.getValuesForSelectDropdown?.searchType;
              const operatorArr = operator.map((e) => {
                return { value: e?.type, title: e?.type };
              });
              _searchField.operatorOptions = operatorArr;
              if (typeof expression?.expressionType === "number") {
                _searchField.selectedOperator =
                  getOperatorNameUsingExpressionType(
                    expression?.expressionType
                  );
              } else {
                _searchField.selectedOperator = expression?.expressionType;
              }
            } else {
              _searchField.operatorOptions = [];
              _searchField.selectedOperator = {};
            }
            setSearchField(_searchField);
            if (Array.isArray(expression?.fieldValue)) {
              expression?.fieldValue &&
                expression?.fieldValue?.length &&
                expression?.fieldValue?.map((val) => {
                  const obj = {
                    _id: val,
                  };
                  arr.push(obj);
                });
              if (arr && arr?.length) {
                setInputValues(arr);
                setSelectedOptionForSearchTerm(arr);
              }
            } else {
              const obj = {
                _id: expression?.fieldValue,
              };

              setInputValues(obj);
              setSelectedOptionForSearchTerm(obj);
            }
          }
        });

      function getPreviousYearDateFromCurrentDay() {
        const today = new Date();
        const lastYear = new Date(today);

        lastYear.setFullYear(today.getFullYear() - 1);

        const formatDate = (date) => {
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
          const day = String(date.getDate()).padStart(2, "0");
          return `${year}-${month}-${day}`;
        };

        const startDate = formatDate(lastYear);
        const endDate = formatDate(today);

        return { startDate, endDate };
      }

      const { startDate, endDate } = getPreviousYearDateFromCurrentDay();

      function getMonthRange(startDate, endDate) {
        const start = new Date(startDate);
        const end = new Date(endDate);

        const startYear = start.getFullYear();
        const startMonth = start.getMonth();
        const endYear = end.getFullYear();
        const endMonth = end.getMonth();

        const yearDiff = endYear - startYear;
        const monthDiff = endMonth - startMonth;

        return yearDiff * 12 + monthDiff;
      }
      _dateRange = {
        monthRange: getMonthRange(startDate, endDate) ?? 0,
        endMonthDate: endDate,
        startMonthDate: startDate,
      };
      setDateRange({ ...dateRange, ..._dateRange });

      setCallForceFully(true);
    }
  }, [filteredTaxonomy, exploreShipmentSpecification, isFromDashboard]);

  useEffect(() => {
    if (callForceFully) {
      callOnSearchClick();
    }
  }, [callForceFully]);

  const callOnSearchClick = () => {
    onSearchClick({}, false, false);
    setCallForceFully(false);
  };

  useEffect(() => {
    if (
      isColumnName &&
      filteredTaxonomy &&
      Object.keys(filteredTaxonomy).length > 0 &&
      exploreShipmentSpecification &&
      Object.keys(exploreShipmentSpecification).length > 0 &&
      searchField &&
      searchField?.getValuesForSelectDropdown &&
      Object.keys(searchField?.getValuesForSelectDropdown).length > 0 &&
      searchField?.getSelectExploreField
    ) {
      loadOptions(companyColumnName?.split("_")?.join(" "), "");

      onSearchClick();
    }
  }, [
    isColumnName,
    filteredTaxonomy,
    exploreShipmentSpecification,
    searchField,
  ]);

  useEffect(() => {
    if (
      globalSearch &&
      !isVisibleData &&
      filteredTaxonomy &&
      Object.keys(filteredTaxonomy).length > 0 &&
      exploreShipmentSpecification &&
      Object.keys(exploreShipmentSpecification).length > 0 &&
      searchField &&
      searchField?.getValuesForSelectDropdown &&
      Object.keys(searchField?.getValuesForSelectDropdown).length > 0 &&
      searchField?.getSelectExploreField
    ) {
      onSearchClick();
    }
  }, [
    globalSearch,
    filteredTaxonomy,
    exploreShipmentSpecification,
    searchField,
  ]);

  const getSaveQueryFilter = async (data) => {
    let payload = { ...data?.payload };
    payload.matchExpressions = payload?.matchExpressions?.filter(
      (e) => !e.hasOwnProperty("filterType")
    );
    await getExploreFilter(payload)
      .then((e) => {
        setGetAllSplitFilters(() => e?.data);
        setAllFilterValuesWithCountsArr(() => e?.data?.filter);
        setAllFilterValueArray(() => e?.data);
        setFilterDataBasedOnSearch(() => e?.data, true);
      })
      .catch((err) => {
        catchBlock(err);
      });
  };

  useEffect(() => {
    if (filteredTaxonomy && filteredTaxonomy?._id) {
      getAccountViewColumns(filteredTaxonomy?._id);
    }
  }, [filteredTaxonomy]);

  const getAccountViewColumns = (id) => {
    getViewColumns(id).then((res) => {
      if (res?.data) {
        if (
          res?.data?.data?.selected_columns &&
          res?.data?.data?.selected_columns?.length > 0
        ) {
          setAccountTableHeader(res?.data?.data?.selected_columns);
          translateButtonClick();
        } else {
          setAccountTableHeader([]);
        }
      }
    });
  };

  const handelChangeSelect = (e) => {
    const { name, value } = e?.target;
    setSelectTrade({ ...selectTrade, [name]: value });
  };

  const handleSelectChange = (e) => {
    setInputValues(null);
    setSelectedOptionForSearchTerm(null);
    setSearchOptionForMain([]);
    let data = searchField?.searchOption.find(
      (item) => item?.field === e?.target?.value
    );
    let options = [];
    let _selectedOperator = "";
    if (data?.searchType && data?.searchType?.length > 0) {
      options =
        data?.searchType?.length > 0 &&
        data?.searchType?.map((e) => {
          return { value: e?.type, title: e?.type };
        });
      _selectedOperator = options[0]?.value;
    }
    setSearchField({
      ...searchField,
      getSelectExploreField: e?.target?.value,
      getValuesForSelectDropdown: data,
      operatorOptions: options,
      selectedOperator: _selectedOperator,
    });
  };

  const filterCountryData = (countryName) => {
    filterCountryArr = [];
    let arr = [];
    countryName &&
      countryName?.length &&
      countryName?.length > 0 &&
      countryName.forEach((e) => {
        if (!arr.includes(e?.country?.toUpperCase())) {
          arr.push(e?.country?.toUpperCase());
          filterCountryArr.push({
            value: e?.country?.toUpperCase(),
            title: e?.country?.split("_").join(" ").toUpperCase(),
          });
        }
      });
    const sortedArr = sortCountryWiseData(filterCountryArr);
    if (sortedArr && sortedArr?.length) {
      setSelectTrade({
        ...selectTrade,
        CountryOptions: sortedArr,
        country: sortedArr.filter(
          (e) => e?.value?.toUpperCase() === selectTrade?.country?.toUpperCase()
        )[0]?.value,
      });
    }
  };

  const getSearchPayload = (inputValue, type, index) => {
    const dividedDateRange = divideDateRange(
      dateRange?.startMonthDate,
      dateRange?.endMonthDate,
      exploreShipmentSpecification?.data_end_date
    );
    let searchPayload = {
      countryCode: isBlFlag === "true" ? "bl" : currentCountry,
      dateField: exploreShipmentSpecification?.explore_aggregation?.sortTerm,
      endDate: dateRange?.endMonthDate,
      searchField: searchField?.getValuesForSelectDropdown?.field,
      searchTerm: inputValue,
      startDate: dateRange?.startMonthDate,
      tradeType: currentTradeType,
      hs_code_digit_classification:
        filteredTaxonomy?.hs_code_digit_classification,
      dividedDateRange: dividedDateRange,
    };
    if (searchField?.getValuesForSelectDropdown?.field === "HS_CODE") {
      const converHsCode = convertDataIntoLeftAndRightValue(
        [inputValue],
        filteredTaxonomy?.hs_code_digit_classification,
        mirrorData
      );
      if (
        converHsCode &&
        converHsCode?.fieldValueArr &&
        converHsCode?.fieldValueArr?.length > 0
      ) {
        searchPayload.fieldValueRight =
          converHsCode?.fieldValueArr[0]?.fieldValueRight;
        searchPayload.fieldValueLeft =
          converHsCode?.fieldValueArr[0]?.fieldValueLeft;
      }
    }
    const filterSEARCH_COUNTRY = {
      identifier: "SEARCH_COUNTRY",
      alias: "COUNTRY",
      clause: "MATCH",
      expressionType: 202,
      relation: "and",
      fieldTerm: "COUNTRY_DATA",
      fieldValue: [currentCountry.replaceAll("_", " ")],
      fieldTermTypeSuffix: "",
    };
    if (isBlFlag === "true") {
      searchPayload.matchExpressions = [filterSEARCH_COUNTRY];
    }

    if (type === SEARCHTYPE?.advancedSearch) {
      const obj = advanceSearchComponent[index];
      searchPayload.searchField = obj.field;
      searchPayload.countryCode = currentCountry;
      // if (obj.field === "HS_CODE") {
      //   const converHsCode = convertDataIntoLeftAndRightValue(
      //     [inputValue],
      //     filteredTaxonomy?.hs_code_digit_classification,
      //     mirrorData
      //   );
      //   if (
      //     converHsCode &&
      //     converHsCode?.fieldValueArr &&
      //     converHsCode?.fieldValueArr?.length > 0
      //   ) {
      //     searchPayload.fieldValueRight =
      //       converHsCode?.fieldValueArr[0]?.fieldValueRight;
      //     searchPayload.fieldValueLeft =
      //       converHsCode?.fieldValueArr[0]?.fieldValueLeft;
      //   }
      // }
    } else {
      searchPayload.searchField =
        searchField?.getValuesForSelectDropdown?.field;
    }
    if (dividedDateRange) {
      if (dividedDateRange.length === 2) {
        searchPayload.dateExpression = 2;
      } else {
        if (dividedDateRange && dividedDateRange[0]) {
          const obj = dividedDateRange[0];
          if (obj.hasOwnProperty("leftFieldvalueHot")) {
            searchPayload.dateExpression = 1;
          } else {
            searchPayload.dateExpression = 0;
          }
        }
      }
    }
    return searchPayload;
  };

  const loadOptions = async (inputValue, type, index) => {
    let searchPayload;
    if (inputValue !== "") {
      if (type === SEARCHTYPE?.advancedSearch) {
        searchPayload = getSearchPayload(inputValue, type, index);
        let dataSearchTerm = await getExploreSearchTermForSearchWithCancelReq(
          searchPayload
        ).then((e) => {
          if (e?.status === 200) {
            setReactSelectAdvanceLoading(false);
            return e?.data?.data;
          } else {
            return [];
          }
        });

        if (
          searchField?.getSelectExploreField === FILTER_OPTIONS?.HS_CODE &&
          typeof dataSearchTerm === "number"
        ) {
          dataSearchTerm = appendZeroAtStart(dataSearchTerm);
        }
        if (
          advanceSearchComponent &&
          dataSearchTerm &&
          Array.isArray(dataSearchTerm) &&
          (ADD_USER_SEARCH_IN_SEARCH_OPTIONS?.includes(
            advanceSearchComponent[index]?.field
          ) ||
            (advanceSearchComponent[index]?.field?.includes("PROD") &&
              advanceSearchComponent[index]?.field?.includes("DESC")))
        ) {
          if (
            ADD_USER_SEARCH_IN_SEARCH_OPTIONS_VALIDATE_FIELD?.includes(
              advanceSearchComponent[index]?.field
            )
          ) {
            if (inputValue?.length >= 2) {
              dataSearchTerm.unshift({
                _id: inputValue,
              });
            }
          } else {
            dataSearchTerm.unshift({
              _id: inputValue,
            });
          }
        }
        if (type === SEARCHTYPE?.advancedSearch) {
          const dataOptions = [...defaultOptionForSearchState];
          dataOptions[index] = {
            searchValueState: dataSearchTerm,
          };
          setDefaultOptionForAdvancedSearch(dataOptions);
        } else {
          setSearchOptionForMain(dataSearchTerm);
        }
        return dataSearchTerm;
      } else {
        if (
          searchField?.searchOption &&
          searchField?.searchOption?.length > 0
        ) {
          searchPayload = getSearchPayload(inputValue, type, index);

          if (isBlFlag === "true") {
            searchPayload.blCountry = currentCountry;
          }
          let dataSearchTerm = await getExploreSearchTermForSearchWithCancelReq(
            searchPayload
          ).then((e) => {
            if (e?.status === 200) {
              setReactSelectLoading(false);
              return e?.data?.data;
            } else {
              return [];
            }
          });
          if (searchField?.getSelectExploreField === FILTER_OPTIONS?.HS_CODE) {
            dataSearchTerm = appendZeroAtStart(dataSearchTerm);
          }
          if (
            ADD_USER_SEARCH_IN_SEARCH_OPTIONS?.includes(
              searchField?.getSelectExploreField
            ) &&
            dataSearchTerm &&
            Array.isArray(dataSearchTerm)
          ) {
            if (
              ADD_USER_SEARCH_IN_SEARCH_OPTIONS_VALIDATE_FIELD?.includes(
                searchField?.getSelectExploreField
              )
            ) {
              if (inputValue?.length >= 2) {
                dataSearchTerm.unshift({
                  _id: inputValue,
                });
              }
            } else {
              dataSearchTerm.unshift({
                _id: inputValue,
              });
            }
            // dataSearchTerm.unshift({ _id: inputValue });
          }
          setSearchOptionForMain(dataSearchTerm);
          return dataSearchTerm;
        }
      }
    }
  };

  const handleInputChangeForSearchTerm = _.debounce(
    (value) => {
      if (value !== "") {
        setReactSelectLoading(true);
        // if(getSelectedOptionForSearchTerm === null || getSelectedOptionForSearchTerm?.length === 0) {
        //   const payload = [{_id: value}];
        //   setSelectedOptionForSearchTerm(payload);
        //   setInputValues(payload);
        // }
        // else{
        //   const selectsearchTerm = getSelectedOptionForSearchTerm
        //   selectsearchTerm.push({_id: value});
        //   setSelectedOptionForSearchTerm(selectsearchTerm);
        //   setInputValues(selectsearchTerm);
        // }
        if (searchField?.getSelectExploreField === FILTER_OPTIONS?.HS_CODE) {
          const hsCodeRegex = /^[0-9]+$/;
          if (!hsCodeRegex.test(value)) {
            setReactSelectLoading(false);
            alert("HS CODE Only Allows Numeric Value!");
          } else {
            loadOptions(value, SEARCHTYPE?.search, "");
          }
        } else {
          loadOptions(value, SEARCHTYPE?.search, "");
        }
        if (
          searchField?.getValuesForSelectDropdown?.query ===
            QUERYTYPE?.CHOOSE ||
          IsMultiSelectedField?.includes(
            searchField?.getValuesForSelectDropdown?.field
          ) ||
          (searchField?.getValuesForSelectDropdown?.field?.includes("PROD") &&
            searchField?.getValuesForSelectDropdown?.field?.includes("DESC"))
        ) {
          setInputValues(value);
        } else {
          let obj = {
            _id: value,
          };

          setInputValues(obj);
          setSelectedOptionForSearchTerm(obj);
        }
      }
    },
    CloseSearchOption?.includes(searchField?.getSelectExploreField) ||
      (searchField?.getSelectExploreField?.includes("PROD") &&
        searchField?.getSelectExploreField?.includes("DESC"))
      ? 500
      : 10
  );

  const handleChangeForSelectedDropdownValue = (value) => {
    if (value === null) {
      setInputValues(null);
      setSelectedOptionForSearchTerm(null);
      setSearchOptionForMain([]);
    } else {
      if (
        SELECT_OPTIONS_VALIDATE_FIELD?.includes(
          searchField?.getSelectExploreField
        )
      ) {
        if (value?.length > 5) {
          showHsCodeError();
        } else {
          setInputValues(value);
          setSelectedOptionForSearchTerm(value);
        }
      } else {
        setInputValues(value);
        setSelectedOptionForSearchTerm(value);
      }
    }
  };

  const showHsCodeError = () => {
    setError(true);
    setErrorMsg(hsCodeSelectLimit);
  };
  const addButton = () => {
    let array = [...advanceSearchComponent];
    array.push(advanceSearchDefaultObj);
    setAdvanceSearchComponent(array);
    let emptyArrayForErr = [...emptyErrorAdvanceSearch];
    emptyArrayForErr.push({ advancedSearch: false });
    setEmptyErrorAdvanceSearch(emptyArrayForErr);
  };

  const handleAdvanceSearchOption = (e, index, type, queryType) => {
    let _advanceSearchComponent = [...advanceSearchComponent];
    setCurrentAdvanceSearchCmpIndex(index);
    if (e !== "") {
      if (
        [
          ADVANCESEARCHFIELDNAME?.relation,
          ADVANCESEARCHFIELDNAME?.operator,
          ADVANCESEARCHFIELDNAME?.field,
        ].includes(e?.target?.name)
      ) {
        const { name, value } = e.target;
        if (
          [
            ADVANCESEARCHFIELDNAME?.relation,
            ADVANCESEARCHFIELDNAME?.operator,
          ].includes(name)
        ) {
          _advanceSearchComponent[index] = {
            ..._advanceSearchComponent[index],
            [name]: value,
          };
        } else if ([ADVANCESEARCHFIELDNAME?.field].includes(name)) {
          let selectedField =
            advanceSearchFieldOptions?.length &&
            advanceSearchFieldOptions?.find((item) => item.field === value);
          let operatorOptions = [];
          if (selectedField && selectedField?.searchType) {
            operatorOptions = createOperator(selectedField?.searchType);
          } else {
            operatorOptions = [{ type: "In" }];
          }
          _advanceSearchComponent[index] = {
            ..._advanceSearchComponent[index],
            query: selectedField?.query,
            operatorOptions: operatorOptions,
            [name]: value,
            value: "",
            operator:
              operatorOptions &&
              operatorOptions?.length > 0 &&
              operatorOptions[0].type,
          };
        }
      } else {
        if (type === "input" && e !== "" && queryType === QUERYTYPE?.SEARCH) {
          _advanceSearchComponent[index] = {
            ..._advanceSearchComponent[index],
            value: { _id: e },
          };
        } else if (e !== "") {
          if (
            SELECT_OPTIONS_VALIDATE_FIELD?.includes(
              _advanceSearchComponent[index]?.field
            )
          ) {
            if (e?.length > 5) {
              showHsCodeError();
            } else {
              _advanceSearchComponent[index] = {
                ..._advanceSearchComponent[index],
                value: e,
              };
            }
          } else {
            _advanceSearchComponent[index] = {
              ..._advanceSearchComponent[index],
              value: e,
            };
          }
        }
      }
      setAdvanceSearchComponent(_advanceSearchComponent);
    }
  };

  const handleInputChangeForAdvanceSearch = _.debounce(
    (e, index, type, queryType) => {
      const _advanceSearchComponent = [...advanceSearchComponent];
      if (e !== "") {
        if (type === "input" && e !== "") {
          setReactSelectAdvanceLoading(true);
          if (
            _advanceSearchComponent[index]?.field === FILTER_OPTIONS?.HS_CODE
          ) {
            const hsCodeRegex = /^[0-9]+$/;
            if (!hsCodeRegex.test(e)) {
              setReactSelectAdvanceLoading(false);
              alert("HS CODE Only Allows Numeric Value!");
            } else {
              loadOptions(e, SEARCHTYPE?.advancedSearch, index);
            }
          } else {
            loadOptions(e, SEARCHTYPE?.advancedSearch, index);
          }
          // loadOptions(e, SEARCHTYPE?.advancedSearch, index);
          if (
            queryType === QUERYTYPE?.SEARCH &&
            !IsMultiSelectedFieldForAdvanceSearch.includes(
              _advanceSearchComponent[index]?.field
            )
          ) {
            _advanceSearchComponent[index] = {
              ..._advanceSearchComponent[index],
              value: { _id: e },
            };
          }
        } else if (e !== "") {
          _advanceSearchComponent[index] = {
            ..._advanceSearchComponent[index],
            value: e,
          };
        }
        setAdvanceSearchComponent(_advanceSearchComponent);
      }
    },
    IsMultiSelectedFieldForAdvanceSearch.includes(
      advanceSearchComponent[currentAdvanceSearchCmpIndex]?.field
    )
      ? 500
      : 10
  );

  const deleteButton = (index) => {
    const array = [...advanceSearchComponent];
    array.splice(index, 1);
    setAdvanceSearchComponent(array);
    // when click or delete btn delete option array based on index
    const _defaultOptionForSearchState = [...defaultOptionForSearchState];
    _defaultOptionForSearchState.splice(index, 1);
    setDefaultOptionForAdvancedSearch(_defaultOptionForSearchState);
    const emptyErrorArray = [...emptyErrorAdvanceSearch];
    emptyErrorArray.splice(index, 1);
    setEmptyErrorAdvanceSearch(emptyErrorArray);
  };

  const scrollToBottomAdvanceSearch = () => {
    if (advanceSearchComponentRef.current) {
      advanceSearchComponentRef.current.scrollTop =
        advanceSearchComponentRef.current.scrollHeight;
    }
  };

  // Example content inside the div
  useEffect(() => {
    scrollToBottomAdvanceSearch();
  }, [advanceSearchComponent]);

  const createTableHeader = (header, country) => {
    let ValuesArr = [...DefaultTableHeader];
    let arr = [];
    let sequencedFieldsArray = [];

    country !== currentCountries?.KAZAKHSTAN &&
      exploreShipmentSpecification?.explore_fields?.forEach((tableField) => {
        sequencedFieldsArray.push(tableField);
      });
    for (const [key, value] of Object.entries(header)) {
      arr.push(key);
    }
    arr &&
      arr?.length > 0 &&
      arr?.forEach((tableField) => {
        const fieldName = tableField.toUpperCase().trim();
        if (!fieldName?.includes(SHIPMENT_FIELD_RECORDS_TAG)) {
          if (!sequencedFieldsArray.includes(tableField)) {
            sequencedFieldsArray.push(tableField);
          }
        }
      });

    sequencedFieldsArray &&
      sequencedFieldsArray.length &&
      sequencedFieldsArray.forEach((e) => {
        const obj = {
          key: e,
          title: header[e].split("_").join(" "),
        };
        ValuesArr.push(obj);
      });
    return { ValuesArr };
  };

  const accountWiseHeader = (ValuesArr) => {
    let headerArray = [...DefaultTableHeader];
    let _viewHeader = {};
    ValuesArr?.forEach((e) => {
      if (e?.key !== "" && e?.key !== "add" && e?.key !== "checkbox") {
        if (accountTableHeader?.length > 0) {
          if (accountTableHeader?.includes(e?.key)) {
            headerArray.push(e);
            _viewHeader = { ..._viewHeader, [e.key]: true };
          } else {
            _viewHeader = { ..._viewHeader, [e.key]: false };
          }
        } else {
          headerArray.push(e);
          _viewHeader = { ..._viewHeader, [e.key]: true };
        }
      }
    });

    return { headerArray, _viewHeader };
  };

  const setTableHeaderAndData = (e, isSetAllHeader) => {
    const data = [...e?.data];
    data &&
      data.length > 0 &&
      data.map((ele) => {
        ele.isFavouriteShipment = false;
        if (currentCountry !== currentCountries?.INDIA) {
          ele.isFavourite = false;
        }
      });

    if (
      currentTradeType === DataType?.IMPORT &&
      currentCountry === currentCountries?.INDIA &&
      isBlFlag === "false"
    ) {
      const { ValuesArr } = createTableHeader(INDIA_IMPORT_COLUMN_NAME);
      const { headerArray, _viewHeader } = accountWiseHeader(ValuesArr);
      setAllHeader(ValuesArr);
      setViewHeader(_viewHeader);
      setDataTableHeader(
        accountTableHeader?.length > 0 ? headerArray : ValuesArr
      );
    } else if (
      currentTradeType === DataType?.EXPORT &&
      currentCountry === currentCountries?.INDIA &&
      isBlFlag === "false"
    ) {
      const { ValuesArr } = createTableHeader(INDIA_EXPORT_COLUMN_NAME);
      const { headerArray, _viewHeader } = accountWiseHeader(ValuesArr);
      setAllHeader(ValuesArr);
      setViewHeader(_viewHeader);
      setDataTableHeader(
        accountTableHeader?.length > 0 ? headerArray : ValuesArr
      );
    } else if (
      currentTradeType === DataType?.IMPORT &&
      currentCountry === currentCountries?.KAZAKHSTAN &&
      isBlFlag === "false"
    ) {
      const { ValuesArr } = createTableHeader(
        KAZAKHSTAN_IMPORT_RECORDS,
        currentCountries?.KAZAKHSTAN
      );
      const { headerArray, _viewHeader } = accountWiseHeader(ValuesArr);
      setAllHeader(ValuesArr);
      setViewHeader(_viewHeader);
      setDataTableHeader(
        accountTableHeader?.length > 0 ? headerArray : ValuesArr
      );
    } else if (
      currentTradeType === DataType?.EXPORT &&
      currentCountry === currentCountries?.KAZAKHSTAN &&
      isBlFlag === "false"
    ) {
      const { ValuesArr } = createTableHeader(
        KAZAKHSTAN_EXPORT_RECORDS,
        currentCountries?.KAZAKHSTAN
      );
      const { headerArray, _viewHeader } = accountWiseHeader(ValuesArr);
      setAllHeader(ValuesArr);
      setViewHeader(_viewHeader);
      setDataTableHeader(
        accountTableHeader?.length > 0 ? headerArray : ValuesArr
      );
    } else {
      let tableHeader = [...DefaultTableHeader];

      const checkedArr = [];
      filteredTaxonomy?.fields?.explore?.length > 0 &&
        filteredTaxonomy?.fields?.explore?.forEach((e) => {
          tableHeader?.push({
            key: e,
            title: e.split("_").join(" "),
          });
          checkedArr.push(e);
        });

      if (data && data.length > 0) {
        for (const [key, value] of Object.entries(data[0])) {
          if (
            !key?.includes(SHIPMENT_FIELD_RECORDS_TAG) &&
            key !== "_id" &&
            key !== "isFavourite" &&
            key !== "isFavouriteShipment"
          ) {
            if (!checkedArr.includes(key)) {
              const obj = {
                key: key,
                title: key.split("_").join(" "),
              };
              tableHeader.push(obj);
            }
          }
        }
      }
      const { headerArray, _viewHeader } = accountWiseHeader(tableHeader);
      setAllHeader(tableHeader);
      setViewHeader(_viewHeader);
      setDataTableHeader(
        accountTableHeader?.length > 0 ? headerArray : tableHeader
      );
    }
    const startRecord = 0 * rowsPerPage;

    // const _data = data.slice(startRecord, rowsPerPage + startRecord);
    const _data = data;
    for (const item of _data) {
      item &&
        Object.keys(item).length > 0 &&
        Object.keys(item).forEach((property) => {
          if (item[property] === "") {
            item[property] = "NULL";
          }

          if (
            Number(item[property]) === item[property] &&
            item[property] % 1 !== 0
          ) {
            item[property] = parseFloat(Number(item[property]).toFixed(2));
          }
        });
    }
    setDataTableRowValues(_data);

    // if (_data.length > 0) {
    //   translateButtonClick();
    // }
  };

  const addViewColumn = (payload) => {
    addViewColumns(payload).then((res) => {
      if (res) {
        getAccountViewColumns(filteredTaxonomy?._id);
        setApprovSuccessModal(true);
        setApprovSuccessModalData(ApprovSuccess_viewColumns);
        setTimeout(() => {
          setApprovSuccessModal(false);
        }, 2000);
      }
      setHandleOpenFilterModal(false);
    });
  };

  const getPayloadRelatedData = () => {
    const payload = {
      isBlFlag: isBlFlag,
      currentTradeType: currentTradeType,
      currentCountryCode: currentCountryCode,
      currentCountry: currentCountry,
      filteredTaxonomy: filteredTaxonomy,
      SHIPMENT_RESULT_TYPE_RECORDS: SHIPMENT_RESULT_TYPE_RECORDS,
      rowsPerPage: rowsPerPage,
      searchField: searchField,
      getSelectedOptionForSearchTerm: getSelectedOptionForSearchTerm,
      exploreShipmentSpecification: exploreShipmentSpecification,
      dateRange: dateRange,
      advanceSearchComponent: advanceSearchComponent,
      advanceSearchFieldOptions: advanceSearchFieldOptions,
      onSearchClickFlag: onSearchClickFlag,
      descendingAndAscending: descendingAndAscending,
      sortedColumnList: sortedColumnList,
      CheckedData: CheckedData,
      removeButtonState: removeButtonState,
      lastSelected: lastSelected,
      isExplore: true,
      getSearchOption: getSearchOption,
      mirrorData: mirrorData,
      isRemoveDuplicateButton: isRemoveDuplicateButton,
      isFromRecentSearch: isFromRecentSearch,
    };
    return payload;
  };

  const getRecommendationsValues = (paramsObj) => {
    let recommendationArray = [];
    // const recommendationCalling = () => {
    //   getRecommendation(paramsObj).then((res) => {
    //     if (res?.status === 200) {
    //       recommendationArray = [
    //         ...recommendationArray,
    //         ...res?.data?.suggestions,
    //       ];
    //       if (recommendationArray?.length >= 5) {
    //         setShowRecommendationLoader(false);
    //       }
    //       if (res?.data?.suggestions?.length > 0) {
    //         setDisplayRec(true);
    //         setRecommandationArr(recommendationArray);
    //       }
    //       if (recommendationArray?.length < 5) {
    //         setShowRecommendationLoader(true);
    //         getRecommendationByValue(paramsObj).then((res) => {
    //           if (res?.status === 200) {
    //             setShowRecommendationLoader(false);
    //             recommendationArray = [
    //               ...recommendationArray,
    //               ...res?.data?.RECORD_SET,
    //             ];
    //             if (res?.data?.RECORD_SET?.length > 0) {
    //               setDisplayRec(true);
    //               setRecommandationArr(recommendationArray);
    //             }
    //           }
    //         });
    //       }
    //     }
    //   });
    // };
    // if (
    //   searchField?.getValuesForSelectDropdown?.identifier ===
    //     defaultValue?.SEARCH_BUYER ||
    //   searchField?.getValuesForSelectDropdown?.identifier ===
    //     defaultValue?.SEARCH_SELLER
    // ) {
    //   const relatedPayload = {
    //     taxonomy_id: filteredTaxonomy?._id,
    //     tradeType: selectTrade?.tradeType,
    //     country: selectTrade?.country,
    //     searchField: searchField?.getSelectExploreField,
    //     searchTerm: getSelectedOptionForSearchTerm[0]?._id,
    //     dateRange: {
    //       startDate: dateRange?.startMonthDate,
    //       endDate: dateRange?.endMonthDate,
    //     },
    //   };
    //   getRelatedSearches(relatedPayload).then((res) => {
    //     if (res?.status === 200) {
    //       if (
    //         res?.data &&
    //         res?.data?.suggestions &&
    //         res?.data?.suggestions?.length >= 5
    //       ) {
    //         recommendationArray = res?.data?.suggestions;
    //         setDisplayRec(true);
    //         setRecommandationArr(recommendationArray);
    //         setShowRecommendationLoader(false);
    //       } else {
    //         recommendationCalling();
    //       }
    //     } else {
    //       recommendationCalling();
    //     }
    //   });
    // } else {
    //   recommendationCalling();
    // }
    let relatedPayload = {};
    if (paramsObj && Object.keys(paramsObj)?.length > 0) {
      relatedPayload = paramsObj;
    } else {
      relatedPayload = {
        taxonomy_id: filteredTaxonomy?._id,
        tradeType: selectTrade?.tradeType,
        country: selectTrade?.country,
        searchField: searchField?.getSelectExploreField,
        searchTerm: getSelectedOptionForSearchTerm[0]?._id,
        dateRange: {
          startDate: dateRange?.startMonthDate,
          endDate: dateRange?.endMonthDate,
        },
      };
    }
    getRelatedSearches(relatedPayload)
      .then((res) => {
        if (res?.status === 200) {
          if (
            res?.data &&
            res?.data?.suggestions &&
            res?.data?.suggestions?.length >= 0
          ) {
            recommendationArray = res?.data?.suggestions;
            setDisplayRec(true);
            setRecommandationArr(recommendationArray);
            setShowRecommendationLoader(false);
          } else {
            setDisplayRec(true);
            setShowRecommendationLoader(false);
          }
        } else {
          setDisplayRec(true);
          setShowRecommendationLoader(false);
        }
      })
      .catch(() => {
        setDisplayRec(true);
        setShowRecommendationLoader(false);
      });
  };

  const getRelatedSearchPayload = () => {
    const relatedPayload = {
      taxonomy_id: filteredTaxonomy?._id,
      tradeType: selectTrade?.tradeType,
      country: selectTrade?.country,
      searchField: searchField?.getSelectExploreField,
      searchTerm:
        getSelectedOptionForSearchTerm?.length > 0
          ? getSelectedOptionForSearchTerm[0]?._id
          : "",
      dateRange: {
        startDate: dateRange?.startMonthDate,
        endDate: dateRange?.endMonthDate,
      },
    };
    return relatedPayload;
  };
  const filterHSCodeAndMonth = (
    arrValueHsCodeData,
    arrValueMonthData,
    isCheckedEmpty,
    isFilter,
    filterData
  ) => {
    const groupedHsCodeData = {};

    arrValueHsCodeData.forEach((item) => {
      const parentId = item._id.toString().substring(0, 2);
      const childId = item._id.toString().substring(0, 4);
      let totalSum = item.totalSum;
      let subCount = 0;
      if (
        isFilter &&
        Object.keys(filterData)?.length > 0 &&
        filterData[FILTER_OPTIONS?.FILTER_HS_CODE]?.length > 0
      ) {
        let object = filterData[FILTER_OPTIONS?.FILTER_HS_CODE]?.find(
          (obj) => obj?._id.toString() === item._id.toString()
        );
        totalSum =
          object !== undefined && Object.keys(object).length > 0
            ? object?.totalSum
            : 0;
        subCount =
          object !== undefined && Object.keys(object).length > 0
            ? object?.count
            : 0;
      }
      if (!groupedHsCodeData[parentId]) {
        groupedHsCodeData[parentId] = {
          _id: parentId,
          isChecked: false,
          children: [],
          count: null,
          totalSum: null,
          subCount: null,
        };
      }

      const parentObj = groupedHsCodeData[parentId];

      if (!parentObj.children.find((child) => child._id === childId)) {
        parentObj.children.push({
          _id: childId,
          isChecked: false,
          children: [],
          count: null,
          subCount: null,
          totalSum: null,
        });
        parentObj.count += item?.count;
        parentObj.totalSum += totalSum;
        parentObj.subCount += subCount;
      } else {
        parentObj.count += item?.count;
        parentObj.totalSum += totalSum;
        parentObj.subCount += subCount;
      }

      let childObj = parentObj.children.find((child) => child._id === childId);
      childObj.children.push({
        _id: item._id.toString(),
        isChecked:
          CheckedData &&
          CheckedData.hasOwnProperty(FILTER_OPTIONS?.HS_CODE) &&
          Object.keys(CheckedData[FILTER_OPTIONS?.HS_CODE])?.length > 0 &&
          Object.keys(CheckedData[FILTER_OPTIONS?.HS_CODE]).includes(
            item._id.toString()
          ) &&
          !isCheckedEmpty
            ? true
            : false,
        count: item.count,
        totalSum: totalSum,
        subCount: subCount,
      });
      childObj.count += item.count;
      childObj.totalSum += totalSum;
      childObj.subCount += subCount;
    });

    const groupedMonthData = {};

    arrValueMonthData.forEach((item) => {
      const [year, month] = item._id.split("-");
      let totalSum = item.totalSum;
      let subCount = 0;
      let isChecked = false;
      if (
        isFilter &&
        Object.keys(filterData)?.length > 0 &&
        filterData[FILTER_OPTIONS?.FILTER_MONTH]?.length > 0
      ) {
        let object = filterData[FILTER_OPTIONS?.FILTER_MONTH]?.find(
          (obj) => obj?._id.toString() === item._id.toString()
        );
        totalSum =
          object !== undefined && Object.keys(object).length > 0
            ? object?.totalSum
            : 0;
        subCount =
          object !== undefined && Object.keys(object).length > 0
            ? object?.count
            : 0;
        isChecked =
          object !== undefined &&
          Object.keys(object).length > 0 &&
          CheckedData[FILTER_OPTIONS?.MONTH] &&
          CheckedData[FILTER_OPTIONS?.MONTH][item?._id]
            ? true
            : false;
      }
      if (!groupedMonthData[year]) {
        groupedMonthData[year] = {
          _id: year,
          isChecked: false,
          children: [],
          count: 0,
          totalSum: 0,
          subCount: 0,
        };
      }

      groupedMonthData[year].count += item.count;
      groupedMonthData[year].totalSum += totalSum;
      groupedMonthData[year].subCount += subCount;

      const monthName = getMonthName(parseInt(month));
      groupedMonthData[year].children.push({
        _id: monthName,
        isChecked: isChecked,
        count: item.count,
        totalSum: totalSum,
        subCount: subCount,
      });
    });
    let hsCode = Object.values(groupedHsCodeData)?.map((ele) => {
      ele?.children &&
        ele?.children?.length > 0 &&
        ele?.children?.map((child) => {
          child?.children &&
            child?.children?.length > 0 &&
            child?.children?.map((subChild) => {
              return subChild;
            });
          child.isChecked = getChildValue(child?.children);
          return child;
        });
      ele.isChecked = getChildValue(ele?.children) ? true : false;
      return ele;
    });
    let month = Object.values(groupedMonthData)?.map((ele) => {
      ele?.children &&
        ele?.children?.length > 0 &&
        ele?.children?.map((child) => {
          return child;
        });
      ele.isChecked = getChildValue(ele?.children) ? true : false;
      return ele;
    });
    setPairForTreeView({
      HS_CODE: hsCode ?? [],
      MONTH: month ?? [],
    });
  };

  const advanceSearchValidation = () => {
    let _emptyErrorAdvanceSearch = [...emptyErrorAdvanceSearch];
    const indexArray = [];
    advanceSearchComponent &&
      advanceSearchComponent?.length > 0 &&
      advanceSearchComponent?.forEach((e, index) => {
        if (e?.value === "" || (e?.value && e?.value.length === 0)) {
          _emptyErrorAdvanceSearch[index] = { advancedSearch: true };
          indexArray.push(index);
          return;
        }
      });
    setEmptyErrorAdvanceSearch(_emptyErrorAdvanceSearch);
    return indexArray;
  };

  const handleBlurSelectedOptions = () => {
    if (getSearchOption && getSearchOption?.length > 0) {
      if (
        getSelectedOptionForSearchTerm === null ||
        (Array.isArray(getSelectedOptionForSearchTerm) &&
          getSelectedOptionForSearchTerm?.length === 0)
      ) {
        if (
          searchField?.getValuesForSelectDropdown?.query ===
            QUERYTYPE?.CHOOSE ||
          IsMultiSelectedField?.includes(
            searchField?.getValuesForSelectDropdown?.field
          ) ||
          (searchField?.getValuesForSelectDropdown?.field?.includes("PROD") &&
            searchField?.getValuesForSelectDropdown?.field?.includes("DESC"))
        ) {
          setSelectedOptionForSearchTerm([getSearchOption[0]]);
        }
      } else if (!Array.isArray(getSelectedOptionForSearchTerm)) {
        const isExist = getSearchOption?.find(
          (e) => e?._id === getSelectedOptionForSearchTerm?._id
        );
        if (!isExist) {
          setSelectedOptionForSearchTerm(getSearchOption[0]);
        }
      }
    }
  };

  const handleToolTipOnSelectedCountry = useCallback(() => {
    let _selectedCountryByUser = [];
    if (
      regionWiseCountryData &&
      Object.keys(regionWiseCountryData).length > 0 &&
      latestSelectedCountries.current &&
      latestSelectedCountries.current.length > 0
    ) {
      let _allCountries = Object.values(regionWiseCountryData).flat();
      _selectedCountryByUser = _allCountries.filter((item) =>
        latestSelectedCountries.current.includes(item.country.toLowerCase())
      );
    }
    setTooltipData(_selectedCountryByUser);
  }, [regionWiseCountryData, latestSelectedCountries.current]);

  const handleMouseEnterOnSelectedCountry = () => {
    setShowSelectedCountryModal(true);
    handleToolTipOnSelectedCountry();
  };
  const handleMouseLeaveOnSelectedCountry = () => {
    setShowSelectedCountryModal(false);
  };

  const onSearchClick = (payload, sorting, removeDuplicate) => {
    setShowRecentActivities(false);
    if (!removeDuplicate && currentCountry === "INDIA") {
      setIsRemoveDuplicateButton(false);
      if (!firstTimeCalled) {
        setIsChangeRemoveDuplicateButton(false);
      }
      firstTimeCalled = false;
    }
    setPage(0);
    setHeaderTitle("");
    setGetAllFilterValues([]);
    setFilterValue([]);
    setCollapseAllFilters(!collapseAllFilters);
    if (
      (!isQueryId || !intialQueryCalled) &&
      (isFromRecentSearch === false || isFromRecentSearch === "false") &&
      (getSelectedOptionForSearchTerm === null ||
        (getSelectedOptionForSearchTerm &&
          getSelectedOptionForSearchTerm.length === 0))
    ) {
      setIsSearchValueEmpty({
        ...isSearchValueEmpty,
        searchTerm: true,
      });
      return;
    }

    const indexArray = advanceSearchValidation();
    if (indexArray?.length > 0) {
      return;
    }
    setIsFilterLoader(true);
    setLoading(true);
    if (isQueryId) {
      setLoadingMsg(LOADING);
    } else if (removeDuplicate && removeDuplicate !== undefined) {
      setLoadingMsg(RemovingDuplicateRecord);
    } else {
      setLoadingMsg(Searching);
    }

    let isCheckedOnSearch = false;

    if (CheckedData && Object.keys(CheckedData).length > 0) {
      isCheckedOnSearch = true;
      let currentAppliedFilterData = {};
      setCheckedData({});
      if (appliedFilterData.hasOwnProperty(FILTER_OPTIONS?.VIEW_COLUMNS)) {
        currentAppliedFilterData = {
          VIEW_COLUMNS: appliedFilterData[FILTER_OPTIONS?.VIEW_COLUMNS],
        };
      }
      setAppliedFilterData(currentAppliedFilterData);
    }
    onSearchClickFlag = true;
    let paramsObj = {};
    let relatedPayload = {};

    if (isQueryId && intialQueryCalled) {
      paramsObj = payload?.recordPayload;
      relatedPayload = payload?.recommendationPayload;
      delete paramsObj.resultType;
    } else {
      const requiredData = getPayloadRelatedData();
      requiredData.isRemoveDuplicateButton = removeDuplicate;
      paramsObj = packDTParams(false, isCheckedOnSearch, requiredData);
    }
    if (!isQueryId && !sorting) {
      paramsObj.resultType = RESULT_TYPE?.SEARCH_RECORDS;
    }
    if (mirrorData) {
      paramsObj.selectedCountry = {
        country: latestSelectedCountries.current,
        trade: selectTradeForPayload,
      };
    }
    setIsRemoveDuplicateButtonModal(false);
    setSearchPayload(paramsObj);

    if (!removeDuplicate) {
      getRecordCount(paramsObj)
        .then(async (e) => {
          if (e?.status === 200) {
            if (e?.data?.data[0][0] <= 400000) {
              await fetchRecordListOnSearchClick(paramsObj, relatedPayload);
              getRecommendationsValues(relatedPayload);
              if (searchField?.selectedOperator === "FULL TEXT") {
                setTimeout(() => {
                  getFiltersForSearch(paramsObj);
                }, 3500);
              } else {
                getFiltersForSearch(paramsObj);
              }
            } else {
              setLoading(false);
              setError(true);
              setErrorMsg(explore_record_limits);
            }
          } else {
            if (e?.data?.message) {
              setLoading(false);
              setIsFilterLoader(false);
              setError(true);
              setErrorMsg({
                title: "Warning",
                content: e?.data?.message,
              });
            } else {
              throw throwError(e);
            }
          }
        })
        .catch((err) => {
          catchBlock(err);
        });
    } else {
      fetchRecordListOnSearchClick(paramsObj, relatedPayload);
      onSearchTradeFilters(paramsObj)
        .then((e) => {
          setIntialQueryCalled(false);
          setIsRemoveDuplicateButtonModal(false);
          if (e?.data?.message) {
            setLoading(false);
            setIsFilterLoader(false);
            setError(true);
            setErrorMsg({
              title: "Warning",
              content: e?.data?.message,
            });
          } else if (e?.status === 401) {
            setLoading(false);
            setIsFilterLoader(false);
            setError(true);
            setErrorMsg({
              title: "Warning",
              content: ErrorData?.SESSION_TIMEOUT,
            });
          } else {
            if (e && e?.length > 0) {
              if (e[0]) {
                setGetAllSplitFilters(e[0]?.data);
                setAllFilterValuesWithCountsArr(e[0]?.data?.filter);
                setAllFilterValueArray(e[0]?.data);
                setFilterDataBasedOnSearch(e[0]?.data, true);
              } else {
                throw new Error();
              }
              if (e[1]) {
                setSummaryDetails(e[1].data);
              } else {
                throw new Error();
              }
            } else {
              throw new Error();
            }
          }
        })
        .catch((err) => {
          setIntialQueryCalled(false);
          catchBlock(err);
        });
    }
    if (mirrorData) {
      const activityPayload = {
        matchExpressions: paramsObj?.matchExpressions,
        tradeType: paramsObj?.tradeType,
        country: paramsObj?.country,
        countryCode: paramsObj?.countryCode,
      };

      const salesTrackPayload = {
        activityType: "MIRROR_SEARCH",
        activityPayload: activityPayload,
        user_id: getCookie("user_id"),
        account_id: getCookie("accountId"),
      };
      salesActivityTrack(salesTrackPayload);
    }
  };

  // const CompanyDetailOnContactClick = (keyName, companyName) => {
  //   if (
  //     (keyName[0] === "IMPORTER" && currentTradeType === "IMPORT") ||
  //     (keyName[0] === "EXPORTER" && currentTradeType === "EXPORT")
  //   ) {
  //     window.open(
  //       `/consumers/companyDetails?location=${currentCountry}&keyName=${keyName[0]}&company=${companyName}&currentTradeType=${currentTradeType}&recordID=${recordIdForClickedRow}`,
  //       "_blank"
  //     );
  //   } else {
  //     let payloadForCountryFind = {
  //       country: currentCountry,
  //       trade: currentTradeType,
  //       company: companyName,
  //       columnName: filteredTaxonomy?.fields?.get_country_column[0]?.NAME,
  //       summarizeBy: filteredTaxonomy?.fields?.get_country_column[0]?.COUNTRY,
  //       start: 1,
  //     };
  //     getCountryNameFromCompany(payloadForCountryFind)
  //       .then((res) => {
  //         if (res?.status === 200) {
  //           if (res?.data) {
  //             const _countryForCompanyDetail = res?.data?.res[0];
  //             window.open(
  //               `/consumers/companyDetails?company=${companyName}&location=${res?.data?.res}&CountryForCompanyDetail=${_countryForCompanyDetail}&currentTradeType=${currentTradeType}&recordID=${recordIdForClickedRow}`,
  //               "_blank"
  //             );
  //           }
  //         } else {
  //           throw throwError();
  //         }
  //       })
  //       .catch((err) => {
  //         catchBlock(err);
  //       });
  //   }
  // };

  const CompanyDetailOnContactClick = (keyName, companyName) => {
    window.open(
      `/consumers/companyDetails?location=${currentCountry}&keyName=${keyName[0]}&company=${companyName}&currentTradeType=${currentTradeType}&recordID=${recordIdForClickedRow}`,
      "_blank"
    );
  };

  const handleChangeTradeType = (e) => {
    const selectedValue = e.target.value;
    setTradeTypeForCountryModal(e.target.value);
    selectTradeForPayload = e.target.value;
    if (selectedValue === "all") {
      const allCountryDataRegionWise = categorizeByRegion(allCountryData);
      setRegionWiseCountryData(allCountryDataRegionWise);
    } else if (selectedValue === "import") {
      const importDataRegionWise = categorizeByRegion(importData);
      setRegionWiseCountryData(importDataRegionWise);
    } else if (selectedValue === "export") {
      const exportDataRegionWise = categorizeByRegion(exportData);
      setRegionWiseCountryData(exportDataRegionWise);
    } else if (selectedValue === "exim") {
      const eximDataRegionWise = categorizeByRegion(eximData);
      setRegionWiseCountryData(eximDataRegionWise);
    }
  };

  const handleCountryChange = (event, country) => {
    if (event?.target?.checked) {
      setSelectedCountries([...selectedCountries, country]);
    } else {
      setSelectedCountries((prevSelected) =>
        prevSelected.filter((c) => c !== country)
      );
    }
  };

  const handleSearchCountryInModal = (event) => {
    let searchValue = event?.target?.value.toLowerCase();
    if (searchValue?.length > 0) {
      setSearchQueryForCountryModal(searchValue);
      setIsSearchCountryTrue(true);
    } else {
      setSearchQueryForCountryModal(searchValue);
      setIsSearchCountryTrue(false);
    }
  };

  // for transition delay on hover selected country modal
  useEffect(() => {
    if (showSelectedCountryModal) {
      setTimeout(() => setShowClass(true), 10);
    } else {
      setShowClass(false);
    }
  }, [showSelectedCountryModal]);

  const handleUncheckAll = () => {
    setSelectedCountries([]);
  };

  // getPowerBiDashBoardDetails(paramsObj);
  const getFiltersForSearch = (paramsObj) => {
    let getTopBuyerAndSellerForRelatedSearch = {};
    onSearchTradeFilters(paramsObj)
      .then((e) => {
        setIntialQueryCalled(false);
        setIsRemoveDuplicateButtonModal(false);
        if (e?.data?.message) {
          setLoading(false);
          setIsFilterLoader(false);
          setError(true);
          setErrorMsg({
            title: "Warning",
            content: e?.data?.message,
          });
        } else if (e?.status === 401) {
          setLoading(false);
          setIsFilterLoader(false);
          setError(true);
          setErrorMsg({
            title: "Warning",
            content: ErrorData?.SESSION_TIMEOUT,
          });
        } else {
          if (e && e?.length > 0) {
            if (e[0]) {
              setGetAllSplitFilters(e[0]?.data);
              setAllFilterValuesWithCountsArr(e[0]?.data?.filter);
              setAllFilterValueArray(e[0]?.data);
              setFilterDataBasedOnSearch(e[0]?.data, true);
              getTopBuyerAndSellerForRelatedSearch.buyer =
                e?.[0]?.data?.filter?.SEARCH_BUYER?.[0]?._id;
              getTopBuyerAndSellerForRelatedSearch.seller =
                e?.[0]?.data?.filter?.SEARCH_SELLER?.[0]?._id;
            } else {
              throw new Error();
            }
            if (e[1]) {
              setSummaryDetails(e[1].data);
            } else {
              throw new Error();
            }
          } else {
            throw new Error();
          }
        }
      })
      .catch((err) => {
        setIntialQueryCalled(false);
        catchBlock(err);
      });
    relatedSearchFromFilterRef.current = getTopBuyerAndSellerForRelatedSearch;
  };

  const fetchRecordListOnSearchClick = async (payload, relatedPayload) => {
    onSearchTradeRecord(payload)
      .then((res) => {
        setIntialQueryCalled(false);
        setLoading(false);
        if (res?.data?.message) {
          setIsRemoveDuplicateButtonModal(false);
          setLoading(false);
          setIsFilterLoader(false);
          setError(true);
          setErrorMsg({
            title: "Warning",
            content: res?.data?.message,
          });
        } else if (res?.status === 401) {
          setIsRemoveDuplicateButtonModal(false);
          setLoading(false);
          setIsFilterLoader(false);
          setError(true);
          setErrorMsg({
            title: "Warning",
            content: ErrorData?.SESSION_TIMEOUT,
          });
        } else {
          if (res?.data) {
            setIntialQueryCalled(false);
            // setRecommandationArr([]);
            if (
              res?.data?.data &&
              res?.data?.data?.length &&
              res?.data?.data?.length > 0
            ) {
              setDisplayRec(true);
              setShowRecommendationLoader(true);
            }
            setDataBasedOnSearch(res?.data);
          } else {
            throw new Error();
          }
        }
      })
      .catch((err) => {
        catchBlock(err);
      });
  };

  const getPowerBiDashBoardDetails = (searchPayload) => {
    setDashboardLoading(true);
    const interval = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress + 5 <= 98 ? prevProgress + 5 : 98
      );
    }, 1000);
    getPowerBiDashBoard(searchPayload)
      .then((e) => {
        if (e?.status === 200) {
          let obj = e?.data;
          if (e?.data?.accessToken && e?.data?.datasetsId) {
            const payload = {
              datasets: [
                {
                  id: e?.data?.datasetsId,
                },
              ],

              reports: [
                {
                  allowEdit: false,
                  id: e?.data?.reportId,
                },
              ],
            };
            getPowerBi(payload, e?.data?.accessToken)
              .then((res) => {
                if (res.status === 200) {
                  obj.token = res?.data?.token;
                  _accessTokenForPowerBiReportDownload = res?.data?.token;
                  setPowerBiDashBoard(obj);
                  setIsRecordBtnChecked(!isRecordBtnChecked);
                  setProgress(100);
                  setTimeout(() => {
                    clearInterval(interval);
                    setProgress(0);
                  }, 2000);
                  setDashboardLoading(false);
                } else {
                  throw throwError(res);
                }
              })
              .catch((err) => {
                setDashboardLoading(false);
                setProgress(0);
                catchBlock(err);
              });
          } else {
            setDashboardLoading(false);
            setProgress(0);
            setLoading(false);
            setPowerBiModelOpen(true);
          }
        } else {
          setDashboardLoading(false);
          setProgress(0);
          setLoading(false);
          throw throwError(e);
        }
      })
      .catch((err) => {
        setDashboardLoading(false);
        setProgress(0);
        catchBlock(err);
      });

    const activityPayload = {
      country: searchPayload?.country,
      countryCode: searchPayload?.countryCode,
      matchExpressions: searchPayload?.matchExpressions,
      tradeType: searchPayload?.tradeType,
    };

    const salesTrackPayload = {
      activityType: "POWERBI_REPORT",
      activityPayload: activityPayload,
      user_id: getCookie("user_id"),
      account_id: getCookie("accountId"),
    };
    salesActivityTrack(salesTrackPayload);
  };
  const setSummaryDetails = (data) => {
    setSummaryData(data?.summary);
    setTotalLeftSearchCount({
      count: data?.dayQueryConsumedLimit,
      maxQueryPerDay: data?.dayQueryAlottedLimit,
    });
    setSaveQueryLimit({
      consumeCount: data?.saveQueryConsumedLimit,
      allotedCount: data?.saveQueryAllotedLimit,
    });
    const _risonQuery = data?.risonQuery;
    setRisonQuery(_risonQuery.substring(1, _risonQuery.length - 1));
    translateButtonClick();
  };
  useEffect(() => {
    if (
      filteredTaxonomy &&
      Object.keys(filteredTaxonomy).length > 0 &&
      exploreShipmentSpecification &&
      Object.keys(exploreShipmentSpecification).length > 0
    ) {
      getSortColumns({ taxonomy: filteredTaxonomy })
        .then((res) => {
          if (res?.status === 500 || res?.status === 401) {
            throw throwError(res);
          } else {
            if (res?.data?.result?.length > 0) {
              if (res?.data?.result[0]?.sortMapping?.length > 0) {
                const sortMappingColumns = res?.data?.result[0];
                let sortedColumnList = [...sortMappingColumns?.sortMapping];
                let sortingObj = {};
                sortMappingColumns &&
                  sortMappingColumns?.sortMapping?.length > 0 &&
                  sortMappingColumns?.sortMapping?.forEach((e) => {
                    sortingObj[e?.column] = SORTINGTYPE?.default;
                  });
                const findClauseSearchMonth =
                  exploreShipmentSpecification?.explore_aggregation
                    ?.matchExpressions &&
                  exploreShipmentSpecification?.explore_aggregation
                    ?.matchExpressions?.length &&
                  exploreShipmentSpecification?.explore_aggregation?.matchExpressions.find(
                    (e) => e?.identifier === defaultValue?.SEARCH_MONTH_RANGE
                  );
                if (
                  findClauseSearchMonth &&
                  Object.keys(findClauseSearchMonth)?.length > 0
                ) {
                  sortedColumnList.push({
                    column: findClauseSearchMonth?.fieldTerm,
                    defaultDataType: findClauseSearchMonth?.fieldTermTypeSuffix,
                    sortField: findClauseSearchMonth?.fieldTerm,
                  });
                  sortingObj[findClauseSearchMonth?.fieldTerm] =
                    SORTINGTYPE?.default;
                }
                setSortedColumnList(sortedColumnList);
                setDescendingAndAscending(sortingObj);
              }
            } else {
              setSortedColumnList([]);
            }
          }
        })
        .catch((err) => {
          catchBlock(err);
        });
    }
  }, [filteredTaxonomy, exploreShipmentSpecification]);

  const handleDescendingAndAscendingOrder = (column, type) => {
    let obj = descendingAndAscending;
    Object.keys(obj).length > 0 &&
      Object.keys(obj).forEach((e) => {
        obj[e] = e === column ? type : SORTINGTYPE?.default;
      });

    setDescendingAndAscending(obj);
    handleAscDesc();
  };
  function handleAscDesc() {
    if (isDisplayRec) {
      onSearchClick({}, true, isRemoveDuplicateButton);
    }
  }
  const RemoveAppliedFilter = (index, e, val) => {
    const all = { ...storeFilteredValues };
    const _CheckedData = { ...CheckedData };

    if (
      e[index][0] === FILTER_OPTIONS?.QUANTITY ||
      e[index][0] === FILTER_OPTIONS?.PRICE
    ) {
      setSelectedQuantityOrPrice({
        ...selectedQuantityOrPriceValue,
        [e[index][0]]: "",
      });

      if (e[index][0] === FILTER_OPTIONS?.QUANTITY) {
        let header = e[index][0];
        removeButtonState = {
          ...removeButtonState,
          [header]: true,
        };
        delete _CheckedData[header][val];
        delete all[header][val];
        setStoreFilterValues(all);
        setCheckedData(_CheckedData);
        if (Object.keys(all[header]).length === 0) {
          setApply({
            ...apply,
            [header]: false,
          });
        }
        if (Object.keys(_CheckedData).length === 0) {
          filterOnSearchClick(true);
        }
        return;
      }
      setApply({
        ...apply,
        [e[index][0]]: false,
      });
    }
    let header = e[index][0];

    removeButtonState = {
      ...removeButtonState,
      [e[index][0]]: true,
    };
    delete _CheckedData[header];
    delete all[header];
    setStoreFilterValues(all);
    setCheckedData(_CheckedData);

    if (Object.keys(_CheckedData).length === 0) {
      filterOnSearchClick(true);
    }
    setApply({
      ...apply,
      [header]: false,
    });
  };

  const handleToggleFilters = () => {
    setIsFiltersShown(!isFiltersShown);
  };
  const filterOptionClick = (name) => {
    switch (name) {
      case FILTER_OPTIONS?.VIEW_COLUMNS:
        setHandleOpenFilterModal(true);
        break;
      case FILTER_OPTIONS?.HS_CODE:
        setHeaderTitle(FILTER_OPTIONS?.HS_CODE);
        break;
      case FILTER_OPTIONS?.FILTER_COUNTRY:
        setHeaderTitle(FILTER_OPTIONS?.FILTER_COUNTRY);
        setGetAllFilterValues(initialAllFilterValues?.FILTER_COUNTRY ?? []);
        setFilterValue(initialAllFilterValues?.FILTER_COUNTRY ?? []);
        break;
      case FILTER_OPTIONS?.FILTER_IMPORTER:
        setHeaderTitle(FILTER_OPTIONS?.FILTER_IMPORTER);
        setGetAllFilterValues(initialAllFilterValues?.FILTER_IMPORTER ?? []);
        setFilterValue(initialAllFilterValues?.FILTER_IMPORTER ?? []);
        break;
      case FILTER_OPTIONS?.FILTER_EXPORTER:
        setHeaderTitle(FILTER_OPTIONS?.FILTER_EXPORTER);
        setGetAllFilterValues(initialAllFilterValues?.FILTER_EXPORTER ?? []);
        setFilterValue(initialAllFilterValues?.FILTER_EXPORTER ?? []);
        break;
      case FILTER_OPTIONS?.FILTER_PIN:
        setHeaderTitle(FILTER_OPTIONS?.FILTER_PIN);
        setGetAllFilterValues(initialAllFilterValues?.FILTER_PIN ?? []);
        setFilterValue(initialAllFilterValues?.FILTER_PIN ?? []);
        break;
      case FILTER_OPTIONS?.REMOVE_ORDER_OR_NULL:
        setHeaderTitle(FILTER_OPTIONS?.REMOVE_ORDER_OR_NULL);
        break;
      case FILTER_OPTIONS?.SEARCH_MODE:
        setHeaderTitle(FILTER_OPTIONS?.SEARCH_MODE);
        break;
      case FILTER_OPTIONS?.FILTER_DESTINATION_COUNTRY:
        setHeaderTitle(FILTER_OPTIONS?.FILTER_DESTINATION_COUNTRY);
        setGetAllFilterValues(
          initialAllFilterValues?.FILTER_DESTINATION_COUNTRY ?? []
        );
        setFilterValue(
          initialAllFilterValues?.FILTER_DESTINATION_COUNTRY ?? []
        );
        break;
      case FILTER_OPTIONS?.FILTER_ORIGIN_COUNTRY:
        setHeaderTitle(FILTER_OPTIONS?.FILTER_ORIGIN_COUNTRY);
        setGetAllFilterValues(
          initialAllFilterValues?.FILTER_ORIGIN_COUNTRY ?? []
        );
        setFilterValue(initialAllFilterValues?.FILTER_ORIGIN_COUNTRY ?? []);
        break;
      case FILTER_OPTIONS?.FILTER_STATE:
        setHeaderTitle(FILTER_OPTIONS?.FILTER_STATE);
        setGetAllFilterValues(initialAllFilterValues?.FILTER_STATE ?? []);
        setFilterValue(initialAllFilterValues?.FILTER_STATE ?? []);
        break;
      case FILTER_OPTIONS?.FILTER_DESTINATION_PORT:
        setHeaderTitle(FILTER_OPTIONS?.FILTER_DESTINATION_PORT);
        setGetAllFilterValues(
          initialAllFilterValues?.FILTER_DESTINATION_PORT ?? []
        );
        setFilterValue(initialAllFilterValues?.FILTER_DESTINATION_PORT ?? []);
        break;
      case FILTER_OPTIONS?.FILTER_ORIGIN_PORT:
        setHeaderTitle(FILTER_OPTIONS?.FILTER_ORIGIN_PORT);
        setGetAllFilterValues(initialAllFilterValues?.FILTER_ORIGIN_PORT ?? []);
        setFilterValue(initialAllFilterValues?.FILTER_ORIGIN_PORT ?? []);
        break;
      case FILTER_OPTIONS?.FILTER_REPORTING_COUNTRY:
        setHeaderTitle(FILTER_OPTIONS?.FILTER_REPORTING_COUNTRY);
        setGetAllFilterValues(
          initialAllFilterValues?.FILTER_REPORTING_COUNTRY ?? []
        );
        setFilterValue(initialAllFilterValues?.FILTER_REPORTING_COUNTRY ?? []);
        break;
      case FILTER_OPTIONS?.FILTER_PORT:
        setHeaderTitle(FILTER_OPTIONS?.FILTER_PORT);
        setGetAllFilterValues(initialAllFilterValues?.FILTER_PORT ?? []);
        setFilterValue(initialAllFilterValues?.FILTER_PORT ?? []);
        break;
      case FILTER_OPTIONS?.FILTER_FOREIGN_PORT:
        setHeaderTitle(FILTER_OPTIONS?.FILTER_FOREIGN_PORT);
        setGetAllFilterValues(
          initialAllFilterValues?.FILTER_FOREIGN_PORT ?? []
        );
        setFilterValue(initialAllFilterValues?.FILTER_FOREIGN_PORT ?? []);
        break;
      case FILTER_OPTIONS?.MONTH:
        setHeaderTitle(FILTER_OPTIONS?.MONTH);
        break;
      case FILTER_OPTIONS?.QUANTITY:
        setHeaderTitle(FILTER_OPTIONS?.QUANTITY);
        if (initialAllFilterValues?.FILTER_UNIT_QUANTITY !== undefined) {
          initialAllFilterValues?.FILTER_UNIT_QUANTITY.length > 0 &&
            initialAllFilterValues?.FILTER_UNIT_QUANTITY?.forEach((e) => {
              e._id = e?._id?.toUpperCase();
            });
          const filterQuantityOptions =
            initialAllFilterValues?.FILTER_UNIT_QUANTITY?.filter(
              (item) => item?._id && item._id.toLowerCase() !== "null"
            );
          setGetAllFilterValues(filterQuantityOptions ?? []);
          setFilterValue(filterQuantityOptions ?? []);
        } else {
          setGetAllFilterValues([]);
          setFilterValue([]);
        }
        break;
      case FILTER_OPTIONS?.PRICE:
        setHeaderTitle(FILTER_OPTIONS?.PRICE);
        const arr = [];
        if (
          initialAllFilterValues?.FILTER_CURRENCY_PRICE_USD !== undefined &&
          initialAllFilterValues?.FILTER_CURRENCY_PRICE_USD?.length > 0
        ) {
          arr.push(initialAllFilterValues?.FILTER_CURRENCY_PRICE_USD[0]);
        }
        if (
          initialAllFilterValues?.FILTER_CURRENCY_PRICE_INR !== undefined &&
          initialAllFilterValues?.FILTER_CURRENCY_PRICE_INR?.length > 0
        ) {
          arr.push(initialAllFilterValues?.FILTER_CURRENCY_PRICE_INR[0]);
        }
        if (
          initialAllFilterValues?.FILTER_DUTY !== undefined &&
          initialAllFilterValues?.FILTER_DUTY?.length > 0
        ) {
          if (
            initialAllFilterValues?.FILTER_DUTY[0]?.metaTag?.length > 0 &&
            initialAllFilterValues?.FILTER_DUTY[0]?.metaTag[0]?.currency !== ""
          ) {
            arr.push(initialAllFilterValues?.FILTER_DUTY[0]);
          }
        }
        setFilterValue(arr);
        setGetAllFilterValues(arr);
        break;
      case BUTTON_NAME?.ANALYZE:
        dataAnalyze();
        break;
      case BUTTON_NAME?.REPORT:
        getPowerBiDashBoardDetails(searchPayload);
        // if (powerBiDashBoard.accessToken && powerBiDashBoard.datasetsId) {
        // setIsRecordBtnChecked(!isRecordBtnChecked);
        // } else {
        // setPowerBiModelOpen(true);
        // }
        break;
      case BUTTON_NAME?.ADD_ALL_RECORDS_TO_WORKSPACE:
        setWorkspaceAddType(defaultValue?.MATCH_EXPRESSIONS);
        summaryOrWorkSpaceClick();
        setWorkSpaceNameExistOrNot(false);
        setNameFromExistingWorkSpace("");
        setIsNameEmpty(false);
        setHeaderTitle(BUTTON_NAME?.ADD_ALL_RECORDS_TO_WORKSPACE);
        break;
      case BUTTON_NAME?.ADD_SELECTED_RECORDS_TO_WORKSPACE:
        if (selectedWorkSpaceShipments?.length === 0) {
          setIsNothingSelected(true);
        } else {
          setWorkspaceAddType(defaultValue?.RECORDS_SELECTIONS);
          setIsOpenWorkspaceModal(true);
          setWorkSpaceNameExistOrNot(false);
          setNameFromExistingWorkSpace("");
          summaryOrWorkSpaceClick();
        }
        setTimeout(() => {
          setIsNothingSelected(false);
        }, 2000);
        setHeaderTitle(BUTTON_NAME?.ADD_SELECTED_RECORDS_TO_WORKSPACE);
        break;
      case BUTTON_NAME?.SAVE_QUERY:
        setSaveQueryOpenModal(true);
        setHandleOpenFilterModal(false);
        break;
      case BUTTON_NAME?.UPDATE_QUERY:
        onSearchClickFlag = true;
        setLoading(true);
        setLoadingMsg(Update_Query);
        const requiredData = getPayloadRelatedData();
        let payload = packDTParams(false, false, requiredData);
        payload.title = saveTitleSavedQuery;
        payload.tradeTotalRecords = summaryData?.SUMMARY_RECORDS;
        const saveQueryId = isQueryId ? queryId : storeUpdateSavedId;
        if (mirrorData) {
          payload.mirrorData = true;
        }
        updateSavedQuery(payload, saveQueryId)
          .then((e) => {
            if (e?.data) {
              setApprovSuccessModal(true);
              setApprovSuccessModalData(Update_Query_Successfully);
              setTimeout(() => {
                setApprovSuccessModal(false);
              }, 2000);
            } else {
              throw throwError(e);
            }
            setLoading(false);
          })
          .catch((err) => {
            catchBlock(err);
          });
        break;
      case BUTTON_NAME?.REQUESTED_BUYERS:
        window.open(`/requested/buyers`, "_blank");
        break;
      case BUTTON_NAME?.REMOVE_DUPLICATE:
        setIsRemoveDuplicateButton(true);
        setIsRemoveDuplicateButtonModal(true);
        break;
      case BUTTON_NAME?.SET_DEFAULT:
        setIsRemoveDuplicateButton(false);
        setIsRemoveDuplicateButtonModal(true);
        break;
      default:
        break;
    }
  };

  //work space
  const handleChangeForRadioButton = (event) => {
    setSelectedValueRadioButton(event.target.value);
  };
  const handleChangeForInputWorkSpace = (event) => {
    const noSpecialCharacterRegex = /^(?!.*[?%#]).*$/;
    const isValidWorkSpaceName = noSpecialCharacterRegex.test(
      event.target.value
    );
    if (isValidWorkSpaceName) {
      setIsNameNotAllowed(false);
      const _getNameForWorkSpace = event?.target?.value;
      setNameForWorkSpace(event?.target?.value);
      if (isNameEmpty) {
        if (_getNameForWorkSpace && _getNameForWorkSpace?.length > 0) {
          setIsNameEmpty(false);
        }
      }
    } else {
      setIsNameNotAllowed(true);
    }
  };
  const handleChangeForExistingWorkSpaces = (event) => {
    if (event?.target?.value === "Select Workspace") {
      setErrorMsgForExistingWorkspace(true);
    } else {
      setErrorMsgForExistingWorkspace(false);
    }
    const currentWorkspaceOperation =
      getTemplateData &&
      getTemplateData?.data &&
      getTemplateData?.data?.find((e) => e?.name === event?.target?.value);
    setCurrentSelectedWorkspaceDetails(currentWorkspaceOperation);
    const _getNameFromExistingWorkSpace = event?.target?.value;
    if (_getNameFromExistingWorkSpace) {
      setNameFromExistingWorkSpace(_getNameFromExistingWorkSpace);
    }
    if (isNameEmpty) {
      if (
        _getNameFromExistingWorkSpace &&
        _getNameFromExistingWorkSpace?.length > 0
      ) {
        setIsNameEmpty(false);
      }
    }
  };
  const validationForExplore = () => {
    if (selectedValueRadioButton === "create") {
      if (getNameForWorkSpace === "") {
        setIsNameEmpty(true);
        return false;
      }
    }

    if (selectedValueRadioButton === "existing") {
      if (getNameFromExistingWorkSpace === "") {
        return false;
      }
    }

    return true;
  };

  const savedClicked = () => {
    if (validationForExplore() === false) {
      return;
    }

    if (
      selectedValueRadioButton === "existing" &&
      getNameFromExistingWorkSpace === "Select Workspace"
    ) {
      setErrorMsgForExistingWorkspace(true);
      return;
    }

    setErrorMsgForExistingWorkspace(false);
    setLoading(true);
    setModalForAddingWorkspace(false);

    if (selectedValueRadioButton === "create") {
      let data = {
        accountId: props?.CLIENT_ACCOUNT_ID,
        workSpaceName: getNameForWorkSpace,
      };
      verifyWorkspaceExistenceAPIHandler(data);
    } else {
      setLoadingMsg(Approving_Purchase);
      const templateApprovalPayload = packWorkspacePurchaseApprovalParams();
      templateApprovalPayload.accountId = props?.CLIENT_ACCOUNT_ID;
      templateApprovalPayload.workspaceType =
        selectedValueRadioButton === "existing" ? "EXISTING" : "NEW";

      templateApprovalPayload.workspaceId =
        currentSelectedWorkspaceDetails?._id;
      templateApproval(templateApprovalPayload)
        .then((e) => {
          if (e?.status === 500 || e?.status === 401) {
            throw throwError(e);
          } else {
            setLoading(false);
            if (e?.data) {
              if (!e?.data?.message) {
                setCountryShipmentModel(true);
                setCountryShipmentModelData(e?.data);
                // setSelectedWorkSpaceShipments([]);
              }
            } else {
              throw new Error();
            }
          }
        })
        .catch((err) => {
          catchBlock(err);
        });
    }
  };
  const packWorkspacePurchaseApprovalParams = () => {
    let paramsObj = {
      accountId: props?.CLIENT_ACCOUNT_ID,
      matchExpressions: searchPayload?.matchExpressions,
      recordsSelections:
        workspaceAddType === defaultValue?.RECORDS_SELECTIONS
          ? selectedWorkSpaceShipments
          : [],
      sortTerm: exploreShipmentSpecification?.explore_aggregation?.sortTerm,
      tradeType: currentTradeType,
      isSuggestion: searchPayload?.isSuggestion,
    };
    if (isBlFlag === "true") {
      paramsObj.country = "bl";
    } else {
      paramsObj.country = filteredTaxonomy?.country
        ?.toString()
        ?.toUpperCase()
        ?.trim();
    }
    if (currentCountry === "INDIA" && isRemoveDuplicateButton) {
      paramsObj.removeDuplicateRelatedData =
        filteredTaxonomy?.fields?.remove_duplicate;
    }
    if (workspaceAddType === defaultValue?.RECORDS_SELECTIONS) {
      paramsObj.tradeRecords =
        selectedWorkSpaceShipments && selectedWorkSpaceShipments?.length;
    } else if (workspaceAddType === defaultValue?.MATCH_EXPRESSIONS) {
      paramsObj.tradeRecords = summaryData?.SUMMARY_RECORDS;
    }
    if (mirrorData && workspaceAddType === defaultValue?.MATCH_EXPRESSIONS) {
      paramsObj.isAllRecordSelected = 1;
    }
    return paramsObj;
  };
  const verifyWorkspaceExistenceAPIHandler = (workspaceData) => {
    setLoading(true);
    setLoadingMsg(Verifying_Workspace_Availability);
    verifyWorkSpaceExistence(workspaceData)
      .then((e) => {
        if (e?.status === 500 || e?.status === 401) {
          throw throwError(e);
        } else {
          if (e?.data) {
            if (!e?.data?.data) {
              const currentWorkspacePurchases =
                packWorkspacePurchaseApprovalParams();
              setLoadingMsg(Approving_Purchase);
              currentWorkspacePurchases.workspaceType =
                selectedValueRadioButton === "existing" ? "EXISTING" : "NEW";
              templateApproval(currentWorkspacePurchases)
                .then((e) => {
                  if (e?.status === 500 || e?.status === 401) {
                    throw throwError(e);
                  } else if (e?.status === 409) {
                    setLoading(false);
                    setError(true);
                    setErrorMsg({
                      title: "Info",
                      content:
                        "You don't have enough point for create a workspace",
                    });
                  } else {
                    setLoading(false);
                    if (!e?.data?.message) {
                      setModalForAddingWorkspace(false);
                      setCountryShipmentModel(true);
                      setCountryShipmentModelData(e?.data);
                      // setSelectedWorkSpaceShipments([]);
                    }
                  }
                })
                .catch((err) => {
                  // addErrorLog(err?.msg ? err?.msg : err, 2466);
                  catchBlock(err);
                });
            } else {
              setModalForAddingWorkspace(true);
              setTimeout(() => {
                setLoading(false);
              }, 1500);
              setWorkSpaceNameExistOrNot(true);
            }
          } else {
            throw new Error();
          }
        }
      })
      .catch((err) => {
        // addErrorLog(err?.msg ? err?.msg : err, 2492);
        catchBlock(err);
      });
  };
  const packWorkspaceRecordsAdditionParams = () => {
    let paramsObj = packWorkspacePurchaseApprovalParams();
    if (filteredTaxonomy !== undefined) {
      paramsObj.country = filteredTaxonomy?.bl_flag
        ? "bl"
        : filteredTaxonomy?.country;
      paramsObj.countryCodeISO3 = filteredTaxonomy?.code_iso_3;
      paramsObj.countryCodeISO2 = filteredTaxonomy?.code_iso_2;
      paramsObj.flagUri = filteredTaxonomy?.flag_uri;
      paramsObj.taxonomyId = filteredTaxonomy?._id;
      paramsObj.indexSpecifications = filteredTaxonomy?.fields?.indexes;
      paramsObj.points_purchase = filteredTaxonomy?.points_purchase;
      paramsObj.workspaceType =
        selectedValueRadioButton === "existing" ? "EXISTING" : "NEW";
      paramsObj.workspaceId =
        selectedValueRadioButton === "existing"
          ? currentSelectedWorkspaceDetails?._id
          : "";
      // paramsObj.workspaceName = currentSelectedWorkspaceDetails.name;
      let workspaceName = getNameForWorkSpace;
      if (workspaceName.length === 0)
        workspaceName = getNameFromExistingWorkSpace;
      paramsObj.workspaceName = workspaceName;
      paramsObj.workspaceElasticConfig =
        filteredTaxonomy?.fields?.workspace_elastic_config;
      paramsObj.allFields = filteredTaxonomy?.fields?.all;
    }
    return paramsObj;
  };
  const confirmWorkspaceRecord = () => {
    setCountryShipmentModel(false);
    setLoading(true);
    setLoadingMsg(Preparing_workspace);
    let payload = packWorkspaceRecordsAdditionParams();
    payload.accountId = props?.CLIENT_ACCOUNT_ID;
    payload.userId = props?.CLIENT_USER_ID;
    payload.pointsToBeDeducted = countryShipmentModelData?.purchasableCost;
    addWorkspaceRecords(payload)
      .then((e) => {
        if (e?.status === 500 || e?.status === 401) {
          throw throwError(e);
        } else {
          if (e) {
            if (e?.status === 409) {
              setError(true);
              setErrorMsg({
                title: "Warning",
                content: e?.data?.message,
              });
            } else if (e?.status === 202) {
              setError(true);
              setSelectedWorkSpaceShipments([]);
              setErrorMsg({
                title: "Info",
                content: e?.data?.message,
              });
            } else if (e?.data?.id) {
              setApprovSuccessModal(true);
              setApprovSuccessModalData({
                title:
                  selectedValueRadioButton === "existing"
                    ? "Successfully Modified Workspace!"
                    : "Successfully Created Workspace!",
                footerTitle: "Operation Successfull",
              });
              setSelectedWorkSpaceShipments([]);
              setTimeout(() => {
                setApprovSuccessModal(false);
              }, 3000);
            } else {
              throw new Error();
            }
          } else {
            throw new Error();
          }
        }
        setLoading(false);
        setSelectedRow([]);
      })
      .catch((err) => {
        // addErrorLog(err?.msg ? err?.msg : err, 2727);
        catchBlock(err);
      });
  };
  const summaryOrWorkSpaceClick = () => {
    setWorkSpaceNameExistOrNot(false);
    setLoading(true);
    const data = {
      userId: props?.CLIENT_USER_ID,
      tradeType: currentTradeType,
      countryCode: currentCountryCode,
    };
    templateForWorkspace(data)
      .then((e) => {
        setLoading(true);
        setLoadingMsg(Retrieving_Workspaces);
        if (e?.status === 500 || e?.status === 401) {
          throw throwError(e);
        } else {
          if (e?.data) {
            setTemplateData(e?.data);
            const arr = [...e?.data?.data];
            const getArr = [];

            arr &&
              arr.length > 0 &&
              arr.forEach((e) => {
                let obj = {
                  label: e?.name,
                  option: e?.name,
                };
                getArr.push(obj);
              });

            getArr.unshift(Select_Workspace);
            if (getArr.length > 0) {
              setNameFromExistingWorkSpace(getArr[0]?.option);
            }
            setOptionsForExistingTemplate(getArr);
            setTimeout(() => {
              setLoading(false);
              setModalForAddingWorkspace(true);
            }, 1000);
          } else {
            throw new Error();
          }
        }
      })
      .catch((err) => {
        catchBlock(err);
      });
    setNameForWorkSpace("");
  };
  const handleSelectedShipments = (e, selectedShipmentId) => {
    let _selectedWorkSpaceShipments = [...selectedWorkSpaceShipments] ?? [];
    // if (e?.target?.checked) {
    //   if (!_selectedWorkSpaceShipments.includes(selectedShipmentId)) {
    //     _selectedWorkSpaceShipments.push(selectedShipmentId);
    //   }
    // } else {
    //   _selectedWorkSpaceShipments = _selectedWorkSpaceShipments.filter(
    //     (shipmentId) => shipmentId !== selectedShipmentId
    //   );
    // }

    if (e) {
      if (!_selectedWorkSpaceShipments.includes(selectedShipmentId)) {
        _selectedWorkSpaceShipments.push(selectedShipmentId);
      }
    } else {
      _selectedWorkSpaceShipments = _selectedWorkSpaceShipments.filter(
        (shipmentId) => shipmentId !== selectedShipmentId
      );
    }

    let arr = [...getSelectedRow] ?? [];
    if (!arr.includes(selectedShipmentId)) {
      arr.push(selectedShipmentId);
    } else {
      let index = arr.indexOf(selectedShipmentId);
      arr.splice(index, 1, 0);
    }
    setSelectedRow(arr);
    setSelectedWorkSpaceShipments(_selectedWorkSpaceShipments);
  };

  const applyButtonClicked = (event, title) => {
    setHandleOpenFilterModal(false);
  };
  const handleApplyFilter = (e) => {
    applyButtonClicked(e, headerTitle);
    setApply({
      ...apply,
      [headerTitle]: true,
    });
    setPage(0);
    removeButtonState = {
      ...removeButtonState,
      [headerTitle]: false,
    };
  };

  useEffect(() => {
    let _appliedFilterData = { ...appliedFilterData };
    if (_appliedFilterData && Object.keys(_appliedFilterData)?.length) {
      delete _appliedFilterData[FILTER_OPTIONS?.VIEW_COLUMNS];
    }
    if (apply && Object.keys(apply)?.length > 0) {
      for (const [keys, values] of Object.entries(apply)) {
        if (!values) {
          delete _appliedFilterData[keys];
        }
      }
    }
    if (JSON.stringify(CheckedData) !== JSON.stringify(_appliedFilterData)) {
      setCheckedData(_appliedFilterData);
    }
  }, [apply]);

  useEffect(() => {
    if (headerTitle !== FILTER_OPTIONS?.VIEW_COLUMNS) {
      setAppliedFilterData(storeFilteredValues);
    }
  }, [storeFilteredValues]);

  if (appliedFilterData) {
    for (const [key, value] of Object.entries(appliedFilterData)) {
      for (const [keys, values] of Object.entries(value)) {
        if (!values && values !== 0) {
          const _appliedFilterData = appliedFilterData;
          delete _appliedFilterData[key][keys];
          setAppliedFilterData(_appliedFilterData);
        }
      }
    }
  }
  useEffect(() => {
    if (CheckedData && Object.keys(CheckedData).length > 0) {
      filterOnSearchClick();
    }
  }, [CheckedData]);
  const setFilterDataBasedOnSearch = (
    e,
    isCheckedEmpty,
    isFilter,
    filterData
  ) => {
    if (e) {
      setInitialAllFilterValues(e?.filter);
      if (!mirrorData) {
        e?.filter?.FILTER_HS_CODE &&
          e?.filter?.FILTER_HS_CODE?.length &&
          e?.filter?.FILTER_HS_CODE?.forEach((e) => {
            e._id = sanitizeHsCodeDigitBoundaries(
              e?._id.toString(),
              filteredTaxonomy?.hs_code_digit_classification
            );
          });
      }

      let hsCode = [];
      let month = [];

      if (e?.filter && e?.filter?.FILTER_HS_CODE) {
        hsCode = [...e?.filter?.FILTER_HS_CODE];
      }
      if (e?.filter && e?.filter?.FILTER_MONTH) {
        month = [...e?.filter?.FILTER_MONTH];
      }

      filterHSCodeAndMonth(hsCode, month, isCheckedEmpty, isFilter, filterData);
      setIsFilterLoader(false);
    } else {
      setLoading(false);
      setIsFilterLoader(false);

      setError(true);
      setErrorMsg({
        title: "Error",
        content: ErrorData?.SOMETHING_WENT_WRONG,
      });
    }
  };

  const setDataBasedOnFilter = (e, isCheckedEmpty) => {
    if (e?.data) {
      if (!mirrorData) {
        if (e?.data && e?.data?.length > 0) {
          e?.data?.forEach((obj) => {
            if (obj.hasOwnProperty(FILTER_OPTIONS?.HS_CODE)) {
              obj.HS_CODE = sanitizeHsCodeDigitBoundaries(
                obj?.HS_CODE,
                filteredTaxonomy?.hs_code_digit_classification
              );
            }
          });
        }
      }
      setAllRecord(e?.data);
      setSearchValueData(e);
      setTableHeaderAndData(e, false);
      setLoading(false);
      // setIsFiltersShown(true);
      setVisibleData(true);
      setIsFilterLoader(false);
      setDisplayRec(true);
    } else {
      setLoading(false);
      setError(true);
      setErrorMsg({
        title: "Error",
        content: ErrorData?.SOMETHING_WENT_WRONG,
      });
      setIsFilterLoader(false);
    }
  };
  const setDataBasedOnSearch = (e) => {
    if (e?.data) {
      if (!mirrorData) {
        if (e?.data && e?.data?.length > 0) {
          e?.data?.forEach((obj) => {
            if (obj.hasOwnProperty(FILTER_OPTIONS?.HS_CODE)) {
              obj.HS_CODE = sanitizeHsCodeDigitBoundaries(
                obj?.HS_CODE,
                filteredTaxonomy?.hs_code_digit_classification
              );
            }
          });
        }
      }

      setAllRecord(e?.data);
      setSearchValueData(e);
      setVisibleData(true);
      setTableHeaderAndData(e, true);
      setDisplayRec(true);
      // setIsFilterLoader(false);
      // setIsFiltersShown(true);
      // setLoading(false);
      getFavouritCompany(e?.data);
      getFavouriteShipment(e?.data);
    } else {
      setLoading(false);
      setError(true);
      setErrorMsg({
        title: "Error",
        content: ErrorData?.SOMETHING_WENT_WRONG,
      });
      setIsFilterLoader(false);
    }
  };

  const fetchRecordListOnFilter = (payload, isCheckedEmpty) => {
    onSearchTradeRecord(payload)
      .then((res) => {
        setLoading(false);
        if (res?.data?.message) {
          setIsFilterLoader(false);
          setError(true);
          setErrorMsg({
            title: "Warning",
            content: res?.data?.message,
          });
        } else if (res?.status === 401) {
          setIsFilterLoader(false);
          setError(true);
          setErrorMsg({
            title: "Warning",
            content: ErrorData?.SESSION_TIMEOUT,
          });
        } else {
          if (res?.data) {
            setIntialQueryCalled(false);
            setSelectedRow([]);
            setDataBasedOnFilter(res?.data, isCheckedEmpty);
          } else {
            throw new Error();
          }
        }
      })
      .catch((err) => {
        catchBlock(err);
      });
  };

  const filterOnSearchClick = (isCheckedEmpty) => {
    const _CheckedData = { ...CheckedData };
    for (const [key, value] of Object.entries(_CheckedData)) {
      if (Object.keys(value).length === 0) {
        delete _CheckedData[key];
        setCheckedData(_CheckedData);
        setStoreFilterValues(_CheckedData);
      }
    }
    onSearchClickFlag = true;
    setLoading(true);
    setIsFilterLoader(false);
    setLoadingMsg(Searching);
    let paramsObj = {};
    if (isQueryId && intialQueryCalled) {
      paramsObj = savedQueryData?.payload;
      delete paramsObj.resultType;
    } else {
      const requiredData = getPayloadRelatedData();
      paramsObj = packDTParams(isCheckedEmpty, false, requiredData);
    }

    if (mirrorData) {
      paramsObj.selectedCountry = {
        country: latestSelectedCountries.current,
        trade: selectTradeForPayload,
      };
    }
    paramsObj.resultType = RESULT_TYPE?.FILTER;
    setSearchPayload(paramsObj);
    fetchRecordListOnFilter(paramsObj, isCheckedEmpty);
    setHeaderTitle("");
    onSearchTradeFilters(paramsObj)
      .then((e) => {
        if (e?.data?.message) {
          setLoading(false);
          setIsFilterLoader(false);
          setError(true);
          setErrorMsg({
            title: "Warning",
            content: e?.data?.message,
          });
        } else if (e?.status === 401) {
          setLoading(false);
          setIsFilterLoader(false);
          setError(true);
          setErrorMsg({
            title: "Warning",
            content: ErrorData?.SESSION_TIMEOUT,
          });
        } else {
          if (e && e?.length > 0) {
            if (e[0]?.data) {
              setIntialQueryCalled(false);
              setGetAllSplitFilters(e[0]?.data);
              setAllFilterValuesWithCountsArr(e[0]?.data?.filter);
              let filter = { ...getAllFilterValueArray };
              setFilterDataBasedOnSearch(
                filter,
                isCheckedEmpty,
                true,
                e[0]?.data?.filter
              );
            } else {
              throw new Error();
            }
            if (e[1]?.data) {
              setSummaryDetails(e[1]?.data);
            } else {
              throw new Error();
            }
          } else {
            throw new Error();
          }
        }
      })
      .catch((err) => {
        catchBlock(err);
      });

    // getPowerBiDashBoardDetails(paramsObj);
  };

  const handleChangePage = (newPage) => {
    setLoading(true);
    setTimeout(() => {
      onSearchClickFlag = true;
      setLoadingMsg(FetchData);
      setLoading(false);
    }, 800);

    let offset = (newPage - 1) * rowsPerPage;

    if (offset + 10 > 1000) {
      setLoading(false);
      setError(true);
      setErrorMsg(RecordLimit);
    } else {
      setPage(newPage - 1);
      const startRecord = (newPage - 1) * rowsPerPage;
      const data = allRecord;
      for (const item of data) {
        item &&
          Object.keys(item).length > 0 &&
          Object.keys(item).forEach((property) => {
            if (item[property] === "") {
              item[property] = "NULL";
            }

            if (
              Number(item[property]) === item[property] &&
              item[property] % 1 !== 0
            ) {
              item[property] = Number(item[property]).toFixed(2);
            }
          });
      }
      setDataTableRowValues(data);
      translateButtonClick();
    }
  };

  const handleChangeRowsPerPage = (value) => {
    setLoading(true);
    setTimeout(() => {
      const row = Number(value);
      setLoadingMsg(FetchData);
      onSearchClickFlag = true;
      setRowsPerPage(row);
      setSelectedRow([]);
      setPage(0);
      const startRecord = 0 * row;
      const data = allRecord;
      for (const item of data) {
        item &&
          Object.keys(item).length > 0 &&
          Object.keys(item).forEach((property) => {
            if (item[property] === "") {
              item[property] = "NULL";
            }
            if (
              Number(item[property]) === item[property] &&
              item[property] % 1 !== 0
            ) {
              item[property] = parseFloat(Number(item[property]).toFixed(2));
            }
          });
      }
      setDataTableRowValues(data);
      translateButtonClick();
      setLoading(false);
    }, 800);
  };
  const getFavouriteShipment = (recordData) => {
    getFavouriteShipments()
      .then((fav) => {
        if (fav?.status === 500 || fav?.status === 401) {
          throw throwError(fav);
        } else {
          if (fav?.favoriteShipment) {
            let _storeFavShipmentId = { ...storeFavShipmentId };
            const isBl = isBlFlag === "true" ? 1 : 0;

            const filterShipment =
              fav?.favoriteShipment &&
              fav?.favoriteShipment?.filter(
                (e) =>
                  e?.country.toUpperCase() === currentCountry &&
                  e?.tradeType === currentTradeType &&
                  e?.bl_flag === isBl
              );

            const favArr =
              fav?.favoriteShipment &&
              fav?.favoriteShipment?.filter((e) => e?.isFavorite === true);
            setOpenToast({
              ...openToast,
              totalSelectedShipment: favArr?.length,
            });

            const _storeRowShipmentArrId = [...storeRowShipmentArrId];
            filterShipment &&
              filterShipment.length > 0 &&
              filterShipment.forEach((ele) => {
                _storeFavShipmentId = {
                  ..._storeFavShipmentId,
                  [ele?.recordRow?._id]: ele?._id,
                };

                recordData &&
                  recordData.length > 0 &&
                  recordData.forEach((record) => {
                    if (record?._id === ele?.recordRow?._id) {
                      record.isFavouriteShipment = ele?.isFavorite;
                      if (
                        !_storeRowShipmentArrId.includes(ele?.recordRow._id)
                      ) {
                        _storeRowShipmentArrId.push(ele?.recordRow._id);
                      }
                    } else if (!record?.isFavouriteShipment) {
                      record.isFavouriteShipment = false;
                    }
                  });
              });

            setStoreFavShipmentId(_storeFavShipmentId);
            setStoreRowShipmentArrId(_storeRowShipmentArrId);
          } else {
            throw new Error();
          }
        }
      })
      .catch((err) => {
        catchBlock(err);
      });
  };
  const getFavouritCompany = (recordData) => {
    getFavouriteCompany()
      .then((fav) => {
        if (fav?.status === 500 || fav?.status === 401) {
          throw throwError(fav);
        } else {
          if (fav?.favoriteShipment) {
            let _storeFavCompanyId = { ...storeFavCompanyId };
            const isBl = isBlFlag === "true" ? 1 : 0;
            const filterCompanyData =
              fav?.favoriteShipment &&
              fav?.favoriteShipment?.length > 0 &&
              fav.favoriteShipment.filter(
                (e) =>
                  e?.country.toUpperCase() === currentCountry &&
                  e?.tradeType === currentTradeType &&
                  e?.bl_flag === isBl
              );
            const _storeFavCompanyName = [...storeFavCompanyName];
            const favArr =
              fav?.favoriteShipment &&
              fav?.favoriteShipment.length &&
              fav?.favoriteShipment?.filter((e) => e?.isFavorite === true);

            setOpenToast({
              ...openToast,
              totalSelectedCompany: favArr?.length,
            });

            filterCompanyData &&
              filterCompanyData.length > 0 &&
              filterCompanyData.forEach((e) => {
                _storeFavCompanyId = {
                  ..._storeFavCompanyId,
                  [e?.columnValue?.split(" ").join("_")]: e._id,
                };
                recordData &&
                  recordData.length &&
                  recordData.forEach((record) => {
                    if (
                      e?.columnValue ===
                      record[e?.columnName?.split(" ").join("_")]
                    ) {
                      record.isFavourite = e?.isFavorite;

                      if (!_storeFavCompanyName.includes(e?.columnValue)) {
                        _storeFavCompanyName.push(e?.columnValue);
                      }
                    } else if (!record.isFavourite) {
                      record.isFavourite = false;
                    }
                  });
              });

            setStoreFavCompanyId(_storeFavCompanyId);
            setStoreFavCompanyName(_storeFavCompanyName);
          } else {
            throw new Error();
          }
        }
      })
      .catch((err) => {
        catchBlock(err);
      });
  };
  const handleClearFilter = () => {
    setIsClearFilter(!isClearFilter);
  };
  const dataAnalyze = () => {
    // setHeaderTitle(event?.target?.innerText?.split(" ")?.join("_"));
    setLoading(true);
    setLoadingMsg(Analyzing_data);
    const count = summaryData?.SUMMARY_RECORDS;
    onSearchAnalyze(searchPayload, count)
      .then((e) => {
        let array = [];
        if (e?.status === 200) {
          if (e?.data?.data?.length > 0) {
            array = e?.data?.data.flat();
            setAnalyzeData(array);
            setLoading(false);
            setOpenAnalyzePage(true);
            setIsRecordBtnChecked(true);
          } else {
            setAnalyzeData([]);
            setLoading(false);
          }
        } else {
          throw throwError(e);
        }
      })
      .catch((err) => {
        catchBlock(err);
      });
  };

  const onClickSavedQuery = () => {
    onSearchClickFlag = true;
    let payload = searchPayload;
    payload.tradeTotalRecords = summaryData?.SUMMARY_RECORDS;
    // payload.resultType = "SEARCH_RECORDS";
    payload.title = saveTitleSavedQuery;
    setLoading(true);
    setLoadingMsg(Saved_Query);
    if (mirrorData) {
      payload.mirrorData = true;
    }
    savedQuery(payload)
      .then((e) => {
        if (e?.status === 500 || e?.status === 401) {
          throw throwError(e);
        } else {
          if (e?.data?.message) {
            setLoading(false);
            setSaveQueryOpenModal(false);
            setError(true);
            setErrorMsg({
              title: "Warning",
              content: e?.data?.message,
            });
          } else {
            setStoreUpdatedSavedId(e?.data?.save_id);
            setLoading(false);
            setSaveQueryOpenModal(false);
            setApprovSuccessModal(true);
            setApprovSuccessModalData(Saved_Query_Successfully);
            setTimeout(() => {
              setApprovSuccessModal(false);
            }, 2000);
          }
        }
      })
      .catch((err) => {
        setSaveQueryOpenModal(false);
        catchBlock(err);
      });
  };

  const getIECSearchDetails = async (iecNumber) => {
    let iec = iecNumber;
    if (typeof iecNumber == "number" && iecNumber.toString().length < 10) {
      iec = sanitizeHsCodeDigitBoundaries(iecNumber, 10);
    }
    const Arr = await getIECSearchData(iec);
    return Arr.data;
  };

  const handleCollapseOpenModal = async (column, row, index) => {
    setRecordIdForClickedRow(row?._id);
    setLoading(true);
    setLoadingMsg(FetchData);
    setIsCollapseModelDetails({
      companyDetails: true,
      productDetails: true,
      otherDetails: true,
      IECDetails: true,
      consigneeDetails: true,
    });
    setStoreEyeModalRowData(row);
    if (currentCountry === currentCountries?.INDIA) {
      const IECArr = await getIECSearchDetails(row?.IEC);
      setIECDetails(IECArr);
    }

    if (
      currentCountry === currentCountries?.INDIA &&
      currentTradeType === DataType?.EXPORT
    ) {
      const shipmentDetailsPayload = {
        userId: row?._id,
        shipment_number: row?.BILL_NO,
      };
      setAddConsigneeDetailsPayload({
        account_id: props?.CLIENT_ACCOUNT_ID,
        country_date: row?.EXP_DATE,
        country_port: row?.INDIAN_PORT,
        shipmentBillNumber: row?.BILL_NO,
        recordRow: row,
      });
      ConsigneeShipmentDetailsAPIHandler(shipmentDetailsPayload);
    }
    const companyIndex =
      column &&
      column?.length &&
      column?.findIndex((e) => e?.key === "isFavourite");
    const shipmentIndex =
      column &&
      column?.length &&
      column?.findIndex((e) => e?.key === "isFavouriteShipment");
    if (column && companyIndex !== -1) {
      column?.splice(companyIndex, 1);
    }
    if (column && shipmentIndex !== -1) {
      column?.splice(shipmentIndex, 1);
    }

    if (column) {
      column?.push(
        {
          title: "isFavourite",
          key: "isFavourite",
        },
        {
          title: "isFavouriteShipment",
          key: "isFavouriteShipment",
        }
      );
    }
    let arr = [];
    column &&
      column?.length &&
      column?.forEach((col) => {
        let obj = {
          [col?.title]: row[col?.key],
        };
        arr.push(obj);
      });
    if (arr && arr?.length) {
      setModalArr(arr);
    }
    setLoading(false);
    setOpenCollapseModal(true);
    setPrevOrNext(index);
  };

  const ConsigneeShipmentDetailsAPIHandler = (shipmentDetailsPayload) => {
    getConsigneeShipmentDetails(shipmentDetailsPayload)
      .then((e) => {
        if (e?.status === 500 || e?.status === 401) {
          throw throwError(e);
        } else {
          if (e) {
            if (e?.message === "Request Cosignee Data") {
              setConsigneeDetails({
                message: "Request Buyer",
                shipmentAllotedLimits: e?.shipmentAllotedLimits,
                shipmentConsumedLimits: e?.shipmentConsumedLimits,
                disabled: false,
              });
            } else if (e?.message === "Shipment Data Request is in process .") {
              setConsigneeDetails({
                message: "Request Processing",
                shipmentAllotedLimits: e?.shipmentAllotedLimits,
                shipmentConsumedLimits: e?.shipmentConsumedLimits,
                disabled: true,
              });
            } else {
              setConsigneeDetails(e);
            }
          } else {
            throw new Error();
          }
        }
      })
      .catch((err) => {
        // addErrorLog(err?.msg ? err?.msg : err, 2617);
        catchBlock(err);
      });
  };

  const previousDataTableRecords = async (direction) => {
    nextDataTableRecords(direction);
  };

  const nextDataTableRecords = async (direction) => {
    let _prevOrNext = prevOrNext;
    if (direction === "next") {
      _prevOrNext = prevOrNext + 1;
    } else {
      _prevOrNext = prevOrNext - 1;
    }
    setLoading(true);
    setLoadingMsg(FetchData);
    setPrevOrNext(_prevOrNext);
    if (
      getSearchValueData &&
      getSearchValueData?.data &&
      getSearchValueData?.data?.length
    ) {
      const row = getSearchValueData?.data[_prevOrNext];
      setStoreEyeModalRowData(row);
      let arr = [];
      dataTableHeader.forEach((col) => {
        let obj = {
          [col?.title]: row[col?.key],
        };
        arr.push(obj);
      });

      if (currentCountry === currentCountries?.INDIA) {
        const IECArr = await getIECSearchDetails(row?.IEC);
        setIECDetails(IECArr);
      }

      if (
        currentCountry === currentCountries?.INDIA &&
        currentTradeType === DataType?.EXPORT
      ) {
        const shipmentDetailsPayload = {
          userId: row?._id,
          shipment_number: row?.BILL_NO,
        };
        setAddConsigneeDetailsPayload({
          account_id: props?.CLIENT_ACCOUNT_ID,
          country_date: row?.EXP_DATE,
          country_port: row?.INDIAN_PORT,
          shipmentBillNumber: row?.BILL_NO,
          recordRow: row,
        });

        ConsigneeShipmentDetailsAPIHandler(shipmentDetailsPayload);
      }
      setModalArr(arr);
    }
    setLoading(false);
  };

  const handlePrintTableRecords = useReactToPrint({
    content: () => PrintRef?.current,
  });
  const handleSummaryDataForTrade = () => {
    const rowData =
      getSearchValueData &&
      getSearchValueData?.data &&
      getSearchValueData?.data?.filter((row, index) => {
        return row?._id === storeEyeModalRowData?._id;
      })[0];
    let currentSelector = "";
    let currentSearchField = "";
    if (currentTradeType?.toUpperCase() === DataType?.IMPORT) {
      currentSelector = rowData?.IMPORTER_NAME
        ? rowData?.IMPORTER_NAME
        : rowData?.CONSIGNEE_NAME;
      currentSearchField = "IMPORTER_NAME";
    } else if (currentTradeType?.toUpperCase() === DataType?.EXPORT) {
      currentSelector = rowData?.EXPORTER_NAME;
      currentSearchField = "EXPORTER_NAME";
    }
    // navigate(`/trade/summary?${currentSearchField}=${currentSelector.split(" ").join("_")}&tradeType=${currentTradeType}&country=${currentCountry}`);

    if (
      currentSearchField &&
      currentSelector &&
      currentTradeType &&
      currentCountry &&
      dateRange?.startMonthDate &&
      dateRange?.endMonthDate &&
      filteredTaxonomy?._id
    ) {
      window.open(
        `/trade/summary?${currentSearchField}=${currentSelector
          .split(" ")
          .join(
            "_"
          )}&tradeType=${currentTradeType}&country=${currentCountry}&startDate=${
          dateRange?.startMonthDate
        }&endDate=${dateRange?.endMonthDate}&taxonomy_id=${
          filteredTaxonomy?._id
        }&dataEndDate=${exploreShipmentSpecification?.data_end_date}`,
        "_blank"
      );
    }
  };
  const handleCollapseDetails = (e) => {
    const name = e?.target?.id;

    if (name) {
      setIsCollapseModelDetails({
        ...isCollapseModelDetails,
        [name]: !isCollapseModelDetails[name],
      });
    }
  };
  const handleFavouriteCompany = (favCompany, favColumnName) => {
    let favCompanyName = "";
    if (favColumnName) {
      if (
        currentCountry === currentCountries?.INDIA &&
        currentTradeType === DataType?.IMPORT
      ) {
        favCompanyName = "IMPORTER_NAME";
      } else if (
        currentCountry === currentCountries?.INDIA &&
        currentTradeType === DataType?.EXPORT
      ) {
        favCompanyName = "EXPORTER_NAME";
      } else {
        favCompanyName = favColumnName?.split(" ").join("_");
      }
    } else {
      favCompanyName =
        currentTradeType === DataType?.IMPORT
          ? "IMPORTER_NAME"
          : "EXPORTER_NAME";
    }
    setTimeout(() => {
      setOpenToast({
        ...openToast,
        open: false,
      });
    }, 5000);

    let dateKeysForFavCompany = [
      "IMP_DATE",
      "EXP_DATE",
      "REGISTRATION_DATE",
      "SAILING_DATE",
      "SAILING_DT",
      "ACTUAL_ARRIVAL_DATE",
      "DATE",
      "DECLARATION_DATE",
      "IMPORT_DATE",
      "EXPORT_DATE",
      "DATE_OF_ISSUE",
      "REGISTRY_DATE",
      "SHIPMENT_DATE",
    ];

    let dateForFavCompany = dateKeysForFavCompany?.find(
      (key) => storeEyeModalRowData?.[key]
    );

    storeEyeModalRowData.isFavourite = !storeEyeModalRowData?.isFavourite;
    let isFavouritePayload = {
      country: currentCountry.toLowerCase(),
      tradeType: currentTradeType,
      bl_flag: isBlFlag === "true" ? 1 : 0,
      // columnName: currentTradeType === "IMPORT" ? "IMPORTER NAME" : "EXPORTER NAME",
      columnName: favCompanyName,
      columnValue: favCompany,
      date: dateForFavCompany,
      recordRow: storeEyeModalRowData,
      isFavorite: storeEyeModalRowData?.isFavourite,
      taxonomyId: filteredTaxonomy?._id,
      user_id: props.CLIENT_USER_ID,
      countryCode: currentCountryCode,
    };
    addOrUpdateDataOfIsFavourite(isFavouritePayload);
  };
  const addOrUpdateDataOfIsFavourite = (payload) => {
    if (storeFavCompanyName.includes(payload?.columnValue)) {
      payload._id =
        storeFavCompanyId[payload?.columnValue?.split(" ").join("_")];
      let arr = [];
      let Row = payload?.recordRow;
      dataTableHeader &&
        dataTableHeader?.length &&
        dataTableHeader?.forEach((col) => {
          let obj = {
            [col?.title]: Row[col?.key],
          };
          arr.push(obj);
        });
      if (arr && arr?.length > 0) {
        setModalArr(arr);
      }
      updateFavouriteCompany(payload).then((e) => {
        const leftCount = openToast?.totalSelectedCompany;
        setOpenToast({
          ...openToast,
          open: true,
          type: "company",
          color: storeEyeModalRowData?.isFavourite ? "green" : "red",
          name: payload.columnValue,
          totalSelectedShipment: e?.allotedCount,
          leftShipmentCount: e?.allotedCount - e?.consumedCount,
        });
      });
    } else {
      addFavouriteCompany(payload).then((e) => {
        if (e?.message) {
          setIsFavCompanyOrShipment(true);
          setFavCompanyOrShipmentLimit({
            title: "Company Limit",
            msg: e?.message,
          });
        } else {
          setOpenToast({
            ...openToast,
            open: true,
            type: "company",
            color: storeEyeModalRowData?.isFavourite ? "green" : "red",
            name: payload.columnValue,
            totalSelectedShipment: e?.allotedCount,
            leftShipmentCount: e?.allotedCount - e?.consumedCount,
          });
          let _storeFavCompanyName = [...storeFavCompanyName];
          if (!_storeFavCompanyName.includes(payload?.columnValue)) {
            _storeFavCompanyName.push(payload?.columnValue);
          }

          setStoreFavCompanyName(_storeFavCompanyName);
          setStoreFavCompanyId({
            ...storeFavCompanyId,
            [payload.columnValue.split(" ").join("_")]: e?.id,
          });

          let arr = [];
          let Row = payload?.recordRow;
          dataTableHeader &&
            dataTableHeader?.length &&
            dataTableHeader.forEach((col) => {
              let obj = {
                [col?.title]: Row[col?.key],
              };
              arr.push(obj);
            });
          if (arr && arr?.length) {
            setModalArr(arr);
          }
        }
      });
    }
  };
  const handleFavouriteShipment = () => {
    setTimeout(() => {
      setOpenToast({
        ...openToast,
        open: false,
      });
    }, 5000);
    storeEyeModalRowData.isFavouriteShipment =
      !storeEyeModalRowData?.isFavouriteShipment;

    let isFavouriteShipmentPayload = {
      country: currentCountry?.toLowerCase(),
      tradeType: currentTradeType,
      bl_flag: isBlFlag === "true" ? 1 : 0,
      recordRow: storeEyeModalRowData,
      taxonomyId: filteredTaxonomy?._id,
      user_id: props?.CLIENT_USER_ID,
    };

    addOrUpdateDataOfIsFavouriteShipment(isFavouriteShipmentPayload);
  };
  const addOrUpdateDataOfIsFavouriteShipment = (payload) => {
    if (storeRowShipmentArrId.includes(payload?.recordRow?._id)) {
      payload._id = storeFavShipmentId[payload?.recordRow?._id];
      let arr = [];

      let Row = payload?.recordRow;
      dataTableHeader &&
        dataTableHeader?.length &&
        dataTableHeader?.forEach((col) => {
          let obj = {
            [col?.title]: Row[col?.key],
          };
          arr.push(obj);
        });
      if (arr && arr?.length) {
        setModalArr(arr);
      }
      updateFavouriteShipment(payload).then((e) => {
        setOpenToast({
          ...openToast,
          open: true,
          type: "shipment",
          color: storeEyeModalRowData?.isFavouriteShipment ? "green" : "red",
          name: "",
          totalSelectedShipment: e?.allotedCount,
          leftShipmentCount: e?.allotedCount - e?.consumedCount,
        });
      });
    } else {
      addFavouriteShipment(payload).then((e) => {
        if (e && e?.message) {
          setIsFavCompanyOrShipment(true);
          setFavCompanyOrShipmentLimit({
            title: "Shipment Limit",
            msg: e?.message,
          });
        } else {
          setOpenToast({
            ...openToast,
            name: "",
            type: "shipment",
            open: true,
            color: storeEyeModalRowData?.isFavouriteShipment ? "green" : "red",
            totalSelectedShipment: e?.allotedCount,
            leftShipmentCount: e?.allotedCount - e?.consumedCount,
          });
          let _storeRowShipmentArrId = [...storeRowShipmentArrId];
          if (!_storeRowShipmentArrId.includes(payload?.recordRow?._id)) {
            _storeRowShipmentArrId.push(payload?.recordRow?._id);
          }

          setStoreFavShipmentId({
            ...storeFavShipmentId,
            [payload.recordRow._id]: e?.id,
          });

          let arr = [];
          let Row = payload?.recordRow;
          dataTableHeader &&
            dataTableHeader?.length &&
            dataTableHeader?.forEach((col) => {
              let obj = {
                [col?.title]: Row[col?.key],
              };
              arr.push(obj);
            });
          if (arr && arr?.length > 0) {
            setModalArr(arr);
          }
          setStoreRowShipmentArrId(_storeRowShipmentArrId);
        }
      });
    }
  };
  const handleAddConsigneeRequest = () => {
    addConsigneeRequest(addConsigneeDetailsPayload)
      .then((e) => {
        if (e?.status === 500 || e?.status === 401) {
          throw throwError(e);
        } else {
          if (e?.data) {
            if (e?.data === "Request Submitted Successfully.") {
              setApprovSuccessModal(true);
              setApprovSuccessModalData(Request_Submitted_Successfully);
              setTimeout(() => {
                setApprovSuccessModal(false);
              }, 3000);
              setConsigneeDetails({
                allotedLimits: e?.shipmentAllotedLimits,
                consumedLimit: e?.shipmentConsumedLimits,
                message: "Request Processing",
                disabled: true,
              });
            }
          } else {
            throw new Error();
          }
        }
      })
      .catch((err) => {
        catchBlock(err);
      });
  };
  let reloadPageFlag = false;

  const reloadPage = () => {
    const getCountryCode =
      specificationData &&
      specificationData?.countryNames &&
      specificationData?.countryNames?.length > 0 &&
      specificationData?.countryNames.find(
        (e) => e?.country.toUpperCase() === selectTrade?.country.toUpperCase()
      );
    if (isColumnName) {
      if (selectTrade?.country !== currentCountry) {
        navigate(
          `?tradeType=${selectTrade?.tradeType}&countryCode=${
            getCountryCode?.code_iso_3
          }&country=${selectTrade?.country}&blFlag=${
            selectTrade?.dataType === "true" ? true : false
          }`
        );
      } else {
        navigate(
          `?tradeType=${
            selectTrade?.tradeType
          }&countryCode=${currentCountryCode}&country=${
            selectTrade?.country
          }&blFlag=${selectTrade?.dataType === "true" ? true : false}`
        );
      }
    } else {
      navigate(
        `?tradeType=${selectTrade?.tradeType}&countryCode=${
          getCountryCode?.code_iso_3
        }&country=${selectTrade?.country}&blFlag=${
          selectTrade?.dataType === "true" ? true : false
        }`
      );
    }
    reloadPageFlag = true;
    if (reloadPageFlag) {
      window.location.reload();
    }
    reloadPageFlag = false;
  };

  const handleCountryModalOpen = () => {
    if (latestSelectedCountries.current.length <= 0) {
      selectTradeForPayload = "all";
      setTradeTypeForCountryModal("all");
      const customCountryByRegion = categorizeByRegion(allCountryData);
      setRegionWiseCountryData(customCountryByRegion);
    }
    setCountryModalOpenClose(!countryModalOpenClose);
  };

  const dataTable = useCallback(() => {
    return (
      <>
        <CombineTable
          columns={dataTableHeader}
          handleCollapseOpenModal={handleCollapseOpenModal}
          rows={dataTableRowValues}
          rowsPerPage={rowsPerPage}
          showPagination={true}
          totalAvailableData={summaryData?.SUMMARY_RECORDS}
          page={page}
          descendingAndAscending={descendingAndAscending}
          isExplore={true}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleDescendingAndAscendingOrder={handleDescendingAndAscendingOrder}
          getSelectedRow={getSelectedRow}
          handleSelectedShipments={handleSelectedShipments}
          tableScroll={false}
          mirrorData={mirrorData}
          setLoading={setLoading}
          loading={loading}
          allRecord={allRecord}
        />
      </>
    );
  }, [
    dataTableHeader,
    dataTableRowValues,
    getSelectedRow,
    advanceSearchComponent,
    selectedWorkSpaceShipments,
    accountTableHeader,
    summaryData,
    allRecord,
  ]);

  const selectComponent = useCallback(() => {
    return (
      <SelectComponent
        isQueryId={isQueryId}
        pageTitle={pageTitle}
        selectTrade={selectTrade}
        setSelectTrade={setSelectTrade}
        btnIcon={BookmarksIcon}
        btnName={"Set Trade"}
        handelChangeSelect={handelChangeSelect}
        currentCountry={currentCountry}
        currentTradeType={currentTradeType}
        isBlFlag={isBlFlag}
        dateRange={dateRange}
        setDateRange={setDateRange}
        btnClick={reloadPage}
        isExplore={true}
        btnShow={true}
        mirrorData={mirrorData}
      />
    );
  }, [selectTrade, dateRange, exploreShipmentSpecification]);

  const summary = useCallback(() => {
    return <Summary data={summaryData} isFromExplore={true} />;
  }, [summaryData]);

  const searchComponent = useCallback(() => {
    return (
      <SearchComponent
        mirrorData={mirrorData}
        handleSelectChange={handleSelectChange}
        handleChangeForSelectedDropdownValue={
          handleChangeForSelectedDropdownValue
        }
        handleInputChangeForSearchTerm={handleInputChangeForSearchTerm}
        searchField={searchField}
        setSearchField={setSearchField}
        reactSelectLoading={reactSelectLoading}
        getSearchOption={getSearchOption}
        getSelectedOptionForSearchTerm={getSelectedOptionForSearchTerm}
        addButton={addButton}
        advanceSearchComponent={advanceSearchComponent}
        currentCountry={currentCountry}
        currentTradeType={currentTradeType}
        isBlFlag={isBlFlag}
        selectTrade={selectTrade}
        onSearchClick={onSearchClick}
        isSearchValueEmpty={isSearchValueEmpty}
        advanceSearchFieldOptions={advanceSearchFieldOptions}
        handleBlurSelectedOptions={handleBlurSelectedOptions}
        isExplore={true}
        handleCountryModalOpen={handleCountryModalOpen}
        tooltipData={tooltipData}
        handleMouseEnterOnSelectedCountry={handleMouseEnterOnSelectedCountry}
        handleMouseLeaveOnSelectedCountry={handleMouseLeaveOnSelectedCountry}
        showClass={showClass}
        showSelectedCountryModal={showSelectedCountryModal}
      />
    );
  }, [
    searchField,
    getSearchOption,
    getSelectedOptionForSearchTerm,
    isSearchValueEmpty,
    selectTrade,
    advanceSearchComponent,
    dateRange,
    exploreShipmentSpecification,
    reactSelectLoading,
    intialQueryCalled,
    tooltipData,
    showSelectedCountryModal,
    showClass,
  ]);

  // useEffect(() => {
  //   let middleContent = document.getElementById("middle-content-explore")
  //   middleContent.style.height = "";
  //   middleContent.classList.remove("middle-content-height")
  // }, [])

  // changes for saved query table
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  var row1 = [];
  var arr = [];
  const [getQuery, setQuery] = useState([]);

  const deleteSaveQuery = (id, index) => {
    setDeleteModal(true);
    setDeleteModalOpen(true);
    setDeleteId(id);
  };

  const redirectPage = async (e) => {
    let country = {};
    let isFilterData = false;

    await getTaxonomyCountryList()
      .then((res) => {
        if (res?.status === 500 || res?.status === 401) {
          throw throwError(e);
        } else {
          setLoading(false);
          country =
            res?.data &&
            res?.data?.length > 0 &&
            res?.data?.find(
              (item) => item?.code_iso_3 === e?.payload?.countryCode
            );
        }
      })
      .catch((err) => {
        catchBlock(err);
      });

    getSavedQuery(props.CLIENT_ACCOUNT_ID)
      .then((ele) => {
        if (ele?.status === 500 || ele?.status === 401) {
          throw throwError(ele);
        } else {
          if (ele?.data) {
            const allQueryData = [...ele.data];
            const filterQueryData =
              allQueryData &&
              allQueryData?.length > 0 &&
              allQueryData.find((data) => data?._id === e?._id);
            const checkedArr =
              filterQueryData?.payload?.matchExpressions.filter((e) => {
                return e?.identifier?.split("_")[0] === "FILTER";
              });

            if (checkedArr.length > 0) {
              // checked any filter are there or not in clicked query
              isFilterData = true;
            }
            if (e?.payload?.mirrorData) {
              navigate(
                `/consumers/mirrorData?id=${e?._id}&title=${e?.payload?.title}&filter=${isFilterData}`
              );
            } else {
              if (e?.payload?.country === "bl") {
                navigate(
                  `/countries/shipments/explorer?tradeType=${
                    e?.payload?.tradeType
                  }&countryCode=${
                    e?.payload?.countryCode
                  }&country=${country?.country?.toUpperCase()}&id=${
                    e?._id
                  }&title=${
                    e?.payload.title
                  }&blFlag=true&filter=${isFilterData}`
                );
              } else {
                navigate(
                  `/countries/shipments/explorer?tradeType=${
                    e?.payload?.tradeType
                  }&countryCode=${
                    e?.payload?.countryCode
                  }&country=${e?.payload?.country.toUpperCase()}&id=${
                    e?._id
                  }&title=${
                    e?.payload?.title
                  }&blFlag=false&filter=${isFilterData}`
                );
              }
            }
          } else {
            throw new Error();
          }
        }
      })
      .catch((err) => {
        catchBlock(err);
      });
  };

  const deleteClick = () => {
    setDeleteModalOpen(false);
    setLoading(true);
    setLoadingMsg(Delete);

    deleteSaveQueryData(deleteId)
      .then((e) => {
        if (e?.status === 500 || e?.status === 401) {
          throw throwError(e);
        } else {
          if (e) {
            getSavedQueryData();
            setTimeout(() => {
              setLoading(false);
            }, 2000);
          } else {
            throw new Error();
          }
        }
      })
      .catch((err) => {
        catchBlock(err);
      });
  };

  const getSavedQueryData = () => {
    getSaveQuery(null, props.CLIENT_ACCOUNT_ID)
      .then((e) => {
        if (e?.status === 500 || e?.status === 401) {
          throw throwError(e);
        } else {
          if (e?.data) {
            row1 = [];
            arr = [...e.data];
            if (arr && arr?.length > 0) {
              arr.sort((a, b) => b?.created_at - a?.created_at);
            }
            arr &&
              arr?.length > 0 &&
              arr.forEach((ele, index) => {
                index++;
                let obj = {
                  title: ele.payload.title,
                  date: moment(new Date(ele.created_at)).format("DD-MM-YYYY"),
                  country: ele.payload.country,
                  tradeType: ele.payload.tradeType,
                  action: (
                    <div>
                      <Tooltip title={"View SaveQuery"} arrow>
                        <RemoveRedEyeIcon
                          onClick={() => redirectPage(ele)}
                          style={{
                            color: "#005d91",
                            margin: "0px 5px 0px 4px",
                            height: "1em",
                            cursor: "pointer",
                          }}
                          fontSize="medium"
                          className="table-row-icon"
                        />
                      </Tooltip>
                      <Tooltip title={"Delete"} arrow>
                        <DeleteForeverIcon
                          onClick={(e) => deleteSaveQuery(ele?._id, index - 2)}
                          style={{
                            color: "red",
                            margin: "0px 5px 0px 4px",
                            height: "1em",
                            cursor: "pointer",
                          }}
                          fontSize="medium"
                          className="table-row-icon"
                        />
                      </Tooltip>
                    </div>
                  ),
                };
                row1.push(obj);
              });
            setQuery(row1);
          } else {
            throw new Error();
          }
        }
      })
      .catch((err) => {
        catchBlock(err);
      });
  };
  useEffect(() => {
    if (!isFromRecentSearch) {
      getSavedQueryData();
    }
  }, []);

  const handleClickForRecentActivity = (columns, rows, index) => {
    let _recentUserActivityQuery = recentUserActivityQuery.map((item) => {
      return JSON.parse(item);
    });

    let countryCode = specificationData?.countryNames?.find(
      (item) =>
        item?.country ==
        rows?.country.charAt(0).toUpperCase() +
          rows?.country.slice(1).toLowerCase()
    );
    if (rows?.country === "Mirror_table") {
      navigate(`/consumers/mirrorData?isFromRecentSearch=${true}`);
    } else {
      navigate(
        `/countries/shipments/explorer?tradeType=${
          rows?.tradeType
        }&countryCode=${countryCode?.code_iso_3}&country=${
          rows?.country
        }&blFlag=${
          rows?.country === "bl" ? true : false
        }&isFromRecentSearch=${true}`
      );
    }
    localStorage.setItem(
      "searchPayload",
      JSON.stringify(_recentUserActivityQuery[index])
    );
    window.location.reload();
  };

  // powerbi report download
  const powerBiReportDownloadHandleChange = (e) => {
    setPowerBiReportPageName(e?.target?.value);
  };

  const RecommendationsWrapper = useCallback(
    () => (
      <Recommendations
        recommandationArr={recommandationArr}
        setSelectedOptionForSearchTerm={setSelectedOptionForSearchTerm}
        getSelectedOptionForSearchTerm={getSelectedOptionForSearchTerm}
        onSearchClick={() => {
          onSearchClick({}, false, isRemoveDuplicateButton);
        }}
        loadOptions={loadOptions}
        setSearchField={setSearchField}
        searchField={searchField}
        currentTradeType={currentTradeType}
        isShowRecommendationLoader={false}
        setPage={setPage}
        relatedSearchFromFilter={relatedSearchFromFilterRef.current}
      />
    ),
    [
      recommandationArr,
      setSelectedOptionForSearchTerm,
      getSelectedOptionForSearchTerm,
      onSearchClick,
      isRemoveDuplicateButton,
      loadOptions,
      setSearchField,
      searchField,
      currentTradeType,
      setPage,
      relatedSearchFromFilterRef.current,
    ]
  );

  // Request for country in explore popup
  const handleTicketManager = () => {
    navigate("/ticket-manager", {
      state: {
        countryName: mirrorCountryData?.country,
        tradeType: mirrorCountryData?.trade,
        upgradePlan: true,
      },
    });
  };

  const handleOkayClick = () => {
    setRequestForCountryModal(false);
  };

  const handleInaccessibleCountryPopUp = (inaccessibleCountryDetail) => {
    setMirrorCountryData(inaccessibleCountryDetail);
    setRequestForCountryModal(true);
  };

  return (
    <Container
      id="ExploreCountryShipment"
      style={{ padding: "0px" }}
      maxWidth="xxl"
    >
      {dashboardLoading && <PercentageLoader value={progress} />}
      {loading && (
        <Loading title={loadingMsg.title} content={loadingMsg.content} />
      )}
      {error && (
        <ErrorLoading
          errorMsg={errorMsg}
          open={error}
          setOpen={setError}
          mirrorData={mirrorData}
        />
      )}
      {powerBiModelOpen && (
        <ErrorLoading
          errorMsg={{
            title: "Info",
            content: "We are working on dashboard for this country",
          }}
          open={powerBiModelOpen}
          setOpen={setPowerBiModelOpen}
        />
      )}
      {/* Nothing Selected when click on Add workspace Btn */}
      {isNothingSelected && (
        <Loading
          title={"Shipments Selected"}
          content={"Select 1 or more records to add to workspace"}
          loader={"hide"}
        />
      )}
      <ApprovedSuccess
        Open={approvSuccessModal}
        title={approvSuccessModalData.title}
        footerTitle={approvSuccessModalData.footerTitle}
      />
      {/* Fav Toast */}
      <Toast openToast={openToast} setOpenToast={setOpenToast} />
      <div
        className="ExploreCountryShipment-main-container"
        ref={exploreContainerRef}
      >
        {isRecordBtnChecked && !openAnalyzePage && (
          <div>
            <div className="exploreCountryShipment-select-container paddingAllLeftRight">
              {selectComponent()}
            </div>

            <div className="exploreCountryShipment-search-container paddingAllLeftRight">
              <Grid container>
                <Grid item xs={isDisplayRec ? 9 : 12}>
                  <div className="search_advancesearch_container">
                    {searchComponent()}

                    {advanceSearchComponent?.length > 0 && (
                      <div
                        style={{
                          maxHeight:
                            isVisibleData && advanceSearchComponent?.length > 3
                              ? "270px"
                              : "",
                          overflow:
                            isVisibleData && advanceSearchComponent?.length > 3
                              ? "overlay"
                              : "",
                        }}
                        ref={advanceSearchComponentRef}
                      >
                        {advanceSearchComponent?.map((e, index) => {
                          return (
                            <div style={{ margin: "10px 0px" }}>
                              <AdvanceSearchComponent
                                index={index}
                                reactSelectAdvanceLoading={
                                  reactSelectAdvanceLoading
                                }
                                addButton={addButton}
                                advanceSearchComponent={advanceSearchComponent}
                                advanceSearchValue={
                                  advanceSearchComponent[index]
                                }
                                advanceSearchFieldOptions={
                                  advanceSearchFieldOptions
                                }
                                handleAdvanceSearchOption={
                                  handleAdvanceSearchOption
                                }
                                defaultOptionForSearchState={
                                  defaultOptionForSearchState[index]
                                }
                                handleInputChangeForAdvanceSearch={
                                  handleInputChangeForAdvanceSearch
                                }
                                deleteButton={deleteButton}
                                onSearchClick={onSearchClick}
                                emptyErrorAdvanceSearch={
                                  emptyErrorAdvanceSearch
                                }
                              />
                            </div>
                          );
                        })}
                      </div>
                    )}

                    {totalLeftSearchCount.count !== undefined && (
                      <div className="set-search-count">
                        You have made{" "}
                        <span className="set-red notranslate">
                          {totalLeftSearchCount.count}
                        </span>{" "}
                        searches today and you have left
                        <span className="set-red notranslate">
                          {" "}
                          {totalLeftSearchCount.maxQueryPerDay -
                            totalLeftSearchCount.count}
                        </span>{" "}
                        searches today
                      </div>
                    )}
                  </div>
                </Grid>
                {/* {isDisplayRec && getPipe()} */}
                {isDisplayRec && (
                  <>
                    <Grid
                      item
                      xs={3}
                      style={{
                        position: "relative",
                      }}
                    >
                      <span
                        className="pipe-line"
                        style={{
                          height: "90%",
                          marginTop: "10px",
                        }}
                      />
                      {RecommendationsWrapper()}
                    </Grid>
                  </>
                )}
              </Grid>
            </div>

            {/* {showRecentActivities && !isFromRecentSearch && (
              <div className="paddingAllLeftRight">
                <RecentUserActivity
                  handleClickForRecentActivity={handleClickForRecentActivity}
                  recentUserActivityData={recentUserActivityData}
                />
              </div>
            )} */}

            {isVisibleData && (
              <>
                <div className="summary-btn-container paddingAllLeftRight">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    className="summary-explore"
                  >
                    {summary()}
                  </div>
                  <ExploreButtonComponent
                    storeUpdateSavedId={storeUpdateSavedId}
                    isQueryId={isQueryId}
                    currentCountry={currentCountry}
                    filterOptionClick={filterOptionClick}
                    currentTradeType={currentTradeType}
                    dashboardLoading={dashboardLoading}
                    ChangeRemoveDuplicateButton={ChangeRemoveDuplicateButton}
                    isExplore={true}
                  />
                </div>
                <div className="exploreData-filter">
                  <Grid container spacing={2}>
                    <Grid
                      className="exploreFilter-div"
                      item
                      xs={isFiltersShown ? getFilterWidth() : 0}
                      display={isFiltersShown ? "" : "none"}
                      style={{
                        background: isFiltersShown ? "#ECF0F2" : "none",
                      }}
                    >
                      <Box width="100%" id="exploreFilter">
                        <CollapseFilter
                          mirrorData={mirrorData}
                          isFiltersShown={isFiltersShown}
                          handleApplyFilter={handleApplyFilter}
                          setIsFiltersShown={setIsFiltersShown}
                          filterOptionClick={filterOptionClick}
                          headerTitle={headerTitle}
                          keyValuePairForTreeview={keyValuePairForTreeview}
                          allFilterValuesWithCountsArr={
                            allFilterValuesWithCountsArr
                          }
                          getAllFilterValues={getAllFilterValues}
                          getFilterValues={getFilterValues}
                          isFromExplore={true}
                          setFilterValue={setFilterValue}
                          currentCountry={currentCountry}
                          getMaxMinRange={getMaxMinRange}
                          setMaxMinRange={setMaxMinRange}
                          rangeValue={rangeValue}
                          setRangeValue={setRangeValue}
                          selectedQuantityOrPriceValue={
                            selectedQuantityOrPriceValue
                          }
                          setSelectedQuantityOrPrice={
                            setSelectedQuantityOrPrice
                          }
                          lastSelected={lastSelected}
                          setLastSelected={setLastSelected}
                          storeFilteredValues={storeFilteredValues}
                          setStoreFilterValues={setStoreFilterValues}
                          CheckedData={CheckedData}
                          appliedFilterData={appliedFilterData}
                          setPairForTreeView={setPairForTreeView}
                          isFilterLoader={isFilterLoader}
                          collapseAllFilters={collapseAllFilters}
                          currentTradeType={currentTradeType}
                          getSelectedOptionForSearchTerm={
                            getSelectedOptionForSearchTerm
                          }
                          appliedFilterComp={
                            <ExploreFilter
                              CheckedData={CheckedData}
                              isFromExplore={true}
                              RemoveAppliedFilter={RemoveAppliedFilter}
                              apply={apply}
                              quantityData={[]}
                              handleToggleFilters={handleToggleFilters}
                            />
                          }
                          importerExporterName={importerExporterName}
                        />
                      </Box>
                    </Grid>
                    {handleOpenFilterModal && (
                      <ViewColumnModal
                        open={handleOpenFilterModal}
                        setOpen={setHandleOpenFilterModal}
                        setDataTableHeader={setDataTableHeader}
                        dataTableHeader={dataTableHeader}
                        taxonomyId={filteredTaxonomy._id}
                        accountTableHeader={accountTableHeader}
                        allHeader={allHeader}
                        addViewColumn={addViewColumn}
                        viewHeader={viewHeader}
                        setViewHeader={setViewHeader}
                      />
                    )}
                    {!isFiltersShown && (
                      <Box className="filter-lable-off">
                        <Box
                          id="set-filters-showing-arrow"
                          style={{
                            zIndex:
                              isVisibleData && !isFiltersShown ? 10000 : "",
                          }}
                          onClick={() => setIsFiltersShown(!isFiltersShown)}
                        >
                          <img
                            src={Filter}
                            alt="filter"
                            width="20px"
                            height="20px"
                          ></img>

                          <ArrowForwardIosIcon
                            sx={{
                              fontSize: "20px",
                              fontWeight: 600,
                            }}
                          />
                        </Box>
                      </Box>
                    )}

                    <Grid item xs={isFiltersShown ? getRecordWidth() : 12}>
                      {dataTable()}
                    </Grid>
                  </Grid>
                </div>
              </>
            )}
          </div>
        )}
        {!isRecordBtnChecked && !openAnalyzePage && (
          // <Iframe
          //   storeRisonQuery={risonQuery}
          //   fieldTerm={exploreShipmentSpecification?.explore_aggregation?.sortTerm}
          //   currentStartDate={dateRange?.startMonthDate}
          //   currentEndDate={dateRange?.endMonthDate}
          //   currentCountry={currentCountry}
          //   currentTradeType={currentTradeType}
          //   handleClearFilter={handleClearFilter}
          //   isClearFilter={isClearFilter}
          //   dashboardData={dashboardData.dashboardQuery}
          //   setIsRecordBtnChecked={setIsRecordBtnChecked}
          //   isExploreNew={true}
          // />
          <PowerBIReport
            powerBiDashBoard={powerBiDashBoard}
            setIsRecordBtnChecked={setIsRecordBtnChecked}
            powerBiDownloadOptions={
              currentTradeType === "IMPORT"
                ? POWER_BI_INDIA_IMP_REPORT_DOWNLOAD_OPTIONS
                : currentTradeType === "EXPORT"
                ? POWER_BI_INDIA_EXP_REPORT_DOWNLOAD_OPTIONS
                : ""
            }
            powerBiReportPageName={powerBiReportPageName}
            powerBiReportDownloadHandleChange={
              powerBiReportDownloadHandleChange
            }
            isFromIndia={currentCountry === "INDIA" ? true : false}
          />
        )}
        {openAnalyzePage && (
          <AnalyzeMain
            setOpenAnalyzePage={setOpenAnalyzePage}
            setloading={setLoading}
            setLoadingMsg={setLoadingMsg}
            setIsRecordBtnChecked={setIsRecordBtnChecked}
            analyzeData={analyzeData}
            columnHeaders={dataTableHeader}
            tradeType={selectTrade?.tradeType}
            openAnalyzePage={openAnalyzePage}
            currentCountry={currentCountry}
          />
        )}
        {isRemoveDuplicateButtonModal && (
          <ModalComponent
            open={isRemoveDuplicateButtonModal}
            setOpen={setIsRemoveDuplicateButtonModal}
            isRemoveDuplicateButton={isRemoveDuplicateButton}
            setIsRemoveDuplicateButton={setIsRemoveDuplicateButton}
            header={
              !isRemoveDuplicateButton ? "Set Default Data" : "Remove Duplicate"
            }
            modalBody={
              <span
                style={{
                  fontSize: "18px",
                  fontWeight: 600,
                  color: "rgb(50, 58, 70)",
                }}
              >
                {!isRemoveDuplicateButton
                  ? "Set Default Data?"
                  : "Are you sure you want to remove duplicate data?"}
              </span>
            }
            width="600px"
            saveText={
              !isRemoveDuplicateButton ? "Set Default" : "Remove Duplicate"
            }
            saveButtonShown={true}
            saveButtonColor="#005D91"
            fromRemoveDuplicate={true}
            savedClicked={removeDuplicateBtnClick}
          />
        )}
        {isVisibleData && (
          <>
            <ModalComponent
              open={openModal}
              setOpen={setModalForAddingWorkspace}
              header="Specify Workspace"
              modalBody={
                <div>
                  {getTemplateData && getTemplateData.limit ? (
                    <Box
                      style={{
                        fontSize: "18px",
                        textAlign: "center",
                        color: "rgb(108, 117, 125)",
                      }}
                    >
                      {getTemplateData.errorMessage}
                    </Box>
                  ) : (
                    <Box>
                      <p className={classes.modelBodyStyle}>
                        {" "}
                        Select Workspace
                      </p>
                      <Box>
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="demo-form-control-label-placement"
                            name="position"
                            defaultValue="top"
                          >
                            <FormControlLabel
                              checked={selectedValueRadioButton === "create"}
                              onChange={handleChangeForRadioButton}
                              value="create"
                              control={<Radio />}
                              label="Create New WorkSpace"
                            />
                            {getTemplateData?.data?.length > 0 && (
                              <FormControlLabel
                                checked={
                                  selectedValueRadioButton === "existing"
                                }
                                onChange={handleChangeForRadioButton}
                                value="existing"
                                control={<Radio />}
                                label="Add existing WorkSpace"
                              />
                            )}
                          </RadioGroup>
                        </FormControl>
                      </Box>

                      <Box style={{ marginTop: "10px" }}>
                        {selectedValueRadioButton === "create" ? (
                          <FormControl style={{ width: "100%" }}>
                            <InputLabel htmlFor="component-outlined">
                              Create New Workspace
                            </InputLabel>
                            <OutlinedInput
                              id="component-outlined"
                              value={getNameForWorkSpace}
                              onChange={handleChangeForInputWorkSpace}
                              label="Create New Workspace"
                            />
                            {isNameEmpty ? (
                              <Box style={{ color: "red", marginTop: "11px" }}>
                                This value is required
                              </Box>
                            ) : (
                              ""
                            )}
                            {isWorkSpaceNameAlredyExists ? (
                              <Box style={{ color: "red", marginTop: "11px" }}>
                                Name Already Exists
                              </Box>
                            ) : (
                              ""
                            )}
                            {isNameNotAllowed ? (
                              <Box style={{ color: "red", marginTop: "11px" }}>
                                # ? and % are not allowed in WorkSpace Name
                              </Box>
                            ) : (
                              ""
                            )}
                          </FormControl>
                        ) : selectedValueRadioButton === "existing" ? (
                          <FormControl
                            className="ml-10"
                            style={{ width: "500px" }}
                          >
                            <InputLabel id="demo-select-small">
                              Existing Workspaces
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Existing Workspaces"
                              style={{ height: "41px" }}
                              onChange={(event) =>
                                handleChangeForExistingWorkSpaces(event)
                              }
                              value={getNameFromExistingWorkSpace}
                            >
                              {optionsForExistingTemplate.length > 0 &&
                                optionsForExistingTemplate.map((e, index) => (
                                  <MenuItem value={e.label} key={index}>
                                    {e.label}
                                  </MenuItem>
                                ))}
                            </Select>
                            {errorMsgForExistingWorkspace && (
                              <p style={{ color: "red", marginTop: "5px" }}>
                                Please select your workspace
                              </p>
                            )}
                          </FormControl>
                        ) : (
                          ""
                        )}
                      </Box>
                    </Box>
                  )}
                </div>
              }
              savedClicked={savedClicked}
              width="568px"
              saveText="Save"
              saveButtonShown={getTemplateData.limit ? false : true}
              saveButtonColor="#1abc9c"
              setErrorMsgForExistingWorkspace={setErrorMsgForExistingWorkspace}
            />
            <ModalComponent
              open={countryShipmentModel}
              setOpen={setCountryShipmentModel}
              header="Add Shipment Records"
              modalBody={
                <CountryShipmentModel
                  countryShipmentModelData={countryShipmentModelData}
                  mirrorData={mirrorData}
                  getNameForWorkSpace={
                    getNameForWorkSpace.length > 0
                      ? getNameForWorkSpace
                      : getNameFromExistingWorkSpace
                  }
                  currentCountry={currentCountry}
                />
              }
              width="800px"
              saveText="Confirm"
              saveButtonShown={true}
              saveButtonColor="#1abc9c"
              savedClicked={confirmWorkspaceRecord}
            />
            <ModalComponent
              open={saveQueryModalOpen}
              setOpen={setSaveQueryOpenModal}
              width="450px"
              header="Save Query"
              modalBody={
                <Box>
                  <TextField
                    value={saveTitleSavedQuery}
                    onChange={(val) =>
                      setSaveTitleForSaveQuery(val.target.value)
                    }
                    type="text"
                    style={{ width: "400px" }}
                    label="Title"
                  />

                  <div
                    style={{
                      fontSize: "11px",
                      position: "relative",
                      top: "12px",
                    }}
                  >
                    You have consumed
                    <strong style={{ color: "red" }} className="notranslate">
                      {" "}
                      {saveQueryLimit?.consumeCount}{" "}
                    </strong>
                    and left with
                    <strong style={{ color: "green" }} className="notranslate">
                      {" "}
                      {saveQueryLimit?.allotedCount -
                        saveQueryLimit?.consumeCount}{" "}
                    </strong>
                    limit for save your Queries
                  </div>
                </Box>
              }
              savedClicked={onClickSavedQuery}
              saveText="Save"
              saveButtonShown={true}
              saveButtonColor="#005d91"
            />

            {/* Eye btn Modal  */}

            <ModalComponent
              IsNextPrevBtnShown={true}
              open={openCollpaseModal}
              setOpen={setOpenCollapseModal}
              header="DataTable"
              prevOrNext={prevOrNext}
              nextDataTableRecords={nextDataTableRecords}
              previousDataTableRecords={previousDataTableRecords}
              rowsPerPage={rowsPerPage}
              handlePrintTableRecords={handlePrintTableRecords}
              handleSummaryDataForTrade={handleSummaryDataForTrade}
              modalBody={
                <ExploreDataTableModal
                  currentTradeType={currentTradeType}
                  currentCountry={currentCountry}
                  PrintRef={PrintRef}
                  handleCollapseDetails={(e) => handleCollapseDetails(e)}
                  collapseTable={collapseTable}
                  setCollapseTable={setCollapseTable}
                  modalheaderArr={modalHeaderArr}
                  isCollapseModelDetails={isCollapseModelDetails}
                  setIsCollapseModelDetails={setIsCollapseModelDetails}
                  handleFavouriteCompany={handleFavouriteCompany}
                  handleFavouriteShipment={handleFavouriteShipment}
                  IECDetails={IECDetails}
                  consigneeDetails={consigneeDetails}
                  Favourite={true}
                  handleAddConsigneeRequest={handleAddConsigneeRequest}
                  searchOption={searchField?.searchOption}
                  CompanyDetailOnContactClick={CompanyDetailOnContactClick}
                  mirrorData={mirrorData}
                />
              }
              width="65%"
              saveText="Save"
              saveButtonShown={false}
              saveButtonColor="#005d91"
            />

            {/* Company Or Shipment Limit */}
            <ModalComponent
              open={isFavCompanyOrShipment}
              setOpen={setIsFavCompanyOrShipment}
              header={favCompanyOrShipmentLimit.title}
              modalBody={favCompanyOrShipmentLimit.msg}
              width="400px"
              saveText="Save"
              saveButtonShown={false}
              saveButtonColor="#005D91"
            />
          </>
        )}
        <ModalComponent
          open={countryModalOpenClose}
          setOpen={setCountryModalOpenClose}
          header="Country Select"
          width="60%"
          headerIcon={<LanguageSharpIcon />}
          mirrorData={mirrorData}
          modalBody={
            <CountrySelectModal
              allCountryData={allCountryData}
              handleChangeTradeType={handleChangeTradeType}
              handleCountryChange={handleCountryChange}
              setSelectedCountries={setSelectedCountries}
              selectedCountries={selectedCountries}
              handleSearchCountryInModal={handleSearchCountryInModal}
              regionWiseCountryData={regionWiseCountryData}
              searchQueryForCountryModal={searchQueryForCountryModal}
              handleUncheckAll={handleUncheckAll}
              tradeTypeForCountryModal={tradeTypeForCountryModal}
              isSearchCountryTrue={isSearchCountryTrue}
              mirrorAccessCountryList={mirrorAccessCountryList}
              handleInaccessibleCountryPopUp={handleInaccessibleCountryPopUp}
            />
          }
          saveText="Save"
          saveButtonShown={true}
          savedClicked={handleCountryModalOpen}
        />

        {deleteModal === true ? (
          <ConfirmationModal
            open={deleteModalOpen}
            header="Delete Query"
            modalBody={
              <div
                style={{
                  padding: "15px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div style={{ margin: "10px" }}>
                  <img src={DeleteIcon} alt="delete" />
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span
                    style={{
                      fontSize: "22px",
                      fontWeight: 600,
                      color: "rgb(50, 58, 70)",
                    }}
                  >
                    Delete
                  </span>
                  <div style={{ fontSize: "16px", marginTop: "20px" }}>
                    Are you sure you want to remove this SaveQuery ?
                  </div>
                </div>
              </div>
            }
            saveText="Delete"
            width="600px"
            saveButtonShown={true}
            savedClicked={(val) => deleteClick(val)}
            saveButtonColor="#df0000c7"
            setOpen={setDeleteModalOpen}
          />
        ) : (
          ""
        )}
        {requestForCountryModal && (
          <ModalComponent
            open={requestForCountryModal}
            setOpen={setRequestForCountryModal}
            modalBody={
              <RequestCompanyModal
                headerIcon={<LockOpenIcon style={{ fontSize: "30px" }} />}
                headerTitle={`Unlock ${mirrorCountryData?.country} Trade Insights`}
                onClick={() => handleTicketManager()}
                handleCancel={handleOkayClick}
                countryName={mirrorCountryData?.country}
              />
            }
            width="550px"
            isUpgradePlan={true}
          />
        )}
      </div>
    </Container>
  );
});

export default explore;
