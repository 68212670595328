"use strict";

import React, { useState } from "react";
import Highcharts from "highcharts";
import PieChart from "highcharts-react-official";
import { useEffect } from "react";
import { CircularProgress } from "@mui/material";
import noPreviewImage from "../../../Asset/images/no_preview.png";
import { isPieChartValidation } from "../../../utils/ChartsValidation";
import millify from "millify";
const PieChartData = ({ data, label, value, isLoaderShowing, recordLength, handleGetData, isBox }) => {
  const [noPreview, setNoPreview] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [allData, setAllData] = useState([]);
  const [chartOptions, setChartOptions] = useState({});
  useEffect(() => {
    if (isPieChartValidation(label, value)) {
      const pp = [];
      setLoading(true);
      setNoPreview(true);
      data.map((item) => {
        let index = pp.findIndex((key) => key.name === item[label]);

        if (index === -1) {
          let json = {
            name: item[label],
            y: item[value],
          };
          pp.push(json);
        } else {
          pp[index]["y"] = Number(pp[index]["y"]) + Number(item[value]);
        }
        setLoading(false);
      });
      setAllData([...pp]);
    } else {
      setNoPreview(true);
    }
  }, [label, value]);

  useEffect(() => {
    if (allData?.length > 0 && isPieChartValidation(label, value)) {
      setLoading(true);
      setTimeout(() => {
        let _pp = [];
        if (recordLength?.type === "top") {
          _pp = allData?.slice(0, Number(recordLength?.key));
        } else if (recordLength?.type === "bottom") {
          _pp = allData?.slice(Number(recordLength?.key));
        }
        let tableHeader = Object.keys(_pp[0]);
        tableHeader = tableHeader.map((e, index) => {
          return {
            title: e,
            val: index === 0 ? label : value,
          };
        });
        setChartOptions({
          chart: {
            type: "pie",
          },
          xAxis: {
            categories: label,
          },
          yAxis: {
            title: {
              text: value,
            },
          },
          series: [
            {
              data: _pp,
            },
          ],
          legend: {
            enabled: true,
          },
          tooltip: {
            formatter: function () {
              var value = this.point.y >= 1 ? millify(this.point.y) : this.point.y;
              return this.point.name + "<br/>" + this.series.name + " : " + value;
            },
          },
        });
        if (isBox) {
          handleGetData({
            headers: tableHeader,
            data: _pp,
          });
        }
        setLoading(false);
        setNoPreview(false);
      }, 1000);
    }
  }, [recordLength, allData]);

  return (
    <div style={{ position: "relative", width: "100%", height: "100%" }}>
      {isLoading && isLoaderShowing && (
        <CircularProgress
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            zIndex: "1000",
          }}
        />
      )}
      {noPreview && isLoaderShowing ? (
        <img
          src={noPreviewImage}
          alt=""
          style={{
            width: "60%",
            height: "100%",
            margin: "0 auto",
            display: "block",
            objectFit: "contain",
          }}
        />
      ) : (
        <PieChart highcharts={Highcharts} options={chartOptions} />
      )}
    </div>
  );
};

export default PieChartData;
