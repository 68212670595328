import Cookies from "js-cookie";
import {
  ADD_USER_SEARCH_IN_SEARCH_OPTIONS,
  FILTER_OPTIONS,
  HS_CODE_DIGIT_CLASSIFICATION_MIN,
  QUERYTYPE,
  SORTINGTYPE,
} from "../Containers/ExploreCountryShipmentNewUI/Explore.enum";
import {
  IsMultiSelectedField,
  IsMultiSelectedFieldForAdvanceSearch,
} from "./commanConditions";
import { divideDateRange } from "./CommanFunction";

const operator = {
  EXACT: 200,
  OR: 201,
  AND: 202,
  CONTAIN: 203,
  NOT_CONTAIN: 204,
  FULL_TEXT: 207,
};

const prodFlagOperator = {
  less_than: 400,
  greater_than: 401,
  less_equal_to_than: 402,
  greater_equal_to_than: 403,
};

const identifier = {
  SEARCH_HS_CODE: "SEARCH_HS_CODE",
  SEARCH_PRODUCT_DESCRIPTION: "SEARCH_PRODUCT_DESCRIPTION",
  SEARCH_PRODUCT_DESCRIPTION_EN: "SEARCH_PRODUCT_DESCRIPTION_EN",
  SEARCH_HS_CODE_DESCRIPTION: "SEARCH_HS_CODE_DESCRIPTION",
  SEARCH_MONTH_RANGE: "SEARCH_MONTH_RANGE",
  SEARCH_COUNTRY: "SEARCH_COUNTRY",
  REMOVE_DUPLICATE: "REMOVE_DUPLICATE",
  SEARCH_REPRESENTATIVE_PRINCIPAL: "SEARCH_REPRESENTATIVE_PRINCIPAL",
  SEARCH_SELLER_EN: "SEARCH_SELLER_EN",
  SEARCH_SELLER: "SEARCH_SELLER",
  SEARCH_BUYER_EN: "SEARCH_BUYER_EN",
  SEARCH_BUYER: "SEARCH_BUYER",
};

const validIdentifiers = [
  identifier.SEARCH_PRODUCT_DESCRIPTION,
  identifier.SEARCH_PRODUCT_DESCRIPTION_EN,
  identifier.SEARCH_HS_CODE_DESCRIPTION,
  identifier.SEARCH_SELLER_EN,
  identifier.SEARCH_SELLER,
  identifier.SEARCH_BUYER_EN,
  identifier.SEARCH_BUYER,
  "SEARCH_PRODUCT_DESCRIPTION_NATIVE",
];

const validFieldTerms = [
  "PRODUCT_DESCRIPTION",
  "CUSTOMS_DESCRIPTION",
  "HS_CODE_DESCRIPTION",
  "SHIPPER_NAME_EN",
  "SHIPPER_NAME_NATIVE",
  "CONSIGNEE_NAME_EN",
  "CONSIGNEE_NAME_NATIVE",
  "SHIPPER_NAME",
  "CONSIGNEE_NAME",
  "EXPORTER_NAME",
  "BUYER_NAME",
  "STD_EXPORTER_NAME",
  "STD_BUYER_NAME",
  "EXPORTER_NAME_EN",
  "EXPORTER_NAME_VN",
  "IMPORTER_NAME",
  "EXPORTER_NAME_AND_ADDRESS",
  "SUPPLIER_NAME_EN",
  "SUPPLIER_NAME",
  "IMPORTER_NAME_EN",
  "STD_IMPORTER_NAME",
  "STD_SUPPLIER_NAME",
  "IMPORTER_NAME_VN",
  "BUYER_NAME_EN",
];
// Example usage:

export const convertDataIntoLeftAndRightValue = (
  currentTermValue,
  hs_code_digit_classification,
  mirrorData
) => {
  const fieldValue = [];
  const fieldValueArr = [];
  if (currentTermValue.length > 0) {
    currentTermValue.length &&
      currentTermValue.forEach((value) => {
        fieldValue.push(value);
        let digitsCount = value?.length;
        let leftValue = value;
        let rightValue = value;
        if (mirrorData) {
          for (
            let index = digitsCount + 1;
            index <= hs_code_digit_classification;
            index++
          ) {
            if (leftValue.length <= HS_CODE_DIGIT_CLASSIFICATION_MIN) {
              leftValue += 0;
            }
            rightValue += 9;
          }
          fieldValueArr.push({
            fieldValueLeft: Number(leftValue),
            fieldValueRight: Number(rightValue),
          });
        } else {
          for (
            let index = digitsCount + 1;
            index <= hs_code_digit_classification;
            index++
          ) {
            leftValue += 0;
            rightValue += 9;
          }
          fieldValueArr.push({
            fieldValueLeft: Number(leftValue),
            fieldValueRight: Number(rightValue),
          });
        }
      });
  } else {
    let digitsCount = currentTermValue.length;
    let leftValue = "";
    let rightValue = "";
    for (
      let index = digitsCount + 1;
      index <= hs_code_digit_classification;
      index++
    ) {
      leftValue += 0;
      rightValue += 9;
    }

    fieldValueArr.push({
      fieldValueLeft: Number(leftValue),
      fieldValueRight: Number(rightValue),
    });
  }

  return { fieldValue, fieldValueArr };
};

const getExpressionsType = (selectedOperator) => {
  if (!Object.keys(selectedOperator)?.length > 0) {
    return 200;
  } else {
    if (operator?.hasOwnProperty(selectedOperator?.split(" ").join("_"))) {
      return operator[selectedOperator?.split(" ").join("_")];
    } else {
      return "";
    }
  }
};

const sortingColumns = (
  descendingAndAscending,
  sortedColumnList,
  findClauseSearchMonth
) => {
  let array = [];
  const sorting =
    descendingAndAscending &&
    Object.keys(descendingAndAscending).filter(
      (e) =>
        descendingAndAscending[e] === "desc" ||
        descendingAndAscending[e] === "asc"
    );
  if (sorting?.length > 0) {
    descendingAndAscending &&
      Object.keys(descendingAndAscending).length &&
      Object.keys(descendingAndAscending).forEach((e) => {
        if (descendingAndAscending[e] !== SORTINGTYPE?.default) {
          let obj =
            sortedColumnList &&
            sortedColumnList?.length &&
            sortedColumnList?.find((val) => val?.column === e);
          obj.sortType = descendingAndAscending[e];
          array.push(obj);
        }
      });
  } else {
    let obj = {
      sortField: findClauseSearchMonth?.fieldTerm,
      column: findClauseSearchMonth?.fieldTerm,
      defaultDataType: findClauseSearchMonth?.fieldTermTypeSuffix,
      sortType: SORTINGTYPE?.desc,
    };
    array.push(obj);
  }

  return array;
};

const formulateDataShipmentPayload = (
  shipmentResultType,
  isCheckedEmpty,
  isCheckedOnSearch,
  data
) => {
  const {
    searchField,
    getSelectedOptionForSearchTerm,
    exploreShipmentSpecification,
    SHIPMENT_RESULT_TYPE_RECORDS,
    isBlFlag,
    currentCountry,
    dateRange,
    advanceSearchComponent,
    advanceSearchFieldOptions,
    filteredTaxonomy,
    onSearchClickFlag,
    descendingAndAscending,
    sortedColumnList,
    CheckedData,
    removeButtonState,
    lastSelected,
    workSpaceIntialCall,
    isWorkSpace,
    isExplore,
    mirrorData,
    isRemoveDuplicateButton,
    isFromRecentSearch,
  } = data;
  let currentTermValue = [];
  if (workSpaceIntialCall && isWorkSpace) {
    if (
      searchField?.getValuesForSelectDropdown?.query === QUERYTYPE?.CHOOSE ||
      IsMultiSelectedField?.includes(
        searchField?.getValuesForSelectDropdown?.field
      )
    ) {
      const arr = [];
      if (
        getSelectedOptionForSearchTerm &&
        getSelectedOptionForSearchTerm?.length
      ) {
        getSelectedOptionForSearchTerm?.map((e) => {
          arr.push(e?._id);
          currentTermValue = arr;
        });
      }
    } else {
      currentTermValue = getSelectedOptionForSearchTerm?._id;
    }
  } else if (isExplore) {
    if (
      searchField?.getValuesForSelectDropdown?.query === QUERYTYPE?.CHOOSE ||
      IsMultiSelectedField?.includes(
        searchField?.getValuesForSelectDropdown?.field
      )
    ) {
      const arr = [];
      if (
        getSelectedOptionForSearchTerm &&
        getSelectedOptionForSearchTerm?.length
      ) {
        getSelectedOptionForSearchTerm?.map((e) => {
          arr.push(e?._id);
          currentTermValue = arr;
        });
      }
    } else {
      currentTermValue = getSelectedOptionForSearchTerm?._id;
    }
  }

  let payload = null;
  let searchSemantics = JSON?.parse(
    JSON.stringify(exploreShipmentSpecification?.search_field_semantic ?? "")
  );
  if (shipmentResultType === SHIPMENT_RESULT_TYPE_RECORDS) {
    payload = JSON?.parse(
      JSON.stringify(exploreShipmentSpecification?.explore_aggregation ?? "")
    );
  }
  if (shipmentResultType === "STATISTICS") {
    payload = JSON?.parse(
      JSON.stringify(exploreShipmentSpecification.statistics_aggregation ?? "")
    );
  }

  let aggregationPayload = {
    allFields: exploreShipmentSpecification?.all_fields,
    isEngine: true,
    recordSetKey: payload?.recordSetKey,
    sortTerms: [],
    searchExpressions: [],
    matchExpressions: [],
    monthRange: dateRange?.monthRange,
    groupExpressions: [],
    projectionExpressions: [],
  };
  if (isWorkSpace) {
    aggregationPayload.indexNamePrefix = payload?.indexNamePrefix;
    aggregationPayload.sortTerm = payload?.sortTerm;
  }
  // advance Search
  let storeArr =
    advanceSearchComponent?.length > 0 ? [...advanceSearchComponent] : [];
  let reverseArr = storeArr.reverse();
  if (reverseArr?.length && reverseArr?.length > 0) {
    reverseArr?.forEach((queries, index) => {
      let arr = [];
      var selectedQuery;
      selectedQuery =
        advanceSearchFieldOptions &&
        advanceSearchFieldOptions?.length &&
        advanceSearchFieldOptions?.find(
          (semantic) => semantic?.field === queries?.field
        );
      var payloadQuery = {};
      if (currentCountry === "INDIA") {
        payloadQuery =
          filteredTaxonomy?.fields.explore_aggregation?.matchExpressions?.find(
            (expression) => {
              if (selectedQuery && Object.keys(selectedQuery)?.length) {
                return expression?.identifier === selectedQuery?.identifier;
              }
            }
          );
      } else {
        if (
          selectedQuery &&
          Object.keys(selectedQuery)?.length &&
          selectedQuery?.identifier === identifier?.SEARCH_HS_CODE
        ) {
          payloadQuery =
            filteredTaxonomy?.fields.explore_aggregation?.matchExpressions?.find(
              (expression) =>
                expression?.identifier === selectedQuery?.identifier
            );
        } else {
          if (selectedQuery?.field === "PRODUCT_DESC_EN") {
            payloadQuery =
              filteredTaxonomy?.fields.explore_aggregation?.matchExpressions.find(
                (expression) => {
                  if (selectedQuery && Object.keys(selectedQuery)?.length) {
                    return expression.fieldTerm === selectedQuery?.field;
                  }
                }
              );
          } else {
            payloadQuery =
              filteredTaxonomy?.fields.explore_aggregation?.matchExpressions.find(
                (expression) => {
                  if (selectedQuery && Object.keys(selectedQuery)?.length) {
                    return expression.identifier === selectedQuery?.identifier;
                  }
                }
              );
          }
        }
      }
      var prodFlag = true;
      if (
        (selectedQuery &&
          Object.keys(selectedQuery)?.length &&
          selectedQuery?.identifier ===
            identifier?.SEARCH_PRODUCT_DESCRIPTION) ||
        selectedQuery?.identifier ===
          identifier?.SEARCH_PRODUCT_DESCRIPTION_EN ||
        selectedQuery?.identifier === identifier?.SEARCH_SELLER_EN ||
        selectedQuery?.identifier === identifier?.SEARCH_SELLER ||
        selectedQuery?.identifier === identifier?.SEARCH_BUYER_EN ||
        selectedQuery?.identifier === identifier?.SEARCH_BUYER ||
        selectedQuery?.identifier === identifier?.SEARCH_HS_CODE_DESCRIPTION
      ) {
        prodFlag = false;
        const operatorExpressionType = getExpressionsType(queries?.operator);
        if (operatorExpressionType !== "") {
          payloadQuery.expressionType = operatorExpressionType;
        }
      }
      if (prodFlag) {
        const queriesOperator = queries?.operator?.split(" ").join("_");
        if (prodFlagOperator?.hasOwnProperty(queriesOperator)) {
          payloadQuery.expressionType = prodFlagOperator[queriesOperator];
        }
      }
      if (onSearchClickFlag) {
        if (
          queries?.query === QUERYTYPE?.CHOOSE ||
          IsMultiSelectedFieldForAdvanceSearch?.includes(queries?.field) ||
          (queries?.query?.includes("PROD") && queries?.query?.includes("DESC"))
        ) {
          const arrOfQueries = [...queries.value];
          arrOfQueries &&
            arrOfQueries.length > 0 &&
            arrOfQueries.forEach((e) => {
              arr.push(e._id);
            });
          payloadQuery.fieldValue = arr;
        } else {
          payloadQuery.fieldValue = queries?.value?._id;
        }
      } else {
        payloadQuery.fieldValue = queries?.fieldValue;
      }

      payloadQuery.specifier = queries?.operator;
      payloadQuery.relation = queries?.relation;
      payloadQuery.advanceSearchType = true;
      if (
        selectedQuery &&
        Object.keys(selectedQuery)?.length &&
        selectedQuery?.identifier === identifier?.SEARCH_HS_CODE
      ) {
        const { fieldValueArr } = convertDataIntoLeftAndRightValue(
          arr,
          exploreShipmentSpecification?.hs_code_digit_classification,
          mirrorData
        );
        payloadQuery.fieldValueArr = fieldValueArr;
      }

      aggregationPayload.matchExpressions.push({ ...payloadQuery });
    });
  }
  //normal search
  let findClauseSearchSemantic =
    searchSemantics &&
    searchSemantics?.find(
      (expression) =>
        expression?.identifier ===
        searchField?.getValuesForSelectDropdown?.identifier
    );
  let findClauseSearchTerm = payload[
    findClauseSearchSemantic?.recordsAggregationFindPattern
  ]?.find(
    (expression) =>
      expression?.identifier ===
      searchField?.getValuesForSelectDropdown?.identifier
  );
  if (findClauseSearchTerm?.identifier === identifier?.SEARCH_HS_CODE) {
    const { fieldValueArr, fieldValue } = convertDataIntoLeftAndRightValue(
      currentTermValue,
      exploreShipmentSpecification?.hs_code_digit_classification,
      mirrorData
    );
    findClauseSearchTerm.fieldValue = fieldValue;
    findClauseSearchTerm.fieldValueArr = fieldValueArr;
    findClauseSearchTerm.advanceSearchType = false;
    aggregationPayload[
      findClauseSearchSemantic?.recordsAggregationFindPattern
    ].push(findClauseSearchTerm);
  } else if (
    (validIdentifiers.includes(findClauseSearchTerm?.identifier) &&
      validFieldTerms.includes(findClauseSearchTerm?.fieldTerm)) ||
    (findClauseSearchTerm?.fieldTerm?.includes("PROD") &&
      findClauseSearchTerm?.fieldTerm?.includes("DESC"))
  ) {
    aggregationPayload.isEngine = true;
    findClauseSearchTerm.fieldValue = currentTermValue;
    findClauseSearchTerm.advanceSearchType = false;
    let expressionType = getExpressionsType(searchField?.selectedOperator);
    if (currentTermValue) {
      findClauseSearchTerm.expressionType = expressionType;
      aggregationPayload[
        findClauseSearchSemantic?.recordsAggregationFindPattern
      ].push(findClauseSearchTerm);
    }
  } else {
    findClauseSearchTerm.fieldValue = currentTermValue;
    findClauseSearchTerm.advanceSearchType = false;
    if (!isWorkSpace && currentTermValue) {
      aggregationPayload[
        findClauseSearchSemantic?.recordsAggregationFindPattern
      ].push(findClauseSearchTerm);
    }

    if (isWorkSpace && currentTermValue && currentTermValue?.length > 0) {
      aggregationPayload[
        findClauseSearchSemantic?.recordsAggregationFindPattern
      ].push(findClauseSearchTerm);
    } else if (isWorkSpace && findClauseSearchTerm?.expressionType == 200) {
      findClauseSearchTerm.fieldValue = "*";
      aggregationPayload[
        findClauseSearchSemantic.recordsAggregationFindPattern
      ].push();
    }
  }
  if (isRemoveDuplicateButton && currentCountry === "INDIA") {
    let removeDuplicateMatchExpression = payload[
      findClauseSearchSemantic?.recordsAggregationFindPattern
    ].find(
      (expression) => expression?.identifier === identifier?.REMOVE_DUPLICATE
    );
    aggregationPayload.matchExpressions.push(removeDuplicateMatchExpression);
  }
  if (!isWorkSpace) {
    const filterSEARCH_COUNTRY = {
      identifier: "SEARCH_COUNTRY",
      alias: "COUNTRY",
      clause: "MATCH",
      expressionType: 202,
      relation: "and",
      fieldTerm: "COUNTRY_DATA",
      fieldValue: [currentCountry.replaceAll("_", " ")],
      fieldTermTypeSuffix: "",
    };

    payload.matchExpressions.push(filterSEARCH_COUNTRY);

    let findClauseSearchCountry = payload[
      findClauseSearchSemantic?.recordsAggregationFindPattern
    ].find(
      (expression) => expression?.identifier === identifier?.SEARCH_COUNTRY
    );

    if (isBlFlag === "true") {
      aggregationPayload[
        findClauseSearchSemantic?.recordsAggregationFindPattern
      ].push(findClauseSearchCountry);
    }
  }
  let findClauseSearchMonth = payload[
    findClauseSearchSemantic?.recordsAggregationFindPattern
  ].find(
    (expression) => expression?.identifier === identifier?.SEARCH_MONTH_RANGE
  );
  findClauseSearchMonth.fieldValueLeft = dateRange?.startMonthDate;
  findClauseSearchMonth.fieldValueRight = dateRange?.endMonthDate;

  const userSelectedStartDate = dateRange?.startMonthDate;
  const userSelectedEndDate = dateRange?.endMonthDate;
  const myEndDate = exploreShipmentSpecification?.data_end_date;
  const dividedDateRange = divideDateRange(
    userSelectedStartDate,
    userSelectedEndDate,
    myEndDate
  );
  if (dividedDateRange) {
    findClauseSearchMonth.dividedDateRange = dividedDateRange;
    if (dividedDateRange.length === 2) {
      findClauseSearchMonth.dateExpression = 2;
    } else {
      if (dividedDateRange && dividedDateRange[0]) {
        const obj = dividedDateRange[0];
        if (obj.hasOwnProperty("leftFieldvalueHot")) {
          findClauseSearchMonth.dateExpression = 1;
        } else {
          findClauseSearchMonth.dateExpression = 0;
        }
      }
    }
  }
  aggregationPayload[
    findClauseSearchSemantic?.recordsAggregationFindPattern
  ].push(findClauseSearchMonth);

  aggregationPayload.groupExpressions = payload?.groupExpressions;
  aggregationPayload.projectionExpressions = payload?.projectionExpressions;

  let _CheckedData = { ...CheckedData };
  if (CheckedData && Object.keys(CheckedData)?.length) {
    for (const [key, value] of Object.entries(CheckedData)) {
      if (Object.keys(value).length === 0) {
        delete _CheckedData[key];
      }
    }
  }
  if (isCheckedEmpty) {
    _CheckedData = {};
  }
  if (isCheckedOnSearch) {
    _CheckedData = {};
  }

  if (
    exploreShipmentSpecification?.filter_field_semantic != null &&
    exploreShipmentSpecification?.filter_field_semantic?.length > 0
  ) {
    exploreShipmentSpecification?.explore_aggregation?.matchExpressions &&
      exploreShipmentSpecification?.explore_aggregation?.matchExpressions
        ?.length &&
      exploreShipmentSpecification?.explore_aggregation?.matchExpressions?.forEach(
        (filterFieldSemantic) => {
          switch (filterFieldSemantic?.identifier) {
            case FILTER_OPTIONS?.FILTER_COUNTRY: {
              if (!removeButtonState?.FILTER_COUNTRY) {
                let findClauseFilterCountry = payload[
                  findClauseSearchSemantic?.recordsAggregationFindPattern
                ].find(
                  (expression) =>
                    expression?.identifier === FILTER_OPTIONS?.FILTER_COUNTRY
                );
                if (_CheckedData.FILTER_COUNTRY) {
                  const field = [];
                  findClauseFilterCountry.expressionType = 206;
                  findClauseFilterCountry.fieldTermTypeSuffix = "";
                  for (const [key, value] of Object.entries(
                    _CheckedData.FILTER_COUNTRY
                  )) {
                    field.push(key.split("_").join(" "));
                  }
                  findClauseFilterCountry.fieldValue = field;
                  aggregationPayload[
                    findClauseSearchSemantic?.recordsAggregationFindPattern
                  ].push(findClauseFilterCountry);
                  findClauseFilterCountry.filterType = true;
                }
              }
              break;
            }
            case FILTER_OPTIONS?.FILTER_IMPORTER: {
              if (!removeButtonState?.FILTER_IMPORTER) {
                let findClauseFilterCountry = payload[
                  findClauseSearchSemantic?.recordsAggregationFindPattern
                ].find(
                  (expression) =>
                    expression?.identifier === FILTER_OPTIONS?.FILTER_IMPORTER
                );
                if (_CheckedData.FILTER_IMPORTER) {
                  const field = [];
                  findClauseFilterCountry.expressionType = 206;
                  findClauseFilterCountry.fieldTermTypeSuffix = "";
                  for (const [key, value] of Object.entries(
                    _CheckedData.FILTER_IMPORTER
                  )) {
                    field.push(key.split("_").join(" "));
                  }
                  findClauseFilterCountry.fieldValue = field;
                  aggregationPayload[
                    findClauseSearchSemantic?.recordsAggregationFindPattern
                  ].push(findClauseFilterCountry);
                  findClauseFilterCountry.filterType = true;
                }
              }
              break;
            }
            case FILTER_OPTIONS?.FILTER_EXPORTER: {
              if (!removeButtonState?.FILTER_EXPORTER) {
                let findClauseFilterCountry = payload[
                  findClauseSearchSemantic?.recordsAggregationFindPattern
                ].find(
                  (expression) =>
                    expression?.identifier === FILTER_OPTIONS?.FILTER_EXPORTER
                );
                if (_CheckedData.FILTER_EXPORTER) {
                  const field = [];
                  findClauseFilterCountry.expressionType = 206;
                  findClauseFilterCountry.fieldTermTypeSuffix = "";
                  for (const [key, value] of Object.entries(
                    _CheckedData.FILTER_EXPORTER
                  )) {
                    field.push(key.split("_").join(" "));
                  }
                  findClauseFilterCountry.fieldValue = field;
                  aggregationPayload[
                    findClauseSearchSemantic?.recordsAggregationFindPattern
                  ].push(findClauseFilterCountry);
                  findClauseFilterCountry.filterType = true;
                }
              }
              break;
            }
            case FILTER_OPTIONS?.FILTER_PIN: {
              if (!removeButtonState?.FILTER_PIN) {
                let findClauseFilterPin = payload[
                  findClauseSearchSemantic?.recordsAggregationFindPattern
                ].find(
                  (expression) =>
                    expression?.identifier === FILTER_OPTIONS?.FILTER_PIN
                );
                if (_CheckedData.FILTER_PIN) {
                  const field = [];
                  findClauseFilterPin.expressionType = 206;
                  findClauseFilterPin.fieldTermTypeSuffix = "";
                  for (const [key, value] of Object.entries(
                    _CheckedData.FILTER_PIN
                  )) {
                    field.push(key.split("_").join(" "));
                  }
                  findClauseFilterPin.fieldValue = field;
                  aggregationPayload[
                    findClauseSearchSemantic?.recordsAggregationFindPattern
                  ].push(findClauseFilterPin);
                  findClauseFilterPin.filterType = true;
                }
              }
              break;
            }
            case FILTER_OPTIONS?.FILTER_STATE: {
              if (!removeButtonState?.FILTER_STATE) {
                let findClauseFilterState = payload[
                  findClauseSearchSemantic?.recordsAggregationFindPattern
                ].find(
                  (expression) =>
                    expression?.identifier === FILTER_OPTIONS?.FILTER_STATE
                );
                if (_CheckedData.FILTER_STATE) {
                  const field = [];
                  findClauseFilterState.expressionType = 206;
                  findClauseFilterState.fieldTermTypeSuffix = "";
                  for (const [key, value] of Object.entries(
                    _CheckedData.FILTER_STATE
                  )) {
                    field.push(key.split("_").join(" "));
                  }
                  findClauseFilterState.fieldValue = field;
                  aggregationPayload[
                    findClauseSearchSemantic?.recordsAggregationFindPattern
                  ].push(findClauseFilterState);
                  findClauseFilterState.filterType = true;
                }
              }
              break;
            }
            case FILTER_OPTIONS?.FILTER_DESTINATION_COUNTRY: {
              if (!removeButtonState?.FILTER_DESTINATION_COUNTRY) {
                let findClauseFilterDestinationCountry = payload[
                  findClauseSearchSemantic?.recordsAggregationFindPattern
                ].find(
                  (expression) =>
                    expression?.identifier ===
                    FILTER_OPTIONS?.FILTER_DESTINATION_COUNTRY
                );
                if (_CheckedData.FILTER_DESTINATION_COUNTRY) {
                  const field = [];
                  findClauseFilterDestinationCountry.expressionType = 206;
                  findClauseFilterDestinationCountry.fieldTermTypeSuffix = "";
                  for (const [key, value] of Object.entries(
                    _CheckedData.FILTER_DESTINATION_COUNTRY
                  )) {
                    field.push(key.split("_").join(" "));
                  }
                  findClauseFilterDestinationCountry.fieldValue = field;
                  aggregationPayload[
                    findClauseSearchSemantic?.recordsAggregationFindPattern
                  ].push(findClauseFilterDestinationCountry);
                  findClauseFilterDestinationCountry.filterType = true;
                }
              }
              break;
            }
            case FILTER_OPTIONS?.FILTER_DESTINATION_PORT: {
              if (!removeButtonState?.FILTER_DESTINATION_PORT) {
                let findClauseFilterDestinationPort = payload[
                  findClauseSearchSemantic?.recordsAggregationFindPattern
                ].find(
                  (expression) =>
                    expression?.identifier ===
                    FILTER_OPTIONS?.FILTER_DESTINATION_PORT
                );
                if (_CheckedData.FILTER_DESTINATION_PORT) {
                  const field = [];
                  findClauseFilterDestinationPort.expressionType = 206;
                  findClauseFilterDestinationPort.fieldTermTypeSuffix = "";
                  for (const [key, value] of Object.entries(
                    _CheckedData.FILTER_DESTINATION_PORT
                  )) {
                    field.push(key.split("_").join(" "));
                  }
                  findClauseFilterDestinationPort.fieldValue = field;
                  aggregationPayload[
                    findClauseSearchSemantic?.recordsAggregationFindPattern
                  ].push(findClauseFilterDestinationPort);
                  findClauseFilterDestinationPort.filterType = true;
                }
              }
              break;
            }
            case FILTER_OPTIONS?.FILTER_ORIGIN_COUNTRY: {
              if (!removeButtonState?.FILTER_ORIGIN_COUNTRY) {
                let findClauseFilterOriginCountry = payload[
                  findClauseSearchSemantic?.recordsAggregationFindPattern
                ].find(
                  (expression) =>
                    expression?.identifier ===
                    FILTER_OPTIONS?.FILTER_ORIGIN_COUNTRY
                );
                if (_CheckedData.FILTER_ORIGIN_COUNTRY) {
                  const field = [];
                  findClauseFilterOriginCountry.expressionType = 206;
                  findClauseFilterOriginCountry.fieldTermTypeSuffix = "";
                  for (const [key, value] of Object.entries(
                    _CheckedData.FILTER_ORIGIN_COUNTRY
                  )) {
                    field.push(key.split("_").join(" "));
                  }
                  findClauseFilterOriginCountry.fieldValue = field;
                  aggregationPayload[
                    findClauseSearchSemantic?.recordsAggregationFindPattern
                  ].push(findClauseFilterOriginCountry);
                  findClauseFilterOriginCountry.filterType = true;
                }
              }
              break;
            }
            case FILTER_OPTIONS?.FILTER_ORIGIN_PORT: {
              if (!removeButtonState?.FILTER_ORIGIN_COUNTRY) {
                let findClauseFilterOriginPort = payload[
                  findClauseSearchSemantic?.recordsAggregationFindPattern
                ].find(
                  (expression) =>
                    expression?.identifier ===
                    FILTER_OPTIONS?.FILTER_ORIGIN_PORT
                );
                if (_CheckedData.FILTER_ORIGIN_PORT) {
                  const field = [];
                  findClauseFilterOriginPort.expressionType = 206;
                  findClauseFilterOriginPort.fieldTermTypeSuffix = "";
                  for (const [key, value] of Object.entries(
                    _CheckedData.FILTER_ORIGIN_PORT
                  )) {
                    field.push(key.split("_").join(" "));
                  }
                  findClauseFilterOriginPort.fieldValue = field;
                  aggregationPayload[
                    findClauseSearchSemantic?.recordsAggregationFindPattern
                  ].push(findClauseFilterOriginPort);
                  findClauseFilterOriginPort.filterType = true;
                }
              }
              break;
            }
            case FILTER_OPTIONS?.FILTER_REPORTING_COUNTRY: {
              if (!removeButtonState?.FILTER_REPORTING_COUNTRY) {
                let findClauseFilterReportingCountry = payload[
                  findClauseSearchSemantic?.recordsAggregationFindPattern
                ].find(
                  (expression) =>
                    expression?.identifier ===
                    FILTER_OPTIONS?.FILTER_REPORTING_COUNTRY
                );
                if (_CheckedData.FILTER_REPORTING_COUNTRY) {
                  const field = [];
                  findClauseFilterReportingCountry.expressionType = 206;
                  findClauseFilterReportingCountry.fieldTermTypeSuffix = "";
                  for (const [key, value] of Object.entries(
                    _CheckedData.FILTER_REPORTING_COUNTRY
                  )) {
                    field.push(key.split("_").join(" "));
                  }
                  findClauseFilterReportingCountry.fieldValue = field;
                  aggregationPayload[
                    findClauseSearchSemantic?.recordsAggregationFindPattern
                  ].push(findClauseFilterReportingCountry);
                  findClauseFilterReportingCountry.filterType = true;
                }
              }
              break;
            }
            case FILTER_OPTIONS?.FILTER_PORT: {
              if (!removeButtonState?.FILTER_PORT) {
                let findClauseFilterPort = payload[
                  findClauseSearchSemantic?.recordsAggregationFindPattern
                ].find(
                  (expression) =>
                    expression?.identifier === FILTER_OPTIONS?.FILTER_PORT
                );
                if (_CheckedData?.FILTER_PORT) {
                  const field = [];
                  findClauseFilterPort.expressionType = 206;
                  findClauseFilterPort.fieldTermTypeSuffix = "";
                  for (const [key, value] of Object.entries(
                    _CheckedData?.FILTER_PORT
                  )) {
                    field.push(key.split("_").join(" "));
                  }
                  findClauseFilterPort.fieldValue = field;
                  aggregationPayload[
                    findClauseSearchSemantic?.recordsAggregationFindPattern
                  ].push(findClauseFilterPort);
                  findClauseFilterPort.filterType = true;
                }
              }
              break;
            }
            case FILTER_OPTIONS?.FILTER_FOREIGN_PORT: {
              if (!removeButtonState?.FILTER_FOREIGN_PORT) {
                let findClauseFilterForeignPort =
                  payload[
                    findClauseSearchSemantic?.recordsAggregationFindPattern
                  ]?.find(
                    (expression) =>
                      expression?.identifier === "FILTER_FOREIGN_PORT"
                  ) ?? {};

                if (_CheckedData?.FILTER_FOREIGN_PORT) {
                  const field = [];
                  findClauseFilterForeignPort.expressionType = 206;
                  findClauseFilterForeignPort.fieldTermTypeSuffix = "";
                  for (const [key, value] of Object.entries(
                    _CheckedData?.FILTER_FOREIGN_PORT
                  )) {
                    field.push(key.split("_").join(" "));
                  }
                  findClauseFilterForeignPort.fieldValue = field;
                  aggregationPayload[
                    findClauseSearchSemantic?.recordsAggregationFindPattern
                  ].push(findClauseFilterForeignPort);
                  findClauseFilterForeignPort.filterType = true;
                }
              }

              break;
            }
            case FILTER_OPTIONS?.FILTER_UNIT: {
              if (!removeButtonState?.QUANTITY) {
                let findClauseFilterQuantity = payload[
                  findClauseSearchSemantic?.recordsAggregationFindPattern
                ].find(
                  (expression) =>
                    expression?.identifier === FILTER_OPTIONS?.FILTER_UNIT
                );

                if (_CheckedData?.QUANTITY) {
                  let field = "";
                  Object.keys(_CheckedData?.QUANTITY).map((e) => {
                    if (lastSelected.includes(e)) {
                      let field =
                        _CheckedData?.QUANTITY[e].value?.toUpperCase();
                      findClauseFilterQuantity.fieldValue = field;
                      findClauseFilterQuantity.filterType = true;
                      aggregationPayload[
                        findClauseSearchSemantic?.recordsAggregationFindPattern
                      ].push({ ...findClauseFilterQuantity });
                    }
                  });
                }
              }

              break;
            }
            case FILTER_OPTIONS?.FILTER_QUANTITY: {
              if (!removeButtonState?.QUANTITY) {
                let findClauseFilterQuantity = payload[
                  findClauseSearchSemantic?.recordsAggregationFindPattern
                ].find(
                  (expression) =>
                    expression?.identifier === FILTER_OPTIONS?.FILTER_QUANTITY
                );

                if (_CheckedData?.QUANTITY) {
                  Object.keys(_CheckedData?.QUANTITY).map((e) => {
                    if (lastSelected.includes(e)) {
                      let field =
                        _CheckedData?.QUANTITY[e].value?.toUpperCase();
                      findClauseFilterQuantity.fieldValueLeft =
                        _CheckedData?.QUANTITY[e]?.minRange;
                      findClauseFilterQuantity.fieldValueRight =
                        _CheckedData?.QUANTITY[e]?.maxRange;
                      findClauseFilterQuantity.unit = field;
                      findClauseFilterQuantity.filterType = true;
                      aggregationPayload[
                        findClauseSearchSemantic?.recordsAggregationFindPattern
                      ].push({ ...findClauseFilterQuantity });
                    }
                  });
                }
              }
              break;
            }
            case FILTER_OPTIONS?.FILTER_PRICE: {
              if (!removeButtonState?.PRICE) {
                if (_CheckedData?.PRICE) {
                  if (
                    filterFieldSemantic?.metaTag === _CheckedData?.PRICE?.value
                  ) {
                    let findClauseFilterCurrencyPrices = payload[
                      findClauseSearchSemantic.recordsAggregationFindPattern
                    ].filter(
                      (expression) =>
                        expression?.identifier === FILTER_OPTIONS?.FILTER_PRICE
                    );
                    let findClauseFilterPrice =
                      findClauseFilterCurrencyPrices &&
                      findClauseFilterCurrencyPrices.length &&
                      findClauseFilterCurrencyPrices.find(
                        (currencyPrice) =>
                          currencyPrice.metaTag === _CheckedData?.PRICE?.value
                      );

                    findClauseFilterPrice.fieldValueLeft =
                      _CheckedData?.PRICE?.minRange;
                    findClauseFilterPrice.fieldValueRight =
                      _CheckedData?.PRICE?.maxRange;
                    findClauseFilterPrice.filterType = true;
                    aggregationPayload[
                      findClauseSearchSemantic?.recordsAggregationFindPattern
                    ].push(findClauseFilterPrice);
                  }
                }
              }
              break;
            }
            case FILTER_OPTIONS?.FILTER_HS_CODE: {
              if (!removeButtonState[FILTER_OPTIONS?.HS_CODE]) {
                let findClauseFilterHsCode = payload[
                  findClauseSearchSemantic?.recordsAggregationFindPattern
                ].find(
                  (expression) =>
                    expression?.identifier === FILTER_OPTIONS?.FILTER_HS_CODE
                );

                if (_CheckedData[FILTER_OPTIONS?.HS_CODE]) {
                  let field = [];
                  for (const [key, value] of Object.entries(
                    _CheckedData[FILTER_OPTIONS?.HS_CODE]
                  )) {
                    // const newkey = key.replace(/^0+/, "");
                    field.push(key);
                  }
                  findClauseFilterHsCode.fieldValue = field;
                  aggregationPayload[
                    findClauseSearchSemantic?.recordsAggregationFindPattern
                  ].push(findClauseFilterHsCode);
                  //  isHsCodeFiltered = true;
                  findClauseFilterHsCode.filterType = true;
                }
              }
              break;
            }
            case FILTER_OPTIONS?.FILTER_MONTH: {
              if (!removeButtonState?.MONTH) {
                if (_CheckedData?.MONTH) {
                  let findClauseFilterMonth = JSON.parse(
                    JSON.stringify(
                      payload[
                        findClauseSearchSemantic?.recordsAggregationFindPattern
                      ].find(
                        (expression) =>
                          expression?.identifier === "FILTER_MONTH"
                      )
                    )
                  );

                  let field = [];
                  for (const [key, value] of Object.entries(
                    _CheckedData?.MONTH
                  )) {
                    field.push(key);
                  }
                  let findClauseFilterMonthOrArrs = [];
                  field &&
                    field?.length &&
                    field?.forEach((yearMonthValue) => {
                      let yearMonthValueArr = yearMonthValue.split("-");
                      let caliberatedEndMonthMaxDay = new Date(
                        yearMonthValueArr[0],
                        yearMonthValueArr[1],
                        0
                      ).getDate();
                      let fieldValues = {};
                      fieldValues.fieldValueLeft = yearMonthValueArr[0].concat(
                        "-",
                        yearMonthValueArr[1].toString().length > 1
                          ? yearMonthValueArr[1]?.toString()
                          : "0".concat(yearMonthValueArr[1].toString()),
                        "-",
                        "01"
                      );
                      fieldValues.fieldValueRight = yearMonthValueArr[0]
                        .toString()
                        .concat(
                          "-",
                          yearMonthValueArr[1].toString().length > 1
                            ? yearMonthValueArr[1]?.toString()
                            : "0".concat(yearMonthValueArr[1].toString()),
                          "-",
                          caliberatedEndMonthMaxDay.toString()
                        );

                      findClauseFilterMonthOrArrs.push(fieldValues);
                    });
                  findClauseFilterMonth.fieldValues =
                    findClauseFilterMonthOrArrs;
                  findClauseFilterMonth.filterType = true;
                  aggregationPayload[
                    findClauseSearchSemantic?.recordsAggregationFindPattern
                  ].push(findClauseFilterMonth);
                }
              }
              break;
            }
            case FILTER_OPTIONS?.TO_ORDER: {
              if (
                !removeButtonState?.REMOVE_ORDER_OR_NULL &&
                _CheckedData?.REMOVE_ORDER_OR_NULL?.TO_ORDER
              ) {
                let findClauseFilterToOrder = payload[
                  findClauseSearchSemantic?.recordsAggregationFindPattern
                ].find(
                  (expression) =>
                    expression?.identifier === FILTER_OPTIONS?.TO_ORDER
                );
                aggregationPayload[
                  findClauseSearchSemantic?.recordsAggregationFindPattern
                ].push(findClauseFilterToOrder);
              }
              break;
            }
            case FILTER_OPTIONS?.TO_NULL: {
              if (
                !removeButtonState?.REMOVE_ORDER_OR_NULL &&
                _CheckedData?.REMOVE_ORDER_OR_NULL?.TO_NULL
              ) {
                let findClauseFilterToNull = payload[
                  findClauseSearchSemantic?.recordsAggregationFindPattern
                ].find(
                  (expression) =>
                    expression?.identifier === FILTER_OPTIONS?.TO_NULL
                );
                aggregationPayload[
                  findClauseSearchSemantic?.recordsAggregationFindPattern
                ].push(findClauseFilterToNull);
              }
              break;
            }
            case FILTER_OPTIONS?.SEARCH_MODE: {
              if (
                !removeButtonState?.SEARCH_MODE &&
                _CheckedData?.SEARCH_MODE
              ) {
                let findClauseFilterSearchMode = payload[
                  findClauseSearchSemantic?.recordsAggregationFindPattern
                ].find(
                  (expression) =>
                    expression?.identifier === FILTER_OPTIONS?.SEARCH_MODE
                );
                findClauseFilterSearchMode.fieldValue = Object.keys(
                  _CheckedData?.SEARCH_MODE
                )[0];
                aggregationPayload[
                  findClauseSearchSemantic?.recordsAggregationFindPattern
                ].push(findClauseFilterSearchMode);
              }
              break;
            }
            default:
              break;
          }
        }
      );
  }

  //sorting
  const sortedColumn = sortingColumns(
    descendingAndAscending,
    sortedColumnList,
    findClauseSearchMonth
  );
  if (aggregationPayload && sortedColumn.length > 0) {
    aggregationPayload.sortTerms = sortedColumn;
  }

  return aggregationPayload;
};

export const packDTParams = (isCheckedEmpty, isCheckedOnSearch, data) => {
  let paramsObj = formulateDataShipmentPayload(
    data?.SHIPMENT_RESULT_TYPE_RECORDS,
    isCheckedEmpty,
    isCheckedOnSearch,
    data
  );
  const {
    isBlFlag,
    currentTradeType,
    currentCountryCode,
    currentCountry,
    filteredTaxonomy,
    rowsPerPage,
    estimateRecordsData,
    isWorkSpace,
    dateRange,
    workspaceTotalRecords,
    workspaceBucket,
    currentSelectedWorkSpace,
    searchField,
    getSelectedOptionForSearchTerm,
    getSearchOption,
    isExplore,
    isRemoveDuplicateButton,
    isFromRecentSearch,
  } = data;
  if (!isWorkSpace) {
    paramsObj.tradeType = currentTradeType;
    paramsObj.countryCode = currentCountryCode;

    if (isBlFlag === "true") {
      paramsObj.country = "bl";
    } else {
      paramsObj.country = currentCountry;
    }
    paramsObj.accountId = Cookies.get("accountId");
    paramsObj.userId = Cookies.get("user_id");
    paramsObj.tradeTotalRecords = estimateRecordsData;
    paramsObj.purchasable = filteredTaxonomy?.fields?.purchasable;
    if (
      ADD_USER_SEARCH_IN_SEARCH_OPTIONS?.includes(
        searchField?.getSelectExploreField
      )
    ) {
      if (getSearchOption && getSearchOption?.length > 0) {
        const _selectedValue = getSearchOption[0];
        let _getSelectedOptionForSearchTerm =
          getSelectedOptionForSearchTerm?.map((e) => {
            return e?._id;
          });
        let _getSelectedOptionForSearchKeys =
          getSelectedOptionForSearchTerm?.map((e) => {
            return e?.key;
          });
        const hasDashZero = _getSelectedOptionForSearchKeys.some((item) =>
          /-0/.test(item)
        );
        if (
          _getSelectedOptionForSearchTerm &&
          _getSelectedOptionForSearchTerm?.length > 0
        ) {
          if (
            _getSelectedOptionForSearchTerm?.includes(_selectedValue?._id) &&
            hasDashZero
          ) {
            if (_getSelectedOptionForSearchTerm?.length === 1) {
              paramsObj.isSuggestion = 1;
            } else {
              paramsObj.isSuggestion = 0;
            }
          } else {
            paramsObj.isSuggestion = 0;
          }
        }
      }
    } else {
      paramsObj.isSuggestion = 0;
    }
  }
  paramsObj.draw = 2;
  paramsObj.start = 0;
  paramsObj.length = 1000;
  if (isWorkSpace) {
    paramsObj.startDate = dateRange?.startMonthDate;
    paramsObj.endDate = dateRange?.endMonthDate;
    paramsObj.workspaceBucket = workspaceBucket;
    paramsObj.workspaceTotalRecords = workspaceTotalRecords;
    paramsObj.accountId = currentSelectedWorkSpace?.account_id;
    paramsObj.userId = currentSelectedWorkSpace?.user_id;
    paramsObj.workspaceId = currentSelectedWorkSpace?._id;
    paramsObj.country = currentSelectedWorkSpace?.country;
    paramsObj.tradeType = currentSelectedWorkSpace?.trade;
  }
  if (currentCountry === "INDIA" && isRemoveDuplicateButton) {
    paramsObj.removeDuplicateRelatedData =
      filteredTaxonomy?.fields?.remove_duplicate;
  }

  return paramsObj;
};
