import * as React from "react";
import "./viewColumns.scss";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { Button, FormControlLabel, Typography } from "@mui/material";
import BpCheckbox from "../CheckBox/BpCheckBox";
import { DefaultTableHeader } from "../../Containers/ExploreCountryShipmentNewUI/Explore.enum";
import { ButtonComponent } from "../ButtonComponent/Button";
import { DefaultWorkSpaceTableHeader } from "../../Containers/Workspace/Workspace.enum";

export default function ViewColumnModal({
  open,
  setOpen,
  setDataTableHeader,
  taxonomyId,
  addViewColumn,
  allHeader,
  setViewHeader,
  viewHeader,
  isWorkSpace,
  //   storeFilteredValues,
  //   setStoreFilterValues,
}) {
  const [viewColumnHeader, setViewColumnHeader] = React.useState({
    ...viewHeader,
  });

  //   const deleteField = (_viewColumnHeader, field) => {
  //     if (_viewColumnHeader.hasOwnProperty(field)) delete _viewColumnHeader[field];
  //     return _viewColumnHeader;
  //   };

  React.useEffect(() => {}, [allHeader]);

  const changeAndStoreFilterOption = (event, range) => {
    const { value } = event?.target;
    let _viewColumnHeader = { ...viewColumnHeader };
    _viewColumnHeader[value] = !_viewColumnHeader[value];

    setViewColumnHeader(_viewColumnHeader);
  };

  const storeFilterOptionOnClickApply = () => {
    let array = [];
    if (isWorkSpace) {
      array = [...DefaultWorkSpaceTableHeader];
    } else {
      array = [...DefaultTableHeader];
    }
    const accountHeaderArray = [];
    allHeader?.forEach((e) => {
      if (e?.key !== "" && e?.key !== "add" && e?.key !== "checkbox") {
        if (viewColumnHeader[e?.key]) {
          array.push(e);
          accountHeaderArray?.push(e?.key.split(" ").join("_"));
        }
      }
    });
    setViewHeader(viewColumnHeader);
    setDataTableHeader(array);

    let payload = {
      taxonomy_id: taxonomyId,
      selected_columns: accountHeaderArray,
    };
    addViewColumn(payload);
  };

  const changeAllFilterOption = (event) => {
    const { value } = event?.target;
    let _viewColumnHeader = { ...viewColumnHeader };
    allHeader?.forEach((e) => {
      if (e?.key !== "" && e?.key !== "add" && e?.key !== "checkbox") {
        _viewColumnHeader[e?.key] = value === "true" ? false : true;
      }
    });
    setViewColumnHeader(_viewColumnHeader);
  };
  return (
    <div>
      <React.Fragment key="viewColumn">
        <SwipeableDrawer
          anchor="right"
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
        >
          <Box
            id="view-column"
            sx={{ width: 380, height: "80%", overflow: "overlay" }}
            role="presentation"
            //   onKeyDown={toggleDrawer(anchor, false)}
          >
            <Typography className="sidebar-title" variant="h6" component="h2">
              Filter:
              <span>VIEW COLUMNS</span>
              <span
                className="sidebar-close"
                onClick={() => {
                  setOpen(false);
                }}
              >
                ×
              </span>
            </Typography>
            <div className="modal-sub-title">
              <div className="modal-checkbox-header-div">
                <div className="modal-check-btn">
                  <FormControlLabel
                    style={{ margin: "0px" }}
                    value={
                      Object.keys(viewColumnHeader).filter(
                        (e) => viewColumnHeader[e] === false
                      ).length > 0
                        ? false
                        : true
                    }
                    onChange={(event) => changeAllFilterOption(event)}
                    checked={
                      Object.keys(viewColumnHeader).filter(
                        (e) => viewColumnHeader[e] === false
                      ).length > 0
                        ? false
                        : true
                    }
                    control={<BpCheckbox />}
                  />
                </div>
                <div className="modal-check-lable">
                  <span className="check-btn-lable">
                    SELECT ALL / DESELECT ALL
                  </span>
                </div>
              </div>
            </div>
            <Box sx={{ py: 3, px: 3 }}>
              <div style={{ marginLeft: "15px" }}>
                {allHeader &&
                  allHeader.length > 0 &&
                  allHeader.map((e, index) => {
                    if (
                      e?.key !== "add" &&
                      e?.key !== "checkbox" &&
                      e?.key !== ""
                    ) {
                      return (
                        <Box key={index}>
                          <span>
                            <FormControlLabel
                              style={{ margin: "0px" }}
                              value={e?.key}
                              onChange={(e) =>
                                changeAndStoreFilterOption(e, [])
                              }
                              checked={viewColumnHeader[e?.key] ? true : false}
                              control={<BpCheckbox />}
                              label={
                                <Typography
                                  title={e?.title.toUpperCase()}
                                  style={{
                                    color: viewColumnHeader[e?.key]
                                      ? "#005d91"
                                      : "#6c757d",
                                  }}
                                >
                                  {e?.title?.toUpperCase()}
                                </Typography>
                              }
                            />
                          </span>
                        </Box>
                      );
                    }
                  })}
              </div>
            </Box>
          </Box>
          {/* <Button onClick={storeFilterOptionOnClickApply} className="viewcolumns-apply-btn">
            Apply
          </Button> */}
          <ButtonComponent
            name={"Apply"}
            style={{ margin: "24px" }}
            btnClick={storeFilterOptionOnClickApply}
          />
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}
