import React, { memo, useState } from "react";
import {
  ButtonComponent,
  ButtonComponentWithToolTip,
} from "../../../Component/ButtonComponent/Button";
import {
  BUTTON_NAME,
  FILTER_OPTIONS,
  analyzeBtn_in_explore_country,
} from "../Explore.enum";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import { Button } from "@mui/material";
import ReportsSvgIcon from "./SvgIcon";
import "../../../Component/ButtonComponent/Button.scss";
const ExploreButtonComponent = memo(
  ({
    storeUpdateSavedId,
    isQueryId,
    currentCountry,
    filterOptionClick,
    currentTradeType,
    dashboardLoading,
    ChangeRemoveDuplicateButton,
    isExplore,
  }) => {
    const [color, setColor] = useState("#005d91");
    return (
      <>
        <div>
          {/* {analyzeBtn_in_explore_country.includes(currentCountry) && (
            <ButtonComponent
              name={BUTTON_NAME?.ANALYZE}
              style={{
                margin: "5px 5px 8px 5px ",
                width: "120px",
                height: "50px",
              }}
              btnClick={() => {
                filterOptionClick(BUTTON_NAME?.ANALYZE);
              }}
            />
          )} */}
          <Button
            variant="outlined"
            sx={{
              margin: "5px 5px 8px 5px",
              width: "120px",
              height: "50px",
            }}
            startIcon={
              <div
                style={{
                  marginRight: "7px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ReportsSvgIcon color={color} />
              </div>
            }
            className={"buttonComponent btn-border btn-text"}
            onMouseEnter={() => setColor("#fff")}
            onMouseLeave={() => setColor("#005d91")}
            onClick={() => {
              filterOptionClick(BUTTON_NAME?.REPORT);
            }}
          >
            {BUTTON_NAME?.REPORT}
          </Button>
          {/* <ButtonComponentWithToolTip
            name={BUTTON_NAME?.REPORT}
            btnIcon={<ReportsSvgIcon />}
            style={{
              margin: "5px 5px 8px 5px",
              width: "120px",
              height: "50px",
            }}
            btnClick={() => {
              filterOptionClick(BUTTON_NAME?.REPORT);
            }}
            // isLoading={dashboardLoading}
            toUpperCase={true}
          /> */}
        </div>
        <div>
          {currentCountry === "INDIA" &&
            !ChangeRemoveDuplicateButton &&
            isExplore && (
              <ButtonComponentWithToolTip
                btnIcon={RemoveCircleIcon}
                style={{ margin: "5px 5px 8px 5px ", height: "50px" }}
                btnClick={() => {
                  filterOptionClick(BUTTON_NAME?.REMOVE_DUPLICATE);
                }}
                tooltipTitle={BUTTON_NAME?.REMOVE_DUPLICATE}
              />
            )}
          {currentCountry === "INDIA" &&
            ChangeRemoveDuplicateButton &&
            isExplore && (
              <ButtonComponentWithToolTip
                btnIcon={AddCircleIcon}
                style={{ margin: "5px 5px 8px 5px ", height: "50px" }}
                btnClick={() => {
                  filterOptionClick(BUTTON_NAME?.SET_DEFAULT);
                }}
                tooltipTitle={"Set Default"}
              />
            )}
          {currentCountry === "INDIA" && currentTradeType === "EXPORT" && (
            <ButtonComponentWithToolTip
              btnIcon={BusinessCenterIcon}
              style={{ margin: "5px 5px 8px 5px ", height: "50px" }}
              btnClick={() => {
                filterOptionClick(BUTTON_NAME?.REQUESTED_BUYERS);
              }}
              className="color-white"
              tooltipTitle={BUTTON_NAME?.REQUESTED_BUYERS}
            />
          )}
          <ButtonComponentWithToolTip
            btnIcon={VisibilityIcon}
            style={{ margin: "5px 5px 8px 5px ", height: "50px" }}
            btnClick={() => {
              filterOptionClick(FILTER_OPTIONS?.VIEW_COLUMNS);
            }}
            tooltipTitle={FILTER_OPTIONS?.VIEW_COLUMNS}
          />
          {!isQueryId && !storeUpdateSavedId && (
            <ButtonComponentWithToolTip
              btnIcon={SaveIcon}
              style={{ margin: "5px 5px 8px 5px ", height: "50px" }}
              btnClick={() => {
                filterOptionClick(BUTTON_NAME?.SAVE_QUERY);
              }}
              tooltipTitle={BUTTON_NAME?.SAVE_QUERY}
            />
          )}
          {isQueryId && (
            <ButtonComponentWithToolTip
              btnIcon={SaveAsIcon}
              style={{ margin: "5px 5px 8px 5px ", height: "50px" }}
              btnClick={() => {
                filterOptionClick(BUTTON_NAME?.SAVE_QUERY);
              }}
              tooltipTitle={BUTTON_NAME?.CREATE_NEW_QUERY}
            />
          )}
          {(isQueryId || storeUpdateSavedId) && (
            <ButtonComponentWithToolTip
              btnIcon={EditIcon}
              style={{ margin: "5px 5px 8px 5px ", height: "50px" }}
              btnClick={() => {
                filterOptionClick(BUTTON_NAME?.UPDATE_QUERY);
              }}
              tooltipTitle={BUTTON_NAME?.UPDATE_QUERY}
            />
          )}
          <ButtonComponent
            name={BUTTON_NAME?.ADD_ALL_RECORDS_TO_WORKSPACE}
            style={{
              margin: "5px 5px 8px 5px ",
              width: "160px",
              height: "50px",
              fontSize: "10px",
            }}
            btnClick={() => {
              filterOptionClick(BUTTON_NAME?.ADD_ALL_RECORDS_TO_WORKSPACE);
            }}
            className="color-white"
            typographyClassName="typo_btn_font"
          />
          <ButtonComponent
            name={BUTTON_NAME?.ADD_SELECTED_RECORDS_TO_WORKSPACE}
            style={{
              margin: "5px 0px 8px 8px ",
              width: "160px",
              height: "50px",
              fontSize: "10px",
            }}
            btnClick={() => {
              filterOptionClick(BUTTON_NAME?.ADD_SELECTED_RECORDS_TO_WORKSPACE);
            }}
            typographyClassName="typo_btn_font"
          />
        </div>
      </>
    );
  }
);

export default ExploreButtonComponent;
