import React from "react";
import "./CountrySelector.scss";
import { makeStyles } from "@mui/styles";
import BpCheckbox from "../../../Component/CheckBox/BpCheckBox";
import noImageFound from "../../../Asset/images/noImageFound.jpg";
import CancelIcon from "@mui/icons-material/Cancel";

const useStyles = makeStyles((theme) => ({
  flag: {
    width: "30px",
    marginRight: theme.spacing(1),
  },
}));

const CountrySelector = ({
  selectedCountries,
  setSelectedCountries,
  handleCountryChange,
  regionWiseCountryData,
  searchQueryForCountryModal,
  isSearchCountryTrue,
  mirrorAccessCountryList,
  handleInaccessibleCountryPopUp,
}) => {
  const classes = useStyles();
  const handleRegionChange = (region, isChecked) => {
    const regionCountries = regionWiseCountryData[region].map((countryData) =>
      countryData.country?.toLowerCase()
    );

    if (isChecked) {
      const newSelectedCountries = [
        ...selectedCountries,
        ...regionCountries.filter(
          (country) => !selectedCountries.includes(country)
        ),
      ];
      let _accessibleCountry = mirrorAccessCountryList
        ?.filter((name) =>
          newSelectedCountries?.some(
            (selectedName) =>
              selectedName?.toLowerCase() === name?.toLowerCase()
          )
        )
        ?.map((name) => name.toLowerCase());

      setSelectedCountries(_accessibleCountry);
    } else {
      const newSelectedCountries = selectedCountries.filter(
        (country) => !regionCountries.includes(country)
      );

      setSelectedCountries(newSelectedCountries);
    }
  };

  const renderCountryList = (countries) => {
    return countries
      .filter((country) =>
        country.country
          .toLowerCase()
          .includes(searchQueryForCountryModal.toLowerCase())
      )
      .map((item, index) => {
        let isAccessibleCountry = mirrorAccessCountryList?.some(
          (cName) => cName?.toLowerCase() === item?.country?.toLowerCase()
        );
        let flagUrl = null;
        try {
          flagUrl = require(`../../../Asset/flags/${item.flag_uri}`);
        } catch (error) {
          flagUrl = noImageFound;
        }

        return (
          <span
            className="country-span"
            key={index}
            onClick={() => {
              if (!isAccessibleCountry) {
                handleInaccessibleCountryPopUp(item);
              }
            }}
            style={{ cursor: !isAccessibleCountry ? "pointer" : "" }}
          >
            <BpCheckbox
              id={`checkbox-${item.country}`}
              checked={selectedCountries.includes(item.country?.toLowerCase())}
              onChange={(event) => {
                handleCountryChange(event, item.country?.toLowerCase());
              }}
              disabled={!isAccessibleCountry}
            />
            {flagUrl && (
              <img src={flagUrl} alt="flag" className={classes.flag} />
            )}
            <label
              className="country-name"
              htmlFor={`checkbox-${item.country}`}
            >
              {item.country.split("_").join(" ").toUpperCase()}
            </label>
          </span>
        );
      });
  };

  const filteredRegions = Object.keys(regionWiseCountryData).reduce(
    (acc, region) => {
      const filteredCountries = regionWiseCountryData[region].filter(
        (country) =>
          country.country
            .toLowerCase()
            .includes(searchQueryForCountryModal.toLowerCase())
      );
      if (filteredCountries.length > 0) {
        acc[region] = filteredCountries;
      }
      return acc;
    },
    {}
  );
  const handleRemoveCountryFromSelectedCountryList = (countryName) => {
    const _selectedCountryByUser = selectedCountries;
    const filteredCountryArr = _selectedCountryByUser.filter(
      (item) => item !== countryName
    );
    setSelectedCountries(filteredCountryArr);
  };

  return (
    <div className="main-div">
      <div className="list-selected">
        {selectedCountries && selectedCountries.length > 0 && (
          <>
            <div className="heading-selected-country">Selected Country</div>
            <div className="container-selected-country">
              {selectedCountries &&
                selectedCountries.map((item, index) => {
                  return (
                    <>
                      <p className="selected-country-tag" key={index}>
                        <span
                          className="cancel-icon-span"
                          onClick={() =>
                            handleRemoveCountryFromSelectedCountryList(item)
                          }
                        >
                          <CancelIcon className="cancel-icon-for-selected-country" />
                        </span>
                        {item?.toUpperCase()?.replace(/_/g, " ")}
                      </p>
                    </>
                  );
                })}
            </div>
          </>
        )}
      </div>
      {Object.keys(filteredRegions).length > 0 ? (
        Object.keys(filteredRegions).map((region) => {
          const allRegionCountriesSelected = filteredRegions[region].every(
            (country) => selectedCountries.includes(country.country)
          );

          let _allAccessibleCountry = mirrorAccessCountryList
            ?.filter((name) =>
              filteredRegions[region]?.some(
                (selectedName) =>
                  selectedName?.country?.toLowerCase() === name?.toLowerCase()
              )
            )
            .map((item) => item?.toLowerCase());

          const _isCheckedAllAccessAbleCountry =
            _allAccessibleCountry.length > 0 &&
            _allAccessibleCountry.every((countryName) =>
              selectedCountries.includes(countryName)
            );

          return (
            <div key={region} className="country-list-container">
              <div className="region-heading">
                {!isSearchCountryTrue && (
                  <BpCheckbox
                    checked={_isCheckedAllAccessAbleCountry}
                    onChange={(e) =>
                      handleRegionChange(region, e.target.checked)
                    }
                  />
                )}

                {region.split("_").join(" ").toUpperCase()}
              </div>
              <div className="country-list">
                {renderCountryList(filteredRegions[region])}
              </div>
            </div>
          );
        })
      ) : (
        <div className="country-list">
          {renderCountryList(Object.values(regionWiseCountryData).flat())}
        </div>
      )}
    </div>
  );
};

export default CountrySelector;
