export const isPieChartValidation = (label, value) => {
  if (label !== "" && label !== "CHOOSE OPTION" && value !== "" && value !== "CHOOSE OPTION") {
    return true;
  } else {
    return false;
  }
};
export const isBarChartValidation = (xkey, ykey) => {
  if (ykey !== "" && xkey !== "" && ykey !== "CHOOSE OPTION" && xkey !== "CHOOSE OPTION") {
    return true;
  } else {
    return false;
  }
};
export const isBarChartYAxis2Validation = (ykey) => {
  if (ykey !== "" && ykey !== "CHOOSE OPTION") {
    return true;
  } else {
    return false;
  }
};
export const isLineChartValidation = (xkey, ykey) => {
  if (ykey !== "" && xkey !== "" && ykey !== "CHOOSE OPTION" && xkey !== "CHOOSE OPTION") {
    return true;
  } else {
    return false;
  }
};
