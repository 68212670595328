import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import ErrorPopup from "../../Component/ErrorLoader/ErrorPopup";
import Subscription from "./Subscription";

const SubscriptionWithError = (props) => {
  function ErrorHandler({ error }) {
    return <div role="alert">{error && <ErrorPopup errorMsg={"Something Went Wrong , Please Login Again"} open={error} />}</div>;
  }
  return (
    <>
      <ErrorBoundary FallbackComponent={ErrorHandler}>
        <Subscription CLIENT_ACCOUNT_ID={props.CLIENT_ACCOUNT_ID} CLIENT_USER_ID={props.CLIENT_USER_ID} />
      </ErrorBoundary>
    </>
  );
};

export default SubscriptionWithError;
