import React, { useState } from "react";
import "./signUp.scss";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import EximpediaLogo from "../../Asset/images/logo-dark-og.png";
import { getSignUpDetails } from "../../Services/service";
import { Link, useNavigate } from "react-router-dom";
import AuthFooter from "../../Component/AuthFooter/authFooter";
import fetchCountryData from "../../fetchCountryData";
import Loading from "../../Component/SuccessLoader/success";
import Error from "../../Component/ErrorLoader/error";
import PasswordStrength from "../../Component/PasswordStrength/PasswordStrength";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const DisplayingErrorMessagesSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("This value is required."),
  cmpName: Yup.string().required("This value is required."),
  password: Yup.string()
    .required("This value is required.")
    .matches(/^(?=.*\d)(?=.*[!@#$%_^&*])(?=.*[A-Z]).{8,16}$/, "Please enter strong password"),
  reTypePassword: Yup.string().when("password", {
    is: (val) => (val && val.length > 0 ? true : false),
    then: Yup.string().oneOf([Yup.ref("password")], "Bo"),
  }),
  phnNo: Yup.string().matches(phoneRegExp, "Phone number is not valid").max(12, "This value is too long. It should have 12 characters or fewer."),
  address: Yup.string().required("This value is required.").min(20, "You need to enter at least a 20 character address.."),
  subsPlan: Yup.string().required("This value is required."),
  heard: Yup.string().required("This value is required."),
  pincode: Yup.string().required("This value is required."),
  country: Yup.string().required("This value is required."),
});
const SignUp = () => {
  const navigate = useNavigate();
  const [error, seterror] = useState(false);
  const [errorMsg, seterrorMsg] = useState({});
  const [loading, setloading] = useState(false);
  const [countryData, setcountryData] = useState(fetchCountryData);
  const [cmpEmail, setCmpEmail] = useState("");
  const [webURL, setWebURl] = useState("");
  const [isVisible, setVisible] = useState(false);
  const [passwordType, setPasswordType] = useState("password");

  const [tinNO, setTINNO] = useState(0);

  function registration(values) {
    setloading(true);
    let payload = {
      company: {
        name: values.cmpName,
        email_id: cmpEmail,
        website_url: webURL,
        phone_no: values.phnNo,
        fax_no: "-",
        tax_identification_no: tinNO,
        address: values.address,
        pin_code: values.pincode,
        city: "-",
        state: "-",
        country: values.country,
      },
      plan: {
        subscriptionType: values.subsPlan,
      },
      referral_medium: values.heard,
      user: {
        email_id: values.email,
        password: values.password,
      },
    };

    getSignUpDetails(payload)
      .then((signUpResponse) => {
        if (signUpResponse.data.type) {
          setloading(false);
          seterror(true);
          seterrorMsg({
            title: signUpResponse.data.msg,
            content: signUpResponse.data.desc,
          });
        } else {
          let activationEmailId = signUpResponse.data.activation_email_id;

          navigate(`accounts/email/activation?activation_email=${activationEmailId}`);
        }
        setTimeout(() => {
          setloading(false);
        }, 2000);
      })
      .catch((e) => {
        setTimeout(() => {
          setloading(false);
          seterror(true);
        }, 2000);
      });
  }

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  return (
    <div>
      {error && <Error errorMsg={errorMsg} open={error} setOpen={seterror} />}
      {loading && <Loading title={"Registering Account"} content={"Verifying Account Specifications"} />}
      <Formik
        initialValues={{
          email: "",
          cmpName: "",
          password: "",
          reTypePassword: "",
          address: "",
          subsPlan: "SP-A",
          heard: "",
          pincode: "",
          country: "",
          phnNo: "",
        }}
        validationSchema={DisplayingErrorMessagesSchema}
        onSubmit={(values) => {
          // same shape as initial values
          registration(values);
        }}
      >
        {({ errors, touched, handleChange, values }) => (
          <Form>
            {/* <button type="submit">Submit</button> */}
            <div id="signup-page">
              <div className="background-content" style={{ height: "auto" }}>
                <div className="container">
                  <div>
                    <div style={{ paddingTop: "80px" }} className="col-md-10 d-flex">
                      <div style={{ paddingTop: "10px", height: "auto" }} className="card-sign-up bg-pattern">
                        <div className="card-body p-4">
                          <div className="text-center w-75 m-auto">
                            <a href="/consumers/login" rel="noopener noreferrer">
                              <span>
                                <img src={EximpediaLogo} alt="" height="44" />
                              </span>
                            </a>
                            <p className="text-muted mb-4 mt-3">Don't have an account? Create your account, and explore the Eximpedia Ecosystem!</p>
                          </div>
                          <div className="col-flex">
                            <div style={{ padding: "0px 15px" }} className="col-6">
                              <h4 className="mt-0">Company Profile Details</h4>
                              <p className="text-width-signUp  text-muted ">Add your account details, it helps us understand you better</p>
                              <div className="form-group">
                                <label htmlFor="company-name-input">
                                  Company Name
                                  <span className="text-danger">*</span>
                                </label>
                                <Field type="text" id="company-name-input" name="cmpName" className="form-control" placeholder="Type something" />
                                {touched.cmpName && errors.cmpName && <div className="error-message">{errors.cmpName}</div>}
                              </div>
                              <div className="form-group">
                                <label htmlFor="company-email-id-input">Company Email</label>
                                <div>
                                  <input
                                    name="company-email"
                                    id="company-email-id-input"
                                    className="form-control"
                                    parsley-type="company-email"
                                    autoComplete="company-email"
                                    onChange={(e) => setCmpEmail(e.target.value)}
                                    placeholder="Enter a valid e-mail"
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <label htmlFor="company-website-url-input">Website Url</label>
                                <div>
                                  <input
                                    id="company-website-url-input"
                                    name="company-website-url-input"
                                    parsley-type="url"
                                    type="url"
                                    onChange={(e) => setWebURl(e.target.value)}
                                    className="form-control"
                                    placeholder="URL"
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <label htmlFor="company-phone-no-input">Phone No</label>
                                <div>
                                  <Field type="number" id="company-phone-no-input" name="phnNo" className="form-control" placeholder="Enter Phone Number" />
                                  {touched.phnNo && errors.phnNo && <div className="error-message">{errors.phnNo}</div>}
                                </div>
                              </div>
                              <div className="form-group">
                                <label htmlFor="company-tax-identification-no-input">Tax Identification Number (TIN)</label>
                                <div>
                                  <input
                                    id="company-tax-identification-no-input"
                                    name="company-tax-identification-no-input"
                                    data-parsley-type="alphanum"
                                    type="text"
                                    onChange={(e) => setTINNO(e.target.value)}
                                    className="form-control"
                                    placeholder="Enter alphanumeric value"
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <label htmlFor="company-address-input">
                                  Address<span className="text-danger"> *</span>
                                </label>
                                <Field as="textarea" id="company-address-input" name="address" className="form-control" />
                                {touched.address && errors.address && <div className="error-message">{errors.address}</div>}
                              </div>
                              <div className="form-group">
                                <label htmlFor="company-pin-code-input">
                                  Pin Code
                                  <span className="text-danger"> *</span>
                                </label>
                                <div>
                                  <Field type="number" id="company-pin-code-input" name="pincode" className="form-control" placeholder="Enter numeric value" />
                                  {touched.pincode && errors.pincode && <div className="error-message">{errors.pincode}</div>}
                                </div>
                              </div>
                              <div className="form-group">
                                <label htmlFor="country-select">
                                  Country<span className="text-danger"> *</span>
                                </label>
                                <div>
                                  <select className="custom-select dropdown" id="country" name="country" onChange={handleChange("country")}>
                                    {countryData.map((e, index) => {
                                      return (
                                        <option key={index} value={e}>
                                          {e}
                                        </option>
                                      );
                                    })}
                                  </select>
                                  {touched.country && errors.country && <div className="error-message">{errors.country}</div>}
                                </div>
                              </div>
                            </div>
                            <div style={{ padding: "0px 15px" }} className="col-6">
                              <h4 className="mt-0">Access Details</h4>
                              <p className="text-muted text-width-signUp ">Enter your email address and password to access account.</p>
                              <div className="form-group">
                                <label htmlFor="user-email-id-input">
                                  Email Id
                                  <span className="text-danger"> *</span>
                                </label>
                                <div>
                                  <Field name="email" id="email-id-input" className="form-control" autoComplete="username" placeholder="Enter a valid e-mail" />
                                  {touched.email && errors.email && <div className="error-message">{errors.email}</div>}
                                </div>
                              </div>
                              <div className="form-group">
                                {passwordType == "password" ? (
                                  <RemoveRedEyeRoundedIcon
                                    style={{
                                      marginTop: "42px",
                                      marginLeft: "360px",
                                      position: "absolute",
                                      cursor: "pointer",
                                      color: "#817979",
                                    }}
                                    onClick={togglePassword}
                                  />
                                ) : (
                                  <VisibilityOffRoundedIcon
                                    style={{
                                      marginTop: "42px",
                                      marginLeft: "360px",
                                      position: "absolute",
                                      cursor: "pointer",
                                      color: "#817979",
                                    }}
                                    onClick={togglePassword}
                                  />
                                )}
                                <label htmlFor="user-password-input">
                                  Password
                                  <span className="text-danger"> *</span>
                                </label>
                                <div>
                                  <Field
                                    type={passwordType}
                                    id="user-password-input"
                                    name="password"
                                    className="form-control"
                                    placeholder="Password"
                                    autoComplete="new-password"
                                    onFocus={() => setVisible(true)}
                                  />
                                  {touched.password && errors.password && <div className="error-message">{errors.password}</div>}
                                </div>
                                <div className="mt-2">
                                  <RemoveRedEyeRoundedIcon
                                    style={{
                                      marginTop: "42px",
                                      marginLeft: "360px",
                                      position: "absolute",
                                    }}
                                  />
                                  <Field
                                    type="password"
                                    id="user-re-password-input"
                                    name="reTypePassword"
                                    className="form-control"
                                    placeholder="Re-Type Password"
                                    autoComplete="new-password"
                                  />
                                  {touched.reTypePassword && errors.reTypePassword && <div className="error-message">{errors.reTypePassword}</div>}
                                  {isVisible && <PasswordStrength values={values} />}
                                </div>
                              </div>
                              <h4 className="mt-4">Plan Details</h4>
                              <p className="text-muted mb-4">Privileges as per the selected plan will be applied to your account</p>
                              <div className="form-group">
                                <label htmlFor="plan-type-select">
                                  Subscription Plan
                                  <span className="text-danger">*</span>
                                </label>
                                <div>
                                  <Field
                                    as="select"
                                    className="custom-select dropdown"
                                    id="plan-type-select"
                                    name="subsPlan"
                                    onChange={handleChange("subsPlan")}
                                  >
                                    <option value="">Choose..</option>
                                    <option value="SP-A">Basic Free Plan</option>
                                  </Field>
                                  {touched.subsPlan && errors.subsPlan && <div className="error-message">{errors.subsPlan}</div>}
                                </div>
                              </div>
                              <div className="form-group">
                                <label htmlFor="referral-medium-select">
                                  Heard about us via
                                  <span className="text-danger">*</span>
                                </label>
                                <div>
                                  <select className="custom-select dropdown" id="referral-medium-select" name="heard" onChange={handleChange("heard")}>
                                    <option value="">Choose..</option>
                                    <option value="PRESS">Press</option>
                                    <option value="NET">Internet</option>
                                    <option value="MOUTH">Word of mouth</option>
                                    <option value="OTHER">Other..</option>
                                  </select>
                                  {touched.heard && errors.heard && <div className="error-message">{errors.heard}</div>}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="form-group mt-3 mb-0 text-center">
                              <div>
                                <button id="cta-register-account" type="submit" className="btn btn-primary waves-effect waves-light btn-block">
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                          {/* </form> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="col-12 text-center">
                      <p className="text-white-50" style={{ paddingBottom: "30px", marginTop: "-5px" }}>
                        Already have account?
                        <Link to="/consumers/login" className="text-white ml-1" rel="noopener noreferrer">
                          <b>Sign In</b>
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
                <AuthFooter name="reg" />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default SignUp;
