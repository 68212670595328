import React, { useState } from "react";
import "./ToggleSwitch.scss";

const ToggleSwitch = ({ labels, onChange }) => {
  const [switchPosition, setSwitchPosition] = useState("left");
  const [animation, setAnimation] = useState(null);

  const getSwitchAnimation = (value) => {
    let newAnimation = null;
    if (value === "center" && switchPosition === "left") {
      newAnimation = "left-to-center";
    } else if (value === "right" && switchPosition === "center") {
      newAnimation = "center-to-right";
    } else if (value === "center" && switchPosition === "right") {
      newAnimation = "right-to-center";
    } else if (value === "left" && switchPosition === "center") {
      newAnimation = "center-to-left";
    } else if (value === "right" && switchPosition === "left") {
      newAnimation = "left-to-right";
    } else if (value === "left" && switchPosition === "right") {
      newAnimation = "right-to-left";
    }
    onChange(value);
    setSwitchPosition(value);
    setAnimation(newAnimation);
  };

  return (
    <div className="main-container-switch">
      <div className={`switch ${animation} ${switchPosition}-position }`}></div>
      <input
        defaultChecked
        onChange={(e) => getSwitchAnimation(e.target.value)}
        name="map-switch"
        id="left"
        type="radio"
        value="left"
      />
      <label
        className={`left-label ${switchPosition === "left" && "black-font"}`}
        htmlFor="left"
      >
        <span
          className={`${
            switchPosition === "left" ? "is_active" : ""
          } country_type`}
        >
          CUSTOM
        </span>
      </label>

      <input
        onChange={(e) => getSwitchAnimation(e.target.value)}
        name="map-switch"
        id="center"
        type="radio"
        value="center"
      />
      <label
        className={`center-label ${
          switchPosition === "center" && "black-font"
        }`}
        htmlFor="center"
      >
        <span
          className={`${
            switchPosition === "center" ? "is_active" : ""
          } country_type`}
        >
          SILK ROUTE
        </span>
      </label>

      <input
        onChange={(e) => getSwitchAnimation(e.target.value)}
        name="map-switch"
        id="right"
        type="radio"
        value="right"
      />
      <label
        className={`right-label ${switchPosition === "right" && "black-font"}`}
        htmlFor="right"
      >
        <span
          className={`${
            switchPosition === "right" ? "is_active" : ""
          } country_type`}
        >
          BL
        </span>
      </label>
    </div>
  );
};

export default ToggleSwitch;
