import React from 'react';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import "./collapseTable.scss"
import { Table } from 'antd';
import { STICKYCOLUMN, maxWidth_column } from '../StickyTable/enum/Table.enum';
const CollapseTable = ({ columns, rows, handleOpen, childTableRows, childTableColumns, handleCollapseOpenModal }) => {
    const expandedRowRender = () => {
        const data = childTableRows.map((e) => {
            return e?.recordRow
        })
        const header = childTableColumns && childTableColumns?.length > 0 && childTableColumns?.map((e) => {
            e.width = ["BE_TYPE"].includes(e?.key) ? 120 : maxWidth_column?.includes(e?.key) ? 420 : 200
            if (e.key !== "" && e?.key !== null && STICKYCOLUMN.includes(e?.key)) {
                e.fixed = 'left';
            }
            if (e?.key === "add") {
                e.render = (text, record, index) => (
                    <div style={{ textAlign: "center" }}>
                        <RemoveRedEyeIcon onClick={() => handleCollapseOpenModal(childTableRows, record, index)} style={{ color: "#9ea5ab", cursor: "pointer" }} />
                    </div>
                )
                e.width = 75;
            }
            return e
        })
        return <div id='collapseTable-div'>
            <Table columns={header} dataSource={data} pagination={false} scroll={{
                x: "100%"
            }} /></div>;
    };
    const customExpandIcon = ({ expanded, onExpand, record }) => {
        return (
            <span onClick={(e) => handleOpen(record, e)}>
                {expanded ? (
                    <a style={{ marginRight: '8px' }} onClick={(e) => onExpand(record, e)}>
                        <RemoveIcon />
                    </a>
                ) : (
                    <a onClick={(e) => onExpand(record, e)}>
                        <AddIcon />
                    </a>
                )}
            </span>
        );
    };
    const data = [];
    for (let i = 0; i < 3; ++i) {
        data.push({
            key: i.toString(),
            name: 'Screen',
            platform: 'iOS',
            version: '10.3.4.5654',
            upgradeNum: 500,
            creator: 'Jack',
            createdAt: '2014-12-24 23:12:00',
        });
    }

    return (
        <div className='collapseTable'>
            <Table
                columns={columns}
                expandable={{
                    expandedRowRender,
                    defaultExpandedRowKeys: ['3'],
                }}
                dataSource={rows}
                size="middle"
                expandIcon={customExpandIcon}
            /></div>
    );
};
export default CollapseTable;