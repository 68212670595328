import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import GlobalSearch from "./GlobalSearch";
import ErrorPopup from "../../Component/ErrorLoader/ErrorPopup";
import { logger } from "../../Loggers/Loggers";

const GlobalSearchWithError = () => {
  function ErrorHandler({ error }) {
    logger(`Global Search ${error?.message}`);
    return (
      <div role="alert">
        {error && (
          <ErrorPopup
            errorMsg={"Something Went Wrong , Please Login Again"}
            open={error}
          />
        )}
      </div>
    );
  }
  return (
    <>
      <ErrorBoundary FallbackComponent={ErrorHandler}>
        <GlobalSearch />
      </ErrorBoundary>
    </>
  );
};

export default GlobalSearchWithError;
