import React from "react";
import "./SaveQueryCard.scss";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CircleIcon from "@mui/icons-material/Circle";
import noImageFound from "../../../../Asset/images/noImageFound.jpg";
import SearchIcon from "@mui/icons-material/Search";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Tooltip from "@mui/material/Tooltip";

const SaveQueryCard = ({
  title,
  date,
  tradeType,
  country,
  recordCount,
  action,
  countryFlag,
  handleRunCompany,
  handleOpenDeleteModal,
  data,
  lastUpdateDate,
  isFromSaveQuery,
}) => {
  const shortName = (name) => {
    if (name?.length > 25) {
      return name?.slice(0, 25) + "...";
    }
    return name;
  };

  let flagUrl = null;
  try {
    flagUrl = require(`../../../../Asset/flags/${countryFlag}`);
  } catch {
    flagUrl = noImageFound;
  } finally {
    return (
      <div className="info-card">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Tooltip title={title} placement="top" arrow>
            <p className="save-query-title">{shortName(title)}</p>
          </Tooltip>

          {flagUrl && (
            <img
              src={flagUrl}
              style={{ height: "25px", borderRadius: "2px" }}
              className="set-image"
              alt="flag"
            />
          )}
        </div>

        <div
          className="info-card-date"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="flex-x-y-axis-center">
            <span className="icon">
              <AccessTimeIcon style={{ fontSize: "20px" }} />
            </span>
            <span>{date}</span>
          </div>

          {action ? (
            <span style={{ marginLeft: "130px" }}>{action}</span>
          ) : (
            <div style={{ marginLeft: "127px" }}>
              <Tooltip title={"View Company"} arrow>
                <SearchIcon
                  onClick={() => handleRunCompany(data)}
                  style={{
                    color: "#005d91",
                    margin: "0px 5px 0px 4px",
                    height: "1em",
                    cursor: "pointer",
                  }}
                  fontSize="medium"
                  className="table-row-icon"
                />
              </Tooltip>
              <Tooltip title={"Delete"} arrow>
                <DeleteForeverIcon
                  onClick={() => handleOpenDeleteModal(data)}
                  style={{
                    color: "red",
                    margin: "0px 5px 0px 4px",
                    height: "1em",
                    cursor: "pointer",
                  }}
                  fontSize="medium"
                  className="table-row-icon"
                />
              </Tooltip>
            </div>
          )}
        </div>
        <div className="info-card-details">
          <ul className="save-query-card-details">
            <li className="save-query-li">
              <CircleIcon className="bullet_icon" />
              Trade Type: {tradeType}
            </li>
            <li className="save-query-li">
              <CircleIcon className="bullet_icon" />
              Country: {country.split("_").join(" ")}
            </li>
            {!isFromSaveQuery && (
              <li className="save-query-li">
                <CircleIcon className="bullet_icon" />
                Record Count: {recordCount}
              </li>
            )}
            {!isFromSaveQuery && (
              <li className="save-query-li">
                <CircleIcon className="bullet_icon" />
                Last Update at: {lastUpdateDate ?? "-"}
              </li>
            )}
          </ul>
        </div>
      </div>
    );
  }
};

export default SaveQueryCard;
