import React from "react";
import Cookies from "js-cookie";
import { Navigate } from "react-router-dom";

export default function ProtectedForAuth(props) {
  let Cmp = props.cmp;
  const token = Cookies.get("auth");
  // const token = localStorage.getItem("token");

  return (
    <div>
      {token === undefined ? (
        props?.path === "/consumers/login" || props?.path === "/login" ? (
          <Cmp setDefaultValue={props?.setDefaultValue} />
        ) : (
          <Cmp />
        )
      ) : (
        <Navigate to="/consumers/dashboard" />
      )}
    </div>
  );
}
