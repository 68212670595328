import React, { useEffect } from "react";
import classnames from "classnames";
import { usePagination, DOTS } from "./usePagination";
import "./pagination.scss";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Pagination = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className,
    isUnique,
    loadMore,
  } = props;
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });
  if (paginationRange) {
    if (currentPage === 0 || (paginationRange && paginationRange?.length < 2)) {
      return null;
    }
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange?.length - 1];

  return (
    <ul
      className={classnames("pagination-container", { [className]: className })}
    >
      {!isUnique && (
        <li
          className={classnames("pagination-item", {
            disabled: currentPage === 1,
          })}
          onClick={onPrevious}
        >
          <div>prev</div>
        </li>
      )}

      {paginationRange &&
        paginationRange?.length > 0 &&
        paginationRange?.map((pageNumber, index) => {
          if (pageNumber === DOTS) {
            return (
              <li key={index} className="pagination-item dots">
                &#8230;
              </li>
            );
          }

          return (
            <li
              key={index}
              className={
                isUnique
                  ? "llm-pagination"
                  : classnames("pagination-item notranslate", {
                      selected: pageNumber === currentPage,
                    })
              }
              onClick={() => onPageChange(pageNumber)}
            >
              {pageNumber}
            </li>
          );
        })}
      <li
        className={classnames("pagination-item", {
          disabled: currentPage === lastPage,
        })}
        onClick={onNext}
      >
        {!isUnique && <div>next</div>}
      </li>
      <li
        onClick={loadMore}
        style={{ cursor: "pointer" }}
        className="pagination-item"
      >
        {isUnique && (
          <div className="llm-prev-next" style={{ padding: "3px" }}>
            load more
            <ExpandMoreIcon />
          </div>
        )}
      </li>
    </ul>
  );
};

export default Pagination;
