export const SearchParams = {
  tradeType: "tradeType",
  taxonomy_id: "taxonomy_id",
  country: "country",
  IMPORTER_NAME: "IMPORTER_NAME",
  EXPORTER_NAME: "EXPORTER_NAME",
  startDate: "startDate",
  endDate: "endDate",
  dataEndDate: "dataEndDate",
};

export const TradeType = {
  IMPORT: "IMPORT",
  EXPORT: "EXPORT",
};

export const CurrentCheckedChart = {
  IMPORT_CHARTS: "IMPORT CHARTS",
  EXPORT_CHARTS: "EXPORT CHARTS",
};

export const MinDateForDatePicker = "2019-01-01";

export const ChartTitle = {
  Port_Vs_Quantity: "Port Vs Quantity",
  Country_Vs_Price_Vs_Quantity: "Country Vs Price Vs Quantity",
  HS_code_Vs_Price_Vs_Quantity: "HS code Vs Price Vs Quantity",
  Price_Vs_Quantity: "Price Vs Quantity",
};
