import React from "react";
import { TextField, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import fetchCountryData from "../../../fetchCountryData";

export default function EditCompanyDetails(props) {
  return (
    <div>
      <div>
        <TextField
          id="outlined-basic standard-error-helper-text"
          label="Company Name"
          variant="outlined"
          className="mt-5"
          name="name"
          onChange={props.handleChangeEditCompanyDetails}
          value={props.editCompanyDetails.name}
          style={{ width: "400px", margin: "10px" }}
          error={props.isEditCompanyNameEmpty.name}
          helperText={props.isEditCompanyNameEmpty.name && "This value is required."}
          required
        />

        <TextField
          id="outlined-basic standard-error-helper-text"
          label="Address"
          variant="outlined"
          className="mt-5"
          name="address"
          onChange={props.handleChangeEditCompanyDetails}
          value={props.editCompanyDetails.address}
          style={{ width: "400px", margin: "10px" }}
          error={props.isEditCompanyNameEmpty.address}
          helperText={props.isEditCompanyNameEmpty.address && "This value is required."}
          required
        />
      </div>

      <div>
        <TextField
          id="outlined-basic standard-error-helper-text"
          label="Company Email"
          variant="outlined"
          className="mt-5"
          name="email_id"
          onChange={props.handleChangeEditCompanyDetails}
          value={props.editCompanyDetails.email_id}
          style={{ width: "400px", margin: "10px" }}
        />

        <TextField
          id="outlined-basic standard-error-helper-text"
          label="Pin Code"
          variant="outlined"
          className="mt-5"
          name="pin_code"
          onChange={props.handleChangeEditCompanyDetails}
          value={props.editCompanyDetails.pin_code}
          style={{ width: "400px", margin: "10px" }}
          error={props.isEditCompanyNameEmpty.pin_code}
          helperText={props.isEditCompanyNameEmpty.pin_code && "This value is required."}
          required
          type="number"
        />
      </div>

      <div>
        <TextField
          id="outlined-basic standard-error-helper-text"
          label="Website URL"
          variant="outlined"
          className="mt-5"
          name="website_url"
          onChange={props.handleChangeEditCompanyDetails}
          value={props.editCompanyDetails.website_url}
          style={{ width: "400px", margin: "10px" }}
        />

        <FormControl sx={{ m: 1, background: "white", width: "400px" }}>
          <InputLabel id="demo-select-small">Country</InputLabel>
          <Select
            labelId="demo-select-smal"
            id="demo-select-small"
            value={props.editCompanyDetails.country}
            label="Country"
            name="country"
            onChange={props.handleChangeEditCompanyDetails}
            style={{ height: "56px", padding: "16px 4px" }}
          >
            {fetchCountryData.length > 0 &&
              fetchCountryData.map((e, index) => {
                return (
                  <MenuItem value={e} key={index}>
                    {e}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </div>

      <div>
        <TextField
          id="outlined-basic standard-error-helper-text"
          label="Phone No"
          variant="outlined"
          className="mt-5"
          name="phone_no"
          onChange={props.handleChangeEditCompanyDetails}
          value={props.editCompanyDetails.phone_no}
          style={{ width: "400px", margin: "10px" }}
        />

        <TextField
          id="outlined-basic standard-error-helper-text"
          label="Tax Identification Number (TIN)"
          variant="outlined"
          className="mt-5"
          name="tax_identification_no"
          onChange={props.handleChangeEditCompanyDetails}
          value={props.editCompanyDetails.tax_identification_no}
          style={{ width: "400px", margin: "10px" }}
        />
      </div>
    </div>
  );
}
