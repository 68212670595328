var ErrorData = {
  SOMETHING_WENT_WRONG: "Something Went Wrong , Please Login Again",
  SESSION_TIMEOUT: "Session TimeOut , Please Login Again",
  INTERNAL_SERVER: "Internal Server Error",
  COUNTRY_NOT_FOUND: "Explore Country Not Found",
  RECORD_LIMIT: "You can see only 200 records",
  SESSION_ACTIVATE:
    "Another session is currently active. Do you want to continue?",
};

export default ErrorData;
