const valueFilter = {
  options: [
    // {
    //   value: "DEFAULT ALL",
    //   title: "DEFAULT ALL",
    // },
    {
      value: "GREATER THAN (0-1 M)",
      title: "GREATER THAN (0-1 M)",
    },
    {
      value: "GREATER THAN (1-5 M)",
      title: "GREATER THAN (1-5 M)",
    },
    {
      value: "GREATER THAN (5-10 M)",
      title: "GREATER THAN (5-10 M)",
    },
    {
      value: "GREATER THAN (10-50 M)",
      title: "GREATER THAN (10-50 M)",
    },
    {
      value: "GREATER THAN (50 M-1 B)",
      title: "GREATER THAN (50 M-1 B)",
    },
    {
      value: "GREATER THAN (0-10 B)",
      title: "GREATER THAN (0-10 B)",
    },
    {
      value: "GREATER THAN (10-50 B)",
      title: "GREATER THAN (10-50 B)",
    },
    {
      value: "GREATER THAN (50 B)",
      title: "GREATER THAN (50 B)",
    },
  ],
  getRangeFromOption: (value) => {
    let min = 0;
    let max = 0;
    switch (value) {
      // case "DEFAULT ALL":
      //   min = 0;
      //   max = 0;
      //   break;
      case "GREATER THAN (0-1 M)":
        min = 0;
        max = 1000000;
        break;
      case "GREATER THAN (1-5 M)":
        min = 1000000;
        max = 5000000;
        break;
      case "GREATER THAN (5-10 M)":
        min = 5000000;
        max = 10000000;
        break;
      case "GREATER THAN (10-50 M)":
        min = 10000000;
        max = 50000000;
        break;
      case "GREATER THAN (50 M-1 B)":
        min = 50000000;
        max = 1000000000;
        break;
      case "GREATER THAN (0-10 B)":
        min = 0;
        max = 10000000000;
        break;
      case "GREATER THAN (10-50 B)":
        min = 10000000000;
        max = 50000000000;
        break;
      case "GREATER THAN (50 B)":
        min = 50000000000;
        max = 100000000000;
        break;
      default:
        min = 0;
        max = 0;
        break;
    }
    return { min, max };
  },
};

export default valueFilter;
