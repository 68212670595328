export const KAZAKHSTAN_IMPORT_RECORDS = {
  HS_CODE: "HS_CODE",
  PRODUCT_DESCRIPTION_EN: "PRODUCT_DESCRIPTION_EN",
  HS_CODE_DESCRIPTION_EN: "HS_CODE_DESCRIPTION_EN",
  BRAND_EN: "BRAND_EN",
  IMPORTER_NAME_EN: "IMPORTER_NAME_EN",
  IMPORTER_ADDRESS_EN: "IMPORTER_ADDRESS_EN",
  MANUFACTURER_EN: "MANUFACTURER_EN",
  SUPPLIER_NAME_EN: "SUPPLIER_NAME_EN",
  SUPPLIER_ADDRESS_EN: "SUPPLIER_ADDRESS_EN",
  GUARANTEE_NAME_EN: "GUARANTEE_NAME_EN",
  GUARANTEE_ADDRESS_EN: "GUARANTEE_ADDRESS_EN",
  DECLARANT_NAME_EN: "DECLARANT_NAME_EN",
  DECLARANT_ADDRESS_EN: "DECLARANT_ADDRESS_EN",
  G_WEIGHT: "G_WEIGHT",
  N_WEIGHT: "N_WEIGHT",
  QUANTITY: "QUANTITY",
  TOTAL_QUANTITY: "TOTAL_QUANTITY",
  UNIT_OF_QUANTITY: "UNIT_OF_QUANTITY",
  CARGO_VALUE_KZT: "CARGO_VALUE_KZT",
  CARGO_VALUE_USD: "CARGO_VALUE_USD",
  VALUE_IN_FOREIGN_CURRENCY: "VALUE_IN_FOREIGN_CURRENCY",
  FOREIGN_CURRENCY: "FOREIGN_CURRENCY",
  CARGO_VALUE_IN_FC: "CARGO_VALUE_IN_FC",
  TOTAL_COST: "TOTAL_COST",
  EXCHANGE_RATE: "EXCHANGE_RATE",
  CUSTOMS_VALUE_KZT: "CUSTOMS_VALUE_KZT",
  CUSTOMS_VALUE_USD: "CUSTOMS_VALUE_USD",
  INCOTERMS: "INCOTERMS",
  ORIGIN_COUNTRY_NAME_EN: "ORIGIN_COUNTRY_NAME_EN",
  COST_ADJUST_METHOD_NAME_EN: "COST_ADJUST_METHOD_NAME_EN",
  PLACE_OF_DELIVERY_EN: "PLACE_OF_DELIVERY_EN",
  TRANS_TYPE_CROSS_BORDER_EN: "TRANS_TYPE_CROSS_BORDER_EN",
  TRANS_TYPE_WITHIN_COUNTRY_EN: "TRANS_TYPE_WITHIN_COUNTRY_EN",
  FROM_COUNTRY_NAME_EN: "FROM_COUNTRY_NAME_EN",
  TO_COUNTRY_NAME_EN: "TO_COUNTRY_NAME_EN",
  TRADING_COUNTRY_NAME_EN: "TRADING_COUNTRY_NAME_EN",
  REGISTRATION_DATE: "REGISTRATION_DATE",
  DECLARATION_NUMBER: "DECLARATION_NUMBER",
  CUSTOMS_OFFICE_CODE: "CUSTOMS_OFFICE_CODE",
  CUSTOMS_OFFICE_NAME: "CUSTOMS_OFFICE_NAME",
  CUSTOMS_OFFICE_CODE_AT_BORDER: "CUSTOMS_OFFICE_CODE_AT_BORDER",
  CUSTOMS_OFFICE_NAME_AT_BORDER: "CUSTOMS_OFFICE_NAME_AT_BORDER",
  CUSTOMS_PROCEDURE_CODE: "CUSTOMS_PROCEDURE_CODE",
  CUSTOMS_PROCEDURE_NAME: "CUSTOMS_PROCEDURE_NAME",
  DIRECTION: "DIRECTION",
  MANUFACTURER: "MANUFACTURER",
  SUPPLIER_NAME: "SUPPLIER_NAME",
  SUPPLIER_ADDRESS: "SUPPLIER_ADDRESS",
  IMPORTER_CODE: "IMPORTER_CODE",
  IMPORTER_NAME: "IMPORTER_NAME",
  IMPORTER_ADDRESS: "IMPORTER_ADDRESS",
  GUARANTEE_CODE: "GUARANTEE_CODE",
  GUARANTEE_NAME: "GUARANTEE_NAME",
  GUARANTEE_ADDRESS: "GUARANTEE_ADDRESS",
  DECLARANT_CODE: "DECLARANT_CODE",
  DECLARANT_NAME: "DECLARANT_NAME",
  DECLARANT_ADDRESS: "DECLARANT_ADDRESS",
  PRODUCT_DESCRIPTION: "PRODUCT_DESCRIPTION",
  HS_CODE_DESCRIPTION: "HS_CODE_DESCRIPTION",
  BRAND: "BRAND",
  ORIGIN_COUNTRY_NAME: "ORIGIN_COUNTRY_NAME",
  COST_ADJUST_METHOD_CODE: "COST_ADJUST_METHOD_CODE",
  COST_ADJUST_METHOD_NAME: "COST_ADJUST_METHOD_NAME",
  PLACE_OF_DELIVERY: "PLACE_OF_DELIVERY",
  TRANS_TYPE_CROSS_BORDER: "TRANS_TYPE_CROSS_BORDER",
  TRANS_TYPE_WITHIN_COUNTRY: "TRANS_TYPE_WITHIN_COUNTRY",
  FROM_COUNTRY_NAME: "FROM_COUNTRY_NAME",
  TO_COUNTRY_NAME: "TO_COUNTRY_NAME",
  TRADING_COUNTRY_NAME: "TRADING_COUNTRY_NAME",
  CUSTOMS_OFFICE_NAME_EN: "CUSTOMS_OFFICE_NAME_EN",
  CUSTOMS_PROCEDURE_NAME_EN: "CUSTOMS_PROCEDURE_NAME_EN",
  RECORDS_TAG: "RECORDS_TAG",
  HS_CODE_2: "HS_CODE_2",
  HS_CODE_4: "HS_CODE_4",
  COUNTRY_ISO_CODE_2: "COUNTRY_ISO_CODE_2",
};

export const KAZAKHSTAN_EXPORT_RECORDS = {
  HS_CODE: "HS_CODE",
  PRODUCT_DESCRIPTION_EN: "PRODUCT_DESCRIPTION_EN",
  HS_CODE_DESCRIPTION_EN: "HS_CODE_DESCRIPTION_EN",
  BRAND_EN: "BRAND_EN",
  EXPORTER_NAME_EN: "EXPORTER_NAME_EN",
  EXPORTER_ADDRESS_EN: "EXPORTER_ADDRESS_EN",
  MANUFACTURER_EN: "MANUFACTURER_EN",
  BUYER_NAME_EN: "BUYER_NAME_EN",
  BUYER_ADDRESS_EN: "BUYER_ADDRESS_EN",
  GUARANTEE_NAME_EN: "GUARANTEE_NAME_EN",
  GUARANTEE_ADDRESS_EN: "GUARANTEE_ADDRESS_EN",
  DECLARANT_NAME_EN: "DECLARANT_NAME_EN",
  DECLARANT_ADDRESS_EN: "DECLARANT_ADDRESS_EN",
  TO_COUNTRY_NAME_EN: "TO_COUNTRY_NAME_EN",
  G_WEIGHT: "G_WEIGHT",
  N_WEIGHT: "N_WEIGHT",
  QUANTITY: "QUANTITY",
  TOTAL_QUANTITY: "TOTAL_QUANTITY",
  UNIT_OF_QUANTITY: "UNIT_OF_QUANTITY",
  CARGO_VALUE_KZT: "CARGO_VALUE_KZT",
  CARGO_VALUE_USD: "CARGO_VALUE_USD",
  VALUE_IN_FOREIGN_CURRENCY: "VALUE_IN_FOREIGN_CURRENCY",
  FOREIGN_CURRENCY: "FOREIGN_CURRENCY",
  CARGO_VALUE_IN_FC: "CARGO_VALUE_IN_FC",
  TOTAL_COST: "TOTAL_COST",
  EXCHANGE_RATE: "EXCHANGE_RATE",
  CUSTOMS_VALUE_KZT: "CUSTOMS_VALUE_KZT",
  CUSTOMS_VALUE_USD: "CUSTOMS_VALUE_USD",
  REGISTRATION_DATE: "REGISTRATION_DATE",
  DECLARATION_NUMBER: "DECLARATION_NUMBER",
  CUSTOMS_OFFICE_CODE: "CUSTOMS_OFFICE_CODE",
  CUSTOMS_OFFICE_NAME: "CUSTOMS_OFFICE_NAME",
  CUSTOMS_OFFICE_CODE_AT_BORDER: "CUSTOMS_OFFICE_CODE_AT_BORDER",
  CUSTOMS_OFFICE_NAME_AT_BORDER: "CUSTOMS_OFFICE_NAME_AT_BORDER",
  CUSTOMS_PROCEDURE_CODE: "CUSTOMS_PROCEDURE_CODE",
  CUSTOMS_PROCEDURE_NAME: "CUSTOMS_PROCEDURE_NAME",
  DIRECTION: "DIRECTION",
  MANUFACTURER: "MANUFACTURER",
  BUYER_NAME: "BUYER_NAME",
  BUYER_ADDRESS: "BUYER_ADDRESS",
  EXPORTER_CODE: "EXPORTER_CODE",
  EXPORTER_NAME: "EXPORTER_NAME",
  EXPORTER_ADDRESS: "EXPORTER_ADDRESS",
  GUARANTEE_CODE: "GUARANTEE_CODE",
  GUARANTEE_NAME: "GUARANTEE_NAME",
  GUARANTEE_ADDRESS: "GUARANTEE_ADDRESS",
  DECLARANT_CODE: "DECLARANT_CODE",
  DECLARANT_NAME: "DECLARANT_NAME",
  DECLARANT_ADDRESS: "DECLARANT_ADDRESS",
  PRODUCT_DESCRIPTION: "PRODUCT_DESCRIPTION",
  HS_CODE_DESCRIPTION: "HS_CODE_DESCRIPTION",
  BRAND: "BRAND",
  COST_ADJUST_METHOD_CODE: "COST_ADJUST_METHOD_CODE",
  COST_ADJUST_METHOD_NAME: "COST_ADJUST_METHOD_NAME",
  INCOTERMS: "INCOTERMS",
  PLACE_OF_DELIVERY: "PLACE_OF_DELIVERY",
  TRANS_TYPE_CROSS_BORDER: "TRANS_TYPE_CROSS_BORDER",
  TRANS_TYPE_WITHIN_COUNTRY: "TRANS_TYPE_WITHIN_COUNTRY",
  ORIGIN_COUNTRY_NAME: "ORIGIN_COUNTRY_NAME",
  FROM_COUNTRY_NAME: "FROM_COUNTRY_NAME",
  TO_COUNTRY_NAME: "TO_COUNTRY_NAME",
  TRADING_COUNTRY_NAME: "TRADING_COUNTRY_NAME",
  CUSTOMS_OFFICE_NAME_EN: "CUSTOMS_OFFICE_NAME_EN",
  CUSTOMS_OFFICE_NAME_AT_BORDER_EN: "CUSTOMS_OFFICE_NAME_AT_BORDER_EN",
  COST_ADJUST_METHOD_NAME_EN: "COST_ADJUST_METHOD_NAME_EN",
  PLACE_OF_DELIVERY_EN: "PLACE_OF_DELIVERY_EN",
  TRANS_TYPE_CROSS_BORDER_EN: "TRANS_TYPE_CROSS_BORDER_EN",
  TRANS_TYPE_WITHIN_COUNTRY_EN: "TRANS_TYPE_WITHIN_COUNTRY_EN",
  ORIGIN_COUNTRY_NAME_EN: "ORIGIN_COUNTRY_NAME_EN",
  FROM_COUNTRY_NAME_EN: "FROM_COUNTRY_NAME_EN",
  RECORDS_TAG: "RECORDS_TAG",
  TRADING_COUNTRY_NAME_EN: "TRADING_COUNTRY_NAME_EN",
  HS_CODE_2: "HS_CODE_2",
  HS_CODE_4: "HS_CODE_4",
  COUNTRY_ISO_CODE_2: "COUNTRY_ISO_CODE_2",
};
