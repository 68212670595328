import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import noImageFound from "../../../../Asset/images/noImageFound.jpg";
import "./RecentlySearchedCountryCard.scss";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
// import { useNavigate } from "react-router-dom";

const RecentSearchedCountry = ({
  topFiveRecentCountrySearch,
  countryListRef,
  redirectPage,
}) => {
  // const navigate = useNavigate();

  const getWidth = () => {
    const width = window.innerWidth;
    return width;
  };

  useEffect(() => {
    getWidth();
  }, []);

  return (
    <Grid
      container
      spacing={3}
      columnSpacing={{ xs: 1, sm: 2, md: 2 }}
      style={{ marginLeft: "-16px", marginTop: "0px" }}
    >
      {topFiveRecentCountrySearch &&
        topFiveRecentCountrySearch.length > 0 &&
        topFiveRecentCountrySearch.map((country, index) => {
          let flagUrl = null;
          try {
            flagUrl = require(`../../../../Asset/flags/${country?.flag}`);
          } catch (error) {
            flagUrl = noImageFound;
          } finally {
            return (
              <Grid
                className="recent-search-country-card-container"
                item
                lg={12}
                md={12}
                sm={12}
                key={index}
              >
                <div
                  className="recent-search-card-box-country"
                  onClick={() =>
                    getWidth() <= 425 ? redirectPage(country) : ""
                  }
                >
                  <div
                    className="recent-search-card-heading"
                    style={{ padding: "0px 0px 0px 1%", display: "flex" }}
                  >
                    <div className="flag-countryName-container">
                      {flagUrl && (
                        <img
                          src={flagUrl}
                          style={{ height: "25px" }}
                          className="set-image"
                          alt="flag"
                        />
                      )}
                      <span className="recent-searched-country-name">
                        {country.country.toUpperCase().split("_").join(" ")}
                      </span>
                    </div>

                    <div className="view-button-container">
                      <div>
                        <button
                          className="view-button-recent-country"
                          onClick={() => {
                            redirectPage(country);
                          }}
                        >
                          <div>View</div>
                          <RemoveRedEyeIcon
                            className="view-btn-eye-icon"
                            style={{
                              fontSize: "21px",
                            }}
                          />
                        </button>
                      </div>
                    </div>
                    <div
                      className="card-box ribbon-box-for-recent-country"
                      style={{
                        marginLeft: "auto",
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "0px",
                      }}
                    >
                      <div className="country-search-ribbon-trade-lable">
                        <div className="country-search-ribbon-success-trade-lable"></div>
                        <div className="rebbon-text-trade-lable-recent-country">
                          {country?.tradeType}
                        </div>
                        <div className="square-for-lable"></div>
                        <div className="country-search-ribbon-trade-lable-1">
                          <div className="country-search-ribbon-success-trade-lable-1"></div>
                          <div className="rebbon-text-trade-lable-recent-country-none"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            );
          }
        })}
    </Grid>
  );
};

export default React.memo(RecentSearchedCountry);
