import PasswordRecoveryComponent from "../../Component/PasswordRecoveryComponent/PasswordRecovery";
import { useLocation } from "react-router-dom";

const EmailActivation = (props) => {
  const search = useLocation().search;
  const email = new URLSearchParams(search).get("activation_email");
  return (
    <PasswordRecoveryComponent
      name="emailActivation"
      data={props}
      emailId={email}
    />
  );
};

export default EmailActivation;
