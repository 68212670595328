import React, { useState } from "react";
import "./Verify.scss";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import loginImage from "../../Asset/images/Enter OTP.png";
import Logo from "../../Asset/images/logo-dark-og-transparent.png";
import Error from "../../Component/ErrorLoader/error";
import Loading from "../../Component/SuccessLoader/success";
import { useLocation, useNavigate } from "react-router";
import {
  getPasswordVerifyDetails,
  getPasswordVerifyForSales,
  manageUserRoleOnActivation,
} from "../../Services/service";
import AuthFooter from "../../Component/AuthFooter/authFooter";
import { SALES_LOGIN_URL } from "./verify.enum";

const DisplayingErrorMessagesSchema = Yup.object().shape({
  otp: Yup.string().required("This value is required."),
});
const PasswordVerify = () => {
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const [error, seterror] = useState(false);
  const [errorMsg, seterrorMsg] = useState({});

  const search = useLocation().search;

  const location = useLocation();

  const { userId, userid, adminUserId, staySubUser, isFromSales } =
    location?.state;

  const handlePasswordUpdateSuccess = () => {
    if (userid && adminUserId && staySubUser) {
      userRoleChangeOnActivation();
    } else {
      if (isFromSales) {
        setTimeout(() => {
          window.location.href = SALES_LOGIN_URL;
          setloading(false);
        }, 1000);
      } else {
        setTimeout(() => {
          navigate("/consumers/login");
          setloading(false);
        }, 1000);
      }
    }
  };

  const handleError = (errorData) => {
    setloading(false);
    seterror(true);
    seterrorMsg({
      title: errorData?.desc,
      content: errorData?.msg,
      open: true,
    });
  };

  const userRoleChangeOnActivation = () => {
    let changeUserRole = {};
    changeUserRole.userid = userid;
    changeUserRole.adminuserid = adminUserId;
    changeUserRole.staysubuser = parseInt(staySubUser);
    manageUserRoleOnActivation(changeUserRole)
      .then((res) => {
        if (res?.status === 200) {
          setTimeout(() => {
            navigate("/consumers/login");
            setloading(false);
          }, 1500);
        } else if (res?.status === 400) {
          seterror(true);
          seterrorMsg({
            title: "Warning",
            content: res?.data?.message,
            open: true,
          });
          setTimeout(() => {
            navigate("/consumers/login");
            setloading(false);
            seterror(false);
          }, 2000);
        }
      })
      .catch((err) => {
        seterrorMsg({
          title: err?.status === 500 ? "Error" : "Warning",
          content: err?.data?.message,
          open: true,
        });
      });
  };

  function verifyPassword(values) {
    setloading(true);
    let verifyPassword = {};
    verifyPassword.passwordId = userId;
    verifyPassword.otp = values.otp;

    const apiPassWordVerify = isFromSales
      ? getPasswordVerifyForSales
      : getPasswordVerifyDetails;

    apiPassWordVerify(verifyPassword)
      .then((verifyPasswordResponse) => {
        if (
          verifyPasswordResponse.message === "Password updated successfully."
        ) {
          handlePasswordUpdateSuccess();
        } else if (verifyPasswordResponse?.data?.type === "UNAUTHORISED") {
          handleError(verifyPasswordResponse?.data);
        }
      })
      .catch((e) => {});
  }

  return (
    <div className="login-test-one-container">
      {loading && (
        <Loading title={"Email"} content={"Sending email for password reset"} />
      )}
      {error && <Error errorMsg={errorMsg} open={error} setOpen={seterror} />}
      <div className="container-one">
        <div className="container-one-image-content">
          <div className="login-content-image-container">
            <img id="login-content-img-one" src={loginImage} />
          </div>
          <h1 className="login-image-content-one">
            Step 4: Enter OTP (One-Time Password)
          </h1>
          <p className="login-image-content-para-one">
            For added security, we've sent a one-time password (OTP) to the
            mobile number associated with your account. Please enter the OTP
            below to verify your identity and proceed with resetting your
            password.
          </p>
        </div>
      </div>
      <div className="container-two">
        <div id="container-two-div">
          <div className="login-form-div">
            <div className="login-test-one-logo-div">
              <img
                src={Logo}
                // width={300}
                // height={100}
                id="login-test-one-logo"
                alt="logo"
              />
            </div>
            <div className="form-container-with-image-one">
              <div>
                <Formik
                  initialValues={{
                    otp: "",
                  }}
                  validationSchema={DisplayingErrorMessagesSchema}
                  onSubmit={(values) => {
                    verifyPassword(values);
                  }}
                >
                  {({ errors, touched }) => (
                    <Form>
                      {/* <button type="submit">Submit</button> */}
                      <div className="mb-5" id="forgot-password">
                        <div className="container">
                          <div className="rowCards">
                            <div
                              style={{ marginTop: "50px" }}
                              className="card-forgot bg-pattern"
                            >
                              <div className="card-body">
                                <div className="text-center w-75 m-auto">
                                  <p className="mb-4 mt-3">
                                    OTP sent successfully to your registered
                                    email. Please enter otp to verify your
                                    password
                                  </p>
                                </div>
                                {/* <form id="form-account-login" className="parsley-components"> */}
                                <div className="form-group">
                                  <label>OTP</label>
                                  <Field
                                    type="text"
                                    name="otp"
                                    className="form-control-forgot"
                                    placeholder="Enter your otp"
                                  />
                                  {touched.otp && errors.otp && (
                                    <div className="error-message">
                                      {errors.otp}
                                    </div>
                                  )}
                                </div>
                                <div className="form-group mb-0 text-center">
                                  <button
                                    type="submit"
                                    id="cta-login-account"
                                    style={{ marginTop: "20px" }}
                                    className="set-submit-btn button-color"
                                  >
                                    Verify OTP
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
            <div className="bottom-paragraph-test-one">
              <AuthFooter />
              {/* <p>2023-24 design and developed by Eximpedia.</p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordVerify;
