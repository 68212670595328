import React, { memo } from "react";
import {
  ButtonComponent,
  ButtonComponentWithToolTip,
} from "../ButtonComponent/Button";
import "./MonthTab.scss";
import SearchIcon from "@mui/icons-material/Search";
import Excel from "../../Asset/images/excel.png";
import { BUTTON_NAME } from "../../Containers/ExploreCountryShipmentNewUI/Explore.enum";
import DateRangePicker from "../DateRangePicker/DateRangePicker";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Button from "@mui/material/Button";
const MonthTab = memo(
  ({
    dateRange,
    handleDownloadExcel,
    setDateRange,
    handleDateRange,
    onSearchClick,
    setIsRecordBtnChecked,
    isGlobalSearch,
  }) => {
    const handleChangeMonth = (range) => {
      setDateRange({
        ...dateRange,
        monthRange: range,
      });
    };
    return (
      <>
        <div id="monthTab_container">
          <div className="monthTab-div">
            {!isGlobalSearch && (
              <div className="tab-div" onClick={() => handleChangeMonth(1)}>
                <Button
                  variant="outlined"
                  className={
                    dateRange?.monthRange === 1 ? "active" : "monthTab-btn"
                  }
                >
                  1 M
                </Button>
              </div>
            )}
            <div className="tab-div" onClick={() => handleChangeMonth(3)}>
              <Button
                variant="outlined"
                className={
                  dateRange?.monthRange === 3 ? "active" : "monthTab-btn"
                }
              >
                3 M
              </Button>
            </div>
            <div className="tab-div" onClick={() => handleChangeMonth(6)}>
              <Button
                variant="outlined"
                className={
                  dateRange?.monthRange === 6 ? "active" : "monthTab-btn"
                }
              >
                6 M
              </Button>
            </div>
            <div className="tab-div" onClick={() => handleChangeMonth(12)}>
              <Button
                variant="outlined"
                className={
                  dateRange?.monthRange === 12 ? "active" : "monthTab-btn"
                }
              >
                1 Y
              </Button>
            </div>
            <div className="tab-div" onClick={() => handleChangeMonth(11)}>
              <Button
                variant="outlined"
                className={
                  dateRange?.monthRange === 11 ? "active" : "monthTab-btn"
                }
              >
                Last Year
              </Button>
            </div>
            <div className="tab-div" onClick={() => handleChangeMonth(0)}>
              <Button
                variant="contained"
                className={
                  dateRange?.monthRange === 0 ? "active" : "monthTab-btn"
                }
                endIcon={
                  dateRange?.monthRange === 0 ? (
                    <KeyboardArrowUpIcon style={{ fontSize: "30px" }} />
                  ) : (
                    <KeyboardArrowDownIcon style={{ fontSize: "30px" }} />
                  )
                }
              >
                Custom
              </Button>
            </div>
            {dateRange?.monthRange === 0 && isGlobalSearch && (
              <>
                <div className="tab-div tab-div-dateRangePicker">
                  <div>
                    <DateRangePicker
                      dateRange={dateRange}
                      setDateRange={setDateRange}
                      isMarket={true}
                      handleDateRange={handleDateRange}
                      compareDate={isGlobalSearch ? false : true}
                    />
                  </div>
                </div>
                <div className="tab-div">
                  <ButtonComponent
                    btnIcon={SearchIcon}
                    name={"Search"}
                    style={{ margin: "0px 0px 0px 0px " }}
                    btnClick={() => {
                      onSearchClick();
                    }}
                  />
                </div>
              </>
            )}
          </div>
          {!isGlobalSearch && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <ButtonComponent
                name={"DashBoard"}
                style={{ margin: "0px 4px 0px 0px", backgroundColor: "white" }}
                btnClick={() => {
                  setIsRecordBtnChecked(false);
                }}
              />
              <ButtonComponentWithToolTip
                style={{ margin: "0px 0px 0px 4px", backgroundColor: "white" }}
                btnClick={() => {
                  handleDownloadExcel();
                }}
                tooltipTitle={BUTTON_NAME?.DOWNLOAD_EXCEL}
                name={<img src={Excel} alt="ExcelDownload" />}
                toUpperCase={true}
              />
            </div>
          )}
        </div>
        {!isGlobalSearch && (
          <>
            {dateRange?.monthRange === 0 && (
              <div style={{ padding: "1% 0px", display: "flex", gap: "0.5%" }}>
                <DateRangePicker
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  isMarket={true}
                  handleDateRange={handleDateRange}
                  compareDate={isGlobalSearch ? false : true}
                />
                <span
                  style={{
                    fontSize: "23px",
                    color: "#005d91",
                    fontWeight: "bold",
                  }}
                >
                  VS
                </span>
                <DateRangePicker
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  isMarket={true}
                  handleDateRange={handleDateRange}
                />
                <ButtonComponent
                  btnIcon={SearchIcon}
                  name={"Search"}
                  style={{ margin: "-5px 0px 0px 0px " }}
                  btnClick={() => {
                    onSearchClick();
                  }}
                />
              </div>
            )}
          </>
        )}
      </>
    );
  }
);

export default MonthTab;
