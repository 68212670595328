import PasswordRecoveryComponent from "../../Component/PasswordRecoveryComponent/PasswordRecovery";

const ErrorPage = (props) => {
  return (
    <div>
      <PasswordRecoveryComponent name="ErrorPage" token={props.token} />
    </div>
  );
};

export default ErrorPage;
