const showCustomAlert = (message) => {
  // Create the alert element
  const alertBox = document.createElement("div");
  alertBox.innerHTML = `<span style="margin-right: 10px;">✅</span>${message}`;
  alertBox.style.position = "fixed";
  alertBox.style.top = "30%";
  alertBox.style.left = "50%";
  alertBox.style.transform = "translateX(-50%)";
  alertBox.style.padding = "15px";
  alertBox.style.color = "#fff";
  alertBox.style.backgroundColor = "#005D91";
  alertBox.style.border = "1px solid #005D91";
  alertBox.style.borderRadius = "5px";
  alertBox.style.zIndex = "1000";
  alertBox.style.opacity = "0";
  alertBox.style.transition = "opacity 0.5s ease";
  alertBox.style.boxShadow = "0 4px 15px rgba(0, 0, 0, 0.2)";

  document.body.appendChild(alertBox);

  setTimeout(() => {
    alertBox.style.opacity = "1";
  }, 10);

  setTimeout(() => {
    alertBox.style.opacity = "0";
    setTimeout(() => {
      document.body.removeChild(alertBox);
    }, 500);
  }, 1000);
};

const CopyIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      fill="#005d91"
    >
      <path d="M5.50280381,4.62704038 L5.5,6.75 L5.5,17.2542087 C5.5,19.0491342 6.95507456,20.5042087 8.75,20.5042087 L17.3662868,20.5044622 C17.057338,21.3782241 16.2239751,22.0042087 15.2444057,22.0042087 L8.75,22.0042087 C6.12664744,22.0042087 4,19.8775613 4,17.2542087 L4,6.75 C4,5.76928848 4.62744523,4.93512464 5.50280381,4.62704038 Z M17.75,2 C18.9926407,2 20,3.00735931 20,4.25 L20,17.25 C20,18.4926407 18.9926407,19.5 17.75,19.5 L8.75,19.5 C7.50735931,19.5 6.5,18.4926407 6.5,17.25 L6.5,4.25 C6.5,3.00735931 7.50735931,2 8.75,2 L17.75,2 Z M17.75,3.5 L8.75,3.5 C8.33578644,3.5 8,3.83578644 8,4.25 L8,17.25 C8,17.6642136 8.33578644,18 8.75,18 L17.75,18 C18.1642136,18 18.5,17.6642136 18.5,17.25 L18.5,4.25 C18.5,3.83578644 18.1642136,3.5 17.75,3.5 Z" />
    </svg>
  );
};

const CopyCellRenderer = (props) => {
  return (
    <div style={{ whiteSpace: "pre-wrap" }}>
      {props.value + "     "}
      <span
        style={{ cursor: "pointer" }}
        onClick={() => {
          navigator.clipboard
            .writeText(props.value)
            .then(() => {
              showCustomAlert(`${props.value} Copied to Clipboard`);
            })
            .catch((error) => {
              showCustomAlert(`Failed to copy: ${error.message}`);
            });
        }}
      >
        <CopyIcon />
      </span>
    </div>
  );
};
export default CopyCellRenderer;
