// export const SalesSupportRelated = {
//   INCREASE_CREDIT_POINTS: "Increase Credit Points",
//   RENEW_PLAN: "Renew Plan",
//   UPGRADE_PLAN: "Upgrade Plan",
//   ADD_BUYERS_REQUEST: "Add Buyers Request",
//   TRAINING_NEEDED: "Training Needed",
//   OTHERS: "Others",
// };

export const SalesSupportRelated = [
  {
    value: "Increase Credit Points",
    title: "Increase Credit Points",
  },
  {
    value: "Renew Plan",
    title: "Renew Plan",
  },
  {
    value: "Upgrade Plan",
    title: "Upgrade Plan",
  },
  {
    value: "Add Buyers Request",
    title: "Add Buyers Request",
  },
  {
    value: "Training Needed",
    title: "Training Needed",
  },
  {
    value: "Others",
    title: "Others",
  },
];

// export const TechnicalIssues = {
//   UNABLE_TO_CREATE_WORKSPACE: "Unable to Create Workspace",
//   LOGIN_ISSUE: "Login Issue",
//   ADD_USERS: "Add Users",
//   DOWNLOADING_DATA: "Downloading data",
//   EXPLORE_OR_SEARCH_RELATED: "Explore or Search related",
//   COUNTRY_NOT_AVAILABLE: "Country Not Available",
//   NOTIFICATION_NOT_COMING: "Notification not coming",
//   VIEW_SUMMARY_RELATED: "View Summary related",
//   OTHER: "Other",
// };

export const TechnicalIssues = [
  {
    value: "Unable to Create Workspace",
    title: "Unable to Create Workspace",
  },
  {
    value: "Login Issue",
    title: "Login Issue",
  },
  {
    value: "Add Users",
    title: "Add Users",
  },
  {
    value: "Downloading data",
    title: "Downloading data",
  },
  {
    value: "Explore or Search related",
    title: "Explore or Search related",
  },
  {
    value: "Country Not Available",
    title: "Country Not Available",
  },
  {
    value: "Notification not coming",
    title: "Notification not coming",
  },
  {
    value: "View Summary related",
    title: "View Summary related",
  },
  {
    value: "Other",
    title: "Other",
  },
];

// export const FinanceRelated = {
//   TDS: "TDS",
//   PAYMENT: "Payment",
//   INVOICE_BILL: "Invoice/Bill",
//   TAX: "TAX",
//   OTHERS: "Others",
// };

export const FinanceRelated = [
  {
    value: "TDS",
    title: "TDS",
  },
  {
    value: "Payment",
    title: "Payment",
  },
  {
    value: "Invoice/Bill",
    title: "Invoice/Bill",
  },
  {
    value: "TAX",
    title: "TAX",
  },
  {
    value: "Others",
    title: "Others",
  },
];

// export const Priority = {
//   Low: "Low",
//   Medium: "Medium",
//   High: "High",
//   Urgent: "Urgent",
// };

export const Priority = [
  { value: "Low", title: "Low" },
  { value: "Medium", title: "Medium" },
  { value: "High", title: "High" },
  { value: "Urgent", title: "Urgent" },
];

// export const StatusFilter = {
//   ALL: "ALL",
//   OPEN: "OPEN",
//   RESOLVED: "RESOLVED",
//   PENDING: "PENDING",
//   CLOSED: "CLOSED",
//   Waiting_on_Customer: "Waiting on Customer",
//   Waiting_on_ThirdParty: "Waiting on Third Party",
//   Work_In_Progress: "Work In Progress",
// };

export const StatusFilter = [
  {
    value: "ALL",
    title: "ALL",
  },
  { value: "OPEN", title: "OPEN" },
  { value: "RESOLVED", title: "RESOLVED" },
  { value: "PENDING", title: "PENDING" },
  { value: "CLOSED", title: "CLOSED" },
  { value: "Waiting on Customer", title: "Waiting on Customer" },
  { value: "Waiting on Third Party", title: "Waiting on Third Party" },
  { value: "Work In Progress", title: "Work In Progress" },
];

export const SELECT_QUERY = [
  {
    value: "Sales & Support related (SS)",
    title: "Sales & Support related (SS)",
  },
  { value: "Technical Issues (TH)", title: "Technical Issues (TH)" },
  { value: "Finance Related (FN)", title: "Finance Related (FN)" },
];
