import React from "react";
import { TextField } from "@mui/material";
export default function EditUserDetails(props) {
  return (
    <div>
      <div>
        <TextField
          id="outlined-basic standard-error-helper-text"
          label="First Name"
          variant="outlined"
          className="mt-5"
          name="first_name"
          onChange={props.handleChangeEditUserDetails}
          error={props.isEditUserNameEmpty.first_name}
          value={props.editUserDetails.first_name}
          helperText={props.isEditUserNameEmpty.first_name && "This value is required."}
          required
          style={{ width: "400px" }}
        />
      </div>

      <div style={{ marginTop: "20px" }}>
        <TextField
          id="outlined-basic standard-error-helper-text"
          label="Last Name"
          variant="outlined"
          className="mt-5"
          name="last_name"
          onChange={props.handleChangeEditUserDetails}
          error={props.isEditUserNameEmpty.last_name}
          value={props.editUserDetails.last_name}
          helperText={props.isEditUserNameEmpty.last_name && "This value is required."}
          required
          style={{ width: "400px" }}
        />
      </div>
      <div style={{ marginTop: "20px" }}>
        <TextField
          id="outlined-basic standard-error-helper-text"
          label="Mobile No"
          variant="outlined"
          className="mt-5"
          name="mobile_no"
          onChange={props.handleChangeEditUserDetails}
          value={props.editUserDetails.mobile_no}
          style={{ width: "400px" }}
        />
      </div>
    </div>
  );
}
